import { SimpleQueryInput } from '@api/core/entities/shared';
import { Entity, Of } from 'entity-of';

@Entity
export class CertificateTypeSearchInput extends SimpleQueryInput {
  @Of(() => Number, { optional: true })
  catID?: string;

  @Of(() => String, { optional: true })
  clID?: string;

  @Of(() => Number, { optional: true })
  cerTypeCoordinatorsAllowed?: number;

  static of = Entity.of<CertificateTypeSearchInput>();
}
