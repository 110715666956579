import { Entity, Of } from 'entity-of';

@Entity
export class Publish {
  @Of(() => String)
  tip: string = '';

  @Of(() => [String])
  fillers: string[] = [];

  @Of(() => [String])
  viewersResults: string[] = [];

  @Of(() => [String])
  dueDate: string[] = [];

  @Of(() => String)
  publishResultsType: string = '';

  static of = Entity.of<Publish>();
}
