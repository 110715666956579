import { Entity, Of } from 'entity-of';

export class StudentProgram {
  @Of(() => String)
  academicYear: string = '';

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => Boolean)
  ascIsActive: boolean = false;

  @Of(() => Number)
  facultyId: number = 0;

  @Of(() => String)
  facultyName: string = '';

  @Of(() => Boolean)
  isActive: boolean = false;

  @Of(() => Boolean)
  isVisible: boolean = false;

  @Of(() => String)
  studyCycle: string = '';

  @Of(() => Number)
  studyCycleId: number = 0;

  @Of(() => String)
  studyDomain: string = '';

  @Of(() => Number)
  studyDomainId: number = 0;

  @Of(() => String)
  studyGroup: string = '';

  @Of(() => Number)
  studyGroupId: number = 0;

  @Of(() => String)
  studyProgram: string = '';

  @Of(() => Number)
  studyProgramId: number = 0;

  @Of(() => String)
  studySeries: string = '';

  @Of(() => Number)
  studySeriesId: number = 0;

  @Of(() => String)
  studyYear: string = '';

  @Of(() => Number)
  studyYearId: number = 0;

  static of = Entity.of<StudentProgram>();
}
