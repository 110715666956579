import { Entity, Of } from 'entity-of';

@Entity
export class Charge {
  @Of(() => Number)
  id = 0;

  @Of(() => Number)
  amount = 0;

  @Of(() => Number)
  invoiceId = 0;

  @Of(() => String)
  tenantId = '';

  @Of(() => String)
  accountId = '';

  @Of(() => String)
  periodEnd = '';

  @Of(() => String)
  periodStart = '';

  @Of(() => String)
  currency = '';

  @Of(() => Number)
  count = 0;

  @Of(() => String)
  assignedServiceId = '';

  @Of(() => String)
  billableAccountId = '';

  @Of(() => Boolean)
  isOneTimePayment = false;

  @Of(() => String)
  createdAt = '';

  @Of(() => String)
  info = '';

  static of = Entity.of<Charge>();
}
