import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import { MODULE_FEATURE, PERM_SUBSCRIPTION_CLIENT } from '@constants';
import { usePlatformEvent } from '@lib/bridge';
import { useRouter } from '@lib/router';
import { computeCalloutProps, useStorageSession } from '@lib/utils';
import { isFeatureEnabled } from '@lib/utils/session/hasAccess';
import { AdservioIcons, Box, Button, Center, Flex, HStack, Image, Text } from '@ui2/components';

import { getCalloutContent } from './getCalloutContent';
import {
  BackButton,
  Description,
  NextPanelButton,
  PanelBullet,
  PrevPanelButton,
  Title,
} from './UpgradeCalloutPage.layout';

interface UpgradeCalloutProps {
  isModal?: boolean;
  isSubmodule?: boolean;
  target?: string;
  isPHP?: boolean;
  buttonIcon?: React.ReactElement;
}

export const UpgradeCalloutPage = ({
  isSubmodule,
  isModal,
  target,
  isPHP,
  buttonIcon,
}: UpgradeCalloutProps) => {
  const { t } = useTranslation('shared');
  const { lang, push, query } = useRouter();
  const { isNative, permissions } = useStorageSession();
  const { sendEvent } = usePlatformEvent();

  const [activeIndex, setActiveIndex] = useState(0);

  const { key, backUrl, empty_state_src } = getCalloutContent(String(target || query.module));

  const { packageName, calloutPanels } = computeCalloutProps(String(target || query.module));

  if (!isFeatureEnabled(MODULE_FEATURE[String(query.module)])) {
    return <Redirect to="/" />;
  }

  const handleRedirect = () => {
    if (isNative) {
      sendEvent({ type: 'buy_premium' });
    } else {
      if (isPHP) {
        window.location.href = `/${lang}/subscriptions/buy`;
      } else {
        push('subscriptions/buy');
      }
    }
  };

  return (
    <>
      <Flex minHeight={isModal ? 'initial' : isSubmodule ? '70vh' : '90vh'} flexDirection="column">
        {!isModal && !isSubmodule && !isNative && (
          <Flex alignItems="center">
            {backUrl && <BackButton backUrl={backUrl} />}

            <Text fontSize="3xl" fontWeight="bold">
              {t(`module_upgrade.${key}.page.header`)}
            </Text>
          </Flex>
        )}

        <Center flexDirection="column" flex={1}>
          <Box mb="6">
            {isModal && calloutPanels ? (
              <>
                <HStack spacing="2" alignItems="center" justifyContent="center" mb="2">
                  <PrevPanelButton
                    isActive={activeIndex > 0}
                    onClick={() => setActiveIndex((prevActiveIndex) => prevActiveIndex - 1)}
                  />

                  <Center width="16.5rem" height="16.5rem">
                    <Image src={calloutPanels[activeIndex].image} width="100%" />
                  </Center>

                  <NextPanelButton
                    isActive={calloutPanels?.length > 0 && activeIndex < calloutPanels?.length - 1}
                    onClick={() => setActiveIndex((prevActiveIndex) => prevActiveIndex + 1)}
                  />
                </HStack>

                {calloutPanels?.length > 1 && (
                  <HStack spacing="2" justifyContent="center" mt="4">
                    {calloutPanels.map((_, index) => {
                      return (
                        <React.Fragment key={index}>
                          <PanelBullet isActive={index === activeIndex} />
                        </React.Fragment>
                      );
                    })}
                  </HStack>
                )}
              </>
            ) : (
              <Center width="16.5rem" height="16.5rem">
                <Image src={empty_state_src} width="100%" />
              </Center>
            )}
          </Box>

          <Box minHeight="9.5rem" maxWidth="6xl">
            <Title isModal={isModal}>
              {t(
                `module_upgrade.${key}.${isModal ? 'modal' : 'page'}${isModal ? `.${calloutPanels?.[activeIndex].translation_key}` : ''}.title`,
                {
                  package_name: packageName,
                }
              )}
            </Title>

            <Description>
              {t(
                `module_upgrade.${key}.${isModal ? 'modal' : 'page'}${isModal ? `.${calloutPanels?.[activeIndex].translation_key}` : ''}.description`,
                {
                  package_name: packageName,
                }
              )}
            </Description>
          </Box>

          {permissions.includes(PERM_SUBSCRIPTION_CLIENT) && (
            <Button onClick={handleRedirect} leftIcon={buttonIcon || <AdservioIcons.Diamond />}>
              {t('general.choose_package_x', {
                package_name: packageName,
              })}
            </Button>
          )}
        </Center>
      </Flex>
    </>
  );
};
