import { Entity, Of } from 'entity-of';

@Entity
export class TfaOption {
  @Of(() => String)
  tfaOptID: string = '';

  @Of(() => String)
  tfaOptType: string = '';

  @Of(() => String)
  tfaOptHint: string = '';

  static of = Entity.of<TfaOption>();
}
