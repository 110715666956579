import { Entity, Of } from 'entity-of';

@Entity
export class ImageInput {
  @Of(() => String)
  source: string = '';

  @Of(() => String)
  alt: string = '';

  @Of(() => String)
  width: string = '';

  @Of(() => String)
  height: string = '';

  static of = Entity.of<ImageInput>();
}
