import { Entity, Of } from 'entity-of';

@Entity
export class PersonStaffFilter {
  @Of(() => Number, { optional: true })
  offset?: number;

  @Of(() => Number, { optional: true })
  limit?: number;

  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => String, { optional: true })
  publisherId?: string;

  @Of(() => String, { optional: true })
  categoryId?: string;

  @Of(() => Boolean, { optional: true })
  isPublished?: boolean;

  @Of(() => String, { optional: true })
  roleID?: string = '';

  @Of(() => String, { optional: true })
  cadCnp?: string = '';

  @Of(() => String, { optional: true })
  cadIsDeleted?: string = '';

  static of = Entity.of<PersonStaffFilter>();
}
