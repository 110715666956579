import { Entity, Of } from 'entity-of';

@Entity
export class AcademicYear {
  @Of(() => Number)
  id = 0;

  @Of(() => Number)
  startYear = 0;

  @Of(() => Number)
  endYear = 0;

  @Of(() => Boolean)
  currentYear = false;

  static of = Entity.of<AcademicYear>();
}
