import { useSelector } from 'react-redux';

import { Role } from '@api/core/entities';
import { TYPE_ADMIN, TYPE_CADRU, TYPE_ELEV, TYPE_PARINTE } from '@constants';

import { isCompany, isSchool } from '../../../Redux/SessionHelper';
import {
  getCountryId,
  getCurrentElevID,
  getCurrentElevName,
  getFromAnScolar,
  getFromProfile,
  getProfileID,
  getSchoolConfig,
  getSchoolID,
  getSchoolIDforAdmin,
} from '../../../Redux/sessionSelectors';
import { OrganizationTypes } from '../entities/Organizations';

import { AdservioUserTypeDict, mapLegacyUserTypes } from './permissions';

export interface SessionItem {
  schoolConfig: Record<string, any>;
  profile: Record<string, any>;
  clase: any[];
}

export const useSession = () => {
  const studentId: string = useSelector(getCurrentElevID());
  const accountId: string = useSelector(getProfileID());
  const {
    accountLastName,
    accountFirstName,
  }: { accountLastName: string; accountFirstName: string } = useSelector(getCurrentElevName());
  const accountRoles: Role[] = useSelector(getFromProfile('roles'));
  const schoolId = useSelector(getSchoolID());
  const legacyAccountType = useSelector(getFromProfile('uaTip'));
  const orgId = useSelector(getFromProfile('uaLiceuID'));
  const schoolConfig = useSelector(getSchoolConfig());
  const accountPermissions = useSelector(getFromProfile('permisiuni'))?.map((p) => p.permID);
  const schoolYear: string = useSelector(getFromAnScolar('ascID'));
  const isAdmin = legacyAccountType === TYPE_ADMIN;
  const countryId = useSelector(getCountryId());
  const schoolYearStartDate = useSelector(getFromAnScolar('ascDataStart'));
  const schoolYearEndDate = useSelector(getFromAnScolar('ascDataEnd'));
  const schoolYearName = useSelector(getFromAnScolar('ascNume'));

  const rootOrgId = isAdmin
    ? useSelector(getSchoolIDforAdmin())
    : useSelector(getFromProfile('liceu'))?.liceuOrgRootID;

  const organizationType: OrganizationTypes = useSelector(isSchool)
    ? OrganizationTypes.SCHOOL
    : useSelector(isCompany)
      ? OrganizationTypes.COMPANY
      : OrganizationTypes.UNIVERSITY;

  const accountType = mapLegacyUserTypes(AdservioUserTypeDict[legacyAccountType]);

  return {
    orgId,
    schoolConfig,
    isAdmin,
    accountId,
    accountLastName,
    accountFirstName,
    accountRoles,
    studentId,
    schoolYear,
    schoolYearStartDate,
    schoolYearEndDate,
    schoolYearName,
    accountType,
    countryId,
    schoolId,
    accountPermissions,
    legacyAccountType,
    organizationType,
    rootOrgId,
  };
};

export const getUrlPrefix = (uaTip) => {
  switch (uaTip) {
    case TYPE_ELEV:
      return 'student';
    case TYPE_PARINTE:
      return 'parent';
    case TYPE_CADRU:
      return 'teacher';
  }
};
