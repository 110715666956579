import { PortfolioCategoryType, PortfolioCategoryTypeEnum } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class StudentPortfolioCategoryPayload {
  @Of(() => String)
  name = '';

  @Of(() => String)
  icon = '';

  @Of(() => String)
  type: PortfolioCategoryType = PortfolioCategoryTypeEnum.Educational;

  static of = Entity.of<StudentPortfolioCategoryPayload>();
}
