import { ArrayNotEmpty, IsEmail, IsNotEmpty, Length, MaxLength, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';
import { t } from 'i18next';
import isEmpty from 'lodash/isEmpty';

import { AssociatedDiscipline } from './AssociatedDiscipline';
import { AssociatedFaculty } from './AssociatedFaculty';
import { AssociatedGroup } from './AssociatedGroup';
import { ErasmusStudentInformation } from './ErasmusStudentInformation';

@Entity
export class UniversityPersonal {
  @Of(() => Number)
  personId: number = 0;

  @Of(() => String)
  uaID: string = '';

  @Of(() => Number)
  userId: number = 0;

  @Of(() => String)
  actualName: string = '';

  @Of(() => String)
  birthGivenName: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  surname: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  name: string = '';

  @Of(() => String)
  @MaxLength(4, { message: 'errors.parent_initial_length' })
  parentInitial: string = '';

  @Of(() => String)
  @Length(13, 13, { message: 'errors.cnp_length' })
  @IsNotEmpty({ message: t('errors.required') })
  cnp: string = '';

  @Of(() => String)
  @ValidateIf((o) => !isEmpty(o.personalEmail))
  @IsEmail({}, { message: 'errors.email_format' })
  personalEmail: string = '';

  @Of(() => String)
  phoneNumber: string = '';

  @Of(() => String)
  microsoftId: string = '';

  @Of(() => Number)
  moodleId: number = 0;

  @Of(() => Boolean)
  foreignCitizen: boolean = false;

  @Of(() => String)
  prevCNP: string = '';

  @Of(() => String)
  bankName: string = '';

  @Of(() => String)
  bankAddress: string = '';

  @Of(() => String)
  swift: string = '';

  @Of(() => String)
  iban: string = '';

  @Of(() => String)
  accountHolderName: string = '';

  @Of(() => Date)
  lastUpdate: Date = new Date();

  @Of(() => Boolean)
  admin: boolean = false;

  @Of(() => Boolean)
  manager: boolean = false;

  @Of(() => Boolean)
  associate: boolean = false;

  @Of(() => Boolean)
  erasmusProgram: boolean = false;

  @Of(() => Boolean)
  departmentManager: boolean = false;

  @Of(() => Boolean)
  financialManager: boolean = false;

  @Of(() => Boolean)
  nonDidacticManager: boolean = false;

  @ValidateIf((o) =>
    o.roleName.find(
      (role) =>
        role.permissions.isSecretary ||
        role.permissions.isManager ||
        role.permissions.isScholarshipManager
    )
  )
  @ArrayNotEmpty({ message: 'errors.required' })
  @Of(() => [AssociatedFaculty])
  associatedFaculties: AssociatedFaculty[] = [];

  @Of(() => Boolean)
  erasmus: boolean = false;

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => ErasmusStudentInformation)
  erasmusStudentInformation: ErasmusStudentInformation | undefined;

  @Of(() => Boolean)
  student: boolean = false;

  @Of(() => [AssociatedGroup])
  associatedGroups: AssociatedGroup[] = [];

  @Of(() => Boolean)
  professor: boolean = false;

  @Of(() => Boolean)
  externalStudent: boolean = false;

  @Of(() => String)
  professorDepartment?: string = '';

  @ValidateIf((o) =>
    o.roleName.find((role) => role.permissions.isTeacher || role.permissions.isManager)
  )
  @Of(() => Number)
  @IsNotEmpty({ message: t('errors.required') })
  professorDepartmentId?: number | null = null;

  @Of(() => String)
  professorFunction?: string = '';

  @ValidateIf((o) =>
    o.roleName.find(
      (role) =>
        role.permissions.isTeacher ||
        role.permissions.isManager ||
        role.permissions.isSecretary ||
        role.permissions.isAuxPers
    )
  )
  @Of(() => Number)
  @IsNotEmpty({ message: t('errors.required') })
  professorFunctionId?: number | null = null;

  @Of(() => Number)
  leadershipFunctionId?: number = 0;

  @Of(() => String)
  leadershipFunction?: string = '';

  @Of(() => String)
  professorFunctionType?: string = '';

  @ValidateIf((o) =>
    o.roleName.find((role) => role.permissions.isTeacher || role.permissions.isManager)
  )
  @Of(() => Number)
  @IsNotEmpty({ message: t('errors.required') })
  professorMark?: number | null = null;

  @Of(() => [AssociatedDiscipline])
  associatedDisciplines: AssociatedDiscipline[] = [];

  @Of(() => String)
  birthDate: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  gender: string = '';

  @Of(() => String)
  identityDocumentType: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  citizen: string = '';

  @Of(() => String)
  documentSeries: string = '';

  @Of(() => String)
  documentNumber: string = '';

  @Of(() => String)
  issuer: string = '';

  @Of(() => String)
  dateOfIssue: string = '';

  @Of(() => String)
  expireDate: string = '';

  @Of(() => String)
  countryName: string = '';

  @Of(() => String)
  country: ValueLabel = ValueLabel.of({});

  @Of(() => String)
  countyName: string = '';

  @Of(() => ValueLabel)
  county: ValueLabel = ValueLabel.of({});

  @Of(() => String)
  cityName: string = '';

  @Of(() => ValueLabel)
  city: ValueLabel = ValueLabel.of({});

  @Of(() => String)
  address: string = '';

  @Of(() => String)
  carLicensePlateNumber: string = '';

  @Of(() => String)
  maritalStatus: string = '';

  @Of(() => String)
  observations: string = '';

  @Of(() => String)
  tuition: string = '';

  @Of(() => Boolean)
  rural: boolean = false;

  @Of(() => Number, { nullable: true })
  @ValidateIf((o) => o.student || o.externalStudent)
  idStudentType: number | null = null;

  @Of(() => Number, { nullable: true })
  @ValidateIf((o) => o.student || o.externalStudent)
  idStudentCategory: number | null = null;

  @Of(() => [Roles])
  roleName: Roles[] = [];

  @Of(() => Boolean)
  shouldSetExternalRole: boolean = false;

  static of = Entity.of<UniversityPersonal>();
}

@Entity
export class ValueLabel {
  @Of(() => String)
  value: string = '';

  @Of(() => String)
  label: string = '';

  static of = Entity.of<ValueLabel>();
}

@Entity
export class UniversityPersonalWithRoles extends UniversityPersonal {
  @ArrayNotEmpty({ message: 'errors.required' })
  @Of(() => [Roles])
  roleName: Roles[] = [];

  static of = Entity.of<UniversityPersonalWithRoles>();
}

@Entity
export class Roles {
  @Of(() => String)
  label: string = '';

  @Of(() => String)
  value: string = '';

  @Of(() => RolePermissions)
  permissions: RolePermissions = RolePermissions.of({});

  static of = Entity.of<UniversityPersonal>();
}

@Entity
export class RolePermissions {
  @Of(() => Boolean)
  isAuxPers: boolean = false;

  @Of(() => Boolean)
  isManager: boolean = false;

  @Of(() => Boolean)
  isTeacher: boolean = false;

  @Of(() => Boolean)
  isSecretary: boolean = false;

  @Of(() => Boolean)
  isScholarshipManager: boolean = false;

  static of = Entity.of<RolePermissions>();
}
