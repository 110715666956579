import { Entity, Of } from 'entity-of';

import { UserTypeInfo } from '../UserTypeInfo';

@Entity
export class MemeberDetail {
  @Of(() => UserTypeInfo)
  info = UserTypeInfo.of({});

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  prefAvatar: string = '';

  @Of(() => String)
  colorScheme: string = '';

  static of = Entity.of<MemeberDetail>();
}
