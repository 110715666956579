import React from 'react';

import { Box, Button, HStack, Text } from '../../atoms';

import { IndicatorWrapper } from './Stepper.theme';

// TODO: Add export to StepProps after dropping Stepper.tsx

interface StepProps {
  id: number;
  label: string;
  isDisabled?: boolean;
}

interface StepperProps {
  steps: StepProps[];
  currentStep: number;
  onStepChange?: (step: number) => void;
}

// TODO: create story
export const Stepper2 = ({ steps, currentStep, onStepChange }: StepperProps) => {
  return (
    <>
      <HStack>
        {steps?.map((step, index) => {
          const color =
            currentStep > index ? 'green.300' : currentStep === index ? 'green.500' : 'gray.200';

          return (
            <HStack key={index}>
              <Button
                variant="unstyled"
                onClick={() => onStepChange?.(step.id)}
                cursor={onStepChange ? 'pointer' : 'default'}
              >
                <HStack color={color}>
                  <IndicatorWrapper color={color}>
                    <Text color="white">{index + 1}</Text>
                  </IndicatorWrapper>

                  <Text fontSize="sm" color={color}>
                    {step.label}
                  </Text>
                </HStack>
              </Button>

              {index < steps.length - 1 && (
                <Box height="2px" width="1.5rem" backgroundColor="gray.200" borderRadius="lg" />
              )}
            </HStack>
          );
        })}
      </HStack>
    </>
  );
};
