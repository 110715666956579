import { ArrayNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class SelectClassesFormInput {
  @Of(() => String)
  @ArrayNotEmpty({ message: 'errors.required' })
  classes: string[] = [];

  static of = Entity.of<SelectClassesFormInput>();
}
