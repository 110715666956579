import { Entity, Of } from 'entity-of';

@Entity
export class Country {
  @Of(() => Number)
  idTara: number = 0;

  @Of(() => String)
  taraCod: string = '';

  @Of(() => String)
  numeTara: string = '';

  @Of(() => String)
  prefixTelefon: string = '';

  @Of(() => Boolean)
  esteUE: boolean = false;

  static of = Entity.of<Country>();
}

@Entity
export class CountrySearchResponse {
  @Of(() => [Country])
  result: Country[] = [];

  static of = Entity.of<CountrySearchResponse>();
}

@Entity
export class City {
  @Of(() => Number)
  idOras: number = 0;

  @Of(() => Number)
  idJudet: number = 0;

  @Of(() => String)
  numeOras: string = '';

  @Of(() => String)
  siruta: string = '';

  static of = Entity.of<City>();
}

@Entity
export class CitySearchResponse {
  @Of(() => [City])
  result: City[] = [];

  static of = Entity.of<CitySearchResponse>();
}

@Entity
export class County {
  @Of(() => Number)
  idJudet: number = 0;

  @Of(() => Number)
  idTara: number = 0;

  @Of(() => String)
  codJudet: string = '';

  @Of(() => String)
  numeJudet: string = '';

  static of = Entity.of<City>();
}

@Entity
export class CountySearchResponse {
  @Of(() => [County])
  result: County[] = [];

  static of = Entity.of<CountySearchResponse>();
}
