import { useQuery, UseQueryOptions } from 'react-query';

import { Country, LegacyPaginatedResponse } from '@api/core/entities';
import { httpClient } from '@lib/http';
import { computeUrlParams } from '@lib/utils';
import { AxiosError } from 'axios';

interface QueryInput {
  lang?: string;
  limit?: number;
}

const searchCountries = ({ queryKey }: UseQueryOptions<QueryInput>) => {
  const [_, queryPayload] = queryKey ?? [];
  const { lang, limit } = queryPayload as QueryInput;

  let url = `/adrese/tari`;

  const params = computeUrlParams({
    _limit: limit || -1,
    lang,
  });

  if (params) {
    url += `?${params}`;
  }

  return httpClient<LegacyPaginatedResponse<Country>, Country>(url).get();
};

export const useCountriesQuery = (
  queryInput: QueryInput,
  options?: UseQueryOptions<LegacyPaginatedResponse<Country>, AxiosError>
) => {
  return useQuery<LegacyPaginatedResponse<Country>, AxiosError>(
    ['countries', queryInput],
    searchCountries,
    options
  );
};
