import { Entity, Of } from 'entity-of';

@Entity
export class ProfessorDepartmentsResponse {
  @Of(() => Number)
  departmentId: number = 0;

  @Of(() => String)
  name: string = '';

  static of = Entity.of<ProfessorDepartmentsResponse>();
}
