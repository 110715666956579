import { QueryClient } from 'react-query';

import { AxiosError } from 'axios';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: (error) => {
        if (
          (error as AxiosError)?.response?.status === 500 ||
          (error as AxiosError)?.code === 'ECONNABORTED'
        ) {
          queryClient.invalidateQueries('platform-status');
        }
      },
    },
    mutations: {
      onError: (error) => {
        if (
          (error as AxiosError)?.response?.status === 500 ||
          (error as AxiosError)?.code === 'ECONNABORTED'
        ) {
          queryClient.invalidateQueries('platform-status');
        }
      },
    },
  },
});
