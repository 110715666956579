import { Entity, Of } from 'entity-of';

@Entity
export class RepartitionSearchInput {
  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => Number, { optional: true })
  facultyId?: number;

  @Of(() => [String], { optional: true })
  studyTypeYear?: string = '';

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => [String], { optional: true })
  statuses?: string[];

  @Of(() => [String], { optional: true })
  dorms?: string[];

  @Of(() => [String], { optional: true })
  rooms?: string[];

  @Of(() => [String], { optional: true })
  startDate?: string;

  @Of(() => [String], { optional: true })
  endDate?: string;

  @Of(() => [String], { optional: true })
  sortField?: string;

  @Of(() => [String], { optional: true })
  direction?: string;

  @Of(() => [Number])
  page: number = 0;

  @Of(() => [Number])
  size: number = 10;

  static of = Entity.of<RepartitionSearchInput>();
}
