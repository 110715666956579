import { Entity, Of } from 'entity-of';

@Entity
export class CreateCertificate {
  @Of(() => String, { optional: true })
  cerType?: string = '';

  @Of(() => String)
  cerIDs: string[] = [];

  @Of(() => Boolean)
  success: boolean = true;

  static of = Entity.of<CreateCertificate>();
}
