import { Entity, Of } from 'entity-of';

@Entity
export class HomeworkEvent {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String, { optional: true })
  title?: string;

  @Of(() => String)
  description: string = '';

  @Of(() => String)
  dueDate: string = '';

  @Of(() => Boolean, { optional: true })
  allowAfterDuwDate?: boolean;

  @Of(() => String)
  type: 'homework' | 'questionair' = 'homework';

  @Of(() => String, { optional: true })
  recurrenceType?: string = '';

  @Of(() => Number, { optional: true })
  recurrenceID?: number;

  @Of(() => Number, { optional: true })
  timeLimit?: number;

  @Of(() => String, { optional: true })
  startDate?: string = '';

  @Of(() => String)
  matColor: string = '';

  static of = Entity.of<HomeworkEvent>();
}
