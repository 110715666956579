import { Entity, Of } from 'entity-of';

@Entity
export class TimetableWizardStatistics {
  @Of(() => Number)
  classes: number = 0;

  @Of(() => Number)
  teachers: number = 0;

  @Of(() => Number)
  rooms: number = 0;

  @Of(() => Number)
  subjects: number = 0;

  @Of(() => Number)
  activities: number = 0;

  @Of(() => Number)
  hours: number = 0;

  static of = Entity.of<TimetableWizardStatistics>();
}
