import { Entity, Of } from 'entity-of';

@Entity
export class SchedulesSearchInput {
  @Of(() => String)
  ascID: string = '';

  @Of(() => String, { optional: true })
  withSlots?: string;

  @Of(() => String, { optional: true })
  name?: string;

  @Of(() => Number, { optional: true })
  _limit?: number;

  @Of(() => Number, { optional: true })
  _offset?: number;

  @Of(() => String, { optional: true })
  _order?: string;

  static of = Entity.of<SchedulesSearchInput>();
}
