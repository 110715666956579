import { useQuery, UseQueryOptions } from 'react-query';

import { ExchangeRates, ExchangeRatesInput } from '@api/billing/entities';
import { httpClient } from '@lib/http';
import { AxiosError } from 'axios';

type QueryInput = ExchangeRatesInput;

const getExchangeRates = ({ queryKey }: UseQueryOptions<QueryInput>) => {
  const [_, queryPayload] = queryKey ?? [];

  const { rootOrgId, ...payload } = queryPayload as QueryInput;

  let headers = {};

  if (rootOrgId) {
    headers = { ...headers, 'X-Tenant-ID': rootOrgId };
  }

  return httpClient<ExchangeRates[], QueryInput>(`/billing/exchange`, {
    payload,
    headers,
    responseMapper: ExchangeRates.of,
  }).post();
};

export const useExchangeRatesQuery = (
  queryInput: Partial<QueryInput>,
  options?: UseQueryOptions<ExchangeRates[], AxiosError>
) => {
  return useQuery<ExchangeRates[], AxiosError>(
    ['exchange', ExchangeRatesInput.of(queryInput)],
    getExchangeRates,
    options
  );
};
