import { Entity, Of } from 'entity-of';

@Entity
export class ProfessorFunctionsResponse {
  @Of(() => Number)
  functionId: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  functionType: number = 0;

  static of = Entity.of<ProfessorFunctionsResponse>();
}
