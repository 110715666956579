import { CreateTenantNumberSeriesInput, InvoiceType } from '@api/billing/entities';
import { Transform, Type } from 'class-transformer';
import { IsArray, IsNotEmpty, ValidateNested } from 'class-validator';
import { Entity, Of } from 'entity-of';

import 'reflect-metadata';

@Entity
export class BillingProfileInvoiceConfigInput {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CreateTenantNumberSeriesInput)
  @Of(() => [CreateTenantNumberSeriesInput])
  @Transform(({ value, obj }) => {
    const x = value.map((v: CreateTenantNumberSeriesInput, i: number) => {
      const temp: CreateTenantNumberSeriesInput[] = [...value];
      temp.splice(i, 1);
      v['currentSeries'] = temp.map((t) => t?.serial);

      for (const proformaSerial of obj.proformaSeries) {
        v['currentSeries'].push(proformaSerial.serial);
      }

      return v;
    });

    return x;
  })
  invoiceSeries: CreateTenantNumberSeriesInput[] = [];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CreateTenantNumberSeriesInput)
  @Of(() => [CreateTenantNumberSeriesInput])
  @Transform(({ value, obj }) => {
    const x = value.map((v: CreateTenantNumberSeriesInput, i: number) => {
      const temp: CreateTenantNumberSeriesInput[] = [...value];
      temp.splice(i, 1);
      v['currentSeries'] = temp.map((t) => t?.serial);

      for (const invoiceSerial of obj.invoiceSeries) {
        v['currentSeries'].push(invoiceSerial.serial);
      }

      return v;
    });

    return x;
  })
  proformaSeries: CreateTenantNumberSeriesInput[] = [];

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.currency.required' })
  currency: string = '';

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.invoiceDay.required' })
  invoiceDay: number | null = null;

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.invoiceDueAfter.required' })
  invoiceDueAfter?: number;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.defaultInvoiceStatus.required' })
  defaultInvoiceStatus: string = '';

  @Of(() => InvoiceType)
  @IsNotEmpty({ message: 'errors.defaultInvoiceType.required' })
  defaultInvoiceType: InvoiceType | null = null;

  @Of(() => String, { optional: true })
  otherInformation?: string;

  static of = Entity.of<BillingProfileInvoiceConfigInput>();
}
