import { Entity, Of } from 'entity-of';
@Entity
export class CompanyBillingProfileInput {
  @Of(() => String)
  companyName: string = '';

  @Of(() => String)
  fiscalCode: string = '';

  @Of(() => String)
  nrJ: string = '';

  @Of(() => String)
  street: string = '';

  @Of(() => String)
  zipCode: string = '';

  @Of(() => String)
  country: string = '';

  @Of(() => String)
  county: string = '';

  @Of(() => String)
  city: string = '';

  @Of(() => String)
  countryId: string = '';

  @Of(() => String)
  countyId: string = '';

  @Of(() => String, { optional: true })
  cityId?: string;

  @Of(() => String)
  firstName: string = '';

  @Of(() => String)
  lastName: string = '';

  @Of(() => String)
  email: string = '';

  @Of(() => String)
  phone: string = '';

  @Of(() => String)
  bankName: string = '';

  @Of(() => String)
  iban: string = '';

  @Of(() => Boolean)
  displayBankOnInvoice: boolean = false;

  @Of(() => Boolean)
  isJuridic: boolean = true;

  @Of(() => String)
  accountId: string = '';

  @Of(() => Boolean)
  isSubscriptions: boolean = false;

  static of = Entity.of<CompanyBillingProfileInput>();
}
