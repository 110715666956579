import googleAnalytics from '@analytics/google-analytics';
import Analytics from 'analytics';

const getAnalytics = () => {
  if (import.meta.env.DEV) {
    return;
  }

  return Analytics({
    app: 'adservio.ro',
    plugins: [
      googleAnalytics({
        measurementIds: ['G-JVKVDCK01P'],
      }),
    ],
    debug: false,
  });
};
const analytics = getAnalytics();

export default analytics;
// This is just for development purposes, so that this key is not poluted with the production events, and have a cleared picture of the events
// Uncomment this if you want to test the events on localhost
// G-7KHRF6GEYY The google analytics ID for localhost
// import googleAnalytics from '@analytics/google-analytics';

// const getAnalytics = () => {
//   return Analytics({
//     app: 'adservio.ro',
//     plugins: [
//       googleAnalytics({
//         measurementIds: ['G-7KHRF6GEYY'],
//       }),
//     ],
//     debug: true,
//   });
// };

// const analytics = getAnalytics();
// export default analytics;
