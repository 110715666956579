import { Entity, Of } from 'entity-of';

@Entity
export class StudentStatistic {
  @Of(() => String)
  cssCount = '';

  @Of(() => String)
  cssDate = '';

  static of = Entity.of<StudentStatistic>();
}
