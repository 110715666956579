import { Entity, Of } from 'entity-of';

@Entity
export class UniversityStructureInput {
  @Of(() => String)
  type: string = 'FACULTATE';

  @Of(() => Number)
  parentId?: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  static of = Entity.of<UniversityStructureInput>();
}
