import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

import { Payment } from '..';

@Entity
export class Payment_Person extends Payment {
  @Of(() => Person)
  person: Person = Person.of({});

  static of = Entity.of<Payment_Person>();
}
