import { useTranslation } from 'react-i18next';

import { PERM_SUBSCRIPTION_CLIENT } from '@constants';
import { useRouter } from '@lib/router';
import { useSession } from '@lib/utils';
import { EmptyFallback } from '@modules/shared';
import { Vectors } from '@ui2/assets';
import { AdservioIcons, Button } from '@ui2/components';

export const NoSubscriptionActive = () => {
  const { accountPermissions } = useSession();
  const { t } = useTranslation('shared');
  const { push } = useRouter();

  return (
    <EmptyFallback
      imgSrc={Vectors.NoSubscriptionActive}
      title={t('general.no_more_subscription_active_title')}
      description={t('general.no_more_subscription_active_description')}
    >
      {accountPermissions.includes(PERM_SUBSCRIPTION_CLIENT) && (
        <Button
          leftIcon={<AdservioIcons.Diamond />}
          onClick={() => {
            push('subscriptions/buy');
          }}
        >
          {t('general.no_more_subscription_active_button')}
        </Button>
      )}
    </EmptyFallback>
  );
};
