import { Entity, Of } from 'entity-of';

@Entity
export class CheckInInput {
  @Of(() => String, { nullable: true })
  carNumber: string | null = '';

  @Of(() => String, { nullable: true })
  observations: string | null = '';

  static of = Entity.of<CheckInInput>();
}

@Entity
export class CarNumberInput {
  @Of(() => String, { nullable: true })
  carNumber: string | null = null;

  static of = Entity.of<CarNumberInput>();
}

@Entity
export class PricePlanInput {
  @Of(() => Number, { nullable: true })
  pricePlanId: number | null = null;

  static of = Entity.of<PricePlanInput>();
}
