import { Entity, Of } from 'entity-of';

@Entity
export class OauthAccount {
  @Of(() => String)
  oaEmail: string = '';

  @Of(() => String)
  oaID: string = '';

  @Of(() => String)
  oaLastUpdate: string = '';

  @Of(() => String)
  oaProviderID: string = '';

  @Of(() => String)
  oaProviderUserID: string = '';

  @Of(() => String)
  oaStatus: string = '';

  @Of(() => String)
  oaUaID: string = '';

  static of = Entity.of<OauthAccount>();
}
