import { IsNotEmpty, Matches, MaxLength } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class Specialization {
  @Of(() => String)
  spID: string = '';

  @Of(() => String)
  @Matches(/^[^-\s]/, {
    message: 'errors.admission.no_white_space',
  })
  @IsNotEmpty({ message: 'errors.spNume.required' })
  @MaxLength(80, {
    message: 'errors.max_80',
  })
  spNume: string = '';

  static of = Entity.of<Specialization>();
}
