import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Text, VStack } from '@ui2/components/atoms';

export type PasswordStrengthType = 'weak' | 'medium' | 'strong' | 'very_strong';
interface PasswordStrengthProps {
  strength?: PasswordStrengthType;
  hideText?: string;
}

export const PasswordStrength = ({ strength, hideText }: PasswordStrengthProps) => {
  const { t } = useTranslation('shared');

  const colors = {
    weak: 'red.500',
    medium: 'orange.500',
    strong: 'green.400',
    very_strong: 'green.500',
  };

  const lengths = {
    weak: 1,
    medium: 2,
    strong: 3,
    very_strong: 4,
  };

  return (
    <VStack w="full" alignItems="flex-end" spacing="1">
      <Grid templateColumns="repeat(4,1fr)" gap="1" w="full">
        {Array(4)
          .fill('')
          .map((_, index) => (
            <Box
              key={index}
              bg={strength && index < lengths[strength] ? colors[strength] : 'gray.200'}
              borderRadius="sm"
              w="full"
              h="1"
            />
          ))}
      </Grid>

      {!hideText && (
        <Text color={strength ? colors[strength] : undefined} fontSize="sm">
          {t(`password_strengths.${strength}`)}
        </Text>
      )}
    </VStack>
  );
};
