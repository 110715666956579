import { PropsWithChildren } from 'react';

import { Avatar, Grid, GridProps, Tag, Text } from '@ui2/components';
import { getOrgTypeTheme } from '@ui2/utils';

interface MainContainerProps extends GridProps {
  hideDetailsOnMobile?: boolean;
}

export const MainContainer = ({
  hideDetailsOnMobile,
  children,
  ...props
}: PropsWithChildren<MainContainerProps>) => {
  return (
    <Grid
      templateColumns="auto 1fr"
      alignItems="center"
      columnGap={{ base: hideDetailsOnMobile ? '0' : '2', sm: '2' }}
      {...props}
    >
      {children}
    </Grid>
  );
};

export const ProfileAvatar = ({
  name,
  size,
  avatar,
}: {
  name: string;
  avatar?: string;
  size?: 'xs' | 'sm' | 'md';
}) => {
  const baseColor = getOrgTypeTheme('blue');
  let boxSize = '3rem';

  switch (size) {
    case 'md':
      boxSize = '3rem';
      break;
    case 'sm':
      boxSize = '2rem';
      break;
    case 'xs':
      boxSize = '1rem';
      break;
  }

  return (
    <Avatar
      size={size}
      name={name}
      src={avatar}
      boxSize={boxSize}
      color="white"
      backgroundColor={`${baseColor}.500`}
    />
  );
};

export const ProfileDetailsSection = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Grid templateColumns="1fr" rowGap="1" alignItems="center" textAlign="left">
      {children}
    </Grid>
  );
};

export const ProfileName = ({
  size = 'md',
  children,
}: PropsWithChildren<{ size?: 'sm' | 'md' }>) => {
  return (
    <Text
      fontWeight="semibold"
      fontSize={size}
      color="gray.700"
      noOfLines={1}
      lineHeight="1.25rem"
      data-testid="account-excerpt-name"
    >
      {children}
    </Text>
  );
};

export const ProfileRole = ({
  useTag,
  fontSize = 'md',
  children,
}: PropsWithChildren<{ fontSize?: 'sm' | 'md'; useTag?: boolean }>) => {
  return (
    <>
      {useTag ? (
        <Tag
          size={fontSize}
          variant="outline"
          colorScheme="gray"
          borderRadius="full"
          tagLabel={children as string}
        >
          {children}
        </Tag>
      ) : (
        <Text
          fontSize={fontSize === 'sm' ? 'xs' : 'sm'}
          fontWeight="normal"
          lineHeight="4"
          color="gray.400"
          textTransform="capitalize"
        >
          {children}
        </Text>
      )}
    </>
  );
};
