import { Entity, Of } from 'entity-of';

type Direction = 'asc' | 'desc';

@Entity
export class UniSimpleQueryInput {
  @Of(() => Number, { optional: true })
  number?: number;

  @Of(() => Number, { optional: true })
  offset?: number;

  @Of(() => Number, { optional: true })
  limit?: number;

  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => [Filters], { optional: true })
  filters?: Filters[];

  @Of(() => [Order], { optional: true })
  order?: Order[];

  static of = Entity.of<UniSimpleQueryInput>();
}

@Entity
export class Filters {
  @Of(() => String, { optional: true })
  field?: string;

  @Of(() => Operators, { optional: true })
  operators?: Operators;

  static of = Entity.of<Filters>();
}

@Entity
export class Order {
  @Of(() => String, { optional: true })
  field?: string;

  @Of(() => String, { optional: true })
  direction?: Direction;
  static of = Entity.of<Order>();
}

@Entity
export class Operators {
  @Of(() => String, { optional: true })
  gt?: string;

  @Of(() => String, { optional: true })
  lt?: string;

  @Of(() => String, { optional: true })
  eq?: string;

  @Of(() => String, { optional: true })
  not?: string;

  @Of(() => String, { optional: true })
  ne?: string;

  @Of(() => String, { optional: true })
  gte?: string;

  @Of(() => String, { optional: true })
  lte?: string;

  @Of(() => String, { optional: true })
  like?: string;

  @Of(() => String, { optional: true })
  in?: any[];

  static of = Entity.of<Operators>();
}
