import { Entity, Of } from 'entity-of';

import { FormField } from './FormField';

@Entity
export class DocumentFormSchema {
  @Of(() => String)
  id = '';

  @Of(() => String)
  name = '';

  @Of(() => [FormField])
  fields: FormField[] = [];

  static of = Entity.of<DocumentFormSchema>();
}
