import { Package } from '../Package';

export class SessionFeatures {
  organization?: FeaturesShape;
  user?: UserFeatures;
}

export class UserFeatures {
  package?: Package;
  features: FeatureDetails[] = [];
}

export class FeaturesShape {
  features: FeatureDetails[] = [];
}

export class FeatureDetails {
  id: string = '';
  value: boolean | string = false;
}
