import { useMemo } from 'react';

import { AccountBillingProfile } from '@api/billing/entities';
import { Grid, RadioCard } from '@ui2/components';

import { BillingAccountProfileRadio } from '../BillingProfileRadio';

interface SelectAccountBillingProfileProps {
  isInvalid?: boolean;
  value?: string | number;
  onChange?: (data: string | number) => void;
  profiles: AccountBillingProfile[];
}

export const SelectAccountBillingProfile = ({
  profiles,
  value,
  isInvalid,
  onChange,
}: SelectAccountBillingProfileProps) => {
  const sortedProfiles = useMemo(() => profiles.sort((a) => (a.isDefault ? -1 : 1)), [profiles]);

  return (
    <Grid rowGap="4" my="2" width="full">
      {sortedProfiles.map((p) => (
        <RadioCard
          key={p.id}
          data={p.id}
          value={value}
          onChange={onChange}
          isInvalid={isInvalid}
          renderChildren={(isChecked) => (
            <BillingAccountProfileRadio profile={p} isChecked={isChecked} />
          )}
        />
      ))}
    </Grid>
  );
};
