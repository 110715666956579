import { registerDecorator, ValidationOptions } from 'class-validator';

import { TreeSelection } from '../TreeSelection';

export function IsTreeShape(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'isTreeShape',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(object: TreeSelection) {
          let hasAnySelection = false;

          if (object.countries) {
            hasAnySelection = hasAnySelection || object.countries[0]?.selection?.length > 0;
          }

          if (object.counties) {
            Object.keys(object.counties).forEach(
              (key) =>
                (hasAnySelection = hasAnySelection || object.counties[key]?.selection?.length > 0)
            );
          }

          if (object.cities) {
            Object.keys(object.cities)?.forEach(
              (key) =>
                (hasAnySelection = hasAnySelection || object.cities[key]?.selection?.length > 0)
            );
          }

          if (object.organizations) {
            Object.keys(object.organizations)?.forEach(
              (key) =>
                (hasAnySelection =
                  hasAnySelection || object.organizations[key]?.selection?.length > 0)
            );
          }

          return !!hasAnySelection;
        },
      },
    });
  };
}
