import { IsNotEmpty, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { PaymentType } from '../Payment';

@Entity
export class PaymentCreateForm {
  @Of(() => Number)
  amount: number = 0;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.paymentType.required' })
  type: PaymentType = 'card';

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.type === 'cash')
  @IsNotEmpty({ message: 'errors.receiptNotes.required' })
  notes?: string;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.type === 'cash')
  @IsNotEmpty({ message: 'errors.receiptSeries.required' })
  serial?: string;

  @Of(() => Number, { optional: true })
  @ValidateIf((o) => o.type === 'cash')
  @IsNotEmpty({ message: 'errors.receiptNumber.required' })
  number?: number;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.type === 'cash')
  @IsNotEmpty({ message: 'errors.receiptDate.required' })
  receiptDate?: string;

  static of = Entity.of<PaymentCreateForm>();
}
