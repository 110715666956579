import { Entity, Of } from 'entity-of';

@Entity
export class ProfileSpvToken {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  tenantId: string = '';

  @Of(() => String)
  accountId: string = '';

  @Of(() => Number)
  tenantBillingProfileId: number = 0;

  @Of(() => String)
  accountName: string = '';

  @Of(() => String)
  email: string = '';

  @Of(() => String)
  alias: string = '';

  @Of(() => String)
  createdAt: string = '';

  @Of(() => String)
  expirationDate: string = '';

  @Of(() => Boolean)
  isExpired: boolean = false;

  @Of(() => Boolean)
  isValid: boolean = true;

  static of = Entity.of<ProfileSpvToken>();
}
