import { IsNotEmpty, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { PaymentType } from '../Payment';

@Entity
export class PaymentUpdateForm {
  @Of(() => String)
  type: PaymentType = 'cash';

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.type === 'cash')
  @IsNotEmpty({ message: 'errors.receiptNotes.required' })
  notes?: string;

  static of = Entity.of<PaymentUpdateForm>();
}
