import { useQuery, UseQueryOptions } from 'react-query';

import { AvailablePackagesInput, Package } from '@api/subscriptions/entities';
import { httpClient } from '@lib/http';
import { computeUrlParams } from '@lib/utils';
import { AxiosError } from 'axios';

type QueryInput = AvailablePackagesInput;

const getAvailablePackages = ({ queryKey }: UseQueryOptions<QueryInput>) => {
  const [_, queryPayload] = queryKey ?? [];

  let url = `/subscriptions/packages/available`;

  const payload = {
    ...(queryPayload as QueryInput),
    withDetails: true,
  };

  const params = computeUrlParams(payload);

  if (params) {
    url += `?${params}`;
  }

  return httpClient<{ data: Package[] }>(url).get();
};

export const useAvailablePackagesQuery = (
  queryInput: Partial<QueryInput>,
  options?: UseQueryOptions<{ data: Package[] }, AxiosError>
) => {
  return useQuery<{ data: Package[] }, AxiosError>(
    ['available-packages', queryInput],
    getAvailablePackages,
    options
  );
};
