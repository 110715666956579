import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class PrivateBillingProfileInput {
  @Of(() => String)
  firstName: string = '';

  @Of(() => String)
  lastName: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.address.required' })
  street: string = '';

  @Of(() => String, { optional: true })
  zipCode?: string;

  @Of(() => String)
  country: string = '';

  @Of(() => String)
  county: string = '';

  @Of(() => String)
  city: string = '';

  @Of(() => String)
  countryId: string = '';

  @Of(() => String)
  countyId: string = '';

  @Of(() => String, { optional: true })
  cityId?: string;

  @Of(() => String, { optional: true })
  phone?: string;

  @Of(() => String, { optional: true })
  email?: string;

  @Of(() => Boolean)
  isJuridic: boolean = false;

  @Of(() => String)
  accountId: string = '';

  @Of(() => Boolean)
  isSubscriptions: boolean = false;

  static of = Entity.of<PrivateBillingProfileInput>();
}
