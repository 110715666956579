import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class HolidayInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.holidayName.required' })
  holName: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.date.required' })
  holDate: string = '';

  @Of(() => String)
  holCountryID: string = '';

  static of = Entity.of<HolidayInput>();
}
