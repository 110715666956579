import { omitByEmpty } from '../objects/omitByEmpty';

export const computeUrlParams = (params: Record<string, any>) => {
  const finalParams = omitByEmpty<Record<string, any>>(params);

  Object.keys(finalParams).forEach((key) => {
    if (Array.isArray(finalParams[key]) && !finalParams[key].length) {
      delete finalParams[key];
    }
  });

  if (!Object.keys(finalParams).length) {
    return null;
  }

  return new URLSearchParams(finalParams);
};
