import { Button, Divider, Grid, Text } from '@ui2/components';
import { getOrgTypeTheme, SelectItemProps } from '@ui2/utils';

interface MonthPickerProps {
  value: number;
  months: SelectItemProps<number>[];
  onChangeMonth: (newMonth: number) => void;
}

export const MonthPicker = ({ months, value, onChangeMonth }: MonthPickerProps) => {
  const baseColor = getOrgTypeTheme('blue');

  return (
    <Grid templateColumns="1fr 1fr 1fr" rowGap="4" columnGap="4">
      {months.map((month, idx) => {
        return (
          <Button
            key={idx}
            variant="ghost"
            colorScheme="gray"
            onClick={() => {
              onChangeMonth(month.value);
            }}
            background={value === month.value ? `${baseColor}.500` : 'initial'}
            className={value === month.value ? 'selected-month-year-button' : ''}
            _hover={{ backgroundColor: value === month.value ? `${baseColor}.600` : 'gray.200' }}
          >
            <Text fontSize="md" fontWeight="normal">
              {month.label}
            </Text>
          </Button>
        );
      })}
    </Grid>
  );
};

interface YearPickerProps {
  value: number;
  years: number[];
  onChangeYear: (newYear: number) => void;
  minDate?: Date;
  maxDate?: Date;
  monthPicker?: boolean;
}

export const YearPicker = ({
  years,
  value,
  onChangeYear,
  minDate,
  maxDate,
  monthPicker,
}: YearPickerProps) => {
  const baseColor = getOrgTypeTheme('blue');

  const minYear = minDate ? minDate.getFullYear() : null;
  const maxYear = maxDate ? maxDate.getFullYear() : null;

  return (
    <>
      <Grid templateColumns="1fr 1fr 1fr" rowGap="4" columnGap="4">
        {years.map((year, idx) => {
          const isDisabled = (minYear && year < minYear) || (maxYear && year > maxYear);

          return (
            <Button
              key={idx}
              variant="ghost"
              colorScheme="gray"
              onClick={() => {
                onChangeYear(year);
              }}
              background={value === year ? `${baseColor}.500` : 'initial'}
              className={value === year ? 'selected-month-year-button' : ''}
              _hover={{ backgroundColor: value === year ? `${baseColor}.600` : 'gray.200' }}
              isDisabled={!!isDisabled}
            >
              <Text fontSize="md" fontWeight="normal">
                {year}
              </Text>
            </Button>
          );
        })}
      </Grid>

      {monthPicker && <Divider color="gray.200" mt="4" mb="2" />}
    </>
  );
};

interface MonthSelectorProps {
  label: string;
  openMonthSelector: () => void;
}

export const MonthSelector = ({ label, openMonthSelector }: MonthSelectorProps) => {
  return (
    <Button
      w="full"
      maxW="120px"
      fontSize="md"
      variant="outline"
      aria-label="month"
      fontWeight="semibold"
      colorScheme="gray"
      onClick={openMonthSelector}
    >
      <Text fontWeight="semibold" fontSize="md">
        {label}
      </Text>
    </Button>
  );
};

interface YearSelectorProps {
  label: number;
  openYearSelector: () => void;
}

export const YearSelector = ({ label, openYearSelector }: YearSelectorProps) => {
  return (
    <Button
      w="full"
      maxW="120px"
      aria-label="year"
      variant="outline"
      colorScheme="gray"
      onClick={openYearSelector}
    >
      <Text fontWeight="semibold" fontSize="md">
        {label}
      </Text>
    </Button>
  );
};
