import { ArrayNotEmpty, IsEmail } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class AssociatedProfessor {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  birthGivenName: string = '';

  @Of(() => String)
  @IsEmail({}, { message: 'errors.email_format' })
  universityEmail: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  surname: string = '';

  @Of(() => String)
  discipline: string = '';

  @Of(() => String)
  acronyms: string = '';

  @Of(() => Number)
  disciplineId: number = 0;

  @Of(() => String)
  roles: string = '';

  @Of(() => String)
  years: string = '';

  @Of(() => String)
  semesters: string = '';

  @Of(() => String)
  role: string = '';

  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.roles.required' })
  roleName: string[] = [];

  @Of(() => String)
  department: string = '';

  @Of(() => Number)
  departmentId: number = 0;

  @Of(() => Number)
  mark: number | string = 0;

  @Of(() => String)
  function: string = '';

  @Of(() => Number)
  functionId: number = 0;

  @Of(() => String)
  functionType: string = '';

  @Of(() => Boolean)
  main: boolean = false;

  static of = Entity.of<AssociatedProfessor>();
}
