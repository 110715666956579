import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BoxProps,
  ButtonProps,
  Modal as ModalCK,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps as CKModalProps,
} from '@chakra-ui/react';

import { AdservioIcons, Box, Button, HStack, IconButton, Text, TextProps } from '../../atoms';

const Title = ({ children, ...rest }: PropsWithChildren<TextProps>) => {
  return <Text {...rest}>{children}</Text>;
};

const Subtitle = ({ children, ...rest }: PropsWithChildren<TextProps>) => {
  return (
    <Text fontSize="0.875rem" fontWeight="normal" color="gray.400" {...rest}>
      {children}
    </Text>
  );
};

export interface ModalProps extends Omit<CKModalProps, 'children'> {
  title?: string;
  subtitle?: string;
  hideFooter?: boolean;
  hasLeftMarker?: boolean;
  leftMarkerSx?: BoxProps;
  closeButtonText?: string;
  submitButtonText?: string;
  borderRadius?: string;
  onBack?(): void;
  onCancel?: () => void;
  onSubmit?(...parameters): void;
  renderHeader?(
    Title: React.FC<PropsWithChildren<TextProps>>,
    Subtitle: React.FC<PropsWithChildren<TextProps>>
  ): React.ReactNode;
  renderFooter?: (
    Cancel: React.FC<ButtonProps>,
    Submit: React.FC<ButtonProps>
  ) => React.ReactElement;
  testId?: string;
  blur?: number;
  hideOverlay?: boolean;
  contentProps?: ModalContentProps;
  hideCloseButton?: boolean;
}

export const Modal = ({
  isOpen = false,
  title,
  testId,
  subtitle,
  children,
  hideFooter,
  hasLeftMarker,
  leftMarkerSx,
  closeButtonText,
  submitButtonText,
  borderRadius,
  onBack,
  onClose,
  onSubmit,
  onCancel,
  renderHeader,
  renderFooter,
  contentProps,
  hideCloseButton,
  ...props
}: React.PropsWithChildren<ModalProps>) => {
  const { t } = useTranslation('shared');

  const containersStyles = hasLeftMarker ? { pl: '8' } : {};

  const Cancel = ({ children, ...props }: ButtonProps) => (
    <Button colorScheme="red" variant="outline" mr="4" onClick={onClose} {...props}>
      {children || t('general.cancel')}
    </Button>
  );

  const Submit = ({ children, ...props }: ButtonProps) => (
    <Button onClick={onSubmit} {...props}>
      {children || t('general.confirm')}
    </Button>
  );

  return (
    <ModalCK isOpen={isOpen} isCentered onClose={onClose} {...props}>
      {!props.hideOverlay && <ModalOverlay backdropFilter={`blur(${props.blur ?? 5}px)`} />}

      <ModalContent
        data-testid={testId}
        borderRadius={borderRadius ?? '2xl'}
        overflow={hasLeftMarker ? 'hidden' : 'initial'}
        height={{ base: '-webkit-fill-available', md: 'auto' }}
        {...contentProps}
      >
        {hasLeftMarker && (
          <Box position="absolute" left="0" top="0" height="100%" width="2" {...leftMarkerSx} />
        )}

        {renderHeader ? (
          <ModalHeader {...containersStyles}>{renderHeader(Title, Subtitle)}</ModalHeader>
        ) : (
          title && (
            <ModalHeader {...containersStyles}>
              <HStack spacing={2}>
                {onBack && (
                  <IconButton
                    colorScheme="gray"
                    variant="ghost"
                    aria-label={'back'}
                    onClick={onBack}
                    icon={<AdservioIcons.FiArrowLeft />}
                  />
                )}
                <Title>{title}</Title>
              </HStack>

              {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </ModalHeader>
          )
        )}

        {!hideCloseButton && (
          <ModalCloseButton>
            <AdservioIcons.FiX boxSize="6" onClick={onClose} zIndex="1" />
          </ModalCloseButton>
        )}

        {children && (
          <ModalBody {...containersStyles}>
            <Box mb="4" h="1px" borderBottom="1px solid" borderColor="gray.200" />

            {children}
          </ModalBody>
        )}

        {!hideFooter && (
          <>
            {renderFooter ? (
              <ModalFooter pt="6" justifyContent="space-between" {...containersStyles}>
                {renderFooter?.(Cancel, Submit)}
              </ModalFooter>
            ) : (
              <ModalFooter justifyContent="space-between" {...containersStyles}>
                <Button
                  colorScheme="red"
                  variant="outline"
                  mr="4"
                  onClick={onCancel || onClose}
                  data-testid="modal-cancel-btn"
                >
                  {closeButtonText || t('general.cancel')}
                </Button>

                <Button onClick={onSubmit} data-testid="modal-confirm-btn">
                  {submitButtonText || t('general.confirm')}
                </Button>
              </ModalFooter>
            )}
          </>
        )}
      </ModalContent>
    </ModalCK>
  );
};
