import { IsNotEmpty, Min } from 'class-validator';

export class BulkAssignServiceForm {
  accounts?: string[];

  @IsNotEmpty({ message: 'errors.service.required' })
  serviceId?: string[];

  @IsNotEmpty({ message: 'errors.date.required' })
  date?: Date;

  @Min(1, { message: 'errors.quantity.min' })
  @IsNotEmpty({ message: 'errors.quantity.required' })
  quantity?: number;
}
