import { ORG_UNIVERSITY } from '@constants';
import { useStorageSession } from '@lib/utils';

export const getOrgTypeTheme = (color: string) => {
  const { orgType } = useStorageSession();

  switch (orgType) {
    case ORG_UNIVERSITY:
      switch (color) {
        case 'blue':
          return 'purple';
        case '#E0F2FE':
          return '#F4F3FF';
        default:
          return color;
      }
  }

  return color;
};
