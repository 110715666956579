import { Address } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class OrganisationPackage {
  @Of(() => String)
  id = '';

  @Of(() => String)
  name = '';

  @Of(() => String)
  classification = '';

  @Of(() => Address, { nullable: true, optional: true })
  address?: Address;

  @Of(() => String)
  startDate = '';

  @Of(() => String)
  endDate = '';

  static of = Entity.of<OrganisationPackage>();
}
