import { Entity, Of } from 'entity-of';

@Entity
export class StudentsGradesChartInput {
  @Of(() => String, { optional: true })
  ascID?: string = '';

  @Of(() => String, { optional: true })
  cicluID?: string;

  @Of(() => String, { optional: true })
  sanID?: string;

  @Of(() => String, { optional: true })
  astID?: string;

  @Of(() => String, { optional: true })
  clID?: string;

  static of = Entity.of<StudentsGradesChartInput>();
}
