import { Entity, Of } from 'entity-of';

import { BehaviorType } from './Conduct';

@Entity
export class ConductSummary {
  @Of(() => String)
  cmeTip: BehaviorType = '1';

  @Of(() => String)
  totalPoints: string = '';

  @Of(() => String)
  totalEntries: string = '';

  static of = Entity.of<ConductSummary>();
}
