import { Entity, Of } from 'entity-of';

@Entity
export class TranslatedLanguageItem {
  @Of(() => String)
  langId: string = '';

  @Of(() => String)
  value: string = '';

  static of = Entity.of<TranslatedLanguageItem>();
}
