import {
  IsNotEmpty,
  Validate,
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';
import { Entity, Of } from 'entity-of';
import isNull from 'lodash/isNull';

@ValidatorConstraint({ name: 'isDuplicateActivity', async: false })
export class IsDuplicateActivity implements ValidatorConstraintInterface {
  validate(value: string, args: ValidationArguments) {
    const activity = args.object as SelectActivitiesFormInput;

    const { initialActivities } = activity;
    const result = initialActivities?.find(
      (item) =>
        item.cmcId === activity?.cmcId &&
        item.classId === activity?.classId &&
        item.teacherId === activity?.teacherId &&
        item.subjectId === activity?.subjectId
    );

    if (result) {
      return false;
    }
    return true;
  }
}

@ValidatorConstraint({ name: 'isZeroHoursActivity', async: false })
export class IsZeroHoursActivity implements ValidatorConstraintInterface {
  validate(value: string, args: ValidationArguments) {
    const activity = args.object as SelectActivitiesFormInput;

    const { cmcNrOre, subjectId, teacherId } = activity;

    if ((isNull(cmcNrOre) || Number(cmcNrOre) < 1) && subjectId && teacherId) {
      return false;
    }

    return true;
  }
}

@Entity
export class SelectActivitiesFormInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.required' })
  @Validate(IsDuplicateActivity, {
    message: 'errors.generate_timetable.duplicate',
  })
  classId: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.required' })
  @Validate(IsDuplicateActivity, {
    message: 'errors.generate_timetable.duplicate',
  })
  subjectId: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.required' })
  @Validate(IsDuplicateActivity, {
    message: 'errors.generate_timetable.duplicate',
  })
  @Validate(IsZeroHoursActivity, {
    message: 'errors.generate_timetable.zero_hours',
  })
  teacherId: string = '';

  @Of(() => String)
  cmcId: string = '';

  @Of(() => String)
  cmcNrOre?: string = '';

  initialActivities?: InitialActivities[] = [];

  static of = Entity.of<SelectActivitiesFormInput>();
}

@Entity
export class TimetableTeacher {
  @Of(() => String)
  value: string = '';

  @Of(() => String)
  label: string = '';

  @Of(() => String)
  cmcID: string = '';

  @Of(() => String, { optional: true })
  coprofFor?: string = '';

  @Of(() => String)
  cmcNrOre?: string = '';

  static of = Entity.of<TimetableTeacher>();
}

@Entity
export class InitialActivities {
  @Of(() => String)
  cmcId: string = '';

  @Of(() => String)
  classId: string = '';

  @Of(() => String)
  teacherId: string = '';

  @Of(() => String, { optional: true })
  subjectId: string = '';

  static of = Entity.of<InitialActivities>();
}
