import { CertificateUser } from '@api/core/entities';
import { Type } from 'class-transformer';
import { IsArray, ValidateNested } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class DiplomaFormInput {
  @Of(() => String)
  diplomaType: string = '';

  @Of(() => String)
  diplomaTemplate: string = '';

  @Of(() => String)
  diplomaDate: string = '';

  @Of(() => String)
  headmaster: string = '';

  @Of(() => String)
  teacher: string = '';

  @Of(() => Boolean)
  includeLogo: boolean = false;

  @Of(() => [UserAccountInfo])
  userAccountInfo: UserAccountInfo[] = [];

  @Of(() => String, { optional: true })
  fileNameUnploadInput?: string = '';

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CertificateUser)
  @Of(() => [CertificateUser])
  cerUsers: CertificateUser[] = [];

  @Of(() => Boolean)
  isUserTeacher: boolean = false;

  @Of(() => String)
  classId: string = '';

  @Of(() => String)
  ascID: string = '';

  static of = Entity.of<DiplomaFormInput>();
}

@Entity
export class UserAccountInfo {
  @Of(() => String)
  clID: string = '';

  @Of(() => String)
  uaID: string = '';

  static of = Entity.of<UserAccountInfo>();
}
