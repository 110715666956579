interface FormatterOptions {
  lang?: string;
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

interface FormatCurrencyOptions {
  locale?: string;
  currency?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export const useFormatter = ({
  minimumFractionDigits = 0,
  maximumFractionDigits,
  currency = 'RON',
  lang = 'ro',
}: FormatterOptions) => {
  let locale = 'ro-RO';

  if (typeof window !== 'undefined') {
    locale = window.navigator.language;
  }

  switch (lang) {
    case 'ro':
      locale = 'ro-RO';
      break;
    case 'en':
      locale = 'en-US';
      break;
    case 'az':
      locale = 'az-az';
      break;
    case 'fr':
      locale = 'fr-FR';
      break;
    case 'hu':
      locale = 'hu-HU';
      break;
    case 'nl':
      locale = 'nl-NL';
      break;
    case 'ru':
      locale = 'ru-RU';
      break;
  }

  const formatDate = (
    date?: Date | string | null,
    options?: { showTime: boolean; timeZone?: string }
  ) => {
    if (!date) {
      return 'Not set';
    }

    const dateOptions = getDateOptions(options?.showTime, options?.timeZone);

    if (typeof date === 'string') {
      const d = new Date(date)
        .toLocaleDateString(locale, dateOptions as Intl.DateTimeFormatOptions)
        .toLocaleUpperCase();

      if (d === 'Invalid Date') {
        return date;
      }

      return d;
    }

    return date.toLocaleDateString(locale, dateOptions as Intl.DateTimeFormatOptions);
  };

  const formatCurrency = (price: number = 0, options?: FormatCurrencyOptions) => {
    const priceOptions = {
      style: 'currency',
      currency: options?.currency || currency,
      minimumFractionDigits: options?.minimumFractionDigits ?? minimumFractionDigits,
      maximumFractionDigits,
    };

    return new Intl.NumberFormat(locale, priceOptions as Intl.NumberFormatOptions).format(price);
  };

  const formatAmount = (price: number = 0, options?: FormatCurrencyOptions) => {
    const priceOptions = {
      minimumFractionDigits: options?.minimumFractionDigits ?? minimumFractionDigits,
      maximumFractionDigits,
    };

    return new Intl.NumberFormat(locale, priceOptions as Intl.NumberFormatOptions).format(price);
  };

  const getLocaleDateFormat = (hasTime: boolean = false) => {
    const timeFormat = getLocaleHoursFormat();

    switch (locale) {
      case 'ro-RO':
        return `dd.MM.yyyy${hasTime ? ' ' + timeFormat : ''}`;
      case 'en-US':
        return `M/d/yyyy${hasTime ? ' ' + timeFormat : ''}`;
      case 'az-az':
        return `dd.MM.yyyy${hasTime ? ' ' + timeFormat : ''}`;
      case 'fr-FR':
        return `dd/MM/yyyy${hasTime ? ' ' + timeFormat : ''}`;
      case 'hu-HU':
        return `yyyy. MM. dd.${hasTime ? ' ' + timeFormat : ''}`;
      case 'nl-NL':
        return `d-M-yyyy${hasTime ? ' ' + timeFormat : ''}`;
      case 'ru-RU':
        return `dd.MM.yyyy${hasTime ? ' ' + timeFormat : ''}`;
    }

    return 'dd.MM.yyyy';
  };

  const getLocaleHoursFormat = (hasSeconds: boolean = false) => {
    const timeFormat = 'HH:mm';
    const timeFormatWithSeconds = 'HH:mm:ss';

    const timeFormat24h = 'hh:mm a';
    const timeFormatWithSeconds24h = 'hh:mm:ss a';

    switch (locale) {
      case 'ro-RO':
        return `${hasSeconds ? timeFormatWithSeconds : timeFormat}`;
      case 'en-US':
        return `${hasSeconds ? timeFormatWithSeconds24h : timeFormat24h}`;
      case 'az-az':
        return `${hasSeconds ? timeFormatWithSeconds24h : timeFormat24h}`;
      case 'fr-FR':
        return `${hasSeconds ? timeFormatWithSeconds24h : timeFormat24h}`;
      case 'hu-HU':
        return `${hasSeconds ? timeFormatWithSeconds24h : timeFormat24h}`;
      case 'nl-NL':
        return `${hasSeconds ? timeFormatWithSeconds24h : timeFormat24h}`;
      case 'ru-RU':
        return `${hasSeconds ? timeFormatWithSeconds24h : timeFormat24h}`;
    }

    return timeFormat;
  };

  const getDateOptions = (hasTime = false, timeZone = 'UTC') => {
    const dateOptions: Intl.DateTimeFormatOptions = {};

    const dateTimeFormat = getLocaleDateFormat(hasTime);

    dateOptions.timeZone = timeZone;

    if (dateTimeFormat.includes('yyyy')) {
      dateOptions.year = 'numeric';
    }
    if (dateTimeFormat.includes('MM') || dateTimeFormat.includes('M')) {
      dateOptions.month = '2-digit';
    }
    if (dateTimeFormat.includes('dd') || dateTimeFormat.includes('d')) {
      dateOptions.day = '2-digit';
    }

    if (dateTimeFormat) {
      if (dateTimeFormat.includes('HH') || dateTimeFormat.includes('hh')) {
        dateOptions.hour = '2-digit';
        dateOptions.hour12 = !dateTimeFormat.includes('HH');
      }

      if (dateTimeFormat.includes('mm')) {
        dateOptions.minute = '2-digit';
      }

      if (dateTimeFormat.includes('ss')) {
        dateOptions.second = '2-digit';
      }
    }

    return dateOptions;
  };

  return {
    formatDate,
    formatCurrency,
    formatAmount,
    getLocaleDateFormat,
    getLocaleHoursFormat,
  };
};
