import React from 'react';
import { FormProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  BoxProps,
  Button,
  Modal as ModalCK,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { ValidationErrors } from 'final-form';

import { Ret } from '../../../utils';
import { AdservioIcons } from '../../atoms';
import { Form } from '../../organisms';
import { ModalProps } from '../Modal';

interface FormModalProps extends ModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  isSubmitDisabled?: boolean;
  errors?: ValidationErrors;
  defaultValues?: object;
  title: string;
  subtitle?: string;
  hasLeftMarker?: boolean;
  leftMarkerSx?: BoxProps;
  closeButtonText?: string;
  submitButtonText?: string;
  submitButtonIcon?: React.ReactElement;
  submitButtonRightElement?: React.ReactElement;
  formExtraProps?: Omit<FormProps, 'onSubmit'>;
  onClose(): void;
  onSubmit(data: object): void;
  validator?: (data: any) => Ret;
  renderFooter?: () => React.ReactElement;
  renderTitle?: (title: string) => React.ReactElement;
  relatedErrors?: Record<string, Record<string, string[]>>;
  footerSx?: ModalFooterProps;
}

export const FormModal = ({
  children,
  isOpen = false,
  isLoading,
  isSubmitDisabled = false,
  errors,
  title,
  defaultValues,
  subtitle,
  closeButtonText,
  submitButtonText,
  submitButtonIcon,
  submitButtonRightElement,
  hasLeftMarker,
  leftMarkerSx,
  formExtraProps,
  onClose,
  onSubmit,
  validator,
  renderFooter,
  renderTitle,
  relatedErrors = {},
  blur = 5,
  hideOverlay = false,
  footerSx,
  hideCloseButton = false,
  ...modalProps
}: React.PropsWithChildren<FormModalProps>) => {
  const { t } = useTranslation('shared');

  const containersStyles = hasLeftMarker ? { pl: '8' } : {};

  const Footer = () => {
    if (modalProps.hideFooter) {
      return null;
    }

    if (renderFooter) {
      return renderFooter();
    }

    return (
      <ModalFooter pt="6" pb="6" justifyContent="flex-end" {...containersStyles} {...footerSx}>
        <Button colorScheme="red" variant="outline" mr="4" onClick={onClose} isLoading={isLoading}>
          {closeButtonText || t('general.cancel')}
        </Button>

        <Button
          type="submit"
          isLoading={isLoading}
          isDisabled={isSubmitDisabled}
          leftIcon={submitButtonIcon}
          rightIcon={submitButtonRightElement}
        >
          {submitButtonText || t('general.confirm')}
        </Button>
      </ModalFooter>
    );
  };

  return (
    <ModalCK isOpen={isOpen} isCentered onClose={onClose} {...modalProps}>
      {!hideOverlay && <ModalOverlay backdropFilter={`blur(${blur}px)`} />}
      <Form
        {...formExtraProps}
        onSubmit={onSubmit}
        validate={validator}
        errors={errors}
        relatedErrors={relatedErrors}
        defaultValues={defaultValues}
      >
        <ModalContent borderRadius="2xl" overflow={hasLeftMarker ? 'hidden' : 'initial'}>
          {hasLeftMarker && (
            <Box position="absolute" left="0" top="0" height="100%" width="2" {...leftMarkerSx} />
          )}

          <ModalHeader {...containersStyles}>
            {renderTitle ? renderTitle(title) : <Text>{title}</Text>}
            {subtitle && (
              <Text fontSize="0.875rem" fontWeight="normal" color="gray.400">
                {subtitle}
              </Text>
            )}
          </ModalHeader>

          {!hideCloseButton && (
            <ModalCloseButton>
              <AdservioIcons.FiX boxSize="6" />
            </ModalCloseButton>
          )}

          <ModalBody {...containersStyles}>
            <Box mb="8" h="1px" borderBottom="1px solid" borderColor="gray.200" />

            {children}
          </ModalBody>

          <Footer />
        </ModalContent>
      </Form>
    </ModalCK>
  );
};
