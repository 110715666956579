import { Person, Subject } from '@api/core/entities';
import { ISO_DAYS_TYPE } from '@constants';
import { Entity, Of } from 'entity-of';

import { Classes, WeekType } from '../../classes';
import { LessonPlan } from '../LessonPlan';
import { Slot } from '../Slot';

export enum ActivityType {
  OFFICIAL = 'official',
  EXTRA = 'extra',
}

@Entity
export class TimetableActivity {
  @Of(() => String)
  id: string = '';

  @Of(() => Slot)
  slot: Slot = Slot.of({});

  @Of(() => String, { nullable: true })
  dayID: ISO_DAYS_TYPE | null = null;

  @Of(() => ActivityType)
  type: ActivityType = ActivityType.OFFICIAL;

  @Of(() => Classes)
  class: Classes = Classes.of({});

  @Of(() => TimetableRoom)
  room?: TimetableRoom;

  @Of(() => Person)
  teacher: Person = Person.of({});

  @Of(() => String)
  name?: string;

  @Of(() => WeekType)
  weekType: WeekType = WeekType.ALL;

  @Of(() => Subject)
  subject?: Subject;

  @Of(() => String)
  countAssignedStudents: string = '';

  @Of(() => String)
  countTotalStudents: string = '';

  @Of(() => String)
  cmcID?: string;

  @Of(() => LessonPlan, { nullable: true })
  lessonPlan: LessonPlan | null = null;
  static of = Entity.of<TimetableActivity>();
}

@Entity
class TimetableRoom {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => TimetableBuilding)
  building?: TimetableBuilding;

  static of = Entity.of<TimetableRoom>();
}

@Entity
class TimetableBuilding {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  name: string = '';

  static of = Entity.of<TimetableBuilding>();
}
