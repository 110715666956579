import React, { PropsWithChildren, useState } from 'react';

import { Tooltip as ChakraTooltip, TooltipProps } from '@chakra-ui/react';

import { useOnClickOutside } from '../../../hooks';
import { Box } from '../../atoms';

export type { TooltipProps } from '@chakra-ui/react';

interface Props extends TooltipProps {
  withTouch?: boolean;
}

export const Tooltip = ({ children, withTouch = false, ...props }: PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = React.useRef(null);

  const onClickOutside = () => {
    setIsOpen(false);
  };

  withTouch && useOnClickOutside([ref], onClickOutside);

  return (
    <ChakraTooltip isOpen={withTouch ? isOpen : undefined} {...props}>
      {withTouch ? (
        <Box
          ref={ref}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
          onClick={() => setIsOpen(true)}
        >
          {children}
        </Box>
      ) : (
        children
      )}
    </ChakraTooltip>
  );
};
