import React from 'react';
import { useTranslation } from 'react-i18next';

import { getOrgTypeTheme } from '@ui2/utils';

import { AdservioIcons, FlexProps, HStack, IconButton, IconNames, Text, VStack } from '../../atoms';
import { FeatureIcon, FeatureIconVariant } from '../FeatureIcon';

import { AlertWrapper } from './AlertMessage.theme';

interface AlertMessageProps extends FlexProps {
  status?: FeatureIconVariant;
  title?: string;
  description: React.ReactNode;
  isClosable?: boolean;
  isToast?: boolean;
  onClose?(): void;
  renderContent?(): React.ReactNode;
}

export const AlertMessage = ({
  status,
  title,
  description,
  isClosable,
  isToast,
  onClose,
  renderContent,
  ...rest
}: AlertMessageProps) => {
  const { t } = useTranslation('shared');

  const colorWeight = isToast ? '500' : '50';
  let color = getOrgTypeTheme('blue');
  let borderColor = `${color}.${colorWeight}`;
  let icon: IconNames = 'FiCheckCircle';
  let defaultTitle = 'general.status';

  switch (status) {
    case 'success':
      borderColor = `green.${colorWeight}`;
      color = 'green';
      icon = 'FiCheckCircle';
      defaultTitle = 'general.success';
      break;
    case 'warning':
      borderColor = `orange.${colorWeight}`;
      color = 'orange';
      icon = 'FiAlertTriangle';
      defaultTitle = 'general.warning';
      break;
    case 'error':
      borderColor = `red.${colorWeight}`;
      color = 'red';
      icon = 'FiAlertCircle';
      defaultTitle = 'general.error';
      break;
  }

  return (
    <AlertWrapper borderColor={borderColor} boxShadow="3xl" {...rest}>
      <HStack alignItems="flex-start" mr="3">
        <FeatureIcon size="lg" name={icon} variant={status} />
        <VStack alignItems="flex-start" spacing="4">
          <VStack alignItems="flex-start" spacing="0">
            <Text color={`${color}.500`} fontWeight="semibold">
              {title || t(defaultTitle)}
            </Text>
            <Text flexGrow={1} color="gray.400">
              {description}
            </Text>
          </VStack>
          {renderContent?.()}
        </VStack>
      </HStack>

      {isClosable && (
        <IconButton
          variant="ghost"
          colorScheme={color}
          size="sm"
          icon={<AdservioIcons.FiX boxSize="1.5rem" color="gray.500" />}
          aria-label="Close toast"
          onClick={onClose}
        />
      )}
    </AlertWrapper>
  );
};
