import React, { cloneElement, isValidElement } from 'react';
import { Field } from 'react-final-form';

import { FormControl } from '../FormControl';

export type FormElementProps<T = unknown> = {
  /**
   * If present, will be merged with the Form onChange event
   */
  onChangeCallback?: (value: any) => void;
  /**
   * Required for registering the component with the Form
   */
  name: string;
  /**
   * it renders a `FormLabel` on top
   */
  label?: React.ReactNode | string;
  /**
   * It renders a `FormHelperText` underneath
   */
  helperText?: string;
  /**
   * If true, the form element will be required. This has two side effects:
   *
   * 1. The `FormLabel` will show a required indicator
   * 2. The form element (eg: Input) will have `aria-required` set to `true`
   */
  isRequired?: boolean;
  /**
   * If true, the form element will receive inline layout styles applied accordingly.
   */
  inlineLayout?: boolean;
  formControlProps?: Omit<React.ComponentProps<typeof FormControl>, 'children' | 'name'>;
  showHelperText?: boolean;
  swapLabel?: boolean;
  testId?: string;
  formType?: 'checkbox' | 'radio' | 'select' | 'input';
  errorBehaviour?: ErrorBehaviour;
} & Omit<T, 'isRequired'>;

export enum ErrorBehaviour {
  BLUR = 'blur',
  DIRTY = 'dirty',
}

export const FormElement = ({
  testId,
  name,
  label,
  children,
  isRequired,
  helperText,
  inlineLayout,
  onChangeCallback,
  showHelperText = true,
  swapLabel = false,
  formControlProps,
  formType,
  errorBehaviour = ErrorBehaviour.BLUR,
}: React.PropsWithChildren<FormElementProps>) => {
  if (!isValidElement(children)) return <>{children}</>;

  return (
    <Field name={name}>
      {({ input, meta }) => {
        let hasError = false;

        switch (errorBehaviour) {
          case ErrorBehaviour.DIRTY:
            if (meta.dirty) {
              hasError = !!meta.error;
            }

            break;
          case ErrorBehaviour.BLUR:
            if (meta.touched) {
              hasError = !!meta.error;
            }

            break;
        }

        return (
          <FormControl
            testId={testId}
            name={name}
            label={label}
            isRequired={isRequired}
            helperText={helperText}
            inlineLayout={inlineLayout}
            swapLabel={swapLabel}
            showHelperText={showHelperText}
            error={hasError && meta.error}
            {...formControlProps}
            formType={formType}
          >
            {cloneElement<any>(children, {
              ...input,
              onChange: (v) => {
                input?.onChange?.(v);
                onChangeCallback?.(v);
              },
              isInvalid: hasError,
            })}
          </FormControl>
        );
      }}
    </Field>
  );
};
