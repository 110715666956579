import { useTranslation } from 'react-i18next';

import { CONFIG_FLAGS, PERM_SUBSCRIPTION_CLIENT } from '@constants';
import {
  getConfigFlagValue,
  isSubscriptionRequired,
  organisationHasFeatures,
  useHasAccess,
  useStorageSession,
} from '@lib/utils';
import { AdservioIcons, AppLink, Grid } from '@ui2/components';

import { MenuItemDivider, UserMenuItem } from '../UserMenu/UserMenu.layout';

interface GenericItemsProps {
  isPHP?: boolean;
}

export const GenericItems = ({ isPHP }: GenericItemsProps) => {
  const { t } = useTranslation(['Site', 'common']);

  const { session, schoolConfig, profile, permissions, features } = useStorageSession();

  const hasDivider =
    (permissions.includes(PERM_SUBSCRIPTION_CLIENT) &&
      useHasAccess({ configFlags: [CONFIG_FLAGS.SUBSCRIPTIONS_ACTIVE] }) &&
      organisationHasFeatures()) ||
    (schoolConfig &&
      !isSubscriptionRequired(
        getConfigFlagValue(session, CONFIG_FLAGS.SUBSCRIPTIONS_ACTIVE),
        profile?.uaTip,
        features
      ));

  return (
    <>
      <Grid templateColumns="1fr" rowGap="2">
        {permissions.includes(PERM_SUBSCRIPTION_CLIENT) &&
          useHasAccess({ configFlags: [CONFIG_FLAGS.SUBSCRIPTIONS_ACTIVE] }) &&
          organisationHasFeatures() && (
            <AppLink href={`subscriptions/my-subscription/package-plan`} isNative={isPHP}>
              <UserMenuItem icon={<AdservioIcons.Diamond />}>
                {t('header.subscription')}
              </UserMenuItem>
            </AppLink>
          )}

        {schoolConfig &&
          !isSubscriptionRequired(
            getConfigFlagValue(session, CONFIG_FLAGS.SUBSCRIPTIONS_ACTIVE),
            profile?.uaTip,
            features
          ) && (
            <>
              <AppLink href={`preferences/security`} isNative={isPHP}>
                <UserMenuItem icon={<AdservioIcons.FiShield />}>
                  {t('header.securitate')}
                </UserMenuItem>
              </AppLink>

              <AppLink href={`preferences/general`} isNative={isPHP}>
                <UserMenuItem icon={<AdservioIcons.FiSettings />}>
                  {t('header.preferinte')}
                </UserMenuItem>
              </AppLink>
            </>
          )}
      </Grid>

      {hasDivider && <MenuItemDivider />}
    </>
  );
};
