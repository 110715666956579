import { Entity, Of } from 'entity-of';

@Entity
export class BulkAssignPackageInput {
  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  @Of(() => String)
  recurrence: string = 'monthly';

  @Of(() => [String])
  organizationIds: string[] = [];

  static of = Entity.of<BulkAssignPackageInput>();
}
