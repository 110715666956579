import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageItem } from '@api/core/entities';
import { useStorageSession } from '@lib/utils';
import { getOrgTypeTheme } from '@ui2/utils';

import { useDisclosure } from '../../../hooks';
import {
  AdservioIcons,
  Divider,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
} from '../../atoms';
import { FormControl, FormLabel } from '../FormElements';
import { Modal } from '../Modal';

type LangData = {
  langId: string;
  value: string;
};

export interface LangInputProps extends Omit<InputGroupProps, 'value' | 'onChange'> {
  value?: LangData[];
  onChange?: (value: LangData[]) => void;
  languages?: LanguageItem[];
  showBackButton?: boolean;
}

export const LangInput = forwardRef<HTMLInputElement, LangInputProps>(
  ({ value = [], onChange, languages, showBackButton, ...props }, ref) => {
    const { t } = useTranslation('shared');
    const { isOpen, onClose, onOpen } = useDisclosure({ id: 'lang-modal' });

    const baseColor = getOrgTypeTheme('blue');

    const { schoolConfig } = useStorageSession();
    const defaultLanguage = schoolConfig.defaultLanguage ?? 'ro';

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange?.(
        (() => {
          const index = (value || []).findIndex((i) => i.langId === e.target.name);

          if (index === -1 || !value) {
            return [...value, { langId: e.target.name, value: e.target.value }];
          }

          return value.map((i) => {
            if (i.langId === e.target.name) {
              return { ...i, value: e.target.value };
            }

            return i;
          });
        })()
      );
    };

    return (
      <>
        <InputGroup
          ref={ref}
          autoComplete="off"
          value={
            (Array.isArray(value) ? value : []).find((i) => i.langId === defaultLanguage)?.value ??
            ''
          }
          onChange={handleChange}
          rightElement={
            languages?.length ? (
              <>
                <Divider
                  position="relative"
                  orientation="vertical"
                  height="65%"
                  borderColor="gray.200"
                  right={'0.45rem'}
                />
                <IconButton
                  size="sm"
                  variant="ghost"
                  colorScheme={baseColor}
                  onClick={onOpen}
                  aria-label="toggle i18n options"
                  icon={<AdservioIcons.FiGlobe color={`${baseColor}.500`} />}
                  mr="3"
                />
              </>
            ) : (
              <></>
            )
          }
          rightElementSx={{
            zIndex: '1',
          }}
          {...props}
          name={defaultLanguage}
        />

        {isOpen && (
          <Modal
            isOpen
            size="3xl"
            isCentered
            onBack={showBackButton ? onClose : undefined}
            blockScrollOnMount={false}
            title={t('general.translations')}
            onClose={onClose}
            onSubmit={onClose}
            closeButtonText={t('general.close')}
          >
            <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} columnGap="4" rowGap="4">
              {languages
                ?.filter((i) => i.langID.toLowerCase() !== defaultLanguage.toLowerCase())
                ?.filter((i) => i.langID.toLowerCase() !== defaultLanguage.toLowerCase())
                .map((lang) => {
                  return (
                    <FormControl
                      key={lang.langID}
                      name={lang.langID}
                      showHelperText={false}
                      w="full"
                    >
                      <FormLabel textAlign="left" htmlFor={lang.langID} mb="1">
                        {t(`languages.${lang.langID}`)}
                      </FormLabel>

                      <Input
                        name={lang.langID}
                        onChange={handleChange}
                        placeholder={t('general.type_here')}
                        value={
                          (Array.isArray(value) ? value : []).find((i) => i.langId === lang.langID)
                            ?.value ?? ''
                        }
                        maxLength={80}
                      />
                    </FormControl>
                  );
                })}
            </Grid>
          </Modal>
        )}
      </>
    );
  }
);
