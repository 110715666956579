import { Entity, Of } from 'entity-of';

@Entity
export class SearchWarningInput {
  @Of(() => String, { optional: true })
  elevID?: string;

  @Of(() => String, { optional: true })
  ascID?: string;

  @Of(() => String, { optional: true })
  clID?: string;

  @Of(() => String, { optional: true })
  avtID?: string;

  @Of(() => String, { optional: true })
  avtShowInReports?: string;

  @Of(() => String, { optional: true })
  q?: string;

  static of = Entity.of<SearchWarningInput>();
}
