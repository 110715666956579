import { WellbeingStateType } from '@modules/wellbeing/domains/wellbeing/views/shared/helpers';
import { Entity, Of } from 'entity-of';

@Entity
export class WellbeingState {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  date: string = '';

  @Of(() => Number)
  type: WellbeingStateType = 0;

  @Of(() => [String])
  tags: string[] = [];

  @Of(() => String)
  details: string = '';

  @Of(() => String)
  updated_at: string = '';

  static of = Entity.of<WellbeingState>();
}
