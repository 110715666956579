import { ReactElement } from 'react';

import {
  EndpointOptions,
  FileUpload2,
  FormElement,
  FormElementProps,
  useForm,
} from '@ui2/components';
import { AxiosError } from 'axios';
import { ValidationErrors } from 'final-form';

const apiBaseUrl = window.env?.V2_PUBLIC_API_BASE_URL || '';

interface FormFileUploaderProps {
  createErrors: (e: AxiosError, options, mapping) => void;
  setIsLoading?: (isLoading: boolean) => void;
  onSuccessfulUpload: (fsID: string) => void;
  renderEmptyState?: () => ReactElement;
  endpointOptions?: EndpointOptions;
  onUploadStart?: () => void;
  hideRemoveButton?: boolean;
  hideProgressBar?: boolean;
  clearErrors: () => void;
  showDownload?: boolean;
  isDisabled?: boolean;
  description?: string;
  onRemove: () => void;
  apiBaseUrl?: string;
  accept?: string;
  multiple?: boolean;
  maxSize?: number;
  abortAllFilesUpload?: boolean;
}

export const FormFileUploader = ({
  onSuccessfulUpload,
  onChangeCallback,
  formControlProps,
  renderEmptyState,
  hideRemoveButton,
  hideProgressBar,
  endpointOptions,
  onUploadStart,
  inlineLayout,
  setIsLoading,
  showDownload,
  createErrors,
  clearErrors,
  description,
  isDisabled,
  helperText,
  isRequired,
  onRemove,
  accept,
  label,
  name,
  multiple,
  maxSize,
  abortAllFilesUpload,
}: FormElementProps<FormFileUploaderProps>) => {
  const { getFieldState } = useForm();
  const fieldState = getFieldState(name);
  const hasErrors = fieldState?.touched && fieldState?.error;

  const errorMapping = (errors: ValidationErrors) => {
    const err: ValidationErrors = {};
    Object.entries(errors || {}).map(([_, value]) => {
      err[name] = value;
    });
    return err;
  };

  const handleError = (e) => {
    createErrors(
      e,
      {
        endpoint: 'fisiere.upload',
      },
      errorMapping
    );
  };

  const handleRemove = () => {
    onRemove();
    clearErrors();
  };

  return (
    <FormElement
      {...{ name, helperText, isRequired, inlineLayout, label, onChangeCallback, formControlProps }}
    >
      <FileUpload2
        renderEmptyState={renderEmptyState}
        hideRemoveButton={hideRemoveButton}
        defaultValue={fieldState?.initial}
        endpointOptions={endpointOptions}
        hideProgressBar={hideProgressBar}
        onSuccess={onSuccessfulUpload}
        onUploadStart={onUploadStart}
        onChange={onChangeCallback}
        setIsLoading={setIsLoading}
        showDownload={showDownload}
        description={description}
        onRemove={handleRemove}
        apiBaseUrl={apiBaseUrl}
        isDisabled={isDisabled}
        onError={handleError}
        hasErrors={hasErrors}
        accept={accept}
        name={name}
        multiple={multiple}
        maxSize={maxSize}
        abortAllFilesUpload={abortAllFilesUpload}
      />
    </FormElement>
  );
};
