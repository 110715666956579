// @ts-nocheck
import React from 'react';

import PropTypes from 'prop-types';

import './Loader.less';

const Loader = ({ color, size, inline }) => (
  <div className={`comp-loader ${color} ${size} ${inline ? 'inline' : ''}`} />
);

Loader.propTypes = {
  /** marimea loader-ului */
  size: PropTypes.oneOf(['xm', 'sm', 'md', 'lg']),
  /** culoarea loader-ului */
  color: PropTypes.oneOf(['light', 'dark']),
  inline: PropTypes.bool,
};

Loader.defaultProps = {
  size: 'md',
  color: 'light',
  inline: false,
};

export default Loader;
