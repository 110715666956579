import React, { PropsWithChildren } from 'react';

import { Box, Text } from '@ui2/components';

export const EmptyFallbackWrapper = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height={{ base: 'initial', md: 'calc(min(100svh, 100vh) - 300px)' }}
      w="full"
    >
      {children}
    </Box>
  );
};

interface EmptyFallbackTitleProps {
  title: string;
  mb: string;
}

export const EmptyFallbackTitle = ({ title, mb }: EmptyFallbackTitleProps) => {
  return (
    <Text
      data-testid="empty-fallback-title"
      mb={mb}
      fontWeight="bold"
      fontSize={{ base: 'lg', md: 'xl' }}
      color="gray.600"
      textAlign="center"
    >
      {title}
    </Text>
  );
};
