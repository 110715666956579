import { Entity, Of } from 'entity-of';

@Entity
export class ScholarshipsReportInput {
  @Of(() => String)
  ascID: string = '';

  @Of(() => String, { optional: true })
  sanID?: string;

  @Of(() => String, { optional: true })
  cicluID?: string;

  @Of(() => String, { optional: true })
  astID?: string;

  @Of(() => String, { optional: true })
  clID?: string;

  @Of(() => String, { optional: true })
  dateStart?: string;

  @Of(() => String, { optional: true })
  dateEnd?: string;

  @Of(() => Number, { optional: true })
  _limit?: number;

  @Of(() => Number, { optional: true })
  _offset?: number;

  @Of(() => String, { optional: true })
  _order?: string;

  static of = Entity.of<ScholarshipsReportInput>();
}
