import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class TimeOffCreateInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.time_off.type.required' })
  type: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.time_off.cadID.required' })
  cadID: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.startDate.required' })
  startDate: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.endDate.required' })
  endDate: string = '';

  @Of(() => String)
  description: string = '';

  static of = Entity.of<TimeOffCreateInput>();
}
