import { Entity, Of } from 'entity-of';

@Entity
export class Absence {
  @Of(() => String)
  absData = '';

  @Of(() => String)
  unmotivatedCount = '';

  @Of(() => Number)
  motivatedCount = '';

  static of = Entity.of<Absence>();
}
