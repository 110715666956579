import { VatCategory, VatExemptionCode, zeroVatCategories } from '@modules/shared/utils/eFactura';
import { IsNotEmpty, Min, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class VatRateInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.value.required' })
  name: string = '';

  @Of(() => Number, { nullable: true })
  @IsNotEmpty({ message: 'errors.value.required' })
  @ValidateIf((o) => !!o.category && !zeroVatCategories.includes(o.category))
  @Min(0.1, { message: 'errors.vatRate.min' })
  value: number | null = null;

  @Of(() => VatCategory, { optional: true })
  @ValidateIf((o) => o.eFacturaActive)
  @IsNotEmpty({ message: 'errors.value.required' })
  category?: VatCategory;

  @Of(() => VatExemptionCode, { optional: true })
  @ValidateIf(
    (o) => !!o.category && zeroVatCategories.includes(o.category) && o.category !== VatCategory.Z
  )
  @IsNotEmpty({ message: 'errors.value.required' })
  exemptionCode?: VatExemptionCode;

  @Of(() => String, { optional: true })
  @ValidateIf(
    (o) =>
      !!o.category &&
      ![VatCategory.O, VatCategory.Z].includes(o.category) &&
      zeroVatCategories.includes(o.category)
  )
  @IsNotEmpty({ message: 'errors.value.required' })
  exemptionReason?: string;

  @Of(() => Boolean)
  eFacturaActive?: boolean;

  static of = Entity.of<VatRateInput>();
}
