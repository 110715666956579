import { Entity, Of } from 'entity-of';

@Entity
export class PackageFeature {
  @Of(() => String)
  id: string = '';

  @Of(() => Number, { nullable: true })
  value: number | boolean | null = null;

  static of = Entity.of<PackageFeature>();
}
