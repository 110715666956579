import { Entity, Of } from 'entity-of';

@Entity
export class StudentsSituationSearchInput {
  @Of(() => String)
  elevID = '';

  @Of(() => String)
  clID = '';

  @Of(() => Number, { optional: true })
  cuScutire?: number;

  @Of(() => Number, { optional: true })
  cuNote?: number;

  @Of(() => Number, { optional: true })
  cuAbsente?: number;

  @Of(() => Number, { optional: true })
  cuMedii?: number;

  @Of(() => String, { optional: true })
  sanID?: string = '';

  @Of(() => [String], { optional: true })
  sanIDs?: string[] = [];

  static of = Entity.of<StudentsSituationSearchInput>();
}
