import { ArrayNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class SelectTeachersFormInput {
  @Of(() => String)
  @ArrayNotEmpty({ message: 'errors.required' })
  teachers: string[] = [];

  static of = Entity.of<SelectTeachersFormInput>();
}
