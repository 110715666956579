import { Entity, Of } from 'entity-of';

@Entity
export class Environment {
  @Of(() => String)
  rural?: string;

  @Of(() => String)
  urban?: string;

  @Of(() => String)
  other?: string;

  @Of(() => String)
  total?: string;

  static of = Entity.of<Environment>();
}
