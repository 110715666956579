import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input, InputProps } from '../../../atoms/Input';
import { FormElement, FormElementProps } from '../FormElement';

export const FormInput = ({
  testId,
  name,
  label,
  isRequired,
  helperText,
  showHelperText,
  inlineLayout,
  errorBehaviour,
  onChangeCallback,
  formControlProps,
  ...props
}: React.PropsWithChildren<FormElementProps<InputProps>>) => {
  const { t } = useTranslation('shared');

  return (
    <FormElement
      {...{
        name,
        label,
        isRequired,
        helperText,
        onChangeCallback,
        showHelperText,
        testId,
        inlineLayout,
        errorBehaviour,
        formControlProps,
      }}
    >
      <Input placeholder={t('general.type_here_ellipsis') ?? ''} {...props} />
    </FormElement>
  );
};
