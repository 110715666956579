import { forwardRef } from 'react';

import { TimePicker, TimePickerProps } from '../../TimePicker';
import { FormElement, FormElementProps } from '../FormElement';

export interface FormTimePickerProps extends FormElementProps<TimePickerProps> {
  withManualInput?: boolean;
  hours?: string[];
  minutes?: string[];
}

export const FormTimePicker = forwardRef<HTMLInputElement, FormTimePickerProps>(
  (
    {
      testId,
      name,
      label,
      isRequired,
      helperText,
      showHelperText,
      inlineLayout,
      errorBehaviour,
      onChangeCallback,
      ...props
    },
    ref
  ) => {
    return (
      <FormElement
        {...{
          name,
          label,
          isRequired,
          helperText,
          showHelperText,
          testId,
          inlineLayout,
          errorBehaviour,
          onChangeCallback,
        }}
      >
        <TimePicker ref={ref} {...props} />
      </FormElement>
    );
  }
);
