import { extendTheme } from '@chakra-ui/react';
import { getOrgTypeTheme } from '@ui2/utils';

import {
  AvatarTheme as Avatar,
  BadgeTheme as Badge,
  ButtonTheme as Button,
  CheckboxTheme as Checkbox,
  ContainerTheme as Container,
  InputTheme as Input,
  MenuTheme as Menu,
  ModalTheme as Modal,
  NumberInputTheme as NumberInput,
  RadioTheme as Radio,
  TableElementsTheme as Table,
  TabsTheme as Tabs,
  TextareaTheme as Textarea,
  TooltipTheme as Tooltip,
} from '../components';

import { breakpoints, colors, shadows } from './variables';

const baseColor = getOrgTypeTheme('blue');

const primaryShadowOutline = (() => {
  switch (baseColor) {
    case 'purple':
      return '0 0 0 3px rgb(171, 152, 243, 0.6)';
    default:
      return '0 0 0 3px rgba(66, 153, 225, 0.6)';
  }
})();

export const theme = extendTheme({
  fonts: {
    body: "'Nunito', sans-serif",
    heading: "'Nunito', sans-serif",
  },
  components: {
    Avatar,
    Badge,
    Button,
    Tabs,
    Input,
    Menu,
    Radio,
    Checkbox,
    Table,
    Container,
    NumberInput,
    Modal,
    Textarea,
    Tooltip,
    // TODO Move this in a separate theme file
    Text: {
      baseStyle: {
        color: 'gray.700',
      },
    },
  },
  shadows,
  breakpoints,
  colors,
  styles: {
    global: {
      ':root': {
        '--primary-shadows-outline': primaryShadowOutline,
        '--primary-50': `var(--chakra-colors-${baseColor}-50)`,
        '--primary-100': `var(--chakra-colors-${baseColor}-100)`,
        '--primary-200': `var(--chakra-colors-${baseColor}-200)`,
        '--primary-300': `var(--chakra-colors-${baseColor}-300)`,
        '--primary-400': `var(--chakra-colors-${baseColor}-400)`,
        '--primary-500': `var(--chakra-colors-${baseColor}-500)`,
        '--primary-600': `var(--chakra-colors-${baseColor}-600)`,
        '--primary-700': `var(--chakra-colors-${baseColor}-700)`,
        '--primary-800': `var(--chakra-colors-${baseColor}-800)`,
        '--primary-900': `var(--chakra-colors-${baseColor}-900)`,
      },
      'html, body': {
        backgroundColor: 'background',
        color: 'gray.900',
      },
      '.rfs-control-container:hover': {
        borderColor: `${baseColor}.500`,
      },
      '.rfs-select-container:not(#no-label-select) .rfs-control-container > div > div': {
        top: 'calc(1.125rem - 1px)',
        transform: 'initial',
      },
      '.rfs-select-container#no-label-select .rfs-control-container': {
        minHeight: '32px',
      },
      '.react-datepicker-wrapper': {
        width: '100%',
      },
      '.react-datepicker__input-container': {
        zIndex: 0,
      },
      'button.chakra-button[aria-label="Switch to dark mode"]': {
        display: 'none',
      },
      '.sync-select-container .chakra__control[data-focus]': {
        zIndex: 'initial',
      },
      '.sync-select-container .chakra__placeholder': {
        pointerEvents: 'none',
      },
      '.collapse-overflow-init .chakra-collapse': {
        overflow: 'initial !important',
      },
    },
  },
});
