import React, { forwardRef } from 'react';

import { OneTimePasswordInput, OneTimePasswordInputProps } from '../..';
import { FormElement, FormElementProps } from '../FormElement';

export interface FormOneTimePasswordInputProps extends FormElementProps<OneTimePasswordInputProps> {
  length?: number;
  size?: 'sm' | 'md' | 'lg';
  withInitialFocus?: boolean;
}

export const FormOneTimePasswordInput = forwardRef<HTMLInputElement, FormOneTimePasswordInputProps>(
  (
    { testId, name, label, isRequired, helperText, showHelperText, errorBehaviour, ...props },
    ref
  ) => {
    return (
      <FormElement
        {...{ name, label, isRequired, helperText, showHelperText, errorBehaviour, testId }}
      >
        <OneTimePasswordInput ref={ref} {...props} />
      </FormElement>
    );
  }
);
