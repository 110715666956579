import { Entity, Of } from 'entity-of';

@Entity
export class StudentType {
  @Of(() => Number)
  studentTypeId: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  code: string = '';

  static of = Entity.of<StudentType>();
}

@Entity
export class StudentTypeResponse {
  @Of(() => [StudentType])
  result: StudentType[] = [];

  static of = Entity.of<StudentTypeResponse>();
}
