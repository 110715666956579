import { Filters, Order } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class FacultiesSearchPayload {
  @Of(() => String)
  orgId: string = '';

  @Of(() => Number, { optional: true })
  offset?: number;

  @Of(() => Number, { optional: true })
  number?: number;

  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => String)
  type: string = 'FACULTATE';

  @Of(() => [Filters], { optional: true })
  filters?: Filters[];

  @Of(() => [Order], { optional: true })
  order?: Order[];

  static of = Entity.of<FacultiesSearchPayload>();
}
