import { ComponentStyleConfig } from '@chakra-ui/react';

export const BadgeTheme: ComponentStyleConfig = {
  defaultProps: {
    size: 'sm',
  },
  baseStyle: {
    borderRadius: 'full',
    textTransform: 'initial',
    fontWeight: 'semibold',
  },
  sizes: {
    xs: {
      px: '2',
    },
    sm: {
      px: '2',
      py: '1',
      fontSize: 'xs',
    },
    md: {
      px: '2',
      py: '0.5',
      fontSize: 'sm',
    },
    lg: {
      px: '3',
      py: '2',
      fontSize: 'md',
    },
  },
  variants: {
    subtle: ({ colorScheme }) => ({
      color: `${colorScheme}.700`,
    }),
  },
};
