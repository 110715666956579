import { Entity, Of } from 'entity-of';

@Entity
export class TimetableSlots {
  @Of(() => [String])
  days: string[] = [];

  @Of(() => [String])
  startHours: string[] = [];

  static of = Entity.of<TimetableSlots>();
}
