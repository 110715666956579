import { TranslatedLanguageItem } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

import { PricingModel, SubscriberType } from '../Package';

@Entity
export class Subscription {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  assignedServiceId: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  internalReference: string = '';

  @Of(() => String)
  nextBillingDate: string = '';

  @Of(() => Number)
  price: number = 0;

  @Of(() => Number)
  licensesLimit: number = 0;

  @Of(() => String)
  subscriberType: SubscriberType = SubscriberType.STUDENT;

  @Of(() => PricingModel)
  pricingModel: PricingModel = PricingModel.FIXED;

  @Of(() => String)
  startDate: string = '';

  @Of(() => Boolean)
  isCancelable: boolean = false;

  @Of(() => String)
  endDate: string = '';

  @Of(() => String)
  recurrence: 'monthly' | 'yearly' = 'monthly';

  @Of(() => String)
  billingProfileId: string = '';

  @Of(() => String)
  tenantBillingProfile: string = '';

  @Of(() => Boolean)
  isDiscount: boolean = false;

  @Of(() => Boolean)
  isImplicit: boolean = false;

  @Of(() => String)
  currencyCode: string = '';

  @Of(() => String)
  packageId: string = '';

  @Of(() => String)
  organisationId: string = '';

  @Of(() => [TranslatedLanguageItem])
  packageName: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  packageDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  priceLabel: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  priceDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  featuresDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature1: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature2: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature3: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature4: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature5: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  promoBannerDescription: TranslatedLanguageItem[] = [];

  @Of(() => Boolean)
  isTrial = false;

  @Of(() => Number)
  packageOrder: number = 0;

  static of = Entity.of<Subscription>();
}
