import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class TimetableInformationInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.name.required' })
  name: string = '';

  static of = Entity.of<TimetableInformationInput>();
}
