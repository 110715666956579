import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class SearchSchoolsInput extends SimpleQueryInput {
  @Of(() => String)
  search?: string = '';

  @Of(() => [String])
  taraID?: string[] = [];

  @Of(() => [String])
  judID?: string[] = [];

  @Of(() => [String])
  cityID?: string[] = [];

  @Of(() => String)
  liceuID?: string = '';

  @Of(() => String, { optional: true })
  liceuOrgType?: string;

  @Of(() => String, { optional: true })
  withLabels?: string;

  @Of(() => String, { optional: true })
  liceuTip?: string;

  @Of(() => String, { optional: true })
  labels?: string;

  @Of(() => String, { optional: true })
  liceuIsActive?: string;

  @Of(() => String, { optional: true })
  liceuIsHidden?: string;

  @Of(() => String, { optional: true })
  liceuInsertDate?: string;

  @Of(() => String, { optional: true })
  withInactiveSchools?: string;

  static of = Entity.of<SearchSchoolsInput>();
}
