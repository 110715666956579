import { Entity, Of } from 'entity-of';

import { PersonClass } from '../../classes';
import { Slot } from '../Slot';

@Entity
export class Schedule {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => [String])
  days: string[] = [];

  @Of(() => String)
  startHour: string = '';

  @Of(() => [String])
  startHours: string[] = [];

  @Of(() => String)
  endHour: string = '';

  @Of(() => String)
  countClasses: string = '';

  @Of(() => [Slot])
  slots: Slot[] = [];

  @Of(() => [PersonClass])
  classes: PersonClass[] = [];

  static of = Entity.of<Schedule>();
}
