import { Entity, Of } from 'entity-of';

@Entity
export class ClassEditPayload {
  @Of(() => Number)
  clID: number = 0;

  @Of(() => Number)
  astID: Number = 0;

  @Of(() => String)
  clLitera: string = '';

  @Of(() => Number)
  ctID: Number = 0;

  @Of(() => Number)
  spID: number = 0;

  @Of(() => String, { optional: true })
  clTrainingField?: string;

  @Of(() => String, { optional: true })
  clProfile?: string;

  @Of(() => String, { optional: true })
  clBranch?: string;

  @Of(() => String, { optional: true })
  clProfessionalQualification?: string;

  @Of(() => String, { optional: true })
  clQualificationLevel?: string;

  @Of(() => Number, { optional: true })
  clRoomID?: number;

  @Of(() => [String])
  coordinatorIds: string[] = [];

  static of = Entity.of<ClassEditPayload>();
}
