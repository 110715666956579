import { useTranslation } from 'react-i18next';

import {
  CONFIG_FLAGS,
  PERM_LIBRARY_MANAGER,
  PERM_LIBRARY_VIEW,
  PERM_MANAGER_ERASMUS,
} from '@constants';
import { LinkButtonItem, LinkButtonsGroup } from '@modules/shared';

export const useUniversitiesTeacherLinkGroups = () => {
  const { t } = useTranslation(['Site', 'common']);

  const groups: LinkButtonsGroup[] = [
    {
      items: [
        LinkButtonItem.of({
          icon: 'FiHome',
          label: t('buttons_mm.dashboard'),
          url: 'dashboard',
          matchingUrls: [
            'secretary/configuration',
            'secretary/configuration/information',
            'secretary/configuration/notification',
            'secretary/configuration/study_years',
            'secretary/configuration/catalog',
            'configuration/roles-and-permissions',
            'secretary/configuration/messages',
            'secretary/configuration/behavior',
            'secretary/configuration/groups',
            'secretary/configuration/homework',
            'secretary/configuration/activities_staff',
            'secretary/config/forms',
            'secretary/configuration/observations',
            'secretary/configuration/schedule',
            'secretary/configuration/files',
            'secretary/configuration/iq_tests',
            'secretary/configuration/library',
            'secretary/configuration/photos',
            'secretary/configuration/admittance',
            'secretary/configuration/edumarket',
            'secretary/configuration/neuron_english',
            'secretary/configuration/news',
            'secretary/configuration/teacher_history',
            'universities/configuration',
          ],
        }),

        LinkButtonItem.of({
          icon: 'ReportCard',
          label: t('buttons_mm.educational_plan'),
          url: 'universities/educational-plan',
          matchingUrls: ['educational-plan'],
        }),

        LinkButtonItem.of({
          icon: 'Gradebook',
          label: t('buttons_mm.catalog'),
          url: 'universities/catalog',
          matchingUrls: [
            'universities/catalog',
            'universities/catalog/student',
            'universities/catalog/grades',
            'universities/catalog/reexamination',
            'universities/catalog/academic',
            'universities/catalog/absences',
            'universities/catalog/promotion',
          ],
        }),

        LinkButtonItem.of({
          icon: 'FiGlobe',
          label: t('buttons_mm.erasmus'),
          url: 'universities/erasmus-program',
          matchingUrls: ['universities/erasmus-program-approvals'],
          accessRules: {
            permissions: [PERM_MANAGER_ERASMUS],
          },
        }),
      ],
    },
    {
      emptyTitle: true,
      items: [
        LinkButtonItem.of({
          icon: 'Portofolio',
          label: t('buttons_mm.academic_profile'),
          url: 'universities/profile-academic',
        }),

        LinkButtonItem.of({
          icon: 'FiFile',
          label: t('buttons_mm.fisiere'),
          url: `files`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.FILES_ACTIVE],
          },
        }),

        LinkButtonItem.of({
          icon: 'Library',
          matchingUrls: ['universities/library/manage', 'universities/library/book'],
          label: t('buttons_mm.biblioteca'),
          url: 'universities/library',
          accessRules: {
            permissions: [PERM_LIBRARY_MANAGER, PERM_LIBRARY_VIEW],
            configFlags: [CONFIG_FLAGS.LIBRARY_ACTIVE],
          },
        }),
      ],
    },
  ];

  return {
    groups,
  };
};
