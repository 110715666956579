import { Entity, Of } from 'entity-of';

import 'reflect-metadata';

import { EntityTypeRules } from '../../TimetableWizardRules';

export type CreateTimetableWizardRulesInput = CreateClassRulesInput | CreateTeacherRulesInput;

// ------------ CLASS ------------
@Entity
export class CreateClassRulesInput {
  @Of(() => String)
  entityType: EntityTypeRules | '' = '';

  @Of(() => String, { optional: true })
  MIN_DAILY_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  MAX_DAILY_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  MAX_GAPS_PER_WEEK: string = '';

  @Of(() => [ClassEntityTypes])
  entities: ClassEntityTypes[] = [];

  static of = Entity.of<CreateClassRulesInput>();
}

@Entity
export class ClassEntityTypes {
  @Of(() => String)
  entityID: string = '';

  @Of(() => String, { optional: true })
  MIN_DAILY_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  MAX_DAILY_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  MAX_GAPS_PER_WEEK: string = '';

  @Of(() => [GeneralUnavailabilityEntity])
  unavailabilities: GeneralUnavailabilityEntity[] = [];

  static of = Entity.of<ClassEntityTypes>();
}

@Entity
export class GeneralUnavailabilityEntity {
  @Of(() => String, { optional: true })
  dayID: string = '';

  @Of(() => String, { optional: true })
  startHour: string = '';

  @Of(() => String, { optional: true })
  endHour: string = '';

  static of = Entity.of<GeneralUnavailabilityEntity>();
}

// ------------ TEACHER ------------
@Entity
export class CreateTeacherRulesInput {
  @Of(() => String)
  entityType: EntityTypeRules | '' = '';

  @Of(() => String, { optional: true })
  MAX_DAILY_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  MAX_HOURS_CONTINUOUSLY: string = '';

  @Of(() => String, { optional: true })
  MAX_GAPS_PER_WEEK: string = '';

  @Of(() => [TeacherEntityTypes])
  entities: TeacherEntityTypes[] = [];

  static of = Entity.of<CreateTeacherRulesInput>();
}

@Entity
export class TeacherEntityTypes {
  @Of(() => String)
  entityID: string = '';

  @Of(() => String, { optional: true })
  MAX_DAILY_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  MAX_HOURS_CONTINUOUSLY: string = '';

  @Of(() => String, { optional: true })
  MAX_GAPS_PER_WEEK: string = '';

  @Of(() => [GeneralUnavailabilityEntity])
  unavailabilities: GeneralUnavailabilityEntity[] = [];

  static of = Entity.of<TeacherEntityTypes>();
}

// ------------ ROOM ------------

@Entity
export class CreateRoomRulesInput {
  @Of(() => String)
  entityType: EntityTypeRules | '' = '';

  @Of(() => [RoomEntityTypes])
  entities: RoomEntityTypes[] = [];

  static of = Entity.of<CreateRoomRulesInput>();
}

@Entity
export class RoomEntityTypes {
  @Of(() => String)
  entityID: string = '';

  @Of(() => [GeneralUnavailabilityEntity])
  unavailabilities: GeneralUnavailabilityEntity[] = [];

  static of = Entity.of<RoomEntityTypes>();
}

// ------------ ACTIVITY ------------

@Entity
export class CreateActivityRulesInput {
  @Of(() => String)
  entityType: EntityTypeRules | '' = '';

  @Of(() => String, { optional: true })
  MIN_DAYS_BETWEEN_SAME_ACTIVITIES: string = '';

  @Of(() => [ActivityEntityTypes])
  entities: ActivityEntityTypes[] = [];

  static of = Entity.of<CreateActivityRulesInput>();
}

@Entity
export class ActivityEntityTypes {
  @Of(() => String)
  entityID: string = '';

  @Of(() => Number, { optional: true })
  ACTIVITY_BEGINS_STUDENTS_DAY?: number;

  @Of(() => Number, { optional: true })
  ACTIVITY_ENDS_STUDENTS_DAY?: number;

  @Of(() => String, { optional: true })
  MIN_DAYS_BETWEEN_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  MAX_DAYS_BETWEEN_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  ACTIVITY_PREFERRED_ROOM: string = '';

  @Of(() => Number, { optional: true })
  ACTIVITIES_MUST_HAVE_THE_SAME_STARTING_HOUR?: number;

  @Of(() => Number, { optional: true })
  CONCURRENT_HOURS: number = 0;

  @Of(() => [Number], { optional: true })
  concurrentActivities: number[] = [];

  static of = Entity.of<ActivityEntityTypes>();
}
