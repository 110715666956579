import { Entity, Of } from 'entity-of';

@Entity
export class ProfileSpvTokenInput {
  @Of(() => Number)
  tenantBillingProfileId: number = 0;

  @Of(() => String)
  code: string = '';

  @Of(() => String)
  clientId: string = '';

  @Of(() => String)
  redirectUri: string = '';

  static of = Entity.of<ProfileSpvTokenInput>();
}
