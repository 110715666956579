import { PhoneWithPrefix } from '@api/core/entities';
import { PhoneNumberWithPrefix, PhoneNumberWithPrefixRequired } from '@lib/validators';
import { BUCHAREST_COUNTY_CODE } from '@modules/shared/utils';
import { Type } from 'class-transformer';
import { IsEmail, IsNotEmpty, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

import 'reflect-metadata';

@Entity
export class PrivateBillingProfileForm {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.firstName.required' })
  firstName: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.lastName.required' })
  lastName: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.address.required' })
  street: string = '';

  @Of(() => String)
  zipCode: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.country.required' })
  country: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.county.required' })
  county: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.county !== BUCHAREST_COUNTY_CODE)
  @IsNotEmpty({ message: 'errors.locality.required' })
  city: string = '';

  @Of(() => String)
  countryId: string = '';

  @Of(() => String)
  countyId: string = '';

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.county === BUCHAREST_COUNTY_CODE)
  @IsNotEmpty({ message: 'errors.locality.required' })
  cityId?: string;

  @Of(() => String)
  @ValidateIf((e) => !!e.email || e.isSubscriptions)
  @IsEmail(undefined, { message: 'errors.email.isValid' })
  @IsNotEmpty({ message: 'errors.email.required' })
  email: string = '';

  @Of(() => PhoneWithPrefix)
  @Type(() => PhoneWithPrefix)
  @ValidateIf((f) => !!(f.phoneNumber.phone || f.phoneNumber.prefix) || f.isSubscriptions)
  @PhoneNumberWithPrefixRequired({ message: 'errors.phoneWithPrefix.required' })
  @PhoneNumberWithPrefix({ message: 'errors.phoneWithPrefix.invalid' })
  phoneNumber: PhoneWithPrefix = PhoneWithPrefix.of({});

  @Of(() => Boolean)
  isJuridic: boolean = false;

  @Of(() => String)
  accountId: string = '';

  @Of(() => Boolean)
  isSubscriptions: boolean = false;

  static of = Entity.of<PrivateBillingProfileForm>();
}
