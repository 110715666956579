export enum VatCategory {
  S = 'S',
  Z = 'Z',
  E = 'E',
  AE = 'AE',
  K = 'K',
  G = 'G',
  O = 'O',
  L = 'L',
  M = 'M',
}

export const vatCategoryLabelMap = {
  [VatCategory.S]: 'S - Cota normală şi cota redusă a TVA',
  [VatCategory.Z]: 'Z - TVA cota zero',
  [VatCategory.E]: 'E - Scutire de TVA',
  [VatCategory.AE]: 'AE - TVA cu taxare inversă',
  [VatCategory.K]: 'K - TVA pentru livrări intracomunitare',
  [VatCategory.G]: 'G - TVA pentru exporturi',
  [VatCategory.O]: 'O - Nu face obiectul TVA',
  [VatCategory.L]: 'L - Taxele din Insulele Canare',
  [VatCategory.M]: 'M - Taxele din Ceuta şi Melilla',
};

export enum VatExemptionCode {
  VATEX_EU_79_C = 'VATEX-EU-79-C',
  VATEX_EU_132 = 'VATEX-EU-132',
  VATEX_EU_132_1A = 'VATEX-EU-132-1A',
  VATEX_EU_132_1B = 'VATEX-EU-132-1B',
  VATEX_EU_132_1C = 'VATEX-EU-132-1C',
  VATEX_EU_132_1D = 'VATEX-EU-132-1D',
  VATEX_EU_132_1E = 'VATEX-EU-132-1E',
  VATEX_EU_132_1F = 'VATEX-EU-132-1F',
  VATEX_EU_132_1G = 'VATEX-EU-132-1G',
  VATEX_EU_132_1H = 'VATEX-EU-132-1H',
  VATEX_EU_132_1I = 'VATEX-EU-132-1I',
  VATEX_EU_132_1J = 'VATEX-EU-132-1J',
  VATEX_EU_132_1K = 'VATEX-EU-132-1K',
  VATEX_EU_132_1L = 'VATEX-EU-132-1L',
  VATEX_EU_132_1M = 'VATEX-EU-132-1M',
  VATEX_EU_132_1N = 'VATEX-EU-132-1N',
  VATEX_EU_132_1O = 'VATEX-EU-132-1O',
  VATEX_EU_132_1P = 'VATEX-EU-132-1P',
  VATEX_EU_132_1Q = 'VATEX-EU-132-1Q',
  VATEX_EU_143 = 'VATEX-EU-143',
  VATEX_EU_143_1A = 'VATEX-EU-143-1A',
  VATEX_EU_143_1B = 'VATEX-EU-143-1B',
  VATEX_EU_143_1C = 'VATEX-EU-143-1C',
  VATEX_EU_143_1D = 'VATEX-EU-143-1D',
  VATEX_EU_143_1E = 'VATEX-EU-143-1E',
  VATEX_EU_143_1F = 'VATEX-EU-143-1F',
  VATEX_EU_143_1FA = 'VATEX-EU-143-1FA',
  VATEX_EU_148 = 'VATEX-EU-148',
  VATEX_EU_AE = 'VATEX-EU-AE',
  VATEX_EU_D = 'VATEX-EU-D',
  VATEX_EU_F = 'VATEX-EU-F',
  VATEX_EU_G = 'VATEX-EU-G',
  VATEX_EU_I = 'VATEX-EU-I',
  VATEX_EU_IC = 'VATEX-EU-IC',
  VATEX_EU_J = 'VATEX-EU-J',
  VATEX_EU_O = 'VATEX-EU-O',
}

export const zeroVatCategories = [
  VatCategory.AE,
  VatCategory.E,
  VatCategory.K,
  VatCategory.G,
  VatCategory.O,
  VatCategory.Z,
];

export const vatCategoriesOptions = [
  {
    value: VatCategory.S,
    label: 'S - Cota normală şi cota redusă a TVA',
  },
  {
    value: VatCategory.Z,
    label: 'Z - TVA cota zero',
  },
  {
    value: VatCategory.E,
    label: 'E - Scutire de TVA',
  },
  {
    value: VatCategory.AE,
    label: 'AE - TVA cu taxare inversă',
  },
  {
    value: VatCategory.K,
    label: 'K - TVA pentru livrări intracomunitare',
  },
  {
    value: VatCategory.G,
    label: 'G - TVA pentru exporturi',
  },
  {
    value: VatCategory.O,
    label: 'O - Nu face obiectul TVA',
  },
  {
    value: VatCategory.L,
    label: 'L - Taxele din Insulele Canare',
  },
  {
    value: VatCategory.M,
    label: 'M - Taxele din Ceuta şi Melilla',
  },
];

export const vatExemptionCodeList = [
  {
    description: `Exceptie cf. Art. 79, lit c din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_79_C,
  },
  {
    description: `Exceptie cf. Art. 132 din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (a) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1A,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (b) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1B,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (c) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1C,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (d) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1D,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (e) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1E,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (f) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1F,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (g) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1G,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (h) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1H,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (i) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1I,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (j) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1J,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (k) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1K,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (l) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1L,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (m) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1M,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (n) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1N,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (o) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1O,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (p) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1P,
  },
  {
    description: `Exceptie cf. Art. 132 , alin. 1, lit (q) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_132_1Q,
  },
  {
    description: `}Exceptie cf. Art. 143 din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_143,
  },
  {
    description: `Exceptie cf. Art. 143, alin. 1, lit (a) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_143_1A,
  },
  {
    description: `Exceptie cf. Art. 143, alin. 1, lit (b) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_143_1B,
  },
  {
    description: `Exceptie cf. Art. 143, alin. 1, lit (c) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_143_1C,
  },
  {
    description: `Exceptie cf. Art. 143, alin. 1, lit (d) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_143_1D,
  },
  {
    description: `Exceptie cf. Art. 143, alin. 1, lit (e) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_143_1E,
  },
  {
    description: `Exceptie cf. Art. 143, alin. 1, lit (f) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_143_1F,
  },
  {
    description: `Exceptie cf. Art. 143, alin. 1, lit (fa) din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_143_1FA,
  },
  {
    description: `Exceptie cf. Art. 148 din Directiva 2006/112/EC`,
    code: VatExemptionCode.VATEX_EU_148,
  },
  {
    description: `Taxare inversa`,
    code: VatExemptionCode.VATEX_EU_AE,
  },
  {
    description: `Intra-Regim special pentru agentiile de turism`,
    code: VatExemptionCode.VATEX_EU_D,
  },
  {
    description: `Regim special pentru bunuri second hand`,
    code: VatExemptionCode.VATEX_EU_F,
  },
  {
    description: `Export in afara UE`,
    code: VatExemptionCode.VATEX_EU_G,
  },
  {
    description: `Regim special pentru obiecte de arta`,
    code: VatExemptionCode.VATEX_EU_I,
  },
  {
    description: `Livrare intra-comunitara`,
    code: VatExemptionCode.VATEX_EU_IC,
  },
  {
    description: `Regim special pentru obiecte de colectie si antichitati`,
    code: VatExemptionCode.VATEX_EU_J,
  },
  {
    description: `Nu face obiectul TVA`,
    code: VatExemptionCode.VATEX_EU_O,
  },
];

export const defaultVatExemptionCodes = {
  [VatCategory.AE]: VatExemptionCode.VATEX_EU_AE,
  [VatCategory.K]: VatExemptionCode.VATEX_EU_IC,
  [VatCategory.G]: VatExemptionCode.VATEX_EU_G,
  [VatCategory.O]: VatExemptionCode.VATEX_EU_O,
};

export const SPV_REDIRECT_PATH = `/ro/billing/spv/callback`;
