import { ComponentMultiStyleConfig } from '@chakra-ui/react';

import { InputTheme } from '../Input';

export const NumberInputTheme: ComponentMultiStyleConfig = {
  parts: ['field', 'stepper'],
  baseStyle: {
    field: {
      _placeholder: {
        opacity: 1,
        color: 'gray.300',
      },
    },
  },
  sizes: InputTheme.sizes,
  variants: InputTheme.variants,
};
