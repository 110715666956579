import { Entity, Of } from 'entity-of';

@Entity
export class TestResultsQueryInput {
  @Of(() => String)
  testID: string = '';

  @Of(() => String)
  elevID: string = '';

  static of = Entity.of<TestResultsQueryInput>();
}
