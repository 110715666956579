import { Vectors } from '@ui2/assets';

export const useFileIcon = () => {
  const getFileIcon = (applicationType: string) => {
    const type = applicationType.split('/')[1] ?? applicationType;

    switch (type) {
      case 'pdf':
        return Vectors.PDF;
      case 'doc':
      case 'docx':
        return Vectors.Doc;
      case 'zip':
      case 'rar':
      case '7z':
        return Vectors.Archive;
      case 'mp3':
      case 'wav':
      case 'flac':
        return Vectors.Audio;
      case 'folder':
        return Vectors.Folder;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return Vectors.Image;
      case 'ppt':
      case 'pptx':
        return Vectors.PPT;
      case 'xls':
      case 'xlsx':
      case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return Vectors.SpreadSheet;
      case 'txt':
      case 'text':
      case 'plain':
        return Vectors.Text;
      case 'mp4':
      case 'avi':
      case 'mkv':
      case 'mov':
      case 'quicktime':
        return Vectors.Video;
      default:
        return Vectors.EmptyFolder;
    }
  };

  return { getFileIcon };
};
