import { Entity, Of } from 'entity-of';

@Entity
export class ArticleFavouriteInput {
  @Of(() => Number)
  nsID: number = 0;

  @Of(() => Number)
  isFavorit: number = 0;

  static of = Entity.of<ArticleFavouriteInput>();
}
