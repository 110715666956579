import { Entity, Of } from 'entity-of';

@Entity
export class UserTypeInfo {
  @Of(() => String)
  clID?: string = '';

  @Of(() => String)
  clNume?: string = '';

  static of = Entity.of<UserTypeInfo>();
}
