import React, { useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { Divider, HStack, Placement } from '@chakra-ui/react';
import { useRouter } from '@lib/router';
import { formatISO } from 'date-fns';
import { enUS, ro } from 'date-fns/locale';

import { getOrgTypeTheme } from '../../../utils';
import { AdservioIcons, Box, Center, IconButton, InputGroup, Text } from '../../atoms';

import { MonthPicker, MonthSelector, YearPicker, YearSelector } from './DatePicker.layout';
import { useDatepicker } from './useDatepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './chakra-react-datepicker.css';

export interface DatePickerProps {
  name?: string;
  value?: string;
  minDate?: Date;
  maxDate?: Date;
  testId?: string;
  disabled?: boolean;
  isClearable?: boolean;
  dateFormat?: string;
  selectRange?: boolean;
  popperPlacement?: Placement;
  onChange?(value: string): void;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  monthPicker?: boolean;
  yearPicker?: boolean;
  onCalendarOpen?(): void;
  onCalendarClose?(): void;
  onBlur?(): void;
  onFocus?(): void;
}

export const DatePicker = React.forwardRef(
  (
    {
      value,
      minDate,
      maxDate,
      onChange,
      disabled = false,
      isClearable = true,
      size = 'md',
      dateFormat,
      name,
      testId,
      popperPlacement,
      onCalendarOpen,
      onCalendarClose,
      monthPicker = false,
      yearPicker = false,
      selectRange,
      onBlur,
      onFocus,
    }: DatePickerProps,
    ref
  ) => {
    const { t } = useTranslation('shared');

    const { lang } = useRouter();

    const {
      months,
      onNext,
      yearsRange,
      onPrevious,
      onYearSelect,
      openYearSelector,
      showYearSelector,
      showMonthSelector,
      openMonthSelector,
      handleCalendarClose,
      onDefaultMonthSelect,
    } = useDatepicker({ onCalendarClose, monthPicker, yearPicker });

    const baseColor = getOrgTypeTheme('blue');

    const calendarInputRef = useRef<HTMLInputElement>(null);

    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    const onRangeChange = (dates) => {
      const [start, end] = dates;

      setStartDate(start);
      setEndDate(end);

      if (start && end) {
        onChange?.(
          `${formatISO(start, { representation: 'date' })}_${formatISO(end, {
            representation: 'date',
          })}`
        );
      }
    };

    return (
      <Box position="relative">
        <ReactDatePicker
          name={name}
          ref={ref as any}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          autoComplete={'off'}
          calendarStartDay={1}
          startDate={startDate}
          onCalendarOpen={onCalendarOpen}
          showPopperArrow={false}
          selectsRange={selectRange}
          shouldCloseOnSelect={true}
          popperPlacement={popperPlacement}
          onCalendarClose={handleCalendarClose}
          locale={lang === 'ro' ? ro : enUS}
          dateFormat={dateFormat || 'dd/MM/yyyy'}
          placeholderText={t('general.select_ellipsis')}
          selected={selectRange ? (startDate ? startDate : null) : value ? new Date(value) : null}
          onFocus={onFocus}
          onBlur={onBlur}
          showMonthYearPicker={monthPicker}
          showYearPicker={yearPicker}
          onChange={(date) => {
            if (!date) return;

            if (Array.isArray(date)) {
              onRangeChange(date);
            } else {
              onChange?.(formatISO(date, { representation: 'date' }));
            }
          }}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => {
            return (
              <>
                <HStack justifyContent="space-between" backgroundColor="white" pb="4" spacing={0}>
                  {!yearPicker && (
                    <IconButton
                      variant="ghost"
                      colorScheme="gray"
                      aria-label="navigate-to-previous"
                      onClick={() => onPrevious(date, decreaseMonth, changeYear)}
                      icon={<AdservioIcons.FiChevronLeft color="gray.400" boxSize="8" />}
                    />
                  )}

                  <HStack spacing="4" width="full" justifyContent="center" px="4">
                    {!showMonthSelector && !showYearSelector && !monthPicker && !yearPicker && (
                      <MonthSelector
                        label={months.find((month) => month.value === date.getMonth())?.label || ''}
                        openMonthSelector={openMonthSelector}
                      />
                    )}

                    {(showYearSelector || yearPicker) && (
                      <Text w="full" maxW="120px" fontWeight="semibold" fontSize="md">
                        {`${yearsRange[0]} - ${yearsRange[yearsRange.length - 1]}`}
                      </Text>
                    )}

                    {!showYearSelector && !yearPicker && (
                      <YearSelector
                        label={date.getFullYear()}
                        openYearSelector={openYearSelector}
                      />
                    )}
                  </HStack>

                  {!yearPicker && (
                    <IconButton
                      variant="ghost"
                      colorScheme="gray"
                      aria-label="navigate-to-next"
                      onClick={() => onNext(date, increaseMonth, changeYear)}
                      icon={<AdservioIcons.FiChevronRight color="gray.400" boxSize="8" />}
                    />
                  )}
                </HStack>

                {showMonthSelector && (
                  <MonthPicker
                    months={months}
                    value={date.getMonth()}
                    onChangeMonth={(newMonth: number) => {
                      changeMonth(newMonth);
                      onDefaultMonthSelect();
                    }}
                  />
                )}

                {showYearSelector && (
                  <YearPicker
                    onChangeYear={(year) => {
                      onYearSelect();
                      changeYear(year);
                    }}
                    value={date.getFullYear()}
                    years={yearsRange}
                    minDate={minDate}
                    maxDate={maxDate}
                    monthPicker={monthPicker}
                  />
                )}
              </>
            );
          }}
          customInput={
            <InputGroup
              ref={calendarInputRef}
              size={size}
              data-testid={testId}
              rightElement={
                <>
                  <Divider
                    position="relative"
                    orientation="vertical"
                    height="65%"
                    borderColor="gray.200"
                    right={
                      !((selectRange && startDate) || value) ||
                      (((selectRange && startDate) || value) && isClearable) ||
                      !isClearable
                        ? '0.45rem'
                        : '0.8rem'
                    }
                  />

                  <Center mr="2" cursor="pointer">
                    {((selectRange && startDate) || value) && isClearable ? (
                      <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="remove"
                        colorScheme={baseColor}
                        isDisabled={disabled}
                        onClick={() => (selectRange ? onRangeChange([null, null]) : onChange?.(''))}
                        icon={<AdservioIcons.FiX boxSize="6" color={`${baseColor}.500`} />}
                      />
                    ) : (
                      <IconButton
                        size="sm"
                        variant="ghost"
                        aria-label="Open calendar"
                        colorScheme={baseColor}
                        icon={<AdservioIcons.FiCalendar color={`${baseColor}.500`} />}
                      />
                    )}
                  </Center>
                </>
              }
              rightElementSx={
                (selectRange && startDate) || (value && isClearable)
                  ? {}
                  : { pointerEvents: 'none' }
              }
            />
          }
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 0],
              },
            },
          ]}
        />
      </Box>
    );
  }
);
