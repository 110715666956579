import { IsNotEmpty, Matches, MaxLength } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class CreateSubjectInput {
  @Of(() => String)
  @Matches(/^[^-\s]/, {
    message: 'errors.admission.no_white_space',
  })
  @IsNotEmpty({ message: 'errors.matNume.required' })
  @MaxLength(150, {
    message: 'errors.admission.max_150',
  })
  matNume: string = '';

  @Of(() => String)
  @Matches(/^[^-\s]/, {
    message: 'errors.admission.no_white_space',
  })
  @IsNotEmpty({ message: 'errors.matPrescurtare.required' })
  @MaxLength(50, {
    message: 'errors.admission.max_50',
  })
  matPrescurtare: string = '';

  @Of(() => String)
  matOptScutire: string = '';

  @Of(() => String)
  matOpt: string = '';

  @Of(() => String)
  matRapoarteElevi: string = '';

  @Of(() => [Number])
  matRoomsIds: number[] = [];

  @Of(() => String)
  matColor: string = '';

  @Of(() => Number)
  matPurtare: number = 0;

  static of = Entity.of<CreateSubjectInput>();
}

@Entity
export class EditSubjectInput extends CreateSubjectInput {
  @Of(() => String)
  matID: string = '';

  static of = Entity.of<EditSubjectInput>();
}
