import { Entity, Of } from 'entity-of';

import { InvoiceRetrieve, InvoiceSpvStatus, InvoiceStatus } from '../Invoice';

@Entity
export class UserInvoicesTableFilters {
  @Of(() => String, { optional: true })
  tenantBillingProfileId?: string;

  @Of(() => [String], { optional: true })
  status?: InvoiceStatus[];

  @Of(() => [String], { optional: true })
  spvStatus?: InvoiceSpvStatus[];

  @Of(() => String, { optional: true })
  createdStartDate?: string;

  @Of(() => String, { optional: true })
  createdEndDate?: string;

  @Of(() => String, { optional: true })
  dueStartDate?: string;

  @Of(() => String, { optional: true })
  dueEndDate?: string;

  @Of(() => String, { optional: true })
  serial?: string;

  @Of(() => Number, { optional: true })
  number?: number;

  @Of(() => String, { optional: true })
  retrieve?: InvoiceRetrieve;

  static of = Entity.of<UserInvoicesTableFilters>();
}
