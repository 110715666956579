import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class ArticlesSearchInput extends SimpleQueryInput {
  @Of(() => String)
  uaID: string = '';

  static of = Entity.of<ArticlesSearchInput>();
}
