import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { range } from 'lodash';

const hideWeekAndDates = () => {
  const dayNames = document.getElementsByClassName('react-datepicker__day-names');
  const months = document.getElementsByClassName('react-datepicker__month');

  if (dayNames.length) {
    dayNames[0].classList.add('hidden');
  }

  if (months.length) {
    months[0].classList.add('hidden');
  }
};

const showWeekAndDates = () => {
  const dayNames = document.getElementsByClassName('react-datepicker__day-names');
  const months = document.getElementsByClassName('react-datepicker__month');

  if (dayNames.length) {
    dayNames[0].classList.remove('hidden');
  }

  if (months.length) {
    months[0].classList.remove('hidden');
  }
};

interface UseDatepickerOptions {
  onCalendarClose?: () => void;
  monthPicker?: boolean;
  yearPicker?: boolean;
}

export const useDatepicker = ({
  onCalendarClose,
  monthPicker,
  yearPicker,
}: UseDatepickerOptions) => {
  const { t } = useTranslation('shared');

  const [showMonthSelector, setShowMonthSelector] = useState<boolean>(false);
  const [showYearSelector, setShowYearSelector] = useState<boolean>(false);
  const [yearsRange, setYearsRange] = useState<number[]>([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();

    setYearsRange(
      yearPicker ? range(currentYear - 8, currentYear + 4) : range(currentYear - 6, currentYear + 6)
    );
  }, [yearPicker]);

  const openMonthSelector = () => {
    hideWeekAndDates();
    setShowYearSelector(false);
    setShowMonthSelector(true);
  };

  const onDefaultMonthSelect = () => {
    setShowMonthSelector(false);
    showWeekAndDates();
  };

  const onYearSelect = () => {
    setShowYearSelector(false);
    setShowMonthSelector(false);
    showWeekAndDates();
  };

  const handleCalendarClose = () => {
    setYearsRange(() => {
      const currentYear = new Date().getFullYear();

      return yearPicker
        ? range(currentYear - 8, currentYear + 4)
        : range(currentYear - 6, currentYear + 6);
    });

    setShowMonthSelector(false);
    setShowYearSelector(false);
    onCalendarClose?.();
  };

  const openYearSelector = () => {
    hideWeekAndDates();
    setShowMonthSelector(false);
    setShowYearSelector(true);
  };

  const onPrevious = (
    date: Date,
    decreaseMonth: () => void,
    changeYear: (year: number) => void
  ) => {
    if (showYearSelector)
      setYearsRange(range(yearsRange[0] - 12, yearsRange[yearsRange.length - 1] - 11));
    if (showMonthSelector || monthPicker) changeYear(date.getFullYear() - 1);
    if (!showMonthSelector && !showYearSelector && !monthPicker) decreaseMonth();
  };

  const onNext = (date: Date, increaseMonth: () => void, changeYear: (year: number) => void) => {
    if (showYearSelector)
      setYearsRange(range(yearsRange[0] + 12, yearsRange[yearsRange.length - 1] + 13));

    if (showMonthSelector || monthPicker) changeYear(date.getFullYear() + 1);
    if (!showMonthSelector && !showYearSelector && !monthPicker) increaseMonth();
  };

  const months = [
    { label: t('general.months_of_year.january'), value: 0 },
    { label: t('general.months_of_year.february'), value: 1 },
    { label: t('general.months_of_year.march'), value: 2 },
    { label: t('general.months_of_year.april'), value: 3 },
    { label: t('general.months_of_year.may'), value: 4 },
    { label: t('general.months_of_year.june'), value: 5 },
    { label: t('general.months_of_year.july'), value: 6 },
    { label: t('general.months_of_year.august'), value: 7 },
    { label: t('general.months_of_year.september'), value: 8 },
    { label: t('general.months_of_year.october'), value: 9 },
    { label: t('general.months_of_year.november'), value: 10 },
    { label: t('general.months_of_year.december'), value: 11 },
  ];

  return {
    onNext,
    months,
    onPrevious,
    yearsRange,
    onYearSelect,
    openYearSelector,
    showYearSelector,
    openMonthSelector,
    showMonthSelector,
    handleCalendarClose,
    onDefaultMonthSelect,
  };
};
