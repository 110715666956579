import { Entity, Of } from 'entity-of';

@Entity
export class DomaineStudyResponse {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  parentId: number = 0;

  @Of(() => String, { nullable: true })
  parentName: string = '';

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  acronym: string = '';

  @Of(() => String)
  uniqueAcronym: string = '';

  @Of(() => Number)
  academicYearId: number = 0;

  static of = Entity.of<DomaineStudyResponse>();
}
