import { Entity, Of } from 'entity-of';

export enum DiscountType {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
}

@Entity
export class Discount {
  @Of(() => String)
  name: string = '';

  @Of(() => String, { optional: true })
  description?: string;

  @Of(() => Number)
  value: number = 0;

  static of = Entity.of<Discount>();
}
