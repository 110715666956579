import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FlexProps,
  FormControl as CKFormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  SystemStyleObject,
} from '@chakra-ui/react';

export interface FormControlProps extends FlexProps {
  /**
   * Required for registering the component with the Form
   */
  name: string;
  /**
   * It renders a `FormLabel` on top
   */
  label?: React.ReactNode | string;
  /**
   * It renders a `FormHelperText` underneath
   */
  error?: string;
  /**
   * If present, the form control will be invalid. This has 3 side effects:
   *
   * - The `FormHelperText` (if present), gets replaced by the `FormErrorMessage`
   * - The `FormLabel` and `FormErrorIcon` will have `data-invalid` set to `true`
   * - The form element (e.g, Input) will have `aria-invalid` set to `true`
   */
  helperText?: string;
  /**
   * If true, the form element will be required. This has two side effects:
   *
   * 1. The `FormLabel` will show a required indicator
   * 2. The form element (eg: Input) will have `aria-required` set to `true`
   */
  isRequired?: boolean;
  /**
   * If true, the form element will receive inline layout styles applied accordingly.
   */
  inlineLayout?: boolean;
  showHelperText?: boolean;
  swapLabel?: boolean;
  testId?: string;
  formType?: 'checkbox' | 'radio' | 'select' | 'input';
  formLabelSx?: FormLabelProps;
}

const emptyInlineStyles = {
  formControl: {},
  formLabel: {
    mb: '1',
  },
  formHelperText: {
    mt: '1',
    minHeight: '7',
  },
  formErrorMessage: {
    mt: '1',
    minHeight: '7',
  },
};

const inlineOverwriteStyles: Record<string, SystemStyleObject> = {
  formControl: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
  formLabel: {
    mb: 'unset',
  },
  formHelperText: {
    columnSpan: '1 / 3',
    mt: 'unset',
    ml: '3',
    gridColumn: '1 / 3',
  },
};

const inlineSwappedOverwriteStyles: Record<string, SystemStyleObject> = {
  formControl: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
  formLabel: {
    mb: 'unset',
    ml: '3',
  },
  formHelperText: {
    columnSpan: '1 / 3',
    mt: 'unset',
    mr: '3',
    gridColumn: '1 / 3',
  },
  formErrorMessage: {
    mt: 'unset',
    mr: '3',
  },
};

export const FormControl = ({
  testId,
  name,
  label,
  error,
  children,
  isRequired,
  helperText,
  inlineLayout,
  showHelperText = true,
  swapLabel = false,
  formType,
  formLabelSx,
  ...props
}: React.PropsWithChildren<FormControlProps>) => {
  const { t } = useTranslation('shared');
  const inlineStyles: Record<string, SystemStyleObject> = inlineLayout
    ? swapLabel
      ? inlineSwappedOverwriteStyles
      : inlineOverwriteStyles
    : emptyInlineStyles;

  return (
    <CKFormControl
      id={name}
      data-testid={testId}
      isRequired={isRequired}
      isInvalid={!!error}
      sx={inlineStyles.formControl}
      {...props}
    >
      {swapLabel && children}

      {label && (
        <Box>
          <FormLabel
            data-testid="form-label"
            color="gray.700"
            htmlFor={name}
            fontWeight="semibold"
            cursor="pointer"
            sx={inlineStyles.formLabel}
            display="inline-block"
            {...formLabelSx}
          >
            {label}
          </FormLabel>
        </Box>
      )}

      {!swapLabel && children}

      {error ? (
        <>
          {!swapLabel && <Box></Box>}

          <FormErrorMessage
            data-testid="form-error-message"
            sx={{ ...inlineStyles.formErrorMessage, ml: formType === 'checkbox' ? '2' : '0' }}
          >
            {t(error)}
          </FormErrorMessage>
        </>
      ) : (
        <FormHelperText
          display={showHelperText ? 'block' : 'none'}
          visibility={helperText ? 'visible' : 'hidden'}
          sx={inlineStyles.formHelperText}
        >
          {helperText ?? '__placeholder__'}
        </FormHelperText>
      )}
    </CKFormControl>
  );
};
