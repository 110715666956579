import { Entity, Of } from 'entity-of';

import { ActivityDetails } from '../TimetableWizardRules';

@Entity
export class SelectActivity extends ActivityDetails {
  @Of(() => [SelectActivity])
  concurrentActivities: SelectActivity[] = [];

  static of = Entity.of<SelectActivity>();
}
