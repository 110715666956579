import { Entity, Of } from 'entity-of';
@Entity
class Answer {
  @Of(() => Number)
  fraID = 0;

  @Of(() => Number)
  fraResultID = 0;

  @Of(() => Number)
  fraQuestionID = 0;

  @Of(() => ({ Number }))
  fraAnswer: Record<string, number> = {};

  @Of(() => Number)
  fraFillerUaID = 0;

  @Of(() => Number)
  fraProgress = 0;

  static of = Entity.of<Answer>();
}

@Entity
class Question {
  @Of(() => Number)
  fsqID = 0;

  @Of(() => Number)
  fsqSectionID = 0;

  @Of(() => Number)
  fsqTypeID = 0;

  @Of(() => String)
  fsqTitle = '';

  @Of(() => [FsqData])
  fsqData: FsqData[] = [];

  static of = Entity.of<Question>();
}

@Entity
class FsqData {
  @Of(() => String)
  item = '';

  @Of(() => Boolean)
  isCorrect;

  static of = Entity.of<FsqData>();
}

@Entity
export class Answers {
  @Of(() => Question)
  question: Question = Question.of({});

  @Of(() => Answer)
  answer: Answer = Answer.of({});

  static of = Entity.of<Answers>();
}
