import React, { PropsWithChildren } from 'react';

import { VStack } from '../../atoms';

interface WrapperProps {
  isInvalid?: boolean;
}

export const Wrapper = ({ isInvalid, children, ...rest }: PropsWithChildren<WrapperProps>) => {
  return (
    <VStack
      spacing="4"
      p="10"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      border="1px dashed"
      borderColor="gray.200"
      borderRadius="md"
      backgroundColor="background"
      {...rest}
    >
      {children}
    </VStack>
  );
};
