import { Entity, Of } from 'entity-of';

@Entity
export class StudentsSituation {
  @Of(() => String)
  scutire = '';

  @Of(() => Materie)
  cmcMaterie = Materie.of({});

  @Of(() => [Note], { optional: true })
  note?: Note[];

  @Of(() => [Absente], { optional: true })
  absente?: Absente[];

  @Of(() => Medii)
  medii = Medii.of({});

  static of = Entity.of<StudentsSituation>();
}
@Entity
export class Materie {
  @Of(() => String)
  matNume = '';

  @Of(() => String)
  matID: string = '';

  static of = Entity.of<Materie>();
}

@Entity
export class Medii {
  @Of(() => MedieAnuala)
  anuala = MedieAnuala.of({});

  static of = Entity.of<Medii>();
}

@Entity
export class MedieAnuala {
  @Of(() => String)
  mgmSituatieScolaraIntermediaraID = '0';

  static of = Entity.of<MedieAnuala>();
}
@Entity
export class Note {
  @Of(() => String)
  notaID: string = '';

  static of = Entity.of<Note>();
}
@Entity
export class Absente {
  @Of(() => String)
  absID: string = '';

  static of = Entity.of<Absente>();
}
