import { IsEmailFromDomain } from '@lib/validators';
import { IsEmail, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class UniversityEmailInput {
  @Of(() => String)
  @ValidateIf((object) => object.oaEmail)
  @IsEmail({}, { message: 'errors.email.isValid' })
  @IsEmailFromDomain(JSON.parse(window.env?.TUIASI_OAUTH_DOMAINS || '[]'))
  oaEmail: string = '';

  static of = Entity.of<UniversityEmailInput>();
}
