import { TYPE_ADMIN, TYPE_CANDIDATE, TYPE_PERS_INST } from '@constants';
import { useContainerWidth } from '@lib/hooks';
import { useStorageSession } from '@lib/utils';
import { Vectors } from '@ui2/assets';
import { Box, HStack, Image } from '@ui2/components';
import Cookies from 'js-cookie';

import { OrganisationsSearch } from '../OrganisationsSearch';

import { UserMenu } from './components/UserMenu';
import { NavbarElementsWrapper, NavbarWrapper, NavigationButton } from './Navbar.layout';

interface NavbarProps {
  isPHP?: boolean;
}

export const Navbar = ({ isPHP }: NavbarProps) => {
  const isTeacherView = Cookies.get('MPR') === '1';
  const { profile } = useStorageSession();
  const { width } = useContainerWidth();

  const handleSidenavToggle = () => {
    const event = new CustomEvent('mobileNavigationToggle');
    window.dispatchEvent(event);
  };

  return (
    <NavbarWrapper isPHP={isPHP}>
      <NavbarElementsWrapper contentWidth={width}>
        <Box>
          {profile?.uaTip !== TYPE_CANDIDATE && <NavigationButton onClick={handleSidenavToggle} />}

          {profile?.uaTip === TYPE_CANDIDATE && (
            <Image position="relative" src={Vectors.LogoNavFull} height="25px" width={'104px'} />
          )}
        </Box>

        <HStack spacing={{ base: '2', md: '4' }}>
          {[TYPE_PERS_INST, TYPE_ADMIN].includes(profile?.uaTip) && (
            <OrganisationsSearch isPHP={isPHP} />
          )}

          <UserMenu isPHP={isPHP} isTeacherView={isTeacherView} />
        </HStack>
      </NavbarElementsWrapper>
    </NavbarWrapper>
  );
};
