import { Entity, Of } from 'entity-of';

import { StatementStatus } from '../StatementData';

@Entity
export class StatementDataUpdateInput {
  @Of(() => [Number])
  invoiceIds: number[] = [];

  @Of(() => String)
  status: StatementStatus = StatementStatus.FAILED;

  static of = Entity.of<StatementDataUpdateInput>();
}
