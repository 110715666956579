// @ts-nocheck
import Cookies from 'js-cookie';

export const haveError = (formErrors, fieldName, errorCode) => {
  if (!formErrors.length) {
    return false;
  }

  if (typeof formErrors !== 'object') {
    return false;
  }

  const error = formErrors.filter(
    (fieldErr) => fieldErr.errorField === fieldName && fieldErr.errorCode === errorCode
  );

  return error.length ? error[0] : false;
};

export const removeTokenCookie = () => {
  Cookies.remove('ADST');
};

export const updateWindowLocation = (url) => {
  window.location = url;
};
