import { Subject, Teacher } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class Homework {
  @Of(() => Number)
  hwID: number = 0;

  @Of(() => String)
  hwName: string = '';

  @Of(() => Number)
  hwDescription: number = 0;

  @Of(() => Number)
  hwInsertDate: number = 0;

  @Of(() => Number)
  hwDueDate: number = 0;

  @Of(() => String, { optional: true })
  hwAllowAfterDueDate?: string;

  @Of(() => Teacher, { optional: true })
  profesor?: Teacher = Teacher.of({});

  @Of(() => Subject, { optional: true })
  materie?: Subject = Subject.of({});

  @Of(() => Grade, { optional: true })
  nota?: Grade = Grade.of({});

  @Of(() => Rating, { optional: true })
  rating?: Rating = Rating.of({});

  @Of(() => Boolean, { optional: true })
  hwsSubmitted?: boolean;

  static of = Entity.of<Homework>();
}

@Entity
export class Grade {
  @Of(() => String, { optional: true })
  notaID: string = '';

  @Of(() => String, { optional: true })
  notaValoare: string = '';

  @Of(() => String, { optional: true })
  notaData: string = '';

  @Of(() => String, { optional: true })
  notaDataInsert: string = '';

  @Of(() => String, { optional: true })
  notaTip: string = '';

  @Of(() => String, { optional: true })
  notaObs: string = '';

  static of = Entity.of<Grade>();
}

@Entity
export class Rating {
  @Of(() => Number, { optional: true })
  hwsRatingID?: number;

  @Of(() => String, { optional: true })
  hwsRatingComment?: string;

  static of = Entity.of<Rating>();
}
