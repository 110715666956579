import { SimpleQueryInput } from '@api/core/entities/shared/SimpleQueryInput';
import { Entity, Of } from 'entity-of';

@Entity
export class ClassCoordinatorInput extends SimpleQueryInput {
  @Of(() => String, { optional: true })
  clID?: string = '';

  @Of(() => String, { optional: true })
  ascID?: string = '';

  static of = Entity.of<ClassCoordinatorInput>();
}
