import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

import { Schedule } from '../Schedule';

export enum TimetableStatus {
  INCOMPLETE = 'incomplete',
  DRAFT = 'draft',
  PUBLISHED = 'published',
  PENDING = 'generator_pending',
  RUNNING = 'generator_running',
  SUCCESS = 'generator_success',
  FAILED = 'generator_failed',
}

export enum GenerationMethod {
  MANUAL = 'manual',
  FET = 'fet',
}

@Entity
export class Timetable {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  status: TimetableStatus = TimetableStatus.DRAFT;

  @Of(() => TimetableLastUpdate)
  lastUpdate: TimetableLastUpdate = TimetableLastUpdate.of({});

  @Of(() => TimetableIssues)
  issues: TimetableIssues = TimetableIssues.of({});

  @Of(() => String)
  generationMethod: GenerationMethod = GenerationMethod.MANUAL;

  @Of(() => String)
  fetError: string = '';

  @Of(() => String)
  schedule: Schedule = Schedule.of({});

  static of = Entity.of<Timetable>();
}

@Entity
export class TimetableLastUpdate {
  @Of(() => String)
  date: Date = new Date();

  @Of(() => Person)
  user: Person = Person.of({});

  static of = Entity.of<TimetableLastUpdate>();
}

@Entity
export class TimetableIssues {
  @Of(() => Number)
  warnings: number = 0;

  @Of(() => Number)
  conflicts: number = 0;

  static of = Entity.of<TimetableIssues>();
}
