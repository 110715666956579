import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class ChangePasswordInput {
  @Of(() => String)
  cvToken: string = '';

  @IsNotEmpty({ message: 'errors.uaPassword.required' })
  @Of(() => String)
  uaNewPassword: string = '';

  @IsNotEmpty({ message: 'errors.uaPassword.required' })
  @Of(() => String)
  uaNewPassword2: string = '';

  static of = Entity.of<ChangePasswordInput>();
}
