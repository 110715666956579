import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class ClassesLocksResponse {
  @Of(() => [ClassesLocksResponseResult])
  result?: ClassesLocksResponseResult[] = [];

  static of = Entity.of<ClassesLocksResponse>();
}

@Entity
export class ClassesLocksResponseResult {
  @Of(() => String)
  type: string = '';

  @Of(() => String)
  dateStart: string = '';

  @Of(() => String)
  dateEnd: string = '';

  @Of(() => String)
  reason: string = '';

  @Of(() => String)
  createdAt?: string = '';

  @Of(() => Person)
  createdBy: Person = Person.of({});

  @Of(() => String)
  createdByName: string = '';

  static of = Entity.of<ClassesLocksResponseResult>();
}
