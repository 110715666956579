// @ts-nocheck
import { useHistory as ReactUseHistory } from 'react-router-dom';

import Cookies from 'js-cookie';

export default function useHistory() {
  const hasCookiesAdsNative = !!Cookies.get('ads_native');

  const history = ReactUseHistory();

  if (hasCookiesAdsNative) {
    history.push = history.replace = (route) => {
      if (typeof route === 'string') {
        window.location.href = route;
        return;
      }

      window.location.href = `${route.pathname}${route.search ? route.search : ''}`;
    };

    history.goBack = () => {
      window.history.back();
    };
  }

  return history;
}
