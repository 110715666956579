import { Person } from '@api/core/entities';
import { PersonCard } from '@modules/billing/domains/shared/components';

interface SearchResultsProps {
  data?: Person[];
  isLoading?: boolean;
  onAccountSelect?: (value: Person) => void;
}

export const SearchResults = ({ data, isLoading, onAccountSelect }: SearchResultsProps) => {
  if (isLoading) return <Skeletons />;

  if (!data || !data.length) return null;

  return (
    <>
      {data.map((person, i) => (
        <PersonCard
          key={`${person.uaID}-${i}`}
          person={person}
          onClick={onAccountSelect}
          testId="account-result-card"
        />
      ))}
    </>
  );
};

const Skeletons = () => {
  const emptyPerson = Person.of({});

  return (
    <>
      <PersonCard person={emptyPerson} testId="account-result-skeleton" isLoading />
      <PersonCard person={emptyPerson} testId="account-result-skeleton" isLoading />
      <PersonCard person={emptyPerson} testId="account-result-skeleton" isLoading />
      <PersonCard person={emptyPerson} testId="account-result-skeleton" isLoading />
    </>
  );
};
