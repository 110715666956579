import { Entity, Of } from 'entity-of';

import { InvoiceServiceInput } from '../../Service';
import { InvoiceType, SerializedExchangeRates } from '../Invoice';

@Entity
export class InvoiceCreateInput {
  @Of(() => [Number])
  accountBillingProfileId: number = 0;

  @Of(() => Number)
  tenantBillingProfileId: number = 0;

  @Of(() => String)
  createdDate: string = '';

  @Of(() => String)
  dueDate: string = '';

  @Of(() => String)
  serial: string = '';

  @Of(() => Number, { optional: true })
  relatedInvoiceId?: number;

  @Of(() => InvoiceType)
  type: InvoiceType = InvoiceType.INVOICE;

  @Of(() => [InvoiceServiceInput])
  services: InvoiceServiceInput[] = [];

  @Of(() => String, { optional: true })
  otherInformation?: string;

  @Of(() => SerializedExchangeRates, { optional: true })
  exchangeRates? = SerializedExchangeRates.of({});

  static of = Entity.of<InvoiceCreateInput>();
}
