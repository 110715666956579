import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class Period {
  @Of(() => String)
  sanID = '';

  @Of(() => String)
  sanNume = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.startDate.required' })
  sanDataStart = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.endDate.required' })
  sanDataEnd = '';

  @Of(() => String)
  sanAscID: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.type.required' })
  sanType = '';

  @Of(() => String)
  sanPrescurtare: string = '';

  @Of(() => String)
  sanIsActiv: string = '';

  @Of(() => String)
  sanIsCorigenta: string = '';

  @Of(() => String)
  sanPozitiaAfisare: string = '';

  @Of(() => String)
  sanDescriere: string = '';

  static of = Entity.of<Period>();
}
