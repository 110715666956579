import { Entity, Of } from 'entity-of';

@Entity
export class Statement {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  fileName: string = '';

  @Of(() => Number)
  billingProfileId: number = 0;

  @Of(() => [String])
  headers: string[] = [];

  @Of(() => Number)
  mapId: number = 0;

  @Of(() => String)
  bankName: string = '';

  @Of(() => String)
  accountId: string = '';

  @Of(() => String)
  tenantId: string = '';

  static of = Entity.of<Statement>();
}
