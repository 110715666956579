import { Entity, Of } from 'entity-of';

export enum PaymentIntentStatus {
  PENDING = 'pending',
  PENDING_LATE = 'pending_late',
  ERROR = 'error',
  SUCCESS = 'success',
}

@Entity
export class PaymentIntent {
  @Of(() => String)
  sessionId = '';

  @Of(() => [Number])
  invoiceIds = [];

  @Of(() => Number)
  amount = 0;

  @Of(() => String)
  tenantId = '';

  @Of(() => String)
  status: PaymentIntentStatus = PaymentIntentStatus.PENDING;

  @Of(() => String)
  reason = '';

  static of = Entity.of<PaymentIntent>();
}
