import { Entity, Of } from 'entity-of';

export type EntityTypeRules = 'CLASS' | 'TEACHER' | 'ROOM' | 'ACTIVITY' | 'SUMMARY';

@Entity
export class TimetableWizardRulesInput {
  @Of(() => String)
  entityType: EntityTypeRules | '' = '';

  static of = Entity.of<TimetableWizardRulesInput>();
}
