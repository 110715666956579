import { Entity, Of } from 'entity-of';

export type TaxType = 'didactic' | 'non_didactic';
@Entity
export class Tax {
  @Of(() => String)
  name: string = '';

  @Of(() => String)
  taxId: string = '';

  @Of(() => String)
  type: TaxType = 'didactic';

  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  @Of(() => Number)
  price: number = 0;

  static of = Entity.of<Tax>();
}

@Entity
export class PaginatedTaxInput {
  @Of(() => Number)
  size?: number = 10;

  @Of(() => Number)
  page?: number = 1;

  @Of(() => Boolean)
  allData?: boolean | null = null;

  @Of(() => String)
  sortField?: string = '';

  @Of(() => String)
  direction?: string = '';

  static of = Entity.of<PaginatedTaxInput>();
}
