import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useStorageSession } from '@lib/utils';
import { useHasAccess } from '@lib/utils/session/hasAccess';
import { SubscriptionsCheckout } from '@modules/billing/domains/checkout';
import { NoSubscriptionActive, NoSubscriptionEmptyState } from '@modules/shared';
import ZendeskFullScreen from '@modules/Zendesk/ZendeskFullScreen';
import Loader from 'Components/Overlays/Loader/Loader/Loader';

import './app.less';

import ProtectedRoute from './Components/ProtectedRoute';
import { UpgradeCalloutPage } from './Components/UpgradeCallout';
import {
  CONFIG_FLAGS,
  FEATURE_FLAGS,
  MODULE_BASE_ROUTES,
  PERM_ATT_REGISTER_MANAGER,
  PERM_CAD_DIRECTOR,
  PERM_CAD_EDUCATOR,
  PERM_CAD_INVATATOR,
  PERM_CAD_PROFESOR,
  PERM_CAD_REP_ADSERVIO,
  PERM_CAD_SECRETAR,
  PERM_SUBSCRIPTION_CLIENT,
  PERM_TIMETABLE_CONFIG,
  PERM_TIMETABLE_VIEW,
  TYPE_ADMIN,
  TYPE_CADRU,
  TYPE_ELEV,
  TYPE_PARINTE,
} from './constants';
import { useLang } from './i18n';

const Admission = React.lazy(() => import('./Modules/admission'));
const Anuar = React.lazy(() => import('./Modules/Anuar'));
const Attendance = React.lazy(() => import('./Modules/attendance-register'));

const Behavior = React.lazy(() => import('./Modules/behavior'));
const Billing = React.lazy(() => import('./Modules/billing'));

const Calendar = React.lazy(() => import('./Modules/Calendar'));
const Classes = React.lazy(() => import('./Modules/classes'));
const Configuration = React.lazy(() => import('./Modules/configuration'));

const Dashboards = React.lazy(() => import('./Modules/dashboards-onboardings'));
const Documents = React.lazy(() => import('./Modules/Documents'));

const EduMarket = React.lazy(() => import('./Modules/EduMarket'));

const Files = React.lazy(() => import('./Modules/Files'));
const Foto = React.lazy(() => import('./Modules/Foto/Foto'));

const Groups = React.lazy(() => import('./Modules/Groups'));

const History = React.lazy(() => import('./Modules/history'));
const Homework = React.lazy(() => import('./Modules/Homework'));

const Library = React.lazy(() => import('./Modules/library-management'));

const Messages = React.lazy(() => import('./Modules/Messages'));

const NationalHolydays = React.lazy(() => import('./Modules/national-holidays'));
const News = React.lazy(() => import('./Modules/News'));
const Nomenclature = React.lazy(() => import('./Modules/nomenclature'));

const Organizations = React.lazy(() => import('./Modules/organizations'));

const Portfolio = React.lazy(() => import('./Modules/portfolio'));
const Preferinte = React.lazy(() => import('./Modules/Preferinte'));
const Progress = React.lazy(() => import('./Modules/Progress'));

const Questionnaires = React.lazy(() => import('./Modules/questionnaires'));

const RedirectHome = React.lazy(() => import('./Modules/Site/components/redirect-home'));
const Reports = React.lazy(() => import('./Modules/reports-statistics'));
const ReportCard = React.lazy(() => import('./Modules/ReportCard/ReportCard'));
const RolesAndPermissions = React.lazy(() => import('./Modules/RolesAndPermissions'));

const SchoolPersonnel = React.lazy(() => import('./Modules/school-personnel'));
const ShareContent = React.lazy(() => import('./Modules/Files/Share/ShareComponent/ShareContent'));
const Subscriptions = React.lazy(() => import('./Modules/subscriptions'));

const Timetable = React.lazy(() => import('./Modules/timetable'));
const Transfers = React.lazy(() => import('./Modules/transfers'));

const Universities = React.lazy(() => import('./Modules/Universities'));

const WellBeing = React.lazy(() => import('./Modules/wellbeing'));

// Authentication, Registration & Email Prefferences
const AuthView = React.lazy(() => import('./Modules/authentication/domains/auth/AuthView'));
const ChangePasswordView = React.lazy(
  () => import('./Modules/authentication/domains/password-recovery/ChangePasswordView')
);
const RecoverPasswordView = React.lazy(
  () => import('./Modules/authentication/domains/password-recovery/RecoverPasswordView')
);
const RegisterCandidateView = React.lazy(
  () => import('./Modules/authentication/domains/register/RegisterCandidateView')
);
const RegisterView = React.lazy(
  () => import('./Modules/authentication/domains/register/RegisterView')
);
const ValidateEmailView = React.lazy(
  () => import('./Modules/authentication/domains/register/ValidateEmailView')
);
const UnsubscribeView = React.lazy(
  () => import('./Modules/authentication/domains/unsubscribe/UnsubscribeView')
);
// Authentication, Registration & Email Prefferences

import { Page404 } from '@modules/Site/components/page404';

interface AppRoutesProps {
  uaID: string;
}

export const AppRoutes = ({ uaID }: AppRoutesProps) => {
  const { isNative } = useStorageSession();
  const lang = useLang();

  return (
    <Switch>
      <Route path={`/${lang}/loading`} component={() => <Loader />} />

      <Route exact path={`/${lang}`} component={() => <Redirect to={`/${lang}/auth`} />} />

      <Route
        exact
        path={`/${lang}/auth`}
        component={() => (uaID ? <RedirectHome /> : <AuthView />)}
      />

      <Route
        exact
        path={`/${lang}/register`}
        component={() => (uaID ? <RedirectHome /> : <RegisterView />)}
      />

      <Route
        exact
        path={`/${lang}/register/candidate`}
        component={() => (uaID ? <RedirectHome /> : <RegisterCandidateView />)}
      />

      <Route
        exact
        path={`/${lang}/register/:token`}
        component={() => (uaID ? <RedirectHome /> : <ValidateEmailView />)}
      />

      <Route exact path={`/${lang}/unsubscribe/:token`} component={() => <UnsubscribeView />} />

      <Route exact path={`/${lang}/recover-password`} component={() => <RecoverPasswordView />} />

      <Route
        exact
        path={`/${lang}/recover-password/:token`}
        component={() => <ChangePasswordView />}
      />

      <ProtectedRoute
        exact
        path={`/${lang}/no-user-subscription`}
        component={NoSubscriptionActive}
        allowedWithoutUserSubscription={true}
      />

      <ProtectedRoute
        exact
        path={`/${lang}/no-subscription`}
        component={NoSubscriptionEmptyState}
        allowedWithoutOrgSubscription={true}
        allowedWithoutUserSubscription={true}
      />

      <ProtectedRoute
        exact
        path={`/${lang}/secretary`}
        component={() => <Redirect to={`/${lang}/dashboard`} />}
      />
      <ProtectedRoute path={`/${lang}/dashboard`} component={Dashboards} />

      <ProtectedRoute
        path={`/${lang}/homework`}
        component={Homework}
        uaTip={[TYPE_CADRU]}
        enabled={useHasAccess({ configFlags: [CONFIG_FLAGS.HOMEWORK_ACTIVE] })}
      />

      <ProtectedRoute
        path={`/${lang}/edumarket`}
        component={EduMarket}
        uaTip={[TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU, TYPE_ADMIN]}
        enabled={useHasAccess({ configFlags: [CONFIG_FLAGS.EDUMARKET_ACTIVE] })}
      />

      <ProtectedRoute
        path={`/${lang}/messages`}
        component={Messages}
        featureFlagRedirect
        featureFlag={FEATURE_FLAGS.FLAG_MODULE_MESSAGES}
      />

      <ProtectedRoute
        path={`/${lang}/report-card`}
        component={ReportCard}
        uaTip={[TYPE_ELEV, TYPE_PARINTE]}
      />

      <ProtectedRoute
        path={`/${lang}/foto`}
        component={Foto}
        uaTip={[TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU]}
        featureFlagRedirect
        featureFlag={FEATURE_FLAGS.FLAG_MODULE_FOTO}
      />

      <ProtectedRoute
        path={`/${lang}/anuar`}
        component={Anuar}
        uaTip={[TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU]}
        featureFlagRedirect
        featureFlag={FEATURE_FLAGS.FLAG_MODULE_YEARBOOK}
      />

      <ProtectedRoute path={`/${lang}/preferences`} component={Preferinte} />

      <ProtectedRoute path={`/${lang}/${MODULE_BASE_ROUTES.REPORTS}`} component={Reports} />

      <ProtectedRoute path={`/${lang}/files`} component={Files} />

      <ProtectedRoute path={`/${lang}/news`} component={News} />

      <Route exact path={`/admitere`} component={() => <Redirect to={`/ro/admission/offers`} />} />
      <Route path={`/${lang}/admission`} component={Admission} />

      <ProtectedRoute
        path={`/${lang}/groups`}
        component={Groups}
        uaTip={[TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU]}
      />

      {(TYPE_CADRU ||
        useHasAccess({ configFlags: [CONFIG_FLAGS.STUDENT_PROGRESS_REPORT_ACTIVE] })) && (
        <ProtectedRoute
          path={`/${lang}/progress`}
          component={Progress}
          uaTip={[TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU]}
          featureFlagRedirect
          featureFlag={FEATURE_FLAGS.FLAG_STATS_STUDENT_PROGRESS}
        />
      )}

      <ProtectedRoute
        path={`/${lang}/configuration/roles-and-permissions/:id?`}
        component={RolesAndPermissions}
      />

      <ProtectedRoute path={`/${lang}/calendar`} component={Calendar} />

      <ProtectedRoute exact path={`/${lang}/zendesk-support`} component={ZendeskFullScreen} />

      <ProtectedRoute path={`/${lang}/billing`} component={Billing} />

      <ProtectedRoute path={`/${lang}/configuration`} component={Configuration} />

      <ProtectedRoute path={`/${lang}/library`} component={Library} />

      <Route path={`/${lang}/subscriptions`} component={Subscriptions} />

      <ProtectedRoute
        path={`/${lang}/history`}
        component={History}
        enabled={useHasAccess({
          permissions: [
            PERM_CAD_DIRECTOR,
            PERM_CAD_SECRETAR,
            PERM_CAD_REP_ADSERVIO,
            PERM_CAD_PROFESOR,
            PERM_CAD_EDUCATOR,
            PERM_CAD_INVATATOR,
          ],
        })}
      />

      <ProtectedRoute
        path={`/${lang}/classes`}
        component={Classes}
        enabled={useHasAccess({
          permissions: [
            PERM_CAD_PROFESOR,
            PERM_CAD_EDUCATOR,
            PERM_CAD_INVATATOR,
            PERM_CAD_DIRECTOR,
            PERM_CAD_SECRETAR,
            PERM_CAD_REP_ADSERVIO,
          ],
        })}
      />

      <ProtectedRoute path={`/${lang}/main-documents`} uaTip={[TYPE_CADRU]} component={Documents} />

      <ProtectedRoute path={`/${lang}/upgrade/:module`} component={UpgradeCalloutPage} />

      <ProtectedRoute
        allowedWithoutUserSubscription
        path={`/${lang}/checkout/:id`}
        component={SubscriptionsCheckout}
        enabled={
          !isNative &&
          useHasAccess({
            permissions: [PERM_SUBSCRIPTION_CLIENT],
            configFlags: [CONFIG_FLAGS.SUBSCRIPTIONS_ACTIVE],
          })
        }
      />

      <ProtectedRoute path={`/${lang}/nomenclature`} component={Nomenclature} />

      <ProtectedRoute path={`/${lang}/behavior`} component={Behavior} />

      <ProtectedRoute
        path={`/${lang}/school-personnel`}
        component={SchoolPersonnel}
        enabled={useHasAccess({
          permissions: [PERM_CAD_DIRECTOR, PERM_CAD_SECRETAR, PERM_CAD_REP_ADSERVIO],
        })}
      />

      <ProtectedRoute
        path={`/${lang}/attendance`}
        component={Attendance}
        enabled={useHasAccess({
          permissions: [PERM_ATT_REGISTER_MANAGER],
          configFlags: [CONFIG_FLAGS.ATTENDANCE_REGISTER],
        })}
      />

      <ProtectedRoute path={`/${lang}/universities`} component={Universities} />

      <ProtectedRoute
        path={`/${lang}/timetable`}
        component={Timetable}
        enabled={useHasAccess({
          permissions: [PERM_TIMETABLE_VIEW, PERM_TIMETABLE_CONFIG],
        })}
      />

      <ProtectedRoute
        exact
        path={`/${lang}/shareContent`}
        component={ShareContent}
        enabled={isNative}
      />

      <ProtectedRoute
        path={`/${lang}/national_holidays`}
        component={NationalHolydays}
        enabled={useHasAccess({
          uaTypes: [TYPE_ADMIN],
        })}
      />

      <ProtectedRoute
        path={`/${lang}/wellbeing`}
        component={WellBeing}
        enabled={useHasAccess({
          uaTypes: [TYPE_ELEV, TYPE_PARINTE],
        })}
      />

      <ProtectedRoute
        path={`/${lang}/portfolio`}
        component={Portfolio}
        enabled={useHasAccess({
          uaTypes: [TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU],
        })}
      />

      <ProtectedRoute path={`/${lang}/questionnaires`} component={Questionnaires} />

      <ProtectedRoute
        path={`/${lang}/organizations`}
        component={Organizations}
        enabled={useHasAccess({
          uaTypes: [TYPE_ADMIN],
        })}
      />

      <ProtectedRoute
        path={`/${lang}/transfers`}
        component={Transfers}
        enabled={useHasAccess({
          permissions: [PERM_CAD_DIRECTOR, PERM_CAD_SECRETAR, PERM_CAD_REP_ADSERVIO],
        })}
      />

      <ProtectedRoute path="*" component={Page404} />
    </Switch>
  );
};
