import React from 'react';

import { Switch as CKSwitch, SwitchProps as CKSwitchProps } from '@chakra-ui/react';

export interface SwitchProps extends Omit<CKSwitchProps, 'value'> {
  value?: boolean;
}

export const Switch = ({ value, ...props }: React.PropsWithChildren<SwitchProps>) => {
  return <CKSwitch type="checkbox" isChecked={value} {...props} />;
};
