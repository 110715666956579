import { Entity, Of } from 'entity-of';

@Entity
export class UniversityFiltersInput {
  @Of(() => Number)
  educationalCycle: number = 0;

  @Of(() => Number)
  studyDomain: number = 0;

  @Of(() => Number)
  studyProgram: number = 0;

  @Of(() => Number)
  studyYear: number = 0;

  @Of(() => Number)
  disciplineId: number = 0;

  @Of(() => Number)
  academicPlanId: number = 0;

  @Of(() => Number)
  series: number = 0;

  @Of(() => Number)
  groupId: number = 0;

  @Of(() => Number)
  semester: number = 1;

  @Of(() => Number)
  session: number = 1;

  @Of(() => String)
  searchTerm: string = '';

  static of = Entity.of<UniversityFiltersInput>();
}
