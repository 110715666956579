import { Entity, Of } from 'entity-of';

@Entity
export class PricePlan {
  @Of(() => String)
  id: string | number = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  abbreviation: 'S' | 'F' | 'N' = 'F'; //subsidized, free, non-subsidized

  static of = Entity.of<PricePlan>();
}
