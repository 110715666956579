import { Entity, Of } from 'entity-of';

@Entity
export class TimetableActivitiesSearchInput {
  @Of(() => String)
  timetableId: string = '';

  @Of(() => [String], { optional: true })
  clIds?: string[];

  @Of(() => [String], { optional: true })
  cadIds?: string[];

  @Of(() => [Number], { optional: true })
  roomIds?: number[];

  @Of(() => Number, { optional: true })
  _limit?: number;

  @Of(() => Number, { optional: true })
  _offset?: number;

  static of = Entity.of<TimetableActivitiesSearchInput>();
}
