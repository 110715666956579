import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

type studyType = 'licence' | 'master' | 'phd';
export type ReportType =
  | 'accommodationsPerStudyYearReport'
  | 'invoicesPerTaxReport'
  | 'accommodationsPerDormReport'
  | 'dormStudentsReport'
  | 'studentsCentralizerReport'
  | 'collectionsSummaryReport'
  | 'studentSummaryReport'
  | 'studentsBalanceReport'
  | 'dormsBalanceReport';

export const reportTypes = [
  'accommodationsPerStudyYearReport',
  'invoicesPerTaxReport',
  'accommodationsPerDormReport',
  'dormStudentsReport',
  'studentsCentralizerReport',
  'collectionsSummaryReport',
  'studentSummaryReport',
  'studentsBalanceReport',
  'dormsBalanceReport',
];

export const studyTypes = ['licence', 'master', 'phd'] as const;

export type ExportType = 'pdf' | 'csv';
export const exportTypes = ['pdf', 'csv'] as const;

@Entity
export class Report {
  @Of(() => String, { optional: true })
  reportType: ReportType | undefined = undefined;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.date.required' })
  date: string = '';

  @Of(() => String, { optional: true })
  studyType: studyType | undefined = undefined;

  @Of(() => Number, { optional: true })
  @IsNotEmpty({ message: 'errors.tax.required' })
  taxId: number | undefined = undefined;

  @Of(() => String, { optional: true })
  paymentMethod: string | undefined = undefined;

  @Of(() => Number)
  dormId: number = 0;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.start_date.required' })
  startDate: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.end_date.required' })
  endDate: string = '';

  @Of(() => String, { optional: true })
  @IsNotEmpty({ message: 'errors.accommodations.student.required' })
  studentId: string | undefined = undefined;

  @Of(() => Number)
  userId: number = 0;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.exportType.required' })
  exportType: ExportType = 'pdf';

  @Of(() => Boolean)
  showAllStudents: boolean = false;

  static of = Entity.of<Report>();
}
