import { Entity, Of } from 'entity-of';

@Entity
export class PaginatedAssignedServicesSearchInput {
  @Of(() => [String], { optional: true })
  accountIds?: string[] = [];

  @Of(() => [String], { optional: true })
  serviceIds?: string[] = [];

  @Of(() => String, { optional: true })
  status?: 'Enabled' | 'Disabled' | 'Review';

  @Of(() => Number, { optional: true })
  offset?: number = 0;

  @Of(() => Number, { optional: true })
  limit?: number = 1;

  @Of(() => String, { optional: true })
  order?: 'asc' | 'desc';

  @Of(() => String, { optional: true })
  orderBy?: string;

  @Of(() => String, { optional: true })
  startDate?: string;

  @Of(() => String, { optional: true })
  endDate?: string;

  @Of(() => [Number], { optional: true })
  tenantBillingProfileIds?: number[];

  static of = Entity.of<PaginatedAssignedServicesSearchInput>();
}
