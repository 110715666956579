import { ArrayNotEmpty, IsNotEmpty, Min, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class PriceConfiguration {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.pricesConfig.tax.required' })
  tax: string = '';

  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.accommodations.pricesConfig.dorms.required' })
  dorms: string[] = [];

  @Of(() => Number, { nullable: true }) //free
  @ValidateIf((o) => o.F)
  @Min(0, { message: 'errors.price.positive' })
  F: number | null = null;

  @Of(() => Number, { nullable: true }) //subsidized
  @ValidateIf((o) => o.S)
  @Min(0, { message: 'errors.price.positive' })
  S: number | null = null;

  @Of(() => Number, { nullable: true }) //unsubsidized
  @ValidateIf((o) => o.N)
  @Min(0, { message: 'errors.price.positive' })
  N: number | null = null;

  static of = Entity.of<PriceConfiguration>();
}

@Entity
export class PriceConfigurationEdit {
  @Of(() => Number, { nullable: true }) //free
  @ValidateIf((o) => o.F)
  @Min(0, { message: 'errors.price.positive' })
  F: number | null = null;

  @Of(() => Number, { nullable: true }) //subsidized
  @ValidateIf((o) => o.S)
  @Min(0, { message: 'errors.price.positive' })
  S: number | null = null;

  @Of(() => Number, { nullable: true }) //unsubsidized
  @ValidateIf((o) => o.N)
  @Min(0, { message: 'errors.price.positive' })
  N: number | null = null;

  static of = Entity.of<PriceConfigurationEdit>();
}
