import { Entity, Of } from 'entity-of';

import { Administrator } from '../Administrator';
import { Room } from '..';

@Entity
export class Dorm {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  address: string = '';

  @Of(() => String)
  observations: string = '';

  @Of(() => [Room])
  rooms: Room[] = [];

  @Of(() => [Administrator])
  administrators: Administrator[] = [];

  static of = Entity.of<Dorm>();
}

@Entity
export class AvailableRoomsInput {
  @Of(() => String)
  checkInDate: string = '';

  @Of(() => String)
  checkOutDate: string = '';

  @Of(() => String)
  accommodationId: string = '';

  static of = Entity.of<AvailableRoomsInput>();
}

@Entity
export class AccomodationDorm extends Dorm {
  @Of(() => String)
  administrator: string = '';

  @Of(() => Number)
  occupiedBeds: number = 0;

  @Of(() => Number)
  totalBeds: number = 0;

  static of = Entity.of<AccomodationDorm>();
}
