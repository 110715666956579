import { Entity, Of } from 'entity-of';

@Entity
export class RecurrenceInput {
  @Of(() => String, { optional: true })
  type?: string;

  @Of(() => [String], { optional: true })
  daysOfWeek?: string[];

  @Of(() => [Number], { optional: true })
  dayOfMonth?: number[];

  @Of(() => [String], { optional: true })
  dates?: string[];

  @Of(() => String, { optional: true })
  startDate?: string;

  @Of(() => String, { optional: true })
  endDate?: string;

  static of = Entity.of<RecurrenceInput>();
}
