import { OauthProviders } from '@constants';
import { Entity, Of } from 'entity-of';

@Entity
export class AccountOauthInput {
  @Of(() => String, { optional: true })
  provider?: OauthProviders;

  @Of(() => String)
  oaProviderToken?: string = '';

  @Of(() => String)
  oaEmail?: string = '';

  @Of(() => String)
  oaUaID: string = '';

  @Of(() => String, { optional: true })
  oaRedirectUri?: string = '';

  static of = Entity.of<AccountOauthInput>();
}
