import { BackendError } from '@ui2/utils';
import { AxiosError } from 'axios';

import i18n from '../../../i18n';

interface Options {
  isLegacy?: boolean;
  t?: (key: string) => string;
  translationType?: string;
}

export const handleRequestErrors = (
  error: AxiosError<any, any>,
  showToast: (toastData) => void,
  options?: Options
) => {
  let translate = options?.t || i18n.t;

  // TODO: After we standardize the errors in the nodejs project, isLegacy must be deleted
  if (options?.isLegacy) {
    if (Array.isArray(error.response?.data)) {
      error.response?.data.forEach(
        ({ errorField, errorCode, errorExtraValue, errorExtraValues }: BackendError) => {
          let i18nKey = translate?.('validation:generic.99');

          let extraValue = errorExtraValue;

          if (Array.isArray(errorExtraValues)) {
            extraValue = errorExtraValues?.[0];
          }

          if (Number(errorCode) < 100) {
            const translationKey = `validation:generic.${errorCode}`;
            const translationOptions = extraValue ? { value: extraValue } : '';

            i18nKey = `${errorField ? errorField + ': ' : ''}${translate?.(translationKey, translationOptions as any)}`; // Fix this "as any"
          }

          showToast({
            description: i18nKey,
            status: 'error',
          });
        }
      );
    } else {
      const { errorField, errorCode } = error.response?.data || {};
      let i18nKey = translate?.('validation:generic.99');

      if (Number(errorCode) < 100) {
        i18nKey = `${errorField ? errorField + ': ' : ''}${translate?.(`validation:generic.${errorCode}`)}`;
      }

      showToast({
        description: i18nKey,
        status: 'error',
      });
    }
  } else {
    if (Array.isArray(error.response?.data)) {
      error.response?.data.forEach(({ message, field = '' }) => {
        showToast({
          description: `${field ? field + ' - ' : ''}${message}`,
          status: 'error',
        });
      });
    } else {
      showToast({
        description: error.response?.data.message,
        status: 'error',
      });
    }
  }
};
