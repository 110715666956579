import { Entity, Of } from 'entity-of';

@Entity
export class TreeCity {
  @Of(() => String)
  countScoli: string = '';

  @Of(() => String)
  cityID: string = '';

  static of = Entity.of<TreeCity>();
}
