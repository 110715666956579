import { Entity, Of } from 'entity-of';

@Entity
export class TreeFaculty {
  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  liceuOrgType?: number = 0;

  static of = Entity.of<TreeFaculty>();
}
