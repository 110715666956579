import {
  PERM_SCHOLARSHIP_ASSIGN_MANAGER,
  PERM_SCHOLARSHIP_BUDGET_MANAGER,
  PERM_SCHOLARSHIP_CLIENT,
  PERM_SCHOLARSHIP_FACULTIES_MANAGER,
  PERM_SCHOLARSHIP_REPORTS,
} from '@constants';
import { useStorageSession } from '@lib/utils';

export const useScholarshipPermissions = () => {
  const { permissions } = useStorageSession();

  const allowAllFacultiesScholarship = permissions.includes(PERM_SCHOLARSHIP_FACULTIES_MANAGER);
  const allowAllocateScholarship = permissions.includes(PERM_SCHOLARSHIP_ASSIGN_MANAGER);
  const allowBudgetAccess = permissions.includes(PERM_SCHOLARSHIP_BUDGET_MANAGER);
  const isScholarshipClient = permissions.includes(PERM_SCHOLARSHIP_CLIENT);
  const isScholarshipReportManager = permissions.includes(PERM_SCHOLARSHIP_REPORTS);

  return {
    allowAllFacultiesScholarship,
    allowAllocateScholarship,
    allowBudgetAccess,
    isScholarshipClient,
    isScholarshipReportManager,
  };
};
