import { OauthProviders } from '@constants';
import { Entity, Of } from 'entity-of';

@Entity
export class SSOInput {
  @Of(() => String)
  provider: OauthProviders = OauthProviders.GOOGLE;

  @Of(() => String)
  code: string = '';

  @Of(() => String)
  redirectUri: string = '';

  static of = Entity.of<SSOInput>();
}
