import { Filters, Order } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class PackagesSearchInput {
  @Of(() => Number, { optional: true })
  limit?: number = 10;

  @Of(() => Number, { optional: true })
  offset?: number = 0;

  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => [Filters], { optional: true })
  filters?: Filters[];

  @Of(() => [Order], { optional: true })
  order?: Order[];

  static of = Entity.of<PackagesSearchInput>();
}
