import { Entity, Of } from 'entity-of';

@Entity
export class RoleInput {
  @Of(() => String)
  roleID: string = '';

  @Of(() => String)
  uaID: string = '';

  static of = Entity.of<RoleInput>();
}
