import { MaxLengthDynamic } from '@lib/utils';
import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class LabelInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.naming.required' })
  @MaxLengthDynamic(50)
  lbName: string = '';

  @Of()
  lbColor: string = '';

  static of = Entity.of<LabelInput>();
}
