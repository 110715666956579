import { Entity, Of } from 'entity-of';

@Entity
export class Price {
  @Of(() => String)
  pricePlanId: string = '';
  @Of(() => Number)
  price: number = 0;

  static of = Entity.of<Price>();
}

@Entity
export class DormTaxInput {
  @Of(() => String)
  taxId: string = '';

  @Of(() => String)
  dormId: string = '';

  @Of(() => [Price])
  prices: Price[] = [];

  static of = Entity.of<DormTaxInput>();
}

@Entity
export class DormTaxPayload {
  @Of(() => String)
  taxId: string = '';

  @Of(() => [String])
  dorms: string[] = [];

  @Of(() => [Price])
  prices: Price[] = [];

  static of = Entity.of<DormTaxPayload>();
}
