import { Entity, Of } from 'entity-of';

@Entity
export class AccountBillingProfile {
  @Of(() => Number)
  id = 0;

  @Of(() => Boolean)
  disabled = false;

  @Of(() => String)
  accountId = '';

  @Of(() => String)
  companyName = '';

  @Of(() => String)
  fiscalCode = '';

  @Of(() => String)
  nrJ = '';

  @Of(() => String)
  street = '';

  @Of(() => String)
  zipCode = '';

  @Of(() => String)
  country = '';

  @Of(() => String)
  county = '';

  @Of(() => String)
  city = '';

  @Of(() => String)
  countryId: string = '';

  @Of(() => String)
  countyId: string = '';

  @Of(() => String, { optional: true })
  cityId?: string;

  @Of(() => String)
  email = '';

  @Of(() => String)
  phone = '';

  @Of(() => String)
  bankName = '';

  @Of(() => String)
  iban = '';

  @Of(() => String)
  firstName = '';

  @Of(() => String)
  lastName = '';

  @Of(() => Boolean)
  isJuridic = false;

  @Of(() => Boolean, { optional: true })
  isDefault?: boolean = false;

  @Of(() => String)
  currency? = '';

  @Of(() => Boolean)
  isSubscriptions = false;

  static of = Entity.of<AccountBillingProfile>();
}
