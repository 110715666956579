import { CreateSlotInput } from '@api/core/entities';
import { SystemDate } from '@lib/utils';

export const checkOverlap = (value: CreateSlotInput, slots: CreateSlotInput[]) => {
  if (!slots?.length) {
    return false;
  }

  for (let i = 0; i < slots.length; i++) {
    if (slots[i].startHour && slots[i].endHour && value.startHour) {
      const check = SystemDate.of(`2021-01-01T${value.startHour}`).raw.getTime();
      const start = SystemDate.of(`2021-01-01T${slots[i].startHour}`).raw.getTime();
      const end = SystemDate.of(`2021-01-01T${slots[i].endHour}`).raw.getTime();

      if (check >= start && check < end) {
        return true;
      }
    }
  }

  return false;
};

export const getIntervalDuration = (startHour?: string, endHour?: string) => {
  if (!startHour || !endHour) {
    return 50;
  }

  const start = SystemDate.of(`2021-01-01T${startHour}`).raw.getTime();
  const end = SystemDate.of(`2021-01-01T${endHour}`).raw.getTime();

  return (end - start) / 60000;
};
