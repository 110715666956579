import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { NumberInput, NumberInputProps } from '../../../atoms/NumberInput';
import { FormElement, FormElementProps } from '../FormElement';

export interface FormNumberInputProps extends FormElementProps<NumberInputProps> {}

export const FormNumberInput = forwardRef<HTMLInputElement, FormNumberInputProps>(
  (
    {
      testId,
      name,
      label,
      isRequired,
      helperText,
      showHelperText,
      onChangeCallback,
      inlineLayout,
      errorBehaviour,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation('shared');

    return (
      <FormElement
        {...{
          name,
          label,
          isRequired,
          helperText,
          showHelperText,
          testId,
          onChangeCallback,
          inlineLayout,
          errorBehaviour,
        }}
      >
        <NumberInput placeholder={t('general.type_here_ellipsis') ?? ''} {...props} />
      </FormElement>
    );
  }
);
