import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class Classes {
  @Of(() => String)
  clID: string = '';

  @Of(() => String)
  clNume: string = '';

  @Of(() => String)
  clNrOrdine: string = '';

  @Of(() => String)
  clAstID: string = '';

  @Of(() => String)
  clAstNume: string = '';

  @Of(() => String)
  clAscID: string = '';

  @Of(() => String)
  clTip: string = '';

  @Of(() => String)
  spID: string = '';

  @Of(() => String)
  clLitera: string = '';

  @Of(() => String)
  cicluID: string = '';

  @Of(() => String)
  cicluNume: string = '';

  @Of(() => String)
  ctID: string = '';

  @Of(() => String)
  ctNume: string = '';

  @Of(() => String)
  spNume: string = '';

  @Of(() => Lock)
  locks: Lock = Lock.of({});

  @Of(() => ClassBookInfo)
  classbookInfo: ClassBookInfo = ClassBookInfo.of({});

  @Of(() => Number, { optional: true })
  clRoomID?: number;

  @Of(() => String, { optional: true })
  clRoomName?: string;

  @Of(() => String)
  timetableScheduleID: string = '';

  @Of(() => [Person])
  coordinators: Person[] = [];

  static of = Entity.of<Classes>();
}

@Entity
class ClassBookInfo {
  @Of(() => String)
  director: string = '';

  @Of(() => String)
  secretary: string = '';

  @Of(() => String)
  headTeacher: string = '';

  @Of(() => String)
  signedDate: string = '';

  @Of(() => String)
  Profile: string = '';

  @Of(() => String)
  ProfessionalQualification: string = '';

  @Of(() => String)
  TrainingField: string = '';

  @Of(() => String)
  QualificationLevel: string = '';

  @Of(() => AveragesLocks)
  averagesLocks: AveragesLocks = AveragesLocks.of({});

  @Of(() => String)
  Branch: string = '';

  static of = Entity.of<ClassBookInfo>();
}

@Entity
class AveragesLocks {
  @Of(() => [Msm])
  msm: Msm[] = [];

  @Of(() => Mgm)
  mgm: Mgm = Mgm.of({});

  @Of(() => [Mgs])
  mgs: Mgs[] = [];

  @Of(() => Mga)
  mga: Mga = Mga.of({});

  static of = Entity.of<AveragesLocks>();
}

@Entity
class Msm {
  @Of(() => String)
  msmSanID: string = '';

  @Of(() => Boolean)
  msmLocked: boolean = false;

  static of = Entity.of<Msm>();
}

@Entity
class Mgm {
  @Of(() => Boolean)
  mgmLocked: boolean = false;

  @Of(() => Boolean)
  mgmIntermediaryLocked: boolean = false;

  static of = Entity.of<Mgm>();
}

@Entity
class Mgs {
  @Of(() => String)
  mgsSanID: string = '';

  @Of(() => Boolean)
  mgsLocked: boolean = false;

  static of = Entity.of<Mgs>();
}

@Entity
class Mga {
  @Of(() => Boolean)
  mgaLocked: boolean = false;

  @Of(() => Boolean)
  mgaIntermediaryLocked: boolean = false;

  static of = Entity.of<Mga>();
}

@Entity
export class Lock {
  @Of(() => String)
  count: string = '';

  @Of(() => Boolean)
  locked: boolean = false;

  static of = Entity.of<Lock>();
}
