import { Entity, Of } from 'entity-of';

@Entity
export class BankResponse {
  @Of(() => [Bank])
  result: Bank[] = [];

  static of = Entity.of<BankResponse>();
}

@Entity
export class Bank {
  @Of(() => Number)
  idBanca: number = 0;

  @Of(() => String)
  nume: string = '';

  @Of(() => String)
  adresa: string = '';

  @Of(() => String)
  swift: string = '';

  @Of(() => Boolean)
  activ: boolean = false;

  static of = Entity.of<Bank>();
}
