import { Entity, Of } from 'entity-of';

import { InvoiceType } from '../../Invoice';

@Entity
export class StatementDataSearchInvoicesInput {
  @Of(() => String, { optional: true })
  accountId?: string;

  @Of(() => String, { optional: true })
  createdStartDate?: string;

  @Of(() => String, { optional: true })
  type?: InvoiceType;

  @Of(() => Number, { optional: true })
  amount?: number;

  static of = Entity.of<StatementDataSearchInvoicesInput>();
}
