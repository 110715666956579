import { ORG_ADSERVIO, ORG_SCHOOL, ORG_UNIVERSITY } from '@constants';
import { useStorageSession } from '@lib/utils';
import { SchoolMenu } from '@modules/Site/components/main-menu/SchoolMenu';
import { UniversitiesMenu } from '@modules/Site/components/main-menu/UniversitiesMenu';

interface MainMenuProps {
  isPHP?: boolean;
}

export const MainMenu = ({ isPHP = false }: MainMenuProps) => {
  const { isNative, school, sessionHelpers } = useStorageSession();

  return (
    <>
      {isNative ? (
        <></>
      ) : (
        <>
          {(school?.liceuOrgType === ORG_SCHOOL ||
            school?.liceuOrgType === ORG_ADSERVIO ||
            sessionHelpers.isAdmin) && <SchoolMenu isPHP={isPHP} />}

          {school?.liceuOrgType === ORG_UNIVERSITY && !sessionHelpers.isAdmin && (
            <UniversitiesMenu isPHP={isPHP} />
          )}
        </>
      )}
    </>
  );
};
