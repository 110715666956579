import { Entity, Of } from 'entity-of';

@Entity
export class AttendanceActivitiesQueryInput {
  @Of(() => String, { optional: true })
  ascID?: string;

  @Of(() => String, { optional: true })
  cadID?: string;

  @Of(() => String, { optional: true })
  status?: string;

  @Of(() => String, { optional: true })
  name?: string;

  @Of(() => String, { optional: true })
  description?: string;

  @Of(() => String, { optional: true })
  timetableActivityID?: string;

  @Of(() => String, { optional: true })
  startDateTime?: string;

  @Of(() => String, { optional: true })
  endDateTime?: string;

  @Of(() => String, { optional: true })
  cmcID?: string;

  @Of(() => String, { optional: true })
  isVisibleStudents?: string;

  @Of(() => String, { optional: true })
  isScheduled?: string;

  static of = Entity.of<AttendanceActivitiesQueryInput>();
}
