import { Entity, Of } from 'entity-of';

@Entity
export class RecurrenceWidthResult {
  @Of(() => String)
  type: string = 'weekly';

  @Of(() => Result)
  result = Result.of({});

  static of = Entity.of<RecurrenceWidthResult>();
}

@Entity
class Result {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  endDate: string = '';

  @Of(() => Boolean)
  isPublished: boolean = false;

  @Of(() => String)
  progress: string = '';

  @Of(() => String)
  myProgress: string = '';

  @Of(() => String)
  startDate?: string = '';

  static of = Entity.of<Result>();
}
