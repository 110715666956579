import { useQuery, UseQueryOptions } from 'react-query';

import { City, LegacyPaginatedResponse } from '@api/core/entities';
import { httpClient } from '@lib/http';
import { computeUrlParams } from '@lib/utils';
import { AxiosError } from 'axios';

interface QueryInput {
  judID: string;
  limit?: number;
}

const searchCities = ({ queryKey }: UseQueryOptions<QueryInput>) => {
  const [_, queryPayload] = queryKey ?? [];
  const { judID, limit } = queryPayload as QueryInput;

  let url = `/adrese/cities`;

  const params = computeUrlParams({
    _limit: limit || -1,
    judID,
  });

  if (params) {
    url += `?${params}`;
  }

  return httpClient<LegacyPaginatedResponse<City>, City>(url).get();
};

export const useCitiesQuery = (
  queryInput: QueryInput,
  options?: UseQueryOptions<LegacyPaginatedResponse<City>, AxiosError>
) => {
  return useQuery<LegacyPaginatedResponse<City>, AxiosError>(
    ['cities', queryInput],
    searchCities,
    options
  );
};
