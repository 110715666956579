import { Entity, Of } from 'entity-of';

import { Charge, Invoice } from '..';

@Entity
export class Charge_Invoice extends Charge {
  @Of(() => Invoice)
  invoice: Invoice = Invoice.of({});

  static of = Entity.of<Charge_Invoice>();
}
