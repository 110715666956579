import { Faculty, Student } from '@modules/Universities/shared-universities';
import { Entity, Of } from 'entity-of';

import { Room } from '../Room';
import { AccommodationDocuments, PricePlan, StudentStudy } from '..';

export class Accommodation {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  studentId: number = 0;

  @Of(() => Number)
  facultyId: number = 0;

  @Of(() => Number)
  studentStudyId: number = 0;

  @Of(() => Number)
  pricePlanId: number = 0;

  @Of(() => Number)
  roomId: number = 0;

  @Of(() => Number, { nullable: true })
  toRoomId: number | null = null;

  @Of(() => Number)
  requestNumber: number = 0;

  @Of(() => Number, { nullable: true })
  checkOutRequestNumber: number | null = null;

  @Of(() => Number, { nullable: true })
  transferRequestNumber: number | null = null;

  @Of(() => String, { nullable: true })
  transferDate: string | null = null;

  @Of(() => Number)
  noOfBeds: number = 0;

  @Of(() => String)
  checkInDate: string = '';

  @Of(() => String)
  checkOutDate: string = '';

  @Of(() => String, { nullable: true })
  contractNumber: string | null = null;

  @Of(() => String, { nullable: true })
  contractStartDate: string | null = null;

  @Of(() => String, { nullable: true })
  contractEndDate: string | null = null;

  @Of(() => String)
  didacticPeriodEndDate: string = '';

  @Of(() => Boolean)
  isRequestOverdue: boolean = false;

  @Of(() => String, { optional: true })
  status?: AccommodationStatus;

  @Of(() => String, { nullable: true })
  carNumber: string | null = null;

  @Of(() => Number)
  operatorId: number | null = null;

  @Of(() => String)
  operatorName: string | null = null;

  @Of(() => AccommodationDocuments)
  documents: AccommodationDocuments = AccommodationDocuments.of({});

  @Of(() => String)
  observations: string = '';

  @Of(() => Student)
  student: Student = Student.of({});

  @Of(() => StudentStudy)
  studentStudy: StudentStudy = StudentStudy.of({});

  @Of(() => String)
  language: 'ro' | 'en' = 'ro';

  @Of(() => Faculty)
  faculty: Faculty = Faculty.of({});

  @Of(() => Room)
  room: Room = Room.of({});

  @Of(() => Room, { optional: true })
  transferRoom?: Room = Room.of({});

  @Of(() => PricePlan)
  pricePlan: PricePlan = PricePlan.of({});

  @Of(() => Boolean, { optional: true })
  isReserved?: boolean;

  @Of(() => Date, { optional: true })
  updatedAt?: Date;

  @Of(() => User)
  user: User = User.of({});

  static of = Entity.of<Accommodation>();
}

export type AccommodationStatus =
  | 'request'
  | 'reserved'
  | 'cancelled'
  | 'checked_in'
  | 'transfer'
  | 'check_out_overdue'
  | 'checked_out'
  | 'transferred';

export const AccommodationStatuses = [
  'request',
  'reserved',
  'cancelled',
  'checked_in',
  'transfer',
  'check_out_overdue',
  'checked_out',
  'transferred',
];

@Entity
export class User {
  @Of(() => String, { nullable: true })
  firstName: string | null = null;

  @Of(() => String, { nullable: true })
  lastName: string | null = null;

  @Of(() => String, { nullable: true })
  username: string | null = null;

  static of = Entity.of<User>();
}
