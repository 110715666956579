import { Address } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class TreeOrganization {
  @Of(() => String)
  liceuID: string = '';

  @Of(() => String)
  liceuNume: string = '';

  @Of(() => String)
  liceuClasificare: string = '';

  @Of(() => Address, { optional: true })
  liceuAdresa?: Address;

  @Of(() => Boolean, { optional: true })
  isEligible?: boolean = false;

  @Of(() => String, { optional: true })
  liceuRepUaID?: string = '';

  @Of(() => String, { optional: true })
  rootOrgId?: string = '';

  @Of(() => Boolean, { optional: true })
  withoutBillingProfile?: boolean = false;

  static of = Entity.of<TreeOrganization>();
}
