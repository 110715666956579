import { PersonClass, Role } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class Student {
  @Of(() => String)
  elevAvatar: string = '';

  @Of(() => String)
  elevCnp: string = '';

  @Of(() => String)
  elevDataNasterii: string = '';

  @Of(() => String)
  elevID: string = '';

  @Of(() => String)
  elevNume: string = '';

  @Of(() => String)
  elevPrenume: string = '';

  @Of(() => String)
  elevSex: string = '';

  @Of(() => String)
  e2cIsActive?: string = '0';

  @Of(() => String)
  elevStatusID: ElevStatusID = ElevStatusID.INACTIVE;

  @Of(() => String)
  elevInitTata = '';

  @Of(() => [Role])
  roles: Role[] = [];

  @Of(() => PersonClass, { optional: true })
  clasa?: PersonClass;

  static of = Entity.of<Student>();
}

export enum ElevStatusID {
  INACTIVE = '0',
  ACTIVE = '1',
  TRANSFERRED_GONE = '3',
  TRANSFERRED_INCOME = '4',
  TRANSFERRED_SCHOLARSHIP = '5',
  ALUMNI = '6',
}
