import React from 'react';

export type { SpinnerProps } from '@chakra-ui/react';

import { Spinner as SpinnerCK, SpinnerProps } from '@chakra-ui/react';
import { getOrgTypeTheme } from '@ui2/utils';

const baseColor = getOrgTypeTheme('blue');

export const Spinner = ({ variant, ...props }: React.PropsWithChildren<SpinnerProps>) => {
  return (
    <SpinnerCK
      thickness="2px"
      speed="0.65s"
      emptyColor={`${baseColor}.200`}
      color={`${baseColor}.600`}
      size="xl"
      {...props}
    />
  );
};
