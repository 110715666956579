import { SimpleQueryInput } from '@api/core/entities/shared/SimpleQueryInput';
import { Entity, Of } from 'entity-of';

@Entity
export class SearchTeachersInput extends SimpleQueryInput {
  @Of(() => String, { optional: true })
  ascID?: string = '';

  @Of(() => String, { optional: true })
  clID?: string = '';

  @Of(() => String, { optional: true })
  matID?: string = '';

  @Of(() => String, { optional: true })
  astID?: string = '';

  @Of(() => String, { optional: true })
  cadID?: string = '';

  @Of(() => String, { optional: true })
  roleID?: string = '';

  @Of(() => String, { optional: true })
  cadNume?: string = '';

  @Of(() => String, { optional: true })
  cadNumePrenume?: string = '';

  @Of(() => String, { optional: true })
  cadCnp?: string = '';

  @Of(() => String, { optional: true })
  cadIsDeleted?: string = '';

  @Of(() => String, { optional: true })
  cadIsSuspended?: string = '';

  static of = Entity.of<SearchTeachersInput>();
}
