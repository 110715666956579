import { Entity, Of } from 'entity-of';

@Entity
export class AttendanceSummary {
  @Of(() => SimpleSummary)
  present: SimpleSummary = SimpleSummary.of({});

  @Of(() => SimpleSummary)
  missed: SimpleSummary = SimpleSummary.of({});

  @Of(() => SimpleSummary)
  scheduled: SimpleSummary = SimpleSummary.of({});

  @Of(() => MixedSummary)
  mixed: MixedSummary = MixedSummary.of({});

  @Of(() => PtoSummary)
  pto: PtoSummary = PtoSummary.of({});

  @Of(() => Number)
  totalCount: number = 0;

  static of = Entity.of<AttendanceSummary>();
}

@Entity
class SimpleSummary {
  @Of(() => String)
  days: number = 0;

  @Of(() => String)
  hours: number = 0;

  static of = Entity.of<SimpleSummary>();
}

@Entity
class PtoSummary {
  @Of(() => String)
  days: number = 0;

  @Of(() => String)
  persons: number = 0;

  static of = Entity.of<PtoSummary>();
}

@Entity
class MixedSummary {
  @Of(() => String)
  days: number = 0;

  @Of(() => MixedHoursSummary)
  hours: MixedHoursSummary = MixedHoursSummary.of({});

  static of = Entity.of<MixedSummary>();
}

@Entity
class MixedHoursSummary {
  @Of(() => String)
  present: number = 0;

  @Of(() => String)
  missed: number = 0;

  static of = Entity.of<MixedHoursSummary>();
}
