import { Entity, Of } from 'entity-of';

@Entity
export class StatementMap {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  tenantId: string = '';

  @Of(() => String, { optional: true, nullable: true })
  date?: string;

  @Of(() => String, { optional: true, nullable: true })
  type?: string;

  @Of(() => String, { optional: true, nullable: true })
  amount?: string;

  @Of(() => String, { optional: true, nullable: true })
  currency?: string;

  @Of(() => String)
  details: string[] = [];

  static of = Entity.of<StatementMap>();
}
