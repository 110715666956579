import { Period, Vacation } from '@api/core/entities';
import { ValidateNested } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class YearStructure {
  @Of(() => String)
  schoolYear: string = '';

  @Of(() => [Period])
  @ValidateNested()
  periods: Period[] = [];

  @Of(() => [Vacation])
  @ValidateNested()
  vacations: Vacation[] = [];

  @Of(() => String)
  yearStartDate: string = '';

  @Of(() => String)
  yearEndDate: string = '';

  @Of(() => [String])
  deletedVacations: string[] = [];

  static of = Entity.of<YearStructure>();
}
