import { Entity, Of } from 'entity-of';

@Entity
export class Warning {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  date: string = '';

  @Of(() => String)
  description: string = '';

  static of = Entity.of<Warning>();
}
