import { Entity, Of } from 'entity-of';

@Entity
export class Parent {
  @Of(() => String)
  epID = '';

  @Of(() => String)
  epElevId = '';

  @Of(() => String)
  epNume = '';

  @Of(() => String)
  epPrenume = '';

  @Of(() => String)
  epSex = '';

  @Of(() => String)
  epTel = '';

  @Of(() => String)
  epTip = '';

  @Of(() => String)
  epEmail = '';

  @Of(() => String)
  epAvatar = '';

  static of = Entity.of<Parent>();
}
