import { Entity, Of } from 'entity-of';

@Entity
export class FillersDetail {
  @Of(() => String)
  clID: string = '';

  @Of(() => String)
  clNume: string = '';

  static of = Entity.of<FillersDetail>();
}
