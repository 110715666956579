import { Entity, Of } from 'entity-of';

@Entity
export class Translation {
  @Of(() => String)
  langId: string = '';

  @Of(() => String)
  value: string = '';

  static of = Entity.of<Translation>();
}
