import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

import { Invoice } from '..';

@Entity
export class Invoice_Person extends Invoice {
  @Of(() => Person)
  person: Person = Person.of({});

  @Of(() => String, { optional: true })
  emitent?: string = '';

  static of = Entity.of<Invoice_Person>();
}
