import { Person } from '@api/core/entities';
import { CONDUCT_DEVIATION, CONDUCT_REMARK, CONDUCT_REWARD } from '@constants';
import { Entity, Of } from 'entity-of';

export type BehaviorType = typeof CONDUCT_REWARD | typeof CONDUCT_DEVIATION | typeof CONDUCT_REMARK;

@Entity
export class Conduct {
  @Of(() => String)
  cmeID: string = '';

  @Of(() => String)
  cmeText: string = '';

  @Of(() => String, { nullable: true })
  cmePunctaj: string | null = null;

  @Of(() => String)
  cmeTip: BehaviorType = '1';

  @Of(() => String)
  cmeData: string = '';

  @Of(() => String)
  cmeDataInsert: string = '';

  @Of(() => Person)
  cadru: Person = Person.of({});

  static of = Entity.of<Conduct>();
}
