import { Account, Person, Student } from '@api/core/entities';
import { TYPE_CANDIDATE } from '@constants';

import { ProfileDetails } from '../ProfileDetails';

interface AccountDetailsProps {
  account: Account;
}

export const AccountDetails = ({ account }: AccountDetailsProps) => {
  return (
    <>
      <ProfileDetails
        alignItems={account.uaTip === TYPE_CANDIDATE ? 'center' : 'flex-start'}
        size="sm"
        profile={Person.of({
          ...account,
          prefAvatar: account.uaPrefAvatar,
          institutie: account.inst,
        })}
        student={Student.of({ ...account })}
        showSchoolDetails
        isAccountCard
      />
    </>
  );
};
