import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PhoneInput, PhoneInputProps } from '../../PhoneInput';
import { FormElement, FormElementProps } from '../FormElement';

export interface FormPhoneInputProps extends FormElementProps<PhoneInputProps> {}

export const FormPhoneInput = forwardRef<HTMLInputElement, FormPhoneInputProps>(
  (
    { testId, name, label, isRequired, helperText, showHelperText, errorBehaviour, ...props },
    ref
  ) => {
    const { t } = useTranslation('shared');

    return (
      <FormElement
        {...{ name, label, isRequired, helperText, showHelperText, errorBehaviour, testId }}
      >
        <PhoneInput ref={ref} placeholder={t('general.type_here_ellipsis') ?? ''} {...props} />
      </FormElement>
    );
  }
);
