import { PropsWithChildren } from 'react';

import { useBannerHeight } from '@modules/Site/components/disruptionBanner';
import { AdservioIcons, Box, ButtonProps, HStack, IconButton } from '@ui2/components';

interface NavbarWrapperProps {
  isPHP?: boolean;
}

export const NavbarWrapper = ({ isPHP, children }: PropsWithChildren<NavbarWrapperProps>) => {
  const { bannerHeight } = useBannerHeight();

  return (
    <Box
      position={isPHP ? 'fixed' : 'relative'}
      top={isPHP ? `${bannerHeight}px` : '0'}
      left="0"
      width="calc(100vw - (100vw - 100%))"
      py="2"
      bgColor="white"
      borderBottom="1px solid"
      borderColor="gray.50"
      zIndex="overlay"
      sx={{ '@media print': { display: 'none' } }}
    >
      {children}
    </Box>
  );
};

interface NavbarElementsWrapperProps {
  contentWidth: 'normal' | 'wide';
}

export const NavbarElementsWrapper = ({
  contentWidth = 'normal',
  children,
}: PropsWithChildren<NavbarElementsWrapperProps>) => {
  let contentStyles: Record<string, any> = {
    maxWidth: '1800px',
    pl: { base: '4', md: '116px' },
    pr: { base: '4', md: '8', maxContent: '116px' },
  };

  if (contentWidth === 'wide') {
    contentStyles = {
      maxWidth: '1920px',
      px: { base: '4', md: '8' },
    };
  }

  return (
    <HStack justifyContent="space-between" mx="auto" {...contentStyles}>
      {children}
    </HStack>
  );
};

export const NavigationButton = ({ ...props }: ButtonProps) => {
  return (
    <IconButton
      display={{ base: 'block', md: 'none' }}
      icon={<AdservioIcons.Bento boxSize="6" />}
      aria-label="Open menu"
      colorScheme="gray"
      variant="ghost"
      color="initial"
      {...props}
    />
  );
};
