import { Entity, Of } from 'entity-of';

@Entity
export class GraduateExportInput {
  @Of(() => String, { optional: true })
  ascID?: string = '';

  @Of(() => String, { optional: true })
  sanID?: string;

  @Of(() => String, { optional: true })
  clID?: string;

  @Of(() => String, { optional: true })
  mgIntermediara?: string;

  @Of(() => Number)
  export_xls: number = 1;

  static of = Entity.of<GraduateExportInput>();
}
