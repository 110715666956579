import { Entity, Of } from 'entity-of';

import { FullDetailedCharge } from '../../Charge';
import { SerializedExchangeRates } from '../Invoice';

@Entity
export class InvoiceEditInput {
  @Of(() => Number)
  totalAmount: number = 0;

  @Of(() => Number, { optional: true })
  netAmount?: number;

  @Of(() => Number, { optional: true })
  vatAmount?: number;

  @Of(() => Number)
  remainingAmount: number = 0;

  @Of(() => [FullDetailedCharge])
  charges: FullDetailedCharge[] = [];

  @Of(() => String)
  otherInformation: string = '';

  @Of(() => String)
  invoiceDate: string = '';

  @Of(() => String)
  dueDate: string = '';

  @Of(() => Number)
  accountBillingProfileId: number = 0;

  @Of(() => SerializedExchangeRates, { optional: true })
  exchangeRates? = SerializedExchangeRates.of({});

  static of = Entity.of<InvoiceEditInput>();
}
