import { IsTreeShape } from '@modules/shared/components/SelectOrganizationTree/entities/CustomDecorators/IsTreeSelectionShape';
import { TreeSelection } from '@modules/shared/components/SelectOrganizationTree/entities/TreeSelection';
import { Entity, Of } from 'entity-of';

@Entity
export class PublishPackageSelection {
  @Of(() => TreeSelection)
  @IsTreeShape({ message: 'errors.selection.required' })
  selection = {};

  static of = Entity.of<PublishPackageSelection>();
}
