import { Entity, Of } from 'entity-of';

@Entity
export class Nationality {
  @Of(() => String)
  natLangKey = '';

  @Of(() => String)
  natDefault = '';

  @Of(() => String)
  natID = '';

  @Of(() => String)
  natName = '';

  static of = Entity.of<Nationality>();
}
