import { Entity, Of } from 'entity-of';

@Entity
export class Group {
  @Of(() => String)
  id = '';

  @Of(() => String)
  name = '';

  @Of(() => Number)
  membersCount = 0;

  @Of(() => [Group], { optional: true })
  subGroups?: Group[] = [];

  @Of(() => String, { nullable: true })
  parentId: string | null = null;

  static of = Entity.of<Group>();

  static subGroupIds(group: Group): string[] {
    if (!group.subGroups) {
      return [];
    }

    return group.subGroups.flatMap((g) =>
      g.subGroups?.length ? [...Group.subGroupIds(g), g.id] : g.id
    );
  }

  static parentIds(group: Group, groups: Group[]): string[] {
    let ids: string[] = [];

    groups.forEach((g) => {
      if ([g.id, ...this.subGroupIds(g)].includes(group.id)) {
        ids = ids.concat([g.id, ...this.parentIds(group, g.subGroups || [])]);
      }
    });

    return ids;
  }

  static isPartial(
    group: Group,
    groupIds: string[],
    accountIds: Record<string, string[]>
  ): boolean {
    return (
      groupIds.some((id) => Group.subGroupIds(group).includes(id)) ||
      group.subGroups?.some((g) => Group.isPartial(g, groupIds, accountIds)) ||
      accountIds[group.id]?.length > 0
    );
  }

  static isSelected(
    group: Group,
    groupIds: string[],
    accountIds: Record<string, string[]>
  ): boolean {
    return (
      groupIds.includes(group.id) ||
      ((group.subGroups || []).length > 0 &&
        group.subGroups?.every((g) => Group.isSelected(g, groupIds, accountIds))) ||
      (!group.subGroups?.length &&
        group.membersCount > 0 &&
        accountIds[group.id]?.length === group.membersCount)
    );
  }

  static countSelectedSubgroups(group: Group, selection: string[]): number {
    const total = 0;

    if (group && selection) {
      return selection.filter((s) => group.subGroups?.map((g) => g.id)?.includes(s))?.length;
    }

    return total;
  }

  static countSelectedGroupsMembers(
    groups: Group[] | undefined,
    groupIds: string[],
    total = 0
  ): number {
    let t = total;
    let targetGroups: Group[] = [];

    if (groups) {
      targetGroups = groups.filter((g) => groupIds.includes(g.id));

      if (targetGroups.length > 0) {
        t += targetGroups.map((g) => g.membersCount).reduce((acc = 0, curr) => acc + curr);
      }

      groups?.forEach((g) => {
        if (g.subGroups?.length) {
          t += Group.countSelectedGroupsMembers(g.subGroups, groupIds);
        }
      });
    }

    return t;
  }
}
