import { Entity, Of } from 'entity-of';

import { InvoicedHistoryItem } from '../InvoicedHistoryItem';

@Entity
export class InvoicedHistory {
  @Of(() => Number)
  tenantBillingProfileId: number = 0;

  @Of(() => [InvoicedHistoryItem])
  invoiced: InvoicedHistoryItem[] = [];

  @Of(() => [InvoicedHistoryItem])
  charged: InvoicedHistoryItem[] = [];

  static of = Entity.of<InvoicedHistory>();
}
