import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const TableElementsTheme: ComponentMultiStyleConfig = {
  parts: ['table', 'th', 'tbody', 'tfoot', 'td', 'caption'],
  baseStyle: {
    table: {
      tr: {
        '&:first-of-type': {
          th: {
            px: '4',
            py: '3',
          },
        },
      },
    },
    th: {
      color: 'gray.700',
      backgroundColor: 'gray.50',
    },
    tbody: {
      td: {
        px: '4',
        py: '3',
        fontSize: 'sm',
        fontWeight: '500',
        color: 'gray.700',
        alignItems: 'center',
        verticalAlign: 'middle',
        backgroundColor: 'white',
        borderBottomColor: 'gray.200',
        transitionProperty: 'common',
        transitionDuration: 'normal',
      },
      tr: {
        _hover: {
          td: {
            backgroundColor: 'gray.50',
          },
        },
      },
    },
    tfoot: {
      tr: {
        th: {
          bgColor: 'white',
          px: '4',
          py: '3',
          '&:first-of-type': {
            borderTopLeftRadius: '0px !important',
            borderBottomLeftRadius: 'lg',
          },
          '&:last-of-type': {
            borderTopRightRadius: '0px !important',
            borderBottomRightRadius: 'lg',
          },
        },
      },
    },
  },
};
