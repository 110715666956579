import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

export enum ClassesLocksItemType {
  CREATED = 'created',
  DELETED = 'deleted',
}
@Entity
export class ClassesLocksHistory {
  @Of(() => String)
  type: string = '';

  @Of(() => String)
  dateStart: string = '';

  @Of(() => String)
  dateEnd: string = '';

  @Of(() => String)
  reason: string = '';

  @Of(() => String)
  actionDate?: string = '';

  @Of(() => Person)
  createdBy: Person = Person.of({});

  @Of(() => String)
  createdByName: string = '';

  static of = Entity.of<ClassesLocksHistory>();
}
