import { useState } from 'react';

import { useFacultyState, useUserTypeState } from '@modules/Universities/hooks';
import { useScholarshipPermissions } from '@modules/Universities/scholarships/hooks';
import { useAssociatedFacultiesQuery } from '@modules/Universities/shared-universities';

export const useUniversityData = () => {
  const { userType } = useUserTypeState();
  const { facultyId, setFacultyId } = useFacultyState();

  const [enabled, setEnabled] = useState<boolean>(false);
  const {
    allowAllFacultiesScholarship,
    allowAllocateScholarship,
    allowBudgetAccess,
    isScholarshipReportManager,
  } = useScholarshipPermissions();

  let userTypeForQuery =
    userType === 'admin' || userType === 'superAdmin' ? 'associate' : userType || 'student';

  // Asta este incorecta. Dar momentan o lasam asa si avem un task de refactor care refactorizeaza tot ce tine de useUserTypeState
  if (userTypeForQuery === 'student') {
    if (
      allowAllFacultiesScholarship ||
      allowAllocateScholarship ||
      allowBudgetAccess ||
      isScholarshipReportManager
    ) {
      userTypeForQuery = 'associate';
    }
  }

  const { data: faculties, isLoading } = useAssociatedFacultiesQuery(
    {
      userType: userTypeForQuery,
    },
    {
      enabled: !facultyId || enabled,
      onSuccess: (data) => {
        if (!facultyId) {
          setFacultyId(data[0].id);
          setEnabled(false);
        }
      },
    }
  );

  const rerun = () => {
    setEnabled(true);
  };

  return {
    rerun,
    faculties: faculties || [],
    isLoading,
  };
};
