import React from 'react';

import { Box, FlexProps, Text } from '../../atoms';

import { Wrapper } from './ContentEmptyState.layout';

interface ContentEmptyStateProps extends FlexProps {
  title?: string;
  description?: string | string[];
  isInvalid?: boolean;
  renderHeader?: () => React.ReactElement;
  renderFooter?: () => React.ReactElement;
}

export const ContentEmptyState = ({
  title,
  description,
  isInvalid,
  renderHeader,
  renderFooter,
  ...rest
}: ContentEmptyStateProps) => {
  return (
    <Wrapper isInvalid={isInvalid} {...rest}>
      {renderHeader?.()}

      {(title || description) && (
        <Box>
          {title && (
            <Text mb="1" fontWeight="medium" fontSize="md" color="gray.700" textAlign="center">
              {title}
            </Text>
          )}

          {description && typeof description === 'string' && (
            <Text fontSize="sm" color="gray.400" textAlign="center">
              {description}
            </Text>
          )}
          {description && Array.isArray(description) && (
            <Box textAlign="center">
              {description.map((desc, index) => (
                <Text key={index} fontSize="sm" color="gray.400">
                  {desc}
                </Text>
              ))}
            </Box>
          )}
        </Box>
      )}

      {renderFooter?.()}
    </Wrapper>
  );
};
