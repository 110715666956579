import { useQuery, UseQueryOptions } from 'react-query';

import { httpClient } from '@lib/http';

import { Faculty, FacultyInput } from '../entities';

type QueryInput = FacultyInput;

const getAssociatedFacultiesQuery = ({ queryKey }: UseQueryOptions<QueryInput>) => {
  const [_, queryPayload] = queryKey ?? [];
  const { userType } = queryPayload as FacultyInput;
  let url = `/uni/faculty/${userType}`;
  return httpClient<Faculty[]>(url).get();
};
export const useAssociatedFacultiesQuery = (
  queryInput: Partial<QueryInput>,
  options?: UseQueryOptions<any>
) => {
  return useQuery<Faculty[]>(['department', queryInput], getAssociatedFacultiesQuery, options);
};
