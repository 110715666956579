import React, { forwardRef, ReactNode } from 'react';

import {
  IconButton,
  InputElementProps,
  InputGroup as CKInputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputProps,
  InputRightAddon,
  InputRightElement,
} from '@chakra-ui/react';
import { getOrgTypeTheme } from '@ui2/utils';

import { AdservioIcons } from '../Icons';
import { Input } from '../Input';

export interface InputGroupProps extends InputProps {
  /**
   * If present, it renders a ReactNode in the prefix possition of the Input field
   *
   * eg: <SearchIcon />
   */
  leftElement?: ReactNode;
  /**
   * It present, it renders a ReactNode in the sufix possition of the Input field
   */
  rightElement?: ReactNode;
  /**
   * If present, it will pass the declared styling props to the `InputLeftElement` component
   */
  leftElementSx?: InputElementProps;
  /**
   * If present, it will pass the declared styling props to the `InputRightElement` component
   */
  rightElementSx?: InputElementProps;
  isDisabled?: boolean;
  /**
   * If `true`, the input will be invalid. This has 1 side effect:
   * - The form element (e.g, Input) will have `aria-invalid` set to `true`
   */
  leftAddon?: ReactNode;
  rightAddon?: ReactNode;
  leftAddonSx?: InputElementProps;
  rightAddonSx?: InputElementProps;
  inputSx?: InputElementProps;
  enableIsolation?: boolean;
  isInvalid?: boolean;
  isReadOnly?: boolean;
  value?: string | number;
  name?: string;
  autoComplete?: string;
  placeholder?: string;
  isClearable?: boolean;
}

export const InputGroup = forwardRef<HTMLDivElement | HTMLInputElement, InputGroupProps>(
  (
    {
      size,
      leftElement,
      rightElement,
      inputSx,
      leftElementSx,
      rightElementSx,
      leftAddon,
      rightAddon,
      leftAddonSx,
      rightAddonSx,
      enableIsolation,
      isReadOnly,
      isClearable = false,
      ...props
    },
    ref
  ) => {
    const baseColor = getOrgTypeTheme('blue');

    const IconButtonSizeDict = {
      sm: 'xs',
      md: 'sm',
      lg: 'md',
    };

    return (
      <CKInputGroup size={size} isolation={enableIsolation ? undefined : 'auto'}>
        {leftAddon && <InputLeftAddon {...leftAddonSx}>{leftAddon}</InputLeftAddon>}
        {leftElement && (
          <InputLeftElement zIndex="initial" color="gray.400" {...leftElementSx}>
            {leftElement}
          </InputLeftElement>
        )}
        <Input {...props} readOnly={isReadOnly} ref={ref as any} {...inputSx} />

        {rightElement && (
          <InputRightElement zIndex={2} {...rightElementSx}>
            {rightElement}
          </InputRightElement>
        )}

        {isClearable && String(props.value).length > 0 && (
          <InputRightElement
            zIndex={2}
            onClick={() => props.onChange?.({ target: { value: '' } } as any)}
          >
            <IconButton
              aria-label="remove-btn"
              variant="ghost"
              colorScheme="gray"
              size={IconButtonSizeDict[String(size || 'md')]}
              icon={<AdservioIcons.FiX boxSize="6" color={`${baseColor}.500`} />}
            />
          </InputRightElement>
        )}

        {rightAddon && <InputRightAddon {...rightAddonSx}>{rightAddon}</InputRightAddon>}
      </CKInputGroup>
    );
  }
);

export { CKInputGroup, InputLeftElement, InputRightElement };
