import { PhoneWithPrefix } from '@api/core/entities';
import { PhoneNumberWithPrefix } from '@lib/validators';
import { ArrayNotEmpty, IsEmail, IsNotEmpty, Matches, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

import 'reflect-metadata';

@Entity
export class TeacherForm {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.lastName.required' })
  cadNume: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.firstName.required' })
  cadPrenume: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.cadCnp)
  @Matches(/^[0-9]+$/, { message: 'errors.socialSecurityNumber.only_digits' })
  cadCnp: string = '';

  @Of(() => String)
  cadDataNasterii: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.sex.required' })
  cadSex: string = '';

  @ValidateIf((o) => o.emailIntampinare.length > 0 || !!o.cadEmail, {
    message: 'errors.email.isValid',
  })
  @IsEmail(undefined, { message: 'errors.email.isValid' })
  @Of(() => String)
  cadEmail: string = '';

  @Of(() => PhoneWithPrefix)
  @PhoneNumberWithPrefix({ message: 'errors.phoneWithPrefix.invalid' })
  cadTelefon: PhoneWithPrefix = PhoneWithPrefix.of({});

  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.roles.required' })
  cadRol: string[] = [];

  @Of(() => [String])
  emailIntampinare: string[] = [];

  static of = Entity.of<TeacherForm>();
}
