import { Entity, Of } from 'entity-of';

@Entity
export class TreeCounty {
  @Of(() => String)
  countScoli: string = '';

  @Of(() => String)
  judID: string = '';

  @Of(() => String)
  judNume: string = '';

  static of = Entity.of<TreeCounty>();
}
