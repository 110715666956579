import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SystemStyleObject } from '@chakra-ui/react';
import { useKey } from 'rooks';

import { useUrlState } from '../../../../../Libs/hooks';
import {
  AdservioIcons,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  NumberInput,
  Text,
} from '../../../atoms';

const baseStyles: SystemStyleObject = {
  px: '4',
  py: '3',
  fontSize: 'sm',
  fontWeight: '500',
  color: 'gray.700',
  justifyContent: 'space-between',
  backgroundColor: 'white',
  transitionProperty: 'common',
  transitionDuration: 'normal',
  borderBottomLeftRadius: 'xl',
  borderBottomRightRadius: 'xl',
  '@media print': { display: 'none' },
};

interface TablePaginationProps {
  totalItemsCount: number;
  pageSizeOptions?: number[];
}

export const TablePagination = React.forwardRef<HTMLDivElement, TablePaginationProps>(
  ({ totalItemsCount, pageSizeOptions = [10, 25, 50] }, ref) => {
    const { pageIndex, pageSize, setPage, setSize } = useUrlState();

    const pageCount = Math.ceil(Number(totalItemsCount || 0) / pageSize);

    const handlePageSizeChange = (pageSize: number) => setSize(pageSize);

    const handlePageChange = (pageIndex: number) => {
      if (pageIndex > pageCount) {
        setPage(pageCount);
        return;
      }

      if (pageIndex < 0) {
        setPage(0);
        return;
      }

      setPage(pageIndex);
    };

    return (
      <>
        {totalItemsCount > pageSizeOptions[0] && (
          <Flex ref={ref} data-testid="table-pagination" sx={baseStyles}>
            <PageSizeSelect
              value={pageSize}
              pageSizeOptions={pageSizeOptions}
              onPageSizeChange={handlePageSizeChange}
              onPageChange={handlePageChange}
            />

            <PageNavigation
              pageCount={pageCount}
              pageIndex={pageIndex}
              onPageChange={handlePageChange}
            />
          </Flex>
        )}
      </>
    );
  }
);

interface PageSizeSelectProps {
  value?: number;
  pageSizeOptions: number[];
  onPageSizeChange?: (pageSize: number) => void;
  onPageChange: (page: number) => void;
}

export const PageSizeSelect = ({
  pageSizeOptions,
  onPageSizeChange,
  onPageChange,
  value,
}: PageSizeSelectProps) => {
  const { t } = useTranslation('shared');

  const handleChange = (value: number) => () => {
    onPageSizeChange?.(Number(value));
    onPageChange(0);
  };

  return (
    <HStack spacing="2">
      <Text color="gray.400">{t('general.displayRows')}</Text>
      <ButtonGroup
        isAttached
        variant="outline"
        colorScheme="gray"
        data-testid="display-rows-group"
        sx={{
          '& > button': {
            '&:not(:first-of-type)': {
              borderLeft: 0,
            },
          },
        }}
      >
        {pageSizeOptions.map((option) => (
          <Button
            key={option}
            data-testid="display-rows-option"
            bg={value === option ? 'gray.100' : 'white'}
            onClick={handleChange(option)}
          >
            {option}
          </Button>
        ))}
      </ButtonGroup>
    </HStack>
  );
};

interface PageNavigationProps {
  pageIndex: number;
  pageCount: number;
  onPageChange: (pageIndex: number) => void;
}

export const PageNavigation = ({ pageIndex, onPageChange, pageCount }: PageNavigationProps) => {
  const { t } = useTranslation('shared');

  return (
    <HStack alignItems="center">
      <IconButton
        colorScheme="gray"
        variant="outline"
        data-testid="go-to-first-page"
        disabled={pageIndex === 0}
        isDisabled={pageIndex === 0}
        onClick={() => onPageChange(0)}
        aria-label="go to first page"
        icon={<AdservioIcons.FiChevronsLeft />}
      />

      <IconButton
        colorScheme="gray"
        variant="outline"
        data-testid="go-to-previous-page"
        disabled={pageIndex === 0}
        isDisabled={pageIndex === 0}
        aria-label="go to previous page"
        onClick={() => onPageChange(pageIndex - 1)}
        icon={<AdservioIcons.FiChevronLeft />}
      />

      <PageJump pageCount={pageCount} pageIndex={pageIndex} onPageChange={onPageChange} />

      <IconButton
        colorScheme="gray"
        variant="outline"
        data-testid="go-to-next-page"
        aria-label="go to next page"
        disabled={pageIndex === pageCount - 1}
        isDisabled={pageIndex === pageCount - 1}
        onClick={() => onPageChange(pageIndex + 1)}
        icon={<AdservioIcons.FiChevronRight />}
      />

      <IconButton
        colorScheme="gray"
        variant="outline"
        data-testid="go-to-last-page"
        aria-label="go to last page"
        disabled={pageIndex === pageCount - 1}
        isDisabled={pageIndex === pageCount - 1}
        onClick={() => onPageChange(pageCount - 1)}
        icon={<AdservioIcons.FiChevronsRight />}
      />
      <Text color="gray.400">
        {t('general.of')} {pageCount}
      </Text>
    </HStack>
  );
};

interface PageJumpProps {
  pageCount: number;
  pageIndex: number;
  onPageChange: (pageIndex: number) => void;
}

export const PageJump = ({ onPageChange, pageCount, pageIndex }: PageJumpProps) => {
  const [val, setVal] = useState(pageIndex + 1 || 1);

  const handlePageChange = () => {
    let next = val - 1;

    if (val === 0 || next <= 0 || next >= pageCount) {
      next = 0;
      setVal(1);
    }

    onPageChange(next);
  };

  const handleChange = (value: number | string) => {
    if (value === null || Number.isNaN(value)) {
      setVal(0);

      return;
    }
    setVal(Number(value));
  };

  useKey('Enter', handlePageChange);

  useEffect(() => {
    setVal(pageIndex + 1);
  }, [pageIndex]);

  return (
    <NumberInput
      min={1}
      maxW="4.5rem"
      textAlign="center"
      hideSteppers
      max={pageCount}
      id="page-jump-input"
      data-testid="page-jump"
      onChange={handleChange}
      onBlur={handlePageChange}
      value={val}
      isDisabled={pageCount === 0}
    />
  );
};
