import { Role } from '@modules/Universities/university-personal/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class StudentsStaffSearchResponse {
  @Of(() => String, { optional: true })
  searchTerm?: string = '';

  @Of(() => String, { optional: true })
  cadCnp?: string = '';

  @Of(() => String, { optional: true })
  cadNumePrenume?: string = '';

  @Of(() => Number)
  perssonId: number = 0;

  @Of(() => String)
  firstName: string = '';

  @Of(() => String)
  lastName: string = '';

  @Of(() => String)
  actualName: string = '';

  @Of(() => String)
  parentInitial: string = '';

  @Of(() => String)
  cnp: string = '';

  @Of(() => Number)
  uaID: number = 0;

  @Of(() => String)
  universityEmail: string = '';

  @Of(() => [Role], { optional: true })
  roles?: Role[] = [];

  static of = Entity.of<StudentsStaffSearchResponse>();
}
