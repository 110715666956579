import { Entity, Of } from 'entity-of';

import { PersonClass } from '../../classes';
import { Subject } from '../../materii';
import { Person } from '../../utilizatori';

@Entity
class Nota {
  @Of(() => String)
  notaID = '';

  @Of(() => String)
  notaValoare = '';

  @Of(() => String)
  notaData = '';

  @Of(() => String)
  notaDataInsert = '';

  @Of(() => String)
  notaTip = '';

  @Of(() => String)
  notaObs = '';

  static of = Entity.of<Nota>();
}

@Entity
class Activitate {
  @Of(() => String)
  aojID = '';

  @Of(() => String)
  aojTip = '';

  @Of(() => String)
  aojDataInsert = '';

  @Of(() => String)
  aojReverted = '';

  @Of(() => String)
  aojDataRevert = '';

  static of = Entity.of<Activitate>();
}

@Entity
class Scutire {
  @Of(() => String)
  scID = '';

  @Of(() => String)
  scTip = '';

  @Of(() => String)
  scDataStart = '';

  @Of(() => String)
  scDataEnd = '';

  @Of(() => String)
  scObservatii = '';

  @Of(() => String)
  scInsertDate = '';

  static of = Entity.of<Scutire>();
}

@Entity
class HistoryTeacher {
  @Of(() => String)
  uacID = 'string';

  @Of(() => String)
  uacFirstName = '';

  @Of(() => String)
  uacLastName = '';

  @Of(() => String)
  uacSex = '';

  @Of(() => String)
  uacTipID = '';

  @Of(() => String)
  uacPrefAvatar = '';

  static of = Entity.of<HistoryTeacher>();
}
@Entity
class Conduita {
  @Of(() => String)
  cmeID = '';

  @Of(() => String)
  cmeText = '';

  @Of(() => String)
  cmePunctaj = '';

  @Of(() => String)
  cmeTip = '';

  @Of(() => String)
  cmeData = '';

  @Of(() => String)
  cmeDataInsert = '';

  @Of(() => HistoryTeacher)
  cadru = HistoryTeacher.of({});

  static of = Entity.of<Conduita>();
}

@Entity
class Observatie {
  @Of(() => String)
  cmeID = '';

  @Of(() => String)
  obsContinut = '';

  @Of(() => String)
  obsData = '';

  @Of(() => String)
  obsAnulataData = '';

  @Of(() => String)
  obsIsRead = '';

  @Of(() => Subject)
  materie = Subject.of({});

  @Of(() => HistoryTeacher)
  cadru = HistoryTeacher.of({});

  static of = Entity.of<Observatie>();
}

@Entity
class Tema {
  @Of(() => String)
  hwID = '';

  @Of(() => String)
  hwName = '';

  @Of(() => String)
  hwDescription = '';

  @Of(() => String)
  hwInsertDate = '';

  @Of(() => String)
  hwDueDate = '';

  @Of(() => String)
  hwTotalAtasamente = '';

  @Of(() => HistoryTeacher)
  hwTeacher = HistoryTeacher.of({});

  static of = Entity.of<Tema>();
}

@Entity
class Group {
  @Of(() => String)
  grpID = '';

  @Of(() => String)
  grpName = '';

  @Of(() => String)
  grpIsPublic = '';

  @Of(() => String)
  evsID = '';

  @Of(() => String)
  evName = '';

  @Of(() => String)
  evsStartDate = '';

  @Of(() => String)
  evsEndDate = '';

  @Of(() => String)
  acmProviderID = '';

  @Of(() => String)
  acmMeetingJoinUrl = '';

  static of = Entity.of<Group>();
}

@Entity
class TaxOwner {
  @Of(() => String)
  uacID = '';

  @Of(() => String)
  uacFirstName = '';

  @Of(() => String)
  uacLastName = '';

  @Of(() => String)
  uacSex = '';

  @Of(() => String)
  uacTipID = '';

  @Of(() => String)
  uacPrefAvatar = '';

  @Of(() => String)
  roles = '';

  static of = Entity.of<TaxOwner>();
}

@Entity
class Taxa {
  @Of(() => String)
  taxID = '';

  @Of(() => String)
  taxName = '';

  @Of(() => String)
  taxAmount = '';

  @Of(() => String)
  taxAmountDue = '';

  @Of(() => String)
  taxCurrency = '';

  @Of(() => String)
  taxInfo = '';

  @Of(() => TaxOwner)
  taxOwner = TaxOwner.of({});

  static of = Entity.of<Taxa>();
}

@Entity
class File {
  @Of(() => String)
  fsID = '';

  @Of(() => String)
  fsNume = '';

  @Of(() => String)
  fssMessage = '';

  @Of(() => String)
  fssDate = '';

  @Of(() => String)
  share = Share.of({});

  static of = Entity.of<File>();
}

@Entity
class Share {
  @Of(() => Details)
  fssgDetails = Details.of({});

  static of = Entity.of<Share>();
}

@Entity
class Details {
  @Of(() => String)
  usID = '';

  @Of(() => HistoryTimelinePerson)
  person = HistoryTimelinePerson.of({});

  static of = Entity.of<Details>();
}

@Entity
class HistoryTimelinePerson {
  @Of(() => String)
  firstName = '';

  @Of(() => String)
  lastName = '';

  static of = Entity.of<HistoryTimelinePerson>();
}

@Entity
class Mesaj {
  @Of(() => String)
  msgID = '';

  @Of(() => String)
  msgDestinatarText = '';

  @Of(() => String)
  msgAreAtasamente = '';

  @Of(() => String)
  msgSubiect = '';

  @Of(() => String)
  msgTotalAtasamente = '';

  static of = Entity.of<Mesaj>();
}

@Entity
class Lectie {
  @Of(() => String)
  lectieID = '';

  @Of(() => String)
  acmTitle = '';

  @Of(() => String)
  acmProviderID = '';

  @Of(() => String, { optional: true })
  acmEndDate?: string;

  @Of(() => String)
  acmMeetingJoinUrl = '';

  @Of(() => String)
  lectieStatus = '';

  static of = Entity.of<Lectie>();
}

@Entity
class Chestionar {
  @Of(() => String)
  fID = '';

  @Of(() => String)
  fTitle = '';

  @Of(() => String)
  fType = '';

  @Of(() => String)
  uaTip = '';

  @Of(() => String)
  clase = '';

  static of = Entity.of<Chestionar>();
}

@Entity
class Absent {
  @Of(() => String)
  absData = '';

  @Of(() => String)
  absDataInsert = '';

  @Of(() => String)
  absID = '';

  @Of(() => String)
  absMotivareData = '';

  @Of(() => String)
  absStatus = '';

  static of = Entity.of<Absent>();
}

@Entity
class StaffAttendance {
  @Of(() => String)
  id = '';

  @Of(() => String)
  eventTitle = '';

  @Of(() => String)
  date = '';

  @Of(() => String, { nullable: true })
  cmcID = null;

  @Of(() => String)
  clNume = '';

  static of = Entity.of<StaffAttendance>();
}

@Entity
export class HistoryTimeline {
  @Of(() => String)
  eventTip = '';

  @Of(() => String)
  eventDate = '';

  @Of(() => Nota)
  nota = Nota.of({});

  @Of(() => String)
  sistemNotare = '';

  @Of(() => Person)
  elev = Person.of({});

  @Of(() => Absent)
  absent = Absent.of({});

  @Of(() => Subject)
  materie = Subject.of({});

  @Of(() => PersonClass)
  clasa = PersonClass.of({});

  @Of(() => Activitate)
  activitate = Activitate.of({});

  @Of(() => Scutire)
  scutire = Scutire.of({});

  @Of(() => Conduita)
  conduita = Conduita.of({});

  @Of(() => Observatie)
  observatie = Observatie.of({});

  @Of(() => Tema)
  tema = Tema.of({});

  @Of(() => Group)
  group = Group.of({});

  @Of(() => Taxa)
  taxa = Taxa.of({});

  @Of(() => File)
  fisier = File.of({});

  @Of(() => Mesaj)
  mesaj = Mesaj.of({});

  @Of(() => Lectie)
  lectie = Lectie.of({});

  @Of(() => Chestionar)
  chestionar = Chestionar.of({});

  @Of(() => StaffAttendance)
  staff_at = StaffAttendance.of({});

  static of = Entity.of<HistoryTimeline>();
}
