import { Accommodation } from '@modules/Universities/accommodations/entities';
import { Entity, Of } from 'entity-of';

import { Faculty } from '..';

@Entity
export class Student {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  firstName: string = '';

  @Of(() => String)
  lastName: string = '';

  @Of(() => String)
  nameInitials: string = '';

  @Of(() => Number)
  socialNumber: number = 0;

  @Of(() => String)
  birthdate: string = '';

  @Of(() => String)
  gender: string = '';

  @Of(() => String)
  country: string = '';

  @Of(() => String)
  citizenship: string = '';

  @Of(() => String)
  county: string = '';

  @Of(() => String)
  city: string = '';

  @Of(() => String)
  address: string = '';

  @Of(() => String)
  phoneNumber: string = '';

  @Of(() => String)
  carNumber: string = '';

  @Of(() => String)
  emailAddress: string = '';

  @Of(() => String)
  personalEmailAddress: string = '';

  @Of(() => String)
  bankName: string = '';

  @Of(() => String)
  bankIban: string = '';

  @Of(() => String)
  bankCity: string = '';

  @Of(() => String)
  bankAccountHolderName: string = '';

  @Of(() => String)
  identityDocumentType: string = '';

  @Of(() => String)
  identityDocumentSeries: string = '';

  @Of(() => String)
  identityDocumentNumber: string = '';

  @Of(() => String)
  identityDocumentIssuedBy: string = '';

  @Of(() => String)
  identityDocumentIssuedDate: string = '';

  @Of(() => String)
  identityDocumentExpiryDate: string = '';

  @Of(() => Boolean)
  isMarried: boolean = false;

  @Of(() => [Study])
  studies: Study[] = [];

  @Of(() => [Warning])
  warnings: Warning[] = [];

  @Of(() => Number, { nullable: true })
  sold: number | null = null;

  @Of(() => Accommodation, { nullable: true })
  accommodation: Accommodation | null = null;

  static of = Entity.of<Student>();
}

@Entity
export class Study {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  studentId: number = 0;

  @Of(() => Faculty)
  faculty: Faculty = Faculty.of({});

  @Of(() => Number)
  faculty_id: number = 0;

  @Of(() => Number)
  studentTypeId: number = 0;

  @Of(() => String)
  registrationNumber: string = '';

  @Of(() => String)
  uniqueRegistrationNumber: string = '';

  @Of(() => StudentType, { nullable: true })
  studentType: StudentType | null = null;

  @Of(() => String)
  educationForm: string = '';

  @Of(() => Number, { optional: true })
  year?: number;

  @Of(() => String, { optional: true })
  profile?: string;

  @Of(() => String, { optional: true })
  specialisation?: string;

  @Of(() => String, { optional: true })
  type?: string;

  @Of(() => String, { optional: true })
  series?: string;

  @Of(() => String, { optional: true })
  group?: string;

  @Of(() => Number, { optional: true })
  isBudget?: number;

  @Of(() => String, { optional: true })
  startYear?: string;

  @Of(() => String, { optional: true })
  endYear?: string;

  static of = Entity.of<Study>();
}

@Entity
export class Warning {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  date: string = '';

  @Of(() => String)
  description: string = '';

  static of = Entity.of<Warning>();
}

@Entity
export class StudentType {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  code: string = '';

  @Of(() => String)
  category: string = '';

  static of = Entity.of<StudentType>();
}
