import { Entity, Of } from 'entity-of';

@Entity
export class ServiceDetails {
  @Of(() => Number)
  invoicedAmount: number = 0;

  @Of(() => Number)
  collectedAmount: number = 0;

  @Of(() => Number)
  numberOfInvoices: number = 0;

  @Of(() => Number)
  numberOfMembers: number = 0;

  static of = Entity.of<ServiceDetails>();
}
