import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class AddSubscriptionInput {
  @Of(() => String, { optional: true })
  @IsNotEmpty({ message: 'errors.packageId.required' })
  packageId?: string;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.startDate.required' })
  startDate: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.endDate.required' })
  endDate: string = '';

  @Of(() => String, { optional: true })
  organizationId?: string = '';

  @Of(() => String, { optional: true })
  studentId?: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.recurrence.required' })
  recurrence: 'monthly' | 'yearly' = 'monthly';

  @Of(() => String, { optional: true })
  subscriberOrgId?: string = '';

  static of = Entity.of<AddSubscriptionInput>();
}
