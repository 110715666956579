import { addMinutes, differenceInMinutes } from 'date-fns';
import add from 'date-fns/add';
import compareAsc from 'date-fns/compareAsc';
import compareDesc from 'date-fns/compareDesc';
import diffInDays from 'date-fns/differenceInDays';
import diffInHours from 'date-fns/differenceInHours';
import { isAfter, isBefore } from 'date-fns/esm';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import isValid from 'date-fns/isValid';
import { enUS, ro } from 'date-fns/locale';
import sub from 'date-fns/sub';
import subDays from 'date-fns/subDays';
import { isEqualWith } from 'lodash';

export class SystemDate {
  private value: Date;
  private locale: any;

  constructor(value?: string | Date, locale?: string) {
    const lang = locale ?? window.location.pathname.split('/')?.[1];

    // TODO: Extend this as needed.
    switch (lang) {
      case 'en':
        this.locale = enUS;
        break;
      case 'ro':
      default:
        this.locale = ro;
        break;
    }

    const temp = !value ? new Date() : new Date(value);
    if (!isValid(temp)) {
      throw new Error('SystemDate was initialized with an invalid date!');
    }
    this.value = temp;
  }

  get valueOf() {
    return this.toISO();
  }

  get valueOfWithTime() {
    return this.toISO('complete');
  }

  get raw() {
    return this.value;
  }

  addMinutes(minutes: number) {
    const next = addMinutes(this.value, minutes);
    return this.chain(next, 'complete');
  }

  removeMinutes(minutes: number) {
    const next = sub(this.value, { minutes });
    return this.chain(next, 'complete');
  }

  minutesBetween(date: SystemDate) {
    return differenceInMinutes(this.value, date.raw);
  }

  addDays(days: number) {
    const next = add(this.value, { days });
    return this.chain(next);
  }

  removeDays(days: number) {
    const next = subDays(this.value, days);
    return this.chain(next);
  }

  addMonths(months: number) {
    const next = add(this.value, { months });
    return this.chain(next);
  }

  removeMonths(months: number) {
    const next = sub(this.value, { months });
    return this.chain(next);
  }

  addYears(years: number) {
    const next = add(this.value, { years });
    return this.chain(next);
  }

  removeYears(years: number) {
    const next = sub(this.value, { years });
    return this.chain(next);
  }

  getHoursAndMinutes = () => {
    return format(this.value, 'HH:mm', { locale: this.locale });
  };

  getISODate = () => {
    return formatISO(this.value, { representation: 'date' });
  };

  format(dateFormat: string) {
    return format(this.value, dateFormat, { locale: this.locale });
  }

  private toISO(representation: 'date' | 'complete' = 'date') {
    return this.formatISO(this.value, representation);
  }

  private formatISO(value: Date, representation: 'date' | 'complete' = 'date') {
    return formatISO(value, { representation });
  }

  private chain(date: Date, representation: 'date' | 'complete' = 'date') {
    return SystemDate.of(this.formatISO(date, representation));
  }

  static of(value?: string | Date, locale?: string): SystemDate {
    return new SystemDate(value, locale);
  }

  static compareISOStrings(
    date1: string | Date,
    date2: string | Date,
    direction: 'asc' | 'desc'
  ): number {
    if (direction === 'asc') {
      return compareAsc(SystemDate.of(date1).raw, SystemDate.of(date2).raw);
    }
    return compareDesc(SystemDate.of(date1).raw, SystemDate.of(date2).raw);
  }

  static differenceInDays(date1: string | Date, date2: string | Date): number {
    return diffInDays(SystemDate.of(date1).raw, SystemDate.of(date2).raw);
  }

  static differenceInHours(date1: string | Date, date2: string | Date): number {
    return diffInHours(SystemDate.of(date1).raw, SystemDate.of(date2).raw);
  }

  isBeforeDate(date: string | Date): boolean {
    const a = SystemDate.of(this.getISODate()).raw;
    const b = SystemDate.of(SystemDate.of(date).getISODate()).raw;

    return isBefore(a, b);
  }

  isAfterDate(date: string | Date): boolean {
    const a = SystemDate.of(this.getISODate()).raw;
    const b = SystemDate.of(SystemDate.of(date).getISODate()).raw;

    return isAfter(a, b);
  }

  isEqualWithDate(date: string | Date): boolean {
    const a = SystemDate.of(this.getISODate()).raw;
    const b = SystemDate.of(SystemDate.of(date).getISODate()).raw;

    return isEqualWith(a, b);
  }
}

export const midnightDate = (date: string | Date): Date => {
  const initDate = new Date(date);
  initDate.setHours(0, 0, 0, 0);
  return initDate;
};

export const midnightDateTime = (date: string | Date): number => {
  const initDate = new Date(date);
  initDate.setHours(0, 0, 0, 0);
  return initDate.getTime();
};
