import React, { useLayoutEffect, useRef, useState } from 'react';

import { AdservioIcons, Box, Grid, IconButton, Text } from '@ui2/components';
import { atom, useAtom } from 'jotai';

import { Maintenance } from '../maintenance';

import { usePlatformStatusQuery } from './cache';
import { PlatformStatus } from './entities';

export const bannerHeightAtom = atom<number>(0);

export const useBannerHeight = () => {
  const [bannerHeight, setBannerHeight] = useAtom(bannerHeightAtom);

  return { bannerHeight, setBannerHeight };
};

export const DisruptionBanner = () => {
  const bannerRef = useRef<HTMLDivElement>(null);
  const { bannerHeight, setBannerHeight } = useBannerHeight();
  const [hideBanner, setHideBanner] = useState(!!localStorage.getItem('status_banner_hidden'));

  if (hideBanner) {
    const disruption = localStorage.getItem('status_banner_hidden');
    const { expirationTime } = JSON.parse(String(disruption));
    if (new Date().getTime() > expirationTime) {
      localStorage.removeItem('status_banner_hidden');
      setHideBanner(false);
    }
  }

  const { data } = usePlatformStatusQuery(
    {},
    {
      onSuccess: (data) => {
        if (!data.disruption.enabled) {
          setBannerHeight(0);
        }
      },
      refetchInterval: 1000 * 60 * 5, // 5 minutes
    }
  );

  useLayoutEffect(() => {
    const handleResize = () => {
      if (bannerRef.current) {
        setBannerHeight(bannerRef.current.offsetHeight);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [data]);

  const status = localStorage.getItem('status')
    ? PlatformStatus.of(JSON.parse(String(localStorage.getItem('status'))).data)
    : PlatformStatus.of(data ?? {});

  if (status.maintenance.enabled) {
    return <Maintenance message={status.maintenance.message} />;
  }

  if (status.disruption.enabled && !hideBanner) {
    return (
      <>
        <Box h={`${bannerHeight}px`} zIndex={100}></Box>
        <Grid
          position={'fixed'}
          zIndex={'skipLink'}
          top={0}
          left={0}
          width="100vw"
          bg="orange.500"
          px={{
            base: 4,
            md: 8,
          }}
          py="2"
          gap={2}
          templateColumns={'1fr auto'}
          alignItems="center"
          justifyContent="space-between"
          ref={bannerRef}
        >
          <Box w="full">
            {status.disruption.message.split('\n').map((line, index) => (
              <Text
                fontWeight="semibold"
                justifySelf="center"
                color="white"
                textAlign="center"
                key={index}
              >
                {line}
              </Text>
            ))}
          </Box>

          <IconButton
            aria-label="close"
            icon={<AdservioIcons.FiX />}
            onClick={() => {
              if (!hideBanner) {
                const expirationTime = new Date().getTime() + 6 * 60 * 60 * 1000; // 6 hours
                localStorage.setItem('status_banner_hidden', JSON.stringify({ expirationTime }));
                setHideBanner(true);
                setBannerHeight(0);
              }
            }}
            color="white"
            variant="ghost"
            colorScheme="gray"
            _hover={{
              bg: 'orange.600',
            }}
          />
        </Grid>
      </>
    );
  }

  return null;
};
