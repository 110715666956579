import { Entity, Of } from 'entity-of';

@Entity
export class TreeSelection {
  @Of(() => String)
  countries = {};

  @Of(() => String)
  counties = {};

  @Of(() => String)
  cities = {};

  @Of(() => String)
  organizations = {};

  @Of(() => String)
  faculties = {};

  static of = Entity.of<TreeSelection>();
}
