import { Entity, Of } from 'entity-of';

import { Facility } from '../Facility';

@Entity
export class RoomType {
  @Of(() => String)
  facilities: Facility[] = [];

  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  noOfBeds: number = 0;

  static of = Entity.of<RoomType>();
}
