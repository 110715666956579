import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { Box, HStack, Placement } from '@chakra-ui/react';
import { useRouter } from '@lib/router';
import { formatISO } from 'date-fns';
import { enUS, ro } from 'date-fns/locale';

import { AdservioIcons, Button, IconButton, Text } from '../../atoms';
import { MonthPicker, YearPicker } from '../DatePicker/DatePicker.layout';
import { useDatepicker } from '../DatePicker/useDatepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './chakra-react-datepicker-panel.css';

export interface DatePickerPanelProps {
  name?: string;
  value?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  dateFormat?: string;
  selectRange?: boolean;
  popperPlacement?: Placement;
  onChange?(value: string | DateRange): void;
  monthPicker?: boolean;
  onCalendarOpen?(): void;
  onCalendarClose?(): void;
  onBlur?(): void;
  onFocus?(): void;
  startDateValue?: Date;
  endDateValue?: Date;
}

export type DateRange = [Date | null, Date | null];

export const DatePickerPanel = React.forwardRef(
  (
    {
      value,
      minDate,
      maxDate,
      onChange,
      disabled = false,
      dateFormat,
      name,
      popperPlacement,
      onCalendarOpen,
      onCalendarClose,
      monthPicker = false,
      selectRange,
      onBlur,
      onFocus,
      startDateValue,
      endDateValue,
    }: DatePickerPanelProps,
    ref
  ) => {
    const { t } = useTranslation('shared');

    const { lang } = useRouter();

    const {
      months,
      onNext,
      yearsRange,
      onPrevious,
      onYearSelect,
      openYearSelector,
      showYearSelector,
      showMonthSelector,
      openMonthSelector,
      handleCalendarClose,
      onDefaultMonthSelect,
    } = useDatepicker({ onCalendarClose, monthPicker });

    const [startDate, setStartDate] = useState<Date | null | undefined>(startDateValue);
    const [endDate, setEndDate] = useState<Date | null | undefined>(endDateValue);

    const onRangeChange = (dates: DateRange) => {
      const [start, end] = dates;

      setStartDate(start);
      setEndDate(end);

      onChange?.(dates);
    };

    return (
      <Box display="flex" justifyContent="center" alignItems="center" className="date-picker-panel">
        <ReactDatePicker
          open
          name={name}
          ref={ref as any}
          endDate={endDate}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          autoComplete={'off'}
          calendarStartDay={1}
          startDate={startDate}
          onCalendarOpen={onCalendarOpen}
          showPopperArrow={false}
          selectsRange={selectRange}
          shouldCloseOnSelect={selectRange ? false : true}
          popperPlacement={popperPlacement}
          onCalendarClose={handleCalendarClose}
          locale={lang === 'ro' ? ro : enUS}
          dateFormat={dateFormat || 'dd/MM/yyyy'}
          placeholderText={t('general.select_ellipsis')}
          selected={selectRange ? (startDate ? startDate : null) : value ? new Date(value) : null}
          onFocus={onFocus}
          onBlur={onBlur}
          showMonthYearPicker={monthPicker}
          onChange={(date) => {
            if (!date) return;
            if (Array.isArray(date)) {
              onRangeChange(date);
            } else {
              onChange?.(formatISO(date, { representation: 'date' }));
            }
          }}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => {
            return (
              <>
                <HStack justifyContent="space-between" backgroundColor="white" pb="4" spacing={0}>
                  <IconButton
                    variant="ghost"
                    colorScheme="gray"
                    aria-label="navigate-to-previous"
                    onClick={() => onPrevious(date, decreaseMonth, changeYear)}
                    icon={<AdservioIcons.FiChevronLeft color="gray.400" boxSize="8" />}
                  />

                  <HStack spacing="4" width="full" justifyContent="center" px="4">
                    {!showMonthSelector && !showYearSelector && !monthPicker && (
                      <Button
                        w="full"
                        maxW="120px"
                        fontSize="md"
                        variant="outline"
                        aria-label="month"
                        fontWeight="semibold"
                        colorScheme="gray"
                        onClick={openMonthSelector}
                      >
                        <Text fontWeight="semibold" fontSize="md">
                          {months.find((month) => month.value === date.getMonth())?.label}
                        </Text>
                      </Button>
                    )}

                    {showYearSelector && (
                      <Text w="full" maxW="120px" fontWeight="semibold" fontSize="md">
                        {`${yearsRange[0]} - ${yearsRange[yearsRange.length - 1]}`}
                      </Text>
                    )}

                    {!showYearSelector && (
                      <Button
                        w="full"
                        maxW="120px"
                        aria-label="year"
                        variant="outline"
                        colorScheme="gray"
                        onClick={openYearSelector}
                      >
                        <Text fontWeight="semibold" fontSize="md">
                          {date.getFullYear()}
                        </Text>
                      </Button>
                    )}
                  </HStack>

                  <IconButton
                    variant="ghost"
                    colorScheme="gray"
                    aria-label="navigate-to-next"
                    onClick={() => onNext(date, increaseMonth, changeYear)}
                    icon={<AdservioIcons.FiChevronRight color="gray.400" boxSize="8" />}
                  />
                </HStack>

                {showMonthSelector && (
                  <MonthPicker
                    months={months}
                    value={date.getMonth()}
                    onChangeMonth={(newMonth: number) => {
                      changeMonth(newMonth);
                      onDefaultMonthSelect();
                    }}
                  />
                )}

                {showYearSelector && (
                  <YearPicker
                    onChangeYear={(year) => {
                      onYearSelect();
                      changeYear(year);
                    }}
                    value={date.getFullYear()}
                    years={yearsRange}
                  />
                )}
              </>
            );
          }}
          customInput={null}
          inline
        />
      </Box>
    );
  }
);
