import { PaginationMeta, Person, SelectActivity, UnavailabilityEntity } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class TimetableWizardRules {
  @Of(() => PaginationMeta)
  meta?: PaginationMeta;

  @Of(() => DefaultRules)
  default: DefaultRules = DefaultRules.of({});

  @Of(() => DefaultRules)
  data: DefaultRules[] = [];

  static of = Entity.of<TimetableWizardRules>();
}

@Entity
export class DefaultRules {
  @Of(() => String, { optional: true })
  MAX_DAILY_ACTIVITIES?: string;

  @Of(() => String, { optional: true })
  MAX_GAPS_PER_WEEK?: string;

  @Of(() => String, { optional: true })
  MAX_HOURS_CONTINUOUSLY?: string;

  @Of(() => String, { optional: true })
  MIN_DAILY_ACTIVITIES?: string;

  @Of(() => String, { optional: true })
  MIN_DAYS_BETWEEN_SAME_ACTIVITIES?: string;

  @Of(() => Number, { optional: true })
  ACTIVITY_BEGINS_STUDENTS_DAY: number = 0;

  @Of(() => Number, { optional: true })
  ACTIVITY_ENDS_STUDENTS_DAY: number = 0;

  @Of(() => String, { optional: true })
  MIN_DAYS_BETWEEN_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  MAX_DAYS_BETWEEN_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  ACTIVITY_PREFERRED_ROOM: string = '';

  @Of(() => Number, { optional: true })
  ACTIVITIES_MUST_HAVE_THE_SAME_STARTING_HOUR?: number;

  @Of(() => Number, { optional: true })
  CONCURRENT_HOURS?: number;

  @Of(() => String)
  entityID: string = '';

  @Of(() => Person)
  teacherDetails: Person = Person.of({});

  @Of(() => ClassRules)
  classDetails: ClassRules = ClassRules.of({});

  @Of(() => RoomRules)
  roomDetails: RoomRules = RoomRules.of({});

  @Of(() => SubjectRules)
  subjectDetails: SubjectRules = SubjectRules.of({});

  @Of(() => ActivityRules)
  activityDetails: ActivityRules = ActivityRules.of({});

  @Of(() => UnavailabilityEntity)
  unavailabilities: UnavailabilityEntity[] = [];

  @Of(() => [SelectActivity])
  concurrentActivities: SelectActivity[] = [];

  static of = Entity.of<DefaultRules>();
}

@Entity
export class DataRules {
  @Of(() => String, { optional: true })
  MAX_DAILY_ACTIVITIES?: string;

  @Of(() => String, { optional: true })
  MAX_GAPS_PER_WEEK?: string;

  @Of(() => String, { optional: true })
  MAX_HOURS_CONTINUOUSLY?: string;

  @Of(() => String, { optional: true })
  MIN_DAILY_ACTIVITIES?: string;

  static of = Entity.of<DataRules>();
}

@Entity
export class ClassRules {
  @Of(() => String)
  clID?: string;

  @Of(() => String)
  clNume?: string;

  static of = Entity.of<ClassRules>();
}

@Entity
export class RoomRules {
  @Of(() => String)
  roomID: string = '';

  @Of(() => String)
  roomName: string = '';

  @Of(() => String)
  buildingName?: string;

  static of = Entity.of<RoomRules>();
}

@Entity
export class ActivityRules {
  @Of(() => String)
  cmcID?: string;

  @Of(() => String)
  cmcSaptTip: string = '';

  @Of(() => String)
  cmcNrOre?: string;

  static of = Entity.of<ActivityRules>();
}

@Entity
export class SubjectRules {
  @Of(() => String)
  matID: string = '';

  @Of(() => String)
  matNume: string = '';

  static of = Entity.of<SubjectRules>();
}

@Entity
export class ActivityDetails {
  @Of(() => Person)
  teacherDetails: Person = Person.of({});

  @Of(() => ClassRules)
  classDetails: ClassRules = ClassRules.of({});

  @Of(() => ActivityRules)
  activityDetails: ActivityRules = ActivityRules.of({});

  @Of(() => SubjectRules)
  subjectDetails: SubjectRules = SubjectRules.of({});

  @Of(() => RoomRules)
  roomDetails: RoomRules = RoomRules.of({});

  static of = Entity.of<ActivityDetails>();
}
