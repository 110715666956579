import { Entity, Of } from 'entity-of';

@Entity
export class ProgramsStudyYearsInput {
  @Of(() => Number)
  programId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  static of = Entity.of<ProgramsStudyYearsInput>();
}
