import Api from '@lib/api';
import axios from 'axios';

const apiBaseUrl = window.env?.V2_PUBLIC_API_BASE_URL || '';

type ResponseMapper = (data: Partial<unknown>) => unknown;

interface HttpClientOptions<T> {
  permissions?: Permissions;
  payload?: T;
  isLegacy?: boolean;
  formData?: boolean;
  responseType?: 'blob';
  headers?: Record<string, string>;
  responseMapper?: ResponseMapper;
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401 || error.response?.status === 403) {
      const lang = window.location.pathname.split('/')?.[1] || 'ro';

      axios({
        method: 'delete',
        baseURL: apiBaseUrl,
        url: '/auth',
      }).then(
        () => {
          window.location.href = `/${lang}/auth`;
        },
        () => {
          window.location.href = `/${lang}/auth`;
        }
      );
    }

    return Promise.reject(error);
  }
);

// TODO: use generic Payload type with axios
export const httpClient = <Response extends object | object[], Payload = unknown>(
  endpoint: string,
  options?: HttpClientOptions<Payload | FormData>
) => {
  const headers = options?.headers || {};
  let payload = options?.payload;

  const orgId = localStorage.getItem('orgId');

  if (orgId && !headers['X-Tenant-ID']) {
    headers['X-Tenant-ID'] = orgId;
  }

  if (options?.formData && payload) {
    headers['Content-Type'] = 'multipart/form-data';
    payload = Api.getFormData(payload);
  }

  const baseUrl = endpoint.startsWith('http') ? '' : apiBaseUrl;

  return {
    get: async () => {
      const response = await axios.get<Response>(`${baseUrl}${endpoint}`, {
        headers,
        responseType: options?.responseType,
      });

      return parseResponse(response.data, options?.responseMapper, options?.isLegacy);
    },
    post: async () => {
      const response = await axios.post<Response>(`${baseUrl}${endpoint}`, payload, {
        headers,
        responseType: options?.responseType,
      });

      return parseResponse(response.data, options?.responseMapper, options?.isLegacy);
    },
    put: async () => {
      const response = await axios.put<Response>(`${baseUrl}${endpoint}`, payload, {
        headers,
        responseType: options?.responseType,
      });

      return parseResponse(response.data, options?.responseMapper, options?.isLegacy);
    },
    patch: async () => {
      const response = await axios.patch<Response>(`${baseUrl}${endpoint}`, payload, {
        headers,
        responseType: options?.responseType,
      });

      return parseResponse(response.data, options?.responseMapper, options?.isLegacy);
    },
    delete: async () => {
      const response = await axios.delete<Response>(`${baseUrl}${endpoint}`, {
        headers,
      });

      return parseResponse(response.data, options?.responseMapper, options?.isLegacy);
    },
  };
};

function parseResponse<Response extends object>(
  data: Response,
  responseMapper?: ResponseMapper,
  isLegacy?: boolean
): Response {
  let out = data;

  if (isLegacy) {
    // This is needed for mapping the response from the PHP endpoints
    out = (data as any).data;
  }

  if (responseMapper) {
    if (Array.isArray(out)) {
      Object.assign(
        out,
        out.map((v) => responseMapper(v))
      );
    } else {
      Object.assign(out, responseMapper(out));
    }
  }

  return out;
}
