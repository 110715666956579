import { Entity, Of } from 'entity-of';

@Entity
export class Visibility {
  @Of(() => [String], { optional: true })
  countryIDs?: string[] = [];

  @Of(() => [String], { optional: true })
  cityIDs?: string[] = [];

  @Of(() => [String], { optional: true })
  organizationIDs?: string[] = [];

  @Of(() => [String], { optional: true })
  countyIDs?: string[] = [];

  @Of(() => [String], { optional: true })
  facultyIDs?: string[] = [];

  static of = Entity.of<Visibility>();
}
