import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';
import { t } from 'i18next';
@Entity
export class Role {
  @Of(() => String)
  roleID: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  roleName: string = '';

  @Of(() => String)
  roleUaTip: string = '';

  @Of(() => String)
  roleIsAdministrative: string = '';

  @Of(() => String)
  roleIsImplicit: string = '';

  @Of(() => String)
  roleVisibleInTitle: string = '';

  @Of(() => String)
  roleIsImplicitAst: string = '';

  @Of(() => [String])
  permissions: string[] = [];

  static of = Entity.of<Role>();
}
