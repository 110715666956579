import { IsStrongPassword, MatchesProperty } from '@lib/utils';
import { Equals, IsEmail, IsNotEmpty, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

type ValidationStepType = 'code' | 'register';

@Entity
export class UserRegisterForm {
  @IsNotEmpty({ message: 'errors.accessCode.required' })
  @Of(() => String)
  accessCode: string = '';

  @IsEmail(undefined, { message: 'errors.email.isValid' })
  @ValidateIf((o) => o.validationStep === 'register')
  @IsNotEmpty({ message: 'errors.email.required' })
  @ValidateIf((o) => o.validationStep === 'register' || o.validationStep === 'unify_accounts')
  @Of(() => String)
  uaUserName: string = '';

  @IsStrongPassword()
  @ValidateIf((o) => o.validationStep === 'register')
  @IsNotEmpty({ message: 'errors.uaPassword.required' })
  @ValidateIf((o) => o.validationStep === 'register' || o.validationStep === 'unify_accounts')
  @Of(() => String)
  uaPassword: string = '';

  @MatchesProperty('uaPassword', { message: 'errors.uaPassword.match' })
  @IsNotEmpty({ message: 'errors.uaPassword.required' })
  @ValidateIf((o) => o.validationStep === 'register')
  @Of(() => String)
  uaPassword2: string = '';

  @IsNotEmpty({ message: 'errors.value.required' })
  @ValidateIf(
    (o) =>
      (o.validationStep === 'register' || o.validationStep === 'unify_accounts') &&
      o.isParentAccount
  )
  @Of(() => String)
  lastName: string = '';

  @IsNotEmpty({ message: 'errors.value.required' })
  @ValidateIf(
    (o) =>
      (o.validationStep === 'register' || o.validationStep === 'unify_accounts') &&
      o.isParentAccount
  )
  @Of(() => String)
  firstName: string = '';

  @Of(() => Boolean)
  uaNewsletter: boolean = false;

  @Equals(true, { message: ' ' })
  @ValidateIf((o) => o.validationStep === 'register')
  @Of(() => Boolean)
  uaTos: boolean = false;

  @Of(() => Boolean)
  uaPrivacy: boolean = false;

  @Of(() => String)
  validationStep: ValidationStepType = 'code';

  @Of(() => Boolean)
  isParentAccount: boolean = false;

  @Of(() => Boolean)
  disableChooseAccounts: boolean = false;

  static of = Entity.of<UserRegisterForm>();
}
