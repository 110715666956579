import { Entity, Of } from 'entity-of';

import 'reflect-metadata';

import { CreateSlotInput } from '..';

@Entity
export class CreateSlotsInput {
  @Of(() => String)
  ascID: string = '';

  @Of(() => [String])
  days: string[] = [];

  @Of(() => [CreateSlotInput])
  slots: CreateSlotInput[] = [];

  static of = Entity.of<CreateSlotsInput>();
}
