import { CertificateUser } from '@api/core/entities';
import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, ValidateNested } from 'class-validator';
import { Entity, Of } from 'entity-of';

import 'reflect-metadata';

@Entity
export class DiplomaValidatorStep2SimpleDiploma {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.date.required' })
  diplomaDate: string = '';

  @Of(() => String)
  headmaster: string = '';

  @Of(() => String)
  diplomaType: string = '';

  @Of(() => String)
  teacher: string = '';

  @Of(() => Boolean)
  includeLogo: boolean = false;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CertificateUser)
  @Of(() => [CertificateUser])
  cerUsers: CertificateUser[] = [];

  static of = Entity.of<DiplomaValidatorStep2SimpleDiploma>();
}

@Entity
export class DiplomaValidatorStep2ComplexDiploma {
  @IsNotEmpty({ message: 'errors.file.required' })
  @Of(() => String)
  fileNameUnploadInput: string = '';

  static of = Entity.of<DiplomaValidatorStep2ComplexDiploma>();
}

export type DiplomaValidatorStep2 =
  | DiplomaValidatorStep2SimpleDiploma
  | DiplomaValidatorStep2ComplexDiploma;
