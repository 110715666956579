import { Entity, Of } from 'entity-of';

@Entity
export class TimeOffQueryInput {
  @Of(() => String, { optional: true })
  cadID?: string;

  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  static of = Entity.of<TimeOffQueryInput>();
}
