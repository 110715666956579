import { Entity, Of } from 'entity-of';

@Entity
export class StudyCycle {
  @Of(() => String)
  cicluID: string = '';

  @Of(() => String)
  cicluNume: string = '';

  @Of(() => String)
  cicluNrOrdine: string = '';

  static of = Entity.of<StudyCycle>();
}
