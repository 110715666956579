import { Entity, Of } from 'entity-of';

@Entity
export class Feature {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  description: string = '';

  @Of(() => String)
  type: string = '';

  static of = Entity.of<Feature>();
}
