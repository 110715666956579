import { Entity, Of } from 'entity-of';

import { FileMeta } from '../File';

@Entity
export class TempFileUpload {
  @Of(() => String)
  fsID: string = '';

  @Of(() => String)
  fsNume: string = '';

  @Of(() => String)
  fsComments: string = '';

  @Of(() => String)
  fsDescriere: string = '';

  @Of(() => String)
  fsExt: string = '';

  @Of(() => String)
  fsInsertDate: string = '';

  @Of(() => FileMeta, { nullable: true })
  fsMeta: FileMeta | null = null;

  @Of(() => String)
  fsMimeType: string = '';

  @Of(() => String)
  fsSize: number = 0;

  @Of(() => String)
  fsTip: string = '';

  static of = Entity.of<TempFileUpload>();
}
