import { Entity, Of } from 'entity-of';

@Entity
export class TeacherTimetableInput {
  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  cadID: string = '';

  @Of(() => String)
  dayID: string = '';

  @Of(() => String)
  withLessonPlanDate: string = '';

  static of = Entity.of<TeacherTimetableInput>();
}
