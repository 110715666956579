import { Entity, Of } from 'entity-of';

@Entity
export class ServiceRequestsSearchInput {
  @Of(() => String, { optional: true })
  accountId?: string;

  @Of(() => [Number], { optional: true })
  serviceRequestIds?: number[];

  @Of(() => Number, { optional: true })
  limit?: number = 10;

  @Of(() => Number, { optional: true })
  offset?: number = 0;

  @Of(() => String, { optional: true })
  order?: 'asc' | 'desc';

  @Of(() => String, { optional: true })
  orderBy?: string;

  static of = Entity.of<ServiceRequestsSearchInput>();
}
