import { PortfolioCategoryType } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class StudentPortfolioCategory {
  @Of(() => String)
  id!: string;

  @Of(() => String)
  name!: string;

  @Of(() => String)
  icon!: string;

  @Of(() => String)
  type: PortfolioCategoryType = 'individual';

  static of = Entity.of<StudentPortfolioCategory>();
}
