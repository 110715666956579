import { useMutation } from 'react-query';

import { PaymentInfo } from '@api/billing/entities';
import { UpgradeInput } from '@api/subscriptions/entities';
import { httpClient } from '@lib/http';
import { handleRequestErrors } from '@lib/utils';
import { useToast } from '@ui2/components';
import { AxiosError } from 'axios';

interface MutationInput {
  payload: UpgradeInput;
}

export const useSubscriptionUpgradeMutation = () => {
  const showToast = useToast();

  return useMutation<PaymentInfo, AxiosError, MutationInput>(
    ({ payload }) => {
      return httpClient<PaymentInfo, UpgradeInput>(`/subscriptions/subscribers/upgrade`, {
        payload: UpgradeInput.of(payload),
      }).post();
    },
    {
      onError: (error) => handleRequestErrors(error, showToast),
    }
  );
};
