import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class GlobalHolidaysQueryInput extends SimpleQueryInput {
  @Of(() => String)
  holName: string = '';

  @Of(() => String)
  holCountryID: string = '';

  static of = Entity.of<GlobalHolidaysQueryInput>();
}
