import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { getOrgTypeTheme } from '@ui2/utils';

const baseColor = getOrgTypeTheme('blue');

export const TabsTheme: ComponentMultiStyleConfig = {
  parts: ['root', 'tab', 'tablist', 'tabpanel'],
  baseStyle: ({ colorScheme }) => ({
    tab: {
      _active: { background: 'gray.100' },
      _selected: {
        boxShadow: 'none',
        borderBottom: '2px solid',
        color: `${colorScheme}.600`,
        backgroundColor: `${colorScheme}.100`,
        borderBottomColor: `${colorScheme}.600`,
      },
    },
  }),
  variants: {
    'solid-rounded': ({ colorScheme }) => ({
      tab: {
        borderRadius: 'md',
        _active: { background: 'gray.100', borderRadius: 'md' },
        _selected: {
          borderRadius: 'md',
          color: `${colorScheme}.600`,
          background: `${colorScheme}.100 `,
          borderBottom: '0px',
        },
      },
    }),
    line: ({ colorScheme }) => ({
      tab: {
        _active: { background: 'gray.100' },
        _selected: {
          borderBottom: '2px solid',
          color: `${colorScheme}.600`,
          backgroundColor: 'transparent',
          borderBottomColor: `${colorScheme}.600`,
        },
      },
    }),
    'white-header-line': ({ colorScheme }) => ({
      tab: {
        _active: { background: 'gray.100' },
        _selected: {
          borderBottom: '2px solid',
          color: `${colorScheme}.600`,
          backgroundColor: 'transparent',
          borderBottomColor: `${colorScheme}.600`,
        },
      },
      tablist: {
        background: 'white',
        padding: '0 2rem 0 2rem',
      },
    }),
  },
  defaultProps: {
    size: 'md',
    variant: 'line',
    isFitted: false,
    colorScheme: baseColor,
  },
};
