import { ArrayNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { AssociatedProfessor } from './AssociatedProfessor';
@Entity
export class AssociatedDiscipline {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  englishName: string = '';

  @Of(() => String)
  acronym: string = '';

  @Of(() => String)
  uniqueAcronym: string = '';

  @Of(() => Number)
  studyYearId: number = 0;

  @Of(() => Number)
  semester: number = 0;

  @Of(() => Number)
  series: number = 0;

  @Of(() => Number)
  examPercent: number = 0;

  @Of(() => Number)
  seminarPercent: number = 0;

  @Of(() => Number)
  laboratoryPercent: number = 0;

  @Of(() => Number)
  projectPercent: number = 0;

  @Of(() => Boolean)
  necessaryMinimumExamGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumSeminarGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumLaboratoryGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumProjectGrade: boolean = false;

  @Of(() => [AssociatedProfessor])
  associatedProfessors: AssociatedProfessor[] = [];

  @Of(() => String)
  role: string = '';

  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.roles.required' })
  roleName: string[] = [];

  @Of(() => String)
  facultyName: string = '';

  @Of(() => Number)
  facultyId: number = 0;

  @Of(() => String)
  currentNumber: string = '';

  static of = Entity.of<AssociatedDiscipline>();
}
