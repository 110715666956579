import React from 'react';

import { Flex, FlexProps } from '../../atoms';

interface AlertWrapperProps extends FlexProps {}

export const AlertWrapper = ({ borderColor, children, ...rest }: AlertWrapperProps) => {
  return (
    <Flex
      minWidth={[null, '24rem']}
      py="3"
      pl="4"
      pr="3"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor="white"
      border="1px solid"
      borderColor={borderColor}
      borderRadius="lg"
      color="white"
      shadow="elevation3"
      {...rest}
    >
      {children}
    </Flex>
  );
};
