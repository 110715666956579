import { IsNotBlank } from '@lib/utils';
import { IsNotEmpty, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class UserAuthForm {
  @Of(() => String)
  intent: string = 'auth';

  @IsNotBlank()
  @IsNotEmpty({ message: 'errors.uaUserName.required' })
  @Of(() => String)
  uaUserName: string = '';

  @IsNotBlank()
  @IsNotEmpty({ message: 'errors.uaPassword.required' })
  @Of(() => String)
  uaPassword: string = '';

  @Of(() => Number)
  setCookie: number = 1;

  @Of(() => Boolean)
  tfaSave: boolean = false;

  @Of(() => String)
  tfaOptID: string = '';

  @IsNotBlank()
  @IsNotEmpty({ message: 'errors.tfaResponse.required' })
  @ValidateIf((o) => o.tfaOptID)
  @Of(() => String)
  tfaResponse: string = '';

  @IsNotBlank()
  @IsNotEmpty({ message: 'errors.uaPassword.required' })
  @ValidateIf((o) => o.validateNewPassword)
  @Of(() => String)
  uaNewPassword: string = '';

  @IsNotBlank()
  @IsNotEmpty({ message: 'errors.uaPassword.required' })
  @ValidateIf((o) => o.validateNewPassword)
  @Of(() => String)
  uaNewPassword2: string = '';

  @IsNotEmpty({ message: 'errors.recaptcha.required' })
  @ValidateIf((o) => o.validateRecaptcha)
  @Of(() => String)
  recaptcha: string = '';

  @IsNotBlank()
  @IsNotEmpty({ message: 'errors.value.required' })
  @ValidateIf((o) => o.validateParentInfo)
  @Of(() => String)
  firstName: string = '';

  @IsNotBlank()
  @IsNotEmpty({ message: 'errors.value.required' })
  @ValidateIf((o) => o.validateParentInfo)
  @Of(() => String)
  lastName: string = '';

  @Of(() => Boolean)
  tokenLifetime: boolean = false;

  @Of(() => Boolean)
  disableChooseAccount: boolean = false;

  @Of(() => Boolean)
  validateRecaptcha: boolean = false;

  @Of(() => Boolean)
  validateNewPassword: boolean = false;

  @Of(() => Boolean)
  validateParentInfo: boolean = false;

  static of = Entity.of<UserAuthForm>();
}
