import { Entity, Of } from 'entity-of';

@Entity
export class AssignedServicesTableFilters {
  @Of(() => String, { optional: true })
  accountId?: string;

  @Of(() => String, { optional: true })
  serviceId?: string;

  @Of(() => String, { optional: true })
  status?: 'Enabled' | 'Disabled' | 'Review';

  @Of(() => String, { optional: true })
  startDate?: string;

  @Of(() => String, { optional: true })
  endDate?: string;

  @Of(() => Number, { optional: true })
  tenantBillingProfileId?: number;

  static of = Entity.of<AssignedServicesTableFilters>();
}
