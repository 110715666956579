import { Entity, Of } from 'entity-of';

@Entity
export class PaymentLinkRequestInput {
  @Of(() => Number, { nullable: true })
  invoiceId: number | null = null;

  @Of(() => String, { optional: true })
  rootOrgId?: string;

  @Of(() => String)
  redirectSuccess: string = '';

  @Of(() => String)
  redirectFailed: string = '';

  static of = Entity.of<PaymentLinkRequestInput>();
}
