import { Entity, Of } from 'entity-of';

@Entity
export class PhoneWithPrefix {
  @Of(() => String)
  prefix: string = '';

  @Of(() => String)
  phone: string = '';

  static of = Entity.of<PhoneWithPrefix>();
}
