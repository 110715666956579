import { Entity, Of } from 'entity-of';

import { AggregatedCharge } from '../AggregatedCharge';

@Entity
export class NonInvoicedAggregatedCharges {
  @Of(() => Number)
  tenantBillingProfileId: number = 0;

  @Of(() => [AggregatedCharge])
  charges: AggregatedCharge[] = [];

  static of = Entity.of<NonInvoicedAggregatedCharges>();
}
