import { IsEmail, IsNotEmpty, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';
import { t } from 'i18next';

export class ErasmusStudentInformation {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  erasmusStudentId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => Number)
  facultyId: number = 0;

  @Of(() => Number)
  cycleId: number = 0;

  @Of(() => Number)
  domainId: number = 0;

  @Of(() => Number)
  programId: number = 0;

  @Of(() => String)
  @ValidateIf((o) => o.erasmus)
  @IsNotEmpty({ message: t('errors.required') })
  originFacultyName: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.erasmus)
  @IsNotEmpty({ message: t('errors.required') })
  hostFacultyName: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.erasmus)
  @IsNotEmpty({ message: t('errors.required') })
  coordinatorName: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.erasmus)
  @IsEmail({}, { message: 'errors.email_format' })
  @IsNotEmpty({ message: t('errors.required') })
  coordinatorEmail: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.erasmus)
  @IsNotEmpty({ message: t('errors.required') })
  coordinatorPhone: string = '';

  @Of(() => String)
  domainName: string = '';

  @Of(() => String)
  programName: string = '';

  static of = Entity.of<ErasmusStudentInformation>();
}
