import { Entity, Of } from 'entity-of';

@Entity
export class FSInput {
  @Of(() => String)
  file?: File;

  @Of(() => String)
  fsFile?: File;

  @Of(() => String)
  fsTip = '';

  @Of(() => String)
  fsNume = '';

  @Of(() => String)
  fsUploader = '';

  @Of(() => String)
  _liceuID = '';

  static of = Entity.of<FSInput>();
}
