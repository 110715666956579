import { ArrayNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class ClassEditInput {
  @Of(() => String)
  studyYear: string = '';

  @Of(() => String)
  letter: string = '';

  @Of(() => String)
  classType: string = '';

  @Of(() => Number)
  specialization: number = 0;

  @Of(() => String)
  domain: string = '';

  @Of(() => String)
  profile: string = '';

  @Of(() => String)
  branch: string = '';

  @Of(() => String)
  professionalQualification: string = '';

  @Of(() => String)
  qualificationLevel: string = '';

  @Of(() => Number, { optional: true })
  clRoomID?: number;

  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.required' })
  coordinatorIds: string[] = [];

  static of = Entity.of<ClassEditInput>();
}
