import { ArrayNotEmpty, IsNotEmpty, MinLength } from 'class-validator';
import { Entity, Of } from 'entity-of';

import 'reflect-metadata';

import { CreateSlotInput } from '../../Slot';

@Entity
export class ScheduleInformationInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.name.required' })
  @MinLength(3, { message: 'errors.name.min_3' })
  name: string = '';

  @Of(() => String)
  ascID: string = '';

  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.days.required' })
  days: string[] = [];

  @Of(() => [CreateSlotInput])
  @ArrayNotEmpty({ message: 'errors.required' })
  slots: CreateSlotInput[] = [];

  static of = Entity.of<ScheduleInformationInput>();
}
