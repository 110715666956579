import { Person } from '@api/core/entities';
import { Type } from 'class-transformer';
import { IsBoolean } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { DueDate } from '../../DueDate';
import { FillersDetail } from '../../FillerDetail';
import { RecurrenceDate } from '../../RecurrenceDate';
import { Viewer } from '../../Viewer';

@Entity
export class CreateQuestionnaireInput {
  @Of(() => String)
  type: string = '';

  @Of(() => [String])
  fillers: string[] = [];

  @Of(() => Boolean)
  @IsBoolean()
  isAnonymousAnswer: boolean = false;

  @Of(() => Boolean)
  @IsBoolean()
  showSignatories: boolean = false;

  @Of(() => [Person])
  others: Person[] = [];

  @Of(() => String)
  publishResultsType: string = '';

  @Of(() => Boolean)
  @IsBoolean()
  recurrenceToggled?: boolean = false;

  @Of(() => [DueDate])
  @Type(() => DueDate)
  dates: DueDate[] = [];

  @Of(() => RecurrenceDate, { optional: true })
  @Type(() => RecurrenceDate)
  recurrence?: RecurrenceDate = RecurrenceDate.of({});

  @Of(() => Boolean)
  @IsBoolean()
  timeLimitToggled?: boolean = false;

  @Of(() => FillersDetail)
  allFillers?: FillersDetail = FillersDetail.of({});

  @Of(() => Viewer)
  @Type(() => Viewer)
  viewers? = Viewer.of({});

  static of = Entity.of<CreateQuestionnaireInput>();
}
