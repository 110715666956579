import { Entity, Of } from 'entity-of';

@Entity
export class StudentsGradesChart {
  @Of(() => String)
  value: string = '';

  @Of(() => String)
  astSistemNotare: string = '';

  @Of(() => String)
  total: string = '';

  static of = Entity.of<StudentsGradesChart>();
}
