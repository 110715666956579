import React from 'react';

import {
  PERM_CAD_AUX,
  PERM_CAD_DIRECTOR,
  PERM_CAD_EDUCATOR,
  PERM_CAD_INVATATOR,
  PERM_CAD_PROFESOR,
  PERM_CAD_REP_ADSERVIO,
  PERM_CAD_SECRETAR,
  TYPE_ADMIN,
  TYPE_ELEV,
  TYPE_PARINTE,
  TYPE_PERS_INST,
} from '@constants';
import { SessionItem } from '@lib/utils';
import { useHasAccess } from '@lib/utils/session/hasAccess';
import Cookies from 'js-cookie';

import { Sidenav } from '../../../../Components';

import {
  useAdminLinkGroups,
  useAuxiliaryLinkGroups,
  useInstitutionLinkGroups,
  useSecretaryLinkGroups,
  useStudentLinkGroups,
  useTeacherLinkGroups,
} from './utils/schools';

interface MainMenuProps {
  isPHP?: boolean;
}

export const SchoolMenu = ({ isPHP = false }: MainMenuProps) => {
  const { groups: headmasterGroups } = useSecretaryLinkGroups();
  const { groups: teacherGroups } = useTeacherLinkGroups();
  const { groups: parentStudentGroups } = useStudentLinkGroups();
  const { groups: institutionsPersonelGroups } = useInstitutionLinkGroups();
  const { groups: auxiliaryPersonelGroups } = useAuxiliaryLinkGroups();
  const { groups: adminGroups } = useAdminLinkGroups();

  const session: SessionItem = JSON.parse(String(localStorage.getItem('session')));

  const uaTip = session?.profile?.uaTip;

  const teacherMenu = !!(Cookies.get('MPR') == null || Cookies.get('MPR') === '1');

  const isHeadmaster = useHasAccess({ permissions: [PERM_CAD_DIRECTOR] });
  const isTeacher = useHasAccess({
    permissions: [PERM_CAD_PROFESOR, PERM_CAD_EDUCATOR, PERM_CAD_INVATATOR],
  });
  const isSecretary = useHasAccess({ permissions: [PERM_CAD_SECRETAR] });
  const isAuxPers = useHasAccess({ permissions: [PERM_CAD_AUX] });
  const isAdsPers = useHasAccess({ permissions: [PERM_CAD_REP_ADSERVIO] });

  return (
    <>
      {(isHeadmaster || isSecretary || isAdsPers) && !teacherMenu && (
        <Sidenav
          isPHP={isPHP}
          groups={headmasterGroups}
          logoUrl={{ url: 'dashboard', redirectRule: isPHP ? 'window' : 'push' }}
        />
      )}

      {isTeacher && teacherMenu && (
        <Sidenav
          isPHP={isPHP}
          groups={teacherGroups}
          logoUrl={{ url: 'dashboard', redirectRule: 'window' }}
        />
      )}

      {(uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE) && (
        <Sidenav
          isPHP={isPHP}
          groups={parentStudentGroups}
          logoUrl={{ url: 'dashboard', redirectRule: isPHP ? 'window' : 'push' }}
        />
      )}

      {uaTip === TYPE_PERS_INST && (
        <Sidenav
          isPHP={isPHP}
          groups={institutionsPersonelGroups}
          logoUrl={{ url: 'institutions', redirectRule: isPHP ? 'window' : 'push' }}
        />
      )}

      {isAuxPers && teacherMenu && !isTeacher && (
        <Sidenav
          isPHP={isPHP}
          groups={auxiliaryPersonelGroups}
          logoUrl={{ url: 'dashboard', redirectRule: isPHP ? 'window' : 'push' }}
        />
      )}

      {uaTip === TYPE_ADMIN && (
        <Sidenav
          isPHP={isPHP}
          groups={adminGroups}
          logoUrl={{ url: 'admin', redirectRule: 'window' }}
        />
      )}
    </>
  );
};
