import React from 'react';

import { SyncSelectProps } from '@ui2/utils/ReactSelect/ReactSelect';

import { Props, SyncSelect } from '../../SyncSelect';
import { FormElement, FormElementProps } from '../FormElement';

export const FormSyncSelect = ({
  testId,
  name,
  label,
  isRequired,
  helperText,
  children,
  showHelperText,
  inlineLayout,
  errorBehaviour,
  onChangeCallback,
  ...props
}: React.PropsWithChildren<FormElementProps<Props<any, boolean, any>> & SyncSelectProps>) => {
  return (
    <FormElement
      {...{
        name,
        label,
        isRequired,
        helperText,
        onChangeCallback,
        showHelperText,
        testId,
        errorBehaviour,
        inlineLayout,
      }}
    >
      <SyncSelect {...props}>{children}</SyncSelect>
    </FormElement>
  );
};
