import { Entity, Of } from 'entity-of';

@Entity
export class DisciplineAcademicResponse {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  englishName: string = '';

  @Of(() => String)
  acronym: string = '';

  @Of(() => String)
  uniqueAcronym: string = '';

  @Of(() => Number)
  studyYearId: number = 0;

  @Of(() => Number)
  semester: number = 0;

  @Of(() => Number)
  series: number = 0;

  @Of(() => Number)
  examPercent: number = 0;

  @Of(() => Number)
  seminarPercent: number = 0;

  @Of(() => Number)
  laboratoryPercent: number = 0;

  @Of(() => Number)
  projectPercent: number = 0;

  @Of(() => Boolean)
  necessaryMinimumExamGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumSeminarGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumLaboratoryGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumProjectGrade: boolean = false;

  @Of(() => String)
  role: string = '';

  @Of(() => String)
  facultyName: string = '';

  @Of(() => String)
  currentNumber: string = '';

  @Of(() => [DisciplineAssociatedProfessors])
  associatedProfessors: DisciplineAssociatedProfessors[] = [];

  static of = Entity.of<DisciplineAcademicResponse>();
}

@Entity
export class DisciplineAssociatedProfessors {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  birthGivenName: string = '';

  @Of(() => String)
  universityEmail: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  surname: string = '';

  @Of(() => String)
  discipline: string = '';

  @Of(() => String)
  acronyms: string = '';

  @Of(() => Number)
  disciplineId: number = 0;

  @Of(() => String)
  roles: string = '';

  @Of(() => String)
  years: string = '';

  @Of(() => String)
  semesters: string = '';

  @Of(() => String)
  role: string = '';

  @Of(() => Boolean)
  examiner: boolean = false;

  @Of(() => String)
  department: string = '';

  @Of(() => String)
  departmentId: string = '';

  @Of(() => Number)
  mark: number = 0;

  @Of(() => String)
  function: string = '';

  @Of(() => String)
  functionId: string = '';

  @Of(() => String)
  functionType: string = '';

  @Of(() => String)
  leadershipFunction: string = '';

  @Of(() => Number)
  leadershipFunctionId: number = 0;

  @Of(() => String)
  adminLeadershipFunctionType: string = '';

  @Of(() => Boolean)
  main: boolean = false;

  static of = Entity.of<DisciplineAssociatedProfessors>();
}
