import { Entity, Of } from 'entity-of';

@Entity
export class AssignServiceAgregateCharge {
  @Of(() => Number, { optional: true })
  id?: number;

  @Of(() => String)
  createdAt = '';

  @Of(() => String)
  name = '';

  @Of(() => Number, { optional: true })
  count?: number;

  static of = Entity.of<AssignServiceAgregateCharge>();
}
