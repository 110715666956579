import { useQuery, UseQueryOptions } from 'react-query';

import { LegacyPaginatedResponse, School, SearchSchoolsInput } from '@api/core/entities';
import { httpClient } from '@lib/http';
import { computeUrlParams } from '@lib/utils';
import { AxiosError } from 'axios';

type QueryInput = SearchSchoolsInput;

export const getSchools = ({ queryKey }: UseQueryOptions<QueryInput>) => {
  const [_, queryPayload] = queryKey ?? [];

  let url = `/scoli`;

  const params = computeUrlParams(queryPayload as QueryInput);

  if (params) {
    url += `?${params}`;
  }

  return httpClient<LegacyPaginatedResponse<School>>(url, {
    responseMapper: School.of,
  }).get();
};

export const useSchoolsQuery = (
  queryInput: QueryInput,
  options?: UseQueryOptions<LegacyPaginatedResponse<School>, AxiosError>
) => {
  return useQuery<LegacyPaginatedResponse<School>, AxiosError>(
    ['schools', queryInput],
    getSchools,
    options
  );
};
