import React, { useCallback } from 'react';

import { AlertStatus, useToast as useChakraToast } from '@chakra-ui/react';
import { BridgeEventType, usePlatformEvent } from '@lib/bridge';
import { useStorageSession } from '@lib/utils';

import { AlertMessage } from './AlertMessage';

interface ToastProps {
  title?: string;
  description: string;
  status: AlertStatus;
  isClosable?: boolean;
  duration?: number;
  renderContent?(): React.ReactNode;
}

export function useToast() {
  const { isNative } = useStorageSession();
  const { sendEvent } = usePlatformEvent();
  const toast = useChakraToast();

  return useCallback(
    (toastData: ToastProps) => {
      if (isNative) {
        sendEvent({
          type: BridgeEventType.display_toast,
          data: {
            description: toastData.description,
            status: toastData.status,
          },
        });
      } else {
        if (typeof toastData.isClosable === 'undefined') {
          toastData.isClosable = true;
        }

        toast({
          variant: 'left-accent',
          description: toastData.description,
          status: toastData.status,
          duration: toastData.duration,
          position: 'top-right',
          isClosable: toastData.isClosable,
          containerStyle: {
            marginRight: '2rem',
          },
          render: (props) => {
            return <AlertMessage isToast={true} {...(toastData as any)} onClose={props.onClose} />;
          },
        });
      }
    },
    [toast]
  );
}
