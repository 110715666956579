import { useTranslation } from 'react-i18next';

import { PERM_SUBSCRIPTION_ADMIN, PERM_SUBSCRIPTION_STATISTICS } from '@constants';
import { useRouter } from '@lib/router';
import { LinkButtonItem, LinkButtonsGroup } from '@modules/shared';

export const useAdminLinkGroups = () => {
  const { lang } = useRouter();
  const { t } = useTranslation(['Site', 'common']);

  const groups: LinkButtonsGroup[] = [
    {
      items: [
        LinkButtonItem.of({
          icon: 'Admin',
          label: t('buttons_mm.admin'),
          url: 'admin',
          redirectRule: 'window',
        }),
        LinkButtonItem.of({
          icon: 'FiUsers',
          label: t('buttons_mm.utilizatori'),
          matchingUrls: [
            'admin/elevi/cauta',
            'admin/cadre/cauta',
            'admin/personal_inst',
            'admin/admini/cauta',
          ],
          accessRules: { permissions: ['9', '10', '11'] },
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.utilizatori_elevi'),
              url: 'admin/elevi/cauta',
              redirectRule: 'window',
              accessRules: { permissions: ['11'] },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.utilizatori_cadre'),
              url: 'admin/cadre/cauta',
              redirectRule: 'window',
              accessRules: { permissions: ['10'] },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.utilizatori_personal_inst'),
              url: 'admin/personal_inst',
              redirectRule: 'window',
              accessRules: { permissions: ['23'] },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.utilizatori_admini'),
              url: 'admin/admini/cauta',
              redirectRule: 'window',
              accessRules: { permissions: ['9'] },
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'Coins',
          label: t('buttons_mm.plati'),
          matchingUrls: [
            'admin/plati/abonamente',
            'admin/plati/chitante',
            'admin/plati/gratuitati',
            'admin/plati/gratuitati/tip',
            'admin/plati/proforme',
            'admin/plati/fiscale',
          ],
          accessRules: { permissions: ['1'] },
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.plati_abonamente'),
              url: 'admin/plati/abonamente',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.plati_chitante'),
              url: 'admin/plati/chitante',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.plati_gratuitati'),
              url: 'admin/plati/gratuitati',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.plati_tip_gratuitati'),
              url: 'admin/plati/gratuitati/tip',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.plati_proforme'),
              url: 'admin/plati/proforme',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.plati_fiscale'),
              url: 'admin/plati/fiscale',
              redirectRule: 'window',
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'FiLayers',
          label: t('buttons_mm.dev'),
          matchingUrls: [
            'admin/dev/query_runner',
            'admin/dev/code_exec',
            'admin/dev/email_complaints',
            'admin/configs/master',
          ],
          accessRules: { permissions: ['3', '4'] },
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.dev_query_runner'),
              url: 'admin/dev/query_runner',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.dev_code_exec'),
              url: 'admin/dev/code_exec',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.dev_email_complaints'),
              url: 'admin/dev/email_complaints',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.dev_config_master'),
              url: 'admin/configs/master',
              redirectRule: 'window',
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'FiBarChart2',
          label: t('buttons_mm.admin_statistici'),
          matchingUrls: [
            'admin/statistici/incasari',
            'admin/statistici/autentificari',
            'admin/statistici/utilizatori',
            'admin/statistici/queues',
          ],
          accessRules: { permissions: ['1'] },
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.admin_statistici_incasari'),
              url: 'admin/statistici/incasari',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.admin_statistici_auth'),
              url: 'admin/statistici/autentificari',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.admin_statistici_utilizatori'),
              url: 'admin/statistici/utilizatori',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.admin_statistici_queues'),
              url: 'admin/statistici/queues',
              redirectRule: 'window',
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'FiTrendingUp',
          label: t('buttons_mm.admin_analytics'),
          matchingUrls: [
            'admin/analytics/active_users',
            'admin/analytics/mesaje',
            'admin/analytics/lectii_interactive',
            'admin/analytics/chestionare',
          ],
          accessRules: { permissions: ['28'] },
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.admin_analytics_active_users'),
              url: 'admin/analytics/active_users',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.admin_analytics_messages'),
              url: 'admin/analytics/mesaje',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.admin_analytics_interactive_lessons'),
              url: 'admin/analytics/lectii_interactive',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.admin_analytics_forms'),
              url: 'admin/analytics/chestionare',
              redirectRule: 'window',
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'School',
          label: t('buttons_mm.organizations'),
          matchingUrls: [
            'organizations',
            'organizations/schools',
            'organizations/universities',
            'organizations/labels',
          ],
          accessRules: { permissions: ['22'] },
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.schools_highschool'),
              url: 'organizations/schools',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.universities'),
              url: 'organizations/universities',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.labels'),
              url: 'organizations/labels',
              redirectRule: 'window',
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'FiCheckCircle',
          label: t('buttons_mm.admin_tichete'),
          url: `admin/tichete`,
          matchingUrls: ['admin/tichete/stats'],
          accessRules: { permissions: ['29'] },
        }),
        LinkButtonItem.of({
          icon: 'Coins',
          label: t('buttons_mm.admin_subscriptions'),
          accessRules: { permissions: [PERM_SUBSCRIPTION_ADMIN, PERM_SUBSCRIPTION_STATISTICS] },
          matchingUrls: [
            'subscriptions/packages/search',
            'subscriptions/schools',
            'subscriptions/statistics',
          ],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.admin_packages'),
              url: 'subscriptions/packages/search',
              accessRules: { permissions: [PERM_SUBSCRIPTION_ADMIN] },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.admin_schools'),
              url: 'subscriptions/schools',
              accessRules: { permissions: [PERM_SUBSCRIPTION_ADMIN] },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.admin_statistics'),
              url: 'subscriptions/statistics',
              accessRules: { permissions: [PERM_SUBSCRIPTION_ADMIN, PERM_SUBSCRIPTION_STATISTICS] },
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'FiCalendar',
          label: t('buttons_mm.holidays'),
          url: 'national_holidays',
          redirectRule: 'window',
          accessRules: { permissions: ['2'] },
        }),
      ],
    },
    {
      emptyTitle: true,
      items: [
        LinkButtonItem.of({
          icon: 'FiMail',
          label: t('buttons_mm.mesaje'),
          url: `messages`,
          matchingUrls: [
            'messages/send',
            'messages/sent',
            'messages/drafts',
            'messages/archive',
            'messages/received',
          ],
          accessRules: { permissions: ['5'] },
        }),
        LinkButtonItem.of({
          icon: 'FiFile',
          label: t('buttons_mm.fisiere'),
          url: `files`,
          matchingUrls: ['files/'],
          accessRules: { permissions: ['40'] },
        }),
        LinkButtonItem.of({
          icon: 'FiBell',
          label: t('buttons_mm.noutati'),
          url: `admin/news`,
          redirectRule: 'window',
          accessRules: {
            permissions: ['8'],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiFileText',
          label: t('buttons_mm.admin_newsletter'),
          url: 'admin/newsletter',
          redirectRule: 'window',
          accessRules: {
            permissions: ['41'],
          },
        }),
        LinkButtonItem.of({
          icon: 'Library',
          label: t('buttons_mm.biblioteca'),
          url: `library`,
          matchingUrls: ['library/manage', 'library/book'],
          accessRules: {
            permissions: ['39'],
          },
        }),
        LinkButtonItem.of({
          icon: 'GraduationCap',
          label: t('buttons_mm.admission'),
          url: `admin/admitere`,
          redirectRule: 'window',
          matchingUrls: ['admin/admitere', 'admin/admitere/institutii', 'admin/admitere/campanii'],
          accessRules: {
            permissions: ['38'],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiShoppingCart',
          label: t('buttons_mm.edumarket'),
          url: `admin/edumarket`,
          redirectRule: 'window',
          matchingUrls: ['edumarket/about', 'edumarket/applications/details'],
          accessRules: {
            permissions: ['37'],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiHelpCircle',
          label: t('buttons_mm.support'),
          url:
            lang === 'ro'
              ? 'https://manual.adservio.ro/web/director-secretar/'
              : 'https://manual.adservio.ro/web/en/headmaster-secretary/',
          redirectRule: 'blank',
          accessRules: {
            permissions: ['27'],
          },
        }),
      ],
    },
  ];

  return {
    groups,
  };
};
