import { VatCategory, VatExemptionCode } from '@modules/shared/utils/eFactura';
import {
  ArrayNotEmpty,
  IsNotEmpty,
  registerDecorator,
  ValidateIf,
  ValidationArguments,
  ValidationOptions,
} from 'class-validator';
import { Entity, Of } from 'entity-of';

export type Permission = 'yes' | 'no' | 'request' | undefined;
export type ServiceType = 'charge' | 'credit';
export type Recurrence = 'daily' | 'monthly' | 'yearly' | 'weekly' | 'biannually' | 'quarterly';
export type ServiceStatus = 'enabled' | 'disabled' | 'review';
export type RequestStatus = 'accepted' | 'rejected' | 'pending';
export type RequestType = 'add' | 'remove';
export type AssignableBy = 'manager' | 'teacher' | 'head_teacher';

@Entity
export class Service {
  @Of(() => Number)
  id = 0;

  @Of(() => String)
  tenantId = '';

  @Of(() => String)
  name = '';

  @Of(() => String)
  description = '';

  @Of(() => String, { optional: true })
  userCanAdd: Permission;

  @Of(() => String, { optional: true })
  userCanRemove: Permission;

  @Of(() => String, { optional: true })
  assignableBy?: AssignableBy;

  @Of(() => Number)
  billingProfileId = 0;

  @Of(() => String, { optional: true })
  status?: ServiceStatus;

  @Of(() => String, { optional: true })
  vatRateId?: string;

  @Of(() => Number, { optional: true, nullable: true })
  oneTimePrice?: number | null;

  @Of(() => Number, { optional: true, nullable: true })
  price?: number | null;

  @Of(() => String, { optional: true })
  recurrence?: Recurrence;

  @Of(() => [String], { optional: true })
  dailyFrequency?: string[] = [];

  @Of(() => Boolean, { optional: true })
  hasCustomCurrency?: boolean;

  @Of(() => String)
  currency?: string = '';

  @Of(() => Number, { optional: true, nullable: true })
  expiration?: number | null;

  @Of(() => String, { optional: true, nullable: true })
  internalCode?: string | null = '';

  @Of(() => String, { optional: true })
  socialSecurityNumber?: string = '';

  @Of(() => String, { optional: true })
  createdAt?: string;

  @Of(() => String, { optional: true })
  updatedAt?: string;

  @Of(() => Boolean, { optional: true })
  isHidden?: boolean;

  get type(): ServiceType {
    if ((this.oneTimePrice && this.oneTimePrice < 0) || (this.price && this.price < 0)) {
      return 'credit';
    }

    return 'charge';
  }

  static getType(service?: Service): ServiceType {
    if (
      service &&
      ((service.oneTimePrice && service.oneTimePrice < 0) || (service.price && service.price < 0))
    ) {
      return 'credit';
    }

    return 'charge';
  }

  static of = Entity.of<Service>();
}

@Entity
export class AssignedService {
  @Of(() => Number)
  id = 0;

  @Of(() => String)
  tenantId = '';

  @Of(() => Number, { optional: true })
  tenantBillingProfileId = 0;

  @Of(() => String)
  name = '';

  @Of(() => String)
  description = '';

  @Of(() => String, { optional: true })
  userCanAdd: Permission;

  @Of(() => String, { optional: true })
  userCanRemove: Permission;

  @Of(() => Number)
  billingProfileId = 0;

  @Of(() => String, { optional: true })
  status?: ServiceStatus;

  @Of(() => Number, { optional: true, nullable: true })
  oneTimePrice?: number | null;

  @Of(() => Number, { optional: true, nullable: true })
  price?: number | null;

  @Of(() => String, { optional: true })
  recurrence?: Recurrence;

  @Of(() => String, { optional: true })
  currency?: string;

  @Of(() => Number, { optional: true, nullable: true })
  expiration?: number | null;

  @Of(() => String, { optional: true, nullable: true })
  internalCode?: string | null = '';

  @Of(() => String, { optional: true })
  socialSecurityNumber?: string = '';

  @Of(() => String, { optional: true })
  createdAt?: string;

  @Of(() => String, { optional: true })
  updatedAt?: string;

  @Of(() => String)
  accountId = '';

  @Of(() => String)
  startDate = '';

  @Of(() => String, { nullable: true })
  endDate: string | null = null;

  @Of(() => String)
  notes = '';

  @Of(() => Number)
  serviceId: number = 0;

  get type(): ServiceType {
    if ((this.oneTimePrice && this.oneTimePrice < 0) || (this.price && this.price < 0)) {
      return 'credit';
    }

    return 'charge';
  }

  static getType(service?: Service): ServiceType {
    if (
      service &&
      ((service.oneTimePrice && service.oneTimePrice < 0) || (service.price && service.price < 0))
    ) {
      return 'credit';
    }

    return 'charge';
  }

  static of = Entity.of<AssignedService>();
}

@Entity
export class ServiceBillingProfileInput {
  @Of(() => Number, { optional: true })
  billingProfileId?: number | undefined;

  static of = Entity.of<ServiceBillingProfileInput>();
}

@Entity
export class ServiceInformationInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.name.required' })
  name = '';

  @Of(() => String)
  description = '';

  @Of(() => String, { optional: true, nullable: true })
  internalCode?: string | null;

  static of = Entity.of<ServiceInformationInput>();
}

@Entity
export class ServicePriceForm {
  @Of(() => String, { optional: true })
  @IsNotEmpty({ message: 'errors.type.required' })
  type?: ServiceType;

  @Of(() => Boolean, { optional: true })
  hasCustomCurrency?: boolean;

  @Of(() => Number, { optional: true, nullable: true })
  @MinIfNotUndefined(0.001, { message: 'errors.oneTimeValue.min' })
  @OnePriceRequired('price', { message: 'errors.servicePrice.required' })
  oneTimePrice?: number | null;

  @Of(() => Number, { optional: true, nullable: true })
  @MinIfNotUndefined(0.001, { message: 'errors.recurringValue.min' })
  @OnePriceRequired('oneTimePrice', { message: 'errors.servicePrice.required' })
  price?: number | null;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => !!o.price)
  @IsNotEmpty({ message: 'errors.recurrence.required' })
  recurrence?: Recurrence;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.recurrence === 'daily')
  @IsNotEmpty({ message: 'errors.dailyFrequency.required' })
  @ArrayNotEmpty({ message: 'errors.dailyFrequency.required' })
  dailyFrequency?: string[];

  @Of(() => Number, { optional: true, nullable: true })
  expiration?: number | null;

  static of = Entity.of<ServicePriceInput>();
}

@Entity
export class ServicePriceInput extends ServicePriceForm {
  @Of(() => String, { optional: true })
  @ValidateIf((o) => !!o.hasCustomCurrency)
  @IsNotEmpty({ message: 'errors.currency.required' })
  currency?: string;

  @Of(() => String)
  @ValidateIf((o) => o.isVatPayer)
  @IsNotEmpty({ message: 'errors.vatRate.required' })
  vatRateId?: string;

  @Of(() => String, { optional: true })
  isVatPayer?: boolean;

  static of = Entity.of<ServicePriceInput>();
}

@Entity
export class ServicePriceFieldsInput extends ServicePriceForm {
  @Of(() => String)
  currency: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.isVatPayer)
  @IsNotEmpty({ message: 'errors.vatRate.required' })
  vatRateId?: string;

  @Of(() => String, { optional: true })
  isVatPayer?: boolean;

  static of = Entity.of<ServicePriceFieldsInput>();
}

function OnePriceRequired(property: string, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'onePriceRequired',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [property],
      options: validationOptions,
      validator: {
        validate(value: number, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const relatedValue = (args.object as any)[relatedPropertyName];

          return relatedValue || value;
        },
      },
    });
  };
}

function MinIfNotUndefined(min: number, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'minIfNotUndefined',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: number) {
          return (
            value === null || typeof value === 'undefined' || Number.isNaN(value) || value > min
          );
        },
      },
    });
  };
}

@Entity
export class ServicePermissionsInput {
  @Of(() => String, { optional: true })
  @IsNotEmpty({ message: 'errors.selectOption.required' })
  userCanAdd: Permission;

  @Of(() => String, { optional: true })
  @IsNotEmpty({ message: 'errors.selectOption.required' })
  userCanRemove: Permission;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => !o.isUniversityOrg)
  @IsNotEmpty({ message: 'errors.selectOption.required' })
  assignableBy?: AssignableBy;

  @Of(() => Boolean, { optional: true })
  isUniversityOrg?: boolean;

  static of = Entity.of<ServicePermissionsInput>();
}

export interface ServiceInput {
  name: string;
  description: string;
  internalCode?: string | undefined;
  oneTimePrice?: number | undefined;
  price?: number | undefined;
  recurrence?: Recurrence;
  expiration?: number;
  userCanAdd: Permission;
  userCanRemove: Permission;
  billingProfileId: number | undefined;
  status?: ServiceStatus;
  isHidden?: boolean;
  vatRateId?: string;
}

export interface AssignServiceInput {
  serviceId: number;
  startDate: string;
  endDate: string | null;
  notes?: string;
  billingProfileId?: number;
  invoiceDay?: number;
}

@Entity
export class ServicePeriodInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.startDate.required' })
  startDate: string = '';

  @Of(() => String, { nullable: true })
  endDate: string | null = null;

  static of = Entity.of<ServicePeriodInput>();
}

export class ServiceAssignModalInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.startDate.required' })
  startDate: string = '';

  @Of(() => String, { nullable: true })
  endDate: string | null = null;

  @Of(() => String, { optional: true })
  notes?: string = '';

  @Of(() => Number, { optional: true })
  billingProfileId?: number = 0;

  static of = Entity.of<ServiceAssignModalInput>();
}

@Entity
export class ServiceRequest {
  @Of(() => Number)
  id = 0;

  @Of(() => String)
  name = '';

  @Of(() => String)
  avatar = '';

  @Of(() => String)
  serviceId = '';

  @Of(() => String)
  startDate = '';

  @Of(() => Number)
  assignedServiceId = 0;

  @Of(() => String)
  endDate = '';

  @Of(() => String, { optional: true })
  currency?: '';

  //for request type:  0 means 'add', 1 means 'remove'
  @Of(() => Number)
  requestType = 0;

  @Of(() => String)
  status: RequestStatus = 'pending';

  @Of(() => Number)
  oneTimePrice = 0;

  @Of(() => Number)
  price = 0;

  @Of(() => String, { optional: true })
  recurrence?: Recurrence;

  @Of(() => String)
  accountId = '';

  get type(): ServiceType {
    if ((this.oneTimePrice && this.oneTimePrice < 0) || (this.price && this.price < 0)) {
      return 'credit';
    }

    return 'charge';
  }

  static getType(service?: ServiceRequest): ServiceType {
    if (
      service &&
      ((service.oneTimePrice && service.oneTimePrice < 0) || (service.price && service.price < 0))
    ) {
      return 'credit';
    }

    return 'charge';
  }

  static of = Entity.of<ServiceRequest>();
}

@Entity
export class ServicePreviewResponse {
  @Of(() => String)
  name = '';

  @Of(() => Number)
  unitValue = 0;

  @Of(() => Number)
  totalAmount = 0;

  @Of(() => Number)
  netAmount = 0;

  @Of(() => Number)
  vatAmount = 0;

  @Of(() => Number)
  originalUnitValue = 0;

  @Of(() => Number)
  quantity = 0;

  @Of(() => Number)
  vatValue = 0;

  @Of(() => String)
  vatName = '';

  @Of(() => String)
  currency: string = '';

  @Of(() => String)
  originalCurrency: string = '';

  @Of(() => VatCategory, { optional: true })
  vatCategory?: VatCategory;

  @Of(() => VatExemptionCode, { optional: true })
  vatExemptionCode?: VatExemptionCode;

  @Of(() => String, { optional: true })
  vatExemptionReason?: string;

  static of = Entity.of<Service>();
}
