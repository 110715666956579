import { Entity, Of } from 'entity-of';

@Entity
export class Document {
  @Of(() => Number)
  number: number = 0;

  @Of(() => String)
  date: string = '';

  @Of(() => Number)
  accommodationId: number = 0;

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  name: string = '';

  static of = Entity.of<Document>();
}
