import { MaxDynamic } from '@lib/utils';
import { IsNotEmpty, Matches, MaxLength } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class RoomTypeInput {
  @Of(() => String)
  @Matches(/^(?=.*[^\s])[A-Za-z0-9\s]+$/, { message: 'errors.general.alphanumeric' })
  @MaxLength(150, { message: 'errors.general.max_150' })
  @IsNotEmpty({ message: 'errors.accommodations.room_types.name.required' })
  name: string = '';

  @Of(() => Number)
  @MaxDynamic(127)
  @IsNotEmpty({ message: 'errors.accommodations.room_types.noOfBeds.required' })
  noOfBeds: number | null = null;

  static of = Entity.of<RoomTypeInput>();
}
