import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class BillingProfileIdInput {
  @Of(() => Number, { optional: true })
  @IsNotEmpty({ message: 'errors.billingProfile.required' })
  billingProfileId: number | undefined;

  static of = Entity.of<BillingProfileIdInput>();
}
