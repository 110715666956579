import { Person } from '@api/core/entities';
import { Type } from 'class-transformer';
import { IsNotEmpty, ValidateNested } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { CertificateFields } from '../Certificate';

export class EditDiplomaFormInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.date.required' })
  diplomaDate: string = '';

  @Of(() => Boolean)
  includeLogo: boolean = false;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.type.required' })
  diplomaType: string = '';

  @Of(() => String)
  headmaster: string = '';

  @Of(() => String)
  teacher: string = '';

  @ValidateNested()
  @Type(() => CertificateFields)
  @Of(() => CertificateFields)
  cerData: CertificateFields = CertificateFields.of({});

  @Of(() => Person)
  cerPerson: Person = Person.of({});

  static of = Entity.of<EditDiplomaFormInput>();
}
