import { Entity, Of } from 'entity-of';

@Entity
export class LivingSituation {
  @Of(() => String)
  elLocCu: string = '';

  @Of(() => Number)
  male: number = 0;

  @Of(() => Number)
  female: number = 0;

  @Of(() => Number)
  other: number = 0;

  @Of(() => Number)
  total: number = 0;

  static of = Entity.of<LivingSituation>();
}
