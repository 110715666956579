import { Type } from 'class-transformer';
import {
  IsArray,
  IsBoolean,
  IsNotEmpty,
  Validate,
  ValidateIf,
  ValidateNested,
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';
import { Entity, Of } from 'entity-of';

import { DueDate } from '../DueDate';
import { RecurrenceDate } from '../RecurrenceDate';
import { RecurrenceType } from '../Todo';

@ValidatorConstraint({ name: 'atLeastOneDate', async: false })
class AtLeastOneDate implements ValidatorConstraintInterface {
  validate(value: any, args: ValidationArguments) {
    const obj = args.object as CreateValidatorStep3;

    if (obj.recurrenceToggled === true) {
      if (obj.recurrence.type === RecurrenceType.WEEKLY) {
        // Verifică dacă startDate și endDate nu sunt string-uri goale și dacă daysOfWeek nu este array gol
        return (
          typeof obj.recurrence.endDate === 'string' &&
          obj.recurrence.endDate.trim() !== '' &&
          Array.isArray(obj.recurrence.daysOfWeek) &&
          obj.recurrence.daysOfWeek.length > 0
        );
      } else if (obj.recurrence.type === RecurrenceType.MONTHLY) {
        // Verifică dacă startDate și endDate nu sunt string-uri goale și dayOfMonth nu este undefined sau gol
        return (
          typeof obj.recurrence.endDate === 'string' &&
          obj.recurrence.endDate.trim() !== '' &&
          Array.isArray(obj.recurrence.dayOfMonth) &&
          obj.recurrence.dayOfMonth.length > 0
        );
      }
    }

    // Verificare generală pentru alte cazuri
    return (
      typeof obj.recurrence.endDate === 'string' ||
      (Array.isArray(obj.recurrence.dayOfMonth) && obj.recurrence.dayOfMonth.length > 0) ||
      (Array.isArray(obj.recurrence.daysOfWeek) && obj.recurrence.daysOfWeek.length > 0)
    );
  }

  defaultMessage(args: ValidationArguments) {
    return 'errors.questionnaires.selectValidDate';
  }
}
@Entity
export class CreateValidatorStep3 {
  @Of(() => Boolean)
  @IsBoolean()
  recurrenceToggled?: boolean = false;

  // Validăm dates doar dacă recurrenceToggled este false
  @Of(() => [DueDate])
  @ValidateIf((o) => o.recurrenceToggled === false)
  @IsArray()
  @Type(() => DueDate)
  @ValidateNested({ each: true })
  dates: DueDate[] = [];

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.questionnaires.publishResultType' })
  publishResultsType: string = '';

  // Validăm recurrence doar dacă recurrenceToggled este true
  @Of(() => RecurrenceDate, { optional: true })
  @ValidateIf((o) => o.recurrenceToggled === true)
  @Type(() => RecurrenceDate)
  @ValidateNested({ each: true })
  recurrence = RecurrenceDate.of({});

  // Custom validation that depends on the state of others
  @Validate(AtLeastOneDate, {
    message: 'errors.questionnaires.selectValidDate',
  })
  allRecurrences?: boolean; // Used only for validation, not submitted

  static of = Entity.of<CreateValidatorStep3>();
}
