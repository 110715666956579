import React, { forwardRef } from 'react';
import ReCAPTCHA, { ReCAPTCHAProps } from 'react-google-recaptcha';

import { useRouter } from '@lib/router';

import { FormElement, FormElementProps } from '../FormElement';

export interface FormRecaptchaProps extends FormElementProps<Omit<ReCAPTCHAProps, 'sitekey'>> {}

const recaptchaKey = window.env?.GOOGLE_RECAPTCHA_SITE_KEY ?? '';

export const FormRecaptcha = forwardRef<ReCAPTCHA, FormRecaptchaProps>(
  (
    { testId, name, label, isRequired, helperText, showHelperText, errorBehaviour, ...props },
    ref
  ) => {
    const { lang } = useRouter();

    return (
      <FormElement
        {...{ name, label, isRequired, helperText, showHelperText, errorBehaviour, testId }}
      >
        <ReCAPTCHA
          sitekey={recaptchaKey}
          ref={ref as React.RefObject<ReCAPTCHA>}
          hl={lang}
          {...props}
        />
      </FormElement>
    );
  }
);
