import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class ClassTransferSearchInput extends SimpleQueryInput {
  @Of(() => String, { optional: true })
  ascID?: string;

  @Of(() => String, { optional: true })
  clID?: string;

  @Of(() => String, { optional: true })
  tcID?: string;

  @Of(() => String, { optional: true })
  tcElevID?: string;

  @Of(() => Number, { optional: true })
  tcTransferNumber?: number;

  static of = Entity.of<ClassTransferSearchInput>();
}
