import { Entity, Of } from 'entity-of';

@Entity
export class AggregatedCharge {
  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  amount: number = 0;

  @Of(() => Number)
  quantity: number = 0;

  @Of(() => String)
  periodStart: string = '';

  @Of(() => String)
  periodEnd: string = '';

  @Of(() => Boolean)
  isPenalty: boolean = false;

  @Of(() => Boolean)
  isOneTimePayment: boolean = false;

  @Of(() => String)
  currency: string = '';

  @Of(() => Number)
  assignedServiceId: number = 0;

  @Of(() => Number)
  serviceId: number = 0;

  @Of(() => String)
  notes: string = '';

  @Of(() => [Number])
  chargesIds: number[] = [];

  @Of(() => Number)
  accountBillingProfileId: number = 0;

  static of = Entity.of<AggregatedCharge>();
}
