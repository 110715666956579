import { Entity, Of } from 'entity-of';

@Entity
export class Address {
  @Of(() => City, { optional: true })
  adrLocalitate?: City;

  @Of(() => County, { optional: true })
  adrJudet?: County;

  @Of(() => Country, { optional: true })
  adrTara?: Country;

  static of = Entity.of<Address>();

  static shortToString = (address?: Address) => {
    if (!address) return '';

    const value = [address.adrLocalitate?.cityFullName, address.adrJudet?.judNume]
      .filter(Boolean)
      .join(', ');

    return value;
  };

  static longToString = (address?: Address) => {
    if (!address) return '';

    const value = [
      address.adrLocalitate?.cityFullName,
      address.adrJudet?.judNume,
      address.adrTara?.taraNumeFull,
    ]
      .filter(Boolean)
      .join(', ');

    return value;
  };
}

@Entity
export class City {
  @Of(() => String)
  cityID: string = '';

  @Of(() => String)
  cityName: string = '';

  @Of(() => String)
  cityFullName: string = '';

  @Of(() => String, { optional: true })
  cityType?: string;

  @Of(() => County, { optional: true })
  adrJudet?: County;

  @Of(() => Country, { optional: true })
  adrTara?: Country;

  @Of(() => String, { optional: true })
  judId?: string = '';

  @Of(() => String)
  liceuOrgType?: string;

  @Of(() => String)
  countScoli?: string = '';

  static of = Entity.of<City>();
}

@Entity
export class County {
  @Of(() => String)
  judID: string = '';

  @Of(() => String)
  judNume: string = '';

  @Of(() => String)
  judCod?: string = '';

  @Of(() => Country, { optional: true })
  adrTara?: Country;

  @Of(() => String)
  liceuOrgType?: string;

  @Of(() => String)
  countScoli?: string = '';

  static of = Entity.of<County>();
}

@Entity
export class Country {
  @Of(() => String)
  taraID: string = '';

  @Of(() => String)
  taraCod: string = '';

  @Of(() => String)
  taraNume: string = '';

  @Of(() => String)
  taraNumeFull: string = '';

  @Of(() => String)
  taraPhonePrefix = '';

  @Of(() => String)
  taraFlag = '';

  @Of(() => String)
  countScoli?: string = '';

  @Of(() => String)
  liceuOrgType?: string;

  static of = Entity.of<Country>();
}
