import { Entity, Of } from 'entity-of';

@Entity
export class UserPreferences {
  @Of(() => String)
  prefNotifNote: string = '';

  @Of(() => String)
  prefNotifAbsente: string = '';

  @Of(() => String)
  prefNotifObservatii: string = '';

  @Of(() => String)
  prefNotifScutiri: string = '';

  @Of(() => String)
  prefNotifMesaje: string = '';

  @Of(() => String)
  prefNotifWallPoze: string = '';

  @Of(() => String)
  prefNotifRezumat: string = '';

  @Of(() => String)
  prefNotifPublicitate: string = '';

  @Of(() => String)
  prefNotifNewsletter: string = '';

  @Of(() => String)
  prefIstoricActivitate: string = '';

  @Of(() => String)
  prefIstoricNote: string = '';

  @Of(() => String)
  prefIstoricAbsente: string = '';

  @Of(() => String)
  prefNoutatiEducatie: string = '';

  @Of(() => String)
  prefNoutatiConcursuri: string = '';

  @Of(() => String)
  prefNoutatiExtrascolare: string = '';

  @Of(() => String)
  prefNoutatiPublicitate: string = '';

  @Of(() => String)
  prefNotifConduita: string = '';

  @Of(() => String)
  prefNotifHomework: string = '';

  @Of(() => String)
  prefAvatar: string = '';

  @Of(() => String)
  prefSemnaturaMesaj: string = '';

  @Of(() => String)
  prefLangID: string = '';

  @Of(() => String)
  prefElevMediiVizibile: string = '';

  @Of(() => String)
  prefElevClasamentVizibil: string = '';

  @Of(() => String)
  prefElevDataNasteriiVizibila: string = '';

  @Of(() => String)
  prefNrRaportSMS: string = '';

  @Of(() => String)
  prefElevNotifSms: string = '';

  @Of(() => String)
  prefElevClasamentClasa: string = '';

  @Of(() => String)
  prefElevClasamentScoala: string = '';

  @Of(() => String)
  prefElevClasamentAds: string = '';

  static of = Entity.of<UserPreferences>();
}
