import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class TransferInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.transfer.transfer_date.required' })
  transferDate: string = '';

  @IsNotEmpty({ message: 'errors.accommodations.transfer.dorm.required' })
  @Of(() => String)
  dormId: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.transfer.room.required' })
  toRoomId: string = '';

  static of = Entity.of<TransferInput>();
}

@Entity
export class TransferFormInput {
  @Of(() => String)
  transferDate: string = '';

  @Of(() => String)
  toRoomId: string = '';

  static of = Entity.of<TransferFormInput>();
}
