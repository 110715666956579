import { Entity, Of } from 'entity-of';

@Entity
export class CalloutCreateInput {
  @Of(() => String)
  calloutID: string = '';

  @Of(() => String)
  expireAt?: string;

  static of = Entity.of<CalloutCreateInput>();
}
