import { CSSProperties } from 'react';

import { getOrgTypeTheme } from '@ui2/utils';

const baseColor = getOrgTypeTheme('blue');

export const hoverState: CSSProperties = {
  borderColor: `${baseColor}.300`,
  boxShadow: 'inputFocus',
  cursor: 'pointer',
  transition: 'all .2s ease-in-out',
};
