import { Entity, Of } from 'entity-of';

@Entity
export class AccessCodeData {
  @Of(() => String)
  uaID: string = '';

  @Of(() => String)
  uaTip: string = '';

  @Of(() => String)
  firstName: string = '';

  @Of(() => String)
  lastName: string = '';

  @Of(() => AccessCodeStudentData)
  elev: AccessCodeStudentData = AccessCodeStudentData.of({});

  static of = Entity.of<AccessCodeData>();
}

@Entity
export class AccessCodeStudentData {
  @Of(() => String)
  elevNume: string = '';

  @Of(() => String)
  elevPrenume: string = '';

  @Of(() => String)
  clNume: string = '';

  static of = Entity.of<AccessCodeStudentData>();
}
