import { Entity, Of } from 'entity-of';

@Entity
export class SchoolYearVacation {
  @Of(() => String)
  ascID = '';

  @Of(() => String, { optional: true })
  v2pStartDate?: string;

  @Of(() => String, { optional: true })
  v2pEndDate?: string;

  @Of(() => String, { optional: true, nullable: true })
  _liceuID?: string | null = '';

  static of = Entity.of<SchoolYearVacation>();
}
