import { Entity, Of } from 'entity-of';

@Entity
export class TempFileUploadInput {
  @Of(() => String, { nullable: true })
  fsFile: File | null = null;

  @Of(() => String)
  fsTip: number = 2;

  @Of(() => String, { optional: true })
  fsNume?: string = '';

  static of = Entity.of<TempFileUploadInput>();
}
