import { ComponentStyleConfig } from '@chakra-ui/react';

export const AvatarTheme: ComponentStyleConfig = {
  defaultProps: {
    size: 'md',
  },
  sizes: {
    xs: {
      width: '2rem',
      height: '2rem',
    },
    sm: {
      width: '2.5rem',
      height: '2.5rem',
    },
    md: {
      width: '3rem',
      height: '3rem',
    },
    lg: {
      width: '4rem',
      height: '4rem',
    },
    xl: {
      width: '6rem',
      height: '6rem',
    },
  },
};
