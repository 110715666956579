export const colorsArr = [
  'gray',
  'blue',
  'red',
  'orange',
  'green',
  'teal',
  'cyan',
  'purple',
  'pink',
  'yellow',
  'moss',
  'lightGreen',
  'neueGreen',
  'neueBlue',
  'darkBlue',
  'indigo',
  'violet',
  'fuchsia',
  'rose',
  'darkOrange',
  'neueOrange',
  'brown',
  'lightYellow',
];

export const colors = {
  black: '#000000',
  white: '#FFFFFF',
  background: '#F9F9FB',

  gray: {
    '50': '#F2F4F7',
    '100': '#EAECF0',
    '200': '#D0D5DD',
    '300': '#98A2B3',
    '400': '#667085',
    '500': '#475467',
    '600': '#344054',
    '700': '#1D2939',
    '800': '#101828',
    '900': '#101828',
  },
  blue: {
    '50': '#F0F9FF',
    '100': '#E0F2FE',
    '200': '#B9E6FE',
    '300': '#7CD4FD',
    '400': '#36BFFA',
    '500': '#0BA5EC',
    '600': '#0086C9',
    '700': '#026AA2',
    '800': '#065986',
    '900': '#0B4A6F',
  },
  red: {
    '50': '#FEF3F2',
    '100': '#FEE4E2',
    '200': '#FECDCA',
    '300': '#FDA29B',
    '400': '#F97066',
    '500': '#F04438',
    '600': '#D92D20',
    '700': '#B42318',
    '800': '#912018',
    '900': '#7A271A',
  },
  orange: {
    '50': '#FFFAEB',
    '100': '#FEF0C7',
    '200': '#FEDF89',
    '300': '#FEC84B',
    '400': '#FDB022',
    '500': '#F79009',
    '600': '#DC6803',
    '700': '#B54708',
    '800': '#93370D',
    '900': '#792E0D',
  },
  green: {
    '50': '#ECFDF3',
    '100': '#D1FADF',
    '200': '#A6F4C5',
    '300': '#6CE9A6',
    '400': '#32D583',
    '500': '#12B76A',
    '600': '#039855',
    '700': '#027A48',
    '800': '#05603A',
    '900': '#054F31',
  },
  teal: {
    '50': '#F0FDF9',
    '100': '#CCFBEF',
    '200': '#99F6E0',
    '300': '#5FE9D0',
    '400': '#2ED3B7',
    '500': '#15B79E',
    '600': '#0E9384',
    '700': '#107569',
    '800': '#125D56',
    '900': '#134E48',
  },
  cyan: {
    '50': '#F0FDF9',
    '100': '#CFF9FE',
    '200': '#A5F0FC',
    '300': '#67E3F9',
    '400': '#22CCEE',
    '500': '#06AED4',
    '600': '#088AB2',
    '700': '#0E6F90',
    '800': '#155B75',
    '900': '#164C63',
  },
  purple: {
    '50': '#F4F3FF',
    '100': '#EBE9FE',
    '200': '#D9D6FE',
    '300': '#BDB4FE',
    '400': '#9B8AFB',
    '500': '#7A5AF8',
    '600': '#6938EF',
    '700': '#5925DC',
    '800': '#4A1FB8',
    '900': '#3E1C96',
  },
  pink: {
    '50': '#FDF2FA',
    '100': '#FCE7F6',
    '200': '#FCCEEE',
    '300': '#FAA7E0',
    '400': '#F670C7',
    '500': '#EE46BC',
    '600': '#DD2590',
    '700': '#C11574',
    '800': '#9E165F',
    '900': '#851651',
  },
  yellow: {
    '50': '#FEFBE8',
    '100': '#FEF7C3',
    '200': '#FEEE95',
    '300': '#FDE272',
    '400': '#FAC515',
    '500': '#EAAA08',
    '600': '#CA8504',
    '700': '#A15C07',
    '800': '#85490E',
    '900': '#713B12',
  },
  moss: {
    '50': '#FAFDF7',
    '100': '#E6F4D7',
    '200': '#CEEAB0',
    '300': '#ACDC79',
    '400': '#86CB3C',
    '500': '#669F2A',
    '600': '#4F7A21',
    '700': '#3F621A',
    '800': '#335015',
    '900': '#2B4212',
  },
  lightGreen: {
    '50': '#F3FEE7',
    '100': '#E3FBCC',
    '200': '#D0F8AB',
    '300': '#A6EF67',
    '400': '#85E13A',
    '500': '#66C61C',
    '600': '#4BA30D',
    '700': '#3B7C0F',
    '800': '#326212',
    '900': '#2B5314',
  },
  neueGreen: {
    '50': '#EDFCF2',
    '100': '#D3F8DF',
    '200': '#AAF0C4',
    '300': '#73E2A3',
    '400': '#3CCB7F',
    '500': '#16B364',
    '600': '#099250',
    '700': '#087443',
    '800': '#095C37',
    '900': '#084C2E',
  },
  neueBlue: {
    '50': '#EFF8FF',
    '100': '#D1E9FF',
    '200': '#B2DDFF',
    '300': '#84CAFF',
    '400': '#53B1FD',
    '500': '#2E90FA',
    '600': '#1570EF',
    '700': '#175CD3',
    '800': '#1849A9',
    '900': '#194185',
  },
  darkBlue: {
    '50': '#EFF4FF',
    '100': '#D1E0FF',
    '200': '#B2CCFF',
    '300': '#84ADFF',
    '400': '#528BFF',
    '500': '#2970FF',
    '600': '#155EEF',
    '700': '#004EEB',
    '800': '#0040C1',
    '900': '#00359E',
  },
  indigo: {
    '50': '#EEF4FF',
    '100': '#E0EAFF',
    '200': '#C7D7FE',
    '300': '#A4BCFD',
    '400': '#8098F9',
    '500': '#6172F3',
    '600': '#444CE7',
    '700': '#3538CD',
    '800': '#2D31A6',
    '900': '#2D3282',
  },
  violet: {
    '50': '#F5F3FF',
    '100': '#ECE9FE',
    '200': '#DDD6FE',
    '300': '#C3B5FD',
    '400': '#A48AFB',
    '500': '#875BF7',
    '600': '#7839EE',
    '700': '#6927DA',
    '800': '#5720B7',
    '900': '#491C96',
  },
  fuchsia: {
    '50': '#FDF4FF',
    '100': '#FBE8FF',
    '200': '#F6D0FE',
    '300': '#EEAAFD',
    '400': '#E478FA',
    '500': '#D444F1',
    '600': '#BA24D5',
    '700': '#9F1AB1',
    '800': '#821890',
    '900': '#6F1877',
  },
  rose: {
    '50': '#FFF1F3',
    '100': '#FFE4E8',
    '200': '#FECDD6',
    '300': '#FEA3B4',
    '400': '#FD6F8E',
    '500': '#F63D68',
    '600': '#E31B54',
    '700': '#C01048',
    '800': '#A11043',
    '900': '#89123E',
  },
  darkOrange: {
    '50': '#FFF4ED',
    '100': '#FFE6D5',
    '200': '#FFD6AE',
    '300': '#FF9C66',
    '400': '#FF692E',
    '500': '#FF4405',
    '600': '#E62E05',
    '700': '#BC1B06',
    '800': '#97180C',
    '900': '#771A0D',
  },
  neueOrange: {
    '50': '#FEF6EE',
    '100': '#FDEAD7',
    '200': '#F9DBAF',
    '300': '#F7B27A',
    '400': '#F38744',
    '500': '#EF6820',
    '600': '#E04F16',
    '700': '#B93815',
    '800': '#932F19',
    '900': '#772917',
  },
  brown: {
    '50': '#F3F2F1',
    '100': '#E7E5E4',
    '200': '#D8D6D4',
    '300': '#BBB7B4',
    '400': '#A3988F',
    '500': '#756961',
    '600': '#544B45',
    '700': '#3D3733',
    '800': '#2D2825',
    '900': '#1C1917',
  },
  lightYellow: {
    '50': '#FDFEE8',
    '100': '#FBFEC3',
    '200': '#FCFE95',
    '300': '#FDF868',
    '400': '#FAEF15',
    '500': '#F2DE00',
    '600': '#CEB900',
    '700': '#A18207',
    '800': '#85670E',
    '900': '#715312',
  },
};

export const colorPickerColors = [
  colors.rose['200'],
  colors.neueOrange['200'],
  colors.yellow['200'],
  colors.lightYellow['200'],
  colors.moss['200'],
  colors.neueGreen['200'],
  colors.cyan['200'],
  colors.neueBlue['200'],
  colors.purple['200'],
  colors.fuchsia['200'],
  colors.gray['200'],
  colors.brown['200'],
  colors.rose['400'],
  colors.neueOrange['400'],
  colors.yellow['400'],
  colors.lightYellow['400'],
  colors.moss['400'],
  colors.neueGreen['400'],
  colors.cyan['400'],
  colors.neueBlue['400'],
  colors.purple['400'],
  colors.fuchsia['400'],
  colors.gray['400'],
  colors.brown['400'],
  colors.rose['600'],
  colors.neueOrange['600'],
  colors.yellow['600'],
  colors.lightYellow['600'],
  colors.moss['600'],
  colors.neueGreen['600'],
  colors.cyan['600'],
  colors.neueBlue['600'],
  colors.purple['600'],
  colors.fuchsia['600'],
  colors.gray['600'],
  colors.brown['600'],
];
