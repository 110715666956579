import React from 'react';
import { useTranslation } from 'react-i18next';

import { Badge, Button, ButtonProps, Flex, HStack, Text } from '../../../atoms';

export const ActionsContainer = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(
  ({ children }, ref) => {
    return (
      <Flex
        p="4"
        bg="white"
        borderRadius="md"
        w="full"
        justify="space-between"
        align="center"
        ref={ref}
      >
        {children}
      </Flex>
    );
  }
);

export const SelectedCount = ({ count }: { count: number }) => {
  const { t } = useTranslation('shared');
  return (
    <HStack spacing="2" align="center">
      <Badge>{count}</Badge>
      <Text>{t('general.selected')}</Text>
    </HStack>
  );
};

export const Actions = ({ children }: { children: React.ReactNode }) => (
  <HStack spacing="2">{children}</HStack>
);

export const Action = (props: ButtonProps) => {
  return <Button {...props} size="sm" />;
};
