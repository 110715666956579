import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ORG_UNIVERSITY, TYPE_CANDIDATE } from '@constants';
import Api2 from '@lib/api';
import { useStorageSession } from '@lib/utils';
import { AdservioIcons, HStack, Menu } from '@ui2/components';
import Cookies from 'js-cookie';
import { sessionInit, sessionUnset } from 'Redux/SessionActions';

import { AcademicYearMenuSelect } from '../AcademicYearMenuSelect';
import { GenericItems } from '../GenericItems';
import { HeadmasterTeacher } from '../HeadmasterTeacher';
import { OrganizationMenuSelect } from '../OrganizationMenuSelect';
import { UnifiedAccounts } from '../UnifiedAccounts';

import { MenuItemDivider, MenuListWrapper, UserMenuButton, UserMenuItem } from './UserMenu.layout';

interface UserMenuProps {
  isPHP?: boolean;
  isTeacherView: boolean;
}

export const UserMenu = ({ isPHP, isTeacherView }: UserMenuProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['Site', 'common']);

  const { profile, accounts, sessionHelpers, school, orgType } = useStorageSession();

  const isAdmin = !!Cookies.get('ADST_ADM_REF');

  const logout = () => {
    Promise.all([
      Api2.del('/auth'),
      Api2.get(`${window.location.protocol}//${window.location.hostname}/logout`),
    ]).finally(() => {
      Cookies.remove('MPR');
      Cookies.remove('ADST');
      Cookies.remove('ADST_ADM');
      Cookies.remove('ADST_ADM_REF');
      localStorage.removeItem('features');
      localStorage.removeItem('status_banner_hidden');
      localStorage.removeItem('stayLoggedIn');
      localStorage.removeItem('academicYearId');
      localStorage.removeItem('facultyId');
      sessionStorage.removeItem('classesStorage');
      dispatch(sessionUnset());

      window.location.href = `/${i18n.language}`;
    });
  };

  const sessionSwitchAdmin = () => {
    dispatch(sessionUnset());

    const adminRefId = Cookies.get('ADST_ADM_REF');

    Api2.post('/auth/switch_admin', {}).then(() => {
      dispatch(sessionInit({ adminRefId }));
    });
  };

  const hasMultipleRoles =
    (sessionHelpers.isDirector || sessionHelpers.isSecretary) && sessionHelpers.isTeacher;

  return (
    <Menu colorScheme="gray" placement="bottom-end" autoSelect={false}>
      {school?.liceuOrgType === ORG_UNIVERSITY && !sessionHelpers.isAdmin && (
        <HStack>
          <AcademicYearMenuSelect />
          <OrganizationMenuSelect />
        </HStack>
      )}

      <UserMenuButton />

      <MenuListWrapper>
        {isAdmin && (
          <>
            <UserMenuItem icon={<AdservioIcons.Admin />} onClick={() => sessionSwitchAdmin()}>
              {t('header.administrator')}
            </UserMenuItem>

            <MenuItemDivider />
          </>
        )}

        {hasMultipleRoles && orgType !== ORG_UNIVERSITY && (
          <HeadmasterTeacher isTeacherView={isTeacherView} />
        )}

        {accounts?.length > 1 && <UnifiedAccounts />}

        {profile?.uaTip !== TYPE_CANDIDATE && <GenericItems isPHP={isPHP} />}

        <UserMenuItem icon={<AdservioIcons.FiLogOut />} color="red.500" onClick={logout}>
          {t('header.logout')}
        </UserMenuItem>
      </MenuListWrapper>
    </Menu>
  );
};
