import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useKey } from 'rooks';

import { useUrlState } from '../../../../../Libs/hooks';
import { AdservioIcons, HStack, IconButton, NumberInput, Text, VStack } from '../../../atoms';

interface MobileTablePaginationProps {
  totalItemsCount: number;
}

export const MobileTablePagination = React.forwardRef<HTMLDivElement, MobileTablePaginationProps>(
  ({ totalItemsCount }, ref) => {
    const { pageIndex, pageSize, setPage } = useUrlState();

    const pageCount = Math.ceil(Number(totalItemsCount || 0) / pageSize);

    const handlePageChange = (pageIndex: number) => {
      if (pageIndex > pageCount) {
        setPage(pageCount);
        return;
      }
      if (pageIndex < 0) {
        setPage(0);
        return;
      }
      setPage(pageIndex);
    };

    return (
      <>
        {totalItemsCount > pageSize && (
          <VStack ref={ref} data-testid="mobile-table-pagination">
            <MobilePageNavigation
              pageCount={pageCount}
              pageIndex={pageIndex}
              onPageChange={handlePageChange}
            />
          </VStack>
        )}
      </>
    );
  }
);

interface PageNavigationProps {
  pageIndex: number;
  pageCount: number;
  onPageChange: (pageIndex: number) => void;
}

export const MobilePageNavigation = ({
  pageIndex,
  onPageChange,
  pageCount,
}: PageNavigationProps) => {
  const { t } = useTranslation('shared');

  return (
    <VStack>
      <HStack alignItems="center">
        <IconButton
          colorScheme="gray"
          variant="outline"
          data-testid="mobile-go-to-first-page"
          disabled={pageIndex === 0}
          isDisabled={pageIndex === 0}
          onClick={() => onPageChange(0)}
          aria-label="go to first page"
          icon={<AdservioIcons.FiChevronsLeft />}
        />

        <IconButton
          colorScheme="gray"
          variant="outline"
          data-testid="mobile-go-to-previous-page"
          disabled={pageIndex === 0}
          isDisabled={pageIndex === 0}
          aria-label="go to previous page"
          onClick={() => onPageChange(pageIndex - 1)}
          icon={<AdservioIcons.FiChevronLeft />}
        />

        <MobilePageJump pageCount={pageCount} pageIndex={pageIndex} onPageChange={onPageChange} />
        <IconButton
          colorScheme="gray"
          variant="outline"
          data-testid="mobile-go-to-next-page"
          aria-label="go to next page"
          disabled={pageIndex === pageCount - 1}
          isDisabled={pageIndex === pageCount - 1}
          onClick={() => onPageChange(pageIndex + 1)}
          icon={<AdservioIcons.FiChevronRight />}
        />

        <IconButton
          colorScheme="gray"
          variant="outline"
          data-testid="mobile-go-to-last-page"
          aria-label="go to last page"
          disabled={pageIndex === pageCount - 1}
          isDisabled={pageIndex === pageCount - 1}
          onClick={() => onPageChange(pageCount - 1)}
          icon={<AdservioIcons.FiChevronsRight />}
        />
      </HStack>
      <Text color="gray.400" data-testid="mobile-page-overview">
        {pageIndex + 1} {t('general.of')} {pageCount}
      </Text>
    </VStack>
  );
};

interface PageJumpProps {
  pageCount: number;
  pageIndex: number;
  onPageChange: (pageIndex: number) => void;
}

export const MobilePageJump = ({ onPageChange, pageCount, pageIndex }: PageJumpProps) => {
  const [val, setVal] = useState(pageIndex + 1 || 1);

  const handlePageChange = () => {
    let next = val - 1;

    if (val === 0 || next <= 0 || next >= pageCount) {
      next = 0;
      setVal(1);
    }

    onPageChange(next);
  };

  const handleChange = (value: number | string) => {
    if (value === null || Number.isNaN(value)) {
      setVal(0);

      return;
    }
    setVal(Number(value));
  };

  useKey('Enter', handlePageChange);

  useEffect(() => {
    setVal(pageIndex + 1);
  }, [pageIndex]);

  return (
    <NumberInput
      min={1}
      maxW="4.5rem"
      textAlign="center"
      hideSteppers
      max={pageCount}
      id="page-jump-input"
      data-testid="mobile-page-jump"
      onChange={handleChange}
      onBlur={handlePageChange}
      value={val}
      isDisabled={pageCount === 0}
    />
  );
};
