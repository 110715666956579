import { forwardRef, useState } from 'react';

import { Person } from '@api/core/entities';
import { usePersonsSearchQuery } from '@modules/billing/domains/shared/cache';
import debounce from 'lodash/debounce';

import {
  SearchPersonsContainer,
  SearchResultsPlaceholder,
  SearchResultsSection,
} from './SearchPersons.layout';
import { SearchPersonsInput } from './SearchPersonsInput';
import { SearchResults } from './SearchResults';

interface SearchPersonsProps {
  onChange?: (value: Person) => void;
  placeholder: string;
}

export const SearchPersons = forwardRef<HTMLInputElement, SearchPersonsProps>(
  ({ onChange, placeholder }, ref) => {
    const [searchTerm, setSearchTerm] = useState('');

    const persons = usePersonsSearchQuery(
      { searchTerm, cuAbsolventi: true },
      {
        enabled: !!searchTerm,
      }
    );

    const handleSearch = debounce((value: string) => {
      if (value.length > 0 && value.length < 3) return;
      setSearchTerm(value);
    }, 250);

    return (
      <SearchPersonsContainer>
        <SearchPersonsInput ref={ref} onChange={handleSearch} isLoading={persons.isLoading} />

        {!persons.data && !searchTerm ? (
          <SearchResultsPlaceholder placeholder={placeholder} />
        ) : (
          <SearchResultsSection>
            <SearchResults
              data={persons.data}
              onAccountSelect={onChange}
              isLoading={persons.isLoading}
            />
          </SearchResultsSection>
        )}
      </SearchPersonsContainer>
    );
  }
);
