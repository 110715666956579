import { Entity, Of } from 'entity-of';

@Entity
export class GetFileInput {
  @Of(() => String)
  id: string = '';

  @Of(() => String, { optional: true })
  tenantId?: string;

  static of = Entity.of<GetFileInput>();
}
