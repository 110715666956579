import { Entity, Of } from 'entity-of';

@Entity
export class ProgramStudyProgramInput {
  @Of(() => Number)
  domainId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  static of = Entity.of<ProgramStudyProgramInput>();
}
