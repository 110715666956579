import React, { useEffect, useState } from 'react';

import { isFeatureEnabled, useHasAccess } from '@lib/utils';
import { LinkButtonItem, LinkButtonsGroup } from '@modules/shared';
import { Box, Divider } from '@ui2/components';
import { useBreakpointValue } from '@ui2/hooks';
import Cookies from 'js-cookie';

import {
  GroupTitle,
  LinkButton,
  LogoUrl,
  SidenavButtonsWrapper,
  SidenavContainer,
  SidenavLogo,
  SidenavSubmenuWrapper,
  SidenavToggleButton,
  SidenavWrapper,
} from './Sidenav.layout';

interface SidebarProps {
  groups: LinkButtonsGroup[];
  logoUrl: LogoUrl;
  isPHP?: boolean;
}

export const Sidenav = ({ groups, logoUrl, isPHP }: SidebarProps) => {
  const [isForcedOpen, setIsForcedOpen] = useState(!!Cookies.get('sidenav-force-open'));
  const [isHoverOpen, setIsHoverOpen] = useState(false);
  const [submenuItem, setSubmenuItem] = useState<LinkButtonItem | null>(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const isMenuOpen = isForcedOpen || isHoverOpen;

  window.addEventListener('mobileNavigationToggle', () => {
    handleToggle();
  });

  const handleToggle = () => {
    if (isForcedOpen) {
      setSubmenuItem(null);
    }

    if (isHoverOpen) {
      setIsForcedOpen(false);
      setIsHoverOpen(false);
      setSubmenuItem(null);
    } else {
      if (isForcedOpen) {
        Cookies.remove('sidenav-force-open');
      } else if (!isMobile) {
        Cookies.set('sidenav-force-open', 'true');
      }

      setIsForcedOpen(!isForcedOpen);
    }

    const event = new CustomEvent('navigationToggle', { detail: !isForcedOpen });
    window.dispatchEvent(event);
  };

  const handleNavigate = (isSubmenuItem?: boolean) => {
    if (isSubmenuItem && !isForcedOpen) {
      return;
    }

    if (!isHoverOpen) {
      setIsHoverOpen(false);
    }

    setSubmenuItem(null);

    if (isMobile) {
      setIsForcedOpen(false);
      Cookies.remove('sidenav-force-open');
      const event = new CustomEvent('navigationToggle', { detail: false });
      window.dispatchEvent(event);
    }
  };

  const handleSubmenuOpen = (item: LinkButtonItem) => {
    if (item.label === submenuItem?.label) {
      setSubmenuItem(null);
    } else {
      setSubmenuItem(item);
    }
  };

  const handleSubmenuClose = () => {
    setSubmenuItem(null);

    if (!isMobile) {
      setIsHoverOpen(false);
    }
  };

  const handleMouseEnter = () => {
    if (!isForcedOpen) {
      setIsHoverOpen(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isForcedOpen) {
      setIsHoverOpen(false);
      setSubmenuItem(null);
    }
  };

  const checkIfGroupHasVisibleItems = (group: LinkButtonsGroup) => {
    return group.items.some((item) => {
      const hasAccess = useHasAccess(item.accessRules);
      const featureEnabled = item.featureFlag ? isFeatureEnabled(item.featureFlag) : true;

      return hasAccess && featureEnabled;
    });
  };

  useEffect(() => {
    const event = new CustomEvent('navigationLoaded');
    window.dispatchEvent(event);
  }, []);

  return (
    <SidenavContainer
      isOpen={isMenuOpen}
      isMobile={isMobile}
      onMouseLeave={handleMouseLeave}
      onToggle={handleToggle}
    >
      <SidenavSubmenuWrapper
        isPHP={isPHP}
        isOpen={isMenuOpen}
        isMobile={isMobile}
        item={submenuItem}
        onBack={handleSubmenuClose}
        onClose={handleToggle}
        onNavigate={() => handleNavigate(true)}
      />

      <SidenavWrapper isHoverOpen={isHoverOpen} isSubmenuOpen={!!submenuItem}>
        {((isMobile && submenuItem === null) || !isMobile) && (
          <SidenavToggleButton
            isOpen={isMenuOpen}
            isHoverOpen={isHoverOpen}
            onToggle={handleToggle}
          />
        )}

        <SidenavLogo logoUrl={logoUrl} isOpen={isMenuOpen} />

        <SidenavButtonsWrapper onMouseEnter={handleMouseEnter}>
          {groups.map((group, index) => {
            const hasItems = checkIfGroupHasVisibleItems(group);

            return (
              <React.Fragment key={index}>
                {group.title && hasItems && (
                  <GroupTitle isOpen={isMenuOpen} text={group.title} mt={!index ? '0' : '4'} />
                )}

                {group.emptyTitle && hasItems && <Box mt="4" />}

                {group.items.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {item.hasTopDivider && <Divider color="gray.200" />}
                      <LinkButton
                        isPHP={isPHP}
                        isOpen={isMenuOpen}
                        item={item}
                        onSubmenuOpen={item.subitems ? handleSubmenuOpen : undefined}
                        onNavigate={handleNavigate}
                      />
                      {item.hasBottomDivider && <Divider color="gray.200" />}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </SidenavButtonsWrapper>
      </SidenavWrapper>
    </SidenavContainer>
  );
};
