import { Entity, Of } from 'entity-of';

@Entity
export class UserAuthOutput {
  @Of(() => String)
  uaID: string = '';

  @Of(() => String)
  atToken: string = '';

  @Of(() => String)
  atValidUntil: string = '';

  @Of(() => String)
  tfaToken: string = '';

  static of = Entity.of<UserAuthOutput>();
}
