import { Address, Label } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class School {
  @Of(() => String)
  liceuID: string = '';

  @Of(() => String)
  liceuClasificare: string = '';

  @Of(() => String)
  liceuNume: string = '';

  @Of(() => String)
  liceuLogo: string = '';

  @Of(() => String)
  liceuOrgParentID: string = '';

  @Of(() => String)
  liceuOrgRootID: string = '';

  @Of(() => String)
  liceuOrgType: string = '';

  @Of(() => String)
  liceuAdresa?: Address = Address.of({});

  @Of(() => String)
  liceuOras: string = '';

  @Of(() => String)
  liceuIsActive: string = '';

  @Of(() => String)
  cityID: string = '';

  @Of(() => String)
  cityName: string = '';

  @Of(() => String)
  cityFullName: string = '';

  @Of(() => String)
  judID: string = '';

  @Of(() => String)
  judNume: string = '';

  @Of(() => String)
  taraID: string = '';

  @Of(() => String)
  taraNume: string = '';

  @Of(() => String)
  taraCod: string = '';

  @Of(() => String)
  liceuCUI: string = '';

  @Of(() => String)
  taraNumeLocalized: string = '';

  @Of(() => String)
  liceuReprezentantUaID: string = '';

  @Of(() => String)
  liceuTel: string = '';

  @Of(() => String)
  liceuIsHidden: string = '';

  @Of(() => String)
  liceuInsertDate: string = '';

  @Of(() => [Label])
  labels: Label[] = [];

  @Of(() => String)
  liceuSecretarID: string = '';

  @Of(() => String)
  liceuDirectorID: string = '';

  // Used for the Global Tree component, not from the back-end
  @Of(() => Number, { optional: true })
  parentId?: number;

  static of = Entity.of<School>();
}
