import { Entity, Of } from 'entity-of';

import { Feature } from './Feature';

@Entity
export class FeatureCategory {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  name: String = '';

  @Of(() => [Feature])
  features: Feature[] = [];

  static of = Entity.of<FeatureCategory>();
}
