import { Entity, Of } from 'entity-of';

@Entity
export class TeacherCreateInput {
  @Of(() => String)
  cadNume: string = '';

  @Of(() => String)
  cadPrenume: string = '';

  @Of(() => String)
  cadCnp: string = '';

  @Of(() => String)
  cadDataNasterii: string = '';

  @Of(() => String)
  cadSex: string = '';

  @Of(() => String)
  cadEmail: string = '';

  @Of(() => String)
  cadTelefon: string = '';

  @Of(() => [String])
  cadRol: string[] = [];

  @Of(() => [String])
  emailIntampinare: string[] = [];

  static of = Entity.of<TeacherCreateInput>();
}
