import { Entity, Of } from 'entity-of';

import { InvoiceStatus } from '../Invoice';

@Entity
export class InvoiceUpdateStatusInput {
  @Of(() => [Number])
  invoiceIds: number[] = [];

  @Of(() => String)
  status: InvoiceStatus = 'issued';

  static of = Entity.of<InvoiceUpdateStatusInput>();
}
