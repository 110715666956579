import { Entity, Of } from 'entity-of';

import { TimeUnitViewType } from './TimeUnitStatsSearchInput';

@Entity
export class TimeUnitTableFilters {
  @Of(() => [String], { optional: true })
  packagesIds?: string[];

  @Of(() => String, { optional: true })
  startDate?: string;

  @Of(() => String, { optional: true })
  endDate?: string;

  @Of(() => String, { optional: true })
  groupBy?: TimeUnitViewType;

  static of = Entity.of<TimeUnitTableFilters>();
}
