import React, { forwardRef, PropsWithChildren } from 'react';

import { Card, HStack } from '@ui2/components';

interface PersonCardContainerProps {
  onClick?: () => void;
  testId?: string;
  ref: React.Ref<HTMLDivElement>;
}

export const PersonCardContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<PersonCardContainerProps>
>(({ children, onClick, testId }, ref) => {
  return (
    <Card
      w="full"
      justify="space-between"
      ref={ref}
      as={HStack}
      cursor="pointer"
      onClick={onClick}
      boxShadow="xs"
      borderColor="gray.100"
      data-testid={testId}
    >
      {children}
    </Card>
  );
});
