import { Faculty } from '@modules/shared';
import { Entity, Of } from 'entity-of';

import { StudentType } from '../Student';

@Entity
export class StudentStudy {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  studentId: number = 0;

  @Of(() => Number)
  faculty_id: number = 0;

  @Of(() => Faculty)
  faculty: Faculty = Faculty.of({});

  @Of(() => Number)
  registrationNumber: number = 0;

  @Of(() => Number)
  uniqueRegistrationNumber: number = 0;

  @Of(() => Number)
  studentTypeId: number = 0;

  @Of(() => Number)
  year: number = 0;

  @Of(() => String)
  profile: string = '';

  @Of(() => String)
  specialisation: string = '';

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  educationForm: string = '';

  @Of(() => String)
  series: string = '';

  @Of(() => String)
  group: string = '';

  @Of(() => Boolean)
  isBudget: boolean = false;

  @Of(() => String)
  startYear: string = '';

  @Of(() => String)
  endYear: string = '';

  @Of(() => StudentType)
  studentType: StudentType = StudentType.of({});

  static of = Entity.of<StudentStudy>();
}
