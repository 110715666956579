import { AccountBillingProfile } from '@api/billing/entities';
import { County } from '@api/core/entities';
import { countries } from '@modules/shared/utils';

export const formattedAccountBillingProfileAddress = (
  profile: AccountBillingProfile,
  lang?: string,
  counties?: County[]
) => {
  const profileCountry = profile.country.toLocaleLowerCase();
  const selectedCountry = lang ? countries.find((c) => c.alpha2 === profileCountry)?.[lang] : '';
  const county = counties?.find((c) => c.judCod === profile.county)?.judNume || profile.county;

  const name = profile.isJuridic ? profile.companyName : `${profile.firstName} ${profile.lastName}`;
  const fiscalInfo = [profile.fiscalCode, profile.nrJ].filter((f) => Boolean(f)).join(', ');
  const street = [profile.street, profile.zipCode].filter((f) => Boolean(f)).join(', ');
  const address = [selectedCountry || profile.country, county, profile.city]
    .filter((f) => Boolean(f))
    .join(', ');
  const bankInfo = [profile.bankName, profile.iban].filter((f) => Boolean(f)).join(', ');

  return {
    name,
    fiscalInfo,
    street,
    address,
    bankInfo,
  };
};
