import { useTranslation } from 'react-i18next';

import {
  CONFIG_FLAGS,
  PERM_BILLING_CLIENT,
  PERM_LIBRARY_MANAGER,
  PERM_LIBRARY_VIEW,
  PERM_SCHOLARSHIP_CLIENT,
} from '@constants';
import { LinkButtonItem, LinkButtonsGroup } from '@modules/shared';

export const useUniversitiesStudentLinkGroups = () => {
  const { t } = useTranslation(['Site', 'common']);

  const groups: LinkButtonsGroup[] = [
    {
      items: [
        LinkButtonItem.of({
          icon: 'FiHome',
          label: t('buttons_mm.dashboard'),
          url: 'dashboard',
        }),

        LinkButtonItem.of({
          icon: 'ReportCard',
          label: t('buttons_mm.educational_plan'),
          url: 'universities/educational-plan',
          matchingUrls: ['educational-plan'],
        }),

        LinkButtonItem.of({
          icon: 'Gradebook',
          label: t('buttons_mm.catalog'),
          url: 'universities/catalog',
          matchingUrls: [
            'universities/catalog',
            'universities/catalog/student',
            'universities/catalog/grades',
            'universities/catalog/academic',
          ],
        }),

        LinkButtonItem.of({
          icon: 'FiFile',
          label: t('buttons_mm.fisiere'),
          url: `files`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.FILES_ACTIVE],
          },
        }),

        LinkButtonItem.of({
          icon: 'Library',
          matchingUrls: ['universities/library/manage', 'universities/library/book'],
          label: t('buttons_mm.biblioteca'),
          url: 'universities/library',
          accessRules: {
            permissions: [PERM_LIBRARY_MANAGER, PERM_LIBRARY_VIEW],
            configFlags: [CONFIG_FLAGS.LIBRARY_ACTIVE],
          },
        }),

        LinkButtonItem.of({
          icon: 'FiGlobe',
          label: t('buttons_mm.erasmus_disciplines'),
          url: 'universities/erasmus-disciplines',
        }),
      ],
    },
    {
      emptyTitle: true,
      items: [
        LinkButtonItem.of({
          icon: 'Scholarship',
          label: t('buttons_mm.scholarships.label'),
          url: 'universities/scholarships/student',
          accessRules: {
            permissions: [PERM_SCHOLARSHIP_CLIENT],
          },
          matchingUrls: ['universities/scholarships/student'],
        }),

        LinkButtonItem.of({
          icon: 'Building',
          label: t('buttons_mm.accommodations'),
          url: 'universities/accommodations/student',
          accessRules: {
            configFlags: [CONFIG_FLAGS.ACCOMMODATION_ACTIVE],
          },
        }),

        LinkButtonItem.of({
          icon: 'Coins',
          label: t('buttons_mm.financials'),
          url: 'billing/account/invoices',
          matchingUrls: [
            'billing/account/invoices',
            'billing/account/payments',
            'billing/account/available-services',
            'billing/account/pending-services',
            'billing/account/profiles',
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.BILLING_ACTIVE],
            permissions: [PERM_BILLING_CLIENT],
          },
        }),
      ],
    },
  ];

  return {
    groups,
  };
};
