import { Entity, Of } from 'entity-of';

import { SummaryHistoryItem } from '../SummaryHistoryItem';

@Entity
export class SummaryHistory {
  @Of(() => SummaryHistoryItem)
  invoiced: SummaryHistoryItem = SummaryHistoryItem.of({});

  @Of(() => SummaryHistoryItem)
  charged: SummaryHistoryItem = SummaryHistoryItem.of({});

  @Of(() => SummaryHistoryItem)
  proformas: SummaryHistoryItem = SummaryHistoryItem.of({});

  static of = Entity.of<SummaryHistory>();
}
