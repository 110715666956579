// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import ReactModal from 'react-modal';

import PropTypes from 'prop-types';

import './Modal.less';

class Modal extends Component {
  constructor(props) {
    super(props);

    const { overlayClassName, onRequestOpen } = this.props;

    if (overlayClassName === 'overlay-toaster') {
      document.getElementsByTagName('html')[0].style.overflow = 'auto';
    } else {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';
    }

    this.modalRefHeight = React.createRef();
    if (typeof onRequestOpen === 'function') {
      onRequestOpen();
    }
  }

  componentDidMount() {
    document.getElementsByTagName('body')[0].addEventListener('resizeModal', this.resizeModal);
  }

  componentWillUnmount() {
    document.getElementsByTagName('body')[0].removeEventListener('resizeModal', this.resizeModal);
    document.getElementsByTagName('html')[0].style.overflow = 'auto';
  }

  resizeModal = (evHeight) => {
    const node = this.modalRefHeight.current;
    node.node.childNodes[0].childNodes[0].style.height = `${evHeight.detail.height}px`;
  };

  onAfterOpen = () => {
    const { onAfterOpen } = this.props;
    if (typeof onAfterOpen === 'function') {
      onAfterOpen();
    }
  };

  onRequestClose = () => {
    const { onRequestClose } = this.props;
    if (typeof onRequestClose === 'function') {
      onRequestClose();
    }
  };

  onAfterClose = () => {
    const { onAfterClose } = this.props;
    if (typeof onAfterClose === 'function') {
      onAfterClose();
    }
  };

  render() {
    const {
      overlay,
      color,
      position,
      width,
      height,
      children,
      className,
      isCloseable,
      isVisible,
      overlayClassName,
    } = this.props;

    return (
      <ReactModal
        {...this.props}
        ref={this.modalRefHeight}
        isOpen
        onRequestClose={isCloseable === true ? this.onRequestClose.bind(this) : null}
        onAfterClose={this.onAfterClose.bind(this)}
        onAfterOpen={this.onAfterOpen.bind(this)}
        overlayClassName={`
							${overlay ? 'with-overlay' : ''}
							${overlayClassName ? `${overlayClassName}` : ''}
							${position === undefined || position === true ? 'position-center' : `position-${position}`}
							${isVisible ? '' : 'hide-react-modal'}
						`}
        className={` comp-react-modal
						${className || ''}
						${color === undefined || color === true ? 'modal-color-black' : `modal-color-${color}`}
						${position === undefined || position === true ? 'position-center' : `position-${position}`}
					`}
        style={{
          content: {
            width: `${!width ? 'auto' : `${width}`}`,
            height: `${!height ? 'auto' : `${height}`}`,
          },
        }}
      >
        {isCloseable && (
          <button
            type="button"
            onClick={this.onRequestClose.bind(this)}
            onTouchStart={this.onRequestClose.bind(this)}
            className="close-modal _icon icon-remove-filled"
          />
        )}

        {children}
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  /* A callback function that is called when the modal close */
  onRequestClose: PropTypes.func,
  /* A callback function that is called after the modal open */
  onAfterOpen: PropTypes.func,
  onAfterClose: PropTypes.func,
  onRequestOpen: PropTypes.func,
  /* false if you don't whant it,  else className  */
  overlay: PropTypes.bool,
  /* add a classname for the overlay to be styled */
  overlayClassName: PropTypes.string,
  /* color of the left border */
  color: PropTypes.oneOf([
    'red',
    'green',
    'orange',
    'blue',
    'black',
    'purple',
    'turquoise',
    'event-style1',
    'event-style2',
    'event-style3',
    'event-style4',
    'event-style5',
  ]),
  /* position of the modal to one of the sides and animate it from that side. is true for center. */
  position: PropTypes.oneOf([
    'top-left',
    'top-right',
    'top-center',
    'bottom-left',
    'bottom-right',
    'bottom-center',
    'center-left',
    'center-right',
    'center-small',
    'center',
  ]),
  /* the width of the modal */
  width: PropTypes.string,
  /* the height of the modal */
  height: PropTypes.string,
  /* the content of the modal */
  children: PropTypes.node,
  /* extra class for the modal is used for styling purposes */
  className: PropTypes.string,
  /* if you have the x icon for closing */
  isCloseable: PropTypes.bool,
  /* flag to see if the modal is visible */
  isVisible: PropTypes.bool,
};

Modal.defaultProps = {
  onRequestClose: () => {},
  onAfterOpen: () => {},
  onAfterClose: () => {},
  onRequestOpen: () => {},
  overlay: true,
  isVisible: true,
  overlayClassName: undefined,
  width: undefined,
  height: undefined,
  children: undefined,
  color: undefined,
  className: undefined,
  position: undefined,
  isCloseable: false,
};

export default Modal;
