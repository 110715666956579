import { Entity, Of } from 'entity-of';

export enum TimeUnitViewType {
  DAILY = 'day',
  MONTHLY = 'month',
  YEARLY = 'year',
}

@Entity
export class TimeUnitStatsSearchInput {
  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  @Of(() => [String])
  packagesIds: string[] = [];

  @Of(() => String)
  groupBy: TimeUnitViewType = TimeUnitViewType.DAILY;

  static of = Entity.of<TimeUnitStatsSearchInput>();
}
