import { Entity, Of } from 'entity-of';

@Entity
export class StatementMapUpdateInput {
  @Of(() => String)
  date: string = '';

  @Of(() => String, { optional: true, nullable: true })
  type?: string;

  @Of(() => String)
  amount: string = '';

  @Of(() => String)
  currency: string = '';

  @Of(() => [String])
  details: string[] = [];

  static of = Entity.of<StatementMapUpdateInput>();
}
