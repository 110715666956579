import { Entity, Of } from 'entity-of';

@Entity
export class OtherActivitiesSignInput {
  @Of(() => String)
  name: string = '';

  @Of(() => String)
  description: string = '';

  @Of(() => String)
  startDateTime: string = '';

  @Of(() => String)
  endDateTime: string = '';

  static of = Entity.of<OtherActivitiesSignInput>();
}
