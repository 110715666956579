import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class DiplomaValidatorStep0ComplexDiploma {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.type.required' })
  diplomaType: string = '';

  static of = Entity.of<DiplomaValidatorStep0ComplexDiploma>();
}

@Entity
export class DiplomaValidatorStep0SimpleDiploma {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.type.required' })
  diplomaType: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.type.required' })
  diplomaTemplate: string = '';

  static of = Entity.of<DiplomaValidatorStep0SimpleDiploma>();
}

export type DiplomaValidatorStep0 =
  | DiplomaValidatorStep0SimpleDiploma
  | DiplomaValidatorStep0ComplexDiploma;
