import { Entity, Of } from 'entity-of';

@Entity
export class Override {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  class_id: string = '';

  @Of(() => String, { nullable: true })
  elevNume: string = '';

  @Of(() => String, { nullable: true })
  elevPrenume: string = '';

  @Of(() => String, { nullable: true })
  elev_id: string = '';

  @Of(() => String, { nullable: true })
  mat_id: string = '';

  @Of(() => String, { nullable: true })
  san_id: string = '';

  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  value: string = '';

  @Of(() => String)
  type: string = '';

  static of = Entity.of<Override>();
}
