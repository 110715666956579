import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import Cookies from 'js-cookie';

import { useLang } from './i18n';

export function useRouter<T extends object>() {
  const lang = useLang();
  const history = useHistory();
  const route = useRouteMatch();
  const location = useLocation();
  const rootWithLang = `/${lang}/`;

  const hasCookiesAdsNative = !!Cookies.get('ads_native');

  const push = (to: string, state?: any) => {
    hasCookiesAdsNative
      ? (window.location.href = `/${lang}/${to}`)
      : history.push(`/${lang}/${to}`, state);
  };

  const pathWithoutLang = history.location.pathname.split('/').slice(2);

  return {
    push,
    lang,
    history,
    location,
    state: (location.state || {}) as T,
    rootWithLang,
    asPath: route.path,
    query: route.params as Record<string, unknown>,
    queryString: new URLSearchParams(location.search),
    path: route.path,
    protocol: window.location.protocol,
    host: window.location.host,
    pathname: history.location.pathname,
    pathWithoutLang: pathWithoutLang.join('/'),
    goBack: history.goBack,
  };
}
