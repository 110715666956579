import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';
import { t } from 'i18next';

@Entity
export class RoleUserResponse {
  @Of(() => data)
  data: data[] = [];

  @Of(() => meta)
  meta: meta | undefined;

  static of = Entity.of<RoleUserResponse>();
}

@Entity
export class meta {
  @Of(() => Number)
  countTotal: number = 3;

  @Of(() => Number)
  countData: number = 3;

  @Of(() => Number)
  countOffset: number = 0;

  static of = Entity.of<meta>();
}

@Entity
export class data {
  @Of(() => Number)
  @IsNotEmpty({ message: t('errors.required') })
  roleID: number = 0;

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  roleName: string = '';

  @Of(() => Number)
  roleUaTip: number = 0;

  @Of(() => Number)
  roleIsAdministrative: number = 0;

  @Of(() => Number)
  roleIsImplicit: number = 0;

  @Of(() => Number)
  roleIsImplicitAst: number | null = null;

  @Of(() => Boolean)
  permissions: boolean = false;

  @Of(() => Number)
  usercount: number = 0;

  static of = Entity.of<data>();
}
