import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountProfilesQuery } from '@api/billing/endpoints';
import { AccountBillingProfile, AssignedService } from '@api/billing/entities';
import { useRouter } from '@lib/router';
import { useSession } from '@lib/utils';
import { AdservioIcons, Button, CustomModal, HStack, Loader, Stack } from '@ui2/components';
import { useBreakpointValue, useMediaQuery } from '@ui2/hooks';

import { SelectAccountBillingProfile } from '../SelectAccountBillingProfile';

interface ChangeBillingProfileModalProps {
  onToggle: () => void;
  isLoading?: boolean;
  isSubscription?: boolean;
  rootOrgId?: string;
  xAccountId?: string;
  currentProfile: AccountBillingProfile | undefined;
  onSubmit: (
    newProfile: AccountBillingProfile | undefined,
    targetService?: AssignedService
  ) => void;
  targetService?: AssignedService;
  returnToOrigin?: string;
  openChangeBillingProfileModal?: boolean;
}
interface RouteState {
  billingProfileId: number;
  targetService?: AssignedService;
}

export const ChangeBillingProfileModal = ({
  onToggle,
  onSubmit,
  rootOrgId,
  xAccountId,
  isLoading,
  isSubscription,
  currentProfile,
  targetService,
  returnToOrigin,
  openChangeBillingProfileModal,
}: ChangeBillingProfileModalProps) => {
  const { t } = useTranslation('shared');
  const {
    push,
    location: { state },
  } = useRouter();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const { isTablet } = useMediaQuery();

  const { studentId, legacyAccountType } = useSession();
  const [profile, setProfile] = useState<AccountBillingProfile | undefined>(currentProfile);
  const routeState: RouteState = state as RouteState;
  const isAccount = ['1', '2'].includes(legacyAccountType);

  const profilesQuery = useAccountProfilesQuery(
    { accountId: xAccountId || studentId, rootOrgId, xAccountId },
    {
      onSuccess: (data) => {
        const target = profile?.id || routeState?.billingProfileId;
        setProfile(data.find((p) => (target ? p.id === target : p.isDefault)));
      },
    }
  );

  return (
    <CustomModal
      isOpen
      size={isTablet ? '2xl' : 'full'}
      onClose={onToggle}
      scrollBehavior="inside"
      onSubmit={() => onSubmit(profile, targetService ?? routeState?.targetService)}
      renderHeader={(Title) => {
        return <Title> {t('general.change_billing_profile')}</Title>;
      }}
      renderFooter={(Cancel, Submit) => {
        return (
          <Stack w="full" spacing="4" direction={{ base: 'column', md: 'row' }}>
            {!isMobile && <Cancel isLoading={isLoading} />}

            {!isSubscription && isMobile && (
              <Button
                variant="outline"
                colorScheme="gray"
                leftIcon={<AdservioIcons.FiPlus />}
                onClick={() =>
                  push(
                    isAccount ? 'billing/account/profile/create' : 'billing/billing-profile/create',
                    {
                      fromSubscription: isSubscription,
                      returnToOrigin,
                      openChangeBillingProfileModal,
                      targetService,
                    }
                  )
                }
              >
                {t('general.new_profile')}
              </Button>
            )}

            <HStack w="full" justifyContent="flex-end">
              {!isSubscription && !isMobile && (
                <Button
                  mr="2"
                  variant="outline"
                  colorScheme="gray"
                  leftIcon={<AdservioIcons.FiPlus />}
                  onClick={() =>
                    push(
                      isAccount
                        ? 'billing/account/profile/create'
                        : 'billing/billing-profile/create',
                      {
                        fromSubscription: isSubscription,
                        returnToOrigin,
                        openChangeBillingProfileModal,
                        targetService,
                      }
                    )
                  }
                >
                  {t('general.new_profile')}
                </Button>
              )}

              {isMobile && <Cancel w="full" isLoading={isLoading} />}

              <Submit isLoading={isLoading} w={{ base: 'full', md: 'auto' }} />
            </HStack>
          </Stack>
        );
      }}
    >
      <Loader isLoading={profilesQuery.isLoading}>
        <SelectAccountBillingProfile
          value={profile?.id}
          onChange={(newProfile) =>
            setProfile(
              (profilesQuery.data as any)?.find(
                (profile: AccountBillingProfile | undefined) => profile?.id === newProfile
              )
            )
          }
          profiles={(profilesQuery?.data as any)?.filter((p) => !p.disabled) || []}
        />
      </Loader>
    </CustomModal>
  );
};
