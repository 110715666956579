import { Transform, Type } from 'class-transformer';
import {
  IsArray,
  IsNotEmpty,
  Min,
  registerDecorator,
  Validate,
  ValidateIf,
  ValidateNested,
  ValidationArguments,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';
import { Entity, Of } from 'entity-of';
import { t } from 'i18next';

import 'reflect-metadata';

import { EntityTypeRules } from './TimetableWizardRulesInput';

@ValidatorConstraint({ name: 'IsTimeRangeValidConstraint', async: false })
export class IsTimeRangeValidConstraint implements ValidatorConstraintInterface {
  validate(_value: string, args: ValidationArguments) {
    const { startHour, endHour } = args.object as UnavailabilityEntity;

    if (!startHour || !endHour) {
      return true;
    }

    const [sHour, sMinute] = startHour.split(':').map(Number);
    const [eHour, eMinute] = endHour.split(':').map(Number);

    if (sHour > eHour || (sHour === eHour && sMinute >= eMinute)) {
      return false;
    }

    return true;
  }
}

@ValidatorConstraint({ name: 'MaxValueGreaterThanMinValue', async: false })
export class MaxValueGreaterThanMinValue implements ValidatorConstraintInterface {
  validate(_value: string, args: ValidationArguments) {
    const {
      MAX_DAILY_ACTIVITIES,
      MAX_DAILY_ACTIVITIES_CHECK,
      MIN_DAILY_ACTIVITIES,
      MIN_DAILY_ACTIVITIES_CHECK,
    } = args.object as TimetableWizardForm;

    if (
      MAX_DAILY_ACTIVITIES_CHECK &&
      MIN_DAILY_ACTIVITIES_CHECK &&
      Number(MAX_DAILY_ACTIVITIES) >= 0 &&
      Number(MIN_DAILY_ACTIVITIES) >= 0 &&
      Number(MAX_DAILY_ACTIVITIES) < Number(MIN_DAILY_ACTIVITIES)
    ) {
      return false;
    }

    return true;
  }
}

@ValidatorConstraint({ name: 'MaxValueGreaterThanMinValueSpecific', async: false })
export class MaxValueGreaterThanMinValueSpecific implements ValidatorConstraintInterface {
  validate(_value: string, args: ValidationArguments) {
    const {
      general,
      MAX_DAILY_ACTIVITIES,
      MAX_DAILY_ACTIVITIES_CHECK,
      MIN_DAILY_ACTIVITIES,
      MIN_DAILY_ACTIVITIES_CHECK,
    } = args.object as EntityTypes;

    if (
      MAX_DAILY_ACTIVITIES_CHECK &&
      MIN_DAILY_ACTIVITIES_CHECK &&
      Number(MAX_DAILY_ACTIVITIES) >= 0 &&
      Number(MIN_DAILY_ACTIVITIES) >= 0 &&
      MAX_DAILY_ACTIVITIES >= MIN_DAILY_ACTIVITIES
    ) {
      return true;
    }

    if (
      MAX_DAILY_ACTIVITIES_CHECK &&
      MIN_DAILY_ACTIVITIES_CHECK &&
      Number(MAX_DAILY_ACTIVITIES) >= 0 &&
      Number(MIN_DAILY_ACTIVITIES) >= 0 &&
      MAX_DAILY_ACTIVITIES < MIN_DAILY_ACTIVITIES
    ) {
      return false;
    }

    if (general?.MAX_DAILY_ACTIVITIES_CHECK && Number(general?.MAX_DAILY_ACTIVITIES) >= 0) {
      if (
        MIN_DAILY_ACTIVITIES_CHECK &&
        Number(MIN_DAILY_ACTIVITIES) >= 0 &&
        general?.MAX_DAILY_ACTIVITIES &&
        general.MAX_DAILY_ACTIVITIES < MIN_DAILY_ACTIVITIES
      ) {
        return false;
      }
    }

    if (general?.MIN_DAILY_ACTIVITIES_CHECK && Number(general?.MIN_DAILY_ACTIVITIES) >= 0) {
      if (
        MAX_DAILY_ACTIVITIES_CHECK &&
        Number(MAX_DAILY_ACTIVITIES) >= 0 &&
        general?.MIN_DAILY_ACTIVITIES &&
        general.MIN_DAILY_ACTIVITIES > MAX_DAILY_ACTIVITIES
      ) {
        return false;
      }
    }
    return true;
  }
}

const maxFunction = (activityEntity: EntityTypes) => {
  const { entityNrOre, concurrentActivities } = activityEntity;

  const activitiesCmcNrHours: number[] = (
    concurrentActivities?.map((activity) => Number(activity.cmcNrOre)) || []
  ).concat(Number(entityNrOre));

  return Math.min(...activitiesCmcNrHours);
};

export function MaxByFunction() {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'maxByFunction',
      target: object.constructor,
      propertyName: propertyName,

      validator: {
        validate(value: any, args: ValidationArguments) {
          const maxValue = maxFunction(args.object as EntityTypes);
          if (maxValue < Number(value)) {
            return false;
          }

          return true;
        },
        defaultMessage(args: ValidationArguments) {
          const maxValue = maxFunction(args.object as EntityTypes);
          return t('shared:errors.max_dynamic', { 0: Number(maxValue) });
        },
      },
    });
  };
}

@Entity
export class TimetableWizardForm {
  @Of(() => String)
  entityType: EntityTypeRules | '' = '';

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.entityType === 'CLASS' && o.MIN_DAILY_ACTIVITIES_CHECK)
  @IsNotEmpty({ message: 'errors.required' })
  @Validate(MaxValueGreaterThanMinValue, { message: 'errors.generate_timetable.minValueBigger' })
  MIN_DAILY_ACTIVITIES: string = '';

  @Of(() => Boolean, { optional: true })
  MIN_DAILY_ACTIVITIES_CHECK: boolean = false;

  @ValidateIf(
    (o) => (o.entityType === 'CLASS' || o.entityType === 'TEACHER') && o.MAX_DAILY_ACTIVITIES_CHECK
  )
  @IsNotEmpty({ message: 'errors.required' })
  @Of(() => String, { optional: true })
  @Validate(MaxValueGreaterThanMinValue, { message: 'errors.generate_timetable.minValueBigger' })
  MAX_DAILY_ACTIVITIES: string = '';

  @Of(() => Boolean, { optional: true })
  MAX_DAILY_ACTIVITIES_CHECK: boolean = false;

  @Of(() => String, { optional: true })
  @ValidateIf(
    (o) => (o.entityType === 'CLASS' || o.entityType === 'TEACHER') && o.MAX_GAPS_PER_WEEK_CHECK
  )
  @IsNotEmpty({ message: 'errors.required' })
  MAX_GAPS_PER_WEEK: string = '';

  @Of(() => Boolean, { optional: true })
  MAX_GAPS_PER_WEEK_CHECK: boolean = false;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.entityType === 'TEACHER' && o.MAX_HOURS_CONTINUOUSLY_CHECK)
  @IsNotEmpty({ message: 'errors.required' })
  MAX_HOURS_CONTINUOUSLY: string = '';

  @Of(() => Boolean, { optional: true })
  MAX_HOURS_CONTINUOUSLY_CHECK: boolean = false;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.entityType === 'ACTIVITY' && o.MIN_DAYS_BETWEEN_SAME_ACTIVITIES_CHECK)
  @IsNotEmpty({ message: 'errors.required' })
  MIN_DAYS_BETWEEN_SAME_ACTIVITIES: string = '';

  @Of(() => Boolean, { optional: true })
  MIN_DAYS_BETWEEN_SAME_ACTIVITIES_CHECK: boolean = false;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => EntityTypes)
  @Of(() => [EntityTypes])
  @Transform(({ value, obj }) => {
    const x = value.map((v: EntityTypes, i: number) => {
      const { entities, entityType, ...rest } = obj;
      v['general'] = rest;

      return v;
    });

    return x;
  })
  entities: EntityTypes[] = [];

  static of = Entity.of<TimetableWizardForm>();
}

@Entity
export class EntityTypes {
  @Of(() => String)
  entityType: EntityTypeRules | '' = '';

  @Of(() => String, { optional: true })
  general?: Partial<TimetableWizardForm>;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.required' })
  entityID: string = '';

  @Of(() => String)
  entityNrOre: string = '';

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.entityType === 'CLASS' && o.MIN_DAILY_ACTIVITIES_CHECK)
  @Validate(MaxValueGreaterThanMinValueSpecific, {
    message: 'errors.generate_timetable.minValueBigger',
  })
  @IsNotEmpty({ message: 'errors.required' })
  MIN_DAILY_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  MIN_DAILY_ACTIVITIES_CHECK: boolean = false;

  @Of(() => String, { optional: true })
  @ValidateIf(
    (o) => (o.entityType === 'CLASS' || o.entityType === 'TEACHER') && o.MAX_DAILY_ACTIVITIES_CHECK
  )
  @IsNotEmpty({ message: 'errors.required' })
  @Validate(MaxValueGreaterThanMinValueSpecific, {
    message: 'errors.generate_timetable.minValueBigger',
  })
  MAX_DAILY_ACTIVITIES: string = '';

  @Of(() => String, { optional: true })
  MAX_DAILY_ACTIVITIES_CHECK: boolean = false;

  @Of(() => String, { optional: true })
  @ValidateIf(
    (o) => (o.entityType === 'CLASS' || o.entityType === 'TEACHER') && o.MAX_GAPS_PER_WEEK_CHECK
  )
  @IsNotEmpty({ message: 'errors.required' })
  MAX_GAPS_PER_WEEK: string = '';

  @Of(() => Boolean, { optional: true })
  MAX_GAPS_PER_WEEK_CHECK: boolean = false;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.entityType === 'TEACHER' && o.MAX_HOURS_CONTINUOUSLY_CHECK)
  @IsNotEmpty({ message: 'errors.required' })
  MAX_HOURS_CONTINUOUSLY: string = '';

  @Of(() => Boolean, { optional: true })
  MAX_HOURS_CONTINUOUSLY_CHECK: boolean = false;

  // ------------- START ACTIVITY -------------

  @Of(() => Boolean, { optional: true })
  ACTIVITY_BEGINS_STUDENTS_DAY: boolean = false;

  @Of(() => Boolean, { optional: true })
  ACTIVITY_ENDS_STUDENTS_DAY: boolean = false;

  @Of(() => Boolean, { optional: true })
  ACTIVITIES_MUST_HAVE_THE_SAME_STARTING_HOUR: boolean = false;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.entityType === 'ACTIVITY' && o.MIN_DAYS_BETWEEN_ACTIVITIES_CHECK)
  @IsNotEmpty({ message: 'errors.required' })
  MIN_DAYS_BETWEEN_ACTIVITIES: string = '';

  @Of(() => Boolean, { optional: true })
  MIN_DAYS_BETWEEN_ACTIVITIES_CHECK: boolean = false;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.entityType === 'ACTIVITY' && o.MAX_DAYS_BETWEEN_ACTIVITIES_CHECK)
  @IsNotEmpty({ message: 'errors.required' })
  MAX_DAYS_BETWEEN_ACTIVITIES: string = '';

  @Of(() => Boolean, { optional: true })
  MAX_DAYS_BETWEEN_ACTIVITIES_CHECK: boolean = false;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.entityType === 'ACTIVITY' && o.ACTIVITY_PREFERRED_ROOM_CHECK)
  @IsNotEmpty({ message: 'errors.required' })
  ACTIVITY_PREFERRED_ROOM: string = '';

  @Of(() => Boolean, { optional: true })
  ACTIVITY_PREFERRED_ROOM_CHECK: boolean = false;

  @Of(() => Number, { optional: true })
  @ValidateIf((o) => o.entityType === 'ACTIVITY' && o.concurrentActivities.length > 0)
  @Min(1, { message: t('shared:errors.min_dynamic', { 0: 1 }) })
  @MaxByFunction()
  @IsNotEmpty({ message: 'errors.required' })
  CONCURRENT_HOURS?: number;

  @Of(() => [ConcurrentActivity], { optional: true })
  concurrentActivities: ConcurrentActivity[] = [];

  // ------------- END ACTIVITY -------------

  @Of(() => String, { optional: true })
  UNAVAILABILITIES_CHECK: boolean = false;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => UnavailabilityEntity)
  @Of(() => [UnavailabilityEntity])
  unavailabilities: UnavailabilityEntity[] = [];

  static of = Entity.of<EntityTypes>();
}

@Entity
export class ConcurrentActivity {
  @Of(() => String)
  cmcID: string = '';

  @Of(() => String)
  cmcNrOre: string = '';

  static of = Entity.of<ConcurrentActivity>();
}

@Entity
export class UnavailabilityEntity {
  @Of(() => String, { optional: true })
  @IsNotEmpty({ message: 'errors.required' })
  dayID: string = '';

  @Of(() => String, { optional: true })
  @IsNotEmpty({ message: 'errors.required' })
  startHour: string = '';

  @Of(() => String, { optional: true })
  @IsNotEmpty({ message: 'errors.required' })
  @Validate(IsTimeRangeValidConstraint, { message: 'errors.generate_timetable.endHourBigger' })
  endHour: string = '';

  static of = Entity.of<UnavailabilityEntity>();
}
