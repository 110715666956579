import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useYearsData } from '@modules/shared/utils';
import {
  AdservioIcons,
  Box,
  Button,
  Divider,
  IconButton,
  Loader,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@ui2/components';
import { useBreakpointValue } from '@ui2/hooks';

export const AcademicYearMenuSelect = () => {
  const { t } = useTranslation('shared');
  const isMobile = useBreakpointValue({ base: true, md: false });

  const {
    academicYears,
    selectedAcademicYear,
    academicYearId,
    setAcademicYearId,
    isLoading,
    rerun,
  } = useYearsData();

  useEffect(() => {
    rerun();
  }, []);

  return (
    <Loader isLoading={isLoading} size="xs">
      <Box>
        <Menu>
          {isMobile ? (
            <MenuButton
              as={IconButton}
              icon={<AdservioIcons.FiCalendar />}
              variant="ghost"
              colorScheme="gray"
            />
          ) : (
            <MenuButton
              maxW="16rem"
              as={Button}
              variant="ghost"
              colorScheme="gray"
              rightIcon={<AdservioIcons.FiChevronDown />}
              leftIcon={<AdservioIcons.FiCalendar />}
            >
              <Text noOfLines={1} textAlign="right" display={isMobile ? 'none' : 'block'}>
                {selectedAcademicYear
                  ? `${selectedAcademicYear.startYear} - ${selectedAcademicYear.endYear}`
                  : t('general.select_academic_year')}
              </Text>
            </MenuButton>
          )}

          <MenuList overflowY="auto" alignItems="flex-start">
            <VStack spacing="2">
              {academicYears?.map((academicYear, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <MenuItem
                      w="13rem"
                      backgroundColor={
                        academicYear?.id === academicYearId ? 'gray.100' : 'transparent'
                      }
                      borderRadius="lg"
                      onClick={() => setAcademicYearId(academicYear.id)}
                      noOfLines={1}
                      alignItems="flex-start"
                      _hover={{ bg: 'gray.50' }}
                    >
                      <Text>
                        {academicYear.startYear} - {academicYear.endYear}
                      </Text>
                    </MenuItem>
                    {idx !== academicYears.length - 1 && <Divider maxW="13rem" color="gray.200" />}
                  </React.Fragment>
                );
              })}
            </VStack>
          </MenuList>
        </Menu>
      </Box>
    </Loader>
  );
};
