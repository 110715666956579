import {
  AsyncCreatableProps,
  AsyncCreatableSelect as _AsyncCreatableSelect,
} from 'chakra-react-select';

import { ReactSelect } from '../../../utils';

export const AsyncCreatableSelect =
  ReactSelect<AsyncCreatableProps<any, boolean, any>>(_AsyncCreatableSelect);

export type { AsyncCreatableProps };
