import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountProfileUpdateMutation } from '@api/billing/endpoints';
import {
  AccountBillingProfile,
  PrivateBillingProfileForm,
  PrivateBillingProfileInput,
} from '@api/billing/entities';
import { useCitiesQuery, useCountiesQuery, useCountriesQuery } from '@api/core/endpoints';
import { PhoneWithPrefix } from '@api/core/entities';
import { useRouter } from '@lib/router';
import { useSession } from '@lib/utils';
import { BUCHAREST_COUNTY_CODE, countries } from '@modules/shared/utils';
import {
  Box,
  Divider,
  FormHook,
  FormInput,
  FormModal,
  FormPhoneInput,
  FormSyncSelect,
  Grid,
  GridItem,
  Stack,
  Text,
  VStack,
} from '@ui2/components';
import { computePhoneInputValue } from '@ui2/components/molecules/PhoneInput';
import { useBreakpointValue } from '@ui2/hooks';
import { createValidator, SelectItemProps } from '@ui2/utils';

const validator = createValidator(PrivateBillingProfileForm);

interface EditPrivateProfileModalProps {
  isOpen: boolean;
  profile: AccountBillingProfile;
  rootOrgId?: string;
  xAccountId?: string;
  isSubscriptions?: boolean;
  onToggle: () => void;
}

export const EditPrivateProfileModal = ({
  isOpen,
  profile,
  rootOrgId,
  xAccountId,
  isSubscriptions,
  children,
  onToggle,
}: PropsWithChildren<EditPrivateProfileModalProps>) => {
  const { t } = useTranslation('billing');
  const updateProfile = useAccountProfileUpdateMutation();
  const { studentId } = useSession();
  const { lang } = useRouter();

  const [selectedCountry, setSelectedCountry] = useState(profile.country);
  const [selectedCounty, setSelectedCounty] = useState(profile.county);

  const { data: countriesData } = useCountriesQuery({});

  const { data: countiesData } = useCountiesQuery(
    { taraCod: String(selectedCountry) },
    { enabled: !!selectedCountry }
  );

  const countryOptions = countries.map(
    (country) =>
      ({
        label: country[String(lang)],
        value: country.alpha2.toLocaleUpperCase(),
      }) as SelectItemProps
  );

  const countyOptions =
    countiesData?.data
      .map(
        (c) =>
          ({
            label: c.judNume,
            value: c.judCod,
          }) as SelectItemProps
      )
      .filter((c) => c.value) || [];

  const countyId = countiesData?.data.find((c) => c.judCod === selectedCounty)?.judID;

  const { data: citiesData, isLoading: isCitiesLoading } = useCitiesQuery(
    { judID: String(countyId) },
    { enabled: !!countyId && selectedCounty === BUCHAREST_COUNTY_CODE }
  );
  const cityOptions =
    citiesData?.data
      .filter((c) => c.cityType === 'sector')
      .map(
        (c) =>
          ({
            label: c.cityName,
            value: c.cityID,
          }) as SelectItemProps
      ) || [];

  const phoneNumber = profile?.phone
    ? computePhoneInputValue(profile.phone)
    : PhoneWithPrefix.of({});

  const size = useBreakpointValue({ base: '2xl', lg: '4xl' });

  const handleSubmit = (payload: PrivateBillingProfileForm) => {
    const phone =
      payload?.phoneNumber?.phone && payload?.phoneNumber?.prefix
        ? payload.phoneNumber.prefix + payload.phoneNumber.phone
        : '';

    updateProfile.mutate(
      {
        accountId: xAccountId || studentId,
        profileId: profile.id,
        payload: PrivateBillingProfileInput.of({ ...payload, phone }),
        rootOrgId,
        xAccountId,
      },
      {
        onSuccess: onToggle,
      }
    );
  };

  useEffect(() => {
    if (isSubscriptions) {
      profile.isSubscriptions = true;
    }
  }, []);

  const isCountryFirstRender = useRef(true);
  const isCountyFirstRender = useRef(true);

  const defaultValues = useMemo(
    () => PrivateBillingProfileForm.of({ ...profile, phoneNumber }),
    [profile]
  );

  return (
    <FormModal
      size={size}
      isOpen={isOpen}
      isLoading={updateProfile.isLoading}
      onClose={onToggle}
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      validator={validator}
      title={t('profile.edit_billing_profile')}
    >
      <FormHook<PrivateBillingProfileForm>>
        {({ values, form }) => {
          useEffect(() => {
            if (isOpen) {
              isCountryFirstRender.current = true;
              isCountyFirstRender.current = true;
            }
          }, [isOpen]);

          useEffect(() => {
            if (isCountryFirstRender.current) {
              isCountryFirstRender.current = false;
              return;
            }

            form.change('county', '');

            setSelectedCountry(values.country);
            setSelectedCounty('');
          }, [values.country]);

          useEffect(() => {
            if (isCountyFirstRender.current) {
              isCountyFirstRender.current = false;
              return;
            }

            if (values.county === BUCHAREST_COUNTY_CODE) {
              form.change('city', '');
            }

            setSelectedCounty(values.county);
          }, [values.county]);

          return (
            <VStack spacing="8" w="full" alignItems="flex-start">
              <Box w="full">
                <Text fontSize="lg" fontWeight="semibold">
                  {t('profile.private_profile.name_and_address')}
                </Text>

                <Divider mt="4" mb="6" />

                <Stack
                  w="full"
                  spacing={{ base: '0', sm: '4' }}
                  direction={{ base: 'column', sm: 'row' }}
                >
                  <FormInput isRequired name="lastName" label={t('profile.last_name')} />
                  <FormInput isRequired name="firstName" label={t('profile.first_name')} />
                </Stack>

                <Grid templateColumns="repeat(6, 1fr)" columnGap={4}>
                  <GridItem colSpan={{ base: 6, sm: 3, md: 2 }}>
                    <FormSyncSelect
                      isRequired
                      name="country"
                      options={countryOptions}
                      label={t('profile.private_profile.country')}
                      onChangeCallback={(country: string) => {
                        const countryId = countriesData?.data.find(
                          (c) => c.taraCod === country
                        )?.taraID;
                        form.change('countryId', countryId);
                      }}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 6, sm: 3, md: 2 }}>
                    <FormSyncSelect
                      isRequired
                      testId="county"
                      name="county"
                      label={t('profile.private_profile.county')}
                      options={countyOptions}
                      onChangeCallback={(county: string) => {
                        const countyId = countiesData?.data.find((c) => c.judCod === county)?.judID;
                        form.change('countyId', countyId);
                      }}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 6, sm: 3, md: 2 }}>
                    {cityOptions.length > 1 ? (
                      <FormSyncSelect
                        isRequired
                        testId="cityId"
                        name="cityId"
                        label={t('profile.locality')}
                        options={cityOptions}
                        isLoading={isCitiesLoading}
                        onChangeCallback={(cityId: string) => {
                          const city = citiesData?.data.find((c) => c.cityID === cityId)?.cityName;
                          form.change('city', city);
                        }}
                      />
                    ) : (
                      <FormInput
                        isRequired
                        testId="city"
                        name="city"
                        label={t('profile.locality')}
                      />
                    )}
                  </GridItem>

                  <GridItem colSpan={{ base: 6, md: 4 }}>
                    <FormInput
                      isRequired
                      name="street"
                      label={t('profile.private_profile.address')}
                      helperText={t('profile.address_helper_text')}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 6, sm: 3, md: 2 }}>
                    <FormInput name="zipCode" label={t('profile.private_profile.zip_code')} />
                  </GridItem>
                </Grid>
              </Box>

              <Box w="full">
                <Text fontSize="lg" fontWeight="semibold">
                  {t('profile.private_profile.contact_information')}
                </Text>

                <Divider mt="4" mb="6" />

                <Stack spacing={{ base: '0', sm: '4' }} direction={{ base: 'column', sm: 'row' }}>
                  <FormInput
                    name="email"
                    label={t('profile.private_profile.email')}
                    isRequired={isSubscriptions}
                  />

                  <FormPhoneInput
                    testId="phoneNumber"
                    name="phoneNumber"
                    label={t('profile.private_profile.phone')}
                    isRequired={isSubscriptions}
                  />
                </Stack>
              </Box>

              {children}
            </VStack>
          );
        }}
      </FormHook>
    </FormModal>
  );
};
