// @ts-nocheck
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import './Preview.less';

import Api2 from '../../../Libs/api';
// eslint-disable-next-line import/no-named-as-default
import FileIcon from '../../Content/Files/FileIcon/FileIcon';
import Icon from '../../Content/Icon/Icon';
import Modal from '../Modal/Modal';

import previewFail from './images/submarin.svg';

function Preview(props) {
  const { file, t, onRequestClose, downloadDisabled, apiPath } = props;

  const handleEsc = (event) => {
    if (event.keyCode !== 27) return;

    if (typeof onRequestClose === 'function') {
      onRequestClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleEsc);

    return () => {
      document.removeEventListener('keyup', handleEsc);
    };
  }, []);

  const [loading, setLoading] = useState(false);
  const [src, setSrc] = useState(
    `${window.location.origin}/${Api2.host}/fisiere/view?fsID=${file.fsID}`
  );

  useEffect(() => {
    if (file.fsMimeType?.match(/word|excel|powerpoint|office/i)) {
      setLoading(true);
      Api2.get(`/fisiere/url?fsID=${file.fsID}`).then((fs) => {
        setSrc(fs.fsUrl);
        setLoading(false);
      });
    } else {
      const baseUrl = `${window.location.origin}${Api2.host}`;
      if (apiPath) {
        setSrc(`${baseUrl}${apiPath}`);
        return;
      } else {
        setSrc(`${baseUrl}/fisiere/view?fsID=${file.fsID}&_liceuID=${Api2.getLiceuID()}`);
      }
    }
  }, [file]);

  const renderFile = (_file, _src) => {
    if (_file.fsMimeType) {
      if (_file.fsMimeType.match(/video\//i)) {
        const extraProps = downloadDisabled
          ? {
              controlsList: 'nodownload',
            }
          : {};

        return (
          <video width="100%" controls autoPlay {...extraProps}>
            <source src={_src} type="video/mp4" />
            {t('preview.browser_not_support')}
          </video>
        );
      }

      if (_file.fsMimeType.match(/audio\//i)) {
        return (
          <audio width="100%" controls autoPlay controlsList="nodownload">
            <source src={_src} type={`${_file.fsMimeType}`} />
            {t('preview.browser_not_support')}
          </audio>
        );
      }

      if (_file.fsMimeType.match(/image\//i)) {
        return <img src={_src} alt="" />;
      }

      if (_file.fsMimeType.match(/pdf/i)) {
        const pdfSrc = `${_src}${downloadDisabled ? '#toolbar=0&navpanes=0' : ''}`;
        return (
          <div className="fs-preview">
            <iframe src={pdfSrc} width="100%" height="100%" />
          </div>
        );
      }

      if (_file.fsMimeType.match(/word|excel|powerpoint|office/i)) {
        const baseUrl = 'https://view.officeapps.live.com/op/view.aspx?src=';
        const fileUrl = `${_src}${downloadDisabled ? '#toolbar=0' : ''}`;
        const url = `${baseUrl}${encodeURIComponent(fileUrl)}`;

        return <iframe src={url} />;
      }

      return (
        <div className="fs-preview-fail">
          {t('preview.fail')}
          <br />
          <a
            href={`${Api2.host}/fisiere/view?fsID=${file.fsID}`}
            target="_blank"
            rel="noreferrer"
            className="download-fs-fail"
          >
            {t('preview.download_file')}
          </a>
          <img src={previewFail} alt="fail-preview" />
        </div>
      );
    }
  };

  return (
    <Modal
      {...props}
      closeable
      overlay
      className="comp-modal-preview"
      overlayClassName="preview-files-overlay"
    >
      <div className="preview-header">
        <div className="preview-header-left">
          <FileIcon fsTip={file.fsTip} fsExt={file.fsExt} />
          {file.fsNume}
        </div>
        <div className="preview-header-right">
          {!downloadDisabled && (
            <a
              href={
                Api2.host +
                (apiPath ||
                  `/fisiere/view?fsID=${file.fsID}&download=1&_liceuID=${Api2.getLiceuID()}`)
              }
              rel="noreferrer"
            >
              <Icon icon="icon-download" />
              {t('preview.download')}
            </a>
          )}
          <button
            type="button"
            onClick={onRequestClose}
            onTouchEnd={onRequestClose}
            className="close-modal _icon icon-remove-filled"
          />
        </div>
      </div>
      <div className="preview-content">{!loading && renderFile(file, src)}</div>
    </Modal>
  );
}

Preview.propTypes = {
  file: PropTypes.shape({
    fsNume: PropTypes.string,
    fsTip: PropTypes.string,
    fsExt: PropTypes.string,
    fsID: PropTypes.string,
    fsMimeType: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func,
};

Preview.defaultProps = {
  file: {},
  onRequestClose: () => {},
};

export default withTranslation('Preview')(Preview);
