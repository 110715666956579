import { Entity, Of } from 'entity-of';

@Entity
export class TimetableWizardStatus {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  tenantID: string = '';

  @Of(() => String)
  timetableID: string = '';

  static of = Entity.of<TimetableWizardStatus>();
}
