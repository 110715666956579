import { Entity, Of } from 'entity-of';

export type PortfolioCategoryType = 'educational' | 'individual';
export const PortfolioCategoryTypeEnum = {
  Educational: 'educational' as PortfolioCategoryType,
  Individual: 'individual' as PortfolioCategoryType,
};

@Entity
export class PortfolioCategory {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  icon: string = '';

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  documentCount: string = '';

  static of = Entity.of<PortfolioCategory>();
}
