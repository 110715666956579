import React, { forwardRef, PropsWithChildren } from 'react';

import { useBreakpointValue } from '@chakra-ui/react';
import { useStorageSession } from '@lib/utils';

import { Box, Container, ContainerProps, Flex, Grid, Heading, HStack, Text } from '../../atoms';

interface MainContainerProps {
  steps?: string[];
}

export const MainContainer = forwardRef<HTMLDivElement, PropsWithChildren<MainContainerProps>>(
  ({ steps, children }, ref) => {
    const { isNative } = useStorageSession();

    return (
      <Flex
        ref={ref as any}
        flexDirection="column"
        height="min(100svh, 100vh)"
        pt={isNative ? '8' : { base: steps?.length ? 0 : '8', lg: '16' }}
        overflowY="auto"
      >
        {children}
      </Flex>
    );
  }
);

interface RenderContentWrapperProps {
  steps?: string[];
}

export const RenderContentWrapper = ({
  steps,
  children,
}: PropsWithChildren<RenderContentWrapperProps>) => {
  const hasMobileStepper = useBreakpointValue({ base: true, md: false });

  return (
    <ContentWrapper
      minHeight={`calc(100% - ${steps?.length ? (hasMobileStepper ? 144 : 120) : 120}px)`}
      paddingBottom={{ sm: '12', md: '16' }}
    >
      {children}
      <Box height={`${steps?.length ? (hasMobileStepper ? 144 : 120) : 120}`}></Box>
    </ContentWrapper>
  );
};

export const ContentWrapper = ({ children, ...props }: PropsWithChildren<ContainerProps>) => {
  return (
    <Container width="100%" maxW="container.xl" px={{ base: 4, lg: 8 }} {...props}>
      {children}
    </Container>
  );
};

interface MobileStepperProps {
  step?: number;
  steps?: string[];
}

export const MobileStepper = ({ step = 0, steps }: MobileStepperProps) => {
  return (
    <>
      {steps?.length && (
        <ContentWrapper>
          <Grid
            templateColumns={`repeat(1fr, ${steps.length})`}
            display="flex"
            mt="2"
            mb="8"
            width="100%"
            columnGap="2"
          >
            {steps?.map((_, index) => {
              const color = step > index ? 'green.300' : step === index ? 'green.500' : 'gray.200';
              return (
                <Box
                  key={index}
                  flex="1"
                  height="0.125rem"
                  borderRadius="lg"
                  backgroundColor={color}
                />
              );
            })}
          </Grid>
        </ContentWrapper>
      )}
    </>
  );
};

export const TitleContainer = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ContentWrapper>
      <HStack
        width="100%"
        mt="0"
        mb="12"
        alignItems="center"
        justifyContent="space-between"
        justifySelf="flex-start"
      >
        {children}
      </HStack>
    </ContentWrapper>
  );
};

export const Title = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Heading
      data-testid={'title-container'}
      color="gray.700"
      size="lg"
      whiteSpace={{ base: 'pre-line', md: 'nowrap' }}
    >
      {children}
    </Heading>
  );
};

export const Subtitle = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Text
      data-testid={'fullpage-wizard-subtitle-container'}
      color={'gray.400'}
      fontSize="sm"
      fontWeight="400"
    >
      {children}
    </Text>
  );
};

export const FooterContainer = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      mt="4"
      backgroundColor="white"
      boxShadow="top"
      borderTopLeftRadius="xl"
      borderTopRightRadius="xl"
      zIndex="overlay"
    >
      <Container width="100%" maxW="container.xl" px={{ base: 4, lg: 8 }}>
        <Flex data-testid="footer-container" justifyContent="space-between" py={{ base: 4, md: 8 }}>
          {children}
        </Flex>
      </Container>
    </Box>
  );
};
