import { Entity, Of } from 'entity-of';

@Entity
export class PackageByIdInput {
  @Of(() => String)
  packageId: String = '';

  @Of(() => Boolean)
  withPublishedList = false;

  static of = Entity.of<PackageByIdInput>();
}
