import {
  IsNotEmpty,
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
} from 'class-validator';
import { Entity, Of } from 'entity-of';

import { DiscountType } from '../Discount';

@Entity
export class AddDiscountInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.type.required' })
  type: DiscountType = DiscountType.PERCENTAGE;

  @Of(() => Number, { optional: true })
  @NotZero({ message: 'errors.value.notZero' })
  @MinIfNotUndefined(-0.001, { message: 'errors.value.positive' })
  @MaxValueIfPercent({ message: 'errors.value.maxPercentage' })
  @MaxValueIfFixed({ message: 'errors.value.maxFixedDiscount' })
  @IsNotEmpty({ message: 'errors.value.required' })
  value?: number;

  @Of(() => String, { optional: true })
  description?: string;

  // this is only needed for the value validation
  @Of(() => Number, { optional: true })
  chargeUnitPrice?: number;

  static of = Entity.of<AddDiscountInput>();
}

function NotZero(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'notZero',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: number) {
          return (
            value === null || typeof value === 'undefined' || Number.isNaN(value) || value !== 0
          );
        },
      },
    });
  };
}

function MinIfNotUndefined(min: number, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'minIfNotUndefined',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: number) {
          return (
            value === null || typeof value === 'undefined' || Number.isNaN(value) || value > min
          );
        },
      },
    });
  };
}

function MaxValueIfPercent(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'maxValueIfPercent',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: number, args: ValidationArguments) {
          const type = (args.object as any).type;

          if (type === DiscountType.PERCENTAGE) {
            return (
              value === null || typeof value === 'undefined' || Number.isNaN(value) || value <= 100
            );
          }

          return true;
        },
      },
    });
  };
}

function MaxValueIfFixed(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'maxValueIfFixed',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: number, args: ValidationArguments) {
          const type = (args.object as any).type;
          const chargeUnitPrice = (args.object as any).chargeUnitPrice;

          if (type === DiscountType.FIXED) {
            return (
              value === null ||
              typeof value === 'undefined' ||
              Number.isNaN(value) ||
              value <= chargeUnitPrice
            );
          }

          return true;
        },
      },
    });
  };
}
