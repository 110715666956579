import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class SearchEdumarketOffersInput extends SimpleQueryInput {
  @Of(() => Number, { optional: true })
  anStudiuID?: number;

  @Of(() => Number, { optional: true })
  emoIsDeleted?: number;

  static of = Entity.of<SearchEdumarketOffersInput>();
}
