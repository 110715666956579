import { Entity, Of } from 'entity-of';

type Order = 'asc' | 'desc';

@Entity
export class PaginatedClassesInput {
  @Of(() => Number)
  offset?: number = 0;

  @Of(() => String, { optional: true })
  clAscID?: string;

  @Of(() => String, { optional: true })
  clAstID?: string;

  @Of(() => String, { optional: true })
  clID?: string;

  @Of(() => Number)
  limit?: number = 1;

  @Of(() => String)
  order?: Order = 'asc';

  @Of(() => String, { optional: true })
  orderBy?: string;

  @Of(() => Boolean, { optional: true })
  withExtraInfo?: boolean = true;

  static of = Entity.of<PaginatedClassesInput>();
}
