import { ValueLabel } from '@modules/Universities/university-personal/entities';
import { Type } from 'class-transformer';
import {
  ArrayNotEmpty,
  IsEmail,
  IsNotEmpty,
  Length,
  MaxLength,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import { Entity, Of } from 'entity-of';
import { t } from 'i18next';

import 'reflect-metadata';

export class ErasmusStudentInformation {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  erasmusStudentId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => Number, { nullable: true })
  @IsNotEmpty({ message: t('errors.required') })
  facultyId: number | null = null;

  @Of(() => Number, { nullable: true })
  @IsNotEmpty({ message: t('errors.required') })
  cycleId: number | null = null;

  @Of(() => Number, { nullable: true })
  @IsNotEmpty({ message: t('errors.required') })
  domainId: number | null = null;

  @Of(() => Number, { nullable: true })
  @IsNotEmpty({ message: t('errors.required') })
  programId: number | null = null;

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  originFacultyName: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  hostFacultyName: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  coordinatorName: string = '';

  @Of(() => String)
  @IsEmail({}, { message: 'errors.email_format' })
  @IsNotEmpty({ message: t('errors.required') })
  coordinatorEmail: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  coordinatorPhone: string = '';

  @Of(() => String)
  domainName: string = '';

  @Of(() => String)
  programName: string = '';

  static of = Entity.of<ErasmusStudentInformation>();
}

@Entity
export class UniversityStudent {
  @Of(() => Boolean)
  isAddEdit: boolean = false;

  @Of(() => Number)
  personId: number = 0;

  @Of(() => String)
  uaID: string = '';

  @Of(() => Number)
  userId: number = 0;

  @Of(() => String)
  actualName: string = '';

  @Of(() => String)
  birthGivenName: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  surname: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  name: string = '';

  @Of(() => String)
  @MaxLength(4, { message: 'errors.parent_initial_length' })
  parentInitial: string = '';

  @Of(() => String)
  @Length(13, 13, { message: 'errors.cnp_length' })
  @IsNotEmpty({ message: t('errors.required') })
  cnp: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.personalEmail !== '')
  @IsEmail({}, { message: 'errors.email_format' })
  personalEmail: string = '';

  @Of(() => String)
  phoneNumber: string = '';

  @Of(() => String)
  microsoftId: string = '';

  @Of(() => Number)
  moodleId: number = 0;

  @Of(() => Boolean)
  foreignCitizen: boolean = false;

  @Of(() => String)
  prevCNP: string = '';

  @Of(() => String)
  bankName: string = '';

  @Of(() => String)
  bankAddress: string = '';

  @Of(() => String)
  swift: string = '';

  @Of(() => String)
  iban: string = '';

  @Of(() => String)
  accountHolderName: string = '';

  @Of(() => Date)
  lastUpdate: Date = new Date();

  @Of(() => Boolean)
  admin: boolean = false;

  @Of(() => Boolean)
  manager: boolean = false;

  @Of(() => Boolean)
  associate: boolean = false;

  @Of(() => Boolean)
  erasmusProgram: boolean = false;

  @Of(() => Boolean)
  departmentManager: boolean = false;

  @Of(() => Boolean)
  financialManager: boolean = false;

  @Of(() => Boolean)
  nonDidacticManager: boolean = false;

  @Of(() => [AssociatedFaculty])
  associatedFaculties: AssociatedFaculty[] = [];

  @Of(() => Boolean)
  erasmus: boolean = false;

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => ErasmusStudentInformation)
  @ValidateNested()
  @Type(() => ErasmusStudentInformation)
  @ValidateIf((o) => o.erasmus === true)
  erasmusStudentInformation: ErasmusStudentInformation = ErasmusStudentInformation.of({});

  @Of(() => Boolean)
  student: boolean = false;

  @ValidateIf((o) => !o.isAddEdit)
  @Of(() => [AssociatedGroup])
  @ValidateNested({ each: true })
  @Type(() => AssociatedGroup)
  associatedGroups: AssociatedGroup[] = [];

  @Of(() => Boolean)
  professor: boolean = false;

  @Of(() => Boolean)
  externalStudent: boolean = false;

  @Of(() => String)
  professorDepartment?: string = '';

  @Of(() => Number)
  professorDepartmentId?: number | null = null;

  @Of(() => String)
  professorFunction?: string = '';

  @Of(() => Number)
  professorFunctionId?: number | null = null;

  @Of(() => Number)
  leadershipFunctionId?: number = 0;

  @Of(() => String)
  professorFunctionType?: string = '';

  @Of(() => Number)
  professorMark?: number | null = null;

  @Of(() => [AssociatedDiscipline])
  associatedDisciplines: AssociatedDiscipline[] = [];

  @Of(() => String)
  birthDate: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  gender: string = '';

  @Of(() => String)
  identityDocumentType: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  citizen: string = '';

  @Of(() => String)
  documentSeries: string = '';

  @Of(() => String)
  documentNumber: string = '';

  @Of(() => String)
  issuer: string = '';

  @Of(() => String)
  dateOfIssue: string = '';

  @Of(() => String)
  expireDate: string = '';

  @Of(() => ValueLabel)
  country: ValueLabel = ValueLabel.of({});

  @Of(() => String)
  countryName: string = '';

  @Of(() => ValueLabel)
  county: ValueLabel = ValueLabel.of({});

  @Of(() => String)
  countyName: string = '';

  @Of(() => ValueLabel)
  city: ValueLabel = ValueLabel.of({});

  @Of(() => String)
  cityName: string = '';

  @Of(() => String)
  address: string = '';

  @Of(() => String)
  carLicensePlateNumber: string = '';

  @Of(() => String)
  maritalStatus: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.observations?.length > 0)
  @MaxLength(399, { message: 'errors.dynamic.max_length' })
  observations: string = '';

  @Of(() => String)
  tuition: string = '';

  @Of(() => Boolean)
  rural: boolean = false;

  @Of(() => Number, { nullable: true })
  @ValidateIf((o) => o.student || o.externalStudent)
  idStudentType: number | null = null;

  @Of(() => Number, { nullable: true })
  @ValidateIf((o) => o.student || o.externalStudent)
  idStudentCategory: number | null = null;

  @ArrayNotEmpty({ message: 'errors.required' })
  @Of(() => [StudentRoles])
  roleName: StudentRoles[] = [];

  static of = Entity.of<UniversityStudent>();
}

@Entity
export class StudentRoles {
  @Of(() => String)
  label: string = '';

  @Of(() => String)
  value: string = '';

  @Of(() => RolePermissions)
  permissions: RolePermissions = RolePermissions.of({});

  static of = Entity.of<StudentRoles>();
}

@Entity
export class RolePermissions {
  @Of(() => Boolean)
  isStudent: boolean = false;

  @Of(() => Boolean)
  isExternalStudent: boolean = false;

  static of = Entity.of<RolePermissions>();
}

@Entity
export class AssociatedFaculty {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  parentId: number = 0;

  @Of(() => String)
  parentName: string = '';

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  acronym: string = '';

  @Of(() => String)
  uniqueAcronym: string = '';

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => Number)
  idPromotie: number = 0;

  static of = Entity.of<AssociatedFaculty>();
}

@Entity
export class AssociatedGroup {
  @Of(() => Number)
  id?: number = 0;

  @Of(() => String)
  name?: string = '';

  @Of(() => Number)
  personId?: number = 0;

  @Of(() => Number)
  studyId?: number = 0;

  @Of(() => Number)
  facultyId?: number = 0;

  @Of(() => Number)
  academicYearId?: number = 0;

  @Of(() => String)
  type?: string = '';

  @Of(() => String)
  registrationNumber?: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  uniqueRegistrationNumber: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  studyStatus?: string = '';

  @Of(() => String)
  groupName?: string = '';

  @Of(() => String)
  facultyName?: string = '';

  @Of(() => String)
  cycleName?: string = '';

  @Of(() => String)
  domainName?: string = '';

  @Of(() => String)
  programName?: string = '';

  @Of(() => String)
  studyYearName?: string = '';

  @Of(() => String)
  seriesName?: string = '';

  @Of(() => String)
  displayStudyStatus?: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  tuitionTypeSem1: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  tuitionTypeSem2: string = '';

  @Of(() => String, { optional: true })
  academicYearName?: string;

  @Of(() => Boolean, { optional: true })
  currentAcademicYear?: boolean;

  static of = Entity.of<AssociatedGroup>();
}

@Entity
class AssociatedDiscipline {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  englishName: string = '';

  @Of(() => String)
  acronym: string = '';

  @Of(() => String)
  uniqueAcronym: string = '';

  @Of(() => Number)
  studyYearId: number = 0;

  @Of(() => Number)
  semester: number = 0;

  @Of(() => Number)
  series: number = 0;

  @Of(() => Number)
  examPercent: number = 0;

  @Of(() => Number)
  seminarPercent: number = 0;

  @Of(() => Number)
  laboratoryPercent: number = 0;

  @Of(() => Number)
  projectPercent: number = 0;

  @Of(() => Boolean)
  necessaryMinimumExamGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumSeminarGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumLaboratoryGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumProjectGrade: boolean = false;

  @Of(() => [AssociatedProfessor])
  associatedProfessors: AssociatedProfessor[] = [];

  @Of(() => String)
  role: string = '';

  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.roles.required' })
  roleName: string[] = [];

  @Of(() => String)
  facultyName: string = '';

  @Of(() => String)
  currentNumber: string = '';

  static of = Entity.of<AssociatedDiscipline>();
}

@Entity
class AssociatedProfessor {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  birthGivenName: string = '';

  @Of(() => String)
  @IsEmail({}, { message: 'errors.email_format' })
  universityEmail: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  surname: string = '';

  @Of(() => String)
  discipline: string = '';

  @Of(() => String)
  acronyms: string = '';

  @Of(() => Number)
  disciplineId: number = 0;

  @Of(() => String)
  roles: string = '';

  @Of(() => String)
  years: string = '';

  @Of(() => String)
  semesters: string = '';

  @Of(() => String)
  role: string = '';

  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.roles.required' })
  roleName: string[] = [];

  @Of(() => String)
  department: string = '';

  @Of(() => Number)
  departmentId: number = 0;

  @Of(() => Number)
  mark: number | string = 0;

  @Of(() => String)
  function: string = '';

  @Of(() => Number)
  functionId: number = 0;

  @Of(() => String)
  functionType: string = '';

  @Of(() => Boolean)
  main: boolean = false;

  static of = Entity.of<AssociatedProfessor>();
}
