import {
  Account,
  LanguageItem,
  Person,
  PersonClass,
  School,
  SchoolConfig,
  SchoolYear,
  Student,
  Teacher,
} from '@api/core/entities';
import { Package, SessionFeatures } from '@api/subscriptions/entities';
import {
  PERM_CAD_AUX,
  PERM_CAD_DIRECTOR,
  PERM_CAD_PROFESOR,
  PERM_CAD_REP_ADSERVIO,
  PERM_CAD_SECRETAR,
  PERM_STUDENT,
  TYPE_ADMIN,
  TYPE_ELEV,
  TYPE_PARINTE,
} from '@constants';
import { Callout } from 'Components/UpgradeCallout/entities';
import Cookies from 'js-cookie';

import { isCadruDidactic, isDirector, isSecretar } from '../../../Redux/SessionHelper';
import { AuthSettingsMap, StudentProgram } from '../entities';

export interface UserSession {
  atID: string;
  accounts: Account[];
  profile: Person;
  elev: Student;
  anScolar: SchoolYear;
  schoolConfig: SchoolConfig;
  clase: PersonClass[];
  school: School;
  studentProgram: StudentProgram[];
  ready?: boolean;
  lastUpdate: number;
  languages: LanguageItem[];
  cadru: Teacher;
}

export const useStorageSession = () => {
  const isNative = !!Cookies.get('ads_native');
  const isMPR = Cookies.get('MPR') === '1';

  const session = JSON.parse(localStorage.getItem('session') || '{}') as UserSession;
  const features = JSON.parse(localStorage.getItem('features') || '{}') as SessionFeatures;
  const callouts = JSON.parse(localStorage.getItem('callouts') || '[]') as Callout[];
  const authSettings = JSON.parse(localStorage.getItem('authSettings') || '{}') as AuthSettingsMap;
  const subscriptionPackages = JSON.parse(
    localStorage.getItem('subscriptionPackages') || '[]'
  ) as Package[];

  const uaTip = session?.profile?.uaTip;
  const permissions = session?.profile?.permisiuni?.map((p) => p.permID) || [];
  const profile = session?.profile || {};
  const accounts = session?.accounts || [];

  const student = session?.elev;
  const studentId = session?.elev?.elevID ?? session?.profile?.uaID;
  const schoolYear = session?.anScolar;
  const classes = session?.clase;
  const studentProgram = session?.studentProgram;

  const orgId = session?.profile?.uaLiceuID;
  const orgType = session?.school?.liceuOrgType || session?.profile?.liceu?.liceuOrgType;
  const school = session?.school || session?.profile?.liceu || {};
  const schoolConfig = session?.schoolConfig || {};
  const rootOrgId = session?.school?.liceuOrgRootID || session?.profile?.liceu?.liceuOrgRootID;

  const sessionHelpers = {
    isAdmin: session?.profile?.uaTip === TYPE_ADMIN,
    isDirector: isDirector({ session }),
    isSecretary: isSecretar({ session }),
    isTeacher: isCadruDidactic({ session }),
    isParent: session?.profile?.uaTip === TYPE_PARINTE,
    isStudent: session?.profile?.uaTip === TYPE_ELEV,
    activeClass: session?.clase?.find((item) => item.isActive === '1'),
  };

  const isSuperAdmin = permissions.includes(PERM_CAD_REP_ADSERVIO);

  const universitySessionHelpers = {
    isSuperAdmin: isSuperAdmin,
    isManager: isSuperAdmin ? true : permissions.includes(PERM_CAD_DIRECTOR),
    isTeacher: isSuperAdmin ? true : permissions.includes(PERM_CAD_PROFESOR),
    isSecretary: isSuperAdmin ? true : permissions.includes(PERM_CAD_SECRETAR),
    isPersonalAux: permissions.includes(PERM_CAD_AUX),
    isStudent: permissions.includes(PERM_STUDENT),
  };

  return {
    isReady: session?.ready,
    isNative,
    isAdmin: sessionHelpers.isAdmin,
    session,
    accounts,
    profile,
    student,
    permissions,
    schoolYear,
    school,
    orgId,
    uaTip,
    orgType,
    schoolConfig,
    classes,
    features,
    authSettings,
    sessionHelpers,
    studentId,
    callouts,
    subscriptionPackages,
    studentProgram,
    isMPR,
    rootOrgId,
    universitySessionHelpers,
  };
};
