import { Entity, Of } from 'entity-of';

export type testStatus = 'not_started' | 'started' | 'finished';

@Entity
export class Test {
  @Of(() => String)
  testID: string = '';

  @Of(() => String)
  testNume: string = '';

  @Of(() => String)
  testStatus: testStatus = 'not_started';

  @Of(() => String)
  testRunID: string = '';

  @Of(() => TestResult)
  testResults: TestResult = TestResult.of({});

  static of = Entity.of<Test>();
}

@Entity
export class TestResult {
  @Of(() => String)
  iq_position: string = '';

  @Of(() => String)
  iq_scor: string = '';

  @Of(() => String)
  iq_timp: string = '';

  @Of(() => [CategoryStats])
  statsCategorii: CategoryStats[] = [];

  static of = Entity.of<TestResult>();
}

@Entity
export class CategoryStats {
  @Of(() => [String])
  attribute: string[] = [];

  @Of(() => String)
  id: string = '';

  @Of(() => String)
  nume: string = '';

  @Of(() => String)
  totalRaspunsuri: string = '';

  static of = Entity.of<CategoryStats>();
}
