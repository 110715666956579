import { Entity, Of } from 'entity-of';

import { Invoice } from '../Invoice';

export enum BalanceAdjustmentType {
  CREDIT = 'credit',
  DEBIT = 'debit',
  PAYMENT = 'payment',
}

@Entity
export class BalanceAdjustment {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  tenantBillingProfileId: number = 0;

  @Of(() => String)
  accountId: string = '';

  @Of(() => Number)
  amount: number = 0;

  @Of(() => String)
  date: string = '';

  @Of(() => String)
  type: BalanceAdjustmentType = BalanceAdjustmentType.CREDIT;

  @Of(() => BalanceAdjustmentDetail)
  details: BalanceAdjustmentDetail = BalanceAdjustmentDetail.of({});

  @Of(() => String, { optional: true })
  observations?: string;

  @Of(() => String, { optional: true })
  currency?: string;

  @Of(() => String, { optional: true })
  createdAt?: string;

  @Of(() => String, { optional: true })
  updatedAt?: string;

  static of = Entity.of<BalanceAdjustment>();
}

@Entity
export class BalanceAdjustmentDetail {
  @Of(() => String)
  name: string = '';

  @Of(() => [Invoice])
  invoices?: Partial<Invoice>[];

  @Of(() => Boolean, { optional: true })
  isBankStatement?: boolean;

  static of = Entity.of<BalanceAdjustmentDetail>();
}
