import { Entity, Of } from 'entity-of';

import { AccountBillingProfile, Charge } from '..';

@Entity
export class Charge_AccountBillingProfile extends Charge {
  @Of(() => AccountBillingProfile)
  accountBillingProfile: AccountBillingProfile = AccountBillingProfile.of({});

  @Of(() => String)
  serviceName: String = '';

  @Of(() => Boolean)
  isPenalty: Boolean = false;

  static of = Entity.of<Charge_AccountBillingProfile>();
}
