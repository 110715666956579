import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class PaginatedQuestionnaireInput extends SimpleQueryInput {
  @Of(() => String, { optional: true })
  search?: string;

  @Of(() => Number, { optional: true })
  type?: number;

  @Of(() => Boolean, { optional: true })
  isPublished?: boolean;

  @Of(() => String, { optional: true })
  progress?: string;

  @Of(() => Number)
  filled?: number;

  static of = Entity.of<PaginatedQuestionnaireInput>();
}
