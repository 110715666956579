import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

import { Invoice } from '..';

@Entity
export class DebtInvoices_Person {
  @Of(() => Person)
  person: Person = Person.of({});

  @Of(() => [Invoice])
  invoices: Invoice[] = [];

  @Of(() => ({ Number }))
  unpaidInvoicesAmounts: Record<string, number> = {};

  @Of(() => Number)
  unpaidInvoicesCount: number = 0;

  static of = Entity.of<DebtInvoices_Person>();
}
