import { Entity, Of } from 'entity-of';

@Entity
export class LessonPlan {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  date: string = '';

  @Of(() => String)
  description: string = '';

  @Of(() => String)
  isPublic: string = '';

  static of = Entity.of<LessonPlan>();
}
