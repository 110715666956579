import { Entity, Of } from 'entity-of';

import { Invoice } from '../Invoice';

export enum StatementStatus {
  CONFIRMED = 'confirmed',
  PENDING = 'pending',
  FAILED = 'failed',
}

@Entity
export class StatementData {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String, { optional: true })
  date?: string;

  @Of(() => String, { optional: true })
  type?: string;

  @Of(() => Number, { optional: true })
  amount?: number;

  @Of(() => Number)
  invoicesAmount: number = 0;

  @Of(() => String, { optional: true })
  currency?: string;

  @Of(() => String, { optional: true })
  details?: string;

  @Of(() => [Invoice])
  invoices: Partial<Invoice>[] = [];

  @Of(() => String)
  status: StatementStatus = StatementStatus.CONFIRMED;

  static of = Entity.of<StatementData>();
}
