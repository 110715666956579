import { Entity, Of } from 'entity-of';

@Entity
export class Recipient {
  @Of(() => Number)
  students: number = 0;

  @Of(() => Number)
  parents: number = 0;

  @Of(() => Number)
  teachers: number = 0;

  static of = Entity.of<Recipient>();
}
