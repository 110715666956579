import { Type } from 'class-transformer';
import { Entity, Of } from 'entity-of';

import { Filler } from '../Filler';
import { FillersDetail } from '../FillerDetail';
import { Recipient } from '../Recipient';
import { RecurrenceDate } from '../RecurrenceDate';
import { Viewer } from '../Viewer';

export enum QuestionnaireItemType {
  SURVEY = 'survey',
  FEEDBACK = 'feedback',
  TEST = 'test',
}

export enum QuestionnaireItemTypeNumber {
  SURVEY = 1,
  FEEDBACK = 2,
  TEST = 3,
}

export enum RecipientsItemType {
  STUDENTS = '1',
  PARENTS = '2',
  TEACHERS = '3',
}

export type QuestionnaireItem = 1 | 2 | 3;

@Entity
export class Questionnaire {
  @Of(() => String)
  id: string = '';

  @Of(() => Number)
  type: number = 0;

  @Of(() => String)
  description: string = '';

  @Of(() => String)
  endDate: string = '';

  @Of(() => Filler, { optional: true })
  @Type(() => Filler)
  fillers?: Filler = Filler.of({});

  @Of(() => FillersDetail, { optional: true })
  allFillers?: FillersDetail = FillersDetail.of({});

  @Of(() => Boolean)
  isAnonymousAnswer: boolean = false;

  @Of(() => Number)
  showSignatories: number = 0;

  @Of(() => String)
  title: string = '';

  @Of(() => Number)
  totalQuestions: number = 0;

  @Of(() => String)
  status: string = '';

  @Of(() => RecurrenceDate, { optional: true })
  @Type(() => RecurrenceDate)
  recurrence?: RecurrenceDate = RecurrenceDate.of({});

  @Of(() => Viewer, { optional: true })
  @Type(() => Viewer)
  viewers?: Viewer = Viewer.of({});

  @Of(() => Recipient)
  totalRecipients: Recipient = Recipient.of({});

  @Of(() => String)
  createdAt: string = '';

  @Of(() => String)
  publishResultsType: string = '';

  @Of(() => String)
  timeLimit: string = '';

  @Of(() => Boolean)
  isPublished: boolean = false;

  static of = Entity.of<Questionnaire>();
}
