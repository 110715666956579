import { Role } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class PersonStaffSearchResponse {
  @Of(() => String, { optional: true })
  searchTerm?: string = '';

  @Of(() => String, { optional: true })
  cadCnp?: string = '';

  @Of(() => String, { optional: true })
  cadNumePrenume?: string = '';

  @Of(() => Number)
  perssonId: number = 0;

  @Of(() => String)
  firstName: string = '';

  @Of(() => String)
  actualName: string = '';

  @Of(() => String)
  lastName: string = '';

  @Of(() => String)
  parentInitial: string = '';

  @Of(() => String)
  cnp: string = '';

  @Of(() => Number)
  uaID: number = 0;

  @Of(() => String, { optional: true })
  studyCycle?: string;

  @Of(() => Number, { optional: true })
  studyCycleId?: number;

  @Of(() => String, { optional: true })
  studyDomain?: string;

  @Of(() => Number, { optional: true })
  studyDomainId?: number;

  @Of(() => String, { optional: true })
  studyGroup?: string;

  @Of(() => Number, { optional: true })
  studyGroupId?: number;

  @Of(() => String, { optional: true })
  studyProgram?: string;

  @Of(() => Number, { optional: true })
  studyProgramId?: number;

  @Of(() => String, { optional: true })
  studySeries?: string;

  @Of(() => Number, { optional: true })
  studySeriesId?: number;

  @Of(() => String, { optional: true })
  studyYear?: string;

  @Of(() => Number, { optional: true })
  studyYearId?: number;

  @Of(() => String, { optional: true })
  facultyName?: string;

  @Of(() => String, { optional: true })
  academicYearName?: string;

  @Of(() => Boolean, { optional: true })
  currentAcademicYear?: boolean;

  @Of(() => String)
  universityEmail: string = '';

  @Of(() => String)
  function: string = '';

  @Of(() => String)
  leadershipFunction: string = '';

  @Of(() => String)
  department: string = '';

  @Of(() => String, { optional: true })
  roles?: Role[] = [];

  static of = Entity.of<PersonStaffSearchResponse>();
}
