import React from 'react';

import { getOrgTypeTheme } from '@ui2/utils';

import { FeatureIcon, FeatureIconSize, VariantStyles } from '../../molecules/FeatureIcon';
import { AdservioIcons, IconNames } from '../Icons';
import { HStack, IconButton, Stack, StackProps, Text, TextProps, VStack } from '../';

type VariantTypesToColors = {
  default: 'gray';
  info: 'purple';
  success: 'green';
  warn: 'yellow';
  error: 'red';
  uniInfo: 'purple';
};

export type BannerVariant = keyof VariantTypesToColors;

const getIcon = (variant: string) => {
  return ['default', 'info', 'error', 'uniInfo'].includes(variant)
    ? 'FiInfo'
    : variant === 'warn'
      ? 'FiAlertTriangle'
      : 'FiCheckCircle';
};

interface BannerProps extends StackProps {
  variant?: keyof VariantTypesToColors;
  icon?: IconNames;
  iconSize?: FeatureIconSize;
  customIcon?: React.ReactNode;
  iconVariant?: 'borderless' | 'withBorder';
  title?: string;
  description?: string | string[];
  onClose?: () => void;
  renderRightElement?: () => React.ReactNode;
  titleSx?: TextProps;
  rightElementSx?: StackProps;
}

export const Banner = ({
  variant = 'default',
  icon,
  iconSize = 'sm',
  customIcon,
  iconVariant = 'borderless',
  title,
  description,
  children,
  onClose,
  renderRightElement,
  titleSx,
  rightElementSx,
  ...props
}: React.PropsWithChildren<BannerProps>) => {
  const baseColor = getOrgTypeTheme(VariantStyles[variant]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      p={3}
      spacing="3"
      width="100%"
      borderWidth={'1px'}
      data-testid="banner"
      borderRadius={'2xl'}
      justifyContent="space-between"
      borderColor={`${baseColor}.200`}
      backgroundColor={`${baseColor}.50`}
      {...props}
    >
      {customIcon ? (
        customIcon
      ) : (
        <FeatureIcon
          size={iconSize}
          isBorderless={iconVariant === 'borderless'}
          name={icon || getIcon(variant)}
          colorScheme={baseColor}
        />
      )}
      <VStack width="100%" alignItems="flex-start" spacing="0" justifyContent="center">
        {title && (
          <Text fontWeight="medium" fontSize="md" color={`${baseColor}.600`} {...titleSx}>
            {title}
          </Text>
        )}

        {description && (
          <>
            {!Array.isArray(description) ? (
              <Text fontWeight="regular" fontSize="sm" color={`${baseColor}.600`}>
                {description}
              </Text>
            ) : (
              <>
                {description.map((desc, index) => (
                  <Text key={index} fontWeight="regular" fontSize="sm" color={`${baseColor}.600`}>
                    {desc}
                  </Text>
                ))}
              </>
            )}
          </>
        )}

        {children}
      </VStack>

      <HStack display={renderRightElement ? 'flex' : 'contents'} {...rightElementSx}>
        {renderRightElement?.()}

        {onClose && (
          <IconButton
            size={'xs'}
            variant="ghost"
            onClick={onClose}
            aria-label={'close'}
            alignSelf="center"
            icon={<AdservioIcons.Close />}
            colorScheme={`${baseColor}`}
          />
        )}
      </HStack>
    </Stack>
  );
};
