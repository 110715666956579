import { Entity, Of } from 'entity-of';

@Entity
export class FillersInfo {
  @Of(() => String)
  value: string = '';

  @Of(() => String)
  label: string = '';

  @Of(() => Info)
  info: Info = Info.of({});

  static of = Entity.of<FillersInfo>();
}

@Entity
class Info {
  @Of(() => String)
  name?: string = '';

  @Of(() => String)
  id?: string = '';

  static of = Entity.of<Info>();
}
