import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFileUploadMutation } from '@api/core/endpoints';
import { FSInput } from '@api/core/entities';
import { useStorageSession } from '@lib/utils';
import { Center, Divider, FileUpload, FormInput, FormInputGroup, FormModal } from '@ui2/components';
import { createValidator, getOrgTypeTheme } from '@ui2/utils';

import { EditorSelection, ImageInput } from '../../utils';

const linkValidator = createValidator(ImageInput);

interface ImageModalProps {
  editorRef: any;
  isOpen: boolean;
  selection: EditorSelection;
  schoolIds?: string[];
  onClose: () => void;
}

export const ImageModal = ({
  editorRef,
  isOpen,
  selection,
  schoolIds,
  onClose,
}: ImageModalProps) => {
  const { t } = useTranslation('shared');
  const { profile } = useStorageSession();
  const baseColor = getOrgTypeTheme('blue');

  const [uploadedFile, setUploadedFile] = useState<string>();
  const fsMutation = useFileUploadMutation();

  const handleSubmit = (values) => {
    let quill = editorRef.current.getEditor();

    quill.insertEmbed(selection.index, 'image', values.source);
    onClose();
  };

  const handleFileUpload = (file: File) => {
    const ids = schoolIds?.length ? schoolIds : [profile.uaLiceuID];

    ids.forEach((sender: string) => {
      fsMutation.mutate(
        FSInput.of({
          _liceuID: sender,
          file: file,
          fsTip: '3',
          fsNume: file.name,
          fsUploader: 'plupload',
        }),
        {
          onSuccess: (data) => {
            setUploadedFile(
              `${window.location.origin}/api/v2/fisiere/view/?fsID=${data.fsID}&_liceuID=${sender}`
            );
          },
        }
      );
    });
  };

  const defaultValues = useMemo(() => {
    if (!isOpen) {
      return {};
    }

    return {
      source: uploadedFile,
      alt: '',
    };
  }, [isOpen, uploadedFile]);

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('general.add_insert_image')}
      defaultValues={defaultValues}
      validator={linkValidator}
      isLoading={fsMutation.isLoading}
    >
      <FormInputGroup
        name="source"
        label={t('general.source')}
        inputSx={{
          paddingRight: '14',
        }}
        rightElementSx={{
          width: 'initial',
        }}
        rightElement={
          <>
            <Divider
              position="relative"
              orientation="vertical"
              height="65%"
              borderColor="gray.200"
              right="0.45rem"
            />

            <Center mr="2">
              <FileUpload
                label={''}
                variant="ghost"
                colorScheme={baseColor}
                size="sm"
                isLoading={fsMutation.isLoading}
                onChange={handleFileUpload}
              />
            </Center>
          </>
        }
      />

      <FormInput name="alt" label={t('general.alt_description')} />
    </FormModal>
  );
};
