import { Entity, Of } from 'entity-of';

@Entity
export class OrganisationsSearchInput {
  @Of(() => String)
  packageId = '';

  @Of(() => Number)
  limit = 10;

  @Of(() => Number)
  offset = 0;

  @Of(() => [DateFilter])
  filters: DateFilter[] = [];

  static of = Entity.of<OrganisationsSearchInput>();
}

@Entity
class DateFilter {
  @Of(() => String)
  field = '';

  @Of(
    () => {
      String;
    },
    { isCustom: true }
  )
  operators: Record<string, string> = {};

  static of = Entity.of<DateFilter>();
}
