import { registerDecorator, ValidationOptions } from 'class-validator';
import zxcvbn from 'zxcvbn';

export function IsStrongPassword(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'isStrongPassword',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [],
      options: validationOptions,
      validator: {
        validate(value: any) {
          return typeof value === 'string' && zxcvbn(value).score >= 3;
        },
        defaultMessage() {
          return `errors.defaults.isStrongPassword`;
        },
      },
    });
  };
}
