import { Entity, Of } from 'entity-of';

import { EfacturaIssueType, SpvActiveType } from '../TenantBillingProfile';

@Entity
export class CreateBillingProfileResponse {
  @Of(() => String)
  id = 0;

  @Of(() => Boolean)
  isDefault = false;

  @Of(() => String)
  createdAt = '';

  @Of(() => String)
  updatedAt = '';

  @Of(() => String)
  onBoardingUrl = '';

  @Of(() => Boolean)
  isOnBoardingDone = false;

  @Of(() => String)
  name = '';

  @Of(() => String)
  fiscalCode = '';

  @Of(() => String)
  street = '';

  @Of(() => String)
  zipCode = '';

  @Of(() => String)
  country = '';

  @Of(() => String)
  county = '';

  @Of(() => String)
  city = '';

  @Of(() => String)
  countryId: string = '';

  @Of(() => String)
  countyId: string = '';

  @Of(() => String, { optional: true })
  cityId?: string;

  @Of(() => String)
  email = '';

  @Of(() => String)
  phone = '';

  @Of(() => String)
  bankName = '';

  @Of(() => String)
  iban = '';

  @Of(() => Boolean)
  displayBankOnInvoice = false;

  @Of(() => String)
  invoiceSeries = '';

  @Of(() => Number)
  invoiceStartNumber = 0;

  @Of(() => String)
  currency = '';

  @Of(() => Number)
  invoiceDay = 0;

  @Of(() => Number)
  invoiceDueAfter = 0;

  @Of(() => Number)
  penaltyPercentage = 0;

  @Of(() => Boolean)
  showExchangeRatesOnInvoice = false;

  @Of(() => String)
  otherInformation = '';

  @Of(() => Boolean)
  acceptsOnlinePayments = false;

  @Of(() => Boolean)
  eFacturaActive: boolean = false;

  @Of(() => SpvActiveType, { optional: true })
  spvActive?: SpvActiveType;

  @Of(() => EfacturaIssueType, { optional: true })
  eFacturaIssueType?: EfacturaIssueType;

  static of = Entity.of<CreateBillingProfileResponse>();
}
