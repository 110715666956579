import { Entity, Of } from 'entity-of';

@Entity
export class ChargeSearchInputSummary {
  @Of(() => Number)
  serviceId: number = 0;

  @Of(() => Number)
  limit: number = 0;

  @Of(() => Number)
  offset: number = 0;

  @Of(() => String)
  billableAccountId: string = '';

  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';
  static of = Entity.of<ChargeSearchInputSummary>();
}
