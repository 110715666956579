import { VatCategory, VatExemptionCode } from '@modules/shared/utils/eFactura';
import { Entity, Of } from 'entity-of';

import { Discount } from '../Discount';

export enum InvoiceItemType {
  SERVICE = 'service',
  DISCOUNT = 'discount',
}

@Entity
export class FullDetailedCharge {
  @Of(() => String)
  serviceName = '';

  @Of(() => String)
  serviceInternalCode = '';

  @Of(() => Number)
  serviceId = 0;

  @Of(() => String)
  assignedServiceInvoiceDetails = '';

  @Of(() => Number)
  unitValue = 0;

  @Of(() => Number)
  netAmount = 0;

  @Of(() => Number)
  vatAmount = 0;

  @Of(() => Number)
  totalAmount = 0;

  @Of(() => Number, { optional: true })
  vatValue?: number;

  @Of(() => String, { optional: true })
  vatName?: string;

  @Of(() => VatCategory, { optional: true })
  vatCategory?: VatCategory;

  @Of(() => VatExemptionCode, { optional: true })
  vatExemptionCode?: VatExemptionCode;

  @Of(() => String, { optional: true })
  vatExemptionReason?: string;

  @Of(() => Number)
  originalUnitValue: number = 0;

  @Of(() => String)
  originalCurrency: string = '';

  @Of(() => Number)
  assignedServiceId = 0;

  @Of(() => Boolean)
  isOneTimePayment = false;

  @Of(() => Number)
  count = 0;

  @Of(() => String)
  currency = '';

  @Of(() => String)
  periodStart = '';

  @Of(() => String)
  periodEnd = '';

  @Of(() => Boolean)
  isPenalty = false;

  @Of(() => Boolean)
  isPendingCharge? = false;

  @Of(() => Boolean)
  isOtherAccountProfile? = false;

  @Of(() => [Number])
  chargesIds?: number[] = [];

  @Of(() => String)
  type: InvoiceItemType = InvoiceItemType.SERVICE;

  @Of(() => String, { optional: true })
  discountsItemId?: string;

  @Of(() => String, { optional: true })
  invoiceItemId?: string;

  @Of(() => Discount, { optional: true })
  discount?: Discount;

  static of = Entity.of<FullDetailedCharge>();
}
