import { IsNotEmpty, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class AvailablePackagesInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.countryId.required' })
  countryId: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.subscriberType.required' })
  subscriberType: 'organization' | 'student' = 'student';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.organizationId.required' })
  @ValidateIf((o: AvailablePackagesInput) => !o.countryId)
  orgId: string = '';

  static of = Entity.of<AvailablePackagesInput>();
}
