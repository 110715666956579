import {
  IsNotEmpty,
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
} from 'class-validator';
import { Entity, Of } from 'entity-of';

import { InvoiceType } from '../../Invoice';

@Entity
export class CreateTenantNumberSeriesInput {
  @Of(() => String, { optional: true })
  id?: number;

  @Of(() => Number, { optional: true })
  tenantBillingProfileId?: number;

  @Of(() => Number, { nullable: true })
  @IsNotEmpty({ message: 'errors.value.required' })
  startNumber: number | null = null;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.invoiceSerial.required' })
  @CheckUniqueSeries({ message: 'errors.invoiceSerial.unique' })
  serial: string = '';

  @Of(() => Boolean)
  @IsNotEmpty({ message: 'errors.invoiceImplicit.required' })
  isImplicit: boolean = false;

  @Of(() => Number, { optional: true })
  number?: number;

  @Of(() => InvoiceType)
  invoiceType = '';

  static of = Entity.of<CreateTenantNumberSeriesInput>();
}

function CheckUniqueSeries(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'checkUniqueSeries',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: string, args: ValidationArguments) {
          const currentSeries = (args.object as any).currentSeries;

          if (value) {
            return !currentSeries.includes(value);
          }
          return true;
        },
      },
    });
  };
}
