import { FEATURE_FLAGS } from '@constants';
import { AccessRules } from '@lib/utils/session/hasAccess';
import { IconNames } from '@ui2/components';
import { Entity, Of } from 'entity-of';

@Entity
export class LinkButtonItem {
  @Of(() => String, { optional: true })
  icon?: IconNames;

  @Of(() => String)
  label: string = '';

  @Of(() => String, { optional: true })
  url?: string;

  @Of(() => [String], { optional: true })
  matchingUrls?: string[] = [];

  @Of(() => [LinkButtonItem], { optional: true })
  subitems?: LinkButtonItem[];

  @Of(() => Boolean)
  hasTopDivider?: boolean = false;

  @Of(() => Boolean)
  hasBottomDivider?: boolean = false;

  @Of(() => AccessRules, { optional: true })
  accessRules?: AccessRules;

  @Of(() => String)
  featureFlag?: FEATURE_FLAGS;

  @Of(() => String)
  redirectRule: 'push' | 'window' | 'blank' = 'push';

  @Of(() => Boolean)
  isNew: boolean = false;

  static of = Entity.of<LinkButtonItem>();
}
