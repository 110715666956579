import React, { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

import { ResponsiveValue } from '@chakra-ui/react';
import { useOnClickOutside } from '@ui2/hooks';
import { getOrgTypeTheme } from '@ui2/utils';

import { AdservioIcons, Box, Button, Divider, IconButton } from '../../atoms';

const IconButtonSizeDict = {
  sm: 'xs',
  md: 'sm',
  lg: 'md',
};

interface InputGroupRightElementProps {
  onToggle: () => void;
  onClear?: () => void;
  size: ResponsiveValue<string>;
}

export const InputGroupRightElement = ({
  onToggle,
  onClear,
  size,
}: InputGroupRightElementProps) => {
  const baseColor = getOrgTypeTheme('blue');

  return (
    <>
      {onClear && (
        <IconButton
          size={IconButtonSizeDict[String(size)]}
          variant="ghost"
          colorScheme={baseColor}
          onClick={onClear}
          aria-label="Toggle time picker options"
          icon={<AdservioIcons.FiX color={`${baseColor}.500`} />}
          mr="3"
        />
      )}

      <Divider
        position="relative"
        orientation="vertical"
        height="65%"
        borderColor="gray.200"
        right={'0.45rem'}
      />

      <IconButton
        size={IconButtonSizeDict[String(size)]}
        variant="ghost"
        colorScheme={baseColor}
        onClick={onToggle}
        aria-label="Toggle time picker options"
        icon={<AdservioIcons.FiClock color={`${baseColor}.500`} />}
        mr="3"
      />
    </>
  );
};

interface UnitsWrapperProps {
  parentRef: React.RefObject<HTMLDivElement>;
  menuPortalTarget?: HTMLElement | null;
  onClose: () => void;
}

export const UnitsWrapper = ({
  children,
  parentRef,
  menuPortalTarget,
  onClose,
}: PropsWithChildren<UnitsWrapperProps>) => {
  const portalRef = React.useRef<HTMLDivElement>(null);

  useOnClickOutside([parentRef, portalRef], () => onClose());

  const menu = (
    <Box
      backgroundColor="white"
      p="4"
      mt="2"
      position="absolute"
      width="100%"
      minWidth="10rem"
      borderRadius="lg"
      boxShadow="3xl"
      zIndex="dropdown"
    >
      {children}
    </Box>
  );

  const portalWrapper = (
    <Box
      ref={portalRef}
      position="absolute"
      top={
        (parentRef.current?.getBoundingClientRect().top || 0) +
        (parentRef.current?.clientHeight || 0)
      }
      left={parentRef.current?.getBoundingClientRect().left + 'px'}
      width={parentRef.current?.clientWidth}
      zIndex="9999"
    >
      {menu}
    </Box>
  );

  return <>{menuPortalTarget ? createPortal(portalWrapper, menuPortalTarget) : menu}</>;
};

export const UnitScrollBox = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      maxHeight="16rem"
      overflowY="scroll"
      css={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      {children}
    </Box>
  );
};

export const UnitsDivider = () => {
  return (
    <Box px="4">
      <Divider orientation="vertical" height="100%" borderColor="gray.200" />
    </Box>
  );
};

interface TimeButtonProps {
  value: string;
  onSetValue: (value: string) => void;
  isActive?: boolean;
}

export const TimeButton = ({ value, onSetValue, isActive }: TimeButtonProps) => {
  const baseColor = getOrgTypeTheme('blue');

  return (
    <Button
      className={isActive ? 'activeUnit' : ''}
      variant="ghost"
      colorScheme={baseColor}
      width="full"
      backgroundColor={isActive ? `${baseColor}.500` : 'initial'}
      color={isActive ? 'white' : 'gray.700'}
      _hover={{ backgroundColor: isActive ? `${baseColor}.500` : 'gray.50' }}
      onClick={() => onSetValue(value)}
    >
      {value}
    </Button>
  );
};
