import { IsEmail, IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class UpdateProfileSpvTokenInput {
  @Of(() => String)
  @IsEmail(undefined, { message: 'errors.email.isValid' })
  @IsNotEmpty({ message: 'errors.email.required' })
  email: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.naming.required' })
  alias: string = '';

  static of = Entity.of<UpdateProfileSpvTokenInput>();
}
