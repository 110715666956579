import { registerDecorator, ValidationArguments } from 'class-validator';
import { t } from 'i18next';

export function MaxLengthDynamic(value: number) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'maxLengthDynamic',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [value],
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [maxLength] = args.constraints;

          if (maxLength < String(value).length) {
            return false;
          }

          return true;
        },
        defaultMessage(args: ValidationArguments) {
          const [maxLength] = args.constraints;

          return t('shared:errors.max_length_dynamic', { 0: maxLength });
        },
      },
    });
  };
}
