import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class ClassStudentsInput extends SimpleQueryInput {
  @Of(() => String)
  clID: string = '';

  static of = Entity.of<ClassStudentsInput>();
}
