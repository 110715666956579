import {
  Accommodation,
  AccommodationStatus,
  PricePlan,
  Room,
  Student,
  StudentStudy,
  Tax,
} from '@modules/Universities/accommodations/entities';
import { IsNotEmpty, IsPositive } from 'class-validator';
import { Entity, Of } from 'entity-of';

export type InvoiceStatus = 'unpaid' | 'paid' | 'partialPaid' | 'cancelled';
export const invoicesStatuses = ['unpaid', 'paid', 'partialPaid', 'cancelled'] as const;
export const publishStatuses = ['sent', 'unsent'] as const;

@Entity
export class Invoice {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  studentId: string = '';

  @Of(() => String)
  studentStudyId: string = '';

  @Of(() => String)
  pricePlanId: string = '';

  @Of(() => String)
  roomId: string = '';

  @Of(() => String)
  accommodationId: string = '';

  @Of(() => String)
  taxId: string = '';

  @Of(() => String)
  periodStart: string = '';

  @Of(() => String)
  periodEnd: string = '';

  @Of(() => Number)
  days: number = 0;

  @Of(() => String)
  amount: string = '';

  @Of(() => String)
  discount: string = '';

  @Of(() => String)
  amountToPay: string = '';

  @Of(() => String)
  paid: string = '';

  @Of(() => String)
  status: InvoiceStatus = 'unpaid';

  @Of(() => String)
  afterPaymentStatus: InvoiceStatus = 'unpaid';

  @Of(() => String)
  observations: string = '';

  @Of(() => String)
  cancelledAt: string = '';

  @Of(() => String)
  cancelledBy: string = '';

  @Of(() => String)
  firstName: string = '';

  @Of(() => String)
  lastName: string = '';

  @Of(() => Number)
  sold: number = 0;

  @Of(() => Boolean)
  isRequestOverdue: boolean = false;

  @Of(() => String, { optional: true })
  soldStatus?: AccommodationStatus;

  @Of(() => Accommodation)
  accommodation: Accommodation = Accommodation.of({});

  @Of(() => Student, { nullable: true })
  student: Student | null = null;

  @Of(() => Tax, { nullable: true })
  tax?: Tax | null = null;

  @Of(() => StudentStudy, { nullable: true })
  studentStudy: StudentStudy | null = null;

  @Of(() => Room)
  room: Room = Room.of({});

  @Of(() => PricePlan, { nullable: true })
  pricePlan: PricePlan | null = null;

  @Of(() => String, { nullable: true })
  publishedAt: string | null = null;

  @Of(() => Users, { nullable: true })
  user: Users | null = null;

  @Of(() => Boolean)
  unpaidAmount: boolean = false;

  static of = Entity.of<Invoice>();
}

@Entity
export class Users {
  @Of(() => Number)
  id?: number = 0;

  @Of(() => String)
  username?: string;

  @Of(() => String)
  firstName?: string;

  @Of(() => String)
  lastName?: string;

  static of = Entity.of<Users>();
}

@Entity
export class PaginatedInvoiceInput {
  @Of(() => Number)
  size?: number = 10;

  @Of(() => Number)
  page?: number = 1;

  @Of(() => String)
  sortField?: string;

  @Of(() => String, { optional: true })
  direction?: 'asc' | 'desc';

  @Of(() => String, { optional: true })
  studentId?: string;

  @Of(() => String, { optional: true })
  facultyId?: string;

  @Of(() => String, { optional: true })
  studentTypeId?: string;

  @Of(() => String, { optional: true })
  studyTypeYear?: string;

  @Of(() => String, { optional: true })
  pricePlanId?: string;

  @Of(() => [String], { optional: true })
  taxes: string[] = [];

  @Of(() => [String], { optional: true })
  dorms: string[] = [];

  @Of(() => [String], { optional: true })
  rooms: string[] = [];

  @Of(() => String, { optional: true })
  invoiceStatus: 'unpaid' | null = null;

  @Of(() => [String], { optional: true })
  statuses?: InvoiceStatus[];

  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => Number, { optional: true })
  paymentAmount?: number;

  @Of(() => String, { optional: true })
  publishStatus?: 'sent' | 'unsent';

  @Of(() => String, { optional: true })
  accommodationStatuses?: AccommodationStatus;

  @Of(() => String, { optional: true })
  publishedAtStart?: string;

  @Of(() => String, { optional: true })
  publishedAtEnd?: string;

  static of = Entity.of<PaginatedInvoiceInput>();
}

@Entity
export class InvoiceInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.invoice.student' })
  studentId: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.invoice.dorm' })
  dormId: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.invoice.room' })
  roomId: string | null = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.invoice.tax' })
  taxId: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.invoice.start_date' })
  periodStart: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.invoice.end_date' })
  periodEnd: string = '';

  @Of(() => Number)
  @IsPositive({ message: 'errors.accommodations.invoice.amount.required' })
  amount: number | null = null;

  @Of(() => Number, { nullable: true })
  discount: number | null = null;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.invoice.observations' })
  observations: string = '';

  static of = Entity.of<InvoiceInput>();
}

@Entity
export class InvoiceUpdateInput {
  @Of(() => Number)
  amount: number | null = null;

  @Of(() => Number, { nullable: true })
  discount: number | null = null;

  @Of(() => String)
  periodStart: string = '';

  @Of(() => String)
  periodEnd: string = '';

  static of = Entity.of<InvoiceUpdateInput>();
}

@Entity
export class InvoicePublishInput {
  @Of(() => [String])
  invoices: string[] = [];

  static of = Entity.of<InvoicePublishInput>();
}
