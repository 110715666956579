import { Vectors } from '@ui2/assets';
import { Center, FullpageWizard, Image, Text, VStack } from '@ui2/components';

interface MaintenanceProps {
  message: string;
}

export const Maintenance = ({ message }: MaintenanceProps) => {
  return (
    <FullpageWizard
      renderContent={() => (
        <Center w="full">
          <VStack gap={8} w="full" p="4">
            <Image src={Vectors.Maintenance} alt="maintenance" />
            <VStack w="full" spacing={0}>
              {message.split('\n').map((line, index) => {
                if (!line) {
                  return <br key={index} />;
                }
                return (
                  <Text
                    fontWeight="bold"
                    textAlign="center"
                    fontSize="xl"
                    color="gray.700"
                    key={index}
                  >
                    {line}
                  </Text>
                );
              })}
            </VStack>
          </VStack>
        </Center>
      )}
      title={[]}
      stepsCount={0}
    />
  );
};
