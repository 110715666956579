import { Entity, Of } from 'entity-of';

@Entity
export class FamilySituationReportInput {
  @Of(() => String)
  ascID: string = '';

  @Of(() => String, { optional: true })
  cicluID?: string;

  @Of(() => String, { optional: true })
  astID?: string;

  @Of(() => String, { optional: true })
  clID?: string;

  @Of(() => Number, { optional: true })
  _limit?: number;

  @Of(() => String, { optional: true })
  _order?: string;

  static of = Entity.of<FamilySituationReportInput>();
}
