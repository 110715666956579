import { useTranslation } from 'react-i18next';

import { Person, Student } from '@api/core/entities';
import {
  TYPE_ADMIN,
  TYPE_CADRU,
  TYPE_CANDIDATE,
  TYPE_ELEV,
  TYPE_PARINTE,
  TYPE_PERS_INST,
} from '@constants';
import { Box, FlexProps, Grid, Text } from '@ui2/components';

import {
  MainContainer,
  ProfileAvatar,
  ProfileDetailsSection,
  ProfileName,
  ProfileRole,
} from './ProfileDetails.layout';

interface ProfileDetailsProps extends FlexProps {
  size?: 'sm' | 'md';
  roleSize?: 'sm' | 'md';
  profile: Person;
  student?: Student;
  useRoleTag?: boolean;
  isLoading?: boolean;
  isAccountCard?: boolean;
  showSchoolDetails?: boolean;
  hideDetailsOnMobile?: boolean;
}

export const ProfileDetails = ({
  showSchoolDetails,
  hideDetailsOnMobile,
  profile,
  student,
  roleSize,
  useRoleTag,
  isLoading,
  isAccountCard,
  size = 'md',
  ...props
}: ProfileDetailsProps) => {
  const { t } = useTranslation(['Site', 'common']);

  let roles = '';
  let schoolDetails = '';

  switch (profile.uaTip) {
    case TYPE_ADMIN:
      roles = t('common:text_admin');
      break;
    case TYPE_CANDIDATE:
      roles = t('common:text_candidate');
      break;
    case TYPE_PERS_INST:
      roles = profile?.institutie?.instNume;
      break;
    case TYPE_PARINTE:
    case TYPE_ELEV:
    case TYPE_CADRU:
      roles = profile?.roles
        ?.filter((role) => {
          return role.roleVisibleInTitle === '1';
        })
        .map((role) => role.roleName)
        .join(', ');
      break;
  }

  switch (profile.uaTip) {
    case TYPE_ELEV:
    case TYPE_PARINTE:
    case TYPE_CADRU:
      schoolDetails = `${profile?.liceu?.liceuClasificare} ${profile?.liceu?.liceuNume}, ${profile?.liceu?.cityFullName}`;
      break;
    case TYPE_PERS_INST:
      schoolDetails = profile?.institutie?.instNume;
      break;
  }

  let name = [profile.uaLastName, profile.uaFirstName]
    .filter((x) => !!x)
    .join(' ')
    .trim();

  let studentName = [student?.elevPrenume, student?.elevNume]
    .filter((x) => !!x)
    .join(' ')
    .trim();

  let displayName = name;

  if (isAccountCard && profile.uaTip === TYPE_PARINTE) {
    displayName = '';
  }

  return (
    <MainContainer alignItems="center" hideDetailsOnMobile={hideDetailsOnMobile} {...props}>
      <Box my="1">
        <ProfileAvatar size={size} name={name} avatar={profile?.prefAvatar} />
      </Box>

      <Box display={{ base: hideDetailsOnMobile ? 'none' : 'block', sm: 'block' }}>
        <ProfileDetailsSection>
          {(displayName || roles) && (
            <Box>
              {displayName && <ProfileName size={size}>{displayName}</ProfileName>}

              {roles &&
                (profile.uaTip !== TYPE_PERS_INST ||
                  (profile.uaTip === TYPE_PERS_INST && !isAccountCard)) && (
                  <ProfileRole fontSize={roleSize ?? size} useTag={useRoleTag}>
                    {roles}
                  </ProfileRole>
                )}
            </Box>
          )}

          {isAccountCard && profile.uaTip === TYPE_PARINTE && (
            <Grid templateColumns="auto 1fr" columnGap="1" alignItems="center">
              <ProfileAvatar avatar={student?.elevAvatar} name={studentName} size="xs" />

              <Text fontWeight="semibold" fontSize="xs" lineHeight="1.125rem" noOfLines={1}>
                {studentName}
              </Text>
            </Grid>
          )}

          {showSchoolDetails && (
            <Text fontWeight="normal" fontSize="xs" lineHeight="1.125rem">
              {schoolDetails}
            </Text>
          )}
        </ProfileDetailsSection>
      </Box>
    </MainContainer>
  );
};
