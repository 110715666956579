import { Entity, Of } from 'entity-of';

@Entity
export class Role {
  @Of(() => String)
  roleID = '';

  @Of(() => String)
  roleIsAdministrative = '';

  @Of(() => String)
  roleIsImplicit = '';

  @Of(() => String)
  roleIsImplicitAst = '';

  @Of(() => String)
  roleName = '';

  @Of(() => String)
  roleUaTip = '';

  @Of(() => String)
  roleVisibleInTitle = '1';

  static of = Entity.of<Role>();
}
