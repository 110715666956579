import { useTranslation } from 'react-i18next';

import { ORG_UNIVERSITY } from '@constants';
import { useStorageSession } from '@lib/utils';
import { AdservioIcons, Grid } from '@ui2/components';
import Cookies from 'js-cookie';

import { MenuItemDivider, UserMenuItem } from '../UserMenu/UserMenu.layout';

interface HeadmasterTeacherProps {
  isTeacherView: boolean;
}

export const HeadmasterTeacher = ({ isTeacherView }: HeadmasterTeacherProps) => {
  const { t, i18n } = useTranslation(['Site', 'common']);

  const { sessionHelpers, orgType } = useStorageSession();

  const switchMeniuProfesor = (MPR: boolean) => {
    if ((MPR && isTeacherView) || (!MPR && !isTeacherView)) {
      return;
    }

    if (MPR) {
      Cookies.set('MPR', '1');
    } else {
      Cookies.set('MPR', '0');
    }

    window.location.assign(`/${i18n.language}/dashboard`);
  };

  return (
    <>
      <Grid templateColumns="1fr" rowGap="2">
        {sessionHelpers.isDirector && (
          <UserMenuItem
            isActive={!isTeacherView}
            icon={<AdservioIcons.FiBriefcase />}
            onClick={() => switchMeniuProfesor(false)}
          >
            {orgType === ORG_UNIVERSITY ? t('header.manager') : t('header.director')}
          </UserMenuItem>
        )}

        {!sessionHelpers.isDirector && sessionHelpers.isSecretary && (
          <UserMenuItem
            isActive={!isTeacherView}
            icon={<AdservioIcons.FiFolder />}
            onClick={() => switchMeniuProfesor(false)}
          >
            {t('header.secretariat')}
          </UserMenuItem>
        )}

        {sessionHelpers.isTeacher && (
          <UserMenuItem
            isActive={isTeacherView}
            icon={<AdservioIcons.Teacher />}
            onClick={() => switchMeniuProfesor(true)}
          >
            {t('header.teacher')}
          </UserMenuItem>
        )}
      </Grid>

      <MenuItemDivider />
    </>
  );
};
