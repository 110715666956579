import { PropsWithChildren } from 'react';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useSession } from '@lib/utils';
import {
  AdservioIcons,
  Button,
  FeatureIcon,
  Flex,
  HStack,
  IconButton,
  Image,
  ProgressBar,
  Text,
  VStack,
} from '@ui2/components';
import { getOrgTypeTheme } from '@ui2/utils';

import { useFileIcon } from './useFileUploaderIcon';

interface EmptyStateProps {
  description?: string;
}

export const EmptyState = ({ description }: EmptyStateProps) => {
  const { t } = useTranslation('shared');

  return (
    <VStack w="full" spacing="4" p="6">
      <FeatureIcon name="FiUploadCloud" size="lg" variant="default" isBorderless />
      <VStack w="full" spacing="1">
        <Text color="gray.700" fontWeight="semibold" fontSize="sm">
          {t('general.click_or_drag')}
        </Text>
        {description && (
          <Text color="gray.400" fontSize="sm">
            {description}
          </Text>
        )}
      </VStack>
    </VStack>
  );
};
interface FileCardContainerProps {
  status: 'loading' | 'error' | 'success' | 'idle';
  onDrop?: (e: any) => void;
  isDisabled?: boolean;
  hasErrors?: boolean;
  name: string;
  withStyling?: boolean;
}

export const FileCardContainer = forwardRef<
  HTMLLabelElement,
  PropsWithChildren<FileCardContainerProps>
>(({ children, isDisabled, onDrop, hasErrors, name, status, withStyling }, ref) => {
  const baseColor = getOrgTypeTheme('blue');

  const extraStyles = withStyling
    ? {
        _hover: {
          boxShadow:
            hasErrors || status === 'error' ? 'inputInvalid' : isDisabled ? 'unset' : 'inputFocus',
          borderColor:
            isDisabled || hasErrors || status === 'error' ? 'default' : `${baseColor}.300`,
        },
        _focus: {
          boxShadow:
            hasErrors || status === 'error' ? 'inputInvalid' : isDisabled ? 'unset' : 'inputFocus',
          borderColor: isDisabled ? 'none' : `${baseColor}.300`,
        },
        borderColor: isDisabled
          ? 'gray.200'
          : hasErrors || status === 'error'
            ? 'red.500'
            : 'gray.200',
        bg: isDisabled ? 'gray.50' : 'white',
        transitionProperty: 'common',
        transitionDuration: 'normal',
        cursor: 'pointer',
        borderWidth: '1px',
        borderRadius: 'xl',
        outline: 'unset',
        boxShadow: 'xs',
        p: '2',
      }
    : {};

  return (
    <Flex
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={(e) => e.preventDefault()}
      onDragLeave={(e) => e.preventDefault()}
      aria-label="select file"
      cursor="pointer"
      onDrop={onDrop}
      htmlFor={name}
      as="label"
      ref={ref}
      {...extraStyles}
    >
      {children}
    </Flex>
  );
});

interface FileCardProps {
  status: 'loading' | 'error' | 'success' | 'idle';
  handleRemoveFile: () => void;
  handleCardClick: (e) => void;
  hideProgressBar?: boolean;
  handleRetry?: () => void;
  showDownload?: boolean;
  hideRemoveButton?: boolean;
  file: { name?: string; size?: number; type?: string } | null;
  progress: number;
  fsId: string;
  fileError?: string;
  hideRetryButton?: boolean;
}

export const FileCard = ({
  handleRemoveFile,
  handleCardClick,
  hideProgressBar,
  handleRetry,
  showDownload,
  hideRemoveButton,
  fileError,
  progress,
  status,
  fsId,
  file,
  hideRetryButton,
}: FileCardProps) => {
  const { getFileIcon } = useFileIcon();

  const onRemove = (e) => {
    handleRemoveFile();
    e.stopPropagation();
    e.preventDefault();
  };

  const convertBytes = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) {
      return '0 Byte';
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const onRetry = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemove(e);
    handleRetry?.();
  };

  return (
    <HStack
      justifyContent="flex-start"
      onClick={handleCardClick}
      alignItems="flex-start"
      spacing="5"
      w="full"
      py="2"
      pl="4"
      pr="2"
      bg="white"
      transitionProperty="common"
      transitionDuration="normal"
      aria-label="select file"
      borderWidth="1px"
      borderRadius="xl"
      outline="unset"
      boxShadow="xs"
      position="relative"
    >
      <Image src={getFileIcon(file?.type ?? '')} h="12" alignSelf="center" my="2" />

      <VStack
        w="full"
        h="full"
        mr="2"
        my="2"
        alignItems="flex-start"
        spacing="0"
        justifyContent="center"
      >
        {fileError && (
          <Text color="red.500" fontWeight="semibold" fontSize="sm">
            {fileError}
          </Text>
        )}

        {!fileError && (
          <>
            <Text
              color="gray.700"
              fontWeight="semibold"
              flexWrap={'wrap'}
              overflowWrap={'break-word'}
              maxW="100%"
            >
              {file?.name}
            </Text>

            {status === 'loading' && !hideProgressBar && (
              <ProgressBar value={progress} withProgressText />
            )}

            {status === 'success' && (
              <Text color="gray.400">{convertBytes(Number(file?.size))}</Text>
            )}

            {status === 'error' && file?.size && (
              <Text color="gray.400">{convertBytes(Number(file?.size))}</Text>
            )}
          </>
        )}
      </VStack>

      <HStack alignItems="center" justifyContent="center" spacing="1">
        {status === 'error' && !hideRetryButton && <RetryButton onClick={onRetry} />}
        {status === 'success' && showDownload && <DownloadButton fsId={fsId} />}
        {status === 'loading' && <CancelButton onClick={onRemove} />}
        {status !== 'loading' && !hideRemoveButton && <RemoveButton onClick={onRemove} />}
        {status === 'error' && hideRemoveButton && <RemoveButton onClick={onRemove} />}
      </HStack>
    </HStack>
  );
};

const CancelButton = ({ onClick }) => {
  return (
    <IconButton
      icon={<AdservioIcons.FiX />}
      aria-label={'delete file'}
      onClick={onClick}
      colorScheme="gray"
      variant="ghost"
    />
  );
};

const RemoveButton = ({ onClick }) => {
  return (
    <IconButton
      icon={<AdservioIcons.FiTrash2 />}
      aria-label={'delete file'}
      onClick={onClick}
      colorScheme="red"
      variant="ghost"
    />
  );
};

const DownloadButton = ({ fsId }: { fsId: string }) => {
  const { rootOrgId } = useSession();
  return (
    <IconButton
      href={`/api/v2/fisiere/download?fsID=${fsId}&_liceuID=${rootOrgId}`}
      icon={<AdservioIcons.FiDownload />}
      aria-label={'download file'}
      onClick={(e) => {
        e.stopPropagation();
      }}
      colorScheme="gray"
      variant="ghost"
      as="a"
    />
  );
};
const RetryButton = ({ onClick }) => {
  const { t } = useTranslation('shared');
  return (
    <Button colorScheme="gray" variant="link" onClick={onClick}>
      {t('general.retry')}
    </Button>
  );
};
