import { Entity, Of } from 'entity-of';

export enum CadStatus {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  DELETED = 'deleted',
}

@Entity
export class PersonnelFiltersInput {
  @Of(() => String, { optional: true })
  searchTerm?: string = '';

  @Of(() => String, { optional: true })
  roleID?: string = '';

  @Of(() => String, { optional: true })
  cadCnp?: string = '';

  @Of(() => CadStatus, { optional: true })
  cadStatus?: CadStatus;

  @Of(() => String, { optional: true })
  cadID?: string = '';

  static of = Entity.of<PersonnelFiltersInput>();
}
