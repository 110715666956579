import { Entity, Of } from 'entity-of';

@Entity
export class ProgramsSeriesInput {
  @Of(() => Number)
  studyYearId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  static of = Entity.of<ProgramsSeriesInput>();
}
