import { PhoneWithPrefix } from '@api/core/entities/shared';
import { PhoneNumberWithPrefix } from '@lib/validators';
import { Type } from 'class-transformer';
import { IsEmail, IsNotEmpty, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class SchoolSettingsFormInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.schoolName.required' })
  liceuNume: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.classification.required' })
  liceuClasificare: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.type.required' })
  liceuTip: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.country.required' })
  taraID: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.county.required' })
  judetID: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.locality.required' })
  cityID: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.address.required' })
  liceuStrada: string = '';

  @Of(() => String)
  @ValidateIf((o) => !!o.liceuEmail)
  @IsEmail(undefined, { message: 'errors.email.isValid' })
  liceuEmail: string = '';

  @Of(() => PhoneWithPrefix)
  @Type(() => PhoneWithPrefix)
  @ValidateIf((f) => !!(f.liceuTel.phone || f.liceuTel.prefix))
  @PhoneNumberWithPrefix({ message: 'errors.phoneWithPrefix.invalid' })
  liceuTel: PhoneWithPrefix = PhoneWithPrefix.of({});

  @Of(() => String)
  liceuCUI: string = '';

  @Of(() => String)
  liceuCodSirues: string = '';

  @Of(() => String)
  liceuCodSiiir: string = '';

  @Of(() => String)
  liceuDirectorID: string = '';

  @Of(() => String)
  liceuSecretarID: string = '';

  @Of(() => Boolean)
  liceuVocational: boolean = false;

  @Of(() => Boolean)
  liceuTehnic: boolean = false;

  @Of(() => String)
  liceuLogo: string = '';

  static of = Entity.of<SchoolSettingsFormInput>();
}

@Entity
export class AdminSchoolSettingsFormInput extends SchoolSettingsFormInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.language.required' })
  defaultLanguage: string = '';

  @Of(() => String)
  liceuObservatii: string = '';

  @Of(() => [String])
  labels: string[] = [];

  @Of(() => Boolean)
  liceuIsHidden: boolean = false;

  static of = Entity.of<AdminSchoolSettingsFormInput>();
}
