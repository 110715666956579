import { useRef } from 'react';

import { Person } from '@api/core/entities';
import { PersonExcerpt } from '@modules/shared';
import { AdservioIcons } from '@ui2/components';
import { getOrgTypeTheme } from '@ui2/utils';
import { useKey } from 'rooks';

import { PersonCardContainer } from './PersonCard.layout';

interface PersonCardProps {
  person: Person;
  isLoading?: boolean;
  useRoleTag?: boolean;
  onClick?: (value: Person) => void;
  testId?: string;
  hideArrow?: boolean;
}

export const PersonCard = ({
  person,
  onClick,
  isLoading,
  useRoleTag,
  testId,
  hideArrow,
}: PersonCardProps) => {
  const baseColor = getOrgTypeTheme('blue');

  const containerRef = useRef(null);

  const handleClick = () => {
    onClick?.(person);
  };

  useKey(['Enter'], handleClick, { target: containerRef });

  return (
    <PersonCardContainer ref={containerRef} onClick={handleClick} testId={testId}>
      <PersonExcerpt person={person} detailsConfig={{ useRoleTag }} isLoading={isLoading} />

      {!hideArrow && (
        <AdservioIcons.FiChevronRight justifySelf="flex-end" color={`${baseColor}.500`} />
      )}
    </PersonCardContainer>
  );
};
