import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

import { ServiceRequest } from '..';

@Entity
export class ServiceRequest_Person extends ServiceRequest {
  @Of(() => Person)
  person: Person = Person.of({});

  static of = Entity.of<ServiceRequest_Person>();
}
