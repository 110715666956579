// @ts-nocheck
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const ZendeskFullScreen = () => {
  useEffect(() => {
    // window.zE('webWidget', 'show');
    // window.zE('webWidget', 'open');
  }, []);

  return (
    <Helmet>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=34253eb0-f339-4571-b90b-043c766cafc6"
      />
      <script src="/rjs/zendesk.js"> </script>
    </Helmet>
  );
};

ZendeskFullScreen.propTypes = {};

ZendeskFullScreen.defaultProps = {};

export default ZendeskFullScreen;
