import { useTranslation } from 'react-i18next';

import { CONFIG_FLAGS, PERM_LIBRARY_MANAGER, PERM_LIBRARY_VIEW } from '@constants';
import { useRouter } from '@lib/router';
import { LinkButtonItem, LinkButtonsGroup } from '@modules/shared';

export const useAuxiliaryLinkGroups = () => {
  const { lang } = useRouter();
  const { t } = useTranslation(['Site', 'common']);

  const groups: LinkButtonsGroup[] = [
    {
      items: [
        LinkButtonItem.of({
          icon: 'FiHome',
          label: t('buttons_mm.dashboard'),
          url: 'dashboard',
        }),
        LinkButtonItem.of({
          icon: 'FiBell',
          label: t('buttons_mm.noutati'),
          url: 'news',
          accessRules: {
            configFlags: [CONFIG_FLAGS.NEWS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'Portofolio',
          label: t('buttons_mm.portofoliu'),
          url: 'teacher/other-activities',
          accessRules: {
            configFlags: [CONFIG_FLAGS.SCHOOL_STAFF_ACTIVITIES_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiMail',
          label: t('buttons_mm.mesaje'),
          url: `messages`,
          matchingUrls: ['messages/sent', 'messages/drafts', 'messages/archive'],
        }),
        LinkButtonItem.of({
          icon: 'Groups',
          label: t('buttons_mm.groups'),
          url: `groups`,
          matchingUrls: ['groups/others'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.GROUPS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiFile',
          label: t('buttons_mm.fisiere'),
          url: `files`,
          accessRules: { configFlags: [CONFIG_FLAGS.FILES_ACTIVE] },
        }),
        LinkButtonItem.of({
          icon: 'FiCalendar',
          label: t('buttons_mm.calendar'),
          url: `calendar`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.CALENDAR_ACTIVE],
          },
        }),
      ],
    },
    {
      emptyTitle: true,
      items: [
        LinkButtonItem.of({
          icon: 'FiImage',
          label: t('buttons_mm.foto'),
          matchingUrls: ['foto', 'anuar'],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.foto'),
              url: `foto`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.yearbook'),
              url: `anuar`,
              accessRules: {
                configFlags: [CONFIG_FLAGS.ANUAR_ACTIVE],
              },
            }),
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.PHOTOS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'Library',
          label: t('buttons_mm.biblioteca'),
          url: `library`,
          matchingUrls: ['library/manage'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.LIBRARY_ACTIVE],
            permissions: [PERM_LIBRARY_MANAGER, PERM_LIBRARY_VIEW],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiHelpCircle',
          label: t('buttons_mm.support'),
          url:
            lang === 'ro'
              ? 'https://manual.adservio.ro/web/profesor-invatator-educator/'
              : 'https://manual.adservio.ro/web/en/teacher-primary-teacher-educator/',
          redirectRule: 'blank',
          hasTopDivider: true,
        }),
      ],
    },
  ];

  return {
    groups,
  };
};
