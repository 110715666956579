import { Entity, Of } from 'entity-of';

import { Accommodation, Student, User } from '..';

@Entity
export class Payment {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  studentId: string = '';

  @Of(() => String)
  paymentMethodId: string = '';

  @Of(() => Number)
  amount: number = 0;

  @Of(() => String)
  documentNumber: string = '';

  @Of(() => String)
  paymentDate: string = '';

  @Of(() => String)
  createdAt: string = '';

  @Of(() => [String])
  observations: string[] = [];

  @Of(() => PaymentType, { nullable: true })
  paymentType: PaymentType | null = null;

  @Of(() => PaymentMethod, { nullable: true })
  paymentMethod: PaymentMethod | null = null;

  @Of(() => Student, { nullable: true })
  student: Student | null = null;

  @Of(() => User, { nullable: true })
  user: User = User.of({});

  @Of(() => Accommodation)
  accommodation: Accommodation = Accommodation.of({});

  @Of(() => [Boolean])
  isTotal: boolean = false;

  static of = Entity.of<Payment>();
}

@Entity
export class PaginatedPaymentInput {
  @Of(() => Number)
  size?: number = 10;

  @Of(() => Number)
  page?: number = 1;

  @Of(() => String, { optional: true })
  sortField?: string;

  @Of(() => String, { optional: true })
  direction?: 'asc' | 'desc' = 'asc';

  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => String, { optional: true })
  facultyId?: string;

  @Of(() => String, { optional: true })
  studentTypeId?: string;

  @Of(() => String, { optional: true })
  studyTypeYear?: string;

  @Of(() => String, { optional: true })
  paymentTypeId?: string;

  @Of(() => [String], { optional: true })
  taxes: string[] = [];

  @Of(() => [String], { optional: true })
  dorms: string[] = [];

  @Of(() => [String], { optional: true })
  rooms: string[] = [];

  @Of(() => String, { optional: true })
  startDate?: string;

  @Of(() => String, { optional: true })
  endDate?: string;

  static of = Entity.of<PaginatedPaymentInput>();
}

@Entity
class PaymentType {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  name: string = '';

  static of = Entity.of<PaymentType>();
}

@Entity
class PaymentMethod {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  static of = Entity.of<PaymentMethod>();
}
