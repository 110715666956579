import { useTranslation } from 'react-i18next';

import {
  CONFIG_FLAGS,
  FEATURE_FLAGS,
  PERM_BILLING_CLIENT,
  PERM_LIBRARY_MANAGER,
  PERM_LIBRARY_VIEW,
  PERM_QUESTIONNAIRE_CREATE_SURVEY,
  PERM_QUESTIONNAIRE_VIEW,
} from '@constants';
import { useRouter } from '@lib/router';
import { getUrlPrefix, useStorageSession } from '@lib/utils';
import { LinkButtonItem, LinkButtonsGroup } from '@modules/shared';

export const useStudentLinkGroups = () => {
  const { profile, classes } = useStorageSession();

  const { lang } = useRouter();
  const { t } = useTranslation(['Site', 'common']);

  const showAdmission = ['3', '4', '5', '6'].includes(String(classes?.[0]?.ciclu?.cicluID) || '');

  const groups: LinkButtonsGroup[] = [
    {
      items: [
        LinkButtonItem.of({
          icon: 'FiHome',
          label: t('buttons_mm.dashboard'),
          url: 'dashboard',
        }),
        LinkButtonItem.of({
          icon: 'Gradebook',
          label: t('buttons_mm.situatie_zi'),
          url: 'report-card',
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.grades'),
              url: 'report-card',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.student_attendance'),
              url: 'report-card/attendance',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.homework'),
              url: 'report-card/homework',
            }),
          ],
          matchingUrls: ['report-card/attendance', 'report-card/homework'],
        }),
        LinkButtonItem.of({
          icon: 'Portofolio',
          label: t('buttons_mm.portofoliu'),
          url: 'portfolio',
          isNew: true,
          featureFlag: FEATURE_FLAGS.FLAG_STUDENT_PORTFOLIO,
          matchingUrls: ['portfolio/student', 'portfolio/student-category'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.STUDENT_PORTFOLIO],
            feature: FEATURE_FLAGS.FLAG_STUDENT_PORTFOLIO,
          },
        }),
        LinkButtonItem.of({
          icon: 'FiTrendingUp',
          label: t('buttons_mm.evolutie'),
          url: 'progress',
          featureFlag: FEATURE_FLAGS.FLAG_STATS_STUDENT_PROGRESS,
          accessRules: {
            configFlags: [CONFIG_FLAGS.STUDENT_PROGRESS_REPORT_ACTIVE],
          },
          matchingUrls: ['upgrade/progress'],
        }),
        LinkButtonItem.of({
          icon: 'Yoga',
          label: t('buttons_mm.wellbeing'),
          url: 'wellbeing',
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.wellbeing_dashboard'),
              url: 'wellbeing',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.wellbeing_history'),
              url: 'wellbeing/moods',
              featureFlag: FEATURE_FLAGS.FLAG_WELLBEING_MOODS,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.observations'),
              url: 'wellbeing/observations',
              featureFlag: FEATURE_FLAGS.FLAG_MODULE_OBSERVATIONS,
              accessRules: {
                configFlags: [CONFIG_FLAGS.OBSERVATIONS_ACTIVE],
              },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.behavior'),
              url: 'wellbeing/behavior',
              featureFlag: FEATURE_FLAGS.FLAG_MODULE_BEHAVIOUR,
              accessRules: {
                configFlags: [CONFIG_FLAGS.BEHAVIOUR_ACTIVE],
              },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.warnings'),
              url: 'wellbeing/warnings',
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'Coins',
          label: t('buttons_mm.financials'),
          url: 'billing/account/invoices',
          matchingUrls: ['billing'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.BILLING_ACTIVE],
            permissions: [PERM_BILLING_CLIENT],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiClipboard',
          label: t('buttons_mm.tests_questionnaires'),
          matchingUrls: ['questionnaires/created', 'questionnaires/todo', 'questionnaires/results'],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.list_created'),
              url: `questionnaires/created`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.list_todo'),
              url: `questionnaires/todo`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.list_results'),
              url: `questionnaires/results`,
            }),
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.QUESTIONNAIRES_ACTIVE],
            permissions: [PERM_QUESTIONNAIRE_VIEW, PERM_QUESTIONNAIRE_CREATE_SURVEY],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiCalendar',
          label: t('buttons_mm.calendar'),
          url: `calendar`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.CALENDAR_ACTIVE],
          },
        }),
      ],
    },
    {
      emptyTitle: true,
      items: [
        LinkButtonItem.of({
          icon: 'FiMail',
          label: t('buttons_mm.mesaje'),
          url: `messages`,
          featureFlag: FEATURE_FLAGS.FLAG_MODULE_MESSAGES,
          matchingUrls: [
            'messages/send',
            'messages/sent',
            'messages/drafts',
            'messages/archive',
            'messages/received',
          ],
        }),
        LinkButtonItem.of({
          icon: 'FiFile',
          label: t('buttons_mm.fisiere'),
          url: `files`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.FILES_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'Groups',
          label: t('buttons_mm.groups'),
          url: `groups`,
          matchingUrls: ['groups/'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.GROUPS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'Library',
          label: t('buttons_mm.biblioteca'),
          url: `library`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.LIBRARY_ACTIVE],
            permissions: [PERM_LIBRARY_MANAGER, PERM_LIBRARY_VIEW],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiPieChart',
          label: t('buttons_mm.rapoarte_btn'),
          url: `${getUrlPrefix(profile.uaTip)}/reports_v1`,
          // matchingUrls: ['teacher/students_reports/secondary'],
          redirectRule: 'window',
          accessRules: {
            configFlags: [CONFIG_FLAGS.STUDENT_REPORTS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiImage',
          label: t('buttons_mm.foto'),
          matchingUrls: ['foto', 'anuar'],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.foto'),
              url: `foto`,
              featureFlag: FEATURE_FLAGS.FLAG_MODULE_FOTO,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.yearbook'),
              url: `anuar`,
              featureFlag: FEATURE_FLAGS.FLAG_MODULE_YEARBOOK,
              accessRules: {
                configFlags: [CONFIG_FLAGS.ANUAR_ACTIVE],
              },
            }),
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.PHOTOS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'GraduationCap',
          label: t('buttons_mm.admission_portal'),
          url: `admission/offers`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.SHOW_ADMISSION_OFFERS_TO_STUDENTS],
            extraChecks: showAdmission,
          },
        }),
        LinkButtonItem.of({
          icon: 'FiShoppingCart',
          label: t('buttons_mm.edumarket'),
          url: `edumarket`,
          matchingUrls: ['edumarket/about', 'edumarket/applications/details'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.EDUMARKET_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiHelpCircle',
          label: t('buttons_mm.support'),
          url:
            lang === 'ro'
              ? 'https://manual.adservio.ro/web/elev-parinte/'
              : 'https://manual.adservio.ro/web/en/student-parent/',
          redirectRule: 'blank',
          hasTopDivider: true,
        }),
      ],
    },
  ];

  return {
    groups,
  };
};
