import { Address, School } from '@api/core/entities';
import { AdservioIcons, Grid, Text } from '@ui2/components';

interface OrganisationDetailsProps {
  school: School;
  isHistory?: boolean;
}

export const OrganisationDetails = ({ school, isHistory }: OrganisationDetailsProps) => {
  return (
    <Grid templateColumns="auto 1fr" alignItems="flex-start">
      {isHistory && <AdservioIcons.History mr="2" mt="0.2rem" boxSize="0.875rem" />}

      <Text
        fontSize="sm"
        fontWeight={isHistory ? 'normal' : 'semibold'}
        color={isHistory ? 'gray.400' : 'gray.700'}
        textAlign="left"
      >
        {school?.liceuClasificare} {school?.liceuNume}
        {Address.shortToString(school?.liceuAdresa) && (
          <Text as="span" color={isHistory ? 'gray.400' : 'gray.700'}>
            , {Address.shortToString(school?.liceuAdresa)}
          </Text>
        )}
      </Text>
    </Grid>
  );
};
