import { getOrgTypeTheme } from '@ui2/utils';

const focusColor = getOrgTypeTheme('#E0F2FE');

export const shadows = {
  xs: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  sm: '0px 1px 2px rgba(16, 24, 40, 0.06), 0px 1px 3px rgba(16, 24, 40, 0.1)',
  md: '0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1)',
  lg: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
  xl: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
  '2xl': '0px 24px 48px -12px rgba(16, 24, 40, 0.18)',
  '3xl': '0px 32px 64px -12px rgba(16, 24, 40, 0.14)',
  inputFocus: `0px 0px 0px 4px ${focusColor}, 0px 1px 2px rgba(16, 24, 40, 0.05);`,
  inputInvalid: '0px 0px 0px 4px #FEE4E2, 0px 1px 2px rgba(16, 24, 40, 0.05)',
  top: '0px -6px 16px rgba(16, 24, 40, 0.08)',
  side: '6px 0px 16px rgba(16, 24, 40, 0.06), 1px 0px 8px rgba(16, 24, 40, 0.1);',
  insetMd:
    'inset 0px 2px 4px -2px rgba(16, 24, 40, 0.06), inset 0px 4px 8px -2px rgba(16, 24, 40, 0.1), inset 0px -2px 4px -2px rgba(16, 24, 40, 0.06), inset 0px -4px 8px -2px rgba(16, 24, 40, 0.1)',
};
