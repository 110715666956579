import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class StudentPortfolioCategoryFormInput {
  @Of(() => String)
  @IsNotEmpty({
    message: 'Numele categoriei este obligatoriu',
  })
  name = '';

  @Of(() => Boolean)
  isIndividual = false;

  static of = Entity.of<StudentPortfolioCategoryFormInput>();
}
