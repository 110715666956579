import { Person, Subject } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class SubjectsTransfer {
  @Of(() => Subject)
  cmcMaterie: Subject = Subject.of({});

  @Of(() => Person)
  cmcCadru: Person = Person.of({});

  @Of(() => Person)
  cmcCadruTo: Person = Person.of({});

  @Of(() => [PersonTo])
  cmcCadruDropdownTo: PersonTo[] = [];

  @Of(() => Person)
  cmcCadruFrom: Person = Person.of({});

  @Of(() => Boolean)
  copyClassbookData: boolean = false;

  @Of(() => String)
  cmcID: string = '';

  @Of(() => String)
  fromCmcID?: string = '';

  @Of(() => String)
  toCmcID?: string = '';

  static of = Entity.of<SubjectsTransfer>();
}

@Entity
export class PersonTo {
  @Of(() => Person)
  cmcCadru: Person = Person.of({});

  @Of(() => String)
  toCmcID?: string = '';

  static of = Entity.of<PersonTo>();
}
