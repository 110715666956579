import { Entity, Of } from 'entity-of';

@Entity
export class AnswerInput {
  @Of(() => String)
  frID: string = '';

  @Of(() => String)
  uaID?: string = '';

  @Of(() => String)
  fsqID?: string = '';

  static of = Entity.of<AnswerInput>();
}
