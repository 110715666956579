import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-restricted-imports
import { chakra } from '@chakra-ui/react';
import { AdservioIcons, Button } from '@ui2/components';

export const StyledFileInput = chakra('input', {
  baseStyle: {
    display: 'none',
  },
});

interface UploadButtonProps {
  name: string;
  label?: string;
  variant?: string;
  colorScheme?: string;
  size?: string;
}

export const UploadButton = forwardRef<HTMLButtonElement, UploadButtonProps>(
  ({ name, label, variant, colorScheme, size }, ref) => {
    const { t } = useTranslation('shared');

    return (
      <Button
        as="label"
        cursor="pointer"
        ref={ref as any}
        htmlFor={name}
        aria-label="select file"
        variant={variant}
        colorScheme={colorScheme}
        size={size}
      >
        <AdservioIcons.FiUploadCloud mr="2" />
        {label || t('upload_file')}
      </Button>
    );
  }
);
