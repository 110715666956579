import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';

import { SessionFeatures } from '@api/subscriptions/entities';
import { useRouter } from '@lib/router';

import { getCalloutContent } from '../../../Components/UpgradeCallout/page/getCalloutContent';
import {
  FEATURE_FLAGS,
  TYPE_ADMIN,
  TYPE_ELEV,
  TYPE_PARINTE,
  TYPE_PERS_INST,
} from '../../../constants';
import { useLang } from '../../i18n';
import { hasFeature, isFeatureEnabled, useStorageSession } from '..';

export const noOrgSubscriptionActive = (
  isSubscriptionsActive: boolean,
  uaTip: string,
  features: SessionFeatures
) => {
  if (!isSubscriptionsActive || uaTip === TYPE_PERS_INST) {
    return false;
  }

  if (!features?.organization?.features?.length) {
    return true;
  }

  // if (uaTip !== TYPE_ELEV && uaTip !== TYPE_PARINTE) {
  //   if (!features?.organization?.features?.length) {
  //     return true;
  //   }
  // } else {
  //   if (uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE) {
  //     if (!features?.organization?.features?.length) {
  //       return true;
  //     } else {
  //       if (!features?.user?.features?.length) {
  //         return true;
  //       }
  //     }
  //   }
  // }

  return false;
};

export const noUserSubscriptionActive = (
  isSubscriptionsActive: boolean,
  uaTip: string,
  features: SessionFeatures
) => {
  if (!isSubscriptionsActive) {
    return false;
  }

  if (
    features?.organization?.features?.length &&
    (uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE) &&
    !features?.user?.features?.length
  ) {
    return true;
  }

  return false;
};

export const isSubscriptionRequired = (
  isSubscriptionsActive: boolean,
  uaTip: string,
  features: SessionFeatures
) => {
  if (!isSubscriptionsActive || uaTip === TYPE_ADMIN || uaTip === TYPE_PERS_INST) {
    return false;
  }

  if (!features?.organization?.features?.length) {
    return true;
  }

  if ((uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE) && !features?.user?.features?.length) {
    return true;
  }

  return false;
};

interface SubscriptionsRedirectOptions {
  isSubscriptionsActive: boolean;
  uaTip: string;
  features: SessionFeatures;
  allowedWithoutOrgSubscription: boolean;
  allowedWithoutUserSubscription: boolean;
}

export const decideNoSubscriptionRedirect = (options: SubscriptionsRedirectOptions) => {
  const lang = useLang();

  const {
    isSubscriptionsActive,
    uaTip,
    features,
    allowedWithoutOrgSubscription,
    allowedWithoutUserSubscription,
  } = options;

  if (
    !allowedWithoutOrgSubscription &&
    noOrgSubscriptionActive(isSubscriptionsActive, uaTip, features)
  ) {
    return <Redirect to={`/${lang}/no-subscription`} />;
  }

  if (
    !allowedWithoutUserSubscription &&
    noUserSubscriptionActive(isSubscriptionsActive, uaTip, features)
  ) {
    return <Redirect to={`/${lang}/no-user-subscription`} />;
  }

  return false;
};

export const computeUpgradeCalloutPackage = (features: FEATURE_FLAGS[]) => {
  const { t } = useTranslation('shared');
  const { lang } = useRouter();
  const { subscriptionPackages } = useStorageSession();
  const sortedPackages = subscriptionPackages.sort((a, b) => a.order - b.order);

  const upgradePackage = sortedPackages.find((pkg) => {
    const pkgFeatures = pkg?.features.map((feature) => feature.id);
    return features?.every((feature) => pkgFeatures.includes(feature));
  });

  let packageName = t('general.premium');

  if (upgradePackage?.packageName?.length) {
    packageName = String(
      upgradePackage.packageName.find((pkg) => {
        return pkg.langId === lang;
      })?.value
    );
  }

  return {
    upgradePackage,
    packageName,
  };
};

export const computeCalloutPanels = (module: string) => {
  const { panels } = getCalloutContent(module);

  const calloutPanels =
    panels?.filter((panel) => {
      if (!panel.featureFlags) {
        return true;
      }
      return panel.featureFlags?.some(
        (featureFlag) => isFeatureEnabled(featureFlag) && !hasFeature(featureFlag)
      );
    }) ?? [];

  return { calloutPanels };
};

export const computeCalloutProps = (module: string) => {
  const { featureFlags } = getCalloutContent(module);

  const { calloutPanels } = computeCalloutPanels(module);

  const allUpgradeFeatures =
    (calloutPanels.length > 0
      ? (calloutPanels?.flatMap((panel) =>
          panel.featureFlags?.map((flag) => flag)
        ) as FEATURE_FLAGS[])
      : featureFlags) ?? [];

  const { packageName, upgradePackage } = computeUpgradeCalloutPackage(allUpgradeFeatures);

  return {
    packageName,
    upgradePackage,
    calloutPanels,
  };
};
