import { Entity, Of } from 'entity-of';

import { SummaryHistoryTax } from '../SummaryHistoryTax';

@Entity
export class SummaryHistoryItem {
  @Of(() => Number)
  count: number = 0;

  @Of(() => Number)
  totalValue: number = 0;

  @Of(() => Number)
  servicesValue: number = 0;

  @Of(() => Number)
  penaltiesValue: number = 0;

  @Of(() => [SummaryHistoryTax])
  taxes: SummaryHistoryTax[] = [];

  static of = Entity.of<SummaryHistoryItem>();
}
