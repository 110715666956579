import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, FeatureIcon } from '@ui2/components';

export const UnificationSuccessDialog = () => {
  const { t } = useTranslation('Auth');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const showUnificationModal = sessionStorage.getItem('showUnificationSuccessDialog') === 'true';

    if (showUnificationModal) {
      setIsOpen(true);
    }

    sessionStorage.removeItem('showUnificationSuccessDialog');
  }, []);

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isOpen && (
        <Dialog
          isOpen
          isCentered
          hasActions
          size="lg"
          backdropBlur="5px"
          title={t('unification.dialog_title')}
          description={t('unification.dialog_description')}
          confirmText={t('unification.confirm_text')}
          renderIcon={() => (
            <FeatureIcon justifyContent="center" name="FiLink" variant="success" size="xl" />
          )}
          onConfirm={closeDialog}
          onClose={closeDialog}
          hasClose={false}
        />
      )}
    </>
  );
};
