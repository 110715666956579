// @ts-nocheck
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { FEATURE_MODULES, TYPE_ADMIN } from '@constants';
import { useLang } from '@lib/i18n';
import { decideNoSubscriptionRedirect, useStorageSession } from '@lib/utils';
import { hasFeature, isFeatureEnabled } from '@lib/utils/session/hasAccess';
import PropTypes from 'prop-types';

import { useHistory } from '../../Helpers';

import hooks from './hooks';

const RedirectHome = React.lazy(() => import('../../Modules/Site/components/redirect-home'));

const ProtectedRoute = ({
  component: Component,
  uaTip,
  enabled,
  permAny,
  permAll,
  subscription,
  redirect,
  computedMatch,
  location,
  path,
  exact,
  featureFlagRedirect,
  featureFlag,
  allowedWithoutOrgSubscription = false,
  allowedWithoutUserSubscription = false,
}) => {
  const lang = useLang();
  const { sessionUaTip, sessionPerm, sessionSubscription } = hooks();

  const { profile, schoolConfig } = useStorageSession();
  const features = JSON.parse(window.localStorage.getItem('features') || '{}');

  const isSubscriptionsActive = schoolConfig?.subscriptions?.isActive === '1';

  const handleRedirect = (redirectUrl) => {
    return redirectUrl ? <Redirect to={`${redirectUrl}`} /> : <RedirectHome />;
  };

  const routeHistory = useHistory();

  return (
    <Route
      computedMatch={computedMatch}
      location={location}
      path={path}
      exact={exact}
      render={({
        location: routeLocation,
        match: routeMatch,
        staticContext: routeStaticContext,
      }) => {
        const noSubscriptionRedirect = decideNoSubscriptionRedirect({
          isSubscriptionsActive,
          uaTip: profile.uaTip,
          features,
          allowedWithoutOrgSubscription,
          allowedWithoutUserSubscription,
        });

        if (noSubscriptionRedirect && sessionUaTip !== TYPE_ADMIN) {
          return noSubscriptionRedirect;
        }

        if (
          featureFlagRedirect &&
          FEATURE_MODULES[featureFlag] &&
          isFeatureEnabled(featureFlag) &&
          !hasFeature(featureFlag) &&
          profile.uaTip !== TYPE_ADMIN
        ) {
          return <Redirect to={`/${lang}/upgrade/${FEATURE_MODULES[featureFlag]}`} />;
        }

        if (!isFeatureEnabled(featureFlag) && profile.uaTip !== TYPE_ADMIN) {
          return handleRedirect(redirect);
        }

        if (!sessionUaTip || !enabled) {
          return <Redirect to={`/${lang}/auth?redirectUrl=${path}`} />;
        }

        if (uaTip) {
          return uaTip.includes(sessionUaTip) ? (
            <Component
              history={routeHistory}
              location={routeLocation}
              match={routeMatch}
              staticContext={routeStaticContext}
            />
          ) : (
            handleRedirect(redirect)
          );
        }

        if (permAny) {
          const permissions = [];
          permAny.forEach((permission) => {
            if (sessionPerm.includes(permission)) {
              permissions.push(permission);
            }
          });

          return permissions.length ? (
            <Component
              history={routeHistory}
              location={routeLocation}
              match={routeMatch}
              staticContext={routeStaticContext}
            />
          ) : (
            handleRedirect(redirect)
          );
        }

        if (permAll) {
          const missingPermissions = [];

          permAll.forEach((permission) => {
            if (!sessionPerm.includes(permission)) {
              missingPermissions.push(permission);
            }
          });

          return missingPermissions.length ? (
            handleRedirect(redirect)
          ) : (
            <Component
              history={routeHistory}
              location={routeLocation}
              match={routeMatch}
              staticContext={routeStaticContext}
            />
          );
        }

        if (subscription) {
          return subscription === sessionSubscription ? (
            <Component
              history={routeHistory}
              location={routeLocation}
              match={routeMatch}
              staticContext={routeStaticContext}
            />
          ) : (
            handleRedirect(redirect)
          );
        }

        return (
          <Component
            history={routeHistory}
            location={routeLocation}
            match={routeMatch}
            staticContext={routeStaticContext}
          />
        );
      }}
    />
  );
};

ProtectedRoute.defaultProps = {
  uaTip: undefined,
  enabled: true,
  permAny: undefined,
  permAll: undefined,
  subscription: undefined,
  redirect: undefined,
  computedMatch: undefined,
  location: undefined,
  allowedWithoutOrgSubscription: false,
  allowedWithoutUserSubscription: false,
};

ProtectedRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object),
  computedMatch: PropTypes.instanceOf(Object),
  uaTip: PropTypes.instanceOf(Array),
  enabled: PropTypes.bool,
  permAny: PropTypes.instanceOf(Array),
  permAll: PropTypes.instanceOf(Array),
  subscription: PropTypes.number,
  redirect: PropTypes.string,
  featureFlagRedirect: PropTypes.bool,
  featureFlag: PropTypes.string,
  allowedWithoutOrgSubscription: PropTypes.bool,
  allowedWithoutUserSubscription: PropTypes.bool,
};

export default ProtectedRoute;
