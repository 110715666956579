import { Entity, Of } from 'entity-of';

@Entity
export class ClassTypes {
  @Of(() => String)
  ctID: string = '';

  @Of(() => String)
  ctNume: string = '';

  static of = Entity.of<ClassTypes>();
}
