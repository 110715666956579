import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  chakraComponents,
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MenuListProps,
  MultiValueProps,
  OptionProps,
  PlaceholderProps,
  SingleValueProps,
} from 'chakra-react-select';
import merge from 'lodash/merge';

import { AdservioIcons, Box, Checkbox, Flex, Grid, IconButton, Text } from '../../components/atoms';
import { getOrgTypeTheme } from '../getOrgTypeTheme';

export const CREATE_OPTION = 'CREATE_OPTION';

const IconButtonSizeDict = {
  sm: 'xs',
  md: 'sm',
  lg: 'md',
};

interface MenuListCustomProps extends MenuListProps {
  isLoading?: boolean;
  moreOptionsCount?: number;
}

export const MenuList = ({
  isLoading,
  moreOptionsCount = 0,
  children,
  ...rest
}: MenuListCustomProps) => {
  const { t } = useTranslation('shared');

  return (
    <chakraComponents.MenuList {...rest}>
      {children}

      {!isLoading && moreOptionsCount > 0 && (
        <Box py="4" textAlign="center" fontSize="sm" color="gray.300">
          {moreOptionsCount === 1
            ? t('general.and_1_more_option')
            : t('general.and_x_more_options', { 0: moreOptionsCount })}
        </Box>
      )}
    </chakraComponents.MenuList>
  );
};

interface SingleValueCustomProps extends SingleValueProps {
  renderSelectedValue?: (value) => React.ReactElement;
  leftIcon?: React.ReactElement;
}

export const SingleValue = (props: SingleValueCustomProps) => {
  const { children, data, renderSelectedValue, leftIcon } = props;

  return (
    <chakraComponents.SingleValue {...props}>
      <Flex alignItems="center">
        {leftIcon && (
          <Flex mr="2" data-testid="sync-select-left-icon">
            {leftIcon}
          </Flex>
        )}
        {renderSelectedValue?.(data) || children}
      </Flex>
    </chakraComponents.SingleValue>
  );
};

export const CustomMultiValue = (props: MultiValueProps) => {
  const { index, children, getValue, options, selectProps } = props;
  const { t } = useTranslation('shared');

  const isLast = index === getValue()?.length - 1;

  const inputValue = selectProps.inputValue;
  const valueLength = getValue()?.length;
  const optionsLength = options?.length;

  let result: any = '';

  if (inputValue) {
    if (index === 0) {
      result = valueLength || '';
    }
  } else {
    if (valueLength === 1) {
      result = children;
    } else if (index === 0) {
      if (valueLength === optionsLength) {
        result = t('general.all_selected');
      } else {
        result = t('general.x_selected', { valueLength });
      }
    }
  }

  return (
    <chakraComponents.MultiValueContainer {...props}>
      {result}

      <Box
        display={isLast && selectProps.menuIsOpen ? 'inline-block' : 'none'}
        height="65%"
        width="1px"
        backgroundColor="gray.200"
        mx="2"
      />
    </chakraComponents.MultiValueContainer>
  );
};

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const baseColor = getOrgTypeTheme('blue');

  return (
    <chakraComponents.DropdownIndicator {...props}>
      <IconButton
        w="full"
        variant="ghost"
        colorScheme={baseColor}
        aria-label="drop-down"
        size={IconButtonSizeDict[props.selectProps.size || 'md']}
        icon={
          props.selectProps.menuIsOpen ? (
            <AdservioIcons.FiChevronUp pointerEvents="none" />
          ) : (
            <AdservioIcons.FiChevronDown pointerEvents="none" />
          )
        }
        isDisabled={props.isDisabled}
      />
    </chakraComponents.DropdownIndicator>
  );
};

export const ClearIndicator = (props: ClearIndicatorProps) => {
  const baseColor = getOrgTypeTheme('blue');

  return (
    <chakraComponents.ClearIndicator {...props}>
      <IconButton
        variant="ghost"
        aria-label="clear"
        colorScheme={baseColor}
        icon={<AdservioIcons.FiX />}
        size={IconButtonSizeDict[props.selectProps.size || 'md']}
      />
    </chakraComponents.ClearIndicator>
  );
};

const CheckedIndicator = () => {
  const baseColor = getOrgTypeTheme('blue');

  return <AdservioIcons.FiCheck color={`${baseColor}.500`} />;
};

interface OptionCustomProps extends OptionProps {
  renderOption?: (option) => React.ReactElement;
}

export const Option = (props: OptionCustomProps) => {
  const { renderOption } = props;

  return (
    <chakraComponents.Option {...props}>
      <Flex data-testid="sync-select-option" justify="space-between" alignItems="center" w="full">
        {renderOption?.(props) || (
          <>
            <Grid templateColumns="auto 1fr" alignItems="center" columnGap="2">
              {props.isMulti && (
                <Checkbox isChecked={props.isSelected} flex="auto" pointerEvents="none" />
              )}

              {props.children}
            </Grid>
          </>
        )}

        {!props.isMulti && props.isSelected && <CheckedIndicator />}
      </Flex>
    </chakraComponents.Option>
  );
};

export const MultiBadgesOption = (props) => {
  const isCreatable = props.value === CREATE_OPTION && Boolean(props.onCreateOption);

  return (
    <Box onClick={isCreatable ? () => props.onCreateOption(true) : undefined}>
      <chakraComponents.Option
        {...props}
        selectProps={{ ...props.selectProps, colorScheme: 'blue' }}
      >
        {isCreatable && (
          <>
            <Box position="absolute" width="100%" bottom="0" left="0" height="1px" bg="gray.200" />
            <AdservioIcons.FiPlus mr="2" />
          </>
        )}

        <Flex data-testid="sync-select-option" justify="space-between" w="full">
          {props.renderOption ? props.renderOption(props.data) : props.children}
        </Flex>
      </chakraComponents.Option>
    </Box>
  );
};

interface PlaceholderCustomProps extends PlaceholderProps {
  placeholderIcon?: React.ReactElement;
  placeholder?: React.ReactElement;
}

export const Placeholder = (props: PlaceholderCustomProps) => {
  const { placeholder, placeholderIcon } = props;

  return (
    <chakraComponents.Placeholder {...props}>
      <Flex data-testid="sync-select-placeholder" alignItems="center">
        {placeholderIcon && <Flex mr="2">{placeholderIcon}</Flex>}
        <Text as="span" color="gray.300">
          {placeholder}
        </Text>
      </Flex>
    </chakraComponents.Placeholder>
  );
};

export const ChakraStyles = ({ size, chakraStyles }) => {
  const baseColor = getOrgTypeTheme('blue');

  const heightOptions = {
    sm: '2rem',
    md: '2.5rem',
    lg: '3rem',
  };

  return merge(
    {
      select: (base) => ({
        ...base,
        minH: '2rem',
      }),
      control: (base) => ({
        ...base,
        minH: '2rem',
      }),
      container: (base) => ({
        ...base,
        minH: heightOptions[(size as string) ?? 'md'],
      }),
      groupHeading: (base) => ({
        ...base,
        fontSize: 'md',
      }),
      option: (base, { isSelected }) => ({
        ...base,
        position: 'relative',
        padding: '0.5rem 1rem',
        bg: isSelected ? `${baseColor}.50` : base.bg,
        color: isSelected ? 'gray.700' : base.color,
      }),
      indicatorSeparator: (base) => ({
        ...base,
        height: '65%',
      }),
      dropdownIndicator: (base, { selectProps }) => ({
        ...base,
        bg: 'transparent',
        py: '3px',
        px: '2',
        '> svg': {
          transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
          color: `${baseColor}.500`,
        },
      }),
      input: (base) => ({
        ...base,
        borderColor: 'transparent',
      }),
      menu: (base) => ({
        ...base,
        boxShadow: 'xl',
        borderRadius: 'xl',
        zIndex: '10',
      }),
      menuList: (base) => ({
        ...base,
        borderRadius: 'xl',
        maxH: { base: '10.5rem', md: '13rem', '3xl': '20rem' },
        py: 0,
        zIndex: '10',
      }),
      clearIndicator: () => ({
        mr: '2',
      }),
    },
    chakraStyles
  );
};
