import { Entity, Of } from 'entity-of';

@Entity
export class AttendanceDownloadInput {
  @Of(() => String)
  type: AttendanceDownloadInputType = AttendanceDownloadInputType.MONTHLY;

  @Of(() => String)
  month: string = '';

  @Of(() => String)
  year: string = '';

  static of = Entity.of<AttendanceDownloadInput>();
}

export enum AttendanceDownloadInputType {
  MONTHLY = 'monthly',
}
