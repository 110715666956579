import { TranslatedLanguageItem } from '@api/core/entities';
import {
  IsNotEmpty,
  MaxLength,
  Min,
  registerDecorator,
  ValidateIf,
  ValidationOptions,
} from 'class-validator';
import { Entity, Of } from 'entity-of';

import { PricingModel, SubscriberType } from '../Package';

@Entity
export class PackageInformationInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.countryId.required' })
  countryId: string = '';

  @Of(() => String)
  organizationTypeId: string = '1';

  @Of(() => SubscriberType, { nullable: true })
  @IsNotEmpty({ message: 'errors.subscriberType.required' })
  subscriberType = 'student';

  @Of(() => Number, { nullable: true })
  @IsNotEmpty({ message: 'errors.billingProfileId.required' })
  billingProfileId: number | null = null;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.currencyCode.required' })
  currencyCode: string = '';

  @ValidateIf((o) => o.isVatPayer)
  @IsNotEmpty({ message: 'errors.vatRate.required' })
  @Of(() => String, { optional: true })
  vatRateId?: string;

  @Of(() => Boolean, { optional: true })
  isVatPayer?: boolean;

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.yearlyPrice.required' })
  @Min(0, { message: 'errors.yearlyPrice.required' })
  yearlyPrice: number | null = null;

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.monthyPrice.required' })
  @Min(0, { message: 'errors.monthlyPrice.required' })
  monthlyPrice: number | null = null;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.pricingModel.required' })
  pricingModel: PricingModel = PricingModel.FIXED;

  @Of(() => Number, { nullable: true })
  licensesLimit: number | null = null;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.name.required' })
  @MaxLength(255, { message: 'errors.max_255' })
  name: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.description.required' })
  @MaxLength(255, { message: 'errors.max_255' })
  description: string = '';

  @Of(() => String)
  @MaxLength(255, { message: 'errors.max_255' })
  internalReference: string = '';

  @Of(() => Boolean)
  isPublic: boolean = false;

  @Of(() => Number, { optional: true })
  trialDays?: number;

  @Of(() => Boolean)
  isPopular: boolean = false;

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.order.required' })
  order: number = 1;

  @Of(() => Number, { nullable: true })
  groupId: number | null = null;

  @Of(() => [TranslatedLanguageItem])
  @RoTranslationRequired({ message: 'errors.packageName.required' })
  packageName: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  @RoTranslationRequired({ message: 'errors.packageDescription.required' })
  packageDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  priceLabel: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  priceDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  featuresDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature1: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature2: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature3: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature4: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature5: TranslatedLanguageItem[] = [];

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.required' })
  colorTheme: string = '';

  @Of(() => [TranslatedLanguageItem])
  promoBannerDescription: TranslatedLanguageItem[] = [];

  static of = Entity.of<PackageInformationInput>();
}

function RoTranslationRequired(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'roTranslationRequired',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: TranslatedLanguageItem[]) {
          return !!value.find((v) => v.langId === 'ro' && v.value);
        },
      },
    });
  };
}
