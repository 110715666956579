import { Entity, Of } from 'entity-of';

import { PackageSales } from '../PackageSales';

@Entity
export class TimeUnitStats {
  @Of(() => String)
  data: string = '';

  @Of(() => [PackageSales])
  subscriptions: PackageSales[] = [];

  @Of(() => Number)
  totalSales: number = 0;

  @Of(() => Number)
  totalTrials: number = 0;

  @Of(() => Number)
  totalAmount: number = 0;

  static of = Entity.of<TimeUnitStats>();
}
