import { Entity, Of } from 'entity-of';

@Entity
export class Details {
  @Of(() => String)
  message: string = '';

  @Of(() => Boolean)
  enabled: boolean = false;

  static of = Entity.of<Details>();
}
