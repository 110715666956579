import { Entity, Of } from 'entity-of';

@Entity
export class AssignServiceToAccountInput {
  @Of(() => String, { optional: true })
  accountId?: string;

  @Of(() => String, { optional: true })
  serviceId?: string;

  @Of(() => String, { optional: true })
  billableAccountId?: string;

  @Of(() => String, { optional: true })
  startDate?: Date;

  @Of(() => String, { optional: true })
  endDate?: Date;

  @Of(() => Number, { optional: true })
  count?: number;

  static of = Entity.of<AssignServiceToAccountInput>();
}
