import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class TransferFrom {
  @Of(() => String)
  clID: string = '';

  @Of(() => String)
  clNume: string = '';

  static of = Entity.of<TransferFrom>();
}

@Entity
export class TransferTo {
  @Of(() => String)
  clID: string = '';

  @Of(() => String)
  clNume: string = '';

  static of = Entity.of<TransferTo>();
}

@Entity
export class ClassTransfers {
  @Of(() => String)
  tcID: string = '';

  @Of(() => String)
  elevFullName: string = '';

  @Of(() => String)
  tcTransferNumber = '';

  @Of(() => Boolean, { optional: true })
  isDupeTransferNumber?: boolean;

  @Of(() => String)
  tcTransferDate = '';

  @Of(() => String)
  tcElevID = '';

  @Of(() => String)
  tcShowInReports = '';

  @Of(() => TransferFrom)
  tcFromClass: TransferFrom = { clID: '', clNume: '' };

  @Of(() => TransferTo)
  tcToClass: TransferTo = { clID: '', clNume: '' };

  @Of(() => Person)
  elev: Person = Person.of({});

  static of = Entity.of<ClassTransfers>();
}
