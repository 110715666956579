import { TaxType } from '@modules/Universities/accommodations/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class TaxWithPrice {
  @Of(() => String)
  name: string = '';

  @Of(() => String)
  taxId: string = '';

  @Of(() => String)
  type: TaxType = 'didactic';

  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  @Of(() => Number)
  price: number = 0;

  static of = Entity.of<TaxWithPrice>();
}

@Entity
export class TaxWithPriceInput {
  @Of(() => String)
  dormId: string = '';

  @Of(() => Boolean)
  withPrice: boolean = false;

  @Of(() => String, { optional: true })
  pricePlanId?: string = '';

  static of = Entity.of<TaxWithPriceInput>();
}
