import { Entity, Of } from 'entity-of';

@Entity
export class AssignPackageEligibilityInput {
  @Of(() => [String], { optional: true })
  cityID: string[] = [];

  @Of(() => String, { optional: true })
  search: string = '';

  @Of(() => [String], { optional: true })
  judID: string[] = [];

  @Of(() => [String], { optional: true })
  taraID: string[] = [];

  @Of(() => String)
  startDate: string = '';

  @Of(() => String, { optional: true })
  endDate: string = '';

  @Of(() => String)
  packageId: string = '';

  static of = Entity.of<AssignPackageEligibilityInput>();
}
