// @ts-nocheck
import Api2 from '../../../Libs/api';

export const FETCH_GROUP_DETAILS_SUCCESS = 'FETCH_GROUP_DETAILS_SUCCESS';
export const FETCH_GROUP_DETAILS_ERROR = 'FETCH_GROUP_DETAILS_ERROR';
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const LOAD_SUCCESS_MESSAGES = 'LOAD_SUCCESS_MESSAGES';

export function getGroupHeader(id) {
  return (dispatch) => {
    Api2.get('/groups', {
      grpID: id,
      withUserInfo: 1,
    })
      .then((data) => {
        dispatch({
          type: FETCH_GROUP_DETAILS_SUCCESS,
          success: true,
          groupDetails: data,
        });
      })
      .catch(() => {
        dispatch({
          type: FETCH_GROUP_DETAILS_ERROR,
          success: false,
        });
      });
  };
}

export const loadMessages = (grpCrID, page) => {
  return (dispatch) => {
    Api2.get('chat/messages', {
      crID: grpCrID,
      _limit: '18',
      _offset: 18 * (page - 1),
      _order: '>cmsgID',
    })
      .then((data) => {
        dispatch({
          type: LOAD_MESSAGES,
          chatMessages: data,
          pendingMessages: true,
        });
      })
      .finally(() => {
        dispatch({
          type: LOAD_SUCCESS_MESSAGES,
          pendingMessages: false,
        });
      });
  };
};
