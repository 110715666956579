import { Person } from '@api/core/entities/utilizatori/Person';
import { Entity, Of } from 'entity-of';

@Entity
export class ClassStudent {
  @Of(() => ({ String }))
  countScutiri: Record<string, number> = {};

  @Of(() => Number)
  countAvertismente: number = 0;

  @Of(() => ClassStudentDetails)
  elev: ClassStudentDetails = ClassStudentDetails.of({});

  @Of(() => AverageGrades)
  mediiGenerale: AverageGrades = AverageGrades.of({});

  static of = Entity.of<ClassStudent>();
}

@Entity
export class ClassStudentDetails extends Person {
  @Of(() => StudentStatus)
  status: StudentStatus = StudentStatus.of({});

  static of = Entity.of<ClassStudentDetails>();
}

@Entity
export class StudentStatus {
  @Of(() => String)
  e2cIsActive: string = '0';

  @Of(() => String)
  elevStatusID: string = '0';

  static of = Entity.of<StudentStatus>();
}

@Entity
export class PeriodValues {
  @Of(() => Number)
  mgsValoare: number = 0;

  @Of(() => Number)
  mgsValoareAds: number = 0;

  @Of(() => Number)
  mgsIsLocked: number = 0;

  @Of(() => Number)
  mgsSituatieScolaraID: number = 0;

  @Of(() => Number)
  sanID: number = 0;

  @Of(() => Number || String)
  astSistemNotare: number | string = '';

  static of = Entity.of<PeriodValues>();
}

@Entity
export class AnualValues {
  @Of(() => Number)
  mgaValoare: number = 0;

  @Of(() => Number)
  mgaIsLocked: number = 0;

  @Of(() => Number)
  mgaValoareAds: number = 0;

  @Of(() => Number)
  mgaSituatieScolaraID: number = 0;

  @Of(() => Number)
  mgaValoareIntermediara: number = 0;

  @Of(() => Number)
  mgaIsLockedIntermediara: number = 0;

  @Of(() => Number)
  mgaSituatieScolaraIntermediaraID: number = 0;

  @Of(() => Number || String)
  astSistemNotare: number | string = '';

  static of = Entity.of<AnualValues>();
}

@Entity
export class AverageGrades {
  @Of(() => ({
    PeriodValues,
  }))
  perioade: Record<number, PeriodValues> = {};

  @Of(() => AnualValues)
  anuala: AnualValues = AnualValues.of({});

  static of = Entity.of<AverageGrades>();
}
