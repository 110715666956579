import React, { forwardRef, useEffect, useRef } from 'react';

import { HStack, Input, InputProps } from '@ui2/components/atoms';
import { useMediaQuery } from '@ui2/hooks';

export interface OneTimePasswordInputProps extends Omit<InputProps, 'value' | 'onChange' | 'size'> {
  value?: string;
  onChange?: (value: string) => void;
  length?: number;
  size?: 'sm' | 'md' | 'lg';
  withInitialFocus?: boolean;
}

export const OneTimePasswordInput = forwardRef<HTMLInputElement, OneTimePasswordInputProps>(
  ({ value, onChange, length = 6, size, withInitialFocus = false, ...props }, ref) => {
    const inputs = Array(length).fill(0);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const { isMobile } = useMediaQuery();

    const defaultSize = size ? size : isMobile ? 'lg' : 'md';

    const style = {
      lg: {
        fontSize: '3xl',
        boxSize: '3.25rem',
        borderRadius: 'xl',
      },
      md: {
        fontSize: '2xl',
        boxSize: '2.75rem',
        borderRadius: 'lg',
      },
      sm: {
        fontSize: 'xl',
        boxSize: '2.25rem',
        borderRadius: 'md',
      },
    };

    useEffect(() => {
      if (withInitialFocus && inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }, []);

    const replaceAt = (str: string, index: number, replacement: string) => {
      let newStr = str.split('');
      newStr[index] = replacement;
      return newStr.join('');
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      onChange?.(
        (() => {
          let { value: targetValue } = e.target;

          if (targetValue === '') {
            return value ?? '';
          }

          if (!/^[0-9\s]*$/.test(targetValue)) {
            return value ?? '';
          }

          if (targetValue.length > 1) {
            if (targetValue[targetValue.length - 1] !== value?.[index]) {
              targetValue = targetValue.slice(-1);
            } else {
              targetValue = targetValue.slice(0, 1);
            }
          }

          if (targetValue.length === 1 && index < length - 1) {
            inputRefs.current[index + 1]?.focus();
          }

          if (targetValue === '' && index > 0 && value?.[index] === ' ') {
            inputRefs.current[index - 1]?.focus();
          }

          if (!value) {
            let newVal = Array(length).fill(' ');
            newVal[index] = targetValue;
            return newVal.join('');
          }

          return replaceAt(value, index, targetValue === '' ? ' ' : targetValue);
        })()
      );
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
      if (e.ctrlKey && e.key === 'a') {
        e.preventDefault();

        inputRefs.current.forEach((input) => {
          input?.select();
        });
      }

      if (e.key === 'Backspace') {
        const allSelected = inputRefs.current.every(
          (input) => input?.selectionStart === 0 && input?.selectionEnd === input.value.length
        );

        if (allSelected) {
          onChange?.('');

          if (inputRefs?.current?.[0]) {
            inputRefs.current[0].focus();
          }
        } else {
          if (value?.[index] === ' ' && index > 0) {
            inputRefs.current[index - 1]?.focus();
          } else {
            onChange?.(replaceAt(value ?? '', index, ' '));
          }
        }
      }

      if (e.key === 'ArrowLeft' && index > 0) {
        inputRefs.current[index - 1]?.focus();
      }

      if (e.key === 'ArrowRight' && index < length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
      e.preventDefault();
      const pastedData = e.clipboardData.getData('text/plain');

      if (!/^[0-9\s]*$/.test(pastedData)) {
        return;
      }

      if (pastedData.length === length) {
        onChange?.(pastedData);
        inputRefs.current[length - 1]?.focus();
      }
    };

    return (
      <HStack gap="2">
        {inputs.map((_, index) => (
          <Input
            key={index}
            id={`${props.name}[${index}]`}
            ref={(el) => (inputRefs.current[index] = el)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onChange={(e) => handleChange(e, index)}
            onPaste={handlePaste}
            value={value?.[index] ?? ''}
            type="text"
            inputMode="numeric"
            autoComplete="off"
            fontWeight="semibold"
            textAlign="center"
            lineHeight="normal"
            px="1"
            {...style[defaultSize]}
            {...props}
          />
        ))}
      </HStack>
    );
  }
);
