import { Person, SchoolConfig } from '@api/core/entities';
import Cookies from 'js-cookie';

import {
  PERM_ADMISSION_MANAGER,
  PERM_ADMISSION_OFFICER,
  PERM_CAD_AUX,
  PERM_CAD_DIRECTOR,
  PERM_CAD_EDUCATOR,
  PERM_CAD_INVATATOR,
  PERM_CAD_PROFESOR,
  PERM_CAD_REP_ADSERVIO,
  PERM_CAD_SECRETAR,
  TYPE_ADMIN,
  TYPE_CADRU,
  TYPE_CANDIDATE,
  TYPE_ELEV,
  TYPE_PARINTE,
  TYPE_PERS_INST,
} from '../../../../constants';

export const getDefaultUrlFromProfile = (profile: Person, schoolConfig: SchoolConfig): string => {
  return getDefaultUrl(profile.uaTip, profile, profile.prefLangID, '', schoolConfig);
};

export const getDefaultUrl = (
  uaTip: string,
  profile: Person,
  lang: string,
  link: string,
  schoolConfig: SchoolConfig
): string => {
  let defaultUrl = '';
  let permisiuni: string[] = [];

  if (uaTip) {
    switch (uaTip) {
      case TYPE_ELEV:
      case TYPE_PARINTE:
        defaultUrl = `/${lang}/dashboard`;
        break;
      case TYPE_CADRU:
        permisiuni = profile.permisiuni.map((v) => v.permID);

        if (
          (permisiuni.includes(PERM_ADMISSION_MANAGER) ||
            permisiuni.includes(PERM_ADMISSION_OFFICER)) &&
          permisiuni.filter((p) => ![PERM_ADMISSION_MANAGER, PERM_ADMISSION_OFFICER].includes(p))
            .length === 0
        ) {
          defaultUrl = `/${lang}/admission`;
          break;
        }

        if (
          [PERM_CAD_EDUCATOR, PERM_CAD_INVATATOR, PERM_CAD_PROFESOR].filter((x) =>
            permisiuni.includes(x)
          ).length
        ) {
          Cookies.set('MPR', 1);
          defaultUrl = `/${lang}/dashboard`;
        } else if (
          [PERM_CAD_SECRETAR, PERM_CAD_DIRECTOR, PERM_CAD_REP_ADSERVIO].filter((x) =>
            permisiuni.includes(x)
          ).length
        ) {
          Cookies.set('MPR', 0);
          defaultUrl = `/${lang}/dashboard`;
        } else {
          Cookies.set('MPR', 1);
          defaultUrl = `/${lang}/news`;
        }

        break;
      case TYPE_ADMIN:
        defaultUrl = `/${lang}/admin`;
        break;
      case TYPE_PERS_INST:
        defaultUrl = `/${lang}/institutions`;
        break;
      case TYPE_CANDIDATE:
        defaultUrl = `/${lang}/admission/offers`;
        break;
      case PERM_CAD_AUX:
        defaultUrl = `/${lang}/dashboard`;
        break;
      default:
        defaultUrl = `/${lang}`;
        break;
    }
  } else {
    defaultUrl = `/${lang}${link ? `/${link}` : ''}`;
  }

  return defaultUrl;
};
