import React from 'react';
import { TableFooterProps } from 'react-table';

import { ColumnPropsItem } from '@ui2/components/organisms';

import { CKTfoot, Th, Tr } from '..';

interface TableFootContainerProps {
  children: React.ReactElement[];
}

export const TableFootContainer = React.forwardRef<
  HTMLTableSectionElement,
  TableFootContainerProps
>(({ children }, ref) => {
  return (
    <CKTfoot
      ref={ref}
      sx={{
        px: '4',
        py: '3',
        bg: 'gray.50',
        color: 'gray.700',
        alignItems: 'center',
        '@media print': {
          '.chakra-table__container': {
            whiteSpace: 'unset !important',
          },
        },
      }}
    >
      {children}
    </CKTfoot>
  );
});

interface TableFootCellProps {
  columnProps?: ColumnPropsItem;
}

export const TableFootCell = React.forwardRef<
  HTMLTableCellElement,
  React.PropsWithChildren<TableFooterProps & TableFootCellProps>
>(({ columnProps, children, ...props }, ref) => {
  const extraStyles = {};

  const { backgroundColor, bgColor, ...rest } = columnProps || {};

  if (backgroundColor || bgColor) {
    extraStyles['backgroundColor'] = `${backgroundColor || bgColor} !important`;
  }

  return (
    <Th ref={ref} {...props} sx={{ ...extraStyles, ...rest }}>
      {children}
    </Th>
  );
});

export const TableFootRow = React.forwardRef<
  HTMLTableRowElement,
  TableFooterProps & { children: React.ReactElement[] }
>(({ children, ...props }, ref) => (
  <Tr ref={ref} data-testid="table-foot" alignItems="center" {...props}>
    {children}
  </Tr>
));
