import { PhoneWithPrefix } from '@api/core/entities';
import { PhoneNumberWithPrefix, PhoneNumberWithPrefixRequired } from '@lib/validators';
import { BUCHAREST_COUNTY_CODE } from '@modules/shared/utils';
import { Type } from 'class-transformer';
import { IsEmail, IsIBAN, IsNotEmpty, Matches, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { OrganisationType } from '../TenantBillingProfile';

@Entity
export class BillingProfileInformationInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.companyName.required' })
  name: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.alias.required' })
  alias: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.fiscalCode.required' })
  fiscalCode: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.address.required' })
  street: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.zipCode.required' })
  zipCode: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.country.required' })
  country: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.county.required' })
  county: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.county !== BUCHAREST_COUNTY_CODE)
  @IsNotEmpty({ message: 'errors.locality.required' })
  city: string = '';

  @Of(() => String)
  countryId: string = '';

  @Of(() => String)
  countyId: string = '';

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.county === BUCHAREST_COUNTY_CODE)
  @IsNotEmpty({ message: 'errors.locality.required' })
  cityId?: string;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.email.required' })
  @IsEmail(undefined, { message: 'errors.email.isValid' })
  email: string = '';

  @Of(() => PhoneWithPrefix)
  @Type(() => PhoneWithPrefix)
  @PhoneNumberWithPrefixRequired({ message: 'errors.phoneWithPrefix.required' })
  @PhoneNumberWithPrefix({ message: 'errors.phoneWithPrefix.invalid' })
  phoneNumber: PhoneWithPrefix = PhoneWithPrefix.of({});

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.bankName.required' })
  bankName: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.iban.required' })
  @IsIBAN({ message: 'errors.iban.isValid' })
  iban: string = '';

  @Of(() => Boolean)
  displayBankOnInvoice: boolean = false;

  @Of(() => Boolean)
  acceptsOnlinePayments: boolean = false;

  @Of(() => String, { optional: true })
  @IsNotEmpty({ message: 'errors.organisationType.required' })
  organisationType?: OrganisationType;

  @Of(() => String, { optional: true })
  logoURL?: string;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => !!o.nrJ)
  @Matches(/^[JFC]([0-9]{2})\/([0-9]+)\/([0-9.]+)$/, {
    message: 'errors.nrJ.isValid',
  })
  nrJ?: string;

  @Of(() => String, { optional: true })
  capital?: string;

  @Of(() => Boolean)
  @IsNotEmpty({ message: 'errors.value.required' })
  eFacturaActive: boolean = false;

  static of = Entity.of<BillingProfileInformationInput>();
}
