import { Entity, Of } from 'entity-of';

@Entity
export class UniversityPersonalSearchPayload {
  @Of(() => String)
  name: string = '';

  @Of(() => String)
  cnp: string = '';

  @Of(() => String)
  role: string = '';

  @Of(() => Boolean)
  admin: boolean = false;

  @Of(() => Boolean)
  manager: boolean = false;

  @Of(() => Boolean)
  associate: boolean = false;

  @Of(() => Boolean)
  student: boolean = false;

  @Of(() => Boolean)
  professor: boolean = false;

  @Of(() => Number)
  facultyId: number = 0;

  @Of(() => String)
  type: string = '';

  @Of(() => Date)
  startDate: Date = new Date();

  @Of(() => Date)
  endDate: Date = new Date();

  static of = Entity.of<UniversityPersonalSearchPayload>();
}
