import { Entity, Of } from 'entity-of';

export class StudyYear {
  @Of(() => String)
  astID: string = '';

  @Of(() => String)
  astNume: string = '';

  @Of(() => String)
  astPrefixClasa: string = '';

  @Of(() => String)
  astCicluID: string = '';

  @Of(() => String)
  astSistemNotare: string = '';

  @Of(() => String)
  astNrOrdine: string = '';

  @Of(() => String)
  astClasamentActiv: string = '';

  @Of(() => String)
  cicluID: string = '';

  @Of(() => String)
  cicluNume: string = '';

  @Of(() => String)
  cicluNrOrdine: string = '';

  static of = Entity.of<StudyYear>();
}
