import { Entity, Of } from 'entity-of';
@Entity
export class ParentSearchInput {
  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  studentID?: string = '';

  @Of(() => String, { optional: true })
  clID?: string;

  @Of(() => String, { optional: true })
  epElevID?: string;

  @Of(() => Boolean, { optional: true })
  includeTransferred?: boolean;

  static of = Entity.of<ParentSearchInput>();
}
