import { Entity, Of } from 'entity-of';

@Entity
export class Memento {
  @Of(() => String)
  cesID = '';

  @Of(() => String)
  cesDateType = '';

  @Of(() => String)
  cesStartDate = '';

  @Of(() => String)
  cesEndDate = '';

  @Of(() => MementoInfo)
  eventInfo = MementoInfo.of({});

  static of = Entity.of<Memento>();
}

@Entity
export class MementoInfo {
  @Of(() => String)
  ceID = '';

  @Of(() => String)
  ceName = '';

  @Of(() => String)
  ceDescription = '';

  @Of(() => String)
  ceStyle: 'event-style1' | 'event-style2' | 'event-style3' | 'event-style4' | 'event-style5' =
    'event-style1';

  static of = Entity.of<MementoInfo>();
}
