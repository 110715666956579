import { Entity, Of } from 'entity-of';

@Entity
export class EditorSelection {
  @Of(() => Number)
  index = 0;

  @Of(() => Number)
  length = 0;

  static of = Entity.of<EditorSelection>();
}
