import { Entity, Of } from 'entity-of';

@Entity
export class LessonPlanInput {
  @Of(() => String)
  date: string = '';

  @Of(() => String)
  description: string = '';

  @Of(() => String)
  isPublic: string = '';

  static of = Entity.of<LessonPlanInput>();
}
