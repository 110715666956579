import { Entity, Of } from 'entity-of';

import { FillersDetail } from '../FillerDetail';

@Entity
export class Filler {
  @Of(() => FillersDetail)
  clDetails? = FillersDetail.of({});

  @Of(() => String)
  type: string = '';

  static of = Entity.of<Filler>();
}
