import { Entity, Of } from 'entity-of';

@Entity
export class SchoolsTableFilters {
  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => String, { optional: true })
  startDate?: string;

  @Of(() => String, { optional: true })
  endDate?: string;

  @Of(() => String, { optional: true })
  status?: string;

  @Of(() => String, { optional: true })
  managedBy?: string;

  static of = Entity.of<SchoolsTableFilters>();
}
