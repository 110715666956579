// @ts-nocheck
import {
  LOAD_BOOKS_ERROR,
  LOAD_BOOKS_PENDING,
  LOAD_BOOKS_SUCCESS,
  PARAMS_LOCATION_BOOK,
} from './libraryActions';

const initState = {
  books: [],
  error: false,
  countDataRedux: 0,
  countOffsetRedux: 0,
  thePositionRedux: 0,
  booksRedux: [],
};

const libraryReducer = (state = initState, action) => {
  switch (action.type) {
    case LOAD_BOOKS_SUCCESS:
      return {
        ...state,
        books: action.books,
      };

    case LOAD_BOOKS_ERROR:
      return {
        ...state,
        error: action.error,
      };

    case LOAD_BOOKS_PENDING:
      return {
        ...state,
        books: action.books,
      };

    case PARAMS_LOCATION_BOOK:
      return {
        ...state,
        countDataRedux: action.countDataRedux,
        countOffsetRedux: action.countOffsetRedux,
        thePositionRedux: action.thePositionRedux,
        booksRedux: action.booksRedux,
      };

    default:
      return state;
  }
};

export default libraryReducer;
