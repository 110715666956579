import React, { ChangeEventHandler, forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast } from '../../molecules';

import { StyledFileInput, UploadButton } from './FileUpload.theme';

const baseUrl = window.env?.V2_PUBLIC_API_BASE_URL || '';

export interface EndpointOptions {
  uploadUrl: string;
  downloadUrl?: string;
  payload?: Record<string, string>;
  fileKeyName: string;
  headers?: Record<string, string>;
  isPresigned?: boolean;
}

interface FileUploaderProps<T> {
  name?: string;
  label?: string;
  variant?: string;
  colorScheme?: string;
  size?: string;
  boxSize?: string;
  width?: string | number | object;
  accept?: string;
  apiBaseUrl?: string;
  isLoading?: boolean;
  endpointOptions?: EndpointOptions;
  onChange?(file: File): void;
  onLoading?(value: boolean): void;
  onProgress?(value: number): void;
  onLoadStart?(): void;
  onLoadEnd?(value: any): void;
  onError?(error: string): void;
  onSuccess?(response: T): void;
  invalidFileDescription?: string;
  renderCustom?: () => React.ReactElement;
  setFileNameUpload?: (fileName: string) => void;
}

function _FileUpload<T>(
  {
    name = 'fileUnploadInput',
    label,
    variant,
    colorScheme,
    size,
    boxSize,
    width,
    apiBaseUrl = baseUrl,
    endpointOptions,
    accept,
    isLoading,
    onChange,
    onLoading,
    onProgress,
    onLoadStart,
    onLoadEnd,
    onSuccess,
    onError,
    invalidFileDescription,
    renderCustom,
    setFileNameUpload,
  }: FileUploaderProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation('shared');

  const showToast = useToast();

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = async () => {
    if (!inputRef) return;

    const currentFiles = inputRef.current?.files;

    if (!currentFiles) return;

    for (const file of currentFiles) {
      if (accept && !accept.includes(file.type)) {
        inputRef.current.value = '';

        return showToast({
          title: t('general.invalid_file'),
          description: invalidFileDescription ?? t('general.invalid_file_description'),
          status: 'error',
        });
      }

      onChange?.(file);

      const xhr = new XMLHttpRequest();

      xhr.upload.onloadstart = () => {
        onLoading?.(true);
        onLoadStart?.();
      };

      xhr.upload.onprogress = (e) => {
        const percentage = Math.floor((e.loaded * 100) / e.total);
        onProgress?.(percentage);
      };

      xhr.upload.onerror = () => {
        onLoading?.(false);
      };

      xhr.upload.onabort = () => {
        onLoading?.(false);
      };

      xhr.upload.ontimeout = () => {
        onLoading?.(false);
      };

      xhr.onloadend = (e) => {
        if (xhr.status >= 200 && xhr.status < 300) {
          onLoadEnd?.(e);
        } else {
          console.error(e);
          onError?.('');
        }

        onLoading?.(false);
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let data = JSON.parse(xhr.responseText);
          onSuccess?.(data);
          setFileNameUpload?.(file.name);
        } else if (xhr.readyState === 4) {
          onError?.('Could not fetch data.');
        }
      };

      if (!endpointOptions) {
        inputRef.current.value = '';
        return;
      }

      const formData = new FormData();

      if (endpointOptions) {
        xhr.open('POST', `${apiBaseUrl}${endpointOptions.uploadUrl}`);

        if (Object.keys(endpointOptions.headers || {})?.length) {
          Object.keys(endpointOptions.headers || {}).forEach((key) => {
            xhr.setRequestHeader(key, String(endpointOptions.headers?.[key]));
          });
        }

        formData.append(endpointOptions.fileKeyName ?? 'content', file);

        if (endpointOptions.payload) {
          Object.keys(endpointOptions.payload).forEach((key) => {
            formData.append(key, String(endpointOptions.payload?.[key]));
          });
        }
      }

      xhr.send(formData);
    }

    inputRef.current.value = '';
    onProgress?.(0);
  };

  return (
    <>
      {renderCustom?.() || (
        <UploadButton
          name={name}
          ref={ref as any}
          variant={variant}
          colorScheme={colorScheme}
          size={size}
          label={label || ''}
          boxSize={boxSize}
          width={width}
          isLoading={isLoading}
        />
      )}

      <StyledFileInput
        type="file"
        ref={inputRef}
        accept={accept}
        id={name}
        onChange={handleOnChange}
        data-testid={name}
      />
    </>
  );
}

export const FileUpload = forwardRef(_FileUpload) as <T>(
  props: FileUploaderProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> }
) => ReturnType<typeof _FileUpload>;
