import { Person } from '@api/core/entities';
import { TYPE_CADRU, TYPE_ELEV } from '@constants';
import { PersonExcerpt } from '@modules/shared';
import { usePersonFormQuery } from '@modules/Universities/university-personal/cache';
import { Box, Loader, Modal } from '@ui2/components';

import { StaffCard, StudentCard } from './PersonInfoModal.layout';

interface PersonInfoDialogProps {
  person: Person;
  isOpen: boolean;
  onClose: () => void;
}

export const PersonInfoModal = ({ person, isOpen, onClose }: PersonInfoDialogProps) => {
  const { uaTip, uaID } = person;

  const { data: personExtraInfo, isLoading: personExtraInfoLoading } = usePersonFormQuery(
    { userId: Number(uaID) },
    { enabled: isOpen }
  );

  const associatedGroups = personExtraInfo?.associatedGroups;

  return (
    <>
      {isOpen && (
        <Modal
          size="xl"
          hideFooter
          isOpen={isOpen}
          onClose={onClose}
          renderHeader={() => (
            <PersonExcerpt person={person} detailsConfig={{ useInfoModal: false }} />
          )}
          contentProps={{ height: 'auto' }}
        >
          <Loader size="xl" isLoading={personExtraInfoLoading} py="12">
            {uaTip === TYPE_ELEV && associatedGroups && (
              <Box pb="2">
                {associatedGroups.map((group) => (
                  <StudentCard key={group.id} group={group} />
                ))}
              </Box>
            )}
            {uaTip === TYPE_CADRU && personExtraInfo && <StaffCard staffInfo={personExtraInfo} />}
          </Loader>
        </Modal>
      )}
    </>
  );
};
