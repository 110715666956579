import { IsNotEmpty, Matches, MaxLength } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class CreateNationalityInput {
  @Of(() => String)
  @Matches(/^[^-\s]/, {
    message: 'errors.admission.no_white_space',
  })
  @IsNotEmpty({ message: 'errors.natName.required' })
  @MaxLength(80, {
    message: 'errors.max_80',
  })
  natName: string = '';

  @Of(() => Boolean)
  natDefault: boolean = false;

  static of = Entity.of<CreateNationalityInput>();
}
