import { Entity, Of } from 'entity-of';

@Entity
export class CertificateType {
  @Of(() => String)
  cerTypeID = '';

  @Of(() => String)
  cerTypeName = '';

  @Of(() => String)
  cerTypeCategory = '';

  static of = Entity.of<CertificateType>();
}
