import { Filters, Order } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class SubscriptionServicesInput {
  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => [Filters], { optional: true })
  filters?: Filters[];

  @Of(() => [Order], { optional: true })
  order?: [Order];

  @Of(() => String)
  subscriberId: string = '';

  @Of(() => Boolean)
  isOrganisation: boolean = false;

  @Of(() => String)
  endDate: string = '';

  @Of(() => Number, { optional: true })
  offset?: number = 0;

  @Of(() => Number, { optional: true })
  limit?: number = 0;

  static of = Entity.of<SubscriptionServicesInput>();
}
