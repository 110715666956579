import { Entity, Of } from 'entity-of';

@Entity
export class SubscriptionFilters {
  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => String, { optional: true })
  serviceType?: string;

  static of = Entity.of<SubscriptionFilters>();
}
