import React, { forwardRef, useEffect, useState } from 'react';

import { InputGroup, InputGroupProps } from '../../atoms/InputGroup';

import { PhoneInputPrefixAddon } from './PhoneInputPrefixAddon';
import { phonePrefixes } from '.';

export type PhoneWithPrefix = {
  phone: string | undefined;
  prefix: string | undefined;
};

export type CountryPhoneData = {
  name: string;
  dial_code: string;
  code: string;
};

export interface PhoneInputProps extends Omit<InputGroupProps, 'value' | 'onChange'> {
  value?: PhoneWithPrefix;
  onChange?: (value: PhoneWithPrefix) => void;
}

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ value, onChange, ...props }, ref) => {
    const [selectedPhonePrefix, setSelectedPhonePrefix] = useState<CountryPhoneData | undefined>();

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      onChange?.(
        (() => {
          if (!value) {
            return { phone: e.target.value, prefix: '' };
          }

          if (e.target.value.charAt(0) === '0') {
            return { ...value, phone: e.target.value.substring(1) };
          }

          return { ...value, phone: e.target.value };
        })()
      );
    };

    useEffect(() => {
      if (value?.prefix) {
        setSelectedPhonePrefix(phonePrefixes.find((prefix) => prefix.dial_code === value?.prefix));
      }
    }, [value?.prefix]);

    const handlePrefixChange = (prefix: CountryPhoneData | undefined) => {
      setSelectedPhonePrefix(prefix);

      onChange?.(
        (() => {
          if (!value) {
            return { phone: '', prefix: prefix?.dial_code };
          }

          if (!prefix) {
            return { ...value, prefix: '' };
          }

          if (!value && !prefix) {
            return { phone: '', prefix: '' };
          }

          return { ...value, prefix: prefix?.dial_code };
        })()
      );
    };

    return (
      <InputGroup
        ref={ref}
        inputMode="tel"
        autoComplete="off"
        value={value?.phone ?? ''}
        onChange={handleChange}
        leftAddonSx={{
          overflow: 'hidden',
          bg: 'white',
          px: '0',
        }}
        leftAddon={
          <PhoneInputPrefixAddon
            selectedPhonePrefix={selectedPhonePrefix}
            handlePrefixChange={handlePrefixChange}
          />
        }
        {...props}
      />
    );
  }
);
