import { memo, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import { omitByEmpty } from '@lib/utils';

import { useRouter } from '../../../../Libs/router';

export interface AppLinkProps extends LinkProps {
  inactive?: boolean;
  nonTextual?: boolean;
  isNative?: boolean;
  nativeTarget?: string;
  linkWidth?: string;
  state?: Record<string, unknown>;
  queryParams?: Record<string, string | number>;
  hash?: string;
}

export const AppLink = memo<PropsWithChildren<AppLinkProps>>(
  ({
    href,
    state = {},
    queryParams,
    inactive = false,
    nonTextual = true,
    hash,
    isNative,
    nativeTarget,
    linkWidth,
    children,
    ...rest
  }) => {
    const { lang } = useRouter();

    const content = nonTextual ? children : <Button variant="link">{children}</Button>;
    const url = href?.startsWith('http') ? href : `/${lang}/${href}`;

    let params = '';

    if (queryParams) {
      params = `?${new URLSearchParams({ ...omitByEmpty(queryParams) })}`;
    }

    let urlWithParams = url + params;

    if (hash) {
      urlWithParams += `#${hash}`;
    }

    return (
      <>
        {href ? (
          <>
            {isNative ? (
              <a
                href={urlWithParams}
                target={nativeTarget || '_self'}
                style={{
                  pointerEvents: href ? 'initial' : 'none',
                  width: linkWidth,
                }}
              >
                {content}
              </a>
            ) : (
              <Link
                to={{ pathname: url, state, search: params, hash }}
                style={{ color: 'inherit', width: linkWidth }}
              >
                <ChakraLink
                  as={Box}
                  height="100%"
                  cursor={inactive ? 'default' : undefined}
                  pointerEvents={inactive ? 'none' : undefined}
                  _hover={{ textUnderline: 'none' }}
                  {...rest}
                >
                  {content}
                </ChakraLink>
              </Link>
            )}
          </>
        ) : (
          <>{content}</>
        )}
      </>
    );
  }
);
