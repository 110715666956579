import { Entity, Of } from 'entity-of';

@Entity
export class Subject {
  @Of(() => String)
  matID: string = '';

  @Of(() => String)
  matNume: string = '';

  @Of(() => String)
  matPrescurtare: string = '';

  @Of(() => String)
  matNrOrdine: string = '';

  @Of(() => String)
  matOptScutire: string = '';

  @Of(() => String)
  matOpt: string = '';

  @Of(() => String)
  matRapoarteElevi: string = '';

  @Of(() => String)
  matPurtare: string = '0';

  @Of(() => String, { optional: true })
  matCountClase?: string;

  @Of(() => [MatRoom])
  matRooms: MatRoom[] = [];

  @Of(() => String)
  matColor: string = '';

  @Of(() => String)
  cmcSistemNotare = '';

  static of = Entity.of<Subject>();
}

@Entity
export class MatRoom {
  @Of(() => String)
  msID: string = '';

  @Of(() => String)
  msMatID: string = '';

  @Of(() => String)
  msRoomID: string = '';

  @Of(() => String)
  msRoomName: string = '';

  static of = Entity.of<MatRoom>();
}
