import { PersonClass, Subject } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class AssociatedClasses {
  @Of(() => String)
  cmcID: string = '';

  @Of(() => Subject)
  cmcMaterie: Subject = Subject.of({});

  @Of(() => PersonClass)
  cmcClasa: PersonClass = PersonClass.of({});
  static of = Entity.of<AssociatedClasses>();
}
