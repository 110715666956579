import {
  Account,
  LanguageItem,
  LegacyPaginatedResponse,
  Person,
  PersonClass,
  School,
  SchoolConfig,
  SchoolYear,
  Student,
  Teacher,
} from '@api/core/entities';
import { Package, SessionFeatures } from '@api/subscriptions/entities';
import { StudentProgram, UserSession } from '@lib/utils';
import Cookie from 'js-cookie';

import { Callout } from '../Components/UpgradeCallout/entities';
import {
  ORG_UNIVERSITY,
  TYPE_ADMIN,
  TYPE_CADRU,
  TYPE_CANDIDATE,
  TYPE_ELEV,
  TYPE_PARINTE,
  TYPE_PERS_INST,
} from '../constants';
import i18n from '../i18n';
import Api2 from '../Libs/api';
import { removeTokenCookie } from '../Modules/Auth/helpers';

export const SESSION_SET = 'SESSION_SET';
export const SESSION_UNSET = 'SESSION_UNSET';
export const SESSION_SWITCH_ACCOUNT = 'SESSION_SWITCH_ACCOUNT';
export const ADS_NATIVE = 'ADS_NATIVE';
export const UNSET_ADS_NATIVE = 'UNSET_ADS_NATIVE';
export const TOGGLE_HEADER = 'TOGGLE_HEADER';
export const TOGGLE_FOOTER = 'TOGGLE_FOOTER';
export const TOGGLE_MENU = 'TOGGLE_MENU';

import axios from 'axios';

import { getDefaultUrl } from '../Modules/Site/components/redirect-home/getDefaultUrl';

/** BEGINING OF SESSION FUNCTIONS */

const loadAnScolar = (profile: Person) => {
  if ([TYPE_CANDIDATE].includes(profile.uaTip)) {
    return new Promise<SchoolYear>((res) => {
      res({} as SchoolYear);
    });
  }

  return new Promise<SchoolYear>((res, rej) => {
    Api2.get<{ data: SchoolYear[] }>('/ani/scolari', {
      ascIsActiv: 1,
    })
      .then((response) => {
        if (response.data.length === 1) {
          res(response.data[0]);
        } else {
          rej('Invalid response.');
        }
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadSchool = (liceuID: string) => {
  return new Promise<School>((res, rej) => {
    Api2.get<School>('/scoli/detalii', {
      liceuID,
      cuReprezentantUaID: 1,
    })
      .then((response) => {
        if (response) res(response);
        else rej('Invalid response.');
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadSchoolConfig = (liceuID: string, profile?: Person) => {
  if (profile?.uaTip === TYPE_CANDIDATE) {
    return new Promise<SchoolConfig>((res) => {
      res({} as SchoolConfig);
    });
  }

  return new Promise<SchoolConfig>((res, rej) => {
    Api2.get<SchoolConfig>('/scoli/config', {
      liceuID,
    })
      .then(async (response) => {
        if (response) {
          if (profile && response.subscriptions?.isActive === '1') {
            await loadCallouts(profile.uaID);

            if (![TYPE_CANDIDATE, TYPE_PERS_INST, TYPE_ADMIN].includes(profile.uaTip)) {
              await loadFeatures();
            }

            if ([TYPE_ELEV, TYPE_PARINTE].includes(profile.uaTip)) {
              await loadSubscriptionPackages(profile.liceu.taraID);
            }
          }

          res(response);
        } else {
          rej('Invalid response.');
        }
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadUniStudentPrograms = (studentId: string) => {
  return new Promise<StudentProgram[]>((res, rej) => {
    Api2.get<{ data: StudentProgram[] }>(`/uni/programs/user/${studentId}`)
      .then(async (response) => {
        if (response) {
          res(response.data);
        } else {
          rej('Invalid response.');
        }
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadProfile = (uaID?: string, liceuID?: string) => {
  return new Promise<Person>((res, rej) => {
    Api2.get<Person>('/utilizatori/profile', {
      uaID,
      cuPermisiuni: 1,
      cuAbonament: 1,
      withRoles: 1,
      _liceuID: liceuID,
    })
      .then(res)
      .catch(rej);
  });
};

const loadAdservioAccounts = (uaID: string) => {
  return new Promise<Account[]>((res, rej) => {
    Api2.get<Account[]>('/accounts/adservio', {
      uaID,
    })
      .then(res)
      .catch(rej);
  });
};

const loadFeatures = async () => {
  return new Promise((res, rej) => {
    Api2.get<SessionFeatures>('/subscriptions/subscribers/features')
      .then((features) => {
        localStorage.setItem('features', JSON.stringify(features));
        res({});
      })
      .catch((e) => {
        localStorage.setItem('features', JSON.stringify({}));
        rej(e);
      });
  });
};

const loadSubscriptionPackages = async (countryId: string) => {
  return new Promise((res, rej) => {
    Api2.get<{ data: Package[] }>(
      `/subscriptions/packages/available?subscriberType=student&countryId=${countryId}`
    )
      .then((response) => {
        localStorage.setItem('subscriptionPackages', JSON.stringify(response.data));
        res({});
      })
      .catch((e) => {
        localStorage.setItem('subscriptionPackages', JSON.stringify([]));
        rej(e);
      });
  });
};

const loadCallouts = async (uaID: string) => {
  return new Promise((res, rej) => {
    Api2.get<LegacyPaginatedResponse<Callout>>(`/callouts?uaID=${uaID}`)
      .then((response) => {
        localStorage.setItem('callouts', JSON.stringify(response.data));
        res({});
      })
      .catch((e) => {
        localStorage.setItem('callouts', JSON.stringify([]));
        rej(e);
      });
  });
};

const loadClaseElev = (elevID: string) => {
  return new Promise<PersonClass[]>((res, rej) => {
    Api2.get<{ data: PersonClass[] }>('/elevi/clase', {
      elevID,
      cuCiclu: 1,
    })
      .then((response) => {
        res(response.data);
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadParinteElev = (epID: string) => {
  return new Promise<Student>((res, rej) => {
    Api2.get<Student>('/parinti/elev', {
      epID,
    })
      .then((response) => {
        res(response);
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadDetaliiCadru = (cadID: string) => {
  return new Promise<Teacher>((res, rej) => {
    Api2.get<Teacher>('/cadre/detalii', {
      cadID,
    })
      .then((response) => {
        res(response);
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const loadFromStorage = async () => {
  if (!window.localStorage) throw new Error();

  if (!localStorage.getItem('session')) {
    throw new Error();
  }

  const session = JSON.parse(localStorage.getItem('session') || '{}') as UserSession;

  if (!session?.atID) {
    throw new Error();
  }

  const tokenData = await Api2.getTokenId();
  if (!tokenData) throw new Error();

  // verificam daca avem acelasi token in cookie si in session
  if (session.atID !== tokenData.atID) throw new Error();

  // totul e ok dar facem un refresh la sesiune macar la o ora
  if (Math.round(new Date().getTime() / 1000) - session.lastUpdate > 3600) throw new Error();

  return session;
};

const loadLanguages = () => {
  return new Promise<LanguageItem[]>((res, rej) => {
    Api2.get<{ data: LanguageItem[] }>('/enumerations/languages?langStatus=2')
      .then((response) => {
        res(response.data);
      })
      .catch((e) => {
        rej(e);
      });
  });
};

const sendMobileArtificialDelay = async () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    await new Promise((res) => {
      setTimeout(() => {
        if (window.NATIVE) {
          window.NATIVE.postMessage(
            JSON.stringify({
              type: 'artificial_delay',
            })
          );
        }

        res({});
      }, 2000);
    });
  }
};

const init = async (adminRefId?: string) => {
  return new Promise<UserSession>((resolve, reject) => {
    loadFromStorage()
      .then((data) => {
        localStorage.removeItem('orgId');

        if ([TYPE_ADMIN, TYPE_PERS_INST].includes(data.profile.uaTip) && data.school.liceuID) {
          Api2.setLiceuID(data.school.liceuID);
          localStorage.setItem('orgId', data.school.liceuID);
        }

        resolve(data);
      })
      .catch(async () => {
        localStorage.removeItem('orgId');
        localStorage.removeItem('session');

        const tokenData = await Api2.getTokenId();

        if (!tokenData) {
          return reject();
        }

        sendMobileArtificialDelay();

        const newSession: UserSession = {} as UserSession;

        newSession.lastUpdate = Math.round(new Date().getTime() / 1000);
        newSession.atID = tokenData.atID;

        loadProfile(tokenData.uaID, adminRefId)
          .then(async (profile) => {
            if ([TYPE_ADMIN, TYPE_PERS_INST].includes(profile.uaTip)) {
              Api2.setLiceuID(adminRefId || profile.uaLiceuID);
            }

            const orgType = profile?.liceu?.liceuOrgType;

            newSession.profile = profile;
            i18n.changeLanguage(newSession.profile.prefLangID);

            await Promise.all([
              loadLanguages(),
              loadAnScolar(profile),
              loadSchoolConfig(adminRefId || profile.uaLiceuID, profile),
              loadAdservioAccounts(profile.uaID),
            ]).then((response) => {
              newSession.languages = response[0];
              newSession.anScolar = response[1];
              newSession.schoolConfig = response[2];
              newSession.accounts = response[3];
            });

            // daca avem cookie de admin incarcam si scoala pentru a o afisa in header
            if (
              Cookie.get('ADST_ADM_REF') &&
              ![TYPE_ADMIN, TYPE_PERS_INST].includes(profile.uaTip)
            ) {
              loadSchool(profile.uaLiceuID).then((data) => {
                newSession.school = data;
              });
            }

            if (profile.uaTip === TYPE_ELEV && orgType === ORG_UNIVERSITY) {
              await loadUniStudentPrograms(profile.uaID).then((response) => {
                newSession.studentProgram = response;
              });
            }

            if (profile.uaTip === TYPE_ELEV) {
              loadClaseElev(profile.uaID).then((response) => {
                newSession.clase = response;

                resolve(newSession);
              });
            }

            if (profile.uaTip === TYPE_PARINTE) {
              loadParinteElev(profile.uaID).then((response) => {
                newSession.elev = response;

                loadClaseElev(newSession.elev.elevID).then((response) => {
                  newSession.clase = response;

                  resolve(newSession);
                });
              });
            }

            if (profile.uaTip === TYPE_CADRU) {
              await loadCallouts(profile.uaID);

              await Promise.all([loadDetaliiCadru(profile.uaID)]).then((response) => {
                newSession.cadru = response[0];

                resolve(newSession);
              });
            }

            if (profile.uaTip === TYPE_ADMIN || profile.uaTip === TYPE_PERS_INST) {
              const schoolId = adminRefId || profile.uaLiceuID;

              if (schoolId) {
                Api2.setLiceuID(schoolId);

                loadSchool(schoolId)
                  .then((response) => {
                    newSession.school = response;

                    resolve(newSession);
                  })
                  .catch(reject);
              } else {
                resolve(newSession);
              }
            }

            if (profile.uaTip === TYPE_CANDIDATE) {
              resolve(newSession);
            }
          })
          .catch(() => {
            // some error occured, clear cookie
            removeTokenCookie();
            reject();
          });
      });
  });
};

export const defaultState = {
  profile: null,
  clase: null,
  elev: null,
  cadru: null,
  anScolar: null,
  ready: false,
  lastUpdated: null,
  isVisibleHeader: !Cookie.get('ads_native'),
  isVisibleFooter: !Cookie.get('ads_native'),
  isVisibleMenu: true,
  hasCookiesAdsNative: false,
};

export const persistSession = (data: UserSession) => {
  const newSession = {
    ...defaultState,
    lastUpdated: new Date(),
    ...data,
    isVisibleHeader: !Cookie.get('ads_native'),
    isVisibleFooter: !Cookie.get('ads_native'),
  };

  if (window.localStorage) {
    localStorage.setItem('session', JSON.stringify(newSession));
  }
};

interface SessionInitProps {
  adminRefId?: string;
  redirectToDefault?: boolean;
  redirectUrl?: string;
}

export const sessionInit = (props: SessionInitProps = {}) => {
  const { adminRefId, redirectToDefault, redirectUrl } = props;

  return (dispatch) => {
    init(adminRefId)
      .then((data) => {
        if (adminRefId) {
          data.ready = true;
          persistSession(data);

          window.location.assign(
            `/${data.profile?.prefLangID || 'ro'}/admin?liceuID=${adminRefId}`
          );
        } else {
          if (redirectToDefault) {
            data.ready = true;
            persistSession(data);

            const url =
              redirectUrl ||
              getDefaultUrl(
                data.profile.uaTip,
                data.profile,
                data.profile.prefLangID,
                '',
                data.schoolConfig
              );

            window.location.assign(url);
          } else {
            data.ready = true;

            dispatch({
              type: SESSION_SET,
              data,
            });
          }

          if (window.NATIVE) {
            window.NATIVE.postMessage(
              JSON.stringify({
                type: 'session_init',
                status: true,
              })
            );
          }
        }
      })
      .catch(() => {
        if (window.NATIVE) {
          window.NATIVE.postMessage(
            JSON.stringify({
              type: 'session_init',
              status: false,
            })
          );
        }

        dispatch({
          type: SESSION_SET,
          data: { ready: true },
        });
      });
  };
};

export const reloadProfile = () => {
  return (dispatch) => {
    const session: UserSession = JSON.parse(localStorage.getItem('session') || '{}');

    loadProfile().then((profile) => {
      const newSession = {
        ...session,
        profile,
      };

      if (window.localStorage) {
        localStorage.setItem('session', JSON.stringify(newSession));
      }
      dispatch({
        type: SESSION_SET,
        data: { profile },
      });
    });
  };
};

export const sessionUnset = () => {
  return {
    type: SESSION_UNSET,
  };
};

export const sessionSet = (data) => {
  return {
    type: SESSION_SET,
    data,
  };
};

export const sessionSwitchAccount = (account: Account, lang: string, redirect = '') => {
  return (dispatch) => {
    dispatch({
      type: SESSION_UNSET,
    });

    const stayLoggedIn = localStorage.getItem('stayLoggedIn');

    Api2.post('/auth/switch', {
      uaID: account.uaID,
      setCookie: 1,
      tokenLifetime: stayLoggedIn === 'true' ? '30' : '0',
    }).then(() => {
      sessionInit()(dispatch);

      window.location.assign(redirect || `/${lang}/auth`);
    });
  };
};

export const sessionSwitchLiceu = (liceuID: string, redirectUrl?: string) => {
  return (dispatch) => {
    dispatch({
      type: SESSION_UNSET,
    });

    init(liceuID).then((data) => {
      axios({
        method: 'get',
        url: `/home/switch_school?liceuID=${liceuID}`,
      }).then(() => {
        data.ready = true;

        dispatch({
          type: SESSION_SET,
          data,
        });

        if (redirectUrl) {
          window.location.assign(redirectUrl);
        }
      });
    });
  };
};

export const sessionReload = () => {
  return (dispatch) => {
    dispatch({
      type: SESSION_UNSET,
    });

    sessionInit()(dispatch);
  };
};
export function cookiesAdsNative() {
  return {
    type: ADS_NATIVE,
    hasCookiesAdsNative: !!Cookie.get('ads_native'),
  };
}

export function unsetCookiesAdsNative() {
  return {
    type: UNSET_ADS_NATIVE,
    hasCookiesAdsNative: false,
  };
}

export function toggleHeader() {
  return {
    type: TOGGLE_HEADER,
  };
}

export function toggleFooter() {
  return {
    type: TOGGLE_FOOTER,
  };
}

export function toggleMenu() {
  return {
    type: TOGGLE_MENU,
  };
}
