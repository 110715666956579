import { Entity, Of } from 'entity-of';

@Entity
export class AssignedServicesSearchInput {
  @Of(() => [String], { optional: true })
  accountIds?: string[];

  @Of(() => [Number], { optional: true })
  serviceIds?: number[];

  @Of(() => String, { optional: true })
  status?: 'Enabled' | 'Disabled' | 'Review';

  static of = Entity.of<AssignedServicesSearchInput>();
}
