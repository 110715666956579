import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class StudentPortfolioCategoryDeleteFormInput {
  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.required',
  })
  targetCategoryID!: string;

  static of = Entity.of<StudentPortfolioCategoryDeleteFormInput>();
}
