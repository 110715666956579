import { Entity, Of } from 'entity-of';

@Entity
export class AttendanceActivity {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  cadID: string = '';

  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  startDateTime: string = '';

  @Of(() => String)
  endDateTime: string = '';

  @Of(() => String)
  status: 'present' | 'missed' = 'missed';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  description: string = '';

  @Of(() => String, { nullable: true })
  timetableActivityID: string | null = null;

  @Of(() => String)
  cmcID: string = '';

  @Of(() => String)
  isVisibleStudents: string = '';

  @Of(() => String)
  isScheduled: string = '';

  @Of(() => String)
  createdAt: string = '';

  @Of(() => String, { nullable: true })
  updatedAt: string | null = null;

  static of = Entity.of<AttendanceActivity>();
}
