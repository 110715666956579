import { Entity, Of } from 'entity-of';

@Entity
export class PersonStaffFilter {
  @Of(() => String)
  name: string = '';

  @Of(() => String)
  cnp: string = '';

  @Of(() => String, { optional: true })
  role?: string;

  @Of(() => String)
  uaID?: string = '';

  static of = Entity.of<PersonStaffFilter>();
}
