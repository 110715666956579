import { Entity, Of } from 'entity-of';

@Entity
export class AccountBalance {
  @Of(() => Number)
  tenantBillingProfileId: number = 0;

  @Of(() => String)
  accountId: string = '';

  @Of(() => Number)
  amount: number = 0;

  @Of(() => String, { optional: true })
  createdAt?: string;

  @Of(() => String, { optional: true })
  updatedAt?: string;

  static of = Entity.of<AccountBalance>();
}
