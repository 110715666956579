import { IsNotEmpty, IsPositive, registerDecorator, ValidationOptions } from 'class-validator';
import { Entity, Of } from 'entity-of';

function MaxDecimals(validationOptions?: ValidationOptions) {
  return (object: object, propertyName: string) => {
    registerDecorator({
      name: 'maxDecimals',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [],
      options: validationOptions,
      validator: {
        validate(value: number) {
          const stringValue = value.toString();
          const decimalIndex = stringValue.indexOf('.');
          const decimals = decimalIndex === -1 ? 0 : stringValue.length - decimalIndex - 1;

          return decimals <= 2;
        },
      },
    });
  };
}

@Entity
export class PaymentInput {
  @Of(() => String)
  studentId: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.payment.amount.required' })
  paymentMethodId: string = '';

  @Of(() => Number)
  @IsPositive({ message: 'errors.accommodations.payment.amount.required' })
  @MaxDecimals({ message: 'errors.accommodations.payment.amount.max_decimals' })
  amount: number = 0;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.payment.doc_no.required' })
  documentNumber: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.payment.date.required' })
  paymentDate: string = '';

  static of = Entity.of<PaymentInput>();
}

@Entity
export class PaymentEditInput {
  @Of(() => String)
  studentId: string = '';

  @Of(() => String)
  paymentId: string = '';

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.accommodations.payment.amount.required' })
  paymentMethodId: number = 0;

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.accommodations.payment.amount.required' })
  @MaxDecimals({ message: 'errors.accommodations.payment.amount.max_decimals' })
  amount: number = 0;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.payment.doc_no.required' })
  documentNumber: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.payment.date.required' })
  paymentDate: string = '';

  static of = Entity.of<PaymentEditInput>();
}
