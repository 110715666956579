import React, { PropsWithChildren } from 'react';

import { InvoiceType } from '@api/billing/entities';
import { Grid } from '@ui2/components';
import { getOrgTypeTheme } from '@ui2/utils';

interface InvoiceCardContainerProps {
  isSelected?: boolean;
  type: InvoiceType;
  onClick?: React.MouseEventHandler;
}

export const CardContainer = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<InvoiceCardContainerProps>
>(({ onClick, children, isSelected, type }, ref) => {
  const baseColor = getOrgTypeTheme('blue');

  return (
    <Grid
      p="5"
      w="full"
      ref={ref}
      tabIndex={0}
      outline="unset"
      cursor="pointer"
      onClick={onClick}
      borderRadius="xl"
      columnGap="4"
      transitionProperty="common"
      transitionDuration="normal"
      bg={isSelected ? `${baseColor}.50` : 'white'}
      borderWidth="1px"
      data-testid="invoice-card"
      borderColor={isSelected ? `${baseColor}.500` : 'gray.200'}
      templateColumns={{
        base: '1fr auto',
        md: type === InvoiceType.PROFORMA && isSelected ? 'auto 1fr auto' : '1fr auto',
      }}
      boxShadow={isSelected ? 'unset' : 'xs'}
      _hover={{
        boxShadow: isSelected ? 'inputFocus' : 'inputFocus',
        borderColor: `${baseColor}.300`,
      }}
      _focus={{
        boxShadow: isSelected ? 'inputFocus' : 'inputFocus',
        borderColor: `${baseColor}.300`,
      }}
    >
      {children}
    </Grid>
  );
});
