import { Entity, Of } from 'entity-of';

@Entity
export class ServiceDetailsInput {
  @Of(() => Number)
  serviceId: number = 0;

  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  static of = Entity.of<ServiceDetailsInput>();
}
