import { Person } from '@api/core/entities/utilizatori';
import { IsNotBlank } from '@lib/utils';
import {
  IsNotEmpty,
  IsNumber,
  MaxLength,
  NotEquals,
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';
import { Entity, Of } from 'entity-of';
@ValidatorConstraint({ name: 'BlockedPeriodValidator', async: false })
class BlockedPeriodValidatorConstraint implements ValidatorConstraintInterface {
  validate(value: string, args: ValidationArguments) {
    const object = args.object as AddWarningForm;
    const blockedPeriod = object.blockedPeriod as PeriodBlockedInfo[];

    if (blockedPeriod?.length > 0) {
      return !(value !== null && value !== undefined && value !== '');
    }

    return true;
  }

  defaultMessage(_: ValidationArguments) {
    return ' ';
  }
}

export function BlockedPeriodValidator(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: BlockedPeriodValidatorConstraint,
    });
  };
}
@Entity
export class AddWarningForm {
  @IsNumber({}, { message: 'errors.avtNumar.required' })
  @NotEquals(null, { message: 'errors.avtNumar.required' })
  @Of(() => Number, { optional: true })
  avtNumar?: number;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.avtData.required' })
  @BlockedPeriodValidator({ message: ' ' })
  avtData: string = '';

  @MaxLength(1000, { message: 'errors.max_1000' })
  @IsNotBlank({ message: 'errors.avtMotiv.required' })
  @IsNotEmpty({ message: 'errors.avtMotiv.required' })
  @Of(() => String)
  avtMotiv: string = '';

  @Of(() => String)
  avtShowInReports: '0' | '1' = '0';

  @Of(() => [PeriodBlockedInfo], { optional: true })
  blockedPeriod?: PeriodBlockedInfo[];

  static of = Entity.of<AddWarningForm>();
}

@Entity
export class PeriodBlockedInfo {
  @Of(() => String)
  createdAt: string = '';

  @Of(() => Person)
  createdBy: Person = Person.of({});

  @Of(() => String)
  dateEnd: string = '';

  @Of(() => String)
  dateStart: string = '';

  @Of(() => String)
  createdByName: string = '';

  @Of(() => String)
  id: string = '';

  @Of(() => String)
  reson: string = '';

  static of = Entity.of<PeriodBlockedInfo>();
}
