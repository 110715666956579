import { useQuery, UseQueryOptions } from 'react-query';

import { AccountBillingProfile } from '@api/billing/entities';
import { httpClient } from '@lib/http';
import { AxiosError } from 'axios';

type QueryInput = {
  accountId: string;
  rootOrgId?: string;
  xAccountId?: string;
  createProfile?: boolean;
};

const getProfiles = ({ queryKey }: UseQueryOptions<QueryInput>) => {
  const [_, queryPayload] = queryKey ?? [];

  const { accountId, rootOrgId, xAccountId, createProfile } = queryPayload as QueryInput;

  let headers = {};

  if (rootOrgId) {
    headers = { ...headers, 'X-Tenant-ID': rootOrgId };
  }

  if (xAccountId) {
    headers = { ...headers, 'X-Account-ID': xAccountId };
  }

  return httpClient<AccountBillingProfile[]>(
    `/billing/profiles/accounts/${accountId}${createProfile ? '?createProfile=true' : ''}`,
    {
      headers,
    }
  ).get();
};

export const useAccountProfilesQuery = (
  queryInput: QueryInput,
  options?: UseQueryOptions<AccountBillingProfile[], AxiosError>
) => {
  return useQuery<AccountBillingProfile[], AxiosError>(
    ['myProfiles', queryInput],
    getProfiles,
    options
  );
};
