import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { chakra } from '@chakra-ui/react';

import { Button } from '../Button';
import { AdservioIcons } from '../Icons';

export const StyledFileInput = chakra('input', {
  baseStyle: {
    display: 'none',
  },
});

interface UploadButtonProps {
  name: string;
  label: string;
  variant?: string;
  colorScheme?: string;
  size?: string;
  boxSize?: string;
  width?: string | number | object;
  isLoading?: boolean;
}

export const UploadButton = forwardRef<HTMLButtonElement, UploadButtonProps>(
  ({ name, label, variant, colorScheme, size, boxSize = 5, width, isLoading }, ref) => {
    const { t } = useTranslation('shared');

    return (
      <Button
        as="label"
        padding={label === '' ? '0' : '4'}
        cursor="pointer"
        ref={ref as any}
        htmlFor={name}
        aria-label="select file"
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        width={width}
        isLoading={isLoading}
      >
        <AdservioIcons.FiUploadCloud mr={label === '' ? '0' : '2'} boxSize={boxSize} />
        {label === '' || label || t('general.upload')}
      </Button>
    );
  }
);
