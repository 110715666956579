// import { ArrayNotEmpty, IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { PackageFeature } from '../PackageFeature';

@Entity
export class PackageFeaturesInput {
  @Of(() => String)
  studentDefaultPackageId?: string = '';

  @Of(() => [PackageFeature])
  // @IsNotEmpty({ message: 'errors.features.required' })
  // @ArrayNotEmpty({ message: 'errors.features.required' })
  featuresData: PackageFeature[] = [];

  static of = Entity.of<PackageFeaturesInput>();
}
