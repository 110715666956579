import React from 'react';
import { useDispatch } from 'react-redux';

import { Account } from '@api/core/entities';
import { TYPE_CADRU, TYPE_CANDIDATE, TYPE_ELEV, TYPE_PARINTE, TYPE_PERS_INST } from '@constants';
import Api2 from '@lib/api';
import { useStorageSession } from '@lib/utils';
import { sessionInit, sessionUnset } from 'Redux/SessionActions';

import { AccountDetails } from '../AccountDetails';
import { MenuItemDivider, UserMenuItem } from '../UserMenu/UserMenu.layout';

const userTypesWeights = {
  [TYPE_ELEV]: 1,
  [TYPE_CANDIDATE]: 2,
  [TYPE_PARINTE]: 3,
  [TYPE_CADRU]: 4,
  [TYPE_PERS_INST]: 5,
};

export const UnifiedAccounts = () => {
  const dispatch = useDispatch();
  const { profile, accounts } = useStorageSession();

  const sessionSwitchAccount = (account: Account, lang: string) => {
    sessionStorage.removeItem('classesStorage');
    dispatch(sessionUnset());
    const stayLoggedIn = localStorage.getItem('stayLoggedIn');

    Api2.post('/auth/switch', {
      uaID: account.uaID,
      setCookie: 1,
      tokenLifetime: stayLoggedIn === 'true' ? '30' : '0',
    }).then(() => {
      dispatch(sessionInit({ redirectToDefault: true }));
    });
  };

  return (
    <>
      {accounts
        ?.sort((a, b) => userTypesWeights[a.uaTip] - userTypesWeights[b.uaTip])
        ?.map((a, index) => (
          <React.Fragment key={a.uaID}>
            <UserMenuItem
              isActive={a.uaID === profile.uaID}
              onClick={() => sessionSwitchAccount(a, profile.prefLangID)}
            >
              <AccountDetails account={a} />
            </UserMenuItem>

            <MenuItemDivider isAccountDivider={index === accounts?.length - 1 ? false : true} />
          </React.Fragment>
        ))}
    </>
  );
};
