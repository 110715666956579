import { Entity, Of } from 'entity-of';

@Entity
export class ClientCodeCreateMutationInput {
  @Of(() => String)
  accountId: string = '';

  @Of(() => String)
  code: string = '';

  static of = Entity.of<ClientCodeCreateMutationInput>();
}
