import { Subject } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class Groups {
  @Of(() => String)
  grupaID = '';

  @Of(() => String)
  grupaNume = '';

  @Of(() => Subject)
  cmcMaterie = Subject.of({});

  static of = Entity.of<Groups>();
}
