import { Entity, Of } from 'entity-of';

@Entity
export class UserRegisterInput {
  @Of(() => String)
  intent: 'link' | 'register' | 'validate_username' = 'register';

  @Of(() => String)
  type: 'accesscode' | 'standard' = 'accesscode';

  @Of(() => String)
  accessCode: string = '';

  @Of(() => String)
  uaUserName: string = '';

  @Of(() => String)
  uaPassword: string = '';

  @Of(() => String)
  uaPassword2: string = '';

  @Of(() => Boolean)
  uaNewsletter: boolean = false;

  @Of(() => Boolean)
  uaTos: boolean = false;

  @Of(() => Boolean)
  uaPrivacy: boolean = false;

  @Of(() => String)
  uaTip: string = '';

  @Of(() => String)
  lastName: string = '';

  @Of(() => String)
  firstName: string = '';

  @Of(() => String)
  recaptcha: string = '';

  static of = Entity.of<UserRegisterInput>();
}
