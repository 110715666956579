import { Institution, Permission, PersonClass, Role, School } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class Person {
  @Of(() => String)
  uaID: string = '';

  @Of(() => String)
  uaTip: string = '';

  @Of(() => String)
  uaFirstName: string = '';

  @Of(() => String)
  uaLastName: string = '';

  @Of(() => String)
  uaUserName: string = '';

  @Of(() => String)
  uaLastLogin: string = '';

  @Of(() => String)
  uaLiceuID: string = '';

  @Of(() => String)
  prefAvatar: string = '';

  @Of(() => String)
  uaPrefAvatar = '';

  @Of(() => String)
  prefLangID: string = '';

  @Of(() => String)
  uaInitTata: string = '';

  @Of(() => String)
  uaSex: string = '';

  @Of(() => String)
  uaEmail: string = '';

  @Of(() => String)
  uaEmailValidat: string = '';

  @Of(() => String)
  uaTel: string = '';

  @Of(() => String)
  uaTelValidat: string = '';

  @Of(() => String)
  uaDataNasterii: string = '';

  @Of(() => String, { optional: true })
  uaCadUniDepartament?: string;

  @Of(() => String)
  uaCadUniDepartamentID?: string;

  @Of(() => String, { optional: true })
  uaCadUniFunctie?: string;

  @Of(() => String)
  uaCadUniFunctieID?: string;

  @Of(() => PersonClass)
  clasa: PersonClass = PersonClass.of({});

  @Of(() => [Role])
  roles: Role[] = [];

  @Of(() => [Permission])
  permisiuni: Permission[] = [];

  @Of(() => School)
  liceu: School = School.of({});

  @Of(() => Institution)
  institutie: Institution = Institution.of({});

  @Of(() => String)
  billableAccountId: string = '';

  @Of(() => String)
  parentId: string = '';

  static of = Entity.of<Person>();
}
