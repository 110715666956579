import { useMemo, useState } from 'react';

import { useAcademicYearState } from '@modules/Universities/hooks';
import { useAcademicYearsQuery } from '@modules/Universities/shared-universities';

export const useYearsData = () => {
  const { academicYearId, setAcademicYearId } = useAcademicYearState();

  const [enabled, setEnabled] = useState<boolean>(false);

  const { data: academicYears, isLoading } = useAcademicYearsQuery({
    enabled: !academicYearId || enabled,
    onSuccess: (data) => {
      const current = data.find((year) => year.currentYear);

      if (current && !academicYearId) {
        setAcademicYearId(current?.id);
      }

      setEnabled(false);
    },
  });

  const selectedAcademicYear = useMemo(() => {
    return academicYears?.find((year) => year.id === academicYearId);
  }, [academicYears, academicYearId]);

  const rerun = () => {
    setEnabled(true);
  };

  return {
    rerun,
    academicYears: academicYears || [],
    isLoading,
    selectedAcademicYear,
    academicYearId,
    setAcademicYearId,
  };
};
