import { ArrayNotEmpty, IsNotEmpty, Matches, MaxLength } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class DormInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.dorms.name.required' })
  @Matches(/^(?=.*[^\s])[A-Za-z0-9\s]+$/, { message: 'errors.general.alphanumeric' })
  @MaxLength(150, { message: 'errors.general.max_150' })
  name: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.dorms.address.required' })
  @MaxLength(255, { message: 'errors.general.max_255' })
  address: string = '';

  @Of(() => [Number])
  @ArrayNotEmpty({ message: 'errors.accommodations.dorms.administrators.required' })
  administrators: number[] = [];

  @Of(() => String)
  observations: string = '';

  static of = Entity.of<DormInput>();
}
