import { Entity, Of } from 'entity-of';

@Entity
export class AttendanceSignInput {
  @Of(() => String)
  cmcID: string = '';

  @Of(() => String)
  description: string = '';

  @Of(() => Number)
  isVisibleStudents: 0 | 1 = 0;

  @Of(() => String)
  startDateTime: string = '';

  @Of(() => String)
  endDateTime: string = '';

  static of = Entity.of<AttendanceSignInput>();
}
