import { useTranslation } from 'react-i18next';

import { OrganisationType } from '@api/billing/entities';
import { AdservioIcons, Box, Center, Grid, RadioCard, Text } from '@ui2/components';
import { getOrgTypeTheme } from '@ui2/utils';

import { OrganisationTypeSelectionProps } from './OrganisationTypeSelection';

export const CompanyIcon = () => {
  return (
    <Center width="10" height="10" borderRadius="full" backgroundColor="cyan.100" color="cyan.600">
      <AdservioIcons.Company boxSize="5" />
    </Center>
  );
};

export const NonProfitOrganisationIcon = () => {
  return (
    <Center
      width="10"
      height="10"
      borderRadius="full"
      backgroundColor="lightGreen.100"
      color="lightGreen.600"
    >
      <AdservioIcons.FiGlobe boxSize="5" />
    </Center>
  );
};

const organisationTypes = [
  {
    data: OrganisationType.COMPANY,
    label: 'general.company',
    description: 'profile.select_invoices',
    icon: <CompanyIcon />,
  },
  {
    data: OrganisationType.NGO,
    label: 'general.non_profit_organisation',
    description: 'profile.select_payment_notices',
    icon: <NonProfitOrganisationIcon />,
  },
];

export const OrganisationTypeGroup = ({ isInvalid, ...props }: OrganisationTypeSelectionProps) => {
  const { t } = useTranslation('billing');
  const baseColor = getOrgTypeTheme('blue');

  return (
    <Grid
      templateColumns={{ base: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr' }}
      columnGap="4"
      rowGap="4"
    >
      {organisationTypes.map(({ data, label, description, icon }) => (
        <RadioCard
          {...props}
          key={data}
          data={data}
          isInvalid={isInvalid}
          renderChildren={(isChecked) => (
            <Grid alignItems="center" gridTemplateColumns="auto 1fr" columnGap="4">
              {icon}

              <Box>
                <Text fontWeight="semibold" color={isChecked ? `${baseColor}.800` : 'gray.700'}>
                  {t(label)}
                </Text>

                <Text fontSize="sm" color={isChecked ? `${baseColor}.600` : 'gray.500'}>
                  {t(description)}
                </Text>
              </Box>
            </Grid>
          )}
        />
      ))}
    </Grid>
  );
};
