import { Entity, Of } from 'entity-of';

@Entity
export class BalanceAccountSummariesTableFilters {
  @Of(() => Number)
  tenantBillingProfileId: number = 0;

  @Of(() => String, { optional: true })
  accountId?: string;

  static of = Entity.of<BalanceAccountSummariesTableFilters>();
}
