import React, { PropsWithChildren } from 'react';

import { Center, HStack } from '../../atoms';

interface AttachmentWrapperProps {
  isEmpty?: boolean;
}

export const AttachmentWrapper = ({
  isEmpty,
  children,
}: PropsWithChildren<AttachmentWrapperProps>) => {
  return (
    <HStack
      p="4"
      borderRadius="xl"
      border={isEmpty ? '1px dashed' : '1px solid'}
      borderColor="gray.200"
      backgroundColor={isEmpty ? 'gray.50' : ''}
      justifyContent="space-between"
      boxShadow={isEmpty ? '' : 'xs'}
    >
      {children}
    </HStack>
  );
};

export const FileIconWrapper = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Center width="2.5rem" height="2.5rem" borderRadius="full" backgroundColor="gray.100">
      {children}
    </Center>
  );
};
