import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { EfacturaIssueType } from '../TenantBillingProfile';

@Entity
export class BillingProfileEfacturaIssueTypeInput {
  @Of(() => EfacturaIssueType)
  @IsNotEmpty({ message: 'errors.value.required' })
  eFacturaIssueType: EfacturaIssueType = EfacturaIssueType.MANUAL;

  static of = Entity.of<BillingProfileEfacturaIssueTypeInput>();
}
