import { Entity, Of } from 'entity-of';

@Entity
export class Viewer {
  @Of(() => Boolean)
  students: boolean = false;

  @Of(() => Boolean)
  teachers: boolean = false;

  @Of(() => Boolean)
  parents: boolean = false;

  @Of(() => [Number])
  others: number[] = [];

  static of = Entity.of<Viewer>();
}
