import { Entity, Of } from 'entity-of';

@Entity
export class ServiceChargesPreviewInput {
  @Of(() => Number, { optional: true })
  serviceId?: number;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  currency: string = '';

  @Of(() => Number)
  quantity: number = 0;

  @Of(() => Number)
  unitValue: number = 0;

  @Of(() => String)
  invoiceDate: string = '';

  @Of(() => String)
  tenantCurrency: string = '';

  @Of(() => Number, { optional: true })
  exchangeRate?: number;

  @Of(() => String, { optional: true })
  vatName?: string;

  static of = Entity.of<ServiceChargesPreviewInput>();
}
