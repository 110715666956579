import React from 'react';

import { useStorageSession } from '@lib/utils';

import { Sidenav } from '../../../../Components';

import {
  useUniversitiesLinkGroups,
  useUniversitiesStudentLinkGroups,
  useUniversitiesTeacherLinkGroups,
} from './utils/universities';

interface MainMenuProps {
  isPHP?: boolean;
}

export const UniversitiesMenu = ({ isPHP = false }: MainMenuProps) => {
  const { groups: universitiesGroups } = useUniversitiesLinkGroups();
  const { groups: teacherGroups } = useUniversitiesTeacherLinkGroups();
  const { groups: studentGroups } = useUniversitiesStudentLinkGroups();

  const {
    universitySessionHelpers: { isStudent, isTeacher, isManager, isSecretary, isPersonalAux },
  } = useStorageSession();

  return (
    <>
      {(isManager || isSecretary || isPersonalAux) && (
        <Sidenav
          isPHP={isPHP}
          groups={universitiesGroups}
          logoUrl={{
            url: 'dashboard',
            redirectRule: isPHP ? 'window' : 'push',
          }}
        />
      )}

      {isTeacher && !(isManager || isSecretary || isPersonalAux) && (
        <Sidenav
          isPHP={isPHP}
          groups={teacherGroups}
          logoUrl={{
            url: 'dashboard',
            redirectRule: isPHP ? 'window' : 'push',
          }}
        />
      )}

      {isStudent && (
        <Sidenav
          isPHP={isPHP}
          groups={studentGroups}
          logoUrl={{
            url: 'dashboard',
            redirectRule: isPHP ? 'window' : 'push',
          }}
        />
      )}
    </>
  );
};
