import { Suspense, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { useRouter } from '@lib/router';
import { useStorageSession } from '@lib/utils';
import { DisruptionBanner } from '@modules/Site/components/disruptionBanner';
import { Center, Spinner } from '@ui2/components';
import { ThemeProvider } from '@ui2/theme';
import { createEntityStore } from 'entity-of';
import Cookies from 'js-cookie';
import { sessionInit } from 'Redux/SessionActions';

import { MainMenu } from './Modules/Site/components/main-menu/';
import Store from './Redux/Store';
import App from './app';
import { Navbar, UpgradeCalloutPage } from './Components';
import i18n from './i18n';

import 'react-datepicker/dist/react-datepicker.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

createEntityStore();

const SessionInit = () => {
  const dispatch = useDispatch();
  const { queryString } = useRouter();
  const { profile } = useStorageSession();

  useSelector((state) => state);

  useEffect(() => {
    if (!Object.keys(profile)?.length) {
      dispatch(sessionInit({ adminRefId: queryString.get('liceuID') || '' }));
    }
  }, []);

  return <></>;
};

const PHPApp = () => {
  const { isReady } = useStorageSession();

  useSelector((state) => state);

  const headerContainer = document.getElementById('chakraHeader');
  const sidebarContainer = document.getElementById('chakraSidebar');
  const bannerContainer = document.getElementById('disruptionBanner');
  const calloutContainer = document.getElementById('upgrade_callout_page');

  const excludedRoutes = ['/site/implementare'];

  const isExcludedRoute = excludedRoutes.some((route) => window.location.pathname.includes(route));

  return (
    <>
      {isExcludedRoute ? (
        <></>
      ) : (
        <Suspense fallback={<></>}>
          <ThemeProvider>
            <QueryClientProvider client={queryClient}>
              {headerContainer && createPortal(<SessionInit />, headerContainer)}

              {!isReady && (
                <Center
                  position="fixed"
                  top="0"
                  left="0"
                  width="100vw"
                  height="min(100svh, 100vh)"
                  backgroundColor="white"
                  zIndex="overlay"
                >
                  <Spinner size="xl" />
                </Center>
              )}

              {isReady && (
                <>
                  {headerContainer && createPortal(<Navbar isPHP={true} />, headerContainer)}
                  {sidebarContainer && createPortal(<MainMenu isPHP={true} />, sidebarContainer)}
                  {bannerContainer && createPortal(<DisruptionBanner />, bannerContainer)}
                  {calloutContainer &&
                    createPortal(
                      <UpgradeCalloutPage
                        target={calloutContainer.dataset.callout}
                        isSubmodule
                        isPHP={true}
                      />,
                      calloutContainer
                    )}
                </>
              )}
            </QueryClientProvider>
          </ThemeProvider>
        </Suspense>
      )}
    </>
  );
};

const phpReactContainer = document.getElementById('php-react');

if (phpReactContainer && !Cookies.get('ads_native')) {
  const root = createRoot(phpReactContainer);

  root.render(
    <Provider store={Store}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <PHPApp />
        </Router>
      </I18nextProvider>
    </Provider>
  );
}

const appContainer = document.getElementById('react-app');

if (appContainer) {
  const root = createRoot(appContainer);

  root.render(
    <Provider store={Store}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <App />
        </Router>
      </I18nextProvider>
    </Provider>
  );
}

(window as any).resizeModalFromReact = (height) => {
  const event = new CustomEvent('resizeModal', { detail: { height } });
  document.getElementsByTagName('body')[0].dispatchEvent(event);
};

(window as any).closeModalFromReact = (update, updateParams) => {
  const event = new CustomEvent('closeModal', {
    detail: { update, updateParams },
  });

  document.getElementsByTagName('body')[0].dispatchEvent(event);
};
