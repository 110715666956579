import { Entity, Of } from 'entity-of';

export class PaymentsTableFilters {
  @Of(() => String, { optional: true })
  accountId?: string;

  @Of(() => String, { optional: true })
  type?: string;

  @Of(() => Number, { optional: true })
  amount?: number;

  @Of(() => String, { optional: true })
  createdAt?: string;

  static of = Entity.of<PaymentsTableFilters>();
}
