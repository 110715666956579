import React from 'react';

import { Box, Radio } from '../../atoms';

import { Wrapper } from './RadioCard.theme';

export interface RadioCardProps {
  isInvalid?: boolean;
  data: string | number;
  value?: string | number;
  onChange?: (value: string | number) => void;
  renderChildren?: (isChecked?: boolean) => React.ReactElement;
}

export const RadioCard = ({ data, isInvalid, value, onChange, renderChildren }: RadioCardProps) => {
  const isChecked = value === data;

  return (
    <Wrapper isChecked={isChecked} isInvalid={isInvalid} onClick={() => onChange?.(data)}>
      <Box>{renderChildren?.(isChecked)}</Box>

      <Radio isChecked={isChecked} />
    </Wrapper>
  );
};
