import { Entity, Of } from 'entity-of';

import { Document } from '.';
@Entity
export class AccommodationDocuments {
  @Of(() => Document, { optional: true })
  contract?: Document | undefined = undefined;

  @Of(() => [Document])
  requests: Document[] = [];

  @Of(() => [Document])
  transferDecision: Document | undefined = undefined;

  @Of(() => [Document])
  terminationAgreement: Document | undefined = undefined;

  static of = Entity.of<AccommodationDocuments>();
}
