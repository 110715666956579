import { Entity, Of } from 'entity-of';

export type JobStatusType = 'pending' | 'completed' | 'failed' | 'running';

@Entity
export class JobStatus {
  @Of(() => String)
  completedAt!: string;

  @Of(() => String)
  id!: string;

  @Of(() => String)
  ownerId!: string;

  @Of(() => String)
  type!: string;

  @Of(() => Object)
  payload!: Record<string, string>;

  @Of(() => String)
  status: JobStatusType = 'pending';

  @Of(() => String)
  url!: string;

  static of = Entity.of<JobStatus>();
}
