import { PropsWithChildren } from 'react';
import { Row } from 'react-grid-system';

import { useContainerWidth } from '@lib/hooks';

interface MainContentProps {
  uaID: string;
  isNavigationOpen: boolean;
  hasCookiesAdsNative: boolean;
  noNavigation: boolean;
}

export const MainContent = ({
  uaID,
  isNavigationOpen,
  hasCookiesAdsNative,
  noNavigation,
  children,
}: PropsWithChildren<MainContentProps>) => {
  const { width, isFullScreen } = useContainerWidth();

  let classes = `${uaID ? 'two-columns-content' : 'one-column-content'} ${width}`;

  if (isNavigationOpen) {
    classes += ' navigation-open';
  }

  if (noNavigation) {
    classes += ' no-navigation';
  }

  if (hasCookiesAdsNative) {
    classes += ' native-app';
  }

  if (isFullScreen) {
    classes += ' full-screen';
  }

  return (
    <Row className={classes} nogutter>
      {children}
    </Row>
  );
};
