import {
  IsNotEmpty,
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
} from 'class-validator';
import { Entity, Of } from 'entity-of';

export enum tcShowInReportsItemType {
  SHOWINREPORTS = '1',
}

@Entity
export class CreateValidatorStep1 {
  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.tcTransferNumber.required' })
  @IsDuplicateTransferNumber({ message: 'errors.tcTransferNumber.duplicate' })
  tcTransferNumber: number | null = null;

  @Of(() => Boolean)
  isDupeTransferNumber: boolean | null = null;

  @Of(() => Number)
  tcID: number = 0;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.date.required' })
  tcTransferDate: string = '';

  @Of(() => String)
  tcTransferPeriod: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.tcToClID.required' })
  tcToClID: string = '';

  @Of(() => String, { optional: true })
  tcToClName?: string = '';

  @Of(() => Boolean)
  tcShowInReports: boolean = false;

  static of = Entity.of<CreateValidatorStep1>();
}

function IsDuplicateTransferNumber(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'isDuplicateTransferNumber',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [],
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const isDupe = Boolean((args.object as any)['isDupeTransferNumber']);

          if (!value) {
            return true;
          }

          return !isDupe;
        },
      },
    });
  };
}
