import { transformAndValidateSync } from 'class-transformer-validator';
import { ValidationError, ValidatorOptions } from 'class-validator';

type Class<T extends object> = { new (): T };

export const createValidator =
  <T extends object>(model: Class<T>, options?: ValidatorOptions) =>
  (data: T) => {
    try {
      transformAndValidateSync(model, data, { validator: options });

      return {};
    } catch (e: unknown) {
      return convertError(e as ValidationError[]);
    }
  };

function convertError(errors: ValidationError[]) {
  if (!errors) {
    return {};
  }

  let result = {};
  // errors[0].target holds original data from form
  // By default, result will be empty object, but if 'errors[0].target' is array, then result will be also an array,
  // and will be filled with the same number of empty objects as 'errors[0].target'
  // This was done because we needed a way to display errors in array of forms using <FieldArray /> from react-final-form
  if (Array.isArray(errors[0]?.target)) {
    result = Array((errors[0].target as []).length).fill({});
  }

  for (const error of Array.from(errors)) {
    result[error.property] = Object.values(error.constraints ?? {})[0];

    if (error?.children && error.children.length > 0) {
      result[error.property] = convertError(error.children as ValidationError[]);
    }
  }

  return result;
}
