import React from 'react';

import { Center } from '../../atoms';

interface IndicatorWrapperProps {
  color: string;
  children: React.ReactNode;
}

export const IndicatorWrapper = ({ color, children }: IndicatorWrapperProps) => {
  return (
    <Center
      width="1.5rem"
      height="1.5rem"
      borderRadius="full"
      fontSize="sm"
      textAlign="center"
      color="white"
      backgroundColor={color}
    >
      {children}
    </Center>
  );
};
