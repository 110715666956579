import { Entity, Of } from 'entity-of';

@Entity
export class SetVacationInput {
  @Of(() => String)
  ascID = '';

  @Of(() => String)
  name = '';

  @Of(() => String)
  startDate = '';

  @Of(() => String)
  endDate = '';

  static of = Entity.of<SetVacationInput>();
}
