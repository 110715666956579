import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class BillingProfilePenaltyInput {
  @Of(() => Number)
  penaltyPercentage: number = 0;

  @Of(() => Boolean)
  @IsNotEmpty({ message: 'errors.vatPayer.required' })
  vatPayer: boolean = true;

  @Of(() => Boolean)
  applyProformaPenalties: boolean = false;

  static of = Entity.of<BillingProfilePenaltyInput>();
}
