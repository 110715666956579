import { Entity, Of } from 'entity-of';

@Entity
export class FSUploadResponse {
  @Of(() => String)
  fsID: string = '';

  @Of(() => String)
  fsTip: string = '';

  @Of(() => String)
  fsNume: string = '';

  @Of(() => String)
  fsExt: string = '';

  @Of(() => String)
  fsMimeType: string = '';

  @Of(() => String)
  fsSize: string = '';

  @Of(() => String)
  fsInsertDate: string = '';

  @Of(() => String)
  fsUrl: string = '';

  static of = Entity.of<FSUploadResponse>();
}
