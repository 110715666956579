import { IsNotEmpty, Matches, MaxLength } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class EthnicityInput {
  @Of(() => String)
  @Matches(/^[^-\s]/, {
    message: 'errors.admission.no_white_space',
  })
  @IsNotEmpty({ message: 'errors.ethName.required' })
  @MaxLength(80, {
    message: 'errors.max_80',
  })
  ethName: string = '';

  @Of(() => Boolean)
  ethDefault: boolean = false;

  static of = Entity.of<EthnicityInput>();
}
