import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDisclosure } from '../../../hooks';
import { Box, Button, Grid, InputGroup, InputGroupProps } from '../../atoms';

import { defaultHours, defaultMinutes, isValid } from './constants';
import {
  InputGroupRightElement,
  TimeButton,
  UnitScrollBox,
  UnitsDivider,
  UnitsWrapper,
} from './TimePicker.layout';

export interface TimePickerProps extends Omit<InputGroupProps, 'value' | 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
  withManualInput?: boolean;
  isClearable?: boolean;
  hours?: string[];
  minutes?: string[];
  menuPortalTarget?: HTMLElement | null;
}

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>(
  (
    {
      value,
      onChange,
      withManualInput = true,
      isClearable = true,
      hours = defaultHours,
      minutes = defaultMinutes,
      menuPortalTarget,
      ...props
    },
    ref
  ) => {
    const wrapperRef = React.useRef(null);
    const { t } = useTranslation('shared');
    const { isOpen, onToggle, onClose } = useDisclosure();

    const [internalValue, setInternalValue] = useState<string>(value || '');
    let lastVal = '';

    useEffect(() => {
      value && setInternalValue(value);
    }, [value]);

    const [hour, setHour] = useState(value?.split(':')[0] ?? '');
    const [minute, setMinute] = useState(value?.split(':')[1] ?? '');

    const validTimeRegex = new RegExp('^([0-1]?[0-9]_?|2[0-3]):([0-5][0-9]|[0-5]_|_d|__)$');

    useEffect(() => {
      if (value) {
        setInternalValue(value);
      }
    }, [value]);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      let val = e.target.value.trim();

      if (val == internalValue) {
        return;
      }

      if (isValid(val)) {
        if (val.length === 2 && lastVal.length !== 3 && val.indexOf(':') === -1) {
          val = val + ':';
        }

        if (val.length === 2 && lastVal.length === 3) {
          val = val.slice(0, 1);
        }

        if (val.length > 5) {
          return false;
        }

        lastVal = val;

        setInternalValue(val);

        if (validTimeRegex.test(val)) {
          onChange?.(val);
        } else {
          onChange?.('');
        }
      }
    };

    const handleToggle = () => {
      setHour('');
      setMinute('');

      let h = value?.split(':')[0] ?? '';
      const m = value?.split(':')[1] ?? '';

      if (!h.startsWith('0') && Number(h) < 10) {
        h = `0${h}`;
      }

      if (hours.includes(h)) {
        setHour(h);
      }

      if (minutes.includes(m)) {
        setMinute(m);
      }

      onToggle();

      if (!isOpen) {
        setTimeout(() => {
          document
            .querySelectorAll('.activeUnit')
            ?.forEach((u) => u.scrollIntoView({ block: 'center' }));
        }, 10);
      }
    };

    const handleSelect = () => {
      setInternalValue(`${hour}:${minute}`);
      onChange?.(`${hour}:${minute}`);
      onClose();
    };

    const handleClear = () => {
      setInternalValue('');
      setHour('');
      setMinute('');
      onChange?.('');
    };

    const validateTime = () => {
      if (!validTimeRegex.test(internalValue)) {
        setInternalValue('');
        onChange?.('');
      }
    };

    return (
      <Box position="relative" width="full" ref={wrapperRef}>
        <InputGroup
          ref={ref}
          autoComplete="off"
          value={internalValue}
          onChange={handleChange}
          isReadOnly={!withManualInput}
          placeholder={withManualInput ? '__ : __' : t('general.select_time')}
          rightElement={
            <InputGroupRightElement
              size={props.size ?? 'md'}
              onToggle={handleToggle}
              onClear={isClearable && internalValue ? handleClear : undefined}
            />
          }
          rightElementSx={{
            zIndex: '1',
            right: isClearable && internalValue ? '1.4rem' : '0',
          }}
          onBlur={validateTime}
          onClick={() => {
            if (!withManualInput) {
              handleToggle();
            }
          }}
          {...props}
        />

        {isOpen && (
          <UnitsWrapper
            parentRef={wrapperRef}
            menuPortalTarget={menuPortalTarget}
            onClose={onClose}
          >
            <Grid templateColumns="1fr auto 1fr" mb="8">
              <UnitScrollBox>
                <>
                  {hours.map((h) => {
                    return (
                      <TimeButton key={h} value={h} isActive={hour === h} onSetValue={setHour} />
                    );
                  })}
                </>
              </UnitScrollBox>

              <UnitsDivider />

              <UnitScrollBox>
                <>
                  {minutes.map((m) => {
                    return (
                      <TimeButton
                        key={m}
                        value={m}
                        isActive={minute === m}
                        onSetValue={setMinute}
                      />
                    );
                  })}
                </>
              </UnitScrollBox>
            </Grid>

            <Button width="full" isDisabled={!hour || !minute} onClick={handleSelect}>
              {t('general.confirm')}
            </Button>
          </UnitsWrapper>
        )}
      </Box>
    );
  }
);
