import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountProfilesQuery, useExchangeRatesQuery } from '@api/billing/endpoints';
import { AccountBillingProfile, PaymentInfo, PaymentProvider } from '@api/billing/entities';
import { useCountiesQuery } from '@api/core/endpoints';
import {
  useAvailablePackagesQuery,
  useSubscriptionUpgradeMutation,
} from '@api/subscriptions/endpoints';
import { useFormatter } from '@lib/hooks';
import { useRouter } from '@lib/router';
import { SystemDate, useSession } from '@lib/utils';
import { PaymentForm } from '@modules/billing/domains/shared/components';
import { formattedAccountBillingProfileAddress } from '@modules/billing/domains/shared/helpers';
import { ChangeBillingProfileModal, PageHeader } from '@modules/shared';
import { EditCompanyProfileModal, EditPrivateProfileModal } from '@modules/shared/components';
import {
  AdservioIcons,
  Badge,
  Banner,
  Box,
  Button,
  Center,
  Checkbox,
  ContentCard,
  ContentEmptyState,
  Divider,
  FeatureIcon,
  Flex,
  Grid,
  HStack,
  Loader,
  Spinner,
  Text,
  Tooltip,
  VStack,
} from '@ui2/components';
import { getOrgTypeTheme } from '@ui2/utils';

interface RouteState {
  billingProfileId: number;
  recurrence?: string;
}

export const SubscriptionsCheckout = () => {
  const { t } = useTranslation('billing');
  const {
    host,
    protocol,
    query,
    lang,
    push,
    location: { state, search },
  } = useRouter();

  const baseColor = getOrgTypeTheme('blue');

  const { formatDate, formatCurrency } = useFormatter({ lang });

  const checkRecurrence = (query.id as string).indexOf('recurrence') !== -1;

  const id = checkRecurrence
    ? (query.id as string).substring(0, (query.id as string).indexOf('?'))
    : (query.id as string);

  const { studentId, legacyAccountType, rootOrgId, schoolYearEndDate, schoolYearName } =
    useSession();

  const routeState: RouteState = state as RouteState;
  const [profile, setProfile] = useState<AccountBillingProfile | undefined>();
  const [isSwitchOpen, setIsSwitchOpen] = useState<boolean>();
  const [isEditOpen, setIsEditOpen] = useState<boolean>();
  const [termsAgree, setTermsAgree] = useState<boolean>();

  const profilesQuery = useAccountProfilesQuery(
    { accountId: studentId, xAccountId: studentId, rootOrgId, createProfile: true },
    {
      enabled: !!studentId && !!rootOrgId,
      onSuccess: (data) => {
        const target = profile?.id || routeState?.billingProfileId;

        setProfile(data.find((p) => (target ? p.id === target : p.isDefault)));
      },
    }
  );

  const { data: availablePackages, isLoading } = useAvailablePackagesQuery({
    subscriberType: 'student',
  });

  const selectedPackage = availablePackages?.data?.filter((p) => p.id === id)?.[0];

  const recurrence = checkRecurrence
    ? (query.id as string).split('=')?.[1]
    : (state as RouteState)?.recurrence || search?.split('=')?.[1];

  const showExchangeRate =
    selectedPackage?.currencyCode !== 'RON' &&
    !selectedPackage?.eligibleForTrial &&
    selectedPackage?.yearlyPriceDiscounted;

  const requiredPrivateProfileFields: (keyof AccountBillingProfile)[] = [
    'firstName',
    'lastName',
    'country',
    'county',
    'city',
    'email',
    'phone',
    'street',
  ];

  const requiredCompanyProfileFields: (keyof AccountBillingProfile)[] = [
    'companyName',
    'fiscalCode',
    'street',
    'country',
    'county',
    'city',
    'email',
    'phone',
  ];

  const profileCompleted = profile?.isJuridic
    ? requiredCompanyProfileFields.every((field) => !!profile?.[field])
    : requiredPrivateProfileFields.every((field) => !!profile?.[field]);

  const { data: countiesData } = useCountiesQuery(
    { taraCod: String(profile?.country) },
    { enabled: !!profile?.country }
  );

  const {
    name: profileName,
    fiscalInfo,
    street,
    address,
  } = formattedAccountBillingProfileAddress(
    AccountBillingProfile.of(profile || {}),
    lang,
    countiesData?.data
  );

  const profileFullAddress = [street, address].filter((f) => Boolean(f)).join(', ');

  const { data: exchangeRate, isLoading: exchangeRateLoading } = useExchangeRatesQuery(
    {
      fromCurrencies: [selectedPackage?.currencyCode || 'EUR'],
      toCurrency: 'RON',
      rootOrgId,
    },
    {
      enabled: Boolean(selectedPackage?.currencyCode) && selectedPackage?.currencyCode !== 'RON',
    }
  );

  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>();

  const upgradeMutation = useSubscriptionUpgradeMutation();

  const handleUpgrade = () => {
    if (profile) {
      upgradeMutation.mutate(
        {
          payload: {
            packageId: id,
            subscriberBillingProfileId: String(profile?.id),
            recurrence,
            trial: Boolean(selectedPackage?.eligibleForTrial),
            terms: true,
            redirects: {
              redirectSuccess: `${protocol}//${host}/${lang}/subscriptions/my-subscription/package-plan?packageId=${id}`,
              redirectCancel: `${protocol}//${host}/${lang}/subscriptions/my-subscription/package-plan?status=failed`,
            },
          },
        },
        {
          onSuccess: (paymentInfo) => {
            if (paymentInfo.provider === PaymentProvider.STRIPE) {
              window.location.assign(paymentInfo.paymentUrl);
            }
            setPaymentInfo(paymentInfo);
          },
        }
      );
    }
  };

  const getTranslation = (field: string, language: string = 'ro', fallback?: string) => {
    return selectedPackage
      ? selectedPackage[field].find((l) => l.langId === language)?.value || fallback
      : '';
  };

  return (
    <>
      <PageHeader
        title={t('general.billing_details')}
        onBack={() => {
          push('subscriptions/buy');
        }}
      />

      <Loader isLoading={profilesQuery.isFetching}>
        <Grid
          templateColumns={{ base: '1fr', lg: '3fr 2fr', xl: '2fr 1fr' }}
          columnGap="4"
          rowGap="4"
          width="full"
        >
          <Box>
            <ContentCard
              title={t('general.billing_profile')}
              isOneRightElement
              rightElement={
                profile?.id ? (
                  <HStack spacing="3">
                    <Button
                      variant="outline"
                      size="sm"
                      colorScheme="gray"
                      onClick={() => setIsSwitchOpen(true)}
                    >
                      <AdservioIcons.FiRefreshCcw boxSize="1rem" />
                      <Text as="span" display={{ base: 'none', md: 'inline-block' }} ml="2">
                        {t('general.change')}
                      </Text>
                    </Button>

                    <Button
                      variant="outline"
                      size="sm"
                      colorScheme="gray"
                      onClick={() =>
                        push('billing/account/profile/create', {
                          returnToOrigin: `checkout/${id}?recurrence=${recurrence}`,
                          fromSubscription: true,
                        })
                      }
                    >
                      <AdservioIcons.FiPlus boxSize="1rem" mr="1" />
                      <Text as="span" display={{ base: 'none', md: 'inline-block' }}>
                        {t('general.create')}
                      </Text>
                    </Button>
                  </HStack>
                ) : (
                  <></>
                )
              }
            >
              {profile?.id ? (
                <VStack gap="4" alignItems="flex-start">
                  <Flex
                    flexDirection={{ base: 'column', sm: 'row' }}
                    alignItems="flex-start"
                    justifyContent="space-between"
                    columnGap="4"
                    rowGap="4"
                    w="full"
                  >
                    <HStack>
                      <Center
                        backgroundColor="gray.100"
                        width="2.5rem"
                        height="2.5rem"
                        borderRadius="full"
                      >
                        <AdservioIcons.BillingProfileSheet />
                      </Center>

                      <Box>
                        <Text mb="2" fontSize="lg" fontWeight="semibold">
                          {profileName}
                        </Text>

                        <Text mb="0.5" fontWeight="semibold">
                          {profile?.isJuridic && fiscalInfo}
                        </Text>

                        <Text mb="0.5">{profile.phone}</Text>

                        <Text>{profileFullAddress}</Text>
                      </Box>
                    </HStack>

                    <Button
                      w={{ base: 'full', sm: 'auto' }}
                      size="sm"
                      fontWeight="600"
                      variant="outline"
                      colorScheme="gray"
                      aria-label="Edit profile"
                      leftIcon={<AdservioIcons.FiEdit2 boxSize="4" />}
                      onClick={() => setIsEditOpen(true)}
                    >
                      {t('general.edit_action')}
                    </Button>
                  </Flex>
                  {!profileCompleted && (
                    <Banner
                      w="full"
                      variant="error"
                      iconVariant="withBorder"
                      icon="FiInfo"
                      title={t('profile.incomplete_profile_title')}
                      description={t('profile.incomplete_profile_description')}
                    />
                  )}
                </VStack>
              ) : (
                <ContentEmptyState
                  title={t('profile.my_profile.no_billing_profile_label')}
                  description={t('profile.my_profile.no_billing_profile_description')}
                  renderHeader={() => (
                    <FeatureIcon name="BillingProfileSheet" size="lg" colorScheme="gray" />
                  )}
                  renderFooter={() => {
                    return (
                      <Button
                        size="sm"
                        onClick={() =>
                          push('billing/account/profile/create', {
                            returnToOrigin: `checkout/${id}?recurrence=${recurrence}`,
                            fromSubscription: true,
                          })
                        }
                      >
                        <AdservioIcons.FiPlus boxSize="1rem" mr="1" />
                        {t('general.create')}
                      </Button>
                    );
                  }}
                />
              )}
            </ContentCard>
          </Box>

          <ContentCard title={t('general.summary')}>
            {isLoading ? (
              <Center>
                <Spinner />
              </Center>
            ) : (
              <>
                <Grid templateColumns="1fr auto" alignItems="center" mb="2">
                  <Text fontSize="2xl" fontWeight="bold">
                    {getTranslation('packageName', lang)}
                  </Text>

                  {selectedPackage?.isPopular && (
                    <Badge colorScheme="orange" variant="solid" size="md">
                      <Center>
                        <AdservioIcons.FiStar mr="1" color="white" boxSize="1rem" />
                        {t('general.popular')}
                      </Center>
                    </Badge>
                  )}
                </Grid>

                <Text color="gray.400">
                  {recurrence === 'monthly'
                    ? t(`general.${recurrence}_subscription`)
                    : t('general.school_year_subscription', { schoolYearName })}
                </Text>

                <Divider my="4" borderColor="gray.200" />

                <Grid templateColumns="1fr auto" alignItems="center" rowGap="4">
                  <Text color="gray.400">{t(`general.price`)}</Text>

                  <Text fontWeight="semibold" justifySelf="flex-end" color="gray.600">
                    {formatCurrency(
                      recurrence === 'monthly'
                        ? selectedPackage?.monthlyPriceWithoutVat
                        : selectedPackage?.yearlyPriceWithoutVat,
                      {
                        currency: selectedPackage?.currencyCode,
                      }
                    )}
                  </Text>

                  <Text color="gray.400">{t(`general.tva`)}</Text>

                  <Text fontWeight="semibold" justifySelf="flex-end" color="gray.600">
                    {formatCurrency(
                      recurrence === 'monthly'
                        ? selectedPackage?.monthlyVat
                        : selectedPackage?.yearlyVat,
                      {
                        currency: selectedPackage?.currencyCode,
                      }
                    )}
                  </Text>

                  <Text color="gray.400">{t(`general.total`)}</Text>

                  <Text fontWeight="bold" justifySelf="flex-end">
                    {formatCurrency(
                      recurrence === 'monthly'
                        ? selectedPackage?.monthlyPriceDiscounted
                        : selectedPackage?.yearlyPriceDiscounted,
                      {
                        currency: selectedPackage?.currencyCode,
                      }
                    )}
                  </Text>
                </Grid>

                <Divider my="4" borderColor="gray.100" />

                <Grid templateColumns="1fr auto" alignItems="center" rowGap="4">
                  {selectedPackage?.eligibleForTrial && (
                    <>
                      <Flex alignItems="center">
                        <Text color="gray.400" mr="0.5" fontSize="sm">
                          {t(`general.next_billing_date`)}
                        </Text>

                        <Tooltip
                          hasArrow
                          placement="top"
                          label={t('checkout.trial_next_billing_date_disclaimer')}
                        >
                          <Flex alignItems="center">
                            <AdservioIcons.FiInfo boxSize="0.85rem" />
                          </Flex>
                        </Tooltip>
                      </Flex>

                      <Text
                        fontWeight="semibold"
                        justifySelf="flex-end"
                        fontSize="sm"
                        color="gray.600"
                      >
                        {formatDate(
                          new SystemDate(new Date()).addDays(selectedPackage.trialDays).valueOf
                        )}
                      </Text>
                    </>
                  )}

                  {recurrence && (
                    <>
                      <Text color="gray.400" fontSize="sm">
                        {t(`general.package_available_until`)}
                      </Text>

                      <Text
                        fontWeight="semibold"
                        justifySelf="flex-end"
                        fontSize="sm"
                        color="gray.600"
                      >
                        {formatDate(new Date(schoolYearEndDate))}
                      </Text>
                    </>
                  )}
                </Grid>

                <Divider my="4" borderColor="gray.200" />

                <Box mb="8">
                  <Grid templateColumns="1fr auto" alignItems="center">
                    <Text fontSize="lg" fontWeight="semibold" color="gray.900">
                      {t(`general.to_pay_now`)}
                    </Text>

                    <Text fontSize="lg" fontWeight="bold" justifySelf="flex-end" color="gray.900">
                      {formatCurrency(
                        selectedPackage?.eligibleForTrial
                          ? 0
                          : recurrence === 'monthly'
                            ? selectedPackage?.monthlyPriceDiscounted
                            : selectedPackage?.yearlyPriceDiscounted,
                        {
                          currency: selectedPackage?.currencyCode,
                        }
                      )}
                    </Text>
                  </Grid>

                  {showExchangeRate && (
                    <Flex alignItems="center" mt="2">
                      <Loader position="left" size="sm" isLoading={exchangeRateLoading}>
                        <AdservioIcons.FiInfo mr="1" boxSize="0.75rem" />
                        <Text fontSize="xs">
                          {t('checkout.conversion_disclaimer', {
                            currency: formatCurrency(
                              Number(
                                recurrence === 'monthly'
                                  ? selectedPackage?.monthlyPriceDiscounted
                                  : selectedPackage?.yearlyPriceDiscounted
                              ) * Number(exchangeRate?.[0].rate || 1),
                              { currency: 'RON' }
                            ),
                          })}
                        </Text>
                      </Loader>
                    </Flex>
                  )}

                  {selectedPackage?.eligibleForTrial && (
                    <Flex mt="2">
                      <AdservioIcons.FiInfo
                        position="relative"
                        top="3px"
                        mr="1"
                        boxSize="0.75rem"
                      />
                      <Text fontSize="xs">
                        {t('checkout.trial_subscription_disclaimer', {
                          currency: formatCurrency(
                            recurrence === 'monthly'
                              ? selectedPackage?.monthlyPriceDiscounted
                              : selectedPackage?.yearlyPriceDiscounted,
                            {
                              currency: selectedPackage?.currencyCode,
                            }
                          ),
                        })}
                      </Text>
                    </Flex>
                  )}
                </Box>

                <Box mb="8">
                  <Checkbox isChecked={termsAgree} onChange={() => setTermsAgree(!termsAgree)}>
                    <Text as="span" mr="1" fontSize="md">
                      {t('general.i_agree_to')}
                    </Text>

                    <a
                      href="https://www.adservio.ro/ro/p/terms-and-condition"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Text
                        as="span"
                        color={`${baseColor}.600`}
                        textDecoration="underline"
                        fontSize="md"
                      >
                        {t('general.adservio_terms_and_conditions')}
                      </Text>
                    </a>
                  </Checkbox>
                </Box>

                <Button
                  width="full"
                  onClick={handleUpgrade}
                  isLoading={upgradeMutation?.isLoading}
                  isDisabled={Boolean(!profile) || !termsAgree || !profileCompleted}
                >
                  {t('checkout.continue_to_card_details')} <AdservioIcons.FiChevronRight ml="2" />
                </Button>
              </>
            )}
          </ContentCard>
        </Grid>
      </Loader>

      <PaymentForm paymentInfo={paymentInfo} />

      {isSwitchOpen && (
        <ChangeBillingProfileModal
          isSubscription
          rootOrgId={rootOrgId}
          currentProfile={profile}
          onToggle={() => setIsSwitchOpen(false)}
          onSubmit={(newProfile) => {
            setProfile(newProfile);
            setIsSwitchOpen(false);
          }}
        />
      )}

      {['1', '2'].includes(legacyAccountType) && profile && !profile.isJuridic && isEditOpen && (
        <EditPrivateProfileModal
          isOpen
          profile={AccountBillingProfile.of(profile)}
          onToggle={() => setIsEditOpen(false)}
          rootOrgId={rootOrgId}
          isSubscriptions
        />
      )}

      {['1', '2'].includes(legacyAccountType) && profile && profile.isJuridic && isEditOpen && (
        <EditCompanyProfileModal
          isOpen
          profile={AccountBillingProfile.of(profile)}
          onToggle={() => setIsEditOpen(false)}
          rootOrgId={rootOrgId}
          isSubscriptions
        />
      )}
    </>
  );
};
