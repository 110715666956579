import { Entity, Of } from 'entity-of';

export type AttendanceStatus = 'missed' | 'present' | 'time-off';

@Entity
export class AttendanceReportsQueryInput {
  @Of(() => String, { optional: true })
  cadID?: string;

  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  @Of(() => String, { optional: true })
  attendanceStatusFilter?: AttendanceStatus;

  @Of(() => String, { optional: true, nullable: true })
  month?: string;

  static of = Entity.of<AttendanceReportsQueryInput>();
}
