import { Entity, Of } from 'entity-of';

import { InvoiceType } from '../Invoice';

@Entity
export class TenantSeriesNumber {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  tenantBillingProfileId: number = 0;

  @Of(() => Number)
  startNumber: number = 0;

  @Of(() => Number)
  number: number = 0;

  @Of(() => String)
  serial: string = '';

  @Of(() => InvoiceType)
  invoiceType: InvoiceType = InvoiceType.INVOICE;

  @Of(() => Boolean)
  isImplicit: boolean = false;

  static of = Entity.of<TenantSeriesNumber>();
}
