import { Entity, Of } from 'entity-of';

@Entity
export class GraduateReportInput {
  @Of(() => String, { optional: true })
  ascID?: string = '';

  @Of(() => String, { optional: true })
  sanID?: string;

  @Of(() => String, { optional: true })
  clID?: string;

  @Of(() => String, { optional: true })
  mgIntermediara?: string;

  static of = Entity.of<GraduateReportInput>();
}
