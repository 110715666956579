import { registerDecorator, ValidationArguments } from 'class-validator';
import { t } from 'i18next';

export function MaxDynamic(value: number) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'maxDynamic',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [value],
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [maxValue] = args.constraints;

          if (maxValue < Number(value)) {
            return false;
          }

          return true;
        },
        defaultMessage(args: ValidationArguments) {
          const [maxValue] = args.constraints;

          return t('shared:errors.max_dynamic', { 0: maxValue });
        },
      },
    });
  };
}
