import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ORG_UNIVERSITY } from '@constants';
import { useStorageSession } from '@lib/utils';

import {
  AdservioIcons,
  Center,
  EndpointOptions,
  FileUpload,
  HStack,
  IconButton,
  Spinner,
  Text,
} from '../../atoms';
import { FormElementProps } from '../FormElements';

import { AttachmentWrapper, FileIconWrapper } from './Attachment.layout';

interface Accessors {
  id: string;
  name: string;
}

interface AttachmentProps {
  value?: string;
  name?: string;
  fileName?: string;
  isLoading?: boolean;
  fsTip?: string;
  isDisabled?: boolean;
  apiBaseUrl: string;
  endpointOptions?: EndpointOptions;
  accessors: Accessors;
  allowDownldoad?: boolean;
  onLoadStart?(): void;
  onLoadEnd?(): void;
  onClear?(): void;
  onChange?: (value: string | null) => void;
}

export function Attachment<T extends object = object>({
  value,
  name,
  fileName,
  isLoading,
  fsTip,
  isDisabled,
  apiBaseUrl,
  accessors,
  endpointOptions,
  allowDownldoad = true,
  onLoadStart,
  onLoadEnd,
  onChange,
}: FormElementProps<AttachmentProps>) {
  const { t } = useTranslation('shared');
  const [label, setLabel] = useState<string>();
  const [isUploading, setIsUploading] = useState<boolean>();
  const { orgType } = useStorageSession();

  if (orgType === ORG_UNIVERSITY) {
    endpointOptions = { uploadUrl: '', downloadUrl: '', fileKeyName: 'file', payload: {} };
  } else {
    endpointOptions = endpointOptions
      ? { ...endpointOptions, payload: { fsTip: endpointOptions?.payload?.fsTip ?? fsTip ?? '6' } }
      : {
          uploadUrl: `/fisiere/upload`,
          downloadUrl: '/fisiere/download?fsID=',
          fileKeyName: 'fsFile',
          payload: {
            fsTip: fsTip ?? '6',
          },
        };
  }

  const handleSuccess = (data: T) => {
    setLabel(String(data[accessors.name]));
    onChange?.(String(data[accessors.id]));
  };

  const handleLoadStart = () => {
    setIsUploading(true);
    onLoadStart?.();
  };

  const handleLoadEnd = () => {
    setIsUploading(false);
    onLoadEnd?.();
  };

  const clearFiles = () => {
    onChange?.('');
    setLabel('');
  };

  useEffect(() => {
    setLabel(fileName);
  }, [fileName]);

  return (
    <>
      <AttachmentWrapper isEmpty={!isLoading && !fileName}>
        {isLoading ? (
          <Center width="100%">
            <Spinner justifySelf="center" />
          </Center>
        ) : (
          <>
            <HStack alignItems="center">
              <FileIconWrapper>
                <AdservioIcons.FiFile />
              </FileIconWrapper>

              <Text fontWeight="semibold">{value ? label : t('general.select_file')}</Text>
            </HStack>

            {isUploading ? (
              <Spinner size="md" />
            ) : (
              <>
                {value && (
                  <HStack spacing="2">
                    {allowDownldoad && (
                      <a
                        href={`${apiBaseUrl}${endpointOptions.downloadUrl}${value}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <IconButton
                          variant="ghost"
                          colorScheme="gray"
                          icon={<AdservioIcons.FiDownloadCloud boxSize="1.5rem" />}
                          aria-label="Download"
                        />
                      </a>
                    )}

                    {!isDisabled && (
                      <IconButton
                        variant="ghost"
                        colorScheme="red"
                        icon={<AdservioIcons.FiTrash2 boxSize="1.5rem" />}
                        aria-label="Delete"
                        onClick={clearFiles}
                      />
                    )}
                  </HStack>
                )}

                {!value && !isDisabled && (
                  <FileUpload<T>
                    name={name}
                    apiBaseUrl={apiBaseUrl}
                    endpointOptions={endpointOptions}
                    label={t('general.upload_file') ?? ''}
                    variant="outline"
                    colorScheme="gray"
                    size="sm"
                    onLoadStart={handleLoadStart}
                    onLoadEnd={handleLoadEnd}
                    onSuccess={handleSuccess}
                    setFileNameUpload={setLabel}
                  />
                )}
              </>
            )}
          </>
        )}
      </AttachmentWrapper>
    </>
  );
}
