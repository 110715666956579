import { Role } from '@api/core/entities';
import { AcademicStructureType } from '@modules/shared/entities';
import { Entity, Of } from 'entity-of';

export enum UserType {
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
  STAFF = 'STAFF',
}

export enum TeacherFlowType {
  BY_YEAR = 'YEAR',
  BY_SUBJECT = 'SUBJECT',
}

@Entity
export class AcademicStructureForm {
  @Of(() => UserType, { optional: true })
  userType?: UserType;

  @Of(() => TeacherFlowType, { optional: true })
  teacherFlowType?: TeacherFlowType;

  @Of(() => Number)
  step: number = 0;

  @Of(() => String)
  selectedUsers: GroupData[] | GroupMember[] = [];

  @Of(() => Boolean, { optional: true })
  isSelectedAll?: Boolean;

  @Of(() => String)
  pathway: Pathway[] = [];

  static of = Entity.of<AcademicStructureForm>();
}

@Entity
export class Pathway {
  @Of(() => Object)
  element: GroupData = GroupData.of({});

  @Of(() => Number)
  step: number = 0;
  static of = Entity.of<Pathway>();
}

@Entity
export class Group {
  @Of(() => String, { optional: true })
  groupName?: string;

  @Of(() => String, { optional: true })
  displayGroupName?: string;

  @Of(() => [])
  data: GroupData[] = [];

  static of = Entity.of<Group>();
}

export enum GroupDataTypes {
  DISCIPLINE = 'DISCIPLINES',
  ROLE = 'ROLE',
}
@Entity
export class GroupData {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number, { optional: true })
  parentId?: number;

  @Of(() => String)
  type?: AcademicStructureType | GroupDataTypes;

  @Of(() => String, { optional: true })
  studyCycle?: string;

  @Of(() => Number, { optional: true })
  studyCycleId?: number;

  @Of(() => String, { optional: true })
  studyDomain?: string;

  @Of(() => Number, { optional: true })
  studyDomainId?: number;

  @Of(() => String, { optional: true })
  studyGroup?: string;

  @Of(() => Number, { optional: true })
  studyGroupId?: number;

  @Of(() => String, { optional: true })
  studyProgram?: string;

  @Of(() => Number, { optional: true })
  studyProgramId?: number;

  @Of(() => String, { optional: true })
  studySeries?: string;

  @Of(() => Number, { optional: true })
  studySeriesId?: number;

  @Of(() => String, { optional: true })
  studyYear?: string;

  @Of(() => Number, { optional: true })
  studyYearId?: number;

  @Of(() => String, { optional: true })
  facultyName?: string;

  static of = Entity.of<GroupData>();
}

@Entity
export class GroupMember {
  @Of(() => Number)
  uaID: number = 0;

  @Of(() => String, { optional: true })
  uaTip?: string;

  @Of(() => String, { optional: true })
  uaFirstName?: string;

  @Of(() => String, { optional: true })
  uaLastName?: string;

  @Of(() => String, { optional: true })
  uaCadUniDepartament?: string;

  @Of(() => String, { optional: true })
  uaCadUniFunctie?: string;

  @Of(() => String)
  uaInitTata: string = '';

  @Of(() => [Role], { optional: true })
  role?: Role[] = [];

  @Of(() => String, { optional: true })
  studyCycle?: string;

  @Of(() => Number, { optional: true })
  studyCycleId?: number;

  @Of(() => String, { optional: true })
  studyDomain?: string;

  @Of(() => Number, { optional: true })
  studyDomainId?: number;

  @Of(() => String, { optional: true })
  studyGroup?: string;

  @Of(() => Number, { optional: true })
  studyGroupId?: number;

  @Of(() => String, { optional: true })
  studyProgram?: string;

  @Of(() => Number, { optional: true })
  studyProgramId?: number;

  @Of(() => String, { optional: true })
  studySeries?: string;

  @Of(() => Number, { optional: true })
  studySeriesId?: number;

  @Of(() => String, { optional: true })
  studyYear?: string;

  @Of(() => Number, { optional: true })
  studyYearId?: number;

  @Of(() => String, { optional: true })
  facultyName?: string;

  @Of(() => String, { optional: true })
  academicYearName?: string;

  @Of(() => Boolean, { optional: true })
  currentAcademicYear?: boolean;

  static of = Entity.of<GroupMember>();
}
