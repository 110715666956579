import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AdservioIcons, Box, InputGroup, Text, VStack } from '../../atoms';

export interface SearchBarWithResultsProps {
  entityName: string;
  onInputChange: (value: string) => void;
  items: any[];
  renderItem: (
    raw: any,
    options: { onToggle: (value: any) => void; selection: any[] }
  ) => React.ReactElement;
  onChange?: (value: string | number | null) => void;
  value?: string | number | null | string[] | number[];
  variant?: 'checkbox' | 'radio';
}

export const SearchBarWithResults = forwardRef<HTMLInputElement, SearchBarWithResultsProps>(
  (
    { value, onChange, onInputChange, renderItem, items, entityName, variant = 'checkbox' },
    ref
  ) => {
    const { t } = useTranslation('shared');
    const [_selection, _setSelection] = useState<any>(value ?? variant === 'checkbox' ? [] : null);

    const change = (ev: any) => {
      onInputChange(ev.target.value);
    };

    const toggleSelected = (value: any) => {
      _setSelection((previous) => {
        let next: any[] | any;

        if (variant === 'checkbox') {
          if ((previous as any[]).includes(value)) {
            next = previous.filter((v) => v !== value);
          } else {
            next = [...previous, value];
          }
        }

        if (variant === 'radio') {
          if (previous === value) {
            next = null;
          } else {
            next = value;
          }
        }

        onChange?.(next);
        return next;
      });
    };

    return (
      <VStack alignItems="flex-start" spacing="4">
        <InputGroup
          size={'md'}
          placeholder={t('general.type_here_ellipsis')}
          onChange={change}
          leftElement={<AdservioIcons.FiSearch color="gray.400" />}
        />

        <Box width="full">
          <Text fontWeight="medium" color="gray.700">
            {entityName}
          </Text>

          <Box
            h="27.5rem"
            width="100%"
            borderRadius="lg"
            border="1px solid"
            overflowY="auto"
            borderColor="gray.200"
          >
            <VStack p="2" spacing="2" alignItems="flex-start">
              {items.map((item) => {
                return renderItem(item, {
                  onToggle: toggleSelected,
                  selection: _selection,
                });
              })}

              {!items?.length && (
                <Text width="full" textAlign="center">
                  {t('general.no_results')}
                </Text>
              )}
            </VStack>
          </Box>
        </Box>
      </VStack>
    );
  }
);
