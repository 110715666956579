import { Entity, Of } from 'entity-of';

@Entity
export class PeriodsSearchInput {
  @Of(() => String)
  sanAscID = '';

  @Of(() => Number, { optional: true })
  sanIsActiv?: number;

  @Of(() => String, { optional: true, nullable: true })
  orgId?: string | null = '';

  static of = Entity.of<PeriodsSearchInput>();
}
