import { Entity, Of } from 'entity-of';

@Entity
export class StudyTypeYear {
  @Of(() => Number)
  year: number = 0;

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  typeYear: string = '';

  static of = Entity.of<StudyTypeYear>();
}
