import { Entity, Of } from 'entity-of';

@Entity
export class UpdateTransfersInput {
  @Of(() => String)
  tcTransferDate: string | null = null;

  @Of(() => Number)
  tcShowInReports: number = 0;

  @Of(() => Number)
  tcTransferNumber: number = 1;

  static of = Entity.of<UpdateTransfersInput>();
}
