import { VatCategory, VatExemptionCode } from '@modules/shared/utils/eFactura';
import { Entity, Of } from 'entity-of';

import { DiscountType } from '../Discount';

@Entity
export class DiscountPreviewInput {
  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  value: number = 0;

  @Of(() => Number)
  type: DiscountType = DiscountType.PERCENTAGE;

  @Of(() => String)
  currency: string = '';

  @Of(() => Number)
  serviceQuantity: number = 0;

  @Of(() => Number)
  serviceAmount: number = 0;

  @Of(() => String)
  invoiceDate: string = '';

  @Of(() => String)
  tenantCurrency: string = '';

  @Of(() => Number, { optional: true })
  exchangeRate?: number;

  @Of(() => Number, { optional: true })
  vatValue?: number;

  @Of(() => String, { optional: true })
  vatName?: string;

  @Of(() => VatCategory, { optional: true })
  vatCategory?: VatCategory;

  @Of(() => VatExemptionCode, { optional: true })
  vatExemptionCode?: VatExemptionCode;

  @Of(() => String, { optional: true })
  vatExemptionReason?: string;

  static of = Entity.of<DiscountPreviewInput>();
}
