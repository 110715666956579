// @ts-nocheck
import { useRouter } from '@lib/router';
import { useStorageSession } from '@lib/utils';
import PropTypes from 'prop-types';

import { allowedLanguages, getDefaultLanguage, useUriLang } from '../../i18n';

const HandleNoLangRoute = () => {
  const { pathname } = useRouter();
  const { profile } = useStorageSession();
  const uriLang = useUriLang();
  const defLang = getDefaultLanguage(profile?.prefLangID);

  const exceptions = ['/admitere'];

  if (exceptions.includes(pathname)) return null;

  if (!uriLang || allowedLanguages.indexOf(uriLang) === -1) {
    window.location.href = `/${defLang}/auth`;
  }

  return null;
};

HandleNoLangRoute.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default HandleNoLangRoute;
