import { Account } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class TimeOff {
  @Of(() => String)
  id: string = '';

  @Of(() => TimeOffType)
  type: TimeOffType = TimeOffType.of({});

  @Of(() => Account)
  teacher: Account = Account.of({});

  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  @Of(() => String)
  description: string = '';

  static of = Entity.of<TimeOff>();
}

@Entity
export class TimeOffType {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  icon: string = '';

  @Of(() => String)
  name: string = '';

  static of = Entity.of<TimeOffType>();
}
