import { Entity, Of } from 'entity-of';

@Entity
export class StudentCategory {
  @Of(() => Number)
  studentCategoryId: number = 0;

  @Of(() => String)
  name: string = '';

  static of = Entity.of<StudentCategory>();
}

@Entity
export class StudentCategoryResponse {
  @Of(() => [StudentCategory])
  result: StudentCategory[] = [];

  static of = Entity.of<StudentCategoryResponse>();
}
