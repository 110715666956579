import {
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';
import { t } from 'i18next';
import PasswordValidator from 'password-validator';

import { SystemDate } from './utils';

// TODO: check if this can be removed in favour of normal class-validator decorators.
interface PasswordPolicy {
  min: number;
  max?: number;
  digits?: number;
  uppercase?: number;
  lowercase?: number;
  special?: number;
}

export function IsPassword(policy: PasswordPolicy, validationOptions?: ValidationOptions) {
  const schema = new PasswordValidator();
  schema.max(policy.max || 32);
  schema.min(policy.min || 8);
  schema.digits(policy.digits || 0);
  schema.lowercase(policy.lowercase || 0);
  schema.uppercase(policy.uppercase || 0);
  schema.symbols(policy.special || 0);

  return function (object: Record<string, unknown>, propertyName: string) {
    registerDecorator({
      name: 'passwordPolicy',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [],
      options: validationOptions,
      validator: {
        validate(value: unknown) {
          return !!schema.validate(value as string);
        },
        defaultMessage() {
          return 'Nu bun';
        },
      },
    });
  };
}

export function MatchesField(field: string, validationOptions?: ValidationOptions) {
  return function (object: Record<string, unknown>, propertyName: string) {
    registerDecorator({
      name: 'matchesField',
      target: object.constructor,
      propertyName: propertyName,
      constraints: [field],
      options: validationOptions,
      validator: {
        validate(value: unknown, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const relatedValue = (args.object as Record<string, unknown>)[relatedPropertyName];
          return (relatedValue as unknown[]).length ? value === relatedValue : true;
        },
        defaultMessage(args: ValidationArguments) {
          const [relatedPropertyName] = args?.constraints ?? [];
          return `Value doesn't match field ${relatedPropertyName}`;
        },
      },
    });
  };
}

@ValidatorConstraint({ async: false })
class IsNotEmptyObjectConstraint implements ValidatorConstraintInterface {
  validate(value: any, _: ValidationArguments) {
    return value && typeof value === 'object' && Object.keys(value).length > 0;
  }

  defaultMessage(_: ValidationArguments) {
    return 'The object should not be empty';
  }
}

export function IsNotEmptyObject(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: IsNotEmptyObjectConstraint,
    });
  };
}

@ValidatorConstraint({ async: false })
class PhoneNumberWithPrefixConstraint implements ValidatorConstraintInterface {
  validate(value: any, _: ValidationArguments) {
    return (
      (value && typeof value === 'object' && value.prefix && value.phone) ||
      (!value.prefix && !value.phone)
    );
  }

  defaultMessage(_: ValidationArguments) {
    return 'Phone number is invalid';
  }
}

export function PhoneNumberWithPrefix(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: PhoneNumberWithPrefixConstraint,
    });
  };
}

@ValidatorConstraint({ async: false })
class PhoneNumberWithPrefixRequiredConstraint implements ValidatorConstraintInterface {
  validate(value: any, _: ValidationArguments) {
    return value && typeof value === 'object' && (value.prefix || value.phone);
  }

  defaultMessage(_: ValidationArguments) {
    return 'Phone number is required';
  }
}

export function PhoneNumberWithPrefixRequired(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [],
      validator: PhoneNumberWithPrefixRequiredConstraint,
    });
  };
}

export function IsAfterStartHour(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'isAfterStartHour',
      target: object.constructor,
      propertyName: propertyName,
      constraints: ['endHour'],
      options: validationOptions,
      validator: {
        validate(value: string, args: ValidationArguments) {
          const startHour = String((args.object as any)['startHour']);
          const hasStartHour = Boolean(startHour);

          if (!hasStartHour || !value) {
            return true;
          }

          return (
            SystemDate.of(`2021-01-01T${startHour}`).raw.getTime() <
            SystemDate.of(`2021-01-01T${value}`).raw.getTime()
          );
        },
      },
    });
  };
}

@ValidatorConstraint({ async: false })
class IsEmailFromDomainConstraint implements ValidatorConstraintInterface {
  validate(email: string, args: ValidationArguments) {
    const [domains] = args.constraints;

    return (
      !email ||
      !domains ||
      !domains.length ||
      (typeof email === 'string' &&
        domains.some((domain) => email.toLowerCase().endsWith(`@${domain}`)))
    );
  }

  defaultMessage(args: ValidationArguments) {
    const [domains] = args.constraints;

    if (domains.length === 1) {
      return t('shared:errors.email.domain_single', { 0: domains });
    } else {
      return t('shared:errors.email.domain_multiple', { 0: domains.join(', ') });
    }
  }
}

export function IsEmailFromDomain(domain: string[], validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [domain],
      validator: IsEmailFromDomainConstraint,
    });
  };
}
