import { Entity, Of } from 'entity-of';

@Entity
export class PersonStaffSearchPayload {
  @Of(() => Number, { optional: true })
  number?: number;

  @Of(() => Number, { optional: true })
  offset?: number;

  @Of(() => Number, { optional: true })
  sort?: string;

  @Of(() => String, { optional: true })
  cadNumePrenume?: string;

  @Of(() => String, { optional: true })
  cadCnp?: string;

  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => Number, { optional: true })
  limit?: number;

  @Of(() => Boolean, { optional: true })
  withUnpublished?: boolean;

  @Of(() => [order], { optional: true })
  order?: order[];

  @Of(() => [filters], { optional: true })
  filters?: filters[];

  static of = Entity.of<PersonStaffSearchPayload>();
}

@Entity
class filters {
  @Of(() => String, { optional: true })
  field?: string;

  @Of(() => operators, { optional: true })
  operators?: operators;

  static of = Entity.of<filters>();
}

@Entity
class order {
  @Of(() => String)
  field: string = '';

  @Of(() => String)
  direction: string = '';

  static of = Entity.of<order>();
}

@Entity
class operators {
  @Of(() => String, { optional: true })
  gt?: string;

  @Of(() => String, { optional: true })
  lt?: string;

  @Of(() => String, { optional: true })
  eq?: string;

  @Of(() => String, { optional: true })
  not?: string;

  @Of(() => String, { optional: true })
  gte?: string;

  @Of(() => String, { optional: true })
  lte?: string;

  @Of(() => String, { optional: true })
  like?: string;

  @Of(() => String, { optional: true })
  in?: string;

  static of = Entity.of<operators>();
}
