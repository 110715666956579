import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class TopStudents {
  @Of(() => String)
  motivated: string = '';

  @Of(() => String)
  unmotivated: string = '';

  @Of(() => String)
  total: string = '';

  @Of(() => String)
  averageValue: string = '';

  @Of(() => String)
  astSistemNotare: string = '';

  @Of(() => Person)
  student: Person = Person.of({});

  static of = Entity.of<TopStudents>();
}
