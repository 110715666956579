import { IsNotEmpty, registerDecorator, ValidateIf, ValidationOptions } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class NewServiceInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.value.required' })
  name: string = '';

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.value.required' })
  @MinIfNotUndefined(0.01, { message: 'errors.amount.min' })
  value: number = 0;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.value.required' })
  currency: string = '';

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.value.required' })
  quantity: number = 1;

  @Of(() => String, { optional: true })
  details?: string;

  @Of(() => Boolean, { optional: true })
  saveService?: boolean;

  @Of(() => String)
  @ValidateIf((o) => o.isVatPayer)
  @IsNotEmpty({ message: 'errors.vatRate.required' })
  vatRateId?: string;

  @Of(() => String, { optional: true })
  isVatPayer?: boolean;

  static of = Entity.of<NewServiceInput>();
}

function MinIfNotUndefined(min: number, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'minIfNotUndefined',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: number) {
          return (
            value === null || typeof value === 'undefined' || Number.isNaN(value) || value > min
          );
        },
      },
    });
  };
}
