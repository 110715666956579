const url = window.env?.TUIASI_OAUTH_URL;
const clientId = window.env?.TUIASI_OAUTH_CLIENT_ID;

const isSSO = [url, clientId].reduce((prev, cur) => {
  return prev && typeof cur === 'string' && cur !== '';
}, true);

const oidcConfig = {
  authority: url,
  client_id: clientId,
};

export { isSSO, oidcConfig };
