import React, { PropsWithChildren, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';

import { Box, Button, Flex, IconNames, Text } from '../../atoms';
import { FeatureIcon, FeatureIconVariant } from '../FeatureIcon';
import { ModalProps } from '../Modal';

export type DialogVariant = 'destructive' | 'warn' | 'info' | 'success';

interface DialogProps extends ModalProps {
  isOpen: boolean;
  isLoading?: boolean;
  variant?: DialogVariant;
  icon?: IconNames;
  iconColorScheme?: string;
  title?: string;
  description?: string | React.ReactElement;
  cancelText?: string;
  confirmText?: string;
  closeOnConfirm?: boolean;
  hasActions?: boolean;
  hasClose?: boolean;
  backdropBlur?: string;
  confirmRightIcon?: React.ReactElement;
  confirmButtonColor?: string;
  onClose: () => void;
  onConfirm?: () => void;
  renderIcon?: () => React.ReactElement;
}

const variantsMap: Record<DialogVariant, FeatureIconVariant> = {
  destructive: 'error',
  warn: 'warning',
  info: 'info',
  success: 'success',
};

const Icons: Record<string, IconNames> = {
  info: 'FiInfo',
  warn: 'FiAlertCircle',
  destructive: 'FiXCircle',
  success: 'FiCheckCircle',
};

const ConfirmColorMap = {
  destructive: 'red',
  warn: 'orange',
  info: 'orange',
};

export const Dialog = ({
  title,
  isOpen,
  isLoading,
  icon,
  iconColorScheme,
  cancelText,
  confirmText,
  description,
  variant,
  closeOnConfirm = true,
  hasActions = true,
  hasClose = true,
  backdropBlur = '5px',
  confirmRightIcon,
  confirmButtonColor,
  onClose,
  onConfirm,
  renderIcon,
  children,
  ...rest
}: PropsWithChildren<DialogProps>) => {
  const { t } = useTranslation('shared');

  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleConfirm = () => {
    onConfirm?.();

    if (closeOnConfirm) {
      onClose();
    }
  };

  return (
    <AlertDialog
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
      {...rest}
    >
      <AlertDialogOverlay backdropFilter={`blur(${backdropBlur})`}>
        <AlertDialogContent borderRadius="xl" data-testid="dialog-modal" mx="4">
          <AlertDialogHeader
            pr="1.5rem"
            fontSize="lg"
            fontWeight="semibold"
            textAlign="center"
            pb="0"
            data-testid="dialog-modal-title"
          >
            {renderIcon?.() || (
              <Flex justify="center" w="full">
                <FeatureIcon
                  size="xl"
                  name={icon ?? Icons[variant ?? 'info']}
                  variant={variantsMap[variant ?? 'info']}
                  colorScheme={iconColorScheme}
                />
              </Flex>
            )}

            <Text mt="1.25rem">{title}</Text>
          </AlertDialogHeader>

          {(description || children) && (
            <AlertDialogBody
              textAlign="center"
              color="gray.500"
              mt="0.5rem"
              data-testid="dialog-modal-description"
            >
              {description && <Text color="gray.500">{description}</Text>}
              {children}
            </AlertDialogBody>
          )}

          {hasActions ? (
            <AlertDialogFooter justifyContent="stretch" pt="8" pb="6">
              {hasClose && (
                <Button
                  flex="1"
                  mr="3"
                  ref={cancelRef}
                  border="1px solid #D0D5DD"
                  isLoading={isLoading}
                  colorScheme="gray"
                  onClick={onClose}
                  variant="outline"
                  data-testid="close-modal-button"
                >
                  {cancelText || t('general.cancel')}
                </Button>
              )}

              <Button
                flex="1"
                colorScheme={confirmButtonColor ?? ConfirmColorMap[variant ?? 'info']}
                isLoading={isLoading}
                onClick={handleConfirm}
                rightIcon={confirmRightIcon}
                data-testid="confirm-modal-button"
              >
                {confirmText || t('general.confirm')}
              </Button>
            </AlertDialogFooter>
          ) : (
            <Box pb="6" />
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
