import { Person, PersonClass, StudyYear, Subject } from '@api/core/entities';
import { PersonTo } from '@modules/transfers/entities';
import { Entity, Of } from 'entity-of';

export enum WeekType {
  ODD = 'odd',
  EVEN = 'even',
  ALL = 'all',
}

@Entity
export class ClassAllocation {
  @Of(() => String)
  cmcID: string = '';

  @Of(() => String)
  cmcContribuieLaMedie: string = '';

  @Of(() => String)
  cmcFormulaMedie: string = '';

  @Of(() => String)
  cmcMedCuZecimale = '';

  @Of(() => String)
  cmcSistemNotare: string = '';

  @Of(() => String)
  cmcNrOre: string = '';

  @Of(() => String)
  cmcOrder: string = '';

  @Of(() => String)
  cmcNrElevi = '';

  @Of(() => Boolean || [CoProfesor])
  coprofesori: boolean | CoProfesor[] = false;

  @Of(() => [Elevi])
  elevi: Elevi[] = [];

  @Of(() => Subject)
  cmcMaterie: Subject = Subject.of({});

  @Of(() => Person)
  cmcCadru: Person = Person.of({});

  @Of(() => WeekType)
  cmcSaptTip: WeekType = WeekType.ALL;

  @Of(() => PersonClass)
  cmcClasa = PersonClass.of({});

  @Of(() => StudyYear)
  cmcAst = StudyYear.of({});

  @Of(() => [PersonTo], { optional: true })
  cmcCadruDropdownTo?: PersonTo[] = [];

  @Of(() => Person, { optional: true })
  cmcCadruFrom: Person = Person.of({});

  @Of(() => [Cmcs], { optional: true })
  cmcs?: Cmcs[] = [];

  @Of(() => String, { optional: true })
  fromCmcID?: string | null;

  @Of(() => String, { optional: true })
  toCmcID?: string;

  @Of(() => Boolean, { optional: true })
  copyClassbookData?: boolean;

  static of = Entity.of<ClassAllocation>();
}

@Entity
class Elevi {
  @Of(() => String)
  e2mID: string = '';

  @Of(() => String)
  e2mElevID: string = '';

  @Of(() => String)
  e2mElevAreTestareSpeciala: string = '';

  @Of(() => String)
  e2mElevAreTeza: string = '';

  @Of(() => String)
  e2mElevAreTITF: string = '';

  @Of(() => String)
  e2mElevAreSimulare: string = '';

  static of = Entity.of<Elevi>();
}

@Entity
export class CoProfesor {
  @Of(() => [Elevi], { nullable: true })
  elevi: Elevi[] = [];

  @Of(() => String)
  co2cID: string = '';

  @Of(() => Person)
  cadruInfo: Person = Person.of({});

  static of = Entity.of<CoProfesor>();
}

@Entity
class Cmcs {
  @Of(() => Number, { optional: true })
  fromCmcID?: number | null;

  @Of(() => Number, { optional: true })
  toCmcID?: number;

  @Of(() => Boolean, { optional: true })
  copyClassbookData?: boolean;

  static of = Entity.of<Cmcs>();
}
