import { Entity, Of } from 'entity-of';

import { InvoiceRetrieve, InvoiceSpvStatus, InvoiceStatus } from '../Invoice';

type Order = 'asc' | 'desc';

@Entity
export class PaginatedInvoicesInput {
  @Of(() => [String], { optional: true })
  billableAccountIds?: string[];

  @Of(() => [String], { optional: true })
  tenantBillingProfileIds?: string[];

  @Of(() => String, { optional: true })
  rootOrgId?: string;

  @Of(() => String, { optional: true })
  xAccountId?: string;

  @Of(() => String, { optional: true })
  statuses?: InvoiceStatus[];

  @Of(() => String, { optional: true })
  spvStatuses?: InvoiceSpvStatus[];

  @Of(() => Number, { optional: true })
  offset?: number = 0;

  @Of(() => Number, { optional: true })
  limit?: number = 1;

  @Of(() => [Number], { optional: true })
  invoiceIds?: number[];

  @Of(() => Number, { optional: true })
  serviceId?: number;

  @Of(() => String, { optional: true })
  createdStartDate?: string;

  @Of(() => String, { optional: true })
  createdEndDate?: string;

  @Of(() => String, { optional: true })
  dueStartDate?: string;

  @Of(() => String, { optional: true })
  dueEndDate?: string;

  @Of(() => String, { optional: true })
  serial?: string;

  @Of(() => Number, { optional: true })
  number?: number;

  @Of(() => String, { optional: true })
  order?: Order;

  @Of(() => String, { optional: true })
  orderBy?: string;

  @Of(() => String, { optional: true })
  retrieve?: InvoiceRetrieve;

  @Of(() => Boolean, { optional: true })
  withTenantProfile?: boolean;

  @Of(() => Boolean, { optional: true })
  withAccountProfile?: boolean;

  @Of(() => Boolean, { optional: true })
  withCharges?: boolean;

  @Of(() => Boolean, { optional: true })
  withExchangeRates?: boolean;

  @Of(() => Boolean, { optional: true })
  withReversedInvoiceInfo?: boolean;

  @Of(() => Number, { optional: true })
  totalAmount?: number;

  static of = Entity.of<PaginatedInvoicesInput>();
}
