import { Entity, Of } from 'entity-of';

import { Accommodation, StudentStudy } from '..';

@Entity
export class Student {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  firstName: string = '';

  @Of(() => String)
  lastName: string = '';

  @Of(() => String)
  nameInitials: string = '';

  @Of(() => Number)
  socialNumber: number = 0;

  @Of(() => String)
  birthDate: string = '';

  @Of(() => String)
  gender: string = '';

  @Of(() => String)
  country: string = '';

  @Of(() => String)
  county: string = '';

  @Of(() => String)
  city: string = '';

  @Of(() => String)
  address: string = '';

  @Of(() => String)
  phoneNumber: string = '';

  @Of(() => String)
  carNumber: string = '';

  @Of(() => String)
  emailAddress: string = '';

  @Of(() => String)
  personalEmailAddress: string = '';

  @Of(() => String)
  bankName: string = '';

  @Of(() => String)
  bankIban: string = '';

  @Of(() => String)
  bankCity: string = '';

  @Of(() => String)
  identityDocumentType: string = '';

  @Of(() => String)
  identityDocumentSeries: string = '';

  @Of(() => String)
  identityDocumentNumber: string = '';

  @Of(() => String)
  identityDocumentIssuedBy: string = '';

  @Of(() => String)
  identityDocumentExpiryDate: string = '';

  @Of(() => [Warning])
  warnings: Warning[] = [];

  @Of(() => [StudentStudy], { nullable: true })
  studies: StudentStudy[] = [];

  @Of(() => Accommodation, { nullable: true })
  accommodation: Accommodation | null = null;

  static of = Entity.of<Student>();
}

@Entity
class Warning {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  description: string = '';

  @Of(() => String)
  date: string = '';

  static of = Entity.of<Warning>();
}
