import { Entity, Of } from 'entity-of';

@Entity
export class InvoicedHistoryItem {
  @Of(() => String)
  date: string = '';

  @Of(() => Number)
  totalValue: number = 0;

  @Of(() => Number)
  convertedValue: number = 0;

  static of = Entity.of<InvoicedHistoryItem>();
}
