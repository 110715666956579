import { IsNotEmpty, Matches, MaxLength } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class GlobalHolidayInput {
  @Of(() => String)
  @MaxLength(80, { message: 'errors.holidayName.max_80' })
  @Matches(/^[^-\s]/, {
    message: 'errors.holidayName.no_white_space',
  })
  @IsNotEmpty({ message: 'errors.holidayName.required' })
  holName: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.date.required' })
  holDate: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.country.required' })
  holCountryID: string = '';

  static of = Entity.of<GlobalHolidayInput>();
}
