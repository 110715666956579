// @ts-nocheck
import { shallowEqual, useSelector } from 'react-redux';

import { get } from '../../../Redux/sessionSelectors';

export default () => {
  const profile = useSelector(get('profile'), shallowEqual);

  const sessionUaTip = profile ? profile.uaTip : null;

  const permissions = profile ? profile.permisiuni : null;

  const handlePermissions = (permissionsArray) => {
    if (!permissionsArray || !permissionsArray.length) {
      return false;
    }

    const permissionsId = [];

    permissionsArray.forEach((item) => {
      const { permID } = item;

      permissionsId.push(permID);
    });

    return permissionsId;
  };

  // Mock subscription
  const sessionSubscription = 1;

  /**
   * Public export
   */
  return {
    sessionUaTip,
    sessionPerm: handlePermissions(permissions),
    sessionSubscription,
  };
};
