import { IsNotEmpty, Matches, MaxLength } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class CreateGradeInput {
  @Of(() => Number)
  frID?: number = 0;

  @Of(() => String)
  @Matches(/^[^-\s]/, {
    message: 'errors.add_grade.no_white_space',
  })
  @IsNotEmpty({ message: 'errors.add_grade.required' })
  @MaxLength(150, {
    message: 'errors.add_grade.max_150',
  })
  grade?: string = '';

  @Of(() => String)
  description?: string = '';

  @Of(() => Number)
  uaID?: number = 0;

  static of = Entity.of<CreateGradeInput>();
}

@Entity
export class EditGradeInput extends CreateGradeInput {
  @Of(() => String)
  id: string = '';

  static of = Entity.of<EditGradeInput>();
}
