import { Entity, Of } from 'entity-of';

import { AccountBalance } from '../AccountBalance';

@Entity
export class BalanceSummary extends AccountBalance {
  @Of(() => String)
  currency: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  alias: string = '';

  @Of(() => Boolean)
  isDefault: boolean = false;

  static of = Entity.of<BalanceSummary>();
}
