import { Entity, Of } from 'entity-of';

import { Invoice } from '../Invoice';

export type PaymentStatus = 'open' | 'closed';
export type PaymentType = 'card' | 'cash' | 'bankTransfer' | 'sold';
export enum PaymentProvider {
  NETOPIA = 'netopia',
  STRIPE = 'stripe',
  LOCAL = 'local',
}

export const paymentTypeToI18nMap: Record<PaymentType, string> = {
  bankTransfer: 'bank_transfer',
  card: 'card',
  cash: 'cash',
  sold: 'sold',
};

@Entity
export class Payment {
  @Of(() => Number)
  id = 0;

  @Of(() => Number)
  amount = 0;

  @Of(() => Number)
  remainingAmount = 0;

  @Of(() => String)
  currency = '';

  @Of(() => String)
  status: PaymentStatus = 'open';

  @Of(() => String)
  type: PaymentType = 'cash';

  @Of(() => String)
  billableAccountId = '';

  @Of(() => String)
  createdAt = '';

  @Of(() => String, { optional: true })
  notes?: string;

  @Of(() => String, { optional: true })
  serial?: string;

  @Of(() => Number, { optional: true })
  number?: number;

  @Of(() => String, { optional: true })
  receiptDate?: string;

  @Of(() => [Invoice])
  invoices: Partial<Invoice>[] = [];

  static of = Entity.of<Payment>();
}

@Entity
export class PaymentInfo {
  @Of(
    () => ({
      String,
      Number,
      Boolean,
    }),
    { optional: true }
  )
  extraInfo?: Record<string, any>;

  @Of(() => String)
  paymentSessionid: string = '';

  @Of(() => String)
  paymentUrl: string = '';

  @Of(() => String)
  provider: PaymentProvider = PaymentProvider.NETOPIA;

  static of = Entity.of<PaymentInfo>();
}

export class PaymentOnboardingLink {
  @Of(() => String)
  url = '';

  static of = Entity.of<PaymentOnboardingLink>();
}
