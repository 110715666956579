import { TranslatedLanguageItem } from '@api/core/entities';
import { VisibilityItem } from '@modules/shared/components/SelectOrganizationTree/entities';
import { Entity, Of } from 'entity-of';

import { FeatureDetails } from '../Feature';

import { PackageFeature } from './PackageFeature';

export enum PricingModel {
  FIXED = 'fixed',
  MEMBER = 'member',
}

export enum SubscriberType {
  ORGANIZATION = 'organization',
  STUDENT = 'student',
}

@Entity
export class Package {
  @Of(() => String)
  id = '';

  @Of(() => String)
  name = '';

  @Of(() => String)
  countryId = '';

  @Of(() => String)
  countryName = '';

  @Of(() => String)
  organizationTypeId = '';

  @Of(() => SubscriberType)
  subscriberType = 'organization';

  @Of(() => String)
  description = '';

  @Of(() => String)
  internalReference = '';

  @Of(() => Boolean)
  isPublic = false;

  @Of(() => Boolean)
  eligibleForTrial = false;

  @Of(() => Number)
  trialDays: number = 0;

  @Of(() => Boolean)
  isPopular = false;

  @Of(() => Number)
  order = 0;

  @Of(() => Number, { nullable: true })
  groupId: number | null = null;

  @Of(() => Number)
  monthlyPrice: number = 0;

  @Of(() => Number)
  monthlyPriceWithTaxes: number = 0;

  @Of(() => Number)
  monthlyPriceWithoutVat: number = 0;

  @Of(() => Number)
  monthlyPriceDiscounted: number = 0;

  @Of(() => Number)
  monthlyVat: number = 0;

  @Of(() => Number)
  yearlyPrice: number = 0;

  @Of(() => Number)
  yearlyPriceWithTaxes: number = 0;

  @Of(() => Number)
  yearlyPriceWithoutVat: number = 0;

  @Of(() => Number)
  yearlyPriceDiscounted: number = 0;

  @Of(() => Number)
  yearlyVat: number = 0;

  @Of(() => String)
  pricingModel = PricingModel.FIXED;

  @Of(() => Number, { nullable: true })
  licensesLimit: number | null = null;

  @Of(() => Number)
  billingProfileId = 0;

  @Of(() => Number)
  billingServiceId = 0;

  @Of(() => String)
  vatRateId = '';

  @Of(() => String)
  studentDefaultPackageId = '';

  @Of(() => String)
  fallbackPackageId = '';

  @Of(() => String, { optional: true })
  createdAt = '';

  @Of(() => String, { optional: true })
  updatedAt = '';

  @Of(() => String)
  status: 'active' | 'inactive' = 'active';

  @Of(() => Number)
  licensesCount = 0;

  @Of(() => [PackageFeature])
  featuresData: PackageFeature[] = [];

  @Of(() => String)
  currencyCode: string = '';

  @Of(() => [GroupPrice])
  groupPrices: GroupPrice[] = [];

  @Of(() => [TranslatedLanguageItem])
  packageName: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  packageDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  priceLabel: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  priceDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  featuresDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature1: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature2: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature3: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature4: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature5: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  promoBannerDescription: TranslatedLanguageItem[] = [];

  @Of(() => [VisibilityItem])
  visibility: VisibilityItem[] = [];

  @Of(() => Number, { optional: true })
  activeOrganizations?: number;

  @Of(() => String)
  colorTheme: string = '';

  @Of(() => [FeatureDetails])
  features: FeatureDetails[] = [];

  static of = Entity.of<Package>();
}

@Entity
export class GroupPrice {
  @Of(() => String)
  id = '';

  @Of(() => Number)
  price = 0;

  @Of(() => Number)
  yearlyPrice: number = 0;

  @Of(() => Number)
  yearlyPriceDiscounted: number = 0;

  @Of(() => Number)
  monthlyPrice: number = 0;

  @Of(() => Number)
  monthlyPriceDiscounted: number = 0;

  @Of(() => String)
  currency: string = '';

  @Of(() => Number)
  licensesCount = 0;

  static of = Entity.of<GroupPrice>();
}
