import { Address, Country, County, Label } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class TreeEntryShape {
  @Of(() => String, { optional: true })
  id: string = '';

  @Of(() => String, { optional: true })
  liceuID: string = '';

  @Of(() => String, { optional: true })
  liceuNume: string = '';

  @Of(() => String, { optional: true })
  name: string = '';

  @Of(() => String, { optional: true })
  liceuClasificare: string = '';

  @Of(() => Address, { optional: true })
  liceuAdresa?: Address;

  @Of(() => String)
  liceuOras: string = '';

  @Of(() => String, { optional: true })
  countScoli: string = '';

  @Of(() => String, { optional: true })
  cityID: string = '';

  @Of(() => String, { optional: true })
  cityName: string = '';

  @Of(() => String, { optional: true })
  cityFullName: string = '';

  @Of(() => County, { optional: true })
  adrJudet?: County;

  @Of(() => Country, { optional: true })
  adrTara?: Country;

  @Of(() => String, { optional: true })
  judID: string = '';

  @Of(() => String, { optional: true })
  judNume: string = '';

  @Of(() => String)
  taraID: string = '';

  @Of(() => String)
  taraNume: string = '';

  @Of(() => String)
  taraNumeFull: string = '';

  @Of(() => String)
  taraCod: string = '';

  @Of(() => String)
  taraPhonePrefix: string = '';

  @Of(() => String)
  taraFlag: string = '';

  @Of(() => String)
  taraNumeLocalized: string = '';

  @Of(() => String)
  liceuReprezentantUaID: string = '';

  @Of(() => String)
  liceuOrgType: string = '';

  @Of(() => String)
  liceuLogo: string = '';

  @Of(() => String)
  liceuTel: string = '';

  @Of(() => String)
  liceuIsActive: string = '';

  @Of(() => String)
  liceuCUI: string = '';

  @Of(() => String)
  liceuIsHidden: string = '';

  @Of(() => String)
  liceuOrgParentID: string = '';

  @Of(() => String)
  liceuOrgRootID: string = '';

  @Of(() => [Label])
  labels: Label[] = [];

  @Of(() => String)
  liceuInsertDate: string = '';

  @Of(() => String)
  liceuSecretarID: string = '';

  @Of(() => String)
  liceuDirectorID: string = '';

  static of = Entity.of<TreeEntryShape>();
}
