import { UserTypes } from '@constants';
import { Entity, Of } from 'entity-of';

@Entity
export class PersonsSearchInput {
  @Of(() => String, { nullable: true })
  searchTerm: string | null = null;

  @Of(() => Boolean, { optional: true })
  cuAbsolventi?: Boolean;

  @Of(() => [String])
  uaTypes?: UserTypes[] = [];

  static of = Entity.of<PersonsSearchInput>();
}
