// @ts-nocheck
// duplicate in www/src/js/functions.js
const imageIcon = (extension) => {
  let output;
  let showExtension = false;

  const trimmedExtension = extension.trim(' .');
  switch (trimmedExtension) {
    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'xltx':
    case 'xltm':
    case 'xls':
    case 'xlt':
    case 'xla':
    case 'xlw':
    case 'xlr':
    case 'csv':
      output = 'excel';
      break;
    case 'doc':
    case 'dot':
    case 'wbk':
    case 'docx':
    case 'docm':
    case 'dotx':
    case 'dotm':
    case 'docb':
      output = 'word';
      break;
    case '.jpg':
    case 'heic':
    case 'jpg':
    case 'jpeg':
    case '.jpeg':
    case 'png':
    case '.png':
    case 'gif':
    case 'tif':
    case 'tiff':
    case 'bmp':
    case 'svg':
      output = 'image';
      break;
    case 'pdf':
      output = 'pdf';
      break;
    case 'pptx':
    case 'pptm':
    case 'ppt':
    case 'potx':
    case 'potm':
    case 'ppsx':
    case 'ppsm':
    case 'pps':
    case 'odp':
      output = 'powerpoint';
      break;
    case 'txt':
    case 'log':
    case 'rtf':
      output = 'text';
      break;
    case 'webm':
    case 'mkv':
    case 'flv':
    case 'vob':
    case 'ogv':
    case 'ogg':
    case 'gifv':
    case 'mng':
    case 'avi':
    case 'mov':
    case 'wmv':
    case 'mp4':
    case 'm4v':
    case 'mpg':
      output = 'video';
      break;
    case 'mp3':
      output = 'audio';
      break;
    case 'tar':
    case 'z':
    case '7z':
    case 'iso':
    case 'rar':
    case 'zip':
    case 'zipx':
      output = 'arhiva';
      break;
    case null:
      output = 'other';
      break;
    default:
      output = 'others-empty';
      showExtension = true;
      break;
  }

  return {
    image: output,
    showExtension,
  };
};

export default imageIcon;
