import { Entity, Of } from 'entity-of';

@Entity
export class ForeignLanguagesTypes {
  @Of(() => String)
  id: string = '';

  @Of(() => [])
  items: Language[] = [];

  @Of(() => StudentsByGenderCounts)
  total: StudentsByGenderCounts = StudentsByGenderCounts.of({});

  static of = Entity.of<ForeignLanguagesTypes>();
}

@Entity
export class StudentsByGenderCounts {
  @Of(() => Number)
  male: number = 0;

  @Of(() => Number)
  female: number = 0;

  @Of(() => Number)
  other: number = 0;

  @Of(() => Number)
  total: number = 0;

  static of = Entity.of<StudentsByGenderCounts>();
}

@Entity
export class Language extends StudentsByGenderCounts {
  @Of(() => String)
  language: string = '';

  static of = Entity.of<Language>();
}
