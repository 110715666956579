import { Entity, Of } from 'entity-of';

@Entity
export class Graduate {
  @Of(() => String, { optional: true })
  clID?: string = '';

  @Of(() => String, { optional: true })
  clNume?: string = '';

  @Of(() => String, { optional: true })
  promovati?: string = '';

  @Of(() => Corijenti)
  corigenti: Corijenti = Corijenti.of({});

  @Of(() => Repetenti)
  repetenti: Repetenti = Repetenti.of({});

  @Of(() => SituatieNeincheiata)
  situatie_neincheiata: SituatieNeincheiata = SituatieNeincheiata.of({});

  @Of(() => String)
  retrasi: string = '';

  @Of(() => String)
  total_students: string = '';

  static of = Entity.of<Graduate>();
}

@Entity
export class Corijenti {
  @Of(() => String, { optional: true })
  total?: string = '';

  @Of(() => Subiecte)
  subjects: Subiecte = Subiecte.of({});

  static of = Entity.of<Corijenti>();
}

@Entity
export class SituatieNeincheiata {
  @Of(() => String, { optional: true })
  total?: string = '';

  @Of(() => String, { optional: true })
  ss_3?: string = '';

  @Of(() => String, { optional: true })
  ss_4?: string = '';

  @Of(() => String, { optional: true })
  ss_5?: string = '';

  @Of(() => Subiecte)
  subjects: Subiecte = Subiecte.of({});

  static of = Entity.of<SituatieNeincheiata>();
}

@Entity
export class Repetenti {
  @Of(() => String, { optional: true })
  total?: string = '';

  @Of(() => String, { optional: true })
  total_toamna?: string = '';

  @Of(() => String, { optional: true })
  ss_6?: string = '';

  @Of(() => String, { optional: true })
  ss_7?: string = '';

  @Of(() => String, { optional: true })
  ss_8?: string = '';

  @Of(() => String, { optional: true })
  ss_9?: string = '';

  @Of(() => Subiecte)
  subjects: Subiecte = Subiecte.of({});

  static of = Entity.of<Repetenti>();
}

@Entity
export class Subiecte {
  @Of(() => String, { optional: true })
  single?: string = '';

  @Of(() => String, { optional: true })
  double?: string = '';

  @Of(() => String, { optional: true })
  multiple?: string = '';

  static of = Entity.of<Subiecte>();
}
