import { useTranslation } from 'react-i18next';

import {
  CONFIG_FLAGS,
  FEATURE_FLAGS,
  MODULE_BASE_ROUTES,
  PERM_ADMISSION_MANAGER,
  PERM_ADMISSION_OFFICER,
  PERM_ATT_REGISTER_MANAGER,
  PERM_BILLING_MANAGER,
  PERM_CAD_DIRECTOR,
  PERM_CAD_REP_ADSERVIO,
  PERM_CAD_SECRETAR,
  PERM_LIBRARY_ADMIN,
  PERM_LIBRARY_MANAGER,
  PERM_LIBRARY_VIEW,
  PERM_QUESTIONNAIRE_CREATE_FEEDBACK,
  PERM_QUESTIONNAIRE_CREATE_SURVEY,
  PERM_QUESTIONNAIRE_CREATE_TEST,
  PERM_QUESTIONNAIRE_VIEW,
  PERM_TIMETABLE_CONFIG,
  REPORTS_ROUTES,
  TYPE_CADRU,
  TYPE_ELEV,
  TYPE_PARINTE,
} from '@constants';
import { useRouter } from '@lib/router';
import { LinkButtonItem, LinkButtonsGroup } from '@modules/shared';

export const useSecretaryLinkGroups = () => {
  const { lang } = useRouter();
  const { t } = useTranslation(['Site', 'common']);

  const groups: LinkButtonsGroup[] = [
    {
      items: [
        LinkButtonItem.of({
          icon: 'FiHome',
          label: t('buttons_mm.dashboard'),
          url: 'dashboard',
          matchingUrls: [
            'secretary/configuration',
            'secretary/configuration/information',
            'secretary/configuration/notification',
            'secretary/configuration/study_years',
            'configuration/school-years/26',
            'secretary/configuration/catalog',
            'configuration/roles-and-permissions',
            'secretary/configuration/messages',
            'secretary/configuration/behavior',
            'secretary/configuration/groups',
            'secretary/configuration/homework',
            'secretary/configuration/activities_staff',
            'secretary/config/forms',
            'secretary/configuration/observations',
            'secretary/configuration/schedule',
            'secretary/configuration/files',
            'secretary/configuration/iq_tests',
            'secretary/configuration/library',
            'secretary/configuration/photos',
            'secretary/configuration/admittance',
            'secretary/configuration/edumarket',
            'secretary/configuration/neuron_english',
            'secretary/configuration/news',
            'secretary/configuration/teacher_history',
          ],
          accessRules: {
            permissions: [PERM_CAD_DIRECTOR, PERM_CAD_SECRETAR, PERM_CAD_REP_ADSERVIO],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiUsers',
          label: t('buttons_mm.elevi'),
          url: 'secretary/students',
          matchingUrls: [
            'secretary/students/add',
            'secretary/import_students',
            'secretary/export/students',
            'secretary/export/elevi',
            'secretary/elevi/coduri_acces',
            'secretary/students/bulk_codes',
          ],
          redirectRule: 'window',
        }),
        LinkButtonItem.of({
          icon: 'FiRepeat',
          label: t('buttons_mm.transferuri'),
          url: 'secretary/students_transfer/schools/1',
          matchingUrls: [
            'secretary/students_transfer/subjects',
            'secretary/students_transfer/classes',
            'secretary/students_transfer/years',
          ],
          redirectRule: 'window',
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.subjects'),
              url: 'secretary/students_transfer/subjects',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.classes'),
              url: 'transfers/classes-transfers',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.schools'),
              url: 'secretary/students_transfer/schools/1',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.years'),
              url: 'secretary/students_transfer/years',
              redirectRule: 'window',
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'Teachers',
          label: t('buttons_mm.school_personnel'),
          url: 'school-personnel',
          matchingUrls: [
            'school-personnel/associated-classes',
            'secretary/teachers/access_codes',
            'secretary/teachers/summary_activities',
            'secretary/teachers_import',
            'secretary/export/profesori',
            'secretary/other-activities',
            'attendance',
          ],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.lista_personal'),
              url: 'school-personnel',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.attendance'),
              url: 'attendance',
              featureFlag: FEATURE_FLAGS.FLAG_ATTENDANCE_REGISTER,
              accessRules: {
                permissions: [PERM_ATT_REGISTER_MANAGER],
                configFlags: [CONFIG_FLAGS.ATTENDANCE_REGISTER],
              },
              isNew: true,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.coduri_inregistrare'),
              url: 'secretary/teachers/access_codes',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.rezumat_activitati'),
              url: 'secretary/teachers/summary_activities',
              redirectRule: 'window',
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'FiList',
          label: t('buttons_mm.nomenclatoare'),
          matchingUrls: [
            'nomenclature/subjects',
            'nomenclature/specializations',
            'nomenclature/nationalities',
            'nomenclature/ethnicity',
            'nomenclature/buildings',
            'nomenclature/rooms',
          ],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.materii'),
              url: 'nomenclature/subjects',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.specializari'),
              url: 'nomenclature/specializations',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.nationalitati'),
              url: 'nomenclature/nationalities',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.ethnicity'),
              url: 'nomenclature/ethnicity',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.buildings_and_rooms'),
              url: 'nomenclature/buildings',
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'Classes',
          label: t('buttons_mm.clase'),
          url: 'classes',
          matchingUrls: [
            'secretary/classes/add_multiple',
            'secretary/clase/coduri_acces',
            'secretary/classes/access_codes',
            'secretary/classes/teachers',
            'secretary/classes/edit',
            'secretary/classes/schedule',
            'secretary/classes/schedule/sets_slots',
            'secretary/sync/class',
            'secretariat/sincronizare',
            'timetable/class',
          ],
        }),
        LinkButtonItem.of({
          icon: 'FiFileText',
          label: t('buttons_mm.documents'),
          url: `main-documents`,
          matchingUrls: [
            'main-documents/classbook-form',
            'main-documents/student-registration-paper',
            'main-documents/student-file',
            'main-documents/student-certificates',
            'main-documents/student-diplomas',
            'secretary/students/registration_paper',
          ],
          accessRules: {
            permissions: [PERM_CAD_DIRECTOR, PERM_CAD_SECRETAR, PERM_CAD_REP_ADSERVIO],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiBarChart2',
          label: t('buttons_mm.statistici'),
          matchingUrls: [
            'secretary/rankings_grades',
            'secretary/rankings_grades/time_slot',
            'secretary/rankings_grades/classes',
            'secretary/rankings_grades/average',
            'secretary/rankings_attendance',
            'secretary/rankings_attendance/time_slot',
            'secretary/rankings_attendance/classes',
            'secretary/rankings_classes/final_average',
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.STUDENTS].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.SCHOLARSHIPS].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.FOREIGN_LANGUAGES].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.FOREIGN_LANGUAGES_TYPES].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.FAMILY_SITUATION].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.AGE].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.BIRTHPLACE].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.NATIONALITY].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.GRADES].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.ABSENCES].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.TOP_STUDENTS].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.LIVING_SITUATION].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.ENVIRONMENT].join('/'),
          ],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.statistici_elevi'),
              url: [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.STUDENTS].join('/'),
              matchingUrls: [
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.STUDENTS].join('/'),
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.SCHOLARSHIPS].join('/'),
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.FOREIGN_LANGUAGES].join('/'),
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.FOREIGN_LANGUAGES_TYPES].join('/'),
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.FAMILY_SITUATION].join('/'),
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.AGE].join('/'),
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.BIRTHPLACE].join('/'),
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.NATIONALITY].join('/'),
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.TOP_STUDENTS].join('/'),
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.LIVING_SITUATION].join('/'),
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.ENVIRONMENT].join('/'),
              ],
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.statistici_note_si_medii'),
              url: [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.GRADES].join('/'),

              matchingUrls: [
                'reports/grades',
                'secretary/rankings_grades',
                'secretary/rankings_grades/time_slot',
                'secretary/rankings_grades/classes',
                'secretary/rankings_grades/average',
                'secretary/rankings_classes/final_average',
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.GRADES].join('/'),
              ],
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.statistici_absente'),
              url: [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.ABSENCES].join('/'),

              matchingUrls: [
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.ABSENCES].join('/'),
                'secretary/rankings_attendance',
                'secretary/rankings_attendance/time_slot',
                'secretary/rankings_attendance/classes',
              ],
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'Coins',
          label: t('buttons_mm.taxe'),
          url: 'secretary/taxes',
          redirectRule: 'window',
          accessRules: {
            configFlags: [CONFIG_FLAGS.TAXES_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'Coins',
          label: t('buttons_mm.financials'),
          url: 'billing',
          subitems: [
            LinkButtonItem.of({
              label: t('legacy-url.path.financial_panel'),
              url: `billing`,
            }),
            LinkButtonItem.of({
              label: t('legacy-url.path.invoices_payments'),
              url: `billing/invoices`,
            }),
            LinkButtonItem.of({
              label: t('legacy-url.path.balances'),
              url: `billing/balances`,
            }),
            LinkButtonItem.of({
              label: t('legacy-url.path.services'),
              url: `billing/services`,
            }),
            LinkButtonItem.of({
              label: t('legacy-url.path.profiles'),
              url: `billing/billing-profiles`,
            }),
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.BILLING_ACTIVE],
            permissions: [PERM_BILLING_MANAGER],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiClipboard',
          label: t('buttons_mm.tests_questionnaires'),
          matchingUrls: ['questionnaires/created', 'questionnaires/todo', 'questionnaires/results'],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.list_created'),
              url: `questionnaires/created`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.list_todo'),
              url: `questionnaires/todo`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.list_results'),
              url: `questionnaires/results`,
            }),
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.QUESTIONNAIRES_ACTIVE],
            permissions: [
              PERM_QUESTIONNAIRE_VIEW,
              PERM_QUESTIONNAIRE_CREATE_SURVEY,
              PERM_QUESTIONNAIRE_CREATE_FEEDBACK,
              PERM_QUESTIONNAIRE_CREATE_TEST,
            ],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiCalendar',
          label: t('buttons_mm.calendar'),
          url: `calendar`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.CALENDAR_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiClock',
          label: t('buttons_mm.timetables'),
          matchingUrls: [`timetable/list`, `timetable/schedules`],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.timetable_list'),
              url: `timetable/list`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.programs_and_time_intervals'),
              url: `timetable/schedules`,
            }),
          ],
          featureFlag: FEATURE_FLAGS.FLAG_TIMETABLES,
          accessRules: {
            configFlags: [CONFIG_FLAGS.TIMETABLE_ACTIVE],
            permissions: [PERM_TIMETABLE_CONFIG],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiAward',
          label: t('buttons_mm.conduita'),
          matchingUrls: [
            'secretary/behavior',
            'secretary/behavior/score',
            'secretary/behavior/reasons',
            'behavior/warnings',
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.BEHAVIOUR_ACTIVE],
          },
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.conduita_elevi'),
              url: 'secretary/behavior',
              redirectRule: 'window',
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.avertismente'),
              url: 'behavior/warnings',
              accessRules: {
                permissions: [PERM_CAD_DIRECTOR, PERM_CAD_SECRETAR, PERM_CAD_REP_ADSERVIO],
              },
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'CancelGrades',
          label: t('buttons_mm.anulare_note'),
          url: 'secretary/cancel_grades',
          matchingUrls: [
            'secretary/cancel_grades/request_grades',
            'secretary/cancel_grades/approved_grades',
            'secretary/cancel_grades/rejected_grades',
          ],
          redirectRule: 'window',
          accessRules: {
            permissions: [PERM_CAD_DIRECTOR, PERM_CAD_REP_ADSERVIO],
          },
        }),
      ],
    },
    {
      emptyTitle: true,
      items: [
        LinkButtonItem.of({
          icon: 'FiMail',
          label: t('buttons_mm.mesaje'),
          url: `messages`,
          matchingUrls: [
            'messages/send',
            'messages/sent',
            'messages/drafts',
            'messages/archive',
            'messages/received',
          ],
        }),
        LinkButtonItem.of({
          icon: 'FiFile',
          label: t('buttons_mm.fisiere'),
          url: `files`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.FILES_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiImage',
          label: t('buttons_mm.foto'),
          matchingUrls: ['foto', 'anuar'],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.foto'),
              url: `foto`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.yearbook'),
              url: `anuar`,
              accessRules: {
                configFlags: [CONFIG_FLAGS.ANUAR_ACTIVE],
              },
            }),
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.PHOTOS_ACTIVE],
            uaTypes: [TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU],
          },
        }),
        LinkButtonItem.of({
          icon: 'Library',
          label: t('buttons_mm.biblioteca'),
          url: `library`,
          matchingUrls: ['library/manage'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.LIBRARY_ACTIVE],
            permissions: [PERM_LIBRARY_ADMIN, PERM_LIBRARY_MANAGER, PERM_LIBRARY_VIEW],
          },
        }),
        LinkButtonItem.of({
          icon: 'Groups',
          label: t('buttons_mm.groups'),
          url: `groups`,
          matchingUrls: ['groups/'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.GROUPS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiBell',
          label: t('buttons_mm.noutati'),
          url: 'news',
          matchingUrls: ['news/item'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.NEWS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiPieChart',
          label: t('buttons_mm.rapoarte_elevi'),
          url: 'secretary/students_reports',
          accessRules: {
            configFlags: [CONFIG_FLAGS.STUDENT_REPORTS_ACTIVE],
          },
          matchingUrls: ['secretary/students_reports/inactive'],
          redirectRule: 'window',
        }),
        LinkButtonItem.of({
          icon: 'FiShoppingCart',
          label: t('buttons_mm.edumarket'),
          url: `edumarket`,
          matchingUrls: ['edumarket/about'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.EDUMARKET_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'GraduationCap',
          label: t('buttons_mm.admission'),
          url: `admission`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.ADMISSION_ACTIVE],
            permissions: [PERM_ADMISSION_MANAGER, PERM_ADMISSION_OFFICER],
          },
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.admission_portal'),
              url: `admission/offers`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.sessions_administration'),
              url: `admission`,
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'FiBook',
          label: t('buttons_mm.decision_register'),
          url: 'decision-register/search',
          matchingUrls: ['decision-register'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.DECISION_REGISTER_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiHelpCircle',
          label: t('buttons_mm.support'),
          url:
            lang === 'ro'
              ? 'https://manual.adservio.ro/web/director-secretar/'
              : 'https://manual.adservio.ro/web/en/headmaster-secretary/',
          redirectRule: 'blank',
          hasTopDivider: true,
        }),
      ],
    },
  ];

  return {
    groups,
  };
};
