import { Entity, Of } from 'entity-of';

import { Dorm } from '..';

@Entity
export class AvailableRooms {
  @Of(() => [Dorm])
  dorms: Dorm[] = [];

  static of = Entity.of<AvailableRooms>();
}
