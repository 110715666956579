import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AdservioIcons, FormControl, FormLabel, InputGroup, Spinner } from '@ui2/components';

interface SearchPersonsFormProps {
  isLoading?: boolean;
  onChange?: (value: string) => void;
}

export const SearchPersonsInput = forwardRef<HTMLInputElement, SearchPersonsFormProps>(
  ({ onChange, isLoading }, ref) => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const { value } = e.target;
      onChange?.(value);
    };

    const { t } = useTranslation('billing');

    return (
      <FormControl name="searchTerm" showHelperText={false}>
        <FormLabel htmlFor="searchTerm" mb="1">
          {t('search.search_members')}
        </FormLabel>
        <InputGroup
          ref={ref}
          id="searchTerm"
          onChange={handleChange}
          data-testid="search-persons-input"
          placeholder={t('general.type_here')}
          leftElement={<AdservioIcons.FiSearch color="gray.600" />}
          rightElement={isLoading ? <Spinner size="md" /> : null}
        />
      </FormControl>
    );
  }
);
