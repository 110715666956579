import { Entity, Of } from 'entity-of';

@Entity
export class EditPeriodInput {
  @Of(() => String)
  sanID = '';

  @Of(() => String)
  type = '';

  @Of(() => String)
  orderID = '';

  @Of(() => String)
  startDate = '';

  @Of(() => String)
  endDate = '';

  @Of(() => String)
  isActive = '';

  static of = Entity.of<EditPeriodInput>();
}
