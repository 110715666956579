import React from 'react';

import { Flex, FlexProps } from '@chakra-ui/react';
import { getOrgTypeTheme } from '@ui2/utils';

interface CardProps extends FlexProps {
  isSelected?: boolean;
  isPartial?: boolean;
  isDisabled?: boolean;
}

export const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ children, isSelected, isPartial, isDisabled, ...props }, ref) => {
    const baseColor = getOrgTypeTheme('blue');

    let borderColor = 'gray.200';
    let borderWidth = '1px';
    let boxShadowHover = 'inputFocus';
    let borderColorHover = `${baseColor}.300`;

    if (isDisabled) {
      boxShadowHover = 'none';
      borderColorHover = 'gray.50';
    }

    if (isPartial) {
      borderWidth = '2px';
      borderColor = `${baseColor}.300`;
    }

    if (isSelected) {
      borderWidth = '2px';
      borderColor = `${baseColor}.300`;
    }

    return (
      <Flex
        p="4"
        ref={ref}
        tabIndex={0}
        outline="unset"
        borderRadius="xl"
        transitionProperty="common"
        transitionDuration="normal"
        bg={isSelected ? `${baseColor}.50` : isDisabled ? 'gray.50' : 'white'}
        boxShadow={isSelected ? 'unset' : 'xs'}
        borderWidth={borderWidth}
        borderColor={borderColor}
        _hover={{
          boxShadow: boxShadowHover,
          borderColor: borderColorHover,
        }}
        _focus={{
          boxShadow: boxShadowHover,
          borderColor: borderColorHover,
        }}
        {...props}
      >
        {children}
      </Flex>
    );
  }
);
