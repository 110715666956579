import { Entity, Of } from 'entity-of';

export class UserPaymentsTableFilters {
  @Of(() => String, { optional: true })
  type?: string;

  @Of(() => Number, { optional: true })
  amount?: number;

  @Of(() => String, { optional: true })
  createdAt?: string;

  static of = Entity.of<UserPaymentsTableFilters>();
}
