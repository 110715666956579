import { Entity, Of } from 'entity-of';

import { PaymentType } from '../Payment';

@Entity
export class PaymentCreateInput {
  @Of(() => Number)
  amount = 0;

  @Of(() => String)
  type: PaymentType = 'cash';

  @Of(() => String)
  billableAccountId = '';

  @Of(() => [Number])
  invoices: number[] = [];

  @Of(() => String, { optional: true })
  notes?: string;

  @Of(() => String, { optional: true })
  serial?: string;

  @Of(() => Number, { optional: true })
  number?: number;

  @Of(() => String, { optional: true })
  receiptDate?: string;

  @Of(() => String, { optional: true })
  rootOrgId?: string;

  static of = Entity.of<PaymentCreateInput>();
}
