import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useStorageSession } from '@lib/utils';
import {
  AdservioIcons,
  Box,
  Button,
  ButtonProps,
  Center,
  Divider,
  Grid,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
} from '@ui2/components';
import { useBreakpointValue } from '@ui2/hooks';

import Loader from '../Overlays/Loader/Loader';

import { OrganisationDetails } from './components/OrganisationDetails';

export const OrganisationsMenuButton = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { school } = useStorageSession();

  return (
    <>
      {isMobile ? (
        <MenuButton
          as={IconButton}
          icon={<AdservioIcons.University />}
          size="lg"
          variant="ghost"
          colorScheme="gray"
          py="1"
          px="2"
        ></MenuButton>
      ) : (
        <MenuButton
          as={Button}
          leftIcon={<AdservioIcons.University />}
          rightIcon={isMobile ? undefined : <AdservioIcons.FiChevronDown />}
          size="lg"
          variant="ghost"
          colorScheme="gray"
          py="1"
          px="2"
        >
          <OrganisationDetails school={school} />
        </MenuButton>
      )}
    </>
  );
};

export const MenuListWrapper = ({ children }: PropsWithChildren<{}>) => {
  return (
    <MenuList
      width={{ base: '100vw', sm: '360px' }}
      maxHeight="50vh"
      overflowY="auto"
      p="2"
      border="1px solid"
      borderColor="gray.200"
      boxShadow="3xl"
    >
      {children}
    </MenuList>
  );
};

interface OrganisationsMenuItemProps extends ButtonProps {
  isActive?: boolean;
}

export const OrganisationsMenuItem = ({
  isActive,
  onClick,
  children,
  ...props
}: PropsWithChildren<OrganisationsMenuItemProps>) => {
  return (
    <MenuItem
      className="organisations-search-menu-item"
      onClick={onClick}
      borderRadius="md"
      _active={{ bg: 'gray.100' }}
      _focus={{ bg: 'gray.100' }}
    >
      <Button
        as="div"
        variant="unstyled"
        colorScheme="gray"
        width="100%"
        height="initial"
        whiteSpace="initial"
        p="2"
        justifyContent="flex-start"
        textAlign="left"
        lineHeight="5"
        {...props}
      >
        {children}
      </Button>
    </MenuItem>
  );
};

interface MenuItemDividerProps {
  isAccountDivider?: boolean;
}

export const MenuItemDivider = ({ isAccountDivider }: MenuItemDividerProps) => {
  return (
    <Box p="2">
      <Divider borderColor={isAccountDivider ? 'gray.100' : 'gray.200'} />
    </Box>
  );
};

export const SkeletonMenuItems = () => {
  return (
    <Grid mt="2" templateColumns="1fr" rowGap="2">
      <Skeleton height="40px" />
      <Skeleton height="40px" />
      <Skeleton height="40px" />
    </Grid>
  );
};

export const SearchBarDisclaimer = () => {
  const { t } = useTranslation(['Site', 'common']);

  return (
    <Center mt="4" py="6" px="4">
      <Text fontWeight="semibold" textAlign="center">
        {t('header.use_search_bar')}
      </Text>
    </Center>
  );
};

export const NoOrganisationsResults = () => {
  const { t } = useTranslation(['Site', 'common']);

  return (
    <Center mt="4" py="6" px="4">
      <Text fontSize="sm" color="gray.400">
        {t('header.no_results')}
      </Text>
    </Center>
  );
};

export const FullpageLoader = () => {
  return (
    <Center
      position="fixed"
      left="0"
      top="0"
      width="100vw"
      height="min(100svh, 100vh)"
      backgroundColor="white"
      zIndex="9999"
    >
      <Loader />
    </Center>
  );
};
