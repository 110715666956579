import { WeekType } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

export enum ViewType {
  CLASSES = 'classes',
  TEACHERS = 'teachers',
  ROOMS = 'rooms',
}

@Entity
export class TimetableFilters {
  @Of(() => String, { optional: true })
  viewType?: ViewType;

  @Of(() => String, { optional: true })
  weekType?: WeekType;

  @Of(() => String, { optional: true })
  clID?: string;

  @Of(() => String, { optional: true })
  matID?: string;

  @Of(() => String, { optional: true })
  cadID?: string;

  @Of(() => String, { optional: true })
  roomID?: string;

  static of = Entity.of<TimetableFilters>();
}
