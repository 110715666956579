import { ArrayNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class UniversityStudentSearchResponse {
  @Of(() => Number)
  totalCount: number = 0;

  @Of(() => Number)
  paginationSize: number = 0;

  @Of(() => [UniversityPersonSearchEntity])
  persons: UniversityPersonSearchEntity[] = [];

  static of = Entity.of<UniversityStudentSearchResponse>();
}

@Entity
export class UniversityPersonSearchEntity {
  @Of(() => Number)
  personId: number = 0;

  @Of(() => String)
  actualName: string = '';

  @Of(() => String)
  birthGivenName: string = '';

  @Of(() => String)
  surname: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  parentInitial: string = '';

  @Of(() => String)
  cnp: string = '';

  @Of(() => String)
  universityEmail: string = '';

  @Of(() => String)
  personalEmail: string = '';

  @Of(() => String)
  phoneNumber: string = '';

  @Of(() => String)
  microsoftId: string = '';

  @Of(() => Number)
  moodleId: number = 0;

  @Of(() => Boolean)
  foreignCitizen: boolean = false;

  @Of(() => String)
  prevCNP: string = '';

  @Of(() => String)
  bankName: string = '';

  @Of(() => String)
  bankAddress: string = '';

  @Of(() => String)
  swift: string = '';

  @Of(() => String)
  iban: string = '';

  @Of(() => String)
  accountHolderName: string = '';

  @Of(() => Date)
  lastUpdate: Date = new Date();

  @Of(() => Boolean)
  admin: boolean = false;

  @Of(() => Boolean)
  manager: boolean = false;

  @Of(() => Boolean)
  associate: boolean = false;

  @Of(() => Boolean)
  erasmusProgram: boolean = false;

  @Of(() => [AssociatedFaculty])
  associatedFaculties: AssociatedFaculty[] = [];

  @Of(() => Boolean)
  erasmus: boolean = false;

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => [ErasmusStudentInformation], { nullable: true })
  erasmusStudentInformation: ErasmusStudentInformation[] | null = null;

  @Of(() => Boolean)
  student: boolean = false;

  @Of(() => [AssociatedGroup])
  associatedGroups: AssociatedGroup[] = [];

  @Of(() => Boolean)
  professor: boolean = false;

  @Of(() => String)
  professorDepartment: string = '';

  @Of(() => Number)
  professorDepartmentId: number = 0;

  @Of(() => String)
  professorFunction: string = '';

  @Of(() => Number)
  professorFunctionId: number = 0;

  @Of(() => String)
  professorFunctionType: string = '';

  @Of(() => Number)
  professorMark: number | string = 0;

  @Of(() => [AssociatedDiscipline])
  associatedDisciplines: AssociatedDiscipline[] = [];
}

@Entity
class AssociatedFaculty {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  parentId: number = 0;

  @Of(() => String)
  parentName: string = '';

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  acronym: string = '';

  @Of(() => String)
  uniqueAcronym: string = '';

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => Number)
  idPromotie: number = 0;
}

@Entity
class ErasmusStudentInformation {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  erasmusStudentId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => Number)
  facultyId: number = 0;

  @Of(() => Number)
  cycleId: number = 0;

  @Of(() => Number)
  domainId: number = 0;

  @Of(() => Number)
  programId: number = 0;

  @Of(() => String)
  originFacultyName: string = '';

  @Of(() => String)
  hostFacultyName: string = '';

  @Of(() => String)
  coordinatorName: string = '';

  @Of(() => String)
  coordinatorEmail: string = '';

  @Of(() => String)
  coordinatorPhone: string = '';

  @Of(() => String)
  domainName: string = '';

  @Of(() => String)
  programName: string = '';
}

@Entity
class AssociatedGroup {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  personId: number = 0;

  @Of(() => Number)
  studyId: number = 0;

  @Of(() => Number)
  facultyId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  registrationNumber: string = '';

  @Of(() => String)
  uniqueRegistrationNumber: string = '';

  @Of(() => String)
  studyStatus: string = '';

  @Of(() => String)
  groupName: string = '';

  @Of(() => String)
  facultyName: string = '';
}

@Entity
class AssociatedDiscipline {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  englishName: string = '';

  @Of(() => String)
  acronym: string = '';

  @Of(() => String)
  uniqueAcronym: string = '';

  @Of(() => Number)
  studyYearId: number = 0;

  @Of(() => Number)
  semester: number = 0;

  @Of(() => Number)
  series: number = 0;

  @Of(() => Number)
  examPercent: number = 0;

  @Of(() => Number)
  seminarPercent: number = 0;

  @Of(() => Number)
  laboratoryPercent: number = 0;

  @Of(() => Number)
  projectPercent: number = 0;

  @Of(() => Boolean)
  necessaryMinimumExamGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumSeminarGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumLaboratoryGrade: boolean = false;

  @Of(() => Boolean)
  necessaryMinimumProjectGrade: boolean = false;

  @Of(() => [AssociatedProfessor])
  associatedProfessors: AssociatedProfessor[] = [];

  @Of(() => String)
  role: string = '';

  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.roles.required' })
  roleName: string[] = [];

  @Of(() => String)
  facultyName: string = '';

  @Of(() => String)
  currentNumber: string = '';
}

@Entity
class AssociatedProfessor {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  birthGivenName: string = '';

  @Of(() => String)
  universityEmail: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  surname: string = '';

  @Of(() => String)
  discipline: string = '';

  @Of(() => String)
  acronyms: string = '';

  @Of(() => Number)
  disciplineId: number = 0;

  @Of(() => String)
  roles: string = '';

  @Of(() => String)
  years: string = '';

  @Of(() => String)
  semesters: string = '';

  @Of(() => String)
  role: string = '';

  @Of(() => String)
  department: string = '';

  @Of(() => Number)
  departmentId: number = 0;

  @Of(() => Number)
  mark: number = 0;

  @Of(() => String)
  function: string = '';

  @Of(() => Number)
  functionId: number = 0;

  @Of(() => String)
  functionType: string = '';

  @Of(() => Boolean)
  main: boolean = false;

  static of = Entity.of<UniversityPersonSearchEntity>();
}
