import { FEATURE_FLAGS } from '@constants';
import { Vectors } from '@ui2/assets';

interface ContentDetails {
  key: string;
  backUrl?: string;
  empty_state_src?: string;
  featureFlags?: FEATURE_FLAGS[];
  panels?: PanelDetails[];
}

export interface PanelDetails {
  translation_key: string;
  image: string;
  featureFlags?: FEATURE_FLAGS[];
}

export const getCalloutContent = (module: string): ContentDetails => {
  switch (module) {
    case 'progress':
      return {
        key: 'progress',
        empty_state_src: Vectors.EvolutionEmptyState,
        featureFlags: [FEATURE_FLAGS.FLAG_STATS_STUDENT_PROGRESS],
        panels: [
          {
            translation_key: 'ai',
            image: Vectors.EvolutionAI,
            featureFlags: [FEATURE_FLAGS.FLAG_STUDENT_AI_SUMMARY],
          },
        ],
      };
    case 'report_card':
      return {
        key: 'report_card',
        panels: [
          {
            translation_key: 'grade_probability',
            image: Vectors.Meter,
            featureFlags: [FEATURE_FLAGS.FLAG_CAT_GRADE_PROBABILITY],
          },
          {
            translation_key: 'feedback',
            image: Vectors.Feedback,
            featureFlags: [FEATURE_FLAGS.FLAG_HW_FEEDBACK],
          },
        ],
      };
    case 'observations':
      return {
        key: 'observations',
        empty_state_src: Vectors.UserAtScreen,
        featureFlags: [FEATURE_FLAGS.FLAG_MODULE_OBSERVATIONS],
      };
    case 'behaviour':
      return {
        key: 'behaviour',
        empty_state_src: Vectors.Conduct,
        featureFlags: [FEATURE_FLAGS.FLAG_MODULE_BEHAVIOUR],
      };
    case 'calendar':
      return {
        key: 'calendar',
        panels: [
          {
            translation_key: 'memento',
            image: Vectors.CalendarEdited,
            featureFlags: [FEATURE_FLAGS.FLAG_CAL_MEMENTO],
          },
          {
            translation_key: 'timetable',
            image: Vectors.Clock,
            featureFlags: [FEATURE_FLAGS.FLAG_TIMETABLES],
          },
        ],
      };
    case 'iq-test':
      return {
        key: 'iq_test',
        backUrl: 'edumarket/applications',
        empty_state_src: Vectors.Brain,
        featureFlags: [FEATURE_FLAGS.FLAG_MODULE_IQ_TEST],
      };
    case 'portfolio':
      return {
        key: 'portfolio',
        empty_state_src: Vectors.ResumeFolder,
        featureFlags: [FEATURE_FLAGS.FLAG_STUDENT_PORTFOLIO],
      };
    case 'selfknowledge-test':
      return {
        key: 'selfknowledge_test',
        backUrl: 'edumarket/applications',
        empty_state_src: Vectors.Brain2,
        featureFlags: [FEATURE_FLAGS.FLAG_MODULE_SELFKNOWLEDGE_TEST],
      };
    case 'messages':
      return {
        key: 'messages',
        empty_state_src: Vectors.MessagesEmptyState,
        featureFlags: [FEATURE_FLAGS.FLAG_MODULE_MESSAGES],
        panels: [
          {
            translation_key: 'group_messages',
            image: Vectors.GroupMessages,
            featureFlags: [FEATURE_FLAGS.FLAG_MSG_GROUP],
          },
          {
            translation_key: 'messages_reports',
            image: Vectors.Messages,
            featureFlags: [FEATURE_FLAGS.FLAG_MSG_OPENED_REPORT],
          },
        ],
      };
    case 'groups':
      return {
        key: 'groups',
        panels: [
          {
            translation_key: 'groups_add',
            image: Vectors.Group,
            featureFlags: [FEATURE_FLAGS.FLAG_GROUPS_ADD],
          },
          {
            translation_key: 'groups_chat_files',
            image: Vectors.Files,
            featureFlags: [FEATURE_FLAGS.FLAG_GROUPS_CHAT_FILES],
          },
        ],
      };
    case 'photos':
      return {
        key: 'photos',
        empty_state_src: Vectors.PhotosEmptyState,
        panels: [
          {
            translation_key: 'upload_photo',
            image: Vectors.Photos,
            featureFlags: [FEATURE_FLAGS.FLAG_FOTO_UPLOAD],
          },
          {
            translation_key: 'share_photo',
            image: Vectors.FilesShare,
            featureFlags: [FEATURE_FLAGS.FLAG_FOTO_SHARE],
          },
        ],
      };
    case 'yearbook':
      return {
        key: 'yearbook',
        empty_state_src: Vectors.YearbookEmptyState,
        featureFlags: [FEATURE_FLAGS.FLAG_MODULE_YEARBOOK],
      };
    case 'library_wellbeing':
      return {
        key: 'library_wellbeing',
        empty_state_src: Vectors.PersonalGrowth,
        featureFlags: [FEATURE_FLAGS.FLAG_LIB_WELLBEING],
      };
    case 'forms-created':
      return {
        key: 'forms_created_by_me',
        empty_state_src: Vectors.Checklist,
        featureFlags: [FEATURE_FLAGS.FLAG_MODULE_CHESTIONAIRES],
      };
    case 'forms-results':
      return {
        key: 'forms_results_and_assessments',
        empty_state_src: Vectors.Reports,
        panels: [
          {
            translation_key: 'forms_results',
            image: Vectors.Reports,
            featureFlags: [FEATURE_FLAGS.FLAG_MODULE_REPORT_FORMS],
          },
        ],
      };
    case 'wellbeing':
      return {
        key: 'wellbeing',
        empty_state_src: Vectors.EmotionsDiary,
        featureFlags: [FEATURE_FLAGS.FLAG_WELLBEING_MOODS],
        panels: [
          {
            translation_key: 'observations',
            image: Vectors.UserAtScreen,
            featureFlags: [FEATURE_FLAGS.FLAG_MODULE_OBSERVATIONS],
          },
          {
            translation_key: 'behaviour',
            image: Vectors.Conduct,
            featureFlags: [FEATURE_FLAGS.FLAG_MODULE_BEHAVIOUR],
          },
          {
            translation_key: 'iq_test',
            image: Vectors.IQTests,
            featureFlags: [
              FEATURE_FLAGS.FLAG_MODULE_IQ_TEST,
              FEATURE_FLAGS.FLAG_MODULE_SELFKNOWLEDGE_TEST,
            ],
          },
          {
            translation_key: 'personal_growth',
            image: Vectors.PersonalGrowth,
            featureFlags: [FEATURE_FLAGS.FLAG_LIB_WELLBEING],
          },
          {
            translation_key: 'moods',
            image: Vectors.EmotionsDiary,
            featureFlags: [FEATURE_FLAGS.FLAG_WELLBEING_MOODS],
          },
          {
            translation_key: 'ai',
            image: Vectors.EvolutionAI,
            featureFlags: [FEATURE_FLAGS.FLAG_STUDENT_AI_SUMMARY],
          },
        ],
      };
    default:
      return {
        key: 'generic',
        empty_state_src: Vectors.UserAtScreen,
      };
  }
};
