import { TaxType } from '@modules/Universities/accommodations/entities/Tax';
import {
  IsNotEmpty,
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
} from 'class-validator';
import { Entity, Of } from 'entity-of';

export function IsEndDateAfterStartDate(startDate: string, validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'isEndDateAfterStartDate',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      constraints: [startDate],
      validator: {
        validate(value: any, args: ValidationArguments) {
          const [relatedPropertyName] = args.constraints;
          const startDate = new Date((args.object as any)[relatedPropertyName]);
          const endDate = new Date(value);
          return endDate > startDate;
        },
      },
    });
  };
}

@Entity
export class TaxInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.tax.name.required' })
  name: string = '';

  @Of(() => String)
  type: TaxType = 'didactic';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.accommodations.tax.start_date.required' })
  startDate: string = '';

  @Of(() => String)
  @IsEndDateAfterStartDate('startDate', {
    message: 'errors.accommodations.tax.end_date.after_start_date',
  })
  @IsNotEmpty({ message: 'errors.accommodations.tax.end_date.required' })
  endDate: string = '';

  static of = Entity.of<TaxInput>();
}
