import { useQuery, UseQueryOptions } from 'react-query';

import axios, { AxiosError } from 'axios';

import { PlatformStatus } from '../entities';

type QueryInput = {};
type QueryResponse = PlatformStatus;

const getStatus = ({ queryKey }: UseQueryOptions<QueryInput>) => {
  return axios.get('https://sentry.adservio.ro/api/status').then((response) => response.data);
};

export const usePlatformStatusQuery = (
  queryInput?: QueryInput,
  options?: UseQueryOptions<QueryResponse, AxiosError>
) => {
  return useQuery<QueryResponse, AxiosError>(['platform-status', queryInput], getStatus, options);
};
