import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class InvoiceDatesInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.value.required' })
  issueDate = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.value.required' })
  lastDayOfPayment = '';

  static of = Entity.of<InvoiceDatesInput>();
}
