import { Entity, Of } from 'entity-of';

import { StatementStatus } from '../StatementData';

@Entity
export class StatementDataInput {
  @Of(() => Number)
  statementId: number = 0;

  @Of(() => Number, { optional: true })
  offset?: number = 0;

  @Of(() => Number, { optional: true })
  limit?: number = 1;

  @Of(() => String, { optional: true })
  order?: string;

  @Of(() => String, { optional: true })
  orderBy?: string;

  @Of(() => Boolean, { optional: true })
  withStatusCounts?: boolean;

  @Of(() => Boolean, { optional: true })
  withInvoices?: boolean;

  @Of(() => String, { optional: true })
  status?: StatementStatus;

  static of = Entity.of<StatementDataInput>();
}
