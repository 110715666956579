import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ActionMeta,
  AsyncCreatableSelect as _AsyncCreatableSelect,
  AsyncSelect as _AsyncSelect,
  chakraComponents,
  CreatableSelect as _CreatableSelect,
  MenuListProps,
  OptionProps,
  OptionsOrGroups,
  PlaceholderProps,
  Props,
  Select as _Select,
  SingleValueProps,
} from 'chakra-react-select';

import {
  ChakraStyles,
  ClearIndicator,
  CREATE_OPTION,
  CustomMultiValue,
  DropdownIndicator,
  MenuList,
  MultiBadgesOption,
  Option,
  Placeholder,
  SingleValue,
} from './ReactSelect.layout';

type Mixed = React.ComponentPropsWithRef<
  typeof _AsyncSelect | typeof _Select | typeof _AsyncCreatableSelect | typeof _CreatableSelect
>;

export const LOAD_DEFAULT_ASYNC_OPTIONS = 'LOAD_DEFAULT_ASYNC_OPTIONS';
export const LOAD_SELECTED_ASYNC_OPTION = 'LOAD_SELECTED_ASYNC_OPTION';
export const LOAD_MORE_ASYNC_OPTIONS = 'LOAD_MORE_ASYNC_OPTIONS';

export interface OptionsRequest {
  term: string;
  offset: number;
  pageSize: number;
}

export interface OptionsResponse {
  options: OptionsOrGroups<any, any>;
  moreOptionsCount?: number;
}

export interface SyncSelectProps {
  withObject?: boolean;
  withBadges?: boolean;
  hasCategories?: boolean;
  leftIcon?: React.ReactElement;

  loadDefault?: boolean;
  loadMoreOnScroll?: boolean;
  defaultOption?: Record<string, any>;
  defaultOptionsPayload?: Record<string, any>;

  syncPlaceholder?: string;
  syncPlaceholderIcon?: React.ReactElement;
  standaloneCreateLabel?: string;

  currentPage?: number;
  pageSize?: number;
  onLoadOptions?: (payload: OptionsRequest, callback: any) => void;
  renderSelectedValue?: (value: any) => React.ReactElement;
  renderOption?: (option: any) => React.ReactElement;
}

export const ReactSelect = <T extends Props>(Component: React.ComponentType<Mixed>) => {
  const ComponentWithStyle = React.forwardRef<any, T & SyncSelectProps>((props, _ref) => {
    const { t } = useTranslation('shared');

    const selectRef = useRef<any>(null);

    const {
      value,
      onChange,
      size = 'md',
      leftIcon,
      chakraStyles,
      withBadges,
      withObject,
      hasCategories,
      isClearable = true,
      closeMenuOnScroll,
      hideSelectedOptions,
      menuPortalTarget,

      options,
      onLoadOptions,
      loadDefault = true,
      loadMoreOnScroll = true,
      defaultOption,
      defaultOptionsPayload,

      syncPlaceholder = t('general.select_ellipsis'),
      syncPlaceholderIcon,
      standaloneCreateLabel,

      pageSize = 10,
      renderOption,
      renderSelectedValue,

      ...rest
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [defaultOptions, setDefaultOptions] = useState<OptionsOrGroups<any, any>>([]);
    const [defaultValue, setDefaultValue] = useState<any>(null);
    const [inputValue, setInputValue] = useState<any>();
    const [moreOptionsCount, setMoreOptionsCount] = useState<number>(0);

    const defaultAsyncOptionsPayload = {
      term: LOAD_DEFAULT_ASYNC_OPTIONS,
      offset: 0,
      pageSize,
    };

    const selectedAsyncOptionPayload = {
      term: LOAD_SELECTED_ASYNC_OPTION,
      offset: 0,
      pageSize,
    };

    useEffect(() => {
      if (loadDefault && onLoadOptions && !value) {
        handleLoadOptions(defaultAsyncOptionsPayload, ({ options }: OptionsResponse) =>
          setDefaultOptions(options)
        );
      }
    }, [JSON.stringify(defaultOptionsPayload)]);

    useEffect(() => {
      // THIS LINE BREAKS THE TABLE FILTERS THAT USE ASYNCSELECTS.
      // IF YOU GO TO PAGE 2 AND REFRESH THE PAGE THE VALUE FROM THE INPUT IS CLEARED
      // BY THIS IF. PROBLEM IS, I CAN'T REMEMBER WHY IT WAS ADDED AND IT SEEMS TO WORK
      // FINE WITHOUT IT. SO WE'LL HAVE TO TEST, BUT LEAVING IT HERE FOR NOW.
      // if ((onLoadOptions || props.isMulti) && value !== 0 && !value) {
      // (selectRef as any)?.current?.clearValue();
      // }

      if (loadDefault && onLoadOptions && !value) {
        handleLoadOptions(defaultAsyncOptionsPayload, ({ options }: OptionsResponse) =>
          setDefaultOptions(options)
        );
      }

      if (
        value &&
        defaultOptions &&
        !defaultValue &&
        defaultOption &&
        value === defaultOption.value
      ) {
        const option = defaultOptions.find((o: any) => o.value === value);

        setDefaultValue(option);
      }
    }, [value]);

    useEffect(() => {
      if (value && onLoadOptions && loadDefault) {
        handleLoadOptions(selectedAsyncOptionPayload, ({ options }: OptionsResponse) => {
          setDefaultOptions(options);
          setDefaultValue(options[0]);
        });
      }
    }, []);

    const handleOnChange = (value: any, actionMeta: ActionMeta<unknown>) => {
      setDefaultValue(null);

      if (
        value === CREATE_OPTION ||
        value?.value === CREATE_OPTION ||
        (Array.isArray(value) && (value || []).find((v) => v.value === CREATE_OPTION))
      ) {
        return;
      }

      if (!withObject) {
        if (props.isMulti) {
          onChange?.(value?.map((v: any) => v.value) ?? value, actionMeta);
        } else {
          onChange?.(value?.value ?? value, actionMeta);
        }
      } else {
        onChange?.(value, actionMeta);
      }
    };

    const onInputChange = (val: any) => {
      setInputValue(val);

      if (val !== '' && !!defaultValue) {
        setDefaultValue(null);
      }
    };

    const onMenuScrollToBottom = () => {
      if (!inputValue && hasMore && onLoadOptions && loadMoreOnScroll) {
        setIsLoading(true);

        handleLoadOptions?.(
          {
            term: LOAD_MORE_ASYNC_OPTIONS,
            offset: currentPage,
            pageSize,
          },
          ({ options }: OptionsResponse) => {
            setDefaultOptions([...(defaultOptions || []), ...options]);
            setIsLoading(false);
            setCurrentPage(currentPage + 1);

            if (options.length < pageSize) {
              setHasMore(false);
            }
          }
        );
      }
    };

    const handleLoadOptions = (payload: OptionsRequest, callback: any) => {
      const { term, offset = 0 } = payload;

      let realTerm = term;

      // This is needed because when typing, the library calls the loadOptions
      // internally, and I can't override this, so this junk has to be here.
      if (typeof term === 'undefined') {
        realTerm = payload as any as string;
      }

      if (realTerm === '') {
        return;
      }

      setIsLoading(true);

      if (realTerm.length >= 3 || realTerm === LOAD_DEFAULT_ASYNC_OPTIONS) {
        const computedTerm = realTerm === LOAD_DEFAULT_ASYNC_OPTIONS ? '' : realTerm;

        const isUserTerm =
          ![LOAD_DEFAULT_ASYNC_OPTIONS, LOAD_SELECTED_ASYNC_OPTION].includes(realTerm) &&
          !realTerm.startsWith(LOAD_MORE_ASYNC_OPTIONS);

        onLoadOptions?.({ term: computedTerm, offset, pageSize }, (response: OptionsResponse) => {
          setIsLoading(false);
          setMoreOptionsCount(response.moreOptionsCount || 0);
          callback(isUserTerm ? response.options : response);
        });
      }
    };

    const val = (() => {
      if (!withObject) {
        if (props.isMulti && Array.isArray(value)) {
          if (hasCategories) {
            const opts = props.options?.flatMap((o: any) => o.options);
            return (value as any[])?.map((v: any) => opts?.find((o: any) => o.value === v));
          }

          return (value as any[])?.map((v: any) => props.options?.find((o: any) => o.value === v));
        }
        return props.options?.find((o: any) => o.value === value);
      }

      return value;
    })();

    const components: Record<string, any> = {
      DropdownIndicator,
      ClearIndicator,
      MenuList: inputValue
        ? (props: MenuListProps) => {
            return (
              <MenuList {...props} isLoading={isLoading} moreOptionsCount={moreOptionsCount} />
            );
          }
        : chakraComponents.MenuList,
      Option:
        props.isMulti && withBadges
          ? (props) => (
              <MultiBadgesOption
                {...props}
                onCreateOption={(rest as any).onCreateOption}
                renderOption={renderOption}
              />
            )
          : (props: OptionProps) => <Option {...props} renderOption={renderOption} />,
      SingleValue: (props: SingleValueProps) => (
        <SingleValue {...props} renderSelectedValue={renderSelectedValue} leftIcon={leftIcon} />
      ),
      Placeholder: (props: PlaceholderProps) => (
        <Placeholder
          {...props}
          placeholder={syncPlaceholder as any}
          placeholderIcon={syncPlaceholderIcon}
        />
      ),
    };

    if (!withBadges) {
      components['MultiValue'] = CustomMultiValue;
    }

    const portalProps = menuPortalTarget
      ? {
          menuPortalTarget,
          styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },
        }
      : {};

    return (
      <>
        <Component
          components={components}
          key={String((val as any)?.value)}
          ref={selectRef}
          value={defaultValue || val}
          onChange={handleOnChange}
          onInputChange={onInputChange}
          onMenuScrollToBottom={onMenuScrollToBottom}
          size={size}
          isClearable={isClearable}
          closeMenuOnSelect={props.isMulti ? false : props.closeMenuOnSelect}
          hideSelectedOptions={withBadges ? true : false}
          menuPlacement="auto"
          className="sync-select-container"
          classNamePrefix="chakra"
          createOptionPosition="first"
          placeholder={t('general.select_ellipsis')}
          loadingMessage={() => t('general.loading')}
          noOptionsMessage={() =>
            inputValue ? t('general.no_options') : t('general.type_to_search')
          }
          chakraStyles={ChakraStyles({ size, chakraStyles })}
          options={
            standaloneCreateLabel
              ? [{ value: CREATE_OPTION, label: standaloneCreateLabel }, ...(options || [])]
              : options
          }
          defaultOptions={defaultOptions}
          {...portalProps}
          {...rest}
          isLoading={isLoading}
          loadOptions={handleLoadOptions}
        />

        <input
          hidden
          data-testid="duplicate-dropdown-value"
          value={(val as any)?.value ?? ''}
          onChange={() => {}}
        />
      </>
    );
  });

  return ComponentWithStyle;
};
