import { useQuery, UseQueryOptions } from 'react-query';

import { GetFileInput, UploadedFile } from '@api/core/entities';
import { httpClient } from '@lib/http';
import { AxiosError } from 'axios';

type QueryInput = GetFileInput;

const getFile = ({ queryKey }: UseQueryOptions<QueryInput>) => {
  const [_, queryPayload] = queryKey ?? [];
  const { id, tenantId } = queryPayload as QueryInput;

  const url = `/fisiere/detalii?fsID=${id}`;

  const headers:
    | {
        'X-Tenant-ID': string;
      }
    | {} = tenantId ? { 'X-Tenant-ID': tenantId } : {};

  return httpClient<UploadedFile, QueryInput>(url, {
    responseMapper: UploadedFile.of,
    headers,
  }).get();
};

export const useFileQuery = (
  queryInput: Partial<QueryInput>,
  options?: UseQueryOptions<UploadedFile, AxiosError>
) => {
  return useQuery<UploadedFile, AxiosError>(
    ['files', GetFileInput.of(queryInput)],
    getFile,
    options
  );
};
