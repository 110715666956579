import { VatCategory, VatExemptionCode } from '@modules/shared/utils/eFactura';
import { Entity, Of } from 'entity-of';

@Entity
export class VatRate {
  @Of(() => Number)
  id: string = '';

  @Of(() => String)
  tenantId: string = '';

  @Of(() => Number)
  tenantBillingProfileId: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  value: number = 0;

  @Of(() => VatCategory)
  category?: VatCategory;

  @Of(() => VatExemptionCode)
  exemptionCode?: VatExemptionCode;

  @Of(() => String)
  exemptionReason?: string;

  static of = Entity.of<VatRate>();
}
