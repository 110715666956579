import { Entity, Of } from 'entity-of';

@Entity
export class BillingProfileByIdInput {
  @Of(() => String)
  accountId = '';

  @Of(() => String)
  profileId = '';

  @Of(() => String, { optional: true })
  rootOrgId: string = '';

  static of = Entity.of<BillingProfileByIdInput>();
}
