import React from 'react';

import { Box, ChakraProvider } from '@chakra-ui/react';

import { theme } from './theme';

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ChakraProvider theme={theme}>
      <Box className="chakra-theme-provider-wrapper">{children}</Box>
    </ChakraProvider>
  );
};
