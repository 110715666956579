import { Entity, Of } from 'entity-of';

import { Details } from './Details';

@Entity
export class PlatformStatus {
  @Of(() => Details)
  disruption: Details = Details.of({});

  @Of(() => Details)
  maintenance: Details = Details.of({});

  @Of(() => String)
  cache_reset_date: string = '';

  static of = Entity.of<PlatformStatus>();
}
