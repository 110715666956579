import { Entity, Of } from 'entity-of';

@Entity
export class PackageStats {
  @Of(() => Number)
  total: number = 0;

  @Of(() => Number)
  done: number = 0;

  static of = Entity.of<PackageStats>();
}

@Entity
class Package {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  name: string = '';

  static of = Entity.of<Package>();
}

@Entity
export class PackageData {
  @Of(() => String)
  organizationId: string = '';

  @Of(() => String)
  userId: string = '';

  @Of(() => String)
  ErrorCodes: string = '';

  static of = Entity.of<PackageData>();
}

@Entity
export class PackageStatus {
  @Of(() => String)
  id: string = '';

  @Of(() => PackageStats)
  stats: PackageStats = PackageStats.of({});

  @Of(() => Package)
  package: Package = Package.of({});

  @Of(() => String)
  createdAt: string = '';

  @Of(() => String)
  status: string = '';

  @Of(() => [PackageData])
  data: PackageData[] = [];

  static of = Entity.of<PackageStatus>();
}

@Entity
export class PackageDataStatus {
  @Of(() => [PackageStatus])
  data: PackageStatus[] = [PackageStatus.of({})];

  static of = Entity.of<PackageDataStatus>();
}
