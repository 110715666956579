import { Institution, Role, School } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class Account {
  @Of(() => [Role])
  roles: Role[] = [];

  @Of(() => String)
  uaFirstName: string = '';

  @Of(() => String)
  uaID: string = '';

  @Of(() => String)
  uaLastLogin: string = '';

  @Of(() => String)
  uaLastName: string = '';

  @Of(() => String)
  uaLiceuID: string = '';

  @Of(() => String)
  uaPrefAvatar: string = '';

  @Of(() => String)
  prefAvatar: string = '';

  @Of(() => String)
  uaTip: string = '';

  @Of(() => String)
  elevNume: string = '';

  @Of(() => String)
  elevPrenume: string = '';

  @Of(() => String)
  elevAvatar: string = '';

  @Of(() => School)
  liceu: School = School.of({});

  @Of(() => Institution)
  inst: Institution = Institution.of({});

  static of = Entity.of<Account>();
}
