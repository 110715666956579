import { Entity, Of } from 'entity-of';

@Entity
export class UpdateBillingProfileInput {
  @Of(() => String)
  billingProfileId: string = '';

  @Of(() => String)
  subscriberId: string = '';

  @Of(() => Boolean)
  isOrganisation?: boolean = false;

  @Of(() => String, { optional: true })
  rootOrgId?: string;

  @Of(() => String, { optional: true })
  xAccountId?: string;

  static of = Entity.of<UpdateBillingProfileInput>();
}
