import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class PersonsSearchInput extends SimpleQueryInput {
  @Of(() => String, { nullable: true })
  searchTerm: string | null = null;

  @Of(() => [String], { nullable: true })
  groupIds: string[] | null = null;

  @Of(() => [String], { nullable: true })
  accountIds: string[] | null = null;

  @Of(() => String, { optional: true })
  classId?: string;

  @Of(() => String, { optional: true })
  rootOrgId?: string = '';

  @Of(() => Boolean, { optional: true })
  cuAbsolventi?: Boolean;

  @Of(() => String, { optional: true })
  ascID?: string = '';

  @Of(() => Boolean, { optional: true })
  searchInStudents?: Boolean;

  @Of(() => Boolean, { optional: true })
  cuClasa?: Boolean;

  @Of(() => Boolean)
  includeInvisibleClasses: boolean = true;

  static of = Entity.of<PersonsSearchInput>();
}
