import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';
import { t } from 'i18next';

@Entity
export class AssociatedGroup {
  @Of(() => Number)
  id?: number = 0;

  @Of(() => String)
  name?: string = '';

  @Of(() => Number)
  personId?: number = 0;

  @Of(() => Number)
  studyId?: number = 0;

  @Of(() => Number)
  facultyId?: number = 0;

  @Of(() => Number)
  academicYearId?: number = 0;

  @Of(() => String)
  type?: string = '';

  @Of(() => String)
  registrationNumber?: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: t('errors.required') })
  uniqueRegistrationNumber: string = '';

  @Of(() => String)
  studyStatus?: string = '';

  @Of(() => String)
  groupName?: string = '';

  @Of(() => String)
  facultyName?: string = '';

  @Of(() => String)
  cycleName?: string = '';

  @Of(() => String)
  domainName?: string = '';

  @Of(() => String)
  programName?: string = '';

  @Of(() => String)
  studyYearName?: string = '';

  @Of(() => String)
  seriesName?: string = '';

  @Of(() => String)
  displayStudyStatus?: string = '';

  @Of(() => String)
  tuitionTypeSem1: string = '';

  @Of(() => String)
  tuitionTypeSem2: string = '';

  static of = Entity.of<AssociatedGroup>();
}
