import { Entity, Of } from 'entity-of';

@Entity
export class PaginatedStudentsSearchInput {
  @Of(() => Number, { optional: true })
  size?: number = 10;

  @Of(() => Number, { optional: true })
  page?: number = 1;

  @Of(() => Boolean, { nullable: true })
  allStudies: boolean | null = null;

  @Of(() => String, { optional: true })
  sortField?: string = '';

  @Of(() => String, { optional: true })
  direction?: string = '';

  @Of(() => String, { optional: true })
  searchTerm?: string = '';

  @Of(() => String, { optional: true })
  email: string = '';

  @Of(() => Number, { optional: true })
  facultyId?: number;

  static of = Entity.of<PaginatedStudentsSearchInput>();
}
