import { CompanyAccountTypes, SchoolAccountTypes, UniversityAccountTypes } from './AccountTypes';

export enum OrganizationTypes {
  'SCHOOL' = 'SCHOOL',
  'UNIVERSITY' = 'UNIVERSITY',
  'FACULTY' = 'FACULTY',
  'COMPANY' = 'COMPANY',
}

export enum OrganizationTypesIds {
  'SCHOOL' = '1',
  'UNIVERSITY' = '2',
  'FACULTY' = '3',
  'COMPANY' = '4',
}

export const organizationTypes = [
  OrganizationTypes.SCHOOL,
  OrganizationTypes.UNIVERSITY,
  OrganizationTypes.COMPANY,
];

interface OrganizationDetails {
  accountTypes: any;
}

export const organizations: Record<OrganizationTypes, OrganizationDetails> = {
  SCHOOL: {
    accountTypes: SchoolAccountTypes,
  },
  UNIVERSITY: {
    accountTypes: UniversityAccountTypes,
  },
  COMPANY: {
    accountTypes: CompanyAccountTypes,
  },
  FACULTY: {
    accountTypes: [],
  },
};
