import { useQuery, UseQueryOptions } from 'react-query';

import { httpClient } from '@lib/http';

import { PersonIdInput, UniversityPersonal } from '../entities';
type QueryInput = UniversityPersonal;

const getPersonFormQuery = ({ queryKey }: UseQueryOptions<QueryInput>) => {
  const [_, queryPayload] = queryKey ?? [];
  const { userId, academicYearId } = queryPayload as PersonIdInput;

  let url = `/uni/persons/user-id/${userId}`;

  if (academicYearId) {
    url += `?academicYearId=${academicYearId}`;
  }

  return httpClient<UniversityPersonal>(url).get();
};

export const usePersonFormQuery = (
  queryInput: Partial<QueryInput>,
  options?: UseQueryOptions<UniversityPersonal>
) => {
  return useQuery<UniversityPersonal>(['person', queryInput], getPersonFormQuery, options);
};
