import { useTranslation } from 'react-i18next';

import { Vectors } from '@ui2/assets';
import { Image, Text, VStack } from '@ui2/components';

export const Page404 = () => {
  const { t } = useTranslation('Site');

  return (
    <VStack w="full" h="full" justifyContent="center" alignItems="center">
      <Image src={Vectors.PageNotFound} mb="2rem" />

      <Text fontSize="2xl" color="gray.700" fontWeight="700" textAlign="center">
        {t('page-404.title')}
      </Text>

      <Text fontSize="lg" color="gray.400" fontWeight="normal" textAlign="center">
        {t('page-404.subtitle')}
      </Text>
    </VStack>
  );
};
