import { ComponentStyleConfig } from '@chakra-ui/react';
import { cssVar } from '@chakra-ui/theme-tools';

const $arrowBg = cssVar('popper-arrow-bg');

export const TooltipTheme: ComponentStyleConfig = {
  baseStyle: {
    px: '3',
    py: '3',
    borderRadius: 'lg',
    color: 'gray.500',
    bg: 'white',
    [$arrowBg.variable]: 'white',
    boxShadow: 'xl',
  },
};
