import { ArrayNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class SelectRoomsFormInput {
  @Of(() => String)
  @ArrayNotEmpty({ message: 'errors.required' })
  rooms: string[] = [];

  static of = Entity.of<SelectRoomsFormInput>();
}
