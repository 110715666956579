import { Entity, Of } from 'entity-of';

@Entity
export class Elev {
  @Of(() => String)
  uaID: string = '';

  static of = Entity.of<Elev>();
}

@Entity
export class SchoolTransfers {
  @Of(() => String)
  tcTransferNumber = '';

  @Of(() => String)
  etTransferTip = '';

  @Of(() => String)
  etLiceuNume = '';

  @Of(() => String)
  etDataTransfer = '';

  @Of(() => String)
  etNrTransfer = '';

  @Of(() => Elev)
  elev: Elev = { uaID: '' };

  static of = Entity.of<SchoolTransfers>();
}
