// @ts-nocheck
/*
|--------------------------------------------------------------------------
| Interconnect URL's
|--------------------------------------------------------------------------
|
*/
export const API_URL =
  window.location.port === '90001'
    ? `${window.location.protocol}//${window.location.hostname}/api/v2`
    : '/api/v2';
export const WS_URL =
  window.location.port === '90001'
    ? `${window.location.protocol}//${window.location.hostname}/`
    : '/';

/*
|--------------------------------------------------------------------------
| General
|--------------------------------------------------------------------------
|
*/

export const ISO_DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export type ISO_DAYS_TYPE = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';
export const CURRENCIES = ['RON', 'EUR', 'USD', 'GBP'];

/*
|--------------------------------------------------------------------------
| Grade systems
|--------------------------------------------------------------------------
|
*/
export const SN_NOTE = '0';
export const SN_CALIFICATIVE = '1';
export const SN_INSIGNE = '2';
export const SN_CAMBRIDGE = '3';
export const SN_PERCENTAGE = '4';

/*
|--------------------------------------------------------------------------
| Tipuri user
|--------------------------------------------------------------------------
|
*/

export const TYPE_ELEV = '1';
export const TYPE_PARINTE = '2';
export const TYPE_CADRU = '3';
export const TYPE_ADMIN = '4';
export const TYPE_PERS_INST = '5';
export const TYPE_CANDIDATE = '7';

export type UserTypes =
  | typeof TYPE_ELEV
  | typeof TYPE_PARINTE
  | typeof TYPE_CADRU
  | typeof TYPE_ADMIN
  | typeof TYPE_PERS_INST
  | typeof TYPE_CANDIDATE;

/*
|--------------------------------------------------------------------------
| Permisiuni/functii cadre
|--------------------------------------------------------------------------
|
*/

export const PERM_CONFIGURATION = '2';
export const PERM_CAD_INVATATOR = '13';
export const PERM_CAD_PROFESOR = '14';
export const PERM_CAD_SECRETAR = '15';
export const PERM_CAD_DIRECTOR = '16';
export const PERM_STUDENT = '42';
export const PERM_STUDENT_EXTERN = '90';
export const PERM_CAD_REP_ADSERVIO = '17';
export const PERM_MANAGER_ERASMUS = '62';
export const PERM_CAD_AUX = '18';
export const PERM_CAD_SYNC = '19';
export const PERM_INSTITUTION_PERS = '24';
export const PERM_CAD_EDUCATOR = '26';
export const PERM_MESAJE_GRUP = '12';

export const PERM_BILLING_MANAGER = '46';
export const PERM_BILLING_CLIENT = '47';
export const PERM_BILLING_ASSIGNER = '53';

export const PERM_SUBSCRIPTION_ADMIN = '54';
export const PERM_SUBSCRIPTION_MANAGER = '55';
export const PERM_SUBSCRIPTION_CLIENT = '56';
export const PERM_SUBSCRIPTION_STATISTICS = '104';

export const PERM_VIEW_CLASSBOOK = '48';
export const PERM_VIEW_CLASSBOOK_HEADTEACHER = '49';
export const PERM_EDIT_CLASSBOOK = '50';

export const PERM_LIBRARY_ADMIN = '39';
export const PERM_LIBRARY_VIEW = '51';
export const PERM_LIBRARY_MANAGER = '52';

export const PERM_ADMISSION_MANAGER = '57';
export const PERM_ADMISSION_OFFICER = '58';
export const PERM_ADMISSION_CANDIDATE = '59';

export const PERM_TIMETABLE_CONFIG = '60';
export const PERM_TIMETABLE_VIEW = '61';

export const PERM_ATT_REGISTER_MANAGER = '63';
export const PERM_ATT_REGISTER_SIGNATORY = '64';

export const PERM_ACCOMODATION_BUILDING_MANAGER = '83';
export const PERM_ACCOMODATION_CLIENT = '84';
export const PERM_ACCOMODATION_REPORTS = '85';
export const PERM_ACCOMODATION_CHECKIN_MANAGER = '86';
export const PERM_ACCOMODATION_ASSIGNMENT_MANAGER = '87';
export const PERM_ACCOMODATION_FINANCIAL_MANAGER = '88';
export const PERM_ACCOMODATION_ALL_FACULTIES = '89';
export const PERM_ACCOMODATION_ALL_DORMS_CHECKIN_MANAGER = '109';

export const PERM_MANAGER_STRUCTURA_UNI = '93';

export const PERM_SCHOLARSHIP_CLIENT = '94';
export const PERM_SCHOLARSHIP_REPORTS = '95';
export const PERM_SCHOLARSHIP_BUDGET_MANAGER = '96';
export const PERM_SCHOLARSHIP_ASSIGN_MANAGER = '97';
export const PERM_SCHOLARSHIP_FACULTIES_MANAGER = '98';

export const PERM_QUESTIONNAIRE_VIEW = '99';
export const PERM_QUESTIONNAIRE_CREATE_SURVEY = '100';
export const PERM_QUESTIONNAIRE_CREATE_FEEDBACK = '101';
export const PERM_QUESTIONNAIRE_CREATE_TEST = '102';

export const PERM_TENANT_MANAGER = '105';

/*
|--------------------------------------------------------------------------
| SSO
|--------------------------------------------------------------------------
|
*/

export enum OauthProviders {
  GOOGLE = 'google',
  TUIASI = 'tuiasi',
}

/*
|--------------------------------------------------------------------------
| Abonamente
|--------------------------------------------------------------------------
|
*/

export const ABO_STANDARD = '1';
export const ABO_PREMIUM = '2';

/*
|--------------------------------------------------------------------------
| Tipuri de nota
|--------------------------------------------------------------------------
|
*/

export const NT_ORAL = '1';
export const NT_TEZA = '2';
export const NT_CORIGENTA = '3';
export const NT_TI = '4';
export const NT_SPECIALITATE = ' 5';
export const NT_TF = '6';
export const NT_TEST = '7';
export const NT_SIMULARE = '8';
export const NT_PROIECT = '9'; // nota normala ca oral/test

/*
|--------------------------------------------------------------------------
| Tipuri fisiere
|--------------------------------------------------------------------------
|
*/

export const FSTIP_DIR = '1';
export const FSTIP_FILE = '2';
export const FSTIP_ATASAMENT = '3';
export const FSTIP_PROIECT = '4';
export const FSTIP_GROUP = '5';

/*
|--------------------------------------------------------------------------
| CONDUITA
|--------------------------------------------------------------------------
*/

export const CONDUCT_REWARD = '1'; // recompensa
export const CONDUCT_DEVIATION = '2'; // abatere
export const CONDUCT_REMARK = '3'; // neutru

///////////////////////////////////////

export const ORG_SCHOOL = '1'; // school
export const ORG_UNIVERSITY = '2'; // university
export const ORG_COMPANY = '4'; // company
export const ORG_ADSERVIO = '5'; // Compania Adservio

///////////////////////////////////////

export enum SERVICE_STATUS {
  'active' = 'active',
  'inactive' = 'inactive',
}

///////////////////////////////////////

export enum CLASS_TYPES {
  ZI = '1',
  SERAL = '2',
  PREOFESIONAL = '3',
  FRECVENTA_REDUSA = '4',
  POSTLICEAL = '5',
}

////////////////////////////////////////

/*
|--------------------------------------------------------------------------
| Session Config Flags
|--------------------------------------------------------------------------
*/
export enum CONFIG_FLAGS {
  ACCOMMODATION_ACTIVE = 'ACCOMMODATION_ACTIVE',
  SCHOOL_STAFF_ACTIVITIES_ACTIVE = 'SCHOOL_STAFF_ACTIVITIES_ACTIVE',
  SCHOOL_STAFF_ACTIVITIES_DAYS_EDIT = 'SCHOOL_STAFF_ACTIVITIES_DAYS_EDIT',
  ADMISSION_ACTIVE = 'ADMISSION_ACTIVE',
  SHOW_ADMISSION_OFFERS_TO_STUDENTS = 'SHOW_ADMISSION_OFFERS_TO_STUDENTS',
  LIBRARY_ACTIVE = 'LIBRARY_ACTIVE',
  BILLING_ACTIVE = 'BILLING_ACTIVE',
  CALENDAR_ACTIVE = 'CALENDAR_ACTIVE',
  QUESTIONNAIRES_ACTIVE = 'QUESTIONNAIRES_ACTIVE',
  QUESTIONNAIRES_SURVEY_ACTIVE = 'QUESTIONNAIRES_SURVEY_ACTIVE',
  QUESTIONNAIRES_FEEDBACK_ACTIVE = 'QUESTIONNAIRES_FEEDBACK_ACTIVE',
  QUESTIONNAIRES_TEST_ACTIVE = 'QUESTIONNAIRES_TEST_ACTIVE',
  QUESTIONNAIRES_FILTER_SUBJECTS_ACTIVE = 'QUESTIONNAIRES_FILTER_SUBJECTS_ACTIVE',
  BEHAVIOUR_ACTIVE = 'BEHAVIOUR_ACTIVE',
  DECISION_REGISTER_ACTIVE = 'DECISION_REGISTER_ACTIVE',
  EDUMARKET_ACTIVE = 'EDUMARKET_ACTIVE',
  FILES_ACTIVE = 'FILES_ACTIVE',
  PHOTOS_ACTIVE = 'PHOTOS_ACTIVE',
  ANUAR_ACTIVE = 'ANUAR_ACTIVE',
  GROUPS_ACTIVE = 'GROUPS_ACTIVE',
  SCHOOL_STAFF_HISTORY_ACTIVE = 'SCHOOL_STAFF_HISTORY_ACTIVE',
  NEURON_ENGLISH_ACTIVE = 'NEURON_ENGLISH_ACTIVE',
  REMARKS_ACTIVE = 'REMARKS_ACTIVE',
  TIMETABLE_ACTIVE = 'TIMETABLE_ACTIVE',
  TIMETABLE_HEADTEACHER_CAN_EDIT = 'TIMETABLE_HEADTEACHER_CAN_EDIT',
  PEOPLE_MANAGEMENT_ACTIVE = 'PEOPLE_MANAGEMENT_ACTIVE',
  GRADES_PROGRESS_ACTIVE = 'GRADES_PROGRESS_ACTIVE',
  STUDENT_REPORTS_ACTIVE = 'STUDENT_REPORTS_ACTIVE', // used only for Little London school
  NEWS_ACTIVE = 'NEWS_ACTIVE',
  NEWS_SECRETARY_ACTIVE = 'NEWS_SECRETARY_ACTIVE',
  TAXES_ACTIVE = 'TAXES_ACTIVE',
  HOMEWORK_ACTIVE = 'HOMEWORK_ACTIVE',
  TESTS_ACTIVE = 'TESTS_ACTIVE',
  TESTS_IQ_ACTIVE = 'TESTS_IQ_ACTIVE',
  TESTS_SELF_ACTIVE = 'TESTS_SELF_ACTIVE',
  VIDEO_GOOGLE_ACTIVE = 'VIDEO_GOOGLE_ACTIVE',
  VIDEO_WEBEX_ACTIVE = 'VIDEO_WEBEX_ACTIVE',
  VIDEO_ZOOM_ACTIVE = 'VIDEO_ZOOM_ACTIVE',
  STUDENT_PROGRESS_REPORT_ACTIVE = 'STUDENT_PROGRESS_REPORT_ACTIVE',
  SUBSCRIPTIONS_ACTIVE = 'SUBSCRIPTIONS_ACTIVE',
  OBSERVATIONS_ACTIVE = 'OBSERVATIONS_ACTIVE',
  ATTENDANCE_REGISTER = 'ATTENDANCE_REGISTER',
  STUDENT_PORTFOLIO = 'STUDENT_PORTFOLIO',
  PROGRESS_GRADES = 'PROGRESS_GRADES',
  CONDUITA = 'CONDUITA',
}

/*
|--------------------------------------------------------------------------
| Package Feature Flags
|--------------------------------------------------------------------------
*/
export enum FEATURE_FLAGS {
  FLAG_STATS_STUDENT_PROGRESS = 'FLAG_STATS_STUDENT_PROGRESS',
  FLAG_CAT_GRADE_PROBABILITY = 'FLAG_CAT_GRADE_PROBABILITY',
  FLAG_MODULE_BEHAVIOUR = 'FLAG_MODULE_BEHAVIOUR',
  FLAG_MODULE_OBSERVATIONS = 'FLAG_MODULE_OBSERVATIONS',
  FLAG_CAL_MEMENTO = 'FLAG_CAL_MEMENTO',
  FLAG_MODULE_IQ_TEST = 'FLAG_MODULE_IQ_TEST',
  FLAG_MODULE_SELFKNOWLEDGE_TEST = 'FLAG_MODULE_SELFKNOWLEDGE_TEST',
  FLAG_SKT_UNIVERSITIES_RECOMMENDATIONS = 'FLAG_SKT_UNIVERSITIES_RECOMMENDATIONS',
  FLAG_GROUPS_ADD = 'FLAG_GROUPS_ADD',
  FLAG_MODULE_FOTO = 'FLAG_MODULE_FOTO',
  FLAG_FOTO_UPLOAD = 'FLAG_FOTO_UPLOAD',
  FLAG_FOTO_SHARE = 'FLAG_FOTO_SHARE',
  FLAG_MODULE_YEARBOOK = 'FLAG_MODULE_YEARBOOK',
  FLAG_MODULE_MESSAGES = 'FLAG_MODULE_MESSAGES',
  FLAG_MSG_GROUP = 'FLAG_MSG_GROUP',
  FLAG_MSG_OPENED_REPORT = 'FLAG_MSG_OPENED_REPORT',
  FLAG_PROMOTE_PACKAGE_UPGRADE = 'FLAG_PROMOTE_PACKAGE_UPGRADE',
  FLAG_PROMOTE_FEATURE_UPGRADE = 'FLAG_PROMOTE_FEATURE_UPGRADE',
  FLAG_TIMETABLES = 'FLAG_TIMETABLES',
  FLAG_TIMETABLES_GENEREATOR = 'FLAG_TIMETABLES_GENEREATOR',
  FLAG_LIB_WELLBEING = 'FLAG_LIB_WELLBEING',
  FLAG_HW_FEEDBACK = 'FLAG_HW_FEEDBACK',
  FLAG_GROUPS_CHAT_FILES = 'FLAG_GROUPS_CHAT_FILES',
  FLAG_STUDENT_AI_SUMMARY = 'FLAG_STUDENT_AI_SUMMARY',
  FLAG_WELLBEING_AI_SUMMARY = 'FLAG_WELLBEING_AI_SUMMARY',
  FLAG_WELLBEING_MOODS = 'FLAG_WELLBEING_MOODS',
  FLAG_SCHOOL_AI_SUMMARY = 'FLAG_SCHOOL_AI_SUMMARY',
  FLAG_MODULE_CHESTIONAIRES = 'FLAG_MODULE_CHESTIONAIRES',
  FLAG_MODULE_REPORT_FORMS = 'FLAG_MODULE_REPORT_FORMS',
  FLAG_ATTENDANCE_REGISTER = 'FLAG_ATTENDANCE_REGISTER',
  FLAG_STUDENT_PORTFOLIO = 'FLAG_STUDENT_PORTFOLIO',
}

export enum FEATURE_MODULES {
  FLAG_STATS_STUDENT_PROGRESS = 'progress',
  FLAG_STUDENT_AI_SUMMARY = 'progress',
  FLAG_MODULE_OBSERVATIONS = 'observations',
  FLAG_MODULE_BEHAVIOUR = 'behaviour',
  FLAG_MODULE_FOTO = 'photos',
  FLAG_MODULE_YEARBOOK = 'yearbook',
  FLAG_MODULE_MESSAGES = 'messages',
  FLAG_LIB_WELLBEING = 'library_wellbeing',
  FLAG_GROUPS_ADD = 'groups',
  FLAG_CAL_MEMENTO = 'calendar',
  FLAG_CAT_GRADE_PROBABILITY = 'report_card',
  FLAG_WELLBEING_MOODS = 'wellbeing',
}

export enum MODULE_FEATURE {
  progress = 'FLAG_STATS_STUDENT_PROGRESS',
  observations = 'FLAG_MODULE_OBSERVATIONS',
  behaviour = 'FLAG_MODULE_BEHAVIOUR',
  photos = 'FLAG_MODULE_FOTO',
  yearbook = 'FLAG_MODULE_YEARBOOK',
  messages = 'FLAG_MODULE_MESSAGES',
  library_wellbeing = 'FLAG_LIB_WELLBEING',
  report_card = 'FLAG_CAT_GRADE_PROBABILITY',
  wellbeing = 'FLAG_WELLBEING_MOODS',
}

export enum MODULE_BASE_ROUTES {
  NO_USER_SUBSCRIPTION = 'no-user-subscription',
  NO_SUBSCRIPTION = 'no-subscription',
  SECRETARY = 'secretary',
  DASHBOARD = 'dashboard',
  HOMEWORK = 'homework',
  EDUMARKET = 'edumarket',
  MESSAGES = 'messages',
  REPORT_CARD = 'report-card',
  FOTO = 'foto',
  ANUAR = 'anuar',
  PREFERENCES = 'preferences',
  REPORTS = 'reports',
  FILES = 'files',
  NEWS = 'news',
  ADMITERE = 'admitere',
  ADMISSION = 'admission',
  OFFERS = 'offers',
  GROUPS = 'groups',
  PROGRESS = 'progress',
  ROLES_AND_PERMISSIONS = 'roles-and-permissions',
  CALENDAR = 'calendar',
  MAIN_DOCUMENTS = 'main-documents',
  ZENDESK_SUPPORT = 'zendesk-support',
  BILLING = 'billing',
  ACCOMMODATION = 'accommodation',
  PEOPLE_MANAGEMENT = 'people-management',
  DECISION_REGISTER = 'decision-register',
  CONFIGURATION = 'configuration',
  LIBRARY = 'library',
  SUBSCRIPTIOS = 'subscriptions',
  HISTORY = 'history',
  CLASSES = 'classes',
  UPGRADE = 'upgrade',
  CHECKOUT = 'checkout',
}

/*
|--------------------------------------------------------------------------
| Billing
|--------------------------------------------------------------------------
*/
export const NETOPIA_URL = 'https://netopia-payments.com/';

export enum REPORTS_ROUTES {
  STUDENTS = 'students',
  GRADES = 'grades',
  ABSENCES = 'absences',
  AGE = 'students/age',
  ENVIRONMENT = 'students/environment',
  BIRTHPLACE = 'students/birthplace',
  NATIONALITY = 'students/nationality',
  ETHNICITY = 'students/ethnicity',
  FAMILY_SITUATION = 'students/family-situation',
  SCHOLARSHIPS = 'students/scholarships',
  FOREIGN_LANGUAGES_TYPES = 'students/foreign-languages-types',
  FOREIGN_LANGUAGES = 'students/foreign-languages',
  TOP_STUDENTS = 'students/top-students',
  LIVING_SITUATION = 'students/living-situation',
  GRADUATE = 'students/graduate',
}

/*
|--------------------------------------------------------------------------
| Wellbeing
|--------------------------------------------------------------------------
*/
export const WELLBEING_LIBRARY_CATEGORY_ID = 'ydmD31E8SdsI';
export const WELLBEING_LIBRARY_SECTION_ID = '5';

/*
|--------------------------------------------------------------------------
| Callouts
|--------------------------------------------------------------------------
*/
export const CALLOUT_CLASS_MANAGEMENT_CLOSE = 'CALLOUT_CLASS_MANAGEMENT_CLOSE';
export const CALLOUT_CLASS_MANAGEMENT_DEFAULT = 'CALLOUT_CLASS_MANAGEMENT_DEFAULT';
export const CALLOUT_CLASS_MANAGEMENT_CLICK_NEW_BUTTON =
  'CALLOUT_CLASS_MANAGEMENT_CLICK_NEW_BUTTON';
