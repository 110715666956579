import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { InputGroupProps } from '@ui2/components/atoms';

import { PasswordInput } from '../..';
import { FormElement, FormElementProps } from '../FormElement';

export interface FormPasswordInputProps extends FormElementProps<InputGroupProps> {
  withStrength?: boolean;
}

export const FormPasswordInput = forwardRef<HTMLInputElement, FormPasswordInputProps>(
  (
    {
      testId,
      name,
      label,
      isRequired,
      helperText,
      showHelperText,
      value,
      errorBehaviour,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation('shared');

    return (
      <FormElement
        {...{ name, label, isRequired, helperText, showHelperText, errorBehaviour, testId }}
      >
        <PasswordInput ref={ref} name={name} placeholder={t('general.type_password')} {...props} />
      </FormElement>
    );
  }
);
