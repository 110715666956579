import { IsNotEmpty, MaxLength, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class OverrideCreateInput {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.type.required' })
  categoryId: string = '';

  @Of(() => String)
  @ValidateIf(
    (o) =>
      ![
        'secretary',
        'classMaster',
        'mentions',
        'studentPersonalMentions',
        'registrationNumber',
      ].includes(o.categoryId)
  )
  @IsNotEmpty({ message: 'errors.type.required' })
  subCategoryId: string = '';

  @Of(() => String)
  @ValidateIf((o) =>
    ['studentsAbsence', 'studentsSituation', 'studentMentions', 'studentPersonalMentions'].includes(
      o.categoryId
    )
  )
  @IsNotEmpty({ message: 'errors.studentId.required' })
  studentId: string = '';

  @Of(() => String)
  classId: string = '';

  @Of(() => String)
  @ValidateIf(
    (o) =>
      o.categoryId === 'studentsSituation' &&
      o.subCategoryId !== 'yearAverage' &&
      o.subCategoryId !== 'situationFinalPeriod' &&
      o.subCategoryId !== 'situationFinalYear'
  )
  @IsNotEmpty({ message: 'errors.subjectId.required' })
  subjectId: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.subCategoryId === 'moduleAverage')
  @IsNotEmpty({ message: 'errors.periodId.required' })
  periodId: string = '';

  @Of(() => String)
  @MaxLength(255, { message: 'errors.max_255' })
  @IsNotEmpty({ message: 'errors.value.required' })
  value: string = '';

  @Of(() => String)
  type: string = '';

  static of = Entity.of<OverrideCreateInput>();
}
