import React from 'react';
import { TableHeaderProps } from 'react-table';

import { getOrgTypeTheme } from '@ui2/utils';

import { CKThead, Th, Tr } from '..';

interface TableHeadContainerProps {
  children: React.ReactElement[];
}

export const TableHeadContainer = React.forwardRef<
  HTMLTableSectionElement,
  TableHeadContainerProps
>(({ children }, ref) => {
  return (
    <CKThead
      ref={ref}
      sx={{
        px: '4',
        py: '3',
        bg: 'gray.50',
        color: 'gray.700',
        alignItems: 'center',
        '@media print': {
          '.chakra-table__container': {
            whiteSpace: 'unset !important',
          },
        },
      }}
    >
      {children}
    </CKThead>
  );
});

interface TableHeaderCellProps {
  isSorting?: boolean;
  isSmallest?: boolean;
  isGroups?: boolean;
  isOrphan?: boolean;
  isPlaceholder?: boolean;
  isGroupParent?: boolean;
  isGroupElement?: boolean;
  isLastInGroup?: boolean;
  isFirstInGroup?: boolean;
  isForcedDivider?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}

export const TableHeadCell = React.forwardRef<
  HTMLTableCellElement,
  React.PropsWithChildren<TableHeaderProps & TableHeaderCellProps>
>(
  (
    {
      children,
      isSorting,
      isSmallest,
      isGroups,
      isOrphan,
      isPlaceholder,
      isGroupParent,
      isGroupElement,
      isFirstInGroup,
      isLastInGroup,
      isFirst,
      isLast,
      isForcedDivider,
      ...props
    },
    ref
  ) => {
    const baseColor = getOrgTypeTheme('blue');

    const sx = {
      mr: '0.5',
      color: isSorting ? `${baseColor}.600` : 'gray.400',
      textTransform: 'none',
      letterSpacing: 'wider',
      fontWeight: 'bold',
      fontSize: 'xs',
      paddingLeft: '4',
      paddingRight: '4',
      borderColor: 'gray.200',
      borderLeft: isGroups && isForcedDivider && !isFirst ? '1px solid' : 'initial',
      borderLeftColor: 'gray.200',
    };

    const smallestSx = {
      whiteSpace: 'nowrap',
      width: '1%',
    };

    const orphanSx = {
      transform: 'translateY(-50%)',
    };

    const placeholderSx = {
      borderBottom: 'none',
    };

    const groupParentSx = {
      borderRight: isLast ? 'initial' : '1px solid',
      borderRightColor: 'gray.200',
      borderLeft: isFirstInGroup ? '1px solid' : 'initial',
      borderLeftColor: 'gray.200',
    };

    const groupElementSx = {
      borderRight: isLast ? 'initial' : '1px solid',
      borderRightColor: 'gray.200',
      borderLeft: isFirstInGroup ? '1px solid' : 'initial',
      borderLeftColor: 'gray.200',
    };

    const finalSx = {
      ...sx,
      ...(isSmallest && smallestSx),
      ...(isGroups && isOrphan && orphanSx),
      ...(isGroups && isPlaceholder && placeholderSx),
      ...(isGroups && isGroupParent && groupParentSx),
      ...(isGroups && isGroupElement && groupElementSx),
    };

    return (
      <Th ref={ref} sx={finalSx} {...props}>
        {children}
      </Th>
    );
  }
);

export const TableHeadRow = React.forwardRef<
  HTMLTableRowElement,
  TableHeaderProps & { children: React.ReactElement[] }
>(({ children, ...props }, ref) => (
  <Tr ref={ref} data-testid="table-head" alignItems="center" {...props}>
    {children}
  </Tr>
));
