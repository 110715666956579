import { Entity, Of } from 'entity-of';

@Entity
export class ScholarshipStudent {
  @Of(() => Number)
  idPersoana: number = 0;

  @Of(() => Number)
  idStudent: number = 0;

  @Of(() => String)
  fullName: string = '';

  @Of(() => String)
  nrMatricolUnic: string = '';

  @Of(() => String)
  cnp: string = '';

  @Of(() => String)
  numeGrupa: string = '';

  static of = Entity.of<ScholarshipStudent>();
}

@Entity
export class ScholarshipStudentsResponse {
  @Of(() => [ScholarshipStudent])
  result: ScholarshipStudent[] = [];

  static of = Entity.of<ScholarshipStudentsResponse>();
}
