import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

import { StatementData } from '../Statement';

@Entity
export class StatementData_Person extends StatementData {
  @Of(() => [Person])
  persons: Person[] = [];

  static of = Entity.of<StatementData_Person>();
}
