import { useMutation } from 'react-query';

import { FSInput, FSUploadResponse } from '@api/core/entities';
import { httpClient } from '@lib/http';
import { handleRequestErrors } from '@lib/utils';
import { useToast } from '@ui2/components';
import { AxiosError } from 'axios';

export const useFileUploadMutation = () => {
  const showToast = useToast();

  return useMutation<FSUploadResponse, AxiosError, FSInput>(
    (input) =>
      httpClient<FSUploadResponse, FSInput>(`/fisiere/upload`, {
        formData: true,
        payload: input,
        responseMapper: FSUploadResponse.of,
      }).post(),
    {
      onError: (error) => handleRequestErrors(error, showToast, { isLegacy: true }),
    }
  );
};
