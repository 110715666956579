import { Entity, Of } from 'entity-of';

export class Administrator {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  api_token: string | null = null;

  @Of(() => Date)
  created_at: Date | null = null;

  @Of(() => Date)
  deleted_at: Date | null = null;

  @Of(() => String)
  email: string = '';

  @Of(() => String)
  first_name: string = '';

  @Of(() => String)
  last_name: string = '';

  @Of(() => Number)
  is_administrator: number = 0;

  @Of(() => Number)
  is_student: number = 0;

  @Of(() => Number)
  person_id: number = 0;

  @Of(() => Number)
  tenant_id: number = 0;

  @Of(() => Date)
  updated_at: Date | null = null;

  @Of(() => Number)
  user_id: number = 0;

  @Of(() => String)
  username: string = '';

  static of = Entity.of<Administrator>();
}
