import { Transform, Type } from 'class-transformer';
import { ArrayNotEmpty, IsArray, IsNotEmpty, ValidateNested } from 'class-validator';
import { Entity, Of } from 'entity-of';

import 'reflect-metadata';

import { CreateSlotInput } from '../../Slot';

@Entity
export class CreateScheduleInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.name.required' })
  name: string = '';

  @Of(() => String)
  ascID: string = '';

  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.days.required' })
  days: string[] = [];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CreateSlotInput)
  @Of(() => [CreateSlotInput])
  @Transform(({ value }) => {
    const x = value.map((v: CreateSlotInput, i: number) => {
      const temp: CreateSlotInput[] = [...value];
      temp.splice(i, 1);
      v['rest'] = temp.map((t) => CreateSlotInput.of(t));
      return v;
    });

    return x;
  })
  slots: CreateSlotInput[] = [];

  static of = Entity.of<CreateScheduleInput>();
}
