import { useQuery, UseQueryOptions } from 'react-query';

import {
  LegacyPaginatedResponse,
  Person,
  SimpleQueryInput,
  Student,
  StudentSearchInput,
} from '@api/core/entities';
import Api from '@lib/api';
import { httpClient } from '@lib/http';
import { computeUrlParams } from '@lib/utils';
import { PersonsSearchInput } from '@modules/billing/entities';
import { AxiosError } from 'axios';

type QueryInput = PersonsSearchInput;

// This one is a special query used in billing that's why is not in the api module
// This endpoint is used in a lot of components and was originally designed with Billing PROXY.
// We have switched to 2 separate PHP endpoints because we dropped the proxy, so some shit-code is required
// Because there is no time to properly refactor all components that are using this endpoint.
interface PHPPayload extends SimpleQueryInput {
  q?: string;
  uaTip?: string;
  clID?: string;
  ascID?: string;
  cuAbsolventi?: string;
  searchInStudents?: boolean;
  cuClasa?: boolean;
  includeInvisibleClasses?: boolean;
}

const getUsers = async (ids: string[]): Promise<Person[]> => {
  if (ids.length === 0) return [];

  const response = await Api.get<LegacyPaginatedResponse<Person>>(
    `/utilizatori/infos?uaID=[${ids}]`,
    {
      withRoles: 1,
      _limit: -1,
    }
  );

  return response?.data ?? [];
};

const searchUsers = async (payload: PHPPayload): Promise<Person[]> => {
  const schoolYear = JSON.parse(String(localStorage.getItem('session')))?.anScolar?.ascID;

  const paramsEleviQuery = StudentSearchInput.of({
    ...payload,
    ascID: payload.ascID ?? schoolYear,
    q: payload.q,
    includeInvisibleClasses: true,
  });

  const paramsUtilizatoriQuery = {
    q: payload.q,
    ascID: payload.ascID ?? schoolYear,
    'uaTip[]': payload.uaTip,
  };

  const { searchInStudents } = payload;

  let url = '/utilizatori/search';

  if (searchInStudents) {
    url = `/elevi`;
  } else {
    if (!payload.q) {
      url = `/elevi`;
    }
  }

  const params = computeUrlParams(url === '/elevi' ? paramsEleviQuery : paramsUtilizatoriQuery);

  if (params) {
    url += `?${params}`;
  }

  const response = await httpClient<any, PHPPayload>(url).get();

  if (url.includes('/elevi')) {
    return (response as LegacyPaginatedResponse<Student>).data.map((a) => {
      return Person.of({
        uaID: a.elevID,
        uaFirstName: a.elevPrenume,
        uaLastName: a.elevNume,
        roles: a.roles,
        prefAvatar: a.elevAvatar,
        billableAccountId: a.elevID,
        parentId: payload.searchInStudents ? `cl-${a.clasa?.clID}` : `cl-${payload.clID}`,
        clasa: a.clasa,
        uaInitTata: a.elevInitTata,
        uaTip: payload.uaTip,
      });
    });
  } else {
    return (response as Person[]).map((a) => {
      return Person.of({
        ...a,
        billableAccountId: a.uaID,
        parentId: `cl-${a.clasa?.clID}`,
        uaTip: payload.uaTip,
      });
    });
  }
};

const searchPersons = async ({ queryKey }: UseQueryOptions<QueryInput>) => {
  const [_, queryPayload] = queryKey ?? [];
  const {
    searchTerm,
    groupIds,
    cuAbsolventi,
    ascID,
    searchInStudents,
    cuClasa,
    includeInvisibleClasses,
    _limit,
  } = queryPayload as QueryInput;

  const payload: PHPPayload = {
    uaTip: '1',
  };

  if (searchTerm) {
    payload.q = searchTerm;
  }

  if (groupIds?.length) {
    payload.clID = groupIds[0].includes('-') ? groupIds[0].split('-')[1] : groupIds[0];
  }

  if (cuAbsolventi) {
    payload.cuAbsolventi = '1';
  }

  if (ascID) {
    payload.ascID = ascID;
  }

  if (searchInStudents) {
    payload.searchInStudents = Boolean(searchInStudents);
  }

  if (cuClasa) {
    payload.cuClasa = Boolean(cuClasa);
  }

  if (includeInvisibleClasses) {
    payload.includeInvisibleClasses = includeInvisibleClasses;
  }

  if (_limit) {
    payload._limit = _limit;
  }

  if ((queryPayload as QueryInput).accountIds?.length) {
    return getUsers((queryPayload as QueryInput).accountIds || []);
  }

  return searchUsers(payload);
};

// Only used because of filtering by array of IDs
export const usePersonsSearchQuery = (
  queryInput: Partial<QueryInput>,
  options?: UseQueryOptions<Person[], AxiosError>
) => {
  return useQuery<Person[], AxiosError>(
    ['persons', PersonsSearchInput.of(queryInput)],
    searchPersons,
    options
  );
};
