import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class PortfolioDocumentInput {
  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.name.required',
  })
  name!: string;

  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.avtData.required',
  })
  date!: string;

  static of = Entity.of<PortfolioDocumentInput>();
}
