import { IsNotEmptyObject } from '@lib/validators';
import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class CreateValidatorStep0 {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.students.required' })
  @IsNotEmptyObject({ message: 'errors.students.required' })
  accountIds: Record<string, string[]> | null = null;

  static of = Entity.of<CreateValidatorStep0>();
}
