import { Entity, Of } from 'entity-of';

@Entity
export class UniversityPersonalSearchInput {
  @Of(() => Number)
  page: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => String)
  order: string = '';

  @Of(() => String)
  orderBy: string = '';

  static of = Entity.of<UniversityPersonalSearchInput>();
}
