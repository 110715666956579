import { Entity, Of } from 'entity-of';

@Entity
export class ProgramDomainInput {
  @Of(() => Number)
  cycleId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  static of = Entity.of<ProgramDomainInput>();
}
