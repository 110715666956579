import { ComponentStyleConfig } from '@chakra-ui/react';

export const ButtonTheme: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: 'orange',
  },
  baseStyle: {
    lineHeight: '150%',
    alignItems: 'center',
  },
  sizes: {
    xs: {
      borderRadius: '4',
    },
    sm: {
      borderRadius: '6',
    },
    md: {
      borderRadius: '8',
    },
    lg: {
      borderRadius: '10',
    },
  },
  variants: {
    flushed: ({ colorScheme }) => ({
      backgroundColor: 'transparent',
      borderRadius: 'none',
      borderBottom: '2px solid',
      borderColor: `gray.200`,
      _active: {
        color: `${colorScheme}.600`,
        borderColor: `${colorScheme}.600`,
      },
    }),
    solid: {
      boxShadow: 'xs',
    },
    outline: ({ colorScheme }) => ({
      background: 'white',
      boxShadow: 'xs',
      borderColor: `${colorScheme}.200`,
      color: `${colorScheme}.700`,
    }),
    unstyled: {
      height: 'initial',
      textAlign: 'initial',
      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },
    },
  },
};
