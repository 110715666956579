import { Entity, Of } from 'entity-of';

@Entity
export class ChargesSearchInput {
  @Of(() => Boolean, { optional: true })
  opened?: boolean;

  @Of(() => Number, { optional: true })
  invoiceId?: number;

  @Of(() => String, { optional: true })
  accountId?: string;

  @Of(() => String, { optional: true })
  serviceId?: string;

  @Of(() => String, { optional: true })
  periodStart?: string;

  @Of(() => String, { optional: true })
  periodEnd?: string;

  @Of(() => Number, { optional: true })
  assignedServiceId?: number;

  @Of(() => String, { optional: true })
  billableAccountId?: string;

  @Of(() => [String], { optional: true })
  tenantBillingProfileIds?: string[];

  @Of(() => Number, { optional: true })
  limit?: number = 1000;

  static of = Entity.of<ChargesSearchInput>();
}
