import Adservio from './vector/adservio.svg';
import AdservioIcon from './vector/Adservio_icon.svg';
import AdservioLogoBorder from './vector/Adservio_logo_border.svg';
import AdservioLogoBorderHover from './vector/Adservio_logo_border_hover.svg';
import AiCircles from './vector/ai_circles.svg';
import AppleLogo from './vector/apple_logo.svg';
import AppleText from './vector/apple_text.svg';
import Archive from './vector/archive.svg';
import Audio from './vector/audio.svg';
import AuthBackground from './vector/auth_background.svg';
import Bag from './vector/bag.svg';
import Bags from './vector/bags.svg';
import Books from './vector/books.svg';
import Brain from './vector/brain.svg';
import Brain2 from './vector/brain2.svg';
import Building from './vector/building.svg';
import BusinessCardEmpty from './vector/business_card_empty.svg';
import Calendar from './vector/calendar.svg';
import CalendarEdited from './vector/calendar_edited.svg';
import CalendarEmpty from './vector/calendar_empty.svg';
import Character from './vector/character.svg';
import Checklist from './vector/checklist.svg';
import Clock from './vector/clock.svg';
import Coins from './vector/coins.svg';
import ColorPicker from './vector/color_picker.svg';
import ColumnChartNoData from './vector/column_chart_no_data.svg';
import Conduct from './vector/conduct.svg';
import ConductEmptyState from './vector/conduct_empty_state.svg';
import Daily from './vector/daily.svg';
import Discount from './vector/discount.svg';
import Doc from './vector/doc.svg';
import Door from './vector/door.svg';
import EduMarket from './vector/edumarket.svg';
import EmojiFaces from './vector/emoji_faces.svg';
import EmotionsDiary from './vector/emotions_diary.svg';
import EmptyAsset from './vector/empty_asset.svg';
import EmptyFolder from './vector/empty_folder.svg';
import NewspaperEmpty from './vector/empty_newspaper.svg';
import EduMarketEmpty from './vector/empty_state_edumarket.svg';
import NextTaskEmpty from './vector/empty_state_next_tasks.svg';
import QuestionnaireEmptyState from './vector/emptystate.svg';
import Even from './vector/even.svg';
import EvolutionAI from './vector/evolution_ai.svg';
import EvolutionEmptyState from './vector/evolution_empty.svg';
import FacebookIcon from './vector/facebook_icon.svg';
import Feedback from './vector/feedback.svg';
import Files from './vector/files.svg';
import FilesShare from './vector/files_share.svg';
import Folder from './vector/folder.svg';
import GooglePlayLogo from './vector/google_play_logo_color.svg';
import GooglePlayText from './vector/google_play_text.svg';
import Graduation from './vector/graduation.svg';
import Group from './vector/group.svg';
import GroupMessages from './vector/group_messages.svg';
import GroupSmall from './vector/group_small.svg';
import HappyEmoji from './vector/happy.svg';
import HappyEmojiSelected from './vector/happy_selected.svg';
import HighSchoolBuilding from './vector/highschool_building.svg';
import History from './vector/history.svg';
import HistoryEmpty from './vector/history_empty_state.svg';
import HuaweiLogo from './vector/huawei_logo.svg';
import HuaweiText from './vector/huawei_text.svg';
import Image from './vector/img.svg';
import InstagramIcon from './vector/instagram_icon.svg';
import InvoiceEmpty from './vector/invoice_empty.svg';
import InvoiceEmptyLight from './vector/invoice_empty_light.svg';
import IQTest from './vector/iq_test.svg';
import IQTests from './vector/iq_tests.svg';
import IQTestsEmptyState from './vector/iq_tests_empty.svg';
import LinkedinIcon from './vector/linkedIn_icon.svg';
import LogoNavA from './vector/logo-nav-a.svg';
import LogoNavAUni from './vector/logo-nav-a-uni.svg';
import LogoNavFull from './vector/logo-nav-full.svg';
import LogoNavFullTi from './vector/logo-nav-full-ti.svg';
import LogoNavFullUni from './vector/logo-nav-full-uni.svg';
import LogoNavTi from './vector/logo-nav-ti.svg';
import Maintenance from './vector/maintenance.svg';
import Megaphone from './vector/megaphone.svg';
import Message from './vector/message.svg';
import Messages from './vector/messages.svg';
import MessagesEmptyState from './vector/messages_empty.svg';
import Meter from './vector/meter.svg';
import Netopia from './vector/netopia.svg';
import NeutralEmoji from './vector/neutral.svg';
import NeutralEmojiSelected from './vector/neutral_selected.svg';
import Newspaper from './vector/newspaper.svg';
import NoAccessAdservio from './vector/no_access_adservio.svg';
import NoAssignedServices from './vector/no_assigned_services.svg';
import NoBillingProfiles from './vector/no_billing_profile_dashboard.svg';
import NoCharges from './vector/no_charges.svg';
import NoClass from './vector/no_class_available.svg';
import NoInvoices from './vector/no_invoices.svg';
import NoPayments from './vector/no_payments.svg';
import NoRequestsServices from './vector/no_requests_services.svg';
import NoScholarshipsStudent from './vector/no_scholarships_student.svg';
import NoServices from './vector/no_services.svg';
import NoSubscriptionActive from './vector/no_subscription_active.svg';
import NotWellEmoji from './vector/not_well.svg';
import NotWellEmojiSelected from './vector/not_well_selected.svg';
import ObservationsEmptyState from './vector/observations_empty_state.svg';
import Odd from './vector/odd.svg';
import BillingOtherInformation from './vector/other_information.svg';
import PackagesBackground from './vector/packages_background.svg';
import PageNotFound from './vector/page_not_found.svg';
import PDF from './vector/pdf.svg';
import PersonJumping from './vector/person_jumping.svg';
import PersonalGrowth from './vector/personal_growth.svg';
import Photos from './vector/photos.svg';
import PhotosEmptyState from './vector/photos_empty.svg';
import PieChartNoData from './vector/pie_chart_no_data.svg';
import PiggyBank from './vector/piggy_bank.svg';
import PiggyBankEmptyState from './vector/piggy_bank_empty_state.svg';
import PPT from './vector/ppt.svg';
import ProcessFailed from './vector/process_failed.svg';
import ProcessPending from './vector/process_pending.svg';
import ProcessRunning from './vector/process_running.svg';
import ProcessSuccess from './vector/process_success.svg';
import RankNoData from './vector/rank_no_data.svg';
import Reports from './vector/reports.svg';
import ResumeFolder from './vector/resume_folder.svg';
import Robot from './vector/robot.svg';
import Rocket from './vector/rocket.svg';
import Room from './vector/room.svg';
import SadEmoji from './vector/sad.svg';
import SadEmojiSelected from './vector/sad_selected.svg';
import SelfDiscoveryTest from './vector/self_discovery_test.svg';
import SelfknowledgeEmptyState from './vector/selfknowledge_empty.svg';
import ShoppingBags from './vector/shopping_bags.svg';
import SpreadSheet from './vector/spreadsheet.svg';
import Stairs from './vector/stairs.svg';
import Statistics from './vector/statistics.svg';
import Stripe from './vector/stripe.svg';
import Success from './vector/success.svg';
import Text from './vector/text.svg';
import TextMessage from './vector/text_message.svg';
import TwitterIcon from './vector/twitter_icon.svg';
import AdmissionCuate from './vector/university_students.svg';
import UnsubscribeSuccess from './vector/unsubscribe_success.svg';
import UserAtScreen from './vector/user_at_screen.svg';
import Video from './vector/video.svg';
import WarningsEmptyState from './vector/warnings_empty_state.svg';
import WellEmoji from './vector/well.svg';
import WellEmojiSelected from './vector/well_selected.svg';
import EmotionsEmptyState from './vector/wellbeing_evolution_empty_state.svg';
import YearbookEmptyState from './vector/yearbook_empty.svg';
import YouTubeIcon from './vector/youtube_icon.svg';

const vectors = {
  Adservio,
  Audio,
  AdmissionCuate,
  Archive,
  AdservioIcon,
  AdservioLogoBorder,
  AdservioLogoBorderHover,
  AiCircles,
  AppleLogo,
  AppleText,
  AuthBackground,
  Bag,
  BillingOtherInformation,
  Brain,
  Brain2,
  Building,
  BusinessCardEmpty,
  Calendar,
  CalendarEdited,
  CalendarEmpty,
  Character,
  Checklist,
  Clock,
  Coins,
  ColorPicker,
  Conduct,
  ConductEmptyState,
  Daily,
  Discount,
  Door,
  Doc,
  EduMarket,
  EduMarketEmpty,
  EmotionsDiary,
  EmotionsEmptyState,
  EmojiFaces,
  EmptyFolder,
  Even,
  EvolutionAI,
  EmptyAsset,
  EvolutionEmptyState,
  FacebookIcon,
  Feedback,
  Folder,
  Files,
  FilesShare,
  GooglePlayLogo,
  GooglePlayText,
  Graduation,
  Group,
  GroupMessages,
  GroupSmall,
  HappyEmoji,
  HappyEmojiSelected,
  HighSchoolBuilding,
  History,
  HistoryEmpty,
  HuaweiLogo,
  HuaweiText,
  InstagramIcon,
  InvoiceEmpty,
  Image,
  IQTest,
  IQTests,
  IQTestsEmptyState,
  LinkedinIcon,
  LogoNavA,
  LogoNavAUni,
  LogoNavTi,
  LogoNavFull,
  LogoNavFullUni,
  LogoNavFullTi,
  Maintenance,
  Megaphone,
  Message,
  Messages,
  MessagesEmptyState,
  Meter,
  Netopia,
  NeutralEmoji,
  NeutralEmojiSelected,
  NextTaskEmpty,
  Newspaper,
  NewspaperEmpty,
  NoAccessAdservio,
  NoAssignedServices,
  NoBillingProfiles,
  NoCharges,
  NoClass,
  NoInvoices,
  NoPayments,
  NoRequestsServices,
  NoServices,
  NoSubscriptionActive,
  NotWellEmoji,
  NotWellEmojiSelected,
  Odd,
  ObservationsEmptyState,
  PageNotFound,
  PackagesBackground,
  PersonJumping,
  PersonalGrowth,
  Photos,
  PhotosEmptyState,
  ProcessFailed,
  ProcessPending,
  ProcessRunning,
  ProcessSuccess,
  PDF,
  PPT,
  Reports,
  Robot,
  Room,
  SadEmoji,
  SadEmojiSelected,
  SelfDiscoveryTest,
  SelfknowledgeEmptyState,
  ShoppingBags,
  SpreadSheet,
  Stairs,
  Statistics,
  Stripe,
  Success,
  TextMessage,
  Text,
  TwitterIcon,
  Video,
  UnsubscribeSuccess,
  UserAtScreen,
  WarningsEmptyState,
  WellEmoji,
  WellEmojiSelected,
  YearbookEmptyState,
  YouTubeIcon,
  ColumnChartNoData,
  PieChartNoData,
  RankNoData,
  Rocket,
  ResumeFolder,
  NoScholarshipsStudent,
  QuestionnaireEmptyState,
  Bags,
  PiggyBank,
  PiggyBankEmptyState,
  InvoiceEmptyLight,
  Books,
};

export type VectorsRecord = Record<keyof typeof vectors, string>;

export const Vectors: VectorsRecord = vectors;
