import { Entity, Of } from 'entity-of';

@Entity
export class UpgradeInput {
  @Of(() => String)
  packageId: string = '';

  @Of(() => String)
  subscriberBillingProfileId: string = '';

  @Of(() => String)
  recurrence: string = '';

  @Of(() => Boolean)
  trial: boolean = false;

  @Of(() => Boolean)
  terms: boolean = false;

  @Of(() => RedirectsInput, { optional: true })
  redirects?: RedirectsInput;

  static of = Entity.of<UpgradeInput>();
}

@Entity
class RedirectsInput {
  @Of(() => String)
  redirectSuccess: string = '';

  @Of(() => String)
  redirectCancel: string = '';

  static of = Entity.of<RedirectsInput>();
}
