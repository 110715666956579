import { Entity, Of } from 'entity-of';

@Entity
export class LanguageItem {
  @Of(() => String)
  langID: string = '';

  @Of(() => String)
  langName: string = '';

  @Of(() => String)
  langOrder: string = '';

  static of = Entity.of<LanguageItem>();
}
