import { Entity, Of } from 'entity-of';

@Entity
export class Label {
  @Of(() => String)
  lbID: string = '';

  @Of(() => String)
  lbName: string = '';

  @Of(() => String)
  lbColor: string = '';

  @Of(() => String)
  lbIsSystem: string = '';

  @Of(() => String, { nullable: true })
  lbType: string | null = null;

  @Of(() => String, { nullable: true })
  lbMetadata: string | null = null;

  @Of(() => String, { nullable: true })
  lbCreatedAt: string = '';

  @Of(() => String, { nullable: true })
  lbUpdatedAt: string | null = null;

  @Of(() => String)
  lbCreatedByID: string = '';

  static of = Entity.of<Label>();
}
