import React, { ChangeEventHandler, forwardRef, useRef } from 'react';

import { StyledFileInput, UploadButton } from './FileUploadTemp.theme';

export interface EndpointOptionsTemp {
  uploadUrl: string;
  downloadUrl?: string;
  payload?: Record<string, string>;
  fileKeyName: string;
}

interface FileUploaderProps<T> {
  name?: string;
  label?: string;
  variant?: string;
  colorScheme?: string;
  size?: string;
  accept?: string;
  apiBaseUrl: string;
  setFileNameUpload?: any;
  endpointOptions?: EndpointOptionsTemp;
  onChange?(file: File): void;
  onLoading?(value: boolean): void;
  onProgress?(value: number): void;
  onLoadStart?(): void;
  onLoadEnd?(value: any): void;
  onError?(response: any): void;
  onSuccess?(response: T): void;
}

function _FileUploadTemp<T>(
  {
    name = 'fileUploadInput',
    label,
    variant,
    colorScheme,
    size,
    apiBaseUrl,
    endpointOptions,
    accept,
    onChange,
    onLoading,
    onProgress,
    onLoadStart,
    onLoadEnd,
    onSuccess,
    onError,
    setFileNameUpload,
  }: FileUploaderProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = async () => {
    if (!inputRef.current) return;

    const currentFiles = inputRef.current.files;

    if (!currentFiles) return;

    for (const file of currentFiles) {
      onChange?.(file);

      const xhr = new XMLHttpRequest();

      xhr.upload.onloadstart = () => {
        onLoading?.(true);
        onLoadStart?.();
      };

      xhr.upload.onprogress = (e) => {
        const percentage = Math.floor((e.loaded * 100) / e.total);
        onProgress?.(percentage);
      };

      xhr.upload.onerror = () => {
        onLoading?.(false);
      };

      xhr.upload.onabort = () => {
        onLoading?.(false);
      };

      xhr.upload.ontimeout = () => {
        onLoading?.(false);
      };

      xhr.onloadend = (e) => {
        if (xhr.status >= 200 && xhr.status < 300) {
          onLoadEnd?.(e);
        } else {
          console.error(e);
          let data = JSON.parse(xhr.responseText);
          onError?.(data);
        }

        onLoading?.(false);
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let data = JSON.parse(xhr.responseText);
          onSuccess?.(data);
          setFileNameUpload?.(file.name);
        }
      };

      if (!endpointOptions) {
        return;
      }

      const formData = new FormData();

      if (endpointOptions) {
        xhr.open('POST', `${apiBaseUrl}${endpointOptions.uploadUrl}`);
        xhr.setRequestHeader('Language', 'ro');

        xhr.setRequestHeader('X-Userrole', localStorage.getItem('campus-user-type') ?? '');
        xhr.setRequestHeader('X-Tenantid', localStorage.getItem('universityId') ?? '');

        formData.append(endpointOptions.fileKeyName ?? 'content', file);
        formData.append('fileName', file.name);

        if (endpointOptions.payload) {
          Object.keys(endpointOptions.payload).forEach((key) => {
            formData.append(key, String(endpointOptions.payload?.[key]));
          });
        }

        setFileNameUpload?.(file.name);
      }

      xhr.send(formData);
    }

    inputRef.current.value = '';
    onProgress?.(0);
  };

  return (
    <>
      <UploadButton
        name={name}
        ref={ref as any}
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        label={label}
      />

      <StyledFileInput
        type="file"
        ref={inputRef}
        accept={accept}
        id={name}
        onChange={handleOnChange}
        data-testid={name}
      />
    </>
  );
}

export const FileUploadTemp = forwardRef(_FileUploadTemp) as <T>(
  props: FileUploaderProps<T> & { ref?: React.ForwardedRef<HTMLUListElement> }
) => ReturnType<typeof _FileUploadTemp>;
