import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class CreateValidatorStep0 {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.questionnaires.formType' })
  type: string = '';

  static of = Entity.of<CreateValidatorStep0>();
}
