import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Person } from '@api/core/entities';
import { ORG_UNIVERSITY, TYPE_CADRU, TYPE_ELEV, UserTypes } from '@constants';
import { useStorageSession } from '@lib/utils';
import { PersonInfoModal } from '@modules/Universities/shared-universities';
import { Flex, FlexProps, HStack } from '@ui2/components';
import { useDisclosure } from '@ui2/hooks';

import {
  DetailsText,
  InfoIconButton,
  MainContainer,
  PersonAvatar,
  PersonDetailsSection,
  PersonName,
  PersonRole,
  Size,
} from './PersonExcerpt.layout';

export interface DetailsConfig {
  showRole?: boolean;
  showClassName?: boolean;
  showFunction?: boolean;
  showDepartment?: boolean;
  useRoleTag?: boolean;
  useInfoModal?: boolean;
  onNameClick?: () => void;
}

interface PersonExcerptProps extends FlexProps {
  person: Person;
  size?: Size;
  isLoading?: boolean;
  detailsConfig?: DetailsConfig;
}

export const PersonExcerpt = ({
  person,
  isLoading,
  size = 'md',
  detailsConfig,
  children,
  ...props
}: PropsWithChildren<PersonExcerptProps>) => {
  const { t } = useTranslation('shared');
  const { orgType } = useStorageSession();

  const {
    uaFirstName,
    uaLastName,
    prefAvatar,
    roles,
    uaCadUniDepartament,
    uaCadUniFunctie,
    uaInitTata,
    uaTip,
  } = person;

  const { ascIsActiv, ascNume, clNume } = person?.clasa || {};

  const fatherInitial =
    uaInitTata && uaTip === TYPE_ELEV
      ? `${uaInitTata.toLocaleUpperCase()}${uaInitTata.lastIndexOf('.') === uaInitTata.length - 1 ? '' : '.'}`
      : '';

  const personName =
    [uaLastName, fatherInitial, uaFirstName].filter((n) => Boolean(n)).join(' ') ||
    t('general.user_not_registred');

  const year = ascIsActiv === '0' && ascNume ? `(${ascNume.replace('/', '-')})` : '';

  const {
    showRole = !(orgType === ORG_UNIVERSITY && uaTip === TYPE_CADRU),
    showClassName = true,
    showFunction = true,
    showDepartment = true,
    useRoleTag = false,
    useInfoModal = orgType === ORG_UNIVERSITY,
    onNameClick,
  } = detailsConfig || {};

  const {
    isOpen: isInfoDialogOpen,
    onOpen: onInfoDialogOpen,
    onClose: onInfoDialogClose,
  } = useDisclosure();

  const showPersonDetails = showRole || showClassName || showFunction || showDepartment;

  const onInfoIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    onInfoDialogOpen();
  };

  return (
    <MainContainer data-testid="person-excerpt" size={size} {...props}>
      <PersonAvatar
        size={size}
        name={personName}
        isLoading={isLoading}
        avatar={prefAvatar}
        onNameClick={onNameClick}
      />

      <PersonDetailsSection size={size}>
        <HStack gap={1} alignItems="center">
          <PersonName
            name={personName}
            size={size}
            isLoading={isLoading}
            onNameClick={onNameClick}
          />

          {useInfoModal && (
            <>
              <InfoIconButton size={size} onClick={(e) => onInfoIconClick(e)} />
              <PersonInfoModal
                person={person}
                isOpen={isInfoDialogOpen}
                onClose={onInfoDialogClose}
              />
            </>
          )}
        </HStack>

        {showPersonDetails && (
          <Flex align="center" justifyContent="flex-start" flexWrap="wrap" gap="2">
            {showRole && roles?.length > 0 && (
              <PersonRole
                userType={person.uaTip as UserTypes}
                size={size}
                roles={roles}
                useRoleTag={useRoleTag}
                isLoading={isLoading}
              />
            )}

            {showClassName && clNume && (
              <DetailsText
                size={size}
                isLoading={isLoading}
                withSeparator={showRole && roles?.length > 0 && !useRoleTag}
                text={year ? `${clNume} ${year}` : clNume}
              />
            )}

            {showFunction && uaCadUniFunctie && (
              <DetailsText size={size} isLoading={isLoading} text={uaCadUniFunctie} />
            )}

            {showDepartment && uaCadUniDepartament && (
              <DetailsText
                size={size}
                isLoading={isLoading}
                withSeparator={!!(showFunction && uaCadUniFunctie)}
                text={uaCadUniDepartament}
              />
            )}
          </Flex>
        )}

        {children}
      </PersonDetailsSection>
    </MainContainer>
  );
};
