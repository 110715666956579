import { Entity, Of } from 'entity-of';

@Entity
export class AgeDistribution {
  @Of(() => String, { nullable: true })
  ageGroup: null | number = null;

  @Of(() => String)
  male: string = '';

  @Of(() => String)
  female: string = '';

  @Of(() => String)
  other: string = '';

  @Of(() => String)
  total: string = '';

  static of = Entity.of<AgeDistribution>();
}
