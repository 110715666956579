import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class StudentSearchInput extends SimpleQueryInput {
  @Of(() => String)
  ascID: string = '';

  @Of(() => String, { optional: true })
  clID?: string = '';

  @Of(() => Boolean, { optional: true })
  includeTransferred?: boolean;

  @Of(() => Boolean, { optional: true })
  includeInactiveClasses?: boolean;

  @Of(() => Boolean, { optional: true })
  includeInvisibleClasses?: boolean;

  @Of(() => Boolean, { optional: true })
  includeAlumni?: boolean;

  @Of(() => Boolean, { optional: true })
  includeDeleted?: boolean;

  @Of(() => Boolean, { optional: true })
  cuClasa?: boolean;

  @Of(() => String, { optional: true })
  q?: String;

  static of = Entity.of<StudentSearchInput>();
}
