import { Entity, Of } from 'entity-of';

import { TimetableActivity } from '../TimetableActivity';

export enum IssueType {
  WARNING = 'warning',
  CONFLICT = 'conflict',
}

export enum ConflictType {
  DUPLICATE_ACTIVITY = 'duplicate_activity',
  TEACHER_ROOM = 'teacher_room',
  TEACHER_SUBJECT = 'teacher_subject',
  STUDENT_ACTIVITIES_OVERLAP = 'student_activities_overlap',
  ROOM_SUBJECT = 'room_subject',
}

@Entity
export class TimetableIssue {
  @Of(() => String)
  id: string = '';

  @Of(() => IssueType)
  type: IssueType = IssueType.CONFLICT;

  @Of(() => ConflictType)
  conflictType?: ConflictType;

  @Of(() => String)
  content: string = '';

  @Of(() => [TimetableActivity])
  activities: TimetableActivity[] = [];

  static of = Entity.of<TimetableIssue>();
}
