import { Entity, Of } from 'entity-of';

import { OauthAccount } from './OauthAccount';

@Entity
export class OauthDetails {
  @Of(() => String)
  oaEmail: string = '';

  @Of(() => String)
  oaStatus: string = '';

  @Of(() => OauthAccount, { optional: true })
  account?: OauthAccount;

  @Of(() => String)
  createUserUrl: string = '';

  @Of(() => String)
  oauthUrl: string = '';

  @Of(() => String)
  returnUrl: string = '';

  static of = Entity.of<OauthDetails>();
}
