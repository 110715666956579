import React from 'react';
import { HeaderGroup } from 'react-table';

import { getOrgTypeTheme } from '@ui2/utils';

import { AdservioIcons, Box, Flex } from '../../../atoms';

import { TableHeadCell, TableHeadContainer, TableHeadRow } from './TableHead.layout';
export interface TableHeadProps {
  headerGroups: HeaderGroup<any>[];
  isSortable?: boolean;
}

interface TableHeadMeta {
  isSmallest?: boolean;
  isOrphan?: boolean;
  isPlaceholder?: boolean;
  isGroupParent?: boolean;
  isGroupElement?: boolean;
  isLastInGroup?: boolean;
  isFirstInGroup?: boolean;
  isForcedDivider?: boolean;
}

export const TableHead = React.forwardRef<HTMLTableSectionElement, TableHeadProps>(
  ({ headerGroups, isSortable }, ref) => {
    const baseColor = getOrgTypeTheme('blue');
    const isGroups = headerGroups?.length > 1;

    return (
      <TableHeadContainer ref={ref}>
        {headerGroups.map((headerGroup, idx) => (
          <TableHeadRow {...headerGroup.getHeaderGroupProps()} key={idx}>
            {headerGroup?.headers?.map((column, idx) => {
              const prevMeta: TableHeadMeta =
                idx > 0 ? (headerGroup.headers[idx - 1] as any).meta : null;

              const meta = getGroupingProps(isGroups, column, prevMeta);

              (column as any).meta = meta;

              return (
                <TableHeadCell
                  {...column.getHeaderProps({
                    ...column?.getSortByToggleProps({
                      onClick: () => column.toggleSortBy?.(!column.isSortedDesc),
                    }),
                  })}
                  key={idx}
                  isFirst={idx === 0}
                  isLast={idx === headerGroup.headers.length - 1}
                  isSorting={column.isSorted}
                  isGroups={isGroups}
                  {...meta}
                >
                  <Flex justifyContent={meta.isGroupParent ? 'center' : 'initial'}>
                    {column.render('Header')}

                    {!meta.isPlaceholder && !column.disableSortBy && isSortable && (
                      <Box sx={{ '@media print': { display: 'none' } }}>
                        {!column.isSorted && (
                          <AdservioIcons.Carets ml="1" boxSize={4} color="gray.400" />
                        )}

                        {column.isSortedDesc && (
                          <AdservioIcons.CaretDown ml="1" boxSize={4} color={`${baseColor}.600`} />
                        )}

                        {column.isSorted && !column.isSortedDesc && (
                          <AdservioIcons.CaretUp ml="1" boxSize={4} color={`${baseColor}.600`} />
                        )}
                      </Box>
                    )}
                  </Flex>
                </TableHeadCell>
              );
            })}
          </TableHeadRow>
        ))}
      </TableHeadContainer>
    );
  }
);

const getGroupingProps = (
  isGroups = false,
  column: HeaderGroup<any>,
  prevMeta: TableHeadMeta
): TableHeadMeta => {
  const isSmallest = ['index', 'selection', 'expander', 'menu'].includes(column.id.split('_')[0]);

  if (!isGroups) {
    return {
      isSmallest,
    };
  }

  const isPlaceholder = Boolean(column.placeholderOf);
  const isGroupParent = !isPlaceholder && Boolean(column.headers?.length);
  const isOrphan = !isPlaceholder && !isGroupParent && !column.parent;
  const isGroupElement = !isPlaceholder && !isGroupParent && Boolean(column.parent);

  const isLastInGroup =
    column.parent?.columns?.[column.parent.columns.length - 1].Header === column.Header;

  const isFirstInGroup = prevMeta
    ? !prevMeta.isGroupElement || (prevMeta.isPlaceholder && isGroupParent)
    : false;

  const isForcedDivider =
    (isPlaceholder && prevMeta?.isPlaceholder) || (!isGroupElement && !prevMeta?.isGroupElement);

  return {
    isSmallest,
    isOrphan,
    isPlaceholder,
    isGroupParent,
    isGroupElement,
    isLastInGroup,
    isFirstInGroup,
    isForcedDivider,
  };
};
