import { Entity, Of } from 'entity-of';

@Entity
export class StudentTimetableInput {
  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  clIds: string = '';

  @Of(() => String)
  elevID: string = '';

  @Of(() => String)
  withLessonPlanDate: string = '';

  static of = Entity.of<StudentTimetableInput>();
}
