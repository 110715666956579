import { Component, PropsWithChildren, Suspense } from 'react';
import { Col, Container } from 'react-grid-system';
import ReactModal from 'react-modal';
import { AuthProvider } from 'react-oidc-context';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

import { isSubscriptionRequired, queryClient } from '@lib/utils';
import { UnificationSuccessDialog } from '@modules/Auth/Components/UnificationSuccessDialog';
import { isSSO, oidcConfig } from '@modules/authentication/utils';
import { NativeAppPrompt } from '@modules/shared/components/NativeAppPrompt/NativeAppPrompt';
import { DisruptionBanner } from '@modules/Site/components/disruptionBanner';
import { MainContent } from '@modules/Site/components/main-content';
import { MainMenu } from '@modules/Site/components/main-menu';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Loader from 'Components/Overlays/Loader/Loader/Loader';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';

import './app.less';

import HandleNoLangRoute from './Components/HandleNoLangRoute';
import { sessionInit } from './Redux/SessionActions';
import { getUaID, getUaTip } from './Redux/SessionHelper';
import { ThemeProvider } from './_ui';
import { Navbar } from './Components';
import { TYPE_CANDIDATE } from './constants';
import { useLang } from './i18n';
import { AppRoutes } from './routes';

import 'cropperjs/dist/cropper.css';
import './Assets/Fonts/adservio/style.css';

class App extends Component<any, any> {
  constructor(props: any) {
    const mprQueryString = new URLSearchParams(location.search).get('MPR');

    if (mprQueryString) {
      Cookies.set('MPR', mprQueryString);
    }

    super(props);

    this.state = {
      hasCookiesAdsNative: !!Cookies.get('ads_native'),
      uaTip: '',
      features: { user: {}, organization: {} },
    };

    ReactModal.setAppElement('body');
  }

  componentDidMount() {
    const { doSessionInit } = this.props as any;
    doSessionInit();

    this.setState({
      isNavigationOpen: !!Cookies.get('sidenav-force-open'),
    });

    const lang = useLang();

    this.props.history.listen((location) => {
      const routeHistory = JSON.parse(localStorage.getItem('routeHistory') || '[]');
      const fullRoute = `${location.pathname.split(`${lang}/`)[1]}${location.hash || ''}`;

      if (routeHistory[routeHistory.length - 1] === fullRoute) {
        return;
      }

      routeHistory.push(fullRoute);

      if (routeHistory.length > 50) {
        routeHistory.shift();
      }

      localStorage.setItem('routeHistory', JSON.stringify(routeHistory));
    });
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      this.props.uaTip !== nextProps.uaTip &&
      this.state.uaTip === nextState.uaTip &&
      this.state.uaTip?.length === 0
    ) {
      if (window.localStorage.getItem('session')) {
        this.setState({
          uaTip: JSON.parse(window.localStorage.getItem('session') || '{}').profile?.uaTip,
          features: JSON.parse(window.localStorage.getItem('features') || '{}'),
        });

        return false;
      }
    }
    return true;
  };

  render() {
    const { uaID, sessionReady } = this.props as any;

    const { hasCookiesAdsNative, isNavigationOpen, features, uaTip } = this.state as any;

    const isSubscriptionsActive = this.props.schoolConfig?.subscriptions?.isActive === '1';

    if (!sessionReady) return <Loader />;

    window.scrollTo(0, 0);
    window.addEventListener('navigationToggle', (data: any) => {
      this.setState({
        isNavigationOpen: data.detail,
      });
    });

    return (
      <Providers>
        <ReactQueryDevtools initialIsOpen={false} />

        <Container
          fluid
          style={{ padding: '0', height: 'min(100svh, 100vh)', position: 'relative' }}
        >
          <DisruptionBanner />

          <UnificationSuccessDialog />

          <Suspense fallback={hasCookiesAdsNative ? <Loader /> : <></>}>
            {!hasCookiesAdsNative && uaID && <Navbar />}

            <MainContent
              uaID={uaID}
              isNavigationOpen={isNavigationOpen}
              noNavigation={
                isSubscriptionRequired(isSubscriptionsActive, uaTip, features) ||
                uaTip === TYPE_CANDIDATE
              }
              hasCookiesAdsNative={hasCookiesAdsNative}
            >
              {uaID && !isSubscriptionRequired(isSubscriptionsActive, uaTip, features) && (
                <Route path="*" render={() => <MainMenu />} />
              )}

              <Col lg={12} md={12} sm={12}>
                <Suspense fallback={hasCookiesAdsNative ? <></> : <Loader />}>
                  <AppRoutes uaID={uaID} />
                </Suspense>
              </Col>
            </MainContent>

            <Route path="*" component={HandleNoLangRoute} />

            {!hasCookiesAdsNative && false && <NativeAppPrompt />}
          </Suspense>
        </Container>
      </Providers>
    );
  }
}

const Providers = ({ children }: PropsWithChildren<{}>) => {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={window.env?.GOOGLE_OAUTH_CLIENT_ID || 'PLACEHOLDER'}>
        <SSOProvider>
          <ThemeProvider>{children}</ThemeProvider>
        </SSOProvider>
      </GoogleOAuthProvider>
    </QueryClientProvider>
  );
};

const SSOProvider = ({ children }: PropsWithChildren<{}>) => {
  if (!isSSO) return <>{children}</>;

  return (
    <AuthProvider {...oidcConfig} scope="openid profile email" redirect_uri={window.location.href}>
      {children}
    </AuthProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    uaID: getUaID(state),
    uaTip: getUaTip(state),
    features: { user: {}, organization: {} },
    sessionReady: state.session.ready,
    schoolConfig: state.session.schoolConfig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doSessionInit: () => {
      return dispatch(sessionInit());
    },
  };
};

(App as any).propTypes = {
  uaID: PropTypes.string,
  sessionReady: PropTypes.bool,
  isNavigationOpen: PropTypes.bool,
  schoolConfig: PropTypes.instanceOf(Object),
  doSessionInit: PropTypes.func.isRequired,
};

(App as any).defaultProps = {
  uaID: undefined,
  sessionReady: undefined,
  isNavigationOpen: false,
  schoolConfig: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
