import { ArrayMaxSize, ArrayNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { UserAccountInfo } from '../DiplomaFormInput';

@Entity
export class DiplomaValidatorStep1 {
  @Of(() => String)
  @ArrayNotEmpty({ message: 'errors.students.required' })
  @ArrayMaxSize(50, { message: 'errors.students.max' })
  userAccountInfo: UserAccountInfo[] = [];

  static of = Entity.of<DiplomaValidatorStep1>();
}
