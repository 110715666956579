import { Person, SchoolYear, StudyCycle, StudyYear } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class PersonClass {
  @Of(() => String)
  clID: string = '';

  @Of(() => String)
  clNume: string = '';

  @Of(() => String)
  ascIsActiv: string = '';

  @Of(() => String)
  clAstID: string = '';

  @Of(() => String)
  clAscID: string = '';

  @Of(() => String)
  ascNume: string = '';

  @Of(() => String)
  isActive: string = '';

  @Of(() => String, { optional: true })
  clRoomID?: string = '';

  @Of(() => String)
  isVisible: string = '';

  @Of(() => SchoolYear)
  asc?: SchoolYear;

  @Of(() => StudyYear)
  ast?: StudyYear;

  @Of(() => String)
  timetableScheduleID?: string;

  @Of(() => StudyCycle)
  ciclu?: StudyCycle;

  @Of(() => String)
  clLitera: string = '';

  @Of(() => ClassBookInfo)
  classbookInfo: ClassBookInfo = ClassBookInfo.of({});

  @Of(() => [Person])
  coordinators: Person[] = [];

  @Of(() => String)
  clTip: string = '';

  @Of(() => String)
  spID: string = '';

  @Of(() => ({ Lock }))
  locks: Record<string, Lock> = {};

  @Of(() => String)
  clNrOrdine: string = '';

  @Of(() => String)
  clAstNume: string = '';

  @Of(() => String)
  cicluID: string = '';

  @Of(() => String)
  cicluNume: string = '';

  @Of(() => String)
  ctID: string = '';

  @Of(() => String)
  ctNume: string = '';

  @Of(() => String)
  spNume: string = '';

  @Of(() => String, { optional: true })
  clRoomName?: string;

  static of = Entity.of<PersonClass>();
}

@Entity
class ClassBookInfo {
  @Of(() => String)
  director: string = '';

  @Of(() => String)
  secretary: string = '';

  @Of(() => String)
  headTeacher: string = '';

  @Of(() => String)
  signedDate: string = '';

  @Of(() => String)
  Profile: string = '';

  @Of(() => String)
  ProfessionalQualification: string = '';

  @Of(() => String)
  TrainingField: string = '';

  @Of(() => String)
  QualificationLevel: string = '';

  @Of(() => AveragesLocks)
  averagesLocks: AveragesLocks = AveragesLocks.of({});

  @Of(() => String)
  Branch: string = '';

  static of = Entity.of<ClassBookInfo>();
}

@Entity
class AveragesLocks {
  @Of(() => [Msm])
  msm: Msm[] = [];

  @Of(() => Mgm)
  mgm: Mgm = Mgm.of({});

  @Of(() => [Mgs])
  mgs: Mgs[] = [];

  @Of(() => Mga)
  mga: Mga = Mga.of({});

  static of = Entity.of<AveragesLocks>();
}

@Entity
class Msm {
  @Of(() => String)
  msmSanID: string = '';

  @Of(() => Boolean)
  msmLocked: boolean = false;

  static of = Entity.of<Msm>();
}

@Entity
class Mgm {
  @Of(() => Boolean)
  mgmLocked: boolean = false;

  @Of(() => Boolean)
  mgmIntermediaryLocked: boolean = false;

  static of = Entity.of<Mgm>();
}

@Entity
class Mgs {
  @Of(() => String)
  mgsSanID: string = '';

  @Of(() => Boolean)
  mgsLocked: boolean = false;

  static of = Entity.of<Mgs>();
}

@Entity
class Mga {
  @Of(() => Boolean)
  mgaLocked: boolean = false;

  @Of(() => Boolean)
  mgaIntermediaryLocked: boolean = false;

  static of = Entity.of<Mga>();
}

@Entity
class Lock {
  @Of(() => String)
  sanID: string = '';

  @Of(() => String)
  clID: string = '';

  @Of(() => String)
  date: string = '';

  @Of(() => String)
  isLocked: string = '';

  @Of(() => [History])
  history: History = History.of({});

  @Of(() => String)
  sanNume: string = '';

  static of = Entity.of<Lock>();
}

@Entity
class History {
  @Of(() => String)
  date: string = '';

  @Of(() => String)
  isLocked: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  uaID: string = '';

  static of = Entity.of<History>();
}
