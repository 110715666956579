import { ArrayNotEmpty, IsNotEmpty, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { RecurrenceType } from '../Todo';

@Entity
export class RecurrenceDate {
  @Of(() => String)
  type: string = RecurrenceType.WEEKLY;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.questionnaires.endDate' })
  endDate: string = '';

  // Validăm `daysOfWeek` doar dacă `type` este `weekly`
  @ValidateIf((o) => o.type === RecurrenceType.WEEKLY)
  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.questionnaires.daysOfWeek' })
  daysOfWeek: string[] = [];

  // Validăm `daysOfMonth` doar dacă `type` este `monthly`
  @ValidateIf((o) => o.type === RecurrenceType.MONTHLY)
  @Of(() => [Number])
  @ArrayNotEmpty({ message: 'errors.questionnaires.dayOfMonth' })
  dayOfMonth: number[] = [];

  static of = Entity.of<RecurrenceDate>();
}
