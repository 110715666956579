import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class Warning {
  @Of(() => String)
  avtID: string = '';

  @Of(() => String)
  avtNumar: string = '';

  @Of(() => String)
  avtMotiv: string = '';

  @Of(() => String)
  avtDataInsert: string = '';

  @Of(() => String)
  avtData: string = '';

  @Of(() => String)
  avtShowInReports: '0' | '1' = '0';

  @Of(() => Person)
  student: Person = Person.of({});

  @Of(() => Person)
  owner: Person = Person.of({});

  static of = Entity.of<Warning>();
}
