import { useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';

import { useDisclosure } from '@ui2/hooks';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module-react';

import { ImageModal } from './components/ImageModal';
import { LinkModal } from './components';

Quill.register('modules/imageResize', ImageResize);

import { TYPE_ADMIN, TYPE_PERS_INST } from '@constants';
import { useStorageSession } from '@lib/utils';

import 'react-quill/dist/quill.snow.css';
import '../../../../Assets/styles/quill_custom.css';

Quill.register(Quill.import('attributors/style/direction'), true);

const Parchment = Quill.import('parchment');

class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }
}

// @ts-ignore: Not sure where to import Parchment typings from
let IndentStyle = new IndentAttributor('indent', 'text-indent', {
  scope: Parchment.Scope.BLOCK,
  whitelist: ['1em', '2em', '3em', '4em', '5em', '6em', '7em', '8em', '9em'],
});

Quill.register(IndentStyle, true);

interface TextEditorProps {
  isDisabled?: boolean;
  value: string;
  placeholder?: string;
  schoolIds?: string[];
  elements?: any;
  direction?: 'horizontal' | 'vertical';
  onChange: (value: string) => void;
  onFocus?: () => void;
  onKeyDown?: (e) => void;
}

export const TextEditor = ({
  isDisabled,
  value,
  placeholder,
  schoolIds,
  elements = [
    [{ header: [1, 2, 5, false] }], // For non admin users, this will be deleted
    ['bold', 'italic', 'underline', { color: [] }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
    [{ list: 'bullet' }, { list: 'ordered' }, 'link', 'image'],
  ],
  direction = 'vertical',
  onChange,
  onFocus,
  onKeyDown,
}: TextEditorProps) => {
  const ref = useRef<any>(null);
  const { profile } = useStorageSession();
  const userIsAdmin = [TYPE_ADMIN, TYPE_PERS_INST].includes(profile.uaTip);

  if (!userIsAdmin) {
    // Do not allow headings for non admin users
    elements.splice(0, 1);
  }

  const { isOpen: isLinkOpen, onToggle: onLinkToggle, onClose: onLinkClose } = useDisclosure();
  const { isOpen: isImageOpen, onToggle: onImageToggle, onClose: onImageClose } = useDisclosure();
  const [selection, setSelection] = useState({ index: 0, length: 0 });

  const persistSelection = () => {
    let quill = ref.current.getEditor();
    const range = quill.getSelection();

    setSelection(range);
  };

  const handleLinkToggle = () => {
    persistSelection();
    onLinkToggle();
  };

  const handleImageToggle = () => {
    persistSelection();
    onImageToggle();
  };

  const modules = useMemo(
    () => ({
      imageResize: {
        parchment: Quill.import('parchment'),
      },
      toolbar: {
        container: elements,
        handlers: {
          link: handleLinkToggle,
          image: handleImageToggle,
        },
      },
    }),
    []
  );

  return (
    <>
      <ReactQuill
        ref={ref}
        className={direction}
        theme="snow"
        value={value}
        modules={modules}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        readOnly={isDisabled}
        placeholder={placeholder}
      />

      {isLinkOpen && (
        <LinkModal editorRef={ref} isOpen selection={selection} onClose={onLinkClose} />
      )}

      {isImageOpen && (
        <ImageModal
          editorRef={ref}
          isOpen
          selection={selection}
          schoolIds={schoolIds}
          onClose={onImageClose}
        />
      )}
    </>
  );
};
