import React from 'react';

import { getOrgTypeTheme } from '@ui2/utils';

import { Center, SpaceProps, Spinner } from '../../../components';

interface LoaderProps extends SpaceProps {
  size?: string;
  position?: 'center' | 'left';
  isLoading: boolean;
  children?: React.ReactNode;
  height?: string;
}

export const Loader = ({
  size,
  position = 'center',
  isLoading,
  height,
  children,
  ...rest
}: LoaderProps) => {
  const baseColor = getOrgTypeTheme('blue');

  return (
    <>
      {position === 'center' &&
        (isLoading ? (
          <Center width="full" {...rest} h={height}>
            <Spinner size={size} color={`${baseColor}.500`} />
          </Center>
        ) : (
          <>{children}</>
        ))}

      {position === 'left' && (
        <>
          {isLoading ? (
            <Spinner size={size} color={`${baseColor}.500`} {...rest} />
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </>
  );
};

Loader.defaultProps = {
  size: 'xl',
};
