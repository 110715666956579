import { Accommodation } from '@modules/Universities/accommodations/entities';
import { Entity, Of } from 'entity-of';

import { Study } from './Study';
import { Warning } from './Warning';
@Entity
export class StudentAccommodation {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  firstName: string = '';

  @Of(() => String)
  lastName: string = '';

  @Of(() => String)
  nameInitials: string = '';

  @Of(() => Number)
  socialNumber: number = 0;

  @Of(() => String)
  birthdate: string = '';

  @Of(() => String)
  gender: string = '';

  @Of(() => String)
  country: string = '';

  @Of(() => String)
  citizenship: string = '';

  @Of(() => String)
  county: string = '';

  @Of(() => String)
  city: string = '';

  @Of(() => String)
  address: string = '';

  @Of(() => String)
  phoneNumber: string = '';

  @Of(() => String)
  carNumber: string = '';

  @Of(() => String)
  emailAddress: string = '';

  @Of(() => String)
  personalEmailAddress: string = '';

  @Of(() => String)
  bankName: string = '';

  @Of(() => String)
  bankIban: string = '';

  @Of(() => String)
  bankCity: string = '';

  @Of(() => String)
  bankAccountHolderName: string = '';

  @Of(() => String)
  identityDocumentType: string = '';

  @Of(() => String)
  identityDocumentSeries: string = '';

  @Of(() => String)
  identityDocumentNumber: string = '';

  @Of(() => String)
  identityDocumentIssuedBy: string = '';

  @Of(() => String)
  identityDocumentIssuedDate: string = '';

  @Of(() => String)
  identityDocumentExpiryDate: string = '';

  @Of(() => Boolean)
  isMarried: boolean = false;

  @Of(() => [Study])
  studies: Study[] = [];

  @Of(() => [Warning])
  warnings: Warning[] = [];

  @Of(() => Number, { nullable: true })
  sold: number | null = null;

  @Of(() => Accommodation, { nullable: true })
  accommodation: Accommodation | null = null;

  static of = Entity.of<StudentAccommodation>();
}
