import { Type } from 'class-transformer';
import { Entity, Of } from 'entity-of';

import { RecurrenceInput } from './RecurrenceInput';

@Entity
export class QuestionnairePatchInput {
  @Of(() => Number, { optional: true })
  id?: number;

  @Of(() => Number, { optional: true })
  questionnaireId?: number;

  @Of(() => String, { optional: true })
  title?: string;

  @Of(() => [String], { optional: true })
  fillers?: string[];

  @Of(() => Boolean, { optional: true })
  isAnonymousAnswer?: boolean;

  @Of(() => Number, { optional: true })
  showSignatories?: Number;

  @Type(() => Viewers)
  @Of(() => Viewers, { optional: true })
  viewers?: Viewers = Viewers.of({});

  @Type(() => RecurrenceInput)
  @Of(() => RecurrenceInput, { optional: true })
  recurrence?: RecurrenceInput = RecurrenceInput.of({});

  @Of(() => String, { optional: true })
  publishResultsType?: string;

  @Of(() => Number, { optional: true })
  timeLimit?: number;

  static of = Entity.of<QuestionnairePatchInput>();
}

@Entity
class Viewers {
  @Of(() => Boolean, { optional: true })
  students?: boolean;

  @Of(() => Boolean, { optional: true })
  teachers?: boolean;

  @Of(() => Boolean, { optional: true })
  parents?: boolean;

  @Of(() => [String], { optional: true })
  others?: string[];

  @Of(() => Boolean, { optional: true })
  everyone?: boolean;

  static of = Entity.of<Viewers>();
}
