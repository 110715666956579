import { Entity, Of } from 'entity-of';

@Entity
export class Faculty {
  @Of(() => String)
  aID = '';

  @Of(() => String)
  aNume = '';

  @Of(() => String)
  aCoverUrl = '';

  @Of(() => String)
  aLogoUrl = '';

  @Of(() => String)
  aSpecializari = '';

  @Of(() => String)
  aDescriere = '';

  @Of(() => String)
  aContinut = '';

  @Of(() => String)
  aProceduraAdmitere = '';

  @Of(() => String)
  aDurataAni = '';

  @Of(() => String)
  aActeInscriere = '';

  @Of(() => String)
  aAdresa = '';

  @Of(() => String)
  aContactEmail = '';

  @Of(() => String)
  aContactFax = '';

  @Of(() => String)
  aContactFb = '';

  @Of(() => String)
  aContactFbTitle = '';

  @Of(() => String)
  aContactTel = '';

  @Of(() => String)
  aContactWebsite = '';

  @Of(() => String)
  aOrgId = '';

  @Of(() => String)
  isPromoted: '0' | '1' = '0';

  @Of(() => aiInfo)
  aiInfo = aiInfo.of({});

  static of = Entity.of<Faculty>();
}

@Entity
class aiInfo {
  @Of(() => String)
  aiID: string = '';

  @Of(() => String)
  aiNume: string = '';

  @Of(() => String)
  aitNume = '';

  @Of(() => aoInfo)
  aoInfo = aoInfo.of({});

  static of = Entity.of<aiInfo>();
}

@Entity
class aoInfo {
  @Of(() => String)
  aoID = '';

  @Of(() => String)
  aoNume = '';

  static of = Entity.of<aoInfo>();
}

@Entity
export class PaginatedFacultiesResponse {
  @Of(() => Faculty)
  data: Faculty[] = [];

  @Of(() => PaginatedFacultyResponseMeta)
  meta = PaginatedFacultyResponseMeta.of({});

  static of = Entity.of<PaginatedFacultiesResponse>();
}

@Entity
class PaginatedFacultyResponseMeta {
  @Of(() => Number)
  countData: number = 0;

  @Of(() => Number)
  countOffset: number = 0;

  @Of(() => Number)
  countTotal: number = 0;

  static of = Entity.of<PaginatedFacultyResponseMeta>();
}

@Entity
export class PaginatedFacultyInput {
  @Of(() => Number, { optional: true })
  offset?: number = 0;

  @Of(() => Number, { optional: true })
  limit?: number = 0;

  @Of(() => String, { optional: true })
  searchTerm?: string = '';

  @Of(() => String, { optional: true })
  filterSearch?: string = '';

  @Of(() => String, { optional: true })
  aiID?: string = '';

  @Of(() => String, { optional: true })
  aiOrasID?: string = '';

  static of = Entity.of<PaginatedFacultyInput>();
}
