import { Entity, Of } from 'entity-of';

@Entity
export class WellbeingQueryInput {
  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  uaID: string = '';

  @Of(() => String)
  date: string = '';

  @Of(() => String)
  type?: string = '';

  static of = Entity.of<WellbeingQueryInput>();
}
