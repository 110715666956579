import { Entity, Of } from 'entity-of';

@Entity
export class Facility {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  group: string = '';

  static of = Entity.of<Facility>();
}
