import { Entity, Of } from 'entity-of';

@Entity
export class GetTfaOptionsInput {
  @Of(() => String)
  _method: string = '';

  @Of(() => String)
  uaUserName: string = '';

  @Of(() => String)
  uaPassword: string = '';

  static of = Entity.of<GetTfaOptionsInput>();
}
