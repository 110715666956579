import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { useStorageSession } from '@lib/utils';
import { UniversityPersonal } from '@modules/Universities/university-personal/entities';
import { AssociatedGroup } from '@modules/Universities/university-student/entities';
import { Box, Grid, GridItem, GridItemProps, Text, VStack } from '@ui2/components';
import { getOrgTypeTheme } from '@ui2/utils';

interface GridRowProps extends GridItemProps {
  label?: string;
  value?: string | number;
}

const GridRow = ({ label, value, ...props }: GridRowProps) => {
  return (
    <>
      <GridItem colSpan={1} {...props}>
        <Text color="gray.500" fontSize="sm" fontWeight="400">
          {label || ''}
        </Text>
      </GridItem>
      <GridItem colSpan={3} {...props}>
        <Text color="gray.600" fontSize="sm" fontWeight="600">
          {value || ''}
        </Text>
      </GridItem>
    </>
  );
};

interface StudentCardProps {
  group: AssociatedGroup;
}

export const StudentCard = ({ group }: StudentCardProps) => {
  const { t } = useTranslation('shared');

  const {
    academicYearName,
    currentAcademicYear,
    facultyName,
    cycleName,
    studyYearName,
    seriesName,
    domainName,
    programName,
    groupName,
    uniqueRegistrationNumber,
  } = group;

  const title =
    !currentAcademicYear && academicYearName ? `${facultyName} (${academicYearName})` : facultyName;

  const {
    universitySessionHelpers: { isManager },
  } = useStorageSession();

  return (
    <InfoCard title={title}>
      <Grid templateColumns="1fr 1fr 1fr 1fr" justifyContent="flex-start" w="full">
        <GridRow label={`${t('general.cycle')}:`} value={cycleName} />
        <GridRow label={`${t('general.study_year')}:`} value={studyYearName} />
        {seriesName !== '---' && <GridRow label={`${t('general.serial')}:`} value={seriesName} />}
        {domainName !== programName && (
          <GridRow label={`${t('general.domain')}:`} value={domainName} />
        )}
        <GridRow label={`${t('general.program')}:`} value={programName} />
        <GridRow label={`${t('general.group')}:`} value={groupName} />
        {isManager && (
          <GridRow
            label={`${t('general.registration_number')}:`}
            value={uniqueRegistrationNumber}
          />
        )}
      </Grid>
    </InfoCard>
  );
};

interface StaffCardProps {
  staffInfo: UniversityPersonal;
}

export const StaffCard = ({ staffInfo }: StaffCardProps) => {
  const { t } = useTranslation('shared');

  const {
    associatedFaculties,
    associatedDisciplines,
    leadershipFunctionId,
    professorFunction,
    professorMark,
  } = staffInfo;

  return (
    <Box pb="2">
      <Grid templateColumns="1fr 1fr 1fr 1fr" justifyContent="flex-start" w="full" mb="4">
        {!!professorMark && <GridRow label="Marca:" value={professorMark} mb="2" />}
        {leadershipFunctionId && professorFunction && (
          <GridRow label={`${t('general.function')}:`} value={professorFunction} />
        )}
      </Grid>
      {associatedFaculties?.map((faculty) => {
        const disciplines = associatedDisciplines
          .filter((discipline) => discipline.facultyId === faculty.id)
          .map((discipline) => discipline.name)
          .join(', ');

        return (
          <InfoCard key={faculty.id} title={faculty.name}>
            {disciplines && (
              <Grid templateColumns="1fr 1fr 1fr 1fr" justifyContent="flex-start" w="full">
                <GridRow label={`${t('general.disciplines')}:`} value={disciplines} />
              </Grid>
            )}
          </InfoCard>
        );
      })}
    </Box>
  );
};

interface InfoCardProps {
  title?: string;
}

export const InfoCard = ({ title, children }: PropsWithChildren<InfoCardProps>) => {
  const baseColor = getOrgTypeTheme('blue');

  return (
    <Box borderWidth="1px" borderRadius="4" borderColor="gray.200" mb="4">
      <Box borderLeftWidth="8px" borderLeftColor={`${baseColor}.500`} borderRadius="4" p="4">
        <VStack alignItems="flex-start" gap="4">
          <Text fontSize="md" color="gray.700" fontWeight="600">
            {title || ''}
          </Text>
          {children}
        </VStack>
      </Box>
    </Box>
  );
};
