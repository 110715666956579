import { Entity, Of } from 'entity-of';

@Entity
export class ProgramCyclesInput {
  @Of(() => Number)
  facultyId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  static of = Entity.of<ProgramCyclesInput>();
}
