import { IsNotEmpty, IsUrl } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class LinkInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.url.required' })
  @IsUrl({ require_protocol: false }, { message: 'errors.url.invalid' })
  url: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.text.required' })
  text: string = '';

  static of = Entity.of<LinkInput>();
}
