import { Entity, Of } from 'entity-of';

import { AggregatedCharge } from '../AggregatedCharge';
import { TenantSeriesNumber } from '../TenantSeriesNumber';

import { EfacturaIssueType, SpvActiveType } from './TenantBillingProfile';

@Entity
export class BillingProfileWithCharges {
  @Of(() => Number)
  id = 0;

  @Of(() => String)
  name = '';

  @Of(() => String)
  alias = '';

  @Of(() => Boolean)
  vatPayer = false;

  @Of(() => String)
  fiscalCode = '';

  @Of(() => String)
  street = '';

  @Of(() => String)
  zipCode = '';

  @Of(() => String)
  country = '';

  @Of(() => String)
  countryId: string = '';

  @Of(() => String)
  countyId: string = '';

  @Of(() => String, { optional: true })
  cityId?: string;

  @Of(() => Boolean, { optional: true })
  disabled = false;

  @Of(() => String)
  county = '';

  @Of(() => String)
  city = '';

  @Of(() => String)
  email = '';

  @Of(() => String)
  phone = '';

  @Of(() => String)
  bankName = '';

  @Of(() => String)
  iban = '';

  @Of(() => [TenantSeriesNumber], { optional: true })
  invoiceSeries?: TenantSeriesNumber[];

  @Of(() => [TenantSeriesNumber], { optional: true })
  proformaSeries?: TenantSeriesNumber[];

  @Of(() => String)
  currency = '';

  @Of(() => String)
  invoiceDay = '';

  @Of(() => Number)
  invoiceDueAfter = 0;

  @Of(() => String)
  otherInformation = '';

  @Of(() => Number, { optional: true })
  penaltyPercentage: number = 0;

  @Of(() => Boolean)
  isDefault = false;

  @Of(() => Boolean)
  acceptsOnlinePayments = false;

  @Of(() => Boolean)
  displayBankOnInvoice = false;

  @Of(() => Boolean)
  isOnBoardingDone = false;

  @Of(() => String, { optional: true })
  firstName? = '';

  @Of(() => String, { optional: true })
  lastName? = '';

  @Of(() => String, { optional: true })
  nrJ?: string;

  @Of(() => String, { optional: true })
  capital?: string;

  @Of(() => Boolean)
  applyProformaPenalties: boolean = false;

  @Of(() => String)
  defaultInvoiceStatus = '';

  @Of(() => [AggregatedCharge])
  charges: AggregatedCharge[] = [];

  @Of(() => Boolean)
  eFacturaActive: boolean = false;

  @Of(() => SpvActiveType, { optional: true })
  spvActive?: SpvActiveType;

  @Of(() => EfacturaIssueType, { optional: true })
  eFacturaIssueType?: EfacturaIssueType;

  static of = Entity.of<BillingProfileWithCharges>();
}
