import { UniSimpleQueryInput } from '@modules/Universities/shared-universities/entities/UniSimpleQueryInput';
import { Entity, Of } from 'entity-of';

export enum AcademicStructureType {
  FACULTATE = 'FACULTATE',
  CICLU = 'CICLU',
  AN = 'AN',
  SERIE = 'SERIE',
  GRUPA = 'GRUPA',
}

@Entity
export class AcademicStructureInput extends UniSimpleQueryInput {
  @Of(() => String, { optional: true })
  type?: AcademicStructureType;

  static of = Entity.of<AcademicStructureInput>();
}
