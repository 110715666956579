import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageItem } from '@api/core/entities';

import { LangInput, LangInputProps } from '../../LangInput';
import { FormElement, FormElementProps } from '../FormElement';

export interface FormLangInputProps extends FormElementProps<LangInputProps> {
  languages?: LanguageItem[];
  showBackButton?: boolean;
}

export const FormLangInput = forwardRef<HTMLInputElement, FormLangInputProps>(
  (
    { testId, name, label, isRequired, helperText, showHelperText, errorBehaviour, ...props },
    ref
  ) => {
    const { t } = useTranslation('shared');

    return (
      <FormElement
        {...{ name, label, isRequired, helperText, showHelperText, errorBehaviour, testId }}
      >
        <LangInput ref={ref} placeholder={t('general.type_here_ellipsis') ?? ''} {...props} />
      </FormElement>
    );
  }
);
