import { Entity, Of } from 'entity-of';

@Entity
export class PortfolioDocumentEditInput {
  @Of(() => String)
  studentId: string = '';

  @Of(() => String)
  categoryID: string = '';

  @Of(() => String)
  documentId: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  date: string = '';

  static of = Entity.of<PortfolioDocumentEditInput>();
}
