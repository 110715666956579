import { Entity, Of } from 'entity-of';

@Entity
export class RepartitionStats {
  @Of(() => String)
  checkOutOverdue: string = '0';

  @Of(() => String)
  checkedIn: string = '0';

  @Of(() => String)
  request: string = '0';

  @Of(() => String)
  reserved: string = '0';

  @Of(() => String)
  transfer: string = '0';

  static of = Entity.of<RepartitionStats>();
}

export const getStatsWeight = (stat: keyof RepartitionStats) => {
  switch (stat) {
    case 'checkOutOverdue':
      return 4;
    case 'checkedIn':
      return 2;
    case 'request':
      return 1;
    case 'reserved':
      return 5;
    case 'transfer':
      return 3;
  }
};
