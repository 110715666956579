import { IsStrongPassword, MatchesProperty } from '@lib/utils';
import { Equals, IsEmail, IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class CandidateRegisterInput {
  @IsEmail(undefined, { message: 'errors.email.isValid' })
  @IsNotEmpty({ message: 'errors.email.required' })
  @Of(() => String)
  uaUserName: string = '';

  @IsStrongPassword()
  @IsNotEmpty({ message: 'errors.uaPassword.required' })
  @Of(() => String)
  uaPassword: string = '';

  @MatchesProperty('uaPassword', { message: 'errors.uaPassword.match' })
  @IsNotEmpty({ message: 'errors.uaPassword.required' })
  @Of(() => String)
  uaPassword2: string = '';

  @IsNotEmpty({ message: 'errors.value.required' })
  @Of(() => String)
  lastName: string = '';

  @IsNotEmpty({ message: 'errors.value.required' })
  @Of(() => String)
  firstName: string = '';

  @Of(() => Boolean)
  uaNewsletter: boolean = false;

  @Equals(true, { message: ' ' })
  @Of(() => Boolean)
  uaTos: boolean = false;

  @Of(() => Boolean)
  uaPrivacy: boolean = false;

  @IsNotEmpty({ message: 'errors.recaptcha.required' })
  @Of(() => String)
  recaptcha: string = '';

  static of = Entity.of<CandidateRegisterInput>();
}
