import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useSchoolsQuery } from '@api/core/endpoints';
import { School } from '@api/core/entities';
import { TYPE_ADMIN, TYPE_PERS_INST } from '@constants';
import { useRouter } from '@lib/router';
import { normalizeString, useStorageSession } from '@lib/utils';
import { getDefaultUrlFromProfile } from '@modules/Site/components/redirect-home/getDefaultUrl';
import { AdservioIcons, Box, Divider, IconButton, InputGroup, Menu } from '@ui2/components';
import debounce from 'lodash/debounce';
import { sessionSwitchLiceu } from 'Redux/SessionActions';

import { OrganisationDetails } from './components/OrganisationDetails';
import {
  FullpageLoader,
  MenuItemDivider,
  MenuListWrapper,
  NoOrganisationsResults,
  OrganisationsMenuButton,
  OrganisationsMenuItem,
  SearchBarDisclaimer,
  SkeletonMenuItems,
} from './OrganisationsSearch.layout';

const AdminRefreshUrls = ['admin/elevi/cauta', 'admin/cadre/cauta', 'messages', 'files'];

const InstitutionRefreshUrls = [
  'institutions/rankings_students',
  'institutions/rankings_grades',
  'institutions/rankings_attendance',
  'institutions/rankings_classes',
  'messages',
];

interface OrganisationsSearchProps {
  isPHP?: boolean;
}

export const OrganisationsSearch = ({ isPHP }: OrganisationsSearchProps) => {
  const dispatch = useDispatch();
  const { lang, location, queryString } = useRouter();
  const { t } = useTranslation(['Site', 'common']);
  const { profile, school, schoolConfig } = useStorageSession();

  const UrlRemap = {
    [`/${lang}/messages`]: [
      'messages/received',
      'messages/sent',
      'messages/send',
      'messages/archive',
    ],
  };

  const lastSearchesKey = `lastSearches_uaID_${profile.uaID}`;

  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [lastSearches, setLastSearches] = useState<School[]>(
    JSON.parse(localStorage.getItem(lastSearchesKey) || '[]')
  );

  const inputRef = useRef<HTMLInputElement>(null);

  const { data: schools, isLoading } = useSchoolsQuery(
    { search: normalizeString(searchTerm) },
    {
      enabled: !!searchTerm,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setTimeout(() => {
          inputRef.current?.focus();
        }, 100);
      },
    }
  );

  const handleChange = useMemo(
    () => (value: string) => {
      setSearchValue(value);
      handleSearch(value);
    },
    []
  );

  const handleSearch = debounce((value: string) => {
    if (profile.uaTip === TYPE_ADMIN || value.length > 2) {
      setSearchTerm(value);
    }
  }, 500);

  const handleClear = () => {
    setSearchTerm('');
    setSearchValue('');
    focusOnSearch();
  };

  const handleSchoolChange = (school: Partial<School>) => {
    if (school.liceuNume) {
      const last: School[] = JSON.parse(localStorage.getItem(lastSearchesKey) || '[]');

      const index = last.findIndex((s) => s.liceuID === school.liceuID);

      if (index === -1) {
        last.unshift(School.of(school));
      } else {
        last.splice(index, 1);
        last.unshift(School.of(school));
      }

      if (last.length > 6) {
        last.pop();
      }

      localStorage.setItem(lastSearchesKey, JSON.stringify(last));
      setLastSearches(last);
    }

    if (isPHP) {
      setShowLoader(true);
    }

    let redirectUrl = getDefaultUrlFromProfile(profile, schoolConfig);

    if (profile.uaTip === TYPE_ADMIN) {
      redirectUrl = `${location.pathname}?liceuID=${school.liceuID}`;

      AdminRefreshUrls.forEach((url) => {
        if (location.pathname.includes(url)) {
          redirectUrl = location.pathname;
        }
      });
    }

    if (profile.uaTip === TYPE_PERS_INST) {
      InstitutionRefreshUrls.forEach((url) => {
        if (location.pathname.includes(url)) {
          redirectUrl = location.pathname;
        }
      });
    }

    Object.keys(UrlRemap).forEach((key) => {
      UrlRemap[key].forEach((url) => {
        if (location.pathname.includes(url)) {
          redirectUrl = key;
        }
      });
    });

    dispatch(sessionSwitchLiceu(String(school.liceuID), redirectUrl));
  };

  const focusOnSearch = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  useEffect(() => {
    if (queryString.get('_useliceuID')) {
      setTimeout(() => {
        handleSchoolChange({ liceuID: queryString.get('_useliceuID') || '' });
      }, 0);
    }
  }, []);

  return (
    <>
      <Menu colorScheme="gray" placement="bottom-end" onOpen={() => focusOnSearch()}>
        <OrganisationsMenuButton />

        <MenuListWrapper>
          <Box mb="4" display={{ base: 'block', md: 'none' }}>
            <OrganisationDetails school={school} />

            <Divider mt="2" borderColor="gray.200" />
          </Box>

          <InputGroup
            ref={inputRef}
            leftElement={<AdservioIcons.FiSearch />}
            rightElement={
              searchValue && (
                <IconButton
                  variant="ghost"
                  size="sm"
                  colorScheme="gray"
                  aria-label="clear"
                  icon={<AdservioIcons.FiX />}
                  onClick={handleClear}
                />
              )
            }
            borderColor="gray.200"
            placeholder={t('header.search_here')}
            value={searchValue}
            onKeyDown={(event: any) => {
              if (event.key === 'ArrowDown') {
                const item = document.querySelector('.organisations-search-menu-item');

                if (item) {
                  (item as HTMLElement).focus();
                }
              }
            }}
            onChange={(event: any) => handleChange(event.target.value)}
          />

          {isLoading && <SkeletonMenuItems />}

          {!isLoading && !schools && (!lastSearches?.length || lastSearches?.length === 1) && (
            <SearchBarDisclaimer />
          )}

          {!isLoading && Number(schools?.meta?.countTotal) === 0 && <NoOrganisationsResults />}

          {!isLoading && (
            <Box mt="2">
              {!schools &&
                lastSearches
                  ?.filter((s) => s.liceuID !== school.liceuID)
                  .map((s, index) => {
                    return (
                      <React.Fragment key={index}>
                        <OrganisationsMenuItem onClick={() => handleSchoolChange(s)}>
                          <OrganisationDetails school={s} isHistory />
                        </OrganisationsMenuItem>

                        {index < lastSearches?.length - 2 && <MenuItemDivider />}
                      </React.Fragment>
                    );
                  })}

              {schools?.data.map((s, index) => {
                return (
                  <React.Fragment key={index}>
                    <OrganisationsMenuItem onClick={() => handleSchoolChange(s)}>
                      <OrganisationDetails school={s} />
                    </OrganisationsMenuItem>

                    {index < schools?.data?.length - 1 && <MenuItemDivider />}
                  </React.Fragment>
                );
              })}
            </Box>
          )}
        </MenuListWrapper>
      </Menu>

      {showLoader && <FullpageLoader />}
    </>
  );
};
