/* eslint-disable no-unused-vars */
import { PERM_BILLING_CLIENT, PERM_BILLING_MANAGER } from '../../../constants';

enum AdservioUserType {
  STUDENT = '1',
  PARENT = '2',
  SCHOOL_STAFF = '3',
  ADMIN = '4',
  INSTITUTION_STAFF = '5',
  APP = '6',
}

export const BillingPermissions = {
  BillingClient: PERM_BILLING_CLIENT,
  BillingManager: PERM_BILLING_MANAGER,
};

export const AdservioUserTypeDict: Record<string, AdservioUserType> = {
  '1': AdservioUserType.STUDENT,
  '2': AdservioUserType.PARENT,
  '3': AdservioUserType.SCHOOL_STAFF,
  '4': AdservioUserType.ADMIN,
  '5': AdservioUserType.APP,
};

export enum BillingUserType {
  ADMIN = 'Billing::Admin',
  SYSTEM = 'Billing::System',
  USER = 'Billing::User',
}

export const mapLegacyUserTypes = (userType: AdservioUserType): BillingUserType[] => {
  switch (userType) {
    case AdservioUserType.STUDENT:
    case AdservioUserType.PARENT:
    case AdservioUserType.SCHOOL_STAFF:
      return [BillingUserType.USER];
    case AdservioUserType.ADMIN:
      return [BillingUserType.ADMIN];
    case AdservioUserType.APP:
      return [BillingUserType.SYSTEM];
    default:
      return [];
  }
};
