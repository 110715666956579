import { Entity, Of } from 'entity-of';

import { Period } from '../Period';

@Entity
export class SchoolYear {
  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  ascNume: string = '';

  @Of(() => String)
  ascIsActiv: string = '';

  @Of(() => String)
  ascNrOrdine: string = '';

  @Of(() => String)
  ascDataStart: string = '';

  @Of(() => String)
  ascDataEnd: string = '';

  @Of(() => String)
  ascLastUpdate: string = '';

  @Of(() => [Period])
  semestre: Period[] = [];

  static of = Entity.of<SchoolYear>();
}
