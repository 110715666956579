import { useEffect, useRef, useState } from 'react';

import { PaymentInfo, PaymentProvider } from '@api/billing/entities';
import { Button, Input } from '@ui2/components';

interface PaymentFormProps {
  paymentInfo?: PaymentInfo;
}

export const PaymentForm = ({ paymentInfo }: PaymentFormProps) => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const extraInfo = paymentInfo?.extraInfo;
  const [formData, setFormData] = useState<Record<string, any>>();
  const paymentUrl = paymentInfo?.paymentUrl;
  const provider = paymentInfo?.provider;
  const enabledForm = formData && paymentUrl && provider === PaymentProvider.NETOPIA;

  const handlePaymentFormSubmit = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setFormData(extraInfo);
  }, [extraInfo]);

  useEffect(() => {
    if (enabledForm && formRef.current) {
      formRef.current.submit();
      setFormData(undefined);
    }
  }, [enabledForm, formRef.current]);

  return (
    <>
      {enabledForm && (
        <form
          hidden
          ref={formRef}
          method="post"
          name="formPaymentRedirect"
          action={paymentUrl}
          onSubmit={handlePaymentFormSubmit}
        >
          <Input id="env_key" name="env_key" value={formData?.env_key} />
          <Input id="data" name="data" value={formData?.data} />

          <Button type="submit" value="pay" />
        </form>
      )}
    </>
  );
};
