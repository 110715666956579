import { IsNotEmpty, Min } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class ChargeEditInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.serviceName.required' })
  name: string = '';

  @Of(() => Number, { nullable: true })
  @IsNotEmpty({ message: 'errors.unitValue.required' })
  value: number | null = null;

  @Of(() => Number, { nullable: true })
  @Min(1, { message: 'errors.quantity.min' })
  @IsNotEmpty({ message: 'errors.quantity.required' })
  quantity: number | null = null;

  @Of(() => String, { optional: true, nullable: true })
  details?: string | null;

  static of = Entity.of<ChargeEditInput>();
}
