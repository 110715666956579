// @ts-nocheck
import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';

export const allowedLanguages = ['en', 'ro', 'fr', 'hu', 'nl'];

export function useLang() {
  return i18n.options.lng;
}

export function useUriLang(defaultLang: string) {
  const { pathname } = window.location;

  // eslint-disable-next-line no-unused-vars
  const [_, lang] = pathname.split('/');
  const match = lang.match(/^([a-z]{2})$/);

  return match ? match[0] : defaultLang;
}

export const getDefaultLanguage = (prefLangID: string) => {
  const uriLang = useUriLang();

  if (uriLang && allowedLanguages.indexOf(uriLang) !== -1) {
    return uriLang;
  }

  return prefLangID || (window.location.hostname.includes('education') ? 'en' : 'ro');
};

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      loadPath: '/locales/{lng}/{ns}.json',
    },
    react: {
      useSuspense: true,
    },
    compatibilityJSON: 'v3',
    ns: ['common', 'validation'],
    defaultNS: 'common',
    otherLanguages: allowedLanguages,
    fallbackLng: useUriLang(getDefaultLanguage()),
    lng: useUriLang(getDefaultLanguage()),
    interpolation: {
      escapeValue: false,
      prefix: '{',
      suffix: '}',
    },
  });

export default i18n;
