import { PropsWithChildren } from 'react';

import { useStorageSession } from '@lib/utils';
import {
  AdservioIcons,
  Box,
  Button,
  Divider,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
} from '@ui2/components';
import { useBreakpointValue } from '@ui2/hooks';

import { ProfileDetails } from '../ProfileDetails';

interface UserMenuItemProps extends MenuItemProps {
  isActive?: boolean;
}

export const UserMenuItem = ({
  isActive,
  children,
  ...props
}: PropsWithChildren<UserMenuItemProps>) => {
  return (
    <MenuItem
      iconSpacing="1rem"
      fontWeight="600"
      lineHeight="6"
      borderRadius="lg"
      px="2"
      py="1"
      _focus={{ bg: isActive ? 'gray.100' : 'gray.200' }}
      _hover={{ bg: isActive ? 'gray.100' : 'gray.50' }}
      _active={{ bg: isActive ? 'gray.100' : 'gray.100' }}
      bg={isActive ? 'gray.100' : 'transparent'}
      cursor={isActive ? 'default' : 'pointer'}
      {...props}
    >
      {children}
    </MenuItem>
  );
};

interface MenuItemDividerProps {
  isAccountDivider?: boolean;
}

export const MenuItemDivider = ({ isAccountDivider }: MenuItemDividerProps) => {
  return (
    <Box p="2">
      <Divider borderColor={isAccountDivider ? 'gray.100' : 'gray.200'} />
    </Box>
  );
};

export const UserMenuButton = () => {
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const { profile, student } = useStorageSession();

  return (
    <MenuButton
      minWidth={isMobile ? 'initial' : '200px'}
      as={Button}
      rightIcon={isMobile ? undefined : <AdservioIcons.FiChevronDown />}
      size="lg"
      variant="ghost"
      colorScheme="gray"
      py="1"
      px="2"
    >
      <ProfileDetails
        hideDetailsOnMobile
        size="sm"
        profile={{ ...profile, institutie: profile?.institutie }}
        student={student}
      />
    </MenuButton>
  );
};

export const MenuListWrapper = ({ children }: PropsWithChildren<{}>) => {
  return (
    <MenuList
      minWidth="200px"
      maxWidth={{ base: '100vw', sm: '340px' }}
      overflowY="auto"
      p="2"
      border="1px solid"
      borderColor="gray.200"
      boxShadow="3xl"
    >
      {children}
    </MenuList>
  );
};
