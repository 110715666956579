import { Person } from '@api/core/entities';
import { Type } from 'class-transformer';
import { IsArray, ValidateNested } from 'class-validator';
import { Entity, Of } from 'entity-of';

import 'reflect-metadata';

import { CertificateFields } from '../Certificate';

@Entity
export class CertificateTemplate {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => CertificateTemplateData)
  @Of(() => CertificateTemplateData)
  data: CertificateTemplateData[] = [];

  static of = Entity.of<CertificateTemplate>();
}

@Entity
export class CertificateTemplateData {
  @Of(() => String)
  cerUaID: string = '';

  @Of(() => String)
  cerClID: string = '';

  @Of(() => Boolean, { optional: true })
  isDuplicate?: boolean = false;

  @Of(() => Object, { optional: true })
  errors?: Record<string, Errors[]> = {};

  @ValidateNested()
  @Type(() => CertificateFields)
  @Of(() => CertificateFields)
  fields: CertificateFields = CertificateFields.of({});

  @Of(() => Person)
  student: Person = Person.of({});

  static of = Entity.of<CertificateTemplateData>();
}

@Entity
export class Errors {
  @Of(() => String)
  errorCode: string = '';

  @Of(() => String)
  errorField: string = '';

  @Of(() => String)
  errorMessage: string = '';

  @Of(() => String, { optional: true })
  errorExtraValue: any;

  static of = Entity.of<Errors>();
}
