import { Entity, Of } from 'entity-of';

@Entity
export class StudentPortfolioCategoryDeletePayload {
  @Of(() => String)
  targetCategoryID!: string;

  @Of(() => String)
  categoryID!: string;

  static of = Entity.of<StudentPortfolioCategoryDeletePayload>();
}
