import { Entity, Of } from 'entity-of';

@Entity
export class OverrideSearchInput {
  @Of(() => String)
  id = '';

  @Of(() => String)
  class_id = '';

  @Of(() => String)
  elev_id? = '';

  @Of(() => String)
  mat_id? = '';

  @Of(() => String)
  san_id? = '';

  @Of(() => String)
  type? = '';

  @Of(() => Number)
  offset = 0;

  @Of(() => Number)
  limit = 10;

  @Of(() => String)
  order = 'asc';

  @Of(() => String)
  orderBy = 'name';

  static of = Entity.of<OverrideSearchInput>();
}
