import React, { PropsWithChildren } from 'react';

import { useRouter } from '@lib/router';
import { AdservioIcons, Box, IconButton, Text } from '@ui2/components';

interface BackButtonProps {
  backUrl: string;
}

export const BackButton = ({ backUrl }: BackButtonProps) => {
  const { push } = useRouter();

  return (
    <IconButton
      size="md"
      variant="ghost"
      colorScheme="gray"
      aria-label="back"
      icon={<AdservioIcons.FiArrowLeft boxSize="1.5rem" />}
      mr="4"
      onClick={() => push(backUrl)}
    />
  );
};

interface PanelButtonProps {
  isActive: boolean;
  onClick: () => void;
}

export const PrevPanelButton = ({ isActive, onClick }: PanelButtonProps) => {
  return (
    <IconButton
      variant="ghost"
      colorScheme="gray"
      icon={<AdservioIcons.FiChevronLeft boxSize="1.5rem" />}
      aria-label="Left"
      visibility={isActive ? 'visible' : 'hidden'}
      onClick={isActive ? onClick : undefined}
    />
  );
};

export const NextPanelButton = ({ isActive, onClick }: PanelButtonProps) => {
  return (
    <IconButton
      variant="ghost"
      colorScheme="gray"
      icon={<AdservioIcons.FiChevronRight boxSize="1.5rem" />}
      aria-label="Left"
      visibility={isActive ? 'visible' : 'hidden'}
      onClick={isActive ? onClick : undefined}
    />
  );
};

interface PanelBulletProps {
  isActive: boolean;
}

export const PanelBullet = ({ isActive }: PanelBulletProps) => {
  return (
    <Box
      width="0.5rem"
      height="0.5rem"
      borderRadius="full"
      backgroundColor={isActive ? 'gray.500' : 'gray.200'}
    />
  );
};

interface TextProps {
  isModal?: boolean;
}

export const Title = ({ isModal, children }: PropsWithChildren<TextProps>) => {
  return (
    <Text
      mb="1"
      textAlign="center"
      fontSize={{ base: 'xl', md: isModal ? 'xl' : '2xl' }}
      fontWeight="bold"
    >
      {children}
    </Text>
  );
};

export const Description = ({ isModal, children }: PropsWithChildren<TextProps>) => {
  return (
    <Text
      mb="8"
      textAlign="center"
      fontSize={{ base: 'md', md: isModal ? 'md' : 'lg' }}
      color="gray.400"
    >
      {children}
    </Text>
  );
};
