import { Entity, Of } from 'entity-of';

@Entity
export class TeacherYears {
  @Of(() => String)
  ascID = '';

  @Of(() => String)
  ascNume = '';

  @Of(() => String)
  ascIsActiv = '';

  @Of(() => String)
  ascDataStart = '';

  @Of(() => String)
  ascDataEnd = '';

  static of = Entity.of<TeacherYears>();
}
