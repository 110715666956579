import { Entity, Of } from 'entity-of';

@Entity
export class SchoolUpdateInput {
  @Of(() => String)
  liceuID: string = '';

  @Of(() => String)
  liceuNume: string = '';

  @Of(() => String)
  liceuClasificare: string = '';

  @Of(() => String)
  liceuTip: string = '';

  @Of(() => String)
  liceuCityID: string = '';

  @Of(() => String)
  liceuAdresa: string = '';

  @Of(() => String)
  liceuEmail: string = '';

  @Of(() => String)
  liceuTel: string = '';

  @Of(() => String)
  liceuCUI: string = '';

  @Of(() => String)
  liceuCodSirues: string = '';

  @Of(() => String)
  liceuCodSiiir: string = '';

  @Of(() => String)
  liceuDirectorID: string = '';

  @Of(() => String)
  liceuSecretarID: string = '';

  @Of(() => String)
  liceuVocational: string = '';

  @Of(() => String)
  liceuTehnic: string = '';

  @Of(() => String)
  liceuLogo: string = '';

  @Of(() => String)
  liceuIsActive: string = '';

  static of = Entity.of<SchoolUpdateInput>();
}

@Entity
export class SchoolUpdateInputAdmin extends SchoolUpdateInput {
  @Of(() => String, { optional: true })
  liceuObservatii?: string;

  @Of(() => String, { optional: true })
  liceuDefaultLanguage?: string;

  @Of(() => String, { optional: true })
  liceuIsHidden?: string;

  @Of(() => [String], { optional: true })
  labels?: string[];

  @Of(() => String)
  liceuIsActive: string = '';

  static of = Entity.of<SchoolUpdateInputAdmin>();
}
