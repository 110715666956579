import { useTranslation } from 'react-i18next';

import {
  AccountBillingProfile,
  OrganisationType,
  TenantBillingProfile,
} from '@api/billing/entities';
import { useCountiesQuery } from '@api/core/endpoints';
import { useRouter } from '@lib/router';
import { formattedAccountBillingProfileAddress } from '@modules/billing/domains/shared/helpers';
import { countries } from '@modules/shared/utils';
import { Badge, Box, Flex, HStack, Text } from '@ui2/components';
import { getOrgTypeTheme } from '@ui2/utils';

interface BillingAccountProfileRadioProps {
  profile: AccountBillingProfile;
  isChecked?: boolean;
}

export const BillingAccountProfileRadio = ({
  profile,
  isChecked,
}: BillingAccountProfileRadioProps) => {
  const { t } = useTranslation('billing');
  const { lang } = useRouter();
  const baseColor = getOrgTypeTheme('blue');

  const { data: countiesData } = useCountiesQuery(
    { taraCod: String(profile?.country) },
    { enabled: !!profile?.country }
  );

  const { name, fiscalInfo, street, address } = formattedAccountBillingProfileAddress(
    AccountBillingProfile.of(profile || {}),
    lang,
    countiesData?.data
  );

  const profileFullAddress = [street, address].filter((f) => Boolean(f)).join(', ');

  return (
    <Flex
      flexDirection={{ base: 'column', sm: 'row' }}
      justifyContent={{ base: 'flex-start', sm: 'space-between' }}
      alignItems={{ base: 'flex-start', sm: 'center' }}
      w="full"
    >
      <Box order={{ base: 2, sm: 1 }}>
        <Text fontWeight="semibold" color={isChecked ? `${baseColor}.800` : 'gray.700'}>
          {name}
        </Text>

        {profile.isJuridic && (
          <Text fontSize="sm" color={isChecked ? `${baseColor}.600` : 'gray.500'}>
            {fiscalInfo}
          </Text>
        )}

        <Text fontSize="sm" color={isChecked ? `${baseColor}.600` : 'gray.500'}>
          {profileFullAddress}
        </Text>
      </Box>

      {profile.isDefault && (
        <Badge
          colorScheme={baseColor}
          ml={{ base: 0, sm: 2 }}
          mb={{ base: 3, sm: 0 }}
          order={{ base: 1, sm: 2 }}
        >
          {t('profile.default_profile')}
        </Badge>
      )}
    </Flex>
  );
};

interface BillingTenantProfileRadioProps {
  profile: TenantBillingProfile;
  isChecked?: boolean;
}

export const BillingTenantProfileRadio = ({
  profile,
  isChecked,
}: BillingTenantProfileRadioProps) => {
  const { t } = useTranslation('billing');
  const baseColor = getOrgTypeTheme('blue');
  const { lang } = useRouter();

  const {
    name,
    alias,
    fiscalCode,
    nrJ,
    capital,
    street,
    zipCode,
    county: profileCounty,
    country,
    city,
    currency,
  } = profile;

  const { data: countiesData } = useCountiesQuery({ taraCod: String(country) });

  const county =
    countiesData?.data.find((c) => c.judCod === profileCounty)?.judNume || profileCounty;

  const selectedCountry = countries.find((c) => c.alpha2 === country.toLocaleLowerCase())?.[
    String(lang)
  ];

  const detailsArr = [street, zipCode, selectedCountry, county, city].filter(Boolean);
  const fiscalInfo = [fiscalCode, nrJ, capital].filter(Boolean).join(', ').trim();

  return (
    <Flex
      flexDirection={{ base: 'column', sm: 'row' }}
      justifyContent={{ base: 'flex-start', sm: 'space-between' }}
      alignItems={{ base: 'flex-start', sm: 'center' }}
      w="full"
    >
      <Box order={{ base: 2, sm: 1 }}>
        <Text fontWeight="semibold" color={isChecked ? `${baseColor}.800` : 'gray.700'}>
          {alias || name}
        </Text>

        <Text fontSize="sm" color={isChecked ? `${baseColor}.600` : 'gray.500'}>
          {fiscalInfo}
        </Text>

        <Text fontSize="sm" color={isChecked ? `${baseColor}.600` : 'gray.500'}>
          {detailsArr.join(', ')}
        </Text>

        <HStack gap="4">
          <Text fontSize="sm" color={isChecked ? `${baseColor}.600` : 'gray.500'} mt={4}>
            {t('general.invoice_currency')}:{' '}
            <Text
              as="span"
              fontWeight="semibold"
              color={isChecked ? `${baseColor}.600` : 'gray.500'}
            >
              {currency}
            </Text>
          </Text>

          <Text fontSize="sm" color={isChecked ? `${baseColor}.600` : 'gray.500'} mt={4}>
            {t('general.organisation_type_short')}:{' '}
            <Text
              as="span"
              fontWeight="semibold"
              color={isChecked ? `${baseColor}.600` : 'gray.500'}
            >
              {profile.organisationType === OrganisationType.NGO
                ? t('general.non_profit_organisation')
                : t('general.company')}
            </Text>
          </Text>

          <Text
            fontSize="sm"
            fontWeight="semibold"
            color={isChecked ? `${baseColor}.600` : 'gray.500'}
            mt={4}
          >
            {profile.vatPayer ? t('general.vat_payer') : t('general.non_vat_payer')}
          </Text>
        </HStack>
      </Box>

      {profile.isDefault && (
        <Badge
          colorScheme={baseColor}
          ml={{ base: 0, sm: 2 }}
          mb={{ base: 3, sm: 0 }}
          order={{ base: 1, sm: 2 }}
        >
          {t('profile.default_profile')}
        </Badge>
      )}
    </Flex>
  );
};
