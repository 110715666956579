/* eslint-disable @typescript-eslint/no-unused-vars */
import Ajv, { DefinedError } from 'ajv';
import ajvErrors from 'ajv-errors';
import { transformAndValidateSync } from 'class-transformer-validator';
import { ValidationError, ValidatorOptions } from 'class-validator';
import { ValidationErrors } from 'final-form';
import { TFunction } from 'i18next';

import { DocumentFormSchema, FieldType, FormField } from '../entities';

import { BackendError, EMPTY_ERROR } from './types';

type Class<T extends object> = { new (): T };
const ajv = new Ajv({ allErrors: true });
ajvErrors(ajv);

export const createValidator =
  <T extends object>(model: Class<T>, options?: ValidatorOptions) =>
  (data: T) => {
    try {
      transformAndValidateSync(model, data, { validator: options });

      return {};
    } catch (e: unknown) {
      return convertError(e as ValidationError[]);
    }
  };

export type Ret = { [key: string]: string | Ret };

function convertError(errors: ValidationError[]) {
  if (!errors) {
    return {};
  }

  let result = {};
  // errors[0].target holds original data from form
  // By default, result will be empty object, but if 'errors[0].target' is array, then result will be also an array,
  // and will be filled with the same number of empty objects as 'errors[0].target'
  // This was done because we needed a way to display errors in array of forms using <FieldArray /> from react-final-form
  if (Array.isArray(errors[0]?.target)) {
    result = Array((errors[0].target as []).length).fill({});
  }

  for (const error of Array.from(errors)) {
    result[error.property] = Object.values(error.constraints ?? {})[0];

    if (error?.children && error.children.length > 0) {
      result[error.property] = convertError(error.children as ValidationError[]);
    }
  }

  return result;
}

interface BackendErrors {
  fieldErrors: ValidationErrors;
  toastErrors: string[];
}

export const convertBackendErrors = (
  t: TFunction,
  errors: BackendError[] = [],
  endpoint: string,
  toastFields: string[] = []
): BackendErrors => {
  const fieldErrors = {};
  const toastErrors: string[] = [];

  errors.forEach(({ errorCode, errorField, errorExtraValue, errorExtraValues }) => {
    if (errorCode === EMPTY_ERROR) {
      fieldErrors[String(errorField)] = ' ';
      return;
    }

    let extraValue = errorExtraValue;

    if (Array.isArray(errorExtraValues)) {
      extraValue = errorExtraValues?.[0];
    }

    const errorFieldWithoutArray = getNonArrayErrorField(errorField);

    // Initialize the default key
    // If the error code is less than 100, it's a generic error
    // Otherwise, we default to checking for a formError key.
    let i18nKey =
      Number(errorCode) < 100 && !toastFields.includes(String(errorFieldWithoutArray))
        ? `generic.${errorCode}`
        : `${endpoint}.formErrors.${errorFieldWithoutArray}.${errorCode}`;

    // Get the key translation
    let translation = t(`validation:${i18nKey}`, { value: extraValue });

    // If the translation is the same as the key, it means the translation does not exist,
    // because i18next returns the key if the translation is not found
    if (i18nKey !== translation) {
      fieldErrors[String(errorField)] = translation;
    } else {
      // If the key was not found in the formErrors structure, then we try to find in in the toastErrors structure
      i18nKey = i18nKey.replace('formErrors', 'toastErrors');
      translation = t(`validation:${i18nKey}`, { value: extraValue });

      // Same check here, if the translation does not exist, or if the error code is less than 100
      // and the field is marked as a forced toastField, then we default to a generic error.
      // We default to generic toast errors for error codes less than 100, because they have no errorField context in the translation
      if (
        i18nKey === translation &&
        Number(errorCode) < 100 &&
        toastFields.includes(String(errorFieldWithoutArray))
      ) {
        console.info('Missing translation for:', {
          i18nKey,
          errorField,
          errorFieldWithoutArray,
          errorCode,
          extraValue,
        });

        translation = t('validation:generic.99');
      }

      toastErrors.push(translation);
    }
  });

  // We return the field and toast errors separately, so we can display them differently
  // either under form fields or in a toast notification
  return {
    fieldErrors,
    toastErrors,
  };
};

export const getNonArrayErrorField = (errorField: string) => {
  return errorField
    .split('.')
    .filter((element) => isNaN(Number(element)))
    .join('.');
};

export const createDynamicValidator = <T extends object>(
  schema: DocumentFormSchema,
  documentId: string
) => {
  const validationSchema = createDynamicSchema(schema, documentId);
  return (data: T) => {
    const validate = ajv.compile(validationSchema);
    if (validate(data)) {
      return {};
    } else {
      return convertDynamicError(validate.errors as DefinedError[]);
    }
  };
};

function convertDynamicError(errors: DefinedError[]) {
  const result = {};
  for (const error of errors) {
    if (error.keyword === 'required') {
      result[error.params.missingProperty] = 'errors.value.required';
    } else {
      const instancePath = error.instancePath.replace('/', '');
      if (!result[instancePath]) {
        result[instancePath] = error.message;
      }
    }
  }

  return result;
}

ajv.addKeyword({
  keyword: 'length',
  type: 'string',
  validate: function (schema: number, data: string) {
    return data.length === schema;
  },
});

ajv.addKeyword({
  keyword: 'spacesNotAllowed',
  type: 'string',
  validate: function (schema: boolean, data: string) {
    return schema ? data.trim().length > 0 : true;
  },
});

const getAjvSchema = (fields: FormField[], prefix: string = '') => {
  const properties = fields.reduce(
    (acc, curr) => {
      let type;

      switch (curr.type) {
        case FieldType.NUMBER:
          type = 'number';
          break;
        case FieldType.TOGGLE:
          type = 'boolean';
          break;
        case FieldType.MULTISELECT:
        case FieldType.CHECKBOX:
          type = 'array';
          break;
        default:
          type = 'string';
      }

      acc[`${prefix}${curr.name}`] = {
        type: [type, 'null'],
        spacesNotAllowed: type === 'string' ? true : undefined,
        length: type === 'string' ? curr.validators?.exact_length : undefined,
        minLength: type === 'string' ? curr.validators?.min_length : undefined,
        maxLength: type === 'string' ? curr.validators?.max_length : undefined,
        minimum: type === 'number' ? curr.validators?.greater_than : undefined,
        maximum: type === 'number' ? curr.validators?.less_than : undefined,
        minItems: curr.validators?.required && type === 'array' ? 1 : undefined,
        errorMessage: {
          spacesNotAllowed: `errors.spaces_not_allowed`,
          length: `errors.dynamic.length`,
          minLength: `errors.dynamic.min_length`,
          maxLength: `errors.dynamic.max_length`,
          minimum: `errors.dynamic.min`,
          maximum: `errors.dynamic.max`,
          minItems: `errors.value.required`,
          type: `errors.dynamic.invalid_value`,
        },
      };

      return acc;
    },
    {} as Record<string, unknown>
  );

  const required = fields.filter((f) => f.validators?.required).map((f) => `${prefix}${f.name}`);

  return { required, properties };
};

export const createDynamicSchema = (schema: DocumentFormSchema, documentId: string) => {
  let ajvRequired: string[] = [];
  let ajvProperties = {};

  for (const field of schema.fields) {
    const prefix =
      field.type === FieldType.FORM ? `${documentId}-${field.name}-` : `${documentId}-`;
    const formFields = field.type === FieldType.FORM ? field.formFields || [] : [field];

    const { required, properties } = getAjvSchema(formFields, prefix);

    ajvRequired = [...ajvRequired, ...required];
    ajvProperties = { ...ajvProperties, ...properties };
  }

  const ajvSchema = {
    type: 'object',
    properties: ajvProperties,
    required: ajvRequired,
  };

  return ajvSchema;
};

// Do not delete just yet
function objectToArray(obj) {
  if (Array.isArray(obj)) {
    // If it's already an array, recursively transform its elements
    return obj.map(objectToArray);
  } else if (typeof obj === 'object' && obj !== null) {
    // Check if all keys are numeric (as strings)
    const keys = Object.keys(obj);
    const allNumeric = keys.every((key) => /^\d+$/.test(key));

    if (allNumeric) {
      // If all keys are numeric, convert the object to an array
      return keys.map((key) => objectToArray(obj[key]));
    } else {
      // Otherwise, recursively transform each value
      const newObj = {};
      keys.forEach((key) => {
        newObj[key] = objectToArray(obj[key]);
      });
      return newObj;
    }
  } else {
    // If it's a primitive value, return as is
    return obj;
  }
}

export const computeRelatedErrors = (
  errors: BackendError[],
  related: Record<string, Record<string, string[]>>
) => {
  const tempRelated: Record<string, Record<string, string[]>> = {};

  errors.forEach((error: BackendError) => {
    if (related[error.errorField] && related[error.errorField][error.errorCode]) {
      related[error.errorField][error.errorCode].forEach((field) => {
        if (!errors.find((err: BackendError) => err.errorField === field)) {
          errors.push(
            BackendError.of({
              errorField: field,
            })
          );

          if (!tempRelated[field]) {
            tempRelated[field] = {};
          }

          if (tempRelated[field][error.errorCode]) {
            tempRelated[field][error.errorCode].push(error.errorField);
          } else {
            tempRelated[field] = {
              [error.errorCode]: [error.errorField],
            };
          }
        }
      });
    }
  });

  return tempRelated;
};
