import { SessionFeatures } from '@api/subscriptions/entities';
import { CONFIG_FLAGS, FEATURE_FLAGS } from '@constants';
import { Entity, Of } from 'entity-of';

import { SessionItem } from '.';

@Entity
export class AccessRules {
  @Of(() => [String], { optional: true })
  permissions?: string[];

  @Of(() => [String], { optional: true })
  uaTypes?: string[];

  @Of(() => [String], { optional: true })
  configFlags?: CONFIG_FLAGS[] = [];

  @Of(() => [String], { optional: true })
  userFeatures?: string[]; // To decide if AND or OR

  @Of(() => [String], { optional: true })
  schoolFeatures?: string[]; // To decide if AND or OR

  @Of(() => String, { optional: true })
  feature?: FEATURE_FLAGS;

  @Of(() => Boolean, { optional: true })
  extraChecks?: boolean;

  static of = Entity.of<AccessRules>();
}

// TODO: Move this to a helper something
const getCurrentClass = (state: Record<string, any>): Record<string, any> => {
  let currentCl = {};

  if (state?.clase) {
    state.clase.forEach((cl) => {
      if (cl.asc.ascIsActiv === '1') {
        currentCl = cl;
      }
    });

    if (Object.keys(currentCl).length === 0) {
      currentCl = state.clase[0];
    }
  }

  return currentCl;
};

export const getConfigFlagValue = (session: Record<string, any>, flag: CONFIG_FLAGS) => {
  if (Object.keys(session?.schoolConfig || {}).length === 0) {
    session.schoolConfig = {};
  }

  const map: Record<CONFIG_FLAGS, boolean> = {
    ACCOMMODATION_ACTIVE: session?.schoolConfig.accommodation?.isActive === '1',
    SCHOOL_STAFF_ACTIVITIES_ACTIVE: session?.schoolConfig.activitati_cadre?.isActive === '1',
    SCHOOL_STAFF_ACTIVITIES_DAYS_EDIT: session?.schoolConfig.activitati_cadre?.zileEditare === '1',
    ADMISSION_ACTIVE: session?.schoolConfig.admitere?.isActive === '1',
    SHOW_ADMISSION_OFFERS_TO_STUDENTS: session?.schoolConfig.admitere?.showOffersToStudents === '1',
    LIBRARY_ACTIVE: session?.schoolConfig.biblioteca?.isActive === '1',
    BILLING_ACTIVE: session?.schoolConfig.billing?.isActive === '1',
    CALENDAR_ACTIVE: session?.schoolConfig.calendar?.isActive === '1',
    QUESTIONNAIRES_ACTIVE: session?.schoolConfig.chestionare?.isActive === '1',
    QUESTIONNAIRES_SURVEY_ACTIVE: session?.schoolConfig.chestionare?.surveyIsActive === '1',
    QUESTIONNAIRES_FEEDBACK_ACTIVE: session?.schoolConfig.chestionare?.feedbackIsActive === '1',
    QUESTIONNAIRES_TEST_ACTIVE: session?.schoolConfig.chestionare?.testIsActive === '1',
    QUESTIONNAIRES_FILTER_SUBJECTS_ACTIVE:
      session?.schoolConfig.chestionare?.filterSubjectsActive === '1',
    BEHAVIOUR_ACTIVE: session?.schoolConfig.conduita?.isActive === '1',
    DECISION_REGISTER_ACTIVE: session?.schoolConfig.decision_register?.isActive === '1',
    EDUMARKET_ACTIVE: session?.schoolConfig.edumarket?.isActive === '1',
    FILES_ACTIVE: session?.schoolConfig.fisiere?.isActive === '1',
    PHOTOS_ACTIVE: session?.schoolConfig.foto?.isActive === '1',
    ANUAR_ACTIVE: session?.schoolConfig.foto?.anuarIsActive === '1',
    GROUPS_ACTIVE: session?.schoolConfig.groups?.isActive === '1',
    SCHOOL_STAFF_HISTORY_ACTIVE: session?.schoolConfig.istoric_cadre?.isActive === '1',
    NEURON_ENGLISH_ACTIVE: session?.schoolConfig.neuron_english?.isActive === '1',
    REMARKS_ACTIVE: session?.schoolConfig.observatii?.isActive === '1',
    TIMETABLE_ACTIVE: session?.schoolConfig.timetable?.isActive === '1',
    TIMETABLE_HEADTEACHER_CAN_EDIT: session?.schoolConfig.timetable?.headteacherCanEdit === '1',
    PEOPLE_MANAGEMENT_ACTIVE: session?.schoolConfig.people_management?.isActive === '1',
    GRADES_PROGRESS_ACTIVE: session?.schoolConfig.progress_grades?.isActive === '1',
    STUDENT_REPORTS_ACTIVE: session?.schoolConfig.rapoarte_elevi?.isActive === '1',
    NEWS_ACTIVE: session?.schoolConfig.stiri?.isActive === '1',
    NEWS_SECRETARY_ACTIVE: session?.schoolConfig.stiri?.secretariatIsActive === '1',
    TAXES_ACTIVE: session?.schoolConfig.taxe?.isActive === '1',
    HOMEWORK_ACTIVE: session?.schoolConfig.teme?.isActive === '1',
    TESTS_ACTIVE: session?.schoolConfig.teste?.isActive === '1',
    TESTS_IQ_ACTIVE: session?.schoolConfig.teste?.testIQIsActive === '1',
    TESTS_SELF_ACTIVE: session?.schoolConfig.teste?.testAutocunoastereIsActive === '1',
    VIDEO_GOOGLE_ACTIVE: session?.schoolConfig.video_google?.isActive === '1',
    VIDEO_WEBEX_ACTIVE: session?.schoolConfig.video_webex?.isActive === '1',
    VIDEO_ZOOM_ACTIVE: session?.schoolConfig.video_zoom?.isActive === '1',
    STUDENT_PROGRESS_REPORT_ACTIVE: getCurrentClass(session)?.ast?.astClasamentActiv === '1',
    SUBSCRIPTIONS_ACTIVE: session?.schoolConfig.subscriptions?.isActive === '1',
    OBSERVATIONS_ACTIVE: session?.schoolConfig.observatii?.isActive === '1',
    ATTENDANCE_REGISTER: session?.schoolConfig.attendance_register?.isActive === '1',
    STUDENT_PORTFOLIO: session?.schoolConfig.studentPortfolio?.isActive === '1',
    PROGRESS_GRADES: session?.schoolConfig.progress_grades?.isActive === '1',
    CONDUITA: session?.schoolConfig.conduita?.isActive === '1',
  };

  return map[flag];
};

export const organisationHasFeatures = () => {
  const features: SessionFeatures = JSON.parse(localStorage.getItem('features') || '{}');

  return Boolean(features?.organization?.features);
};

export const isFeatureEnabled = (feature?: FEATURE_FLAGS) => {
  if (
    !feature ||
    !useHasAccess({
      configFlags: [CONFIG_FLAGS.SUBSCRIPTIONS_ACTIVE],
    })
  ) {
    return true;
  }

  const features: SessionFeatures = JSON.parse(localStorage.getItem('features') || '{}');

  return Boolean(features?.organization?.features.find((f) => f.id === feature)?.value);
};

export const hasFeature = (feature?: FEATURE_FLAGS) => {
  if (
    !feature ||
    !useHasAccess({
      configFlags: [CONFIG_FLAGS.SUBSCRIPTIONS_ACTIVE],
    })
  ) {
    return true;
  }

  const features: SessionFeatures = JSON.parse(localStorage.getItem('features') || '{}');

  return Boolean(features?.user?.features.find((f) => f.id === feature)?.value);
};

export const useHasAccess = (rules?: AccessRules): boolean => {
  const session: SessionItem = JSON.parse(String(localStorage.getItem('session')));

  if (!session?.schoolConfig) {
    return false;
  }

  const permissions = session?.profile?.permisiuni?.map((p) => p.permID) || [];
  const uaType = session?.profile?.uaTip;

  const hasAccess = (): boolean => {
    if (!rules) {
      return true;
    }

    const hasPermission = rules.permissions
      ? permissions.some((q: string) => {
          if (rules.permissions?.includes(q)) {
            return true;
          }
        })
      : true;

    const isConfigActive = rules.configFlags?.length
      ? rules.configFlags?.some((c) => {
          return getConfigFlagValue(session, c);
        })
      : true;

    const isUaType = rules.uaTypes ? rules.uaTypes.includes(uaType) : true;

    const extraChecks = rules.extraChecks !== undefined ? rules.extraChecks : true;

    return (
      hasPermission && isConfigActive && isUaType && isFeatureEnabled(rules.feature) && extraChecks
    );
  };

  return hasAccess();
};
