import { ComponentStyleConfig } from '@chakra-ui/react';

export type {
  BoxProps,
  CenterProps,
  ContainerProps,
  FlexProps,
  GridItemProps,
  GridProps,
  SimpleGridProps,
  SpaceProps,
  StackProps,
  TableContainerProps,
} from '@chakra-ui/react';
export {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  SimpleGrid,
  Spacer,
  Stack,
  StackDivider,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

export const ContainerTheme: ComponentStyleConfig = {
  baseStyle: {
    px: '2rem',
  },
};
