import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { getOrgTypeTheme } from '@ui2/utils';

const baseColor = getOrgTypeTheme('blue');

export const MenuTheme: ComponentMultiStyleConfig = {
  parts: ['button', 'list', 'item', 'groupTitle', 'command', 'divider'],
  baseStyle: {
    list: {
      boxShadow: 'lg',
      borderRadius: 'xl',
    },
    item: {
      color: 'gray.500',
      alignItems: 'center',
      px: 4,
      _selected: {
        backgroundColor: `${baseColor}.50`,
        color: 'gray.700',
      },
      _hover: {
        backgroundColor: `${baseColor}.50`,
        color: 'gray.700',
      },
      _focus: {
        backgroundColor: `${baseColor}.50`,
        color: 'gray.700',
      },
      '& > span': {
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
  sizes: {
    sm: {
      item: {
        height: '8',
        py: 'unset',
        fontSize: 'sm',
      },
    },
    lg: {
      item: {
        height: '12',
        fontSize: 'lg',
        py: 'unset',
      },
    },
  },
};
