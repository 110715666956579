import { Entity, Of } from 'entity-of';

import { Dorm, Facility, Faculty } from '..';

@Entity
export class Room {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  dormId: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  facultyId: number = 0;

  @Of(() => String)
  facultyAbbr: string = '';

  @Of(() => Number)
  availableBeds: number = 0;

  @Of(() => Number)
  beds: number = 0;

  @Of(() => Number)
  roomTypeId: number = 0;

  @Of(() => Number)
  floor: number = 0;

  @Of(() => Dorm, { optional: true })
  dorm?: Dorm;

  @Of(() => String)
  category: string = '';

  @Of(() => String)
  occupiedBeds: string = '';

  @Of(() => RoomType)
  roomType: RoomType = RoomType.of({});

  @Of(() => [Facility])
  facilities: Facility[] = [];
  static of = Entity.of<Room>();

  @Of(() => Number, { optional: true })
  coreFacultyId?: number = 0;
}

@Entity
class RoomType {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  noOfBeds: number = 0;

  static of = Entity.of<RoomType>();
}

@Entity
export class AccomodationRoom extends Room {
  @Of(() => Number)
  room_type_id: number = 0;

  @Of(() => Faculty)
  faculty: Faculty = Faculty.of({});
  static of = Entity.of<AccomodationRoom>();
}
