import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class CitiesByCountyIdInput extends SimpleQueryInput {
  @Of(() => [String])
  judID: string[] = [];

  @Of(() => [String])
  taraID: string[] = [];

  @Of(() => String, { optional: true })
  liceuOrgType?: string;

  static of = Entity.of<CitiesByCountyIdInput>();
}
