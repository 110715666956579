import { Entity, Of } from 'entity-of';

import { InvoiceType } from '../Invoice';
import { PaymentProvider } from '../Payment';
import { TenantSeriesNumber } from '../TenantSeriesNumber/TenantSeriesNumber';

export enum OrganisationType {
  NGO = 'ngo',
  COMPANY = 'company',
}

export enum EfacturaIssueType {
  AUTO = 'auto',
  MANUAL = 'manual',
}

export enum SpvActiveType {
  INACTIVE = 'inactive',
  ERROR = 'error',
  ACTIVE = 'active',
}

@Entity
export class TenantBillingProfile {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  alias: string = '';

  @Of(() => Boolean)
  vatPayer: boolean = false;

  @Of(() => String)
  fiscalCode: string = '';

  @Of(() => String)
  street: string = '';

  @Of(() => String)
  zipCode: string = '';

  @Of(() => String)
  country: string = '';

  @Of(() => Boolean, { optional: true })
  disabled?: boolean;

  @Of(() => String)
  county: string = '';

  @Of(() => String)
  city: string = '';

  @Of(() => String)
  countryId: string = '';

  @Of(() => String)
  countyId: string = '';

  @Of(() => String, { optional: true })
  cityId?: string;

  @Of(() => String)
  email: string = '';

  @Of(() => String)
  phone: string = '';

  @Of(() => String)
  bankName: string = '';

  @Of(() => String)
  iban: string = '';

  @Of(() => [TenantSeriesNumber], { optional: true })
  invoiceSeries?: TenantSeriesNumber[];

  @Of(() => [TenantSeriesNumber], { optional: true })
  proformaSeries?: TenantSeriesNumber[];

  @Of(() => InvoiceType)
  defaultInvoiceType: InvoiceType = InvoiceType.INVOICE;

  @Of(() => String)
  currency: string = '';

  @Of(() => Number)
  invoiceDay: number = 0;

  @Of(() => Number)
  invoiceDueAfter: number = 0;

  @Of(() => String)
  otherInformation: string = '';

  @Of(() => Number, { optional: true })
  penaltyPercentage: number = 0;

  @Of(() => Boolean)
  isDefault: boolean = false;

  @Of(() => Boolean)
  acceptsOnlinePayments: boolean = false;

  @Of(() => Boolean)
  displayBankOnInvoice: boolean = false;

  @Of(() => Boolean)
  applyProformaPenalties: boolean = false;

  @Of(() => Boolean)
  isOnBoardingDone: boolean = false;

  @Of(() => String)
  defaultInvoiceStatus: string = '';

  @Of(() => String, { optional: true })
  firstName?: string = '';

  @Of(() => String, { optional: true })
  lastName?: string = '';

  @Of(() => String, { optional: true })
  nrJ?: string;

  @Of(() => String, { optional: true })
  capital?: string;

  @Of(() => String)
  organisationType: OrganisationType = OrganisationType.COMPANY;

  @Of(() => Boolean)
  eFacturaActive: boolean = false;

  @Of(() => SpvActiveType, { optional: true })
  spvActive?: SpvActiveType;

  @Of(() => EfacturaIssueType, { optional: true })
  eFacturaIssueType?: EfacturaIssueType;

  static of = Entity.of<TenantBillingProfile>();
}

@Entity
export class TenantBillingProfileProvider {
  @Of(() => Boolean)
  status: boolean = false;

  @Of(() => String)
  provider: PaymentProvider = PaymentProvider.NETOPIA;

  static of = Entity.of<TenantBillingProfileProvider>();
}
