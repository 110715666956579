import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useUniversityData, useYearsData } from '@modules/shared/utils';
import { useFacultyState } from '@modules/Universities/hooks';
import {
  AdservioIcons,
  Box,
  Button,
  Divider,
  IconButton,
  Loader,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from '@ui2/components';
import { useBreakpointValue } from '@ui2/hooks';

export const OrganizationMenuSelect = () => {
  const { t } = useTranslation('shared');
  const { facultyId, setFacultyId } = useFacultyState();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const { faculties, isLoading: isUniLoading, rerun: rerunUni } = useUniversityData();
  const { isLoading: isYearsLoading, rerun: rerunYears } = useYearsData();

  const isLoading = isUniLoading || isYearsLoading;

  useEffect(() => {
    rerunUni();
    rerunYears();
  }, []);

  return (
    <Loader isLoading={isLoading} size="xs">
      <Box>
        <Menu>
          {isMobile ? (
            <MenuButton
              as={IconButton}
              icon={<AdservioIcons.University />}
              variant="ghost"
              colorScheme="gray"
            />
          ) : (
            <MenuButton
              maxW="23rem"
              as={Button}
              variant="ghost"
              colorScheme="gray"
              rightIcon={<AdservioIcons.FiChevronDown />}
              leftIcon={<AdservioIcons.University />}
            >
              <Text noOfLines={1} textAlign="right" display={isMobile ? 'none' : 'block'}>
                {faculties?.find((fac) => fac.id === facultyId)?.name ||
                  t('general.select_faculty')}
              </Text>
            </MenuButton>
          )}

          <MenuList w="23rem" overflowY="auto" alignItems="flex-start">
            <VStack spacing="0.5rem">
              {faculties?.map((faculty, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <MenuItem
                      w="22rem"
                      backgroundColor={faculty?.id === facultyId ? 'gray.100' : 'transparent'}
                      borderRadius="lg"
                      onClick={() => setFacultyId(faculty.id)}
                      alignItems="flex-start"
                      _hover={{ bg: 'gray.50' }}
                    >
                      <Text textOverflow="ellipsis" noOfLines={1}>
                        {faculty?.name}
                      </Text>
                    </MenuItem>
                    {idx !== faculties.length - 1 && <Divider maxW="22rem" color="gray.200" />}
                  </React.Fragment>
                );
              })}
            </VStack>
          </MenuList>
        </Menu>
      </Box>
    </Loader>
  );
};
