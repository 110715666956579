import { Entity, Of } from 'entity-of';

import { Discount } from '../../Discount';

@Entity
export class InvoiceServiceInput {
  @Of(() => Number)
  serviceId: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  description: string = '';

  @Of(() => Number)
  quantity: number = 0;

  @Of(() => Number)
  value: number = 0;

  @Of(() => [Number], { optional: true })
  chargeIds?: number[];

  @Of(() => String)
  periodStart: string = '';

  @Of(() => String)
  periodEnd: string = '';

  @Of(() => Discount, { optional: true })
  discount?: Discount;

  static of = Entity.of<InvoiceServiceInput>();
}
