export const useUserTypeState = () => {
  const session = JSON.parse(localStorage.getItem('session') || '{}');
  const sessionType = session?.profile?.permisiuni?.map((p) => p.permID) || [];

  const professor = sessionType.includes('14');
  const associate = sessionType.includes('15') || sessionType.includes('16');
  const student = sessionType.includes('42');
  const superAdmin = sessionType.includes('17');
  const erasmus = sessionType.includes('62');
  const auxiliar = sessionType.includes('18');

  let userType = '';

  if (erasmus) {
    userType = 'erasmus';
  }

  if (student) {
    userType = 'student';
  }

  if (professor || auxiliar) {
    userType = 'professor';
  }

  if (associate) {
    userType = 'associate';
  }

  if (superAdmin) {
    userType = 'superAdmin';
  }

  const setUserType = (type) => {
    localStorage.setItem('session', JSON.stringify(type));
  };

  const removeUserType = () => {
    localStorage.removeItem('session');
  };

  const isAdmin = () => userType === 'associate';
  const isAssociate = () => userType === 'associate';
  const isProfessor = () => userType === 'professor';
  const isSuperAdmin = () => userType === 'superAdmin';
  const isStudent = () => userType === 'student';
  const isErasmus = () => userType === 'erasmus';

  return {
    userType,
    setUserType,
    removeUserType,
    isAdmin,
    isAssociate,
    isProfessor,
    isSuperAdmin,
    isStudent,
    isErasmus,
    associate,
    student,
    professor,
    superAdmin,
    erasmus,
  };
};
