import { Entity, Of } from 'entity-of';

export enum FieldType {
  INPUT = 'INPUT',
  TEXTAREA = 'TEXTAREA',
  NUMBER = 'NUMBER',
  SELECT = 'SELECT',
  DATE = 'DATE',
  CHECKBOX = 'CHECKBOX',
  RADIO_GROUP = 'RADIO_GROUP',
  FORM = 'FORM',
  FILE = 'FILE',
  TOGGLE = 'TOGGLE',
  MULTISELECT = 'MULTISELECT',
  TIME = 'TIME',
}

@Entity
export class FormField {
  @Of(() => String)
  id = '';

  @Of(() => String)
  type: FieldType = FieldType.INPUT;

  @Of(() => String)
  name = '';

  @Of(() => String)
  label = '';

  @Of(() => String)
  placeholder = '';

  @Of(() => [FormFieldSelectItemProps])
  fieldOptions: FormFieldSelectItemProps[] = [];

  @Of(() => FormFieldValidators)
  validators?: FormFieldValidators;

  @Of(() => String)
  icon = '';

  @Of(() => Boolean, { optional: true })
  isDisabled? = false;

  @Of(() => String)
  hint = '';

  @Of(() => Number)
  order = 0;

  @Of(() => String)
  parentId = '';

  @Of(() => [FormField])
  formFields?: FormField[] = [];

  static of = Entity.of<FormField>();
}

@Entity
export class FormFieldSelectItemProps {
  @Of(() => String)
  label = '';

  @Of(() => String)
  value: string | number = '';

  @Of(() => Boolean)
  isDisabled?: boolean;

  static of = Entity.of<FormFieldSelectItemProps>();
}

@Entity
export class FormFieldValidators {
  @Of(() => Boolean)
  required?: boolean;

  @Of(() => Number)
  exact_length?: number;

  @Of(() => Number)
  min_length?: number;

  @Of(() => Number)
  max_length?: number;

  @Of(() => Number)
  greater_than?: number;

  @Of(() => Number)
  less_than?: number;

  @Of(() => Number)
  min?: number;

  @Of(() => Number)
  max?: number;

  static of = Entity.of<FormFieldValidators>();
}
