import { Entity, Of } from 'entity-of';

@Entity
export class HomeworkInput {
  @Of(() => Number, { optional: true })
  sanID?: number = 0;

  @Of(() => Number)
  elevID: number = 0;

  @Of(() => Number, { optional: true })
  clID?: number;

  @Of(() => Number, { optional: true })
  cmcID?: number;

  @Of(() => Number, { optional: true })
  grupaID?: number;

  @Of(() => String, { optional: true })
  hwDueDateStart?: string;

  @Of(() => String, { optional: true })
  hwDueDateEnd?: string;

  @Of(() => Number, { optional: true })
  cuProfesor?: number;

  @Of(() => Number, { optional: true })
  cuMaterie?: number;

  @Of(() => Number, { optional: true })
  cuNota?: number;

  @Of(() => Number, { optional: true })
  cuRating?: number;

  @Of(() => Number, { optional: true })
  cuHwsSubmitted?: number;

  @Of(() => Number, { optional: true })
  hwsSubmitted?: number;

  @Of(() => Number, { optional: true })
  cuHwsCount?: number;

  static of = Entity.of<HomeworkInput>();
}

@Entity
export class MultipleHomeworkInput extends HomeworkInput {
  @Of(() => [String])
  clIds: string[] = [];

  static of = Entity.of<MultipleHomeworkInput>();
}
