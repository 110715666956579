import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { getOrgTypeTheme } from '@ui2/utils';

const baseColor = getOrgTypeTheme('blue');

export const InputTheme: ComponentMultiStyleConfig = {
  parts: ['field', 'addon'],
  baseStyle: {
    field: {
      _placeholder: {
        opacity: 1,
        color: 'gray.300',
      },
    },
  },
  sizes: {
    xs: {
      field: {
        borderRadius: '4',
      },
    },
    sm: {
      field: {
        borderRadius: '6',
      },
    },
    md: {
      field: {
        borderRadius: '8',
      },
    },
    lg: {
      field: {
        borderRadius: '10',
      },
    },
  },
  variants: {
    outline: {
      field: {
        boxShadow: 'xs',
        _hover: {
          borderColor: `${baseColor}.300`,
          boxShadow: 'inputFocus',
        },
        _focus: {
          boxShadow: 'inputFocus',
          borderColor: `${baseColor}.500`,
        },
        _disabled: {
          borderColor: 'gray.200',
          backgroundColor: 'gray.100',
          opacity: 1,
          _hover: {
            boxShadow: 'xs',
          },
          _invalid: {
            boxShadow: 'inputInvalid',
          },
        },
        _invalid: {
          boxShadow: 'inputInvalid',
          _focus: {
            boxShadow: 'inputInvalid',
            borderColor: 'red.500',
          },
        },
      },
    },
  },
};
