import React, { PropsWithChildren } from 'react';

import { Role } from '@api/core/entities';
import { UserTypes } from '@constants';
import { RoleBadge } from '@modules/shared/components/SearchBar/components/RoleBadge';
import {
  AdservioIcons,
  Avatar,
  Box,
  BoxProps,
  Flex,
  FlexProps,
  IconButton,
  Skeleton,
  SkeletonCircle,
  Text,
  VStack,
} from '@ui2/components';
import { getOrgTypeTheme } from '@ui2/utils';

export type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const getAvatarSize = (size: Size) => {
  switch (size) {
    case 'xxs':
      return { avatarSize: 'xs', boxSize: '1.5rem' };
    case 'xs':
      return { avatarSize: 'sm', boxSize: '2rem' };
    case 'sm':
      return { avatarSize: '1rem', boxSize: '2.5rem' };
    case 'md':
      return { avatarSize: 'md', boxSize: '3rem' };
    case 'lg':
      return { avatarSize: 'lg', boxSize: '4rem' };
    case 'xl':
      return { avatarSize: 'xl', boxSize: '6rem' };
    default:
      return { avatarSize: 'md', boxSize: '2.5rem' };
  }
};

const getSecondaryTextSize = (size: Size) => {
  switch (size) {
    case 'xs':
      return 'xs';
    case 'sm':
      return 'xs';
    case 'md':
      return 'sm';
    case 'lg':
      return 'sm';
    case 'xl':
      return 'md';
    default:
      return 'sm';
  }
};

const getPrimaryTextSize = (size: Size) => {
  switch (size) {
    case 'xs':
      return 'sm';
    case 'sm':
      return 'sm';
    case 'md':
      return 'md';
    case 'lg':
      return 'lg';
    case 'xl':
      return '2xl';
    default:
      return 'md';
  }
};

const getSkeletonHeight = (size: Size) => {
  switch (size) {
    case 'xs':
      return '3';
    case 'sm':
      return '4';
    case 'md':
      return '5';
    case 'lg':
      return '5';
    case 'xl':
      return '6';
    default:
      return '4';
  }
};

const getInfoIconSize = (size: Size) => {
  switch (size) {
    case 'xs':
      return '3.5';
    case 'sm':
      return '3.5';
    case 'md':
      return '4';
    case 'lg':
      return '4';
    case 'xl':
      return '5';
    default:
      return '4';
  }
};

interface PersonAvatarProps extends BoxProps {
  name: string;
  avatar: string;
  size?: Size;
  isLoading?: boolean;
  avatarBorder?: string;
  onNameClick?: () => void;
}

export const PersonAvatar = ({
  name,
  size = 'md',
  avatar,
  isLoading,
  avatarBorder,
  onNameClick,
  ...props
}: PersonAvatarProps) => {
  const { boxSize, avatarSize } = getAvatarSize(size);
  const baseColor = getOrgTypeTheme('blue');

  return (
    <SkeletonCircle boxSize={boxSize} isLoaded={!isLoading} {...props}>
      <Avatar
        onClick={onNameClick}
        size={avatarSize}
        name={name}
        src={avatar}
        color="white"
        boxSize={boxSize}
        backgroundColor={`${baseColor}.700`}
        border={avatarBorder}
        data-testid="person-excerpt-avatar"
        _hover={{
          ...(onNameClick && {
            cursor: 'pointer',
            outline: '1px solid',
            outlineColor: 'blue.300',
            boxShadow: 'inputFocus',
          }),
        }}
      />
    </SkeletonCircle>
  );
};

interface PersonDetailsSectionProps {
  isLoading?: boolean;
  size: Size;
}

export const PersonDetailsSection = ({
  children,
  isLoading,
  size,
}: PropsWithChildren<PersonDetailsSectionProps>) => {
  return (
    <VStack
      flex="1"
      spacing={!isLoading ? '0.5' : '3'}
      align={{ base: size === 'xl' ? 'center' : 'flex-start', sm: 'flex-start' }}
      ml={{ base: size === 'xl' ? '0' : '3', sm: '3' }}
      mt={{ base: size === 'xl' ? '4' : '0', sm: '0' }}
    >
      {children}
    </VStack>
  );
};

interface MainContainerProps {
  size: Size;
  props?: FlexProps;
}

export const MainContainer = ({
  children,
  size,
  ...props
}: PropsWithChildren<MainContainerProps>) => {
  return (
    <Flex align="center" flexDir={{ base: size === 'xl' ? 'column' : 'row', sm: 'row' }} {...props}>
      {children}
    </Flex>
  );
};

interface PersonNameProps {
  size: Size;
  name: string;
  isLoading?: boolean;
  onNameClick?: () => void;
}

export const PersonName = ({ name, isLoading, size, onNameClick }: PersonNameProps) => {
  const fontSize = getPrimaryTextSize(size);
  const skeletonHeight = getSkeletonHeight(size);

  return (
    <>
      {isLoading ? (
        <Skeleton minH={skeletonHeight} minW="36" isLoaded={!isLoading} mb="1" />
      ) : (
        <Text
          noOfLines={2}
          fontSize={fontSize}
          color="gray.700"
          fontWeight={size === 'xl' ? 'bold' : 'semibold'}
          data-testid="person-excerpt-name"
          textTransform="capitalize"
          wordBreak="break-word"
          onClick={onNameClick}
          _hover={{
            cursor: onNameClick ? 'pointer' : 'initial',
            textDecoration: onNameClick ? 'underline' : 'none',
          }}
        >
          {name}
        </Text>
      )}
    </>
  );
};

interface PersonRoleProps {
  size: Size;
  roles: Role[];
  userType: UserTypes;
  isLoading?: boolean;
  useRoleTag?: boolean;
}

export const PersonRole = ({ roles, useRoleTag, userType, isLoading, size }: PersonRoleProps) => {
  const fontSize = getSecondaryTextSize(size);
  const skeletonHeight = getSkeletonHeight(size);

  const role = roles
    ?.map((role) => role.roleName)
    .filter((r) => Boolean(r))
    .join(', ');

  return (
    <>
      {isLoading ? (
        <Skeleton minH={skeletonHeight} minW="24" />
      ) : useRoleTag ? (
        <>
          {roles.map(({ roleName }, index) => (
            <RoleBadge key={`role-${index}`} badgeText={roleName} userType={userType} />
          ))}
        </>
      ) : (
        <Text
          fontSize={fontSize}
          fontWeight="regular"
          color="gray.400"
          data-testid="person-excerpt-role"
        >
          {role}
        </Text>
      )}
    </>
  );
};

interface DetailsTextProps {
  size: Size;
  text: string;
  isLoading?: boolean;
  withSeparator?: boolean;
}

export const DetailsText = ({ isLoading, size, text, withSeparator }: DetailsTextProps) => {
  const fontSize = getSecondaryTextSize(size);
  const skeletonHeight = getSkeletonHeight(size);

  return (
    <>
      {isLoading ? (
        <Skeleton minH={skeletonHeight} minW="24" />
      ) : (
        <Flex alignItems="center">
          {withSeparator && (
            <Box
              w="1"
              h="1"
              minH="1"
              minW="1"
              mr="2"
              as="span"
              display="inline-block"
              bg="gray.400"
              borderRadius="50%"
            />
          )}

          <Text
            fontSize={fontSize}
            fontWeight="regular"
            color="gray.400"
            data-testid="person-excerpt-class"
          >
            {text}
          </Text>
        </Flex>
      )}
    </>
  );
};

interface InfoIconButtonProps {
  size: Size;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const InfoIconButton = ({ size, onClick }: InfoIconButtonProps) => {
  const boxSize = getInfoIconSize(size);

  return (
    <IconButton
      isRound
      mr="2"
      size="xs"
      aria-label="person-info"
      variant="ghost"
      colorScheme="gray"
      icon={<AdservioIcons.FiInfo boxSize={boxSize} />}
      onClick={onClick}
    />
  );
};
