import { useEffect } from 'react';

import { useFileQuery } from '@api/core/endpoints';
import { Attachment, Box, EndpointOptions, FormElement, FormElementProps } from '@ui2/components';
import { FormField } from '@ui2/entities';

const apiBaseUrl = window.env?.V2_PUBLIC_API_BASE_URL || '';
interface DynamicFileUploaderProps {
  field: FormField;
  fsTip?: string;
  resourceId?: string;
  tenantId?: string;
  endpointOptions?: EndpointOptions;
  onLoadStart?(): void;
  onLoadEnd?(): void;
}

export const DynamicFileUploader = ({
  field,
  fsTip,
  resourceId,
  endpointOptions,
  tenantId,
  onLoadStart,
  onLoadEnd,
  ...props
}: FormElementProps<DynamicFileUploaderProps>) => {
  const filesQuery = useFileQuery(
    {
      id: resourceId,
      tenantId: tenantId,
    },
    {
      enabled: !!resourceId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data) {
          onLoadEnd?.();
        }
      },
    }
  );

  useEffect(() => {
    if (resourceId) {
      onLoadStart?.();
    }
  }, []);

  return (
    <Box gridColumn="1 / 4">
      <FormElement {...{ name: field.name, label: field.isDisabled ? undefined : field.label }}>
        <Attachment
          apiBaseUrl={apiBaseUrl}
          accessors={{
            id: 'fsID',
            name: 'fsNume',
          }}
          endpointOptions={endpointOptions}
          fileName={filesQuery.data?.fsNume}
          isLoading={filesQuery.isLoading}
          onLoadStart={onLoadStart}
          onLoadEnd={onLoadEnd}
          fsTip={fsTip}
          isDisabled={field.isDisabled}
          {...props}
        />
      </FormElement>
    </Box>
  );
};
