import { useTranslation } from 'react-i18next';

import {
  CONFIG_FLAGS,
  FEATURE_FLAGS,
  PERM_CAD_EDUCATOR,
  PERM_CAD_INVATATOR,
  PERM_CAD_PROFESOR,
  PERM_LIBRARY_MANAGER,
  PERM_LIBRARY_VIEW,
  PERM_QUESTIONNAIRE_CREATE_FEEDBACK,
  PERM_QUESTIONNAIRE_CREATE_SURVEY,
  PERM_QUESTIONNAIRE_CREATE_TEST,
  PERM_QUESTIONNAIRE_VIEW,
  PERM_TIMETABLE_CONFIG,
} from '@constants';
import { useRouter } from '@lib/router';
import { LinkButtonItem, LinkButtonsGroup } from '@modules/shared';

export const useTeacherLinkGroups = () => {
  const { lang } = useRouter();
  const { t } = useTranslation(['Site', 'common']);

  const groups: LinkButtonsGroup[] = [
    {
      items: [
        LinkButtonItem.of({
          icon: 'FiHome',
          label: t('buttons_mm.dashboard'),
          accessRules: { permissions: [PERM_CAD_EDUCATOR, PERM_CAD_INVATATOR, PERM_CAD_PROFESOR] },
          url: `dashboard`,
          matchingUrls: [
            'dashboard/all-classes',
            'teacher/class',
            'teacher/clase',
            'teacher/group',
            'teacher/rankings_students',
            'teacher/rankings_attendance',
            'teacher/grade_head_teacher',
            'classes/management',
            'teacher/schedule/class',
            'teacher/teachers',
            'teacher/live_lesson',
            'homework',
            'reports/head_master/averages',
            'reports/head_master/class_stats',
            'main-documents/student-file',
            'main-documents/classbook-form',
            'reports/students/top-students',
            'timetable/class',
          ],
        }),

        LinkButtonItem.of({
          icon: 'FiFile',
          label: t('buttons_mm.fisiere'),
          url: `files`,
          accessRules: { configFlags: [CONFIG_FLAGS.FILES_ACTIVE] },
        }),
        LinkButtonItem.of({
          icon: 'History',
          label: t('buttons_mm.istoric'),
          url: `history`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.SCHOOL_STAFF_HISTORY_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiBell',
          label: t('buttons_mm.noutati'),
          url: 'news',
          matchingUrls: ['news/item'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.NEWS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'Portofolio',
          label: t('buttons_mm.portofoliu'),
          url: 'teacher/other-activities',
          redirectRule: 'window',
          matchingUrls: ['teacher/other-activities'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.SCHOOL_STAFF_ACTIVITIES_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiClipboard',
          label: t('buttons_mm.tests_questionnaires'),
          matchingUrls: ['questionnaires/created', 'questionnaires/todo', 'questionnaires/results'],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.list_created'),
              url: `questionnaires/created`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.list_todo'),
              url: `questionnaires/todo`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.list_results'),
              url: `questionnaires/results`,
            }),
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.QUESTIONNAIRES_ACTIVE],
            permissions: [
              PERM_QUESTIONNAIRE_VIEW,
              PERM_QUESTIONNAIRE_CREATE_SURVEY,
              PERM_QUESTIONNAIRE_CREATE_FEEDBACK,
              PERM_QUESTIONNAIRE_CREATE_TEST,
            ],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiCalendar',
          label: t('buttons_mm.calendar'),
          url: `calendar`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.CALENDAR_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiClock',
          label: t('buttons_mm.timetables'),
          matchingUrls: [`timetable/list`, `timetable/schedules`],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.timetable_list'),
              url: `timetable/list`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.programs_and_time_intervals'),
              url: `timetable/schedules`,
            }),
          ],
          featureFlag: FEATURE_FLAGS.FLAG_TIMETABLES,
          accessRules: {
            configFlags: [CONFIG_FLAGS.TIMETABLE_ACTIVE],
            permissions: [PERM_TIMETABLE_CONFIG],
          },
        }),
      ],
    },
    {
      emptyTitle: true,
      items: [
        LinkButtonItem.of({
          icon: 'FiMail',
          label: t('buttons_mm.mesaje'),
          url: `messages`,
          matchingUrls: [
            'messages/send',
            'messages/sent',
            'messages/drafts',
            'messages/archive',
            'messages/received',
          ],
        }),
        LinkButtonItem.of({
          icon: 'Groups',
          label: t('buttons_mm.groups'),
          url: `groups`,
          matchingUrls: ['groups/'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.GROUPS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiPieChart',
          label: t('buttons_mm.rapoarte_elevi'),
          url: 'teacher/students_reports',
          matchingUrls: ['teacher/students_reports/secondary'],
          redirectRule: 'window',
          accessRules: {
            configFlags: [CONFIG_FLAGS.STUDENT_REPORTS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiImage',
          label: t('buttons_mm.foto'),
          matchingUrls: ['foto', 'anuar'],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.foto'),
              url: `foto`,
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.yearbook'),
              url: `anuar`,
              accessRules: {
                configFlags: [CONFIG_FLAGS.ANUAR_ACTIVE],
              },
            }),
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.PHOTOS_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'Library',
          label: t('buttons_mm.biblioteca'),
          url: `library`,
          matchingUrls: ['library/manage'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.LIBRARY_ACTIVE],
            permissions: [PERM_LIBRARY_MANAGER, PERM_LIBRARY_VIEW],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiShoppingCart',
          label: t('buttons_mm.edumarket'),
          url: `edumarket`,
          matchingUrls: ['edumarket/about', 'edumarket/applications/details'],
          accessRules: {
            configFlags: [CONFIG_FLAGS.EDUMARKET_ACTIVE],
          },
        }),
        LinkButtonItem.of({
          icon: 'FiHelpCircle',
          label: t('buttons_mm.support'),
          url:
            lang === 'ro'
              ? 'https://manual.adservio.ro/web/profesor-invatator-educator/'
              : 'https://manual.adservio.ro/web/en/teacher-primary-teacher-educator/',
          redirectRule: 'blank',
          hasTopDivider: true,
        }),
      ],
    },
  ];

  return {
    groups,
  };
};
