import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class Observation {
  @Of(() => String)
  obsID: string = '';

  @Of(() => String)
  obsContinut: string = '';

  @Of(() => String)
  obsData: string = '';

  @Of(() => String)
  obsIsRead: string = '';

  @Of(() => String)
  obsPermisiuni: string = '';

  @Of(() => String)
  obsPosIsRead: string = '';

  @Of(() => Person)
  ownerInfo: Person = Person.of({});

  @Of(() => Subject)
  cmcMaterie: Subject = Subject.of({});

  static of = Entity.of<Observation>();
}

@Entity
class Subject {
  @Of(() => String)
  matID: string = '';

  @Of(() => String)
  matNume: string = '';

  @Of(() => String)
  matPrescurtare: string = '';

  static of = Entity.of<Subject>();
}
