import { Entity, Of } from 'entity-of';

import { PortfolioCategory } from '../PortfolioCategory/PortfolioCategory';

@Entity
export class StudentPortfolio {
  @Of(() => School)
  school: School = School.of({});

  @Of(() => [PortfolioCategory])
  categories: PortfolioCategory[] = [];

  static of = Entity.of<StudentPortfolio>();
}

@Entity
class School {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  name: string = '';

  static of = Entity.of<School>();
}
