import { Entity, Of } from 'entity-of';

@Entity
export class ResultsGrade {
  @Of(() => ResultDataGrade)
  data: ResultDataGrade = ResultDataGrade.of({});

  static of = Entity.of<ResultsGrade>();
}

@Entity
class ResultDataGrade {
  @Of(() => String)
  frpID: string = '';

  @Of(() => String)
  frpResultID: string = '';

  @Of(() => String)
  frpFillerUaID: string = '';

  @Of(() => String)
  frpAvgProgress: string = '';

  @Of(() => String)
  frpGrade?: string = '';

  @Of(() => String)
  frpGradeDescription?: string = '';

  static of = Entity.of<ResultDataGrade>();
}
