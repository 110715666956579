import { Type } from 'class-transformer';
import { IsArray, IsNotEmpty, ValidateNested } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class CreateCertificateFormInput {
  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => AdeverinteUsers)
  @Of(() => AdeverinteUsers)
  cerUsers: AdeverinteUsers[] = [];

  @IsNotEmpty({ message: 'errors.type.required' })
  @Of(() => String)
  cerType: string = '';

  static of = Entity.of<CreateCertificateFormInput>();
}

@Entity
export class AdeverinteUsers {
  @ValidateNested()
  @Type(() => AdeverinteFields)
  @Of(() => AdeverinteFields)
  cerData: AdeverinteFields = AdeverinteFields.of({});

  static of = Entity.of<AdeverinteUsers>();
}

@Entity
export class EditCertificateFormInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.type.required' })
  cerType: string = '';

  @ValidateNested()
  @Type(() => AdeverinteFields)
  @Of(() => AdeverinteFields)
  cerData: AdeverinteFields = AdeverinteFields.of({});

  static of = Entity.of<EditCertificateFormInput>();
}

@Entity
export class AdeverinteFields {
  @IsNotEmpty({ message: 'errors.date.required' })
  @Of(() => String, { optional: true })
  issued_date: string = '';

  @IsNotEmpty({ message: 'errors.reason.required' })
  @Of(() => String, { optional: true })
  reason: string = '';

  @IsNotEmpty({ message: 'errors.number.required' })
  @Of(() => String, { optional: true })
  number: string = '';

  @IsNotEmpty({ message: 'errors.director.required' })
  @Of(() => String, { optional: true })
  headmaster: string = '';

  @IsNotEmpty({ message: 'errors.secretary.required' })
  @Of(() => String, { optional: true })
  secretary: string = '';

  @Of(() => Boolean, { optional: true })
  show_logo: boolean = false;

  static of = Entity.of<AdeverinteFields>();
}
