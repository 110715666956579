import { Entity, Of } from 'entity-of';

@Entity
export class InvoiceByIdInput {
  @Of(() => String)
  invoiceId = '';

  @Of(() => String, { optional: true })
  rootOrgId: string = '';

  static of = Entity.of<InvoiceByIdInput>();
}
