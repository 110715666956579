import { Entity, Of } from 'entity-of';

@Entity
export class PersonIdInput {
  @Of(() => Number)
  userId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  static of = Entity.of<PersonIdInput>();
}
