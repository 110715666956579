import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AdservioIcons, Center, Text, VStack } from '@ui2/components/atoms';
import { useField } from '@ui2/components/organisms';
import { getOrgTypeTheme } from '@ui2/utils';
import zxcvbn from 'zxcvbn';

import { InputGroup, InputGroupProps } from '../../atoms/InputGroup';

import { PasswordStrength, PasswordStrengthType } from '.';

export interface PasswordInputProps extends Omit<InputGroupProps, 'value'> {
  withStrength?: boolean;
  value?: string;
  name: string;
}

export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  ({ withStrength, value, name, ...props }, ref) => {
    const { t } = useTranslation('shared');
    const baseColor = getOrgTypeTheme('blue');

    const [showPassword, setShowPassword] = useState(false);
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState<PasswordStrengthType | undefined>();

    const {
      meta: { error, touched },
    } = useField(name);

    const zxcvbnStrengths = ['weak', 'weak', 'medium', 'strong', 'very_strong'];

    useEffect(() => {
      if (!value) {
        setPasswordStrength(undefined);
      } else {
        const passwordEvaluation = zxcvbn(value);
        setPasswordStrength(zxcvbnStrengths[passwordEvaluation.score] as PasswordStrengthType);
      }
    }, [value]);

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.getModifierState('CapsLock')) {
        setIsCapsLockOn(true);
      } else {
        setIsCapsLockOn(false);
      }
    };

    return (
      <VStack w="full" alignItems="flex-start">
        <InputGroup
          ref={ref}
          autoComplete="off"
          onKeyUp={handleKeyUp}
          rightElement={
            <Center position="relative" w="full">
              {showPassword ? (
                <AdservioIcons.FiEyeOff
                  cursor="pointer"
                  boxSize="1.5rem"
                  color={`${baseColor}.500`}
                  onClick={() => setShowPassword((prev) => !prev)}
                />
              ) : (
                <AdservioIcons.FiEye
                  cursor="pointer"
                  boxSize="1.5rem"
                  color={`${baseColor}.500`}
                  onClick={() => setShowPassword((prev) => !prev)}
                />
              )}
            </Center>
          }
          rightElementSx={{
            w: '2.5rem',
            zIndex: 1,
          }}
          type={showPassword ? 'text' : 'password'}
          value={value}
          {...props}
        />

        {withStrength && (
          <PasswordStrength
            strength={passwordStrength}
            hideText={(error && touched) || !passwordStrength}
          />
        )}

        {isCapsLockOn && (
          <Text fontSize="sm" fontWeight="semibold" color="orange.500">
            {t('general.caps_lock_on')}
          </Text>
        )}
      </VStack>
    );
  }
);
