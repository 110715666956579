import React from 'react';
import * as FeatherIcons from 'react-icons/fi';

import { createIcon, Icon, IconProps } from '@chakra-ui/react';

import { customIcons } from './customIcons';
import { customMultiPathsIcons } from './customMultiPathsIcons';

export const Icons = { ...FeatherIcons, ...customIcons, ...customMultiPathsIcons };
export type IconNames = keyof typeof Icons;
export type AdservioIconsRecord = Record<IconNames, React.FC<IconProps>>;

export const AdservioIcons: AdservioIconsRecord = Object.entries(Icons).reduce(
  (prev, [name, icon]) => {
    // Custom Icons with multiple paths
    if (Array.isArray(icon)) {
      return {
        ...prev,
        [name]: createIcon({
          defaultProps: {
            fill: 'currentColor',
            boxSize: '5',
            strokeWidth: '1.5px',
          },
          path: icon.map((el) => <path {...el} key={el.d} />),
        }),
      };
    }

    // Feather Icons
    if (typeof icon === 'function') {
      return {
        ...prev,
        [name]: (props) => <Icon boxSize="5" strokeWidth="1.5px" as={icon} {...props} />,
      };
    }

    // Custom Icons with single path
    return {
      ...prev,
      [name]: createIcon({
        defaultProps: {
          boxSize: '5',
          fill: 'none',
          strokeWidth: '1.5px',
        },
        d: icon,
      }),
    };
  },
  {} as AdservioIconsRecord
);

export { Icon };
