import { Role } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class Teacher {
  @Of(() => String)
  cadID = '';

  @Of(() => String)
  cadCnp = '';

  @Of(() => String)
  cadNume = '';

  @Of(() => String)
  cadPrenume = '';

  @Of(() => String)
  cadDataNasterii = '';

  @Of(() => String)
  cadSex = '';

  @Of(() => String)
  cadAdresa = '';

  @Of(() => String)
  cadEmail = '';

  @Of(() => String)
  cadEmailValidat = '';

  @Of(() => String)
  cadTelefon = '';

  @Of(() => String)
  cadTelefonValidat = '';

  @Of(() => String)
  cadDataInsert = '';

  @Of(() => String)
  prefAvatar = '';

  @Of(() => [UserFunctions])
  functii = [];

  @Of(() => [Role])
  roles: Role[] = [];

  @Of(() => String)
  uacPrefAvatar = '';

  @Of(() => String)
  cadIsSuspended = '';

  @Of(() => String)
  cadIsDeleted = '';

  @Of(() => String)
  uacCadUniDepartament: string = '';

  @Of(() => String)
  uacCadUniDepartamentID: string = '';

  @Of(() => String)
  uacCadUniFunctie: string = '';

  @Of(() => String)
  uacCadUniFunctieID: string = '';

  static of = Entity.of<Teacher>();
}

@Entity
export class UserFunctions {
  @Of(() => String)
  cfID = '';

  @Of(() => String)
  cfNume = '';

  static of = Entity.of<UserFunctions>();
}
