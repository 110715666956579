import { useEffect } from 'react';

import { atom, useAtomValue, useSetAtom } from 'jotai';

type WidthType = 'normal' | 'wide';

interface ContainerWidthAtom {
  width: WidthType;
  isFullScreen?: boolean;
}

const containerAtom = atom<ContainerWidthAtom>({ width: 'normal' });

export const useContainerWidth = () => {
  const atomValue = useAtomValue(containerAtom);
  const setAtom = useSetAtom(containerAtom);

  const setWidth = (width: WidthType) => {
    setAtom({ ...atomValue, width });
  };

  const setFullScreen = (isFullScreen: boolean) => {
    setAtom({ ...atomValue, isFullScreen });
  };

  useEffect(() => {
    return () => setAtom({ width: 'normal', isFullScreen: false });
  }, []);

  return {
    width: atomValue.width,
    isFullScreen: atomValue.isFullScreen,
    setWidth,
    setFullScreen,
  };
};
