import { Entity, Of } from 'entity-of';

@Entity
export class VisibilityItem {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  orgType: string = '';

  @Of(() => String)
  orgID?: string;

  static of = Entity.of<VisibilityItem>();
}
