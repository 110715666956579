import { Entity, Of } from 'entity-of';

@Entity
export class UnsubscribeInfo {
  @Of(() => String)
  uaID: string = '';

  @Of(() => String)
  uaEmail: string = '';

  @Of(() => String)
  utTip: string = '';

  @Of(() => String)
  utUsed: '0' | '1' = '0';

  @Of(() => String, { optional: true })
  utUsedDate?: string;

  static of = Entity.of<UnsubscribeInfo>();
}
