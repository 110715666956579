import { Center, Flex, FlexProps } from '@chakra-ui/react';
import { useToken } from '@ui2/hooks';
import { getOrgTypeTheme } from '@ui2/utils';

import { AdservioIcons, IconNames } from '../../atoms/Icons';

export type FeatureIconVariant = 'info' | 'error' | 'default' | 'success' | 'warning';
export type FeatureIconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const variantToColorScheme = {
  info: getOrgTypeTheme('blue'),
  error: 'red',
  default: 'gray',
  success: 'green',
  warning: 'orange',
};

const innerWrapperSizesDict = {
  xs: '1.13rem',
  sm: '1.5rem',
  md: '1.88rem',
  lg: '2.25rem',
  xl: '2.63rem',
  '2xl': '23rem',
};

const outerWrapperSizesDict = {
  xs: '1.5rem',
  sm: '2rem',
  md: '2.5rem',
  lg: '3rem',
  xl: '3.5rem',
  '2xl': '4rem',
};

const iconSizesDict = {
  xs: '0.75rem',
  sm: '1rem',
  md: '1.25rem',
  lg: '1.5rem',
  xl: '1.75rem',
  '2xl': '2rem',
};

interface FeatureIconProps extends FlexProps {
  size?: FeatureIconSize;
  variant?: FeatureIconVariant;
  colorScheme?: string;
  name: IconNames;
  isBorderless?: boolean;
  isSelected?: boolean;
}

export const FeatureIcon = ({
  variant = 'info',
  colorScheme,
  name,
  size = 'sm',
  isBorderless,
  isSelected,
  ...rest
}: FeatureIconProps) => {
  const Icon = AdservioIcons[name];

  const [shadowColor] = useToken('colors', [`${colorScheme}.100`]);

  const isSelectedStyle = isSelected
    ? {
        border: '2px solid',
        borderColor: `${colorScheme}.500`,
        shadow: `0px 0px 0px 4px ${shadowColor}`,
      }
    : {};

  return (
    <Flex {...rest}>
      <Center
        width={outerWrapperSizesDict[size]}
        height={outerWrapperSizesDict[size]}
        borderRadius="100%"
        bgColor={colorScheme ? `${colorScheme}.50` : `${variantToColorScheme[variant]}.50`}
        {...isSelectedStyle}
        transition="all 0.2s"
      >
        <Center
          width={isBorderless ? outerWrapperSizesDict[size] : innerWrapperSizesDict[size]}
          height={isBorderless ? outerWrapperSizesDict[size] : innerWrapperSizesDict[size]}
          borderRadius="100%"
          color={colorScheme ? `${colorScheme}.600` : `${variantToColorScheme[variant]}.600`}
          bgColor={colorScheme ? `${colorScheme}.100` : `${variantToColorScheme[variant]}.100`}
        >
          <Icon boxSize={iconSizesDict[size]} />
        </Center>
      </Center>
    </Flex>
  );
};
