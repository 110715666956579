import { Entity, Of } from 'entity-of';

@Entity
export class LeadershipFunctionsResponse {
  @Of(() => Number)
  leadershipFunctionId: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  nameEn: string = '';

  @Of(() => String)
  functionType: string = '';

  static of = Entity.of<LeadershipFunctionsResponse>();
}
