import { Entity, Of } from 'entity-of';

@Entity
export class Ethnicity {
  @Of(() => String)
  ethID: string = '';

  @Of(() => String)
  ethName: string = '';

  @Of(() => String)
  ethDefault: '0' | '1' = '0';

  @Of(() => String)
  ethLangKey: string = '';

  static of = Entity.of<Ethnicity>();
}
