export const getSettingOverride = function <T>(key: string, defaultValue: any) {
  const sessionValue = localStorage.getItem(key);

  if (sessionValue) {
    if (sessionValue === 'true' || sessionValue === '1') {
      return true;
    }

    if (sessionValue === 'false' || sessionValue === '0') {
      return false;
    }

    return sessionValue as T;
  }

  if (defaultValue === 'true' || defaultValue === '1') {
    return true;
  }

  if (defaultValue === 'false' || defaultValue === '0') {
    return false;
  }

  return defaultValue as T;
};
