export type SelectItemProps<T = string | number | boolean> = {
  label: string;
  value: T;
  isDisabled?: boolean;
};

import { Entity, Of } from 'entity-of';

export const EMPTY_ERROR = '__EMPTY__';

@Entity
export class BackendError {
  @Of(() => String)
  errorCode: string = EMPTY_ERROR;

  @Of(() => String)
  errorMessage?: string;

  @Of(() => String)
  errorField: string = '';

  @Of(() => String)
  errorExtraValue?: string;

  @Of(() => String)
  errorExtraValues?: string[];

  static of = Entity.of<BackendError>();
}

export type FileType = 'docgen' | 'pdf' | 'xlsx' | 'xls' | 'xml' | 'zip';
