import { Entity, Of } from 'entity-of';

@Entity
export class TenantDetails {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  tenantId: number = 0;

  @Of(() => String)
  tenantName: string = '';

  @Of(() => String)
  contractStartDate: string = '';

  @Of(() => String)
  contractEndDate: string = '';

  @Of(() => String)
  didacticPeriodEndDate: string = '';

  @Of(() => Number)
  status: number = 0;

  @Of(() => String, { nullable: true })
  createdAt: string | null = null;

  @Of(() => String, { nullable: true })
  updatedAt: string | null = null;

  @Of(() => String, { nullable: true })
  deletedAt: string | null = null;

  static of = Entity.of<TenantDetails>();
}
