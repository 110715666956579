import React, { useEffect, useState } from 'react';

import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput as CKNumberInput,
  NumberInputField,
  NumberInputProps as CKNumberInputProps,
  NumberInputStepper,
} from '@chakra-ui/react';
import { getOrgTypeTheme } from '@ui2/utils';

import { AdservioIcons } from '../Icons';

const IconSizeDict = {
  xs: '3',
  sm: '4',
  md: '5',
  lg: '6',
};

export interface NumberInputProps extends Omit<CKNumberInputProps, 'onChange'> {
  onChange?: (value: number | string) => void;
  hideSteppers?: boolean;
  placeholder?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  limitDecimals?: boolean;
}

export const NumberInput = ({
  placeholder,
  onChange,
  value,
  hideSteppers,
  textAlign,
  limitDecimals = false,
  ...props
}: React.PropsWithChildren<NumberInputProps>) => {
  const [_value, _setValue] = useState(() => String(value));
  const { size = 'md' } = props;
  const baseColor = getOrgTypeTheme('blue');

  const handleChange = (valueAsString: string, valueAsNumber: number) => {
    let formattedValue = valueAsString;

    if (limitDecimals && props.precision) {
      const regex = new RegExp(`(\\.\\d{${props.precision}})\\d+$`);

      formattedValue = valueAsString.replace(regex, '$1');
    }

    if (!formattedValue.endsWith('.')) {
      onChange?.(Number.isNaN(valueAsNumber) ? formattedValue : Number(formattedValue));
    }

    _setValue(formattedValue);
  };

  useEffect(() => {
    const next = Number.isNaN(value) || typeof value === 'undefined' ? '' : value;
    _setValue(String(next));
  }, [value]);

  return (
    <CKNumberInput w="full" onChange={handleChange} value={_value} {...props}>
      <NumberInputField
        placeholder={placeholder}
        pr={hideSteppers ? '4' : '8'}
        textAlign={textAlign}
      />
      {!hideSteppers ? (
        <NumberInputStepper>
          <NumberIncrementStepper
            border="none"
            mr={['md', 'lg'].includes(size) ? '4' : '2'}
            background="none"
          >
            <AdservioIcons.FiChevronUp
              color={`${baseColor}.500`}
              boxSize={IconSizeDict[String(size)]}
              sx={{ '&:hover': { color: `${baseColor}.700` } }}
            />
          </NumberIncrementStepper>

          <NumberDecrementStepper
            border="none"
            mr={['md', 'lg'].includes(size) ? '4' : '2'}
            background="none"
          >
            <AdservioIcons.FiChevronDown
              color={`${baseColor}.500`}
              boxSize={IconSizeDict[String(size)]}
              sx={{ '&:hover': { color: `${baseColor}.700` } }}
            />
          </NumberDecrementStepper>
        </NumberInputStepper>
      ) : null}
    </CKNumberInput>
  );
};
