import { Entity, Of } from 'entity-of';

@Entity
export class Filters {
  @Of(() => String, { optional: true })
  field?: string;

  @Of(() => Operators, { optional: true })
  operators?: Operators;
}

@Entity
export class Operators {
  @Of(() => String, { optional: true })
  gt?: string;

  @Of(() => String, { optional: true })
  lt?: string;

  @Of(() => String, { optional: true })
  eq?: string | boolean | number;

  @Of(() => String, { optional: true })
  not?: string;

  @Of(() => String, { optional: true })
  gte?: string;

  @Of(() => String, { optional: true })
  lte?: string;

  @Of(() => String, { optional: true })
  like?: string;

  @Of(() => [String], { optional: true })
  in?: string[];
}

@Entity
export class Order {
  @Of(() => String, { optional: true })
  field?: string;

  @Of(() => String, { optional: true })
  direction?: 'desc' | 'asc';
}

@Entity
export class MappedFilter {
  @Of(() => String)
  field: string = '';

  @Of(() => String)
  operator: string = '';
}
