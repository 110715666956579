import {
  IsNotEmpty,
  MaxLength,
  registerDecorator,
  ValidateIf,
  ValidationOptions,
} from 'class-validator';
import { Entity, Of } from 'entity-of';

export enum CreateBalanceAdjustmentType {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

@Entity
export class BalanceAdjustmentCreateInput {
  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.value.required' })
  tenantBillingProfileId: number = 0;

  @Of(() => String)
  accountId: string = '';

  @Of(() => Number)
  @NotZero({ message: 'errors.value.notZero' })
  @IsNotEmpty({ message: 'errors.value.required' })
  amount: number = 0;

  @Of(() => String)
  date: string = '';

  @Of(() => String)
  type: CreateBalanceAdjustmentType = CreateBalanceAdjustmentType.CREDIT;

  @Of(() => String, { optional: true })
  @ValidateIf((o) => o.observations)
  @MaxLength(255, { message: 'errors.max_255' })
  observations?: string;

  static of = Entity.of<BalanceAdjustmentCreateInput>();
}

function NotZero(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'notZero',
      target: object.constructor,
      propertyName: propertyName,
      options: validationOptions,
      validator: {
        validate(value: number) {
          return (
            value === null || typeof value === 'undefined' || Number.isNaN(value) || value !== 0
          );
        },
      },
    });
  };
}
