import { IsNotEmpty, Matches, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { GenerationMethod } from '../Timetable';

@Entity
export class CreateTimetableInput {
  @Of(() => String)
  @Matches(/^[^-\s]/, {
    message: 'errors.admission.no_white_space',
  })
  @IsNotEmpty({ message: 'errors.naming.required' })
  name: string = '';

  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  @ValidateIf((o) => o.generationMethod === GenerationMethod.FET)
  @IsNotEmpty({ message: 'errors.required' })
  scheduleID: string = '';

  @Of(() => String)
  generationMethod: GenerationMethod = GenerationMethod.MANUAL;

  static of = Entity.of<CreateTimetableInput>();
}
