import { OauthProviders } from '@constants';
import { Entity, Of } from 'entity-of';

@Entity
export class AccountOauthSearchInput {
  @Of(() => String)
  oaUaID: string = '';

  @Of(() => String)
  provider: OauthProviders = OauthProviders.GOOGLE;

  static of = Entity.of<AccountOauthSearchInput>();
}
