import { Person } from '@api/core/entities';
import { Type } from 'class-transformer';
import { ArrayNotEmpty, IsBoolean } from 'class-validator';
import { Entity, Of } from 'entity-of';

import 'reflect-metadata';

import { FillersInfo } from '../FillersInfo';

@Entity
export class CreateValidatorStep2 {
  @Of(() => String)
  type?: string = '';

  @Of(() => [String])
  fillers?: string[] = [];

  @Of(() => [FillersInfo])
  @ArrayNotEmpty({ message: 'errors.questionnaires.membersModal' })
  allFillers = FillersInfo.of({});

  @Of(() => Boolean)
  @IsBoolean()
  isAnonymousAnswer: boolean = false;

  @Of(() => Viewers, { optional: true })
  @Type(() => Viewers)
  viewers = Viewers.of({});

  @Of(() => [Person])
  @Type(() => Person)
  others: Person[] = [];

  static of = Entity.of<CreateValidatorStep2>();
}
@Entity
class Viewers {
  @Of(() => Boolean)
  @IsBoolean()
  students: boolean = false;

  @Of(() => Boolean)
  @IsBoolean()
  teachers: boolean = false;

  @Of(() => Boolean)
  @IsBoolean()
  parents: boolean = false;

  @Of(() => [String], { optional: true })
  others?: string[];

  @Of(() => [String], { optional: true })
  defaultOthersViewers?: string[];

  static of = Entity.of<Viewers>();
}
