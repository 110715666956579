import { IsAfterStartHour } from '@lib/validators';
import { checkOverlap } from '@modules/timetable/domains/schedule/utils';
import {
  IsNotEmpty,
  registerDecorator,
  ValidationArguments,
  ValidationOptions,
} from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class CreateSlotInput {
  @Of(() => String, { optional: true })
  id?: string;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.startHour.required' })
  @CheckStartHoursOverlap({ message: 'errors.startHour.hasOverlaps' })
  startHour: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.endHour.required' })
  @IsAfterStartHour({ message: 'errors.endHour.beforeStartHour' })
  endHour: string = '';

  static of = Entity.of<CreateSlotInput>();
}

function CheckStartHoursOverlap(validationOptions?: ValidationOptions) {
  return function (object: Object, propertyName: string) {
    registerDecorator({
      name: 'checkStartHoursOverlap',
      target: object.constructor,
      propertyName: propertyName,
      constraints: ['rest', 'endHour'],
      options: validationOptions,
      validator: {
        validate(value: string, args: ValidationArguments) {
          const rest = (args.object as any)['rest'];
          const endHour = (args.object as any)['endHour'];

          return !checkOverlap({ startHour: value, endHour }, rest);
        },
      },
    });
  };
}
