import { useQuery, UseQueryOptions } from 'react-query';

import { County, LegacyPaginatedResponse } from '@api/core/entities';
import { httpClient } from '@lib/http';
import { computeUrlParams } from '@lib/utils';
import { AxiosError } from 'axios';

interface QueryInput {
  taraID?: string;
  taraCod?: string;
  limit?: number;
}

const searchCounties = ({ queryKey }: UseQueryOptions<QueryInput>) => {
  const [_, queryPayload] = queryKey ?? [];
  const { taraID, taraCod, limit } = queryPayload as QueryInput;

  let url = '/adrese/judete';

  const params = computeUrlParams({
    _limit: limit || -1,
    taraID,
    taraCod,
  });

  if (params) {
    url += `?${params}`;
  }

  return httpClient<LegacyPaginatedResponse<County>, County>(url).get();
};

export const useCountiesQuery = (
  queryInput: QueryInput,
  options?: UseQueryOptions<LegacyPaginatedResponse<County>, AxiosError>
) => {
  return useQuery<LegacyPaginatedResponse<County>, AxiosError>(
    ['counties', queryInput],
    searchCounties,
    options
  );
};
