import { School } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

import { Subscription, SubscriptionStatus } from '../Subscription';

@Entity
export class SchoolSubscriptionDetails {
  @Of(() => School)
  school?: School;

  @Of(() => [Subscription])
  subscriptions: Subscription[] = [];

  @Of(() => Boolean)
  isSelfManaged?: boolean;

  @Of(() => Boolean)
  isSubscriptionsActive?: boolean;

  @Of(() => String)
  status: SubscriptionStatus = 'disabled';

  @Of(() => String)
  startDate = '';

  @Of(() => String)
  endDate = '';

  static of = Entity.of<SchoolSubscriptionDetails>();
}
