import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';

import { FEATURE_FLAGS, ORG_UNIVERSITY } from '@constants';
import { useRouter } from '@lib/router';
import { getSettingOverride, useStorageSession } from '@lib/utils';
import { hasFeature, isFeatureEnabled, useHasAccess } from '@lib/utils/session/hasAccess';
import { LinkButtonItem } from '@modules/shared';
import { useBannerHeight } from '@modules/Site/components/disruptionBanner';
import { Vectors } from '@ui2/assets';
import {
  AdservioIcons,
  AppLink,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  IconButton,
  Image,
  Text,
  TextProps,
} from '@ui2/components';
import { useBreakpointValue } from '@ui2/hooks';
import { getOrgTypeTheme } from '@ui2/utils';

interface SidenavContainerProps {
  isOpen: boolean;
  isMobile?: boolean;
  onToggle: () => void;
  onMouseLeave: () => void;
}

export const SidenavContainer = ({
  isOpen,
  isMobile,
  children,
  onToggle,
  onMouseLeave,
}: PropsWithChildren<SidenavContainerProps>) => {
  const handleMouseLeave = useBreakpointValue({ sm: () => {}, md: onMouseLeave });

  const [delayedTransition, setDelayedTransition] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setDelayedTransition(false);
    }, 500);
  });

  const { bannerHeight } = useBannerHeight();

  return (
    <>
      <Box
        display={isMobile ? 'block' : 'none'}
        position="fixed"
        top={`${bannerHeight}px`}
        left="0"
        width="100vw"
        height={`calc(min(100svh, 100vh) - ${bannerHeight}px)`}
        backdropFilter="blur(5px)"
        backgroundColor="rgba(16, 24, 40, .32)"
        zIndex={isOpen ? 'overlay' : 0}
        opacity={isOpen ? '1' : '0'}
        onClick={isOpen ? onToggle : () => {}}
        sx={{ '@media print': { display: 'none' } }}
      />

      <Box
        className="sidenav-container"
        position="fixed"
        top={`${bannerHeight}px`}
        left={isMobile && !isOpen ? '-6rem' : '0'}
        height={`calc(min(100svh, 100vh) - ${bannerHeight}px)`}
        width={isOpen ? '20rem' : '5.25rem'}
        transition={delayedTransition ? '' : 'width .2s ease-in-out, left .2s ease-in-out'}
        zIndex="overlay"
        onMouseLeave={handleMouseLeave}
        sx={{ '@media print': { display: 'none' } }}
      >
        {children}
      </Box>
    </>
  );
};

interface SidenavWrapperProps {
  isHoverOpen: boolean;
  isSubmenuOpen: boolean;
}

export const SidenavWrapper = ({
  isHoverOpen,
  isSubmenuOpen,
  children,
}: PropsWithChildren<SidenavWrapperProps>) => {
  return (
    <Flex
      position="absolute"
      top="0"
      left="0"
      direction="column"
      height="100%"
      width="100%"
      py="6"
      px="3"
      backgroundColor="white"
      boxShadow={isHoverOpen && !isSubmenuOpen ? 'side' : ''}
      borderRight="1px solid"
      borderRightColor="gray.50"
    >
      {children}
    </Flex>
  );
};

interface SidenavToggleButtonProps {
  isOpen: boolean;
  isHoverOpen?: boolean;
  onToggle: () => void;
}

export const SidenavToggleButton = ({
  isOpen,
  isHoverOpen,
  onToggle,
}: SidenavToggleButtonProps) => {
  return (
    <Center
      position="absolute"
      right="-0.75rem"
      top="25px"
      width="1.5rem"
      height="1.5rem"
      borderRadius="full"
      backgroundColor="gray.100"
      zIndex="banner"
      transition="background-color .1s ease-in-out, opacity .1s ease-in-out"
      cursor={isHoverOpen ? 'default' : 'pointer'}
      opacity={isHoverOpen ? 0 : 1}
      _hover={{ backgroundColor: 'gray.200' }}
      onClick={isHoverOpen ? undefined : onToggle}
    >
      <AdservioIcons.FiChevronRight
        color="gray.700"
        transform={`rotate(${isOpen ? '180deg' : 0})`}
        transition="transform .2s ease-in-out"
      />
    </Center>
  );
};

interface SidenavLogoProps {
  isOpen: boolean;
  logoUrl: LogoUrl;
}

export interface LogoUrl {
  url: string;
  redirectRule?: 'window' | 'push';
}

export const SidenavLogo = ({ isOpen, logoUrl }: SidenavLogoProps) => {
  const { orgType } = useStorageSession();

  const isTUIASI = getSettingOverride<boolean>(
    'TUIASI_OAUTH_ENABLED',
    window.env?.TUIASI_OAUTH_ENABLED
  );

  let logoA = Vectors.LogoNavA;
  let logoFull = Vectors.LogoNavFull;

  switch (orgType) {
    case ORG_UNIVERSITY:
      logoA = Vectors.LogoNavAUni;
      logoFull = Vectors.LogoNavFullUni;
      break;
  }

  if (isTUIASI) {
    logoA = Vectors.LogoNavTi;
    logoFull = Vectors.LogoNavFullTi;
  }

  return (
    <AppLink href={logoUrl?.url} isNative={logoUrl?.redirectRule !== 'push'} width="100%">
      <Flex mb={'10'} zIndex="banner" position={'relative'} top="0">
        <Image
          position="relative"
          src={logoA}
          height={isOpen ? '17px' : '25px'}
          top={isOpen ? '8px' : '0px'}
          opacity={isOpen ? 0 : 1}
          transition="all .2s ease-in-out"
          ml="18px"
          cursor="pointer"
        />

        <Image
          position="relative"
          left="-34px"
          src={logoFull}
          height={isTUIASI ? '32px' : '25px'}
          width={isOpen ? (isTUIASI ? '80px' : '104px') : '0'}
          opacity={isOpen ? 1 : 0}
          transition="all .2s ease-in-out"
          ml="18px"
          cursor="pointer"
        />
      </Flex>
    </AppLink>
  );
};

interface SidenavButtonsWrapperProps {
  onMouseEnter: () => void;
}

export const SidenavButtonsWrapper = ({
  onMouseEnter,
  children,
}: PropsWithChildren<SidenavButtonsWrapperProps>) => {
  const [canScrollPrevious, setCanScrollPrevious] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const gradientTop = 'linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)';
  const gradientBottom = 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)';

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    setTimeout(() => {
      if (!ref.current) {
        return;
      }

      const containerHeight = ref.current.clientHeight;
      const scrollHeight = ref.current.scrollHeight;
      const currentScroll = ref.current.scrollTop || 0;
      const _canScrollNext = scrollHeight - containerHeight !== Math.round(currentScroll);

      if (_canScrollNext) {
        setCanScrollNext(true);
      }
    }, 600);

    const onScroll = (e) => {
      if (!ref.current) return;

      const containerHeight = ref.current.clientHeight;
      const scrollHeight = ref.current.scrollHeight;
      const currentScroll = ref.current.scrollTop;

      if (currentScroll < 15) {
        setCanScrollPrevious(false);
      } else if (!canScrollPrevious) {
        setCanScrollPrevious(true);
      }

      if (scrollHeight - containerHeight <= Math.round(currentScroll + 15)) {
        setCanScrollNext(false);
      } else if (!canScrollNext) {
        setCanScrollNext(true);
      }

      e.preventDefault();
    };

    ref.current.addEventListener('scroll', onScroll);

    return () => {
      ref.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <Flex
      height={`calc(100% - 65px)`}
      direction="column"
      position="relative"
      zIndex="banner"
      onMouseEnter={onMouseEnter}
    >
      <Grid
        ref={ref}
        templateColumns="1fr"
        rowGap="2"
        maxHeight="100%"
        p="1"
        justifyContent="left"
        overflowY="auto"
        css={{
          '::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          width: '100%',
          height: canScrollPrevious ? '7.5rem' : '0rem',
          background: gradientTop,
          zIndex: 'dropdown',
          transition: 'height .2s ease-in-out',
          pointerEvents: 'none',
        }}
        _after={{
          content: '""',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: canScrollNext ? '7.5rem' : '0rem',
          background: gradientBottom,
          zIndex: 'dropdown',
          transition: 'height .2s ease-in-out',
          pointerEvents: 'none',
        }}
      >
        {children}
      </Grid>
    </Flex>
  );
};

interface SidenavSubmenuWrapperProps {
  isPHP?: boolean;
  isOpen: boolean;
  isMobile?: boolean;
  item: LinkButtonItem | null;
  onBack: () => void;
  onClose: () => void;
  onNavigate?: () => void;
}

export const SidenavSubmenuWrapper = ({
  isPHP,
  isOpen,
  isMobile,
  item,
  onBack,
  onClose,
  onNavigate,
}: SidenavSubmenuWrapperProps) => {
  return (
    <Flex
      direction="column"
      position="absolute"
      top="0"
      left={
        !isOpen && !item
          ? '-20rem'
          : isOpen && isMobile && item
            ? '0'
            : !isOpen && item
              ? '5.25rem'
              : isOpen && item
                ? '20rem'
                : '0'
      }
      width="20rem"
      height="min(100svh, 100vh)"
      py="4"
      px="3"
      backgroundColor="white"
      boxShadow={isOpen && !isMobile ? 'side' : ''}
      borderRight="1px solid"
      borderRightColor="gray.50"
      opacity={item ? 1 : 0}
      transition={isMobile ? '' : 'all .2s ease-in-out'}
      zIndex={isMobile && item ? 'skipLink' : 'initial'}
    >
      <Flex justifyContent={isMobile ? 'space-between' : 'flex-end'} mb="8">
        {isMobile && (
          <IconButton
            variant="ghost"
            colorScheme="gray"
            icon={<AdservioIcons.FiArrowLeft boxSize="1.5rem" />}
            aria-label={'Close submenu'}
            onClick={onBack}
          />
        )}

        <IconButton
          variant="ghost"
          colorScheme="gray"
          icon={<AdservioIcons.FiX boxSize="1.5rem" />}
          aria-label={'Close submenu'}
          onClick={isMobile ? onClose : onBack}
        />
      </Flex>

      {item && (
        <Grid
          templateColumns="1fr"
          rowGap="2"
          maxHeight="100%"
          p="1"
          justifyContent="left"
          overflowY="auto"
          css={{
            '::-webkit-scrollbar': {
              display: 'none',
            },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          }}
        >
          <Text
            px="4"
            fontSize="sm"
            fontWeight="semibold"
            color="gray.300"
            sx={{ '&::first-letter': { textTransform: 'capitalize' } }}
          >
            {item.label}
          </Text>

          {item.subitems?.map((item, index) => (
            <LinkButton
              key={index}
              isPHP={isPHP}
              isOpen={isOpen}
              item={item}
              onNavigate={onNavigate}
            />
          ))}
        </Grid>
      )}
    </Flex>
  );
};

interface GroupTitleProps extends TextProps {
  isOpen: boolean;
  text: string;
}

export const GroupTitle = ({ isOpen, text, ...rest }: GroupTitleProps) => {
  return (
    <Text
      width={isOpen ? '100%' : '0'}
      opacity={isOpen ? 1 : 0}
      overflow="hidden"
      px="4"
      fontSize="sm"
      fontWeight="semibold"
      color="gray.300"
      transition="all .2s ease-in-out"
      isTruncated
      {...rest}
    >
      {text}
    </Text>
  );
};

interface LinkButtonProps {
  isPHP?: boolean;
  isOpen?: boolean;
  item: LinkButtonItem;
  onSubmenuOpen?: (item: LinkButtonItem) => void;
  onNavigate?: () => void;
}

export const LinkButton = (props: LinkButtonProps) => {
  const { isPHP, item } = props;

  const hasAccess = useHasAccess(item.accessRules);
  const featureEnabled = item.featureFlag ? isFeatureEnabled(item.featureFlag) : true;

  const { url, subitems } = item;

  return (
    <>
      {hasAccess && featureEnabled ? (
        <>
          {subitems ? (
            <NavigationItem {...props} />
          ) : (
            <AppLink
              href={url}
              isNative={isPHP || item.redirectRule !== 'push'}
              nativeTarget={item.redirectRule === 'blank' ? '_blank' : ''}
              width="100%"
            >
              <NavigationItem {...props} />
            </AppLink>
          )}
        </>
      ) : null}
    </>
  );
};

const NavigationItem = ({ isOpen, item, onNavigate, onSubmenuOpen }: LinkButtonProps) => {
  const { t } = useTranslation('shared');
  const { lang } = useRouter();
  let pathMatch = useRouteMatch(String(`/${lang}/${item.url}`));
  let isPartialMatch = false;

  const buttonTheme = getOrgTypeTheme('blue');

  if (item.matchingUrls) {
    item.matchingUrls.some((url) => {
      if (useRouteMatch(String(`/${lang}/${url}`))) {
        isPartialMatch = true;
        return true;
      }
    });
  }

  const isSelected = pathMatch?.isExact || isPartialMatch || (pathMatch && item.subitems);

  const { icon, label: text, url, subitems, isNew } = item;
  const Icon = icon && AdservioIcons[icon] ? AdservioIcons[icon] : null;

  const handleClick = () => {
    if (onSubmenuOpen) {
      onSubmenuOpen(item);
    } else if (url) {
      onNavigate?.();
    }
  };

  return (
    <Button
      role="group"
      width="100%"
      variant={isSelected ? 'solid' : 'ghost'}
      colorScheme={isSelected ? buttonTheme : 'gray'}
      aria-label={text}
      justifyContent="start"
      _hover={{ backgroundColor: isSelected ? `${buttonTheme}.500` : `${buttonTheme}.100` }}
      onClick={handleClick}
    >
      {Icon && (
        <Icon
          mr={isOpen ? '3' : '0'}
          color={isSelected ? 'white' : 'gray.400'}
          _groupHover={{ color: isSelected ? 'white' : `${buttonTheme}.400` }}
          transition="color .2s ease-in-out, margin-right .2s ease-in-out"
        />
      )}

      <Text
        width={isOpen ? '100%' : '0'}
        fontWeight="semibold"
        fontFamily="Nunito"
        textAlign="left"
        opacity={isOpen ? '1' : '0'}
        transition="all .2s ease-in-out"
        color={isSelected ? 'white' : 'gray.700'}
        sx={{ '&::first-letter': { textTransform: 'capitalize' } }}
        _groupHover={{ color: isSelected ? 'white' : `${buttonTheme}.700` }}
        isTruncated
      >
        {text}
      </Text>

      {isNew && (
        <Badge
          transition={isOpen ? 'opacity, width .2s ease-in-out' : ''}
          width={isOpen ? 'initial' : '0'}
          opacity={isOpen ? '1' : '0'}
          px={isOpen ? '2' : '0'}
          backgroundColor="green.500"
          colorScheme="green"
          color="white"
          size="md"
          mr={isOpen ? '2' : '0'}
        >
          {t('general.new')}
        </Badge>
      )}

      {hasFeature(FEATURE_FLAGS.FLAG_PROMOTE_FEATURE_UPGRADE) && !hasFeature(item.featureFlag) && (
        <AdservioIcons.Diamond
          transition={isOpen ? 'opacity, width .2s ease-in-out' : ''}
          color={isSelected ? 'white' : `${buttonTheme}.500`}
          width={isOpen ? 'initial' : '0'}
          opacity={isOpen ? '1' : '0'}
        />
      )}

      {subitems && (
        <AdservioIcons.FiChevronRight
          opacity={isOpen ? '1' : '0'}
          width={isOpen ? '1.25rem' : '0'}
          _groupHover={{ color: isSelected ? 'white' : `${buttonTheme}.400` }}
          transition="color .2s ease-in-out, width .3s ease-in-out"
        />
      )}
    </Button>
  );
};
