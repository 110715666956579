import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class HolidaysQueryInput extends SimpleQueryInput {
  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  @Of(() => String)
  holName: string = '';

  static of = Entity.of<HolidaysQueryInput>();
}
