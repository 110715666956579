import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const ModalTheme: ComponentMultiStyleConfig = {
  parts: ['overlay', 'dialogContainer', 'dialog', 'header', 'closeButton', 'body', 'footer'],
  baseStyle: {
    dialog: {
      boxShadow: '3xl',
    },
    header: {
      pt: '6',
      pr: '16',
      borderTopRadius: '2xl',
    },
    closeButton: {
      top: '6',
      right: '6',
    },
    body: {
      py: 0,
    },
    footer: {
      pt: '12',
      borderBottomRadius: '2xl',
    },
  },
};
