import { useState } from 'react';

import { AdservioIcons, Box, Skeleton } from '../../atoms';
import { Modal } from '../../molecules/Modal';
import { AspectRatioType, ImageCropper, useImageCropper } from '../ImageCropper';

interface ImageCropModalProps {
  title?: string;
  image: string;
  isOpen: boolean;
  mimeType?: string;
  aspectRatio: AspectRatioType;
  cropperHeight?: string;
  modalSize?: string;
  onClose: () => void;
  onChange?: (croppedImage: string) => void;
}

const heightDict: Record<AspectRatioType, string> = {
  '16:9': '540px',
  '9:15': '400px',
  '4:3': '350px',
  '1:1': '300px',
  '2:3': '600px',
  '2:1': '450px',
};

const ModalSizeDict = {
  '16:9': '5xl',
  '9:15': 'sm',
  '2:3': 'xl',
  '2:1': '5xl',
};

export const ImageCropModal = ({
  isOpen,
  image,
  mimeType,
  aspectRatio,
  title,
  cropperHeight,
  modalSize,
  onClose,
  onChange,
}: ImageCropModalProps) => {
  const [isReady, setIsReady] = useState(false);
  const { getImageCropperProps, handleCrop, ref } = useImageCropper({
    src: image,
    aspectRatio,
    onChange,
    mimeType,
  });

  const handleSubmit = () => {
    handleCrop();
    onClose();
  };

  const handleClose = () => {
    onClose();
    setIsReady(false);
  };

  return (
    <Modal
      title={title ?? 'Crop Image'}
      isOpen={isOpen}
      onClose={handleClose}
      onCancel={handleClose}
      onSubmit={handleSubmit}
      size={modalSize || ModalSizeDict[aspectRatio]}
      renderFooter={(Cancel, Submit) => (
        <>
          <Cancel />
          <Submit leftIcon={<AdservioIcons.FiCheck />} />
        </>
      )}
    >
      <Box position="relative" h={cropperHeight || heightDict[aspectRatio]}>
        <Skeleton h="100%" w="100%" isLoaded={isReady}>
          <ImageCropper
            ref={ref}
            {...getImageCropperProps()}
            ready={() => setIsReady(true)}
            style={{ height: '100%' }}
            dragMode="move"
            viewMode={0}
          />
        </Skeleton>
      </Box>
    </Modal>
  );
};
