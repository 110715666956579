import { Entity, Of } from 'entity-of';

@Entity
export class AssociatedFaculty {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  parentId: number = 0;

  @Of(() => String)
  parentName: string = '';

  @Of(() => String)
  type: string = '';

  @Of(() => String)
  acronym: string = '';

  @Of(() => String)
  uniqueAcronym: string = '';

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => Number)
  idPromotie: number = 0;

  static of = Entity.of<AssociatedFaculty>();
}
