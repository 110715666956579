import { Entity, Of } from 'entity-of';

import { AcademicStructureType } from './input';

@Entity
export class AcademicStructure {
  @Of(() => Number)
  departmentId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => String)
  acronym: string = '';

  @Of(() => String)
  uniqueAcronym: string = '';

  @Of(() => String, { optional: true })
  cycleName?: string;

  @Of(() => String, { optional: true })
  domainName?: string;

  @Of(() => String, { optional: true })
  facultyName?: string;

  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  parentId: number = 0;

  @Of(() => String)
  parentName: string = '';

  @Of(() => String, { optional: true })
  programName?: string;

  @Of(() => String, { optional: true })
  seriesName?: string;

  @Of(() => String)
  type?: AcademicStructureType;

  @Of(() => String, { optional: true })
  yearName?: string;

  static of = Entity.of<AcademicStructure>();
}
