import { useRef, useState } from 'react';
import Cropper from 'react-cropper';

export type AspectRatioType = '4:3' | '16:9' | '9:15' | '1:1' | '2:3' | '2:1';

const AspectRatioDict: Record<AspectRatioType, number> = {
  '4:3': 4 / 3,
  '16:9': 16 / 9,
  '9:15': 9 / 15,
  '1:1': 1 / 1,
  '2:3': 2 / 3,
  '2:1': 2 / 1,
};

export interface ImageCropperProps {
  src: string;
  mimeType?: string;
  aspectRatio: AspectRatioType;
  dragMode?: 'crop' | 'move' | 'none';
  onChange?: (croppedImage: string) => void;
}

export const useImageCropper = ({
  src,
  onChange,
  mimeType,
  aspectRatio = '16:9',
}: ImageCropperProps) => {
  const ref = useRef<HTMLImageElement>(null);
  const [cropData, setCropData] = useState('#');

  const handleCrop = () => {
    if (ref && ref?.current) {
      const imageEl: any = ref.current;
      const cropper: any = imageEl.cropper;
      const croppedCanvas = cropper.getCroppedCanvas().toDataURL(mimeType ?? undefined);
      setCropData(croppedCanvas);
      onChange?.(croppedCanvas);
    }
  };

  const getImageCropperProps = () => ({
    src,
    zoomTo: 1,
    aspectRatio: AspectRatioDict[aspectRatio],
    minCropBoxHeight: 10,
    minCropBoxWidth: 10,
    background: false,
    responsive: true,
    autoCropArea: 1,
    checkOrientation: false,
    guides: true,
  });

  return {
    ref,
    cropData,
    handleCrop,
    getImageCropperProps,
  };
};

export const ImageCropper = Cropper;
