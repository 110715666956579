import React from 'react';

import { Heading, HeadingProps, Text, TextProps } from '@chakra-ui/react';

export const Title = ({ children, ...props }: React.PropsWithChildren<HeadingProps>) => {
  return (
    <Heading as="h4" color="gray.700" fontSize="lg" fontWeight="medium" {...props}>
      {children}
    </Heading>
  );
};

export const Subtitle = ({ children, ...props }: React.PropsWithChildren<TextProps>) => {
  return (
    <Text color={'gray.400'} fontSize="sm" fontWeight="400" {...props}>
      {children}
    </Text>
  );
};
