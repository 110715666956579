import { Entity, Of } from 'entity-of';

@Entity
export class CertificateTemplateInput {
  @Of(() => Object, { optional: true })
  file?: File;

  @Of(() => String)
  cerTypeId?: string = '';

  @Of(() => [String])
  uaInfo?: string[] = [];

  static of = Entity.of<CertificateTemplateInput>();
}
