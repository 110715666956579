import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Modal as ModalCK,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps as CKModalProps,
} from '@chakra-ui/react';

import {
  AdservioIcons,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Text,
  TextProps,
} from '../../atoms';

export interface CustomModalProps extends Omit<CKModalProps, 'children'> {
  bodyProps?: ModalBodyProps;
  contentProps?: ModalContentProps;
  hideFooter?: boolean;
  hideHeaderDivider?: boolean;
  hideCloseButton?: boolean;
  hasLeftMarker?: boolean;
  leftMarkerSx?: BoxProps;
  onSubmit?(...parameters): void;
  renderHeader?: (
    Title: React.FC<TextProps>,
    Subtitle: React.FC<TextProps>,
    Back: React.FC<Omit<IconButtonProps, 'aria-label'>>
  ) => React.ReactElement;
  renderFooter?: (
    Cancel: React.FC<ButtonProps>,
    Submit: React.FC<ButtonProps>
  ) => React.ReactElement;
}

export const CustomModal = ({
  children,
  isOpen = false,
  bodyProps,
  contentProps,
  hideFooter,
  hideHeaderDivider,
  hideCloseButton,
  hasLeftMarker,
  leftMarkerSx,
  onClose,
  onSubmit,
  renderFooter,
  renderHeader,
  ...props
}: React.PropsWithChildren<CustomModalProps>) => {
  const { t } = useTranslation('shared');

  const containersStyles = hasLeftMarker ? { pl: '6' } : {};

  const Title = ({ children, ...props }: TextProps) => (
    <Text data-testid="modal-title" {...props}>
      {children}
    </Text>
  );

  const Subtitle = ({ children, ...props }: TextProps) => (
    <Text
      fontSize="0.875rem"
      fontWeight="normal"
      color="gray.400"
      data-testid="modal-subtitle"
      {...props}
    >
      {children}
    </Text>
  );

  const Cancel = ({ children, ...props }: ButtonProps) => (
    <Button colorScheme="red" variant="outline" mr="4" onClick={onClose} {...props}>
      {children || t('general.cancel')}
    </Button>
  );

  const Submit = ({ children, ...props }: ButtonProps) => (
    <Button onClick={onSubmit} {...props}>
      {children || t('general.confirm')}
    </Button>
  );

  const Back = (props: Omit<IconButtonProps, 'aria-label'>) => (
    <IconButton
      size="sm"
      variant="ghost"
      aria-label="back"
      colorScheme="gray"
      icon={<AdservioIcons.FiArrowLeft boxSize="6" />}
      {...props}
    />
  );

  return (
    <ModalCK isOpen={isOpen} isCentered onClose={onClose} scrollBehavior="inside" {...props}>
      <ModalOverlay backdropFilter="blur(5px)" />

      <ModalContent
        borderRadius="2xl"
        overflow={hasLeftMarker ? 'hidden' : 'initial'}
        {...contentProps}
      >
        {hasLeftMarker && (
          <Box position="absolute" left="0" top="0" height="100%" width="2" {...leftMarkerSx} />
        )}

        <ModalHeader {...containersStyles}>{renderHeader?.(Title, Subtitle, Back)}</ModalHeader>

        {!hideCloseButton && (
          <ModalCloseButton>
            <AdservioIcons.FiX boxSize="6" />
          </ModalCloseButton>
        )}

        <ModalBody px={{ base: '4', sm: '6' }} {...containersStyles} {...bodyProps}>
          {!hideHeaderDivider && (
            <Box mb="6" h="1px" borderBottom="1px solid" borderColor="gray.200" />
          )}

          {children}
        </ModalBody>

        {!hideFooter ? (
          <ModalFooter p={{ base: '4', sm: '6' }} {...containersStyles}>
            {renderFooter?.(Cancel, Submit)}
          </ModalFooter>
        ) : (
          <Box minHeight="6" />
        )}
      </ModalContent>
    </ModalCK>
  );
};
