import React, { PropsWithChildren } from 'react';

import { Image, StackProps, Text, VStack } from '@ui2/components';

import { EmptyFallbackTitle, EmptyFallbackWrapper } from './EmptyFallback.layout';

interface EmptyFallbackProps extends StackProps {
  imgSrc: string;
  title: string;
  description?: string | React.ReactNode;
}

export const EmptyFallback = ({
  title,
  imgSrc,
  children,
  description,
  ...rest
}: PropsWithChildren<EmptyFallbackProps>) => {
  return (
    <EmptyFallbackWrapper>
      <VStack spacing={12} w="full" h="full" p={6} justifyContent="center" {...rest}>
        <Image data-testid="empty-fallback-image" src={imgSrc} alt={title} />
        <VStack spacing="none" maxW="80%">
          <EmptyFallbackTitle title={title} mb={description ? '2' : '0'} />

          {description && (
            <Text data-testid="empty-fallback-description" textAlign="center" color="gray.400">
              {description}
            </Text>
          )}
        </VStack>
        {children}
      </VStack>
    </EmptyFallbackWrapper>
  );
};
