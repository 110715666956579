import { IsTreeShape } from '@modules/shared/components/SelectOrganizationTree/entities/CustomDecorators/IsTreeSelectionShape';
import { TreeSelection } from '@modules/shared/components/SelectOrganizationTree/entities/TreeSelection';
import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class AssignPackgeInputDefinePeriod {
  @Of(() => TreeSelection)
  @IsTreeShape({ message: 'errors.selection.required' })
  selection = {};

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.startDate.required' })
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.recurrence.required' })
  recurrence: string = 'monthly';

  static of = Entity.of<AssignPackgeInputDefinePeriod>();
}
