import { Entity, Of } from 'entity-of';

@Entity
export class CreateTransfersInput {
  @Of(() => Number)
  elevID: number = 0;

  @Of(() => String)
  tcTransferDate: string | null = null;

  @Of(() => Number)
  tcShowInReports: number = 0;

  @Of(() => Number)
  tcTransferNumber: number = 1;

  @Of(() => Boolean, { optional: true })
  isDupeTransferNumber?: boolean;

  @Of(() => TransferPeriod, { optional: true })
  tcTransferPeriod?: TransferPeriod;

  @Of(() => Number)
  tcFromClID: number = 0;

  @Of(() => Number)
  tcToClID: number = 0;

  @Of(() => String, { optional: true })
  tcFromClName?: string;

  @Of(() => [Cmcs])
  cmcs: Cmcs[] = [];

  @Of(() => String, { optional: true })
  accountIds?: Record<string, string[]>;

  @Of(() => String, { optional: true })
  schoolYear?: string;

  static of = Entity.of<CreateTransfersInput>();
}

@Entity
export class Cmcs {
  @Of(() => Number, { optional: true })
  fromCmcID?: number | null;

  @Of(() => Number)
  toCmcID: number = 0;

  @Of(() => Number)
  copyClassbookData: number = 0;

  static of = Entity.of<Cmcs>();
}

@Entity
export class TransferPeriod {
  @Of(() => String)
  start: string = '';

  @Of(() => String)
  end: string = '';

  static of = Entity.of<TransferPeriod>();
}
