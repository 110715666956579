import { useQuery, UseQueryOptions } from 'react-query';

import { httpClient } from '@lib/http';

import { AcademicYear } from '../entities';

const getAcademicYearsQuery = () => {
  let url = '/uni/academic-years';

  return httpClient<AcademicYear[]>(url).get();
};

export const useAcademicYearsQuery = (options?: UseQueryOptions<any>) => {
  return useQuery<AcademicYear[]>(['academicYear'], getAcademicYearsQuery, options);
};
