import { Entity, Of } from 'entity-of';

@Entity
export class StudentDetails {
  @Of(() => String)
  elevID? = '';

  @Of(() => String)
  elObservatii? = '';

  static of = Entity.of<StudentDetails>();
}
