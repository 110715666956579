import { Entity, Of } from 'entity-of';

import { LinkButtonItem } from './LinkButtonItem';

@Entity
export class LinkButtonsGroup {
  @Of(() => String, { optional: true })
  title?: string;

  @Of(() => Boolean, { optional: true })
  emptyTitle?: boolean;

  @Of(() => [LinkButtonItem])
  items: LinkButtonItem[] = [];

  static of = Entity.of<LinkButtonsGroup>();
}
