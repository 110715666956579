import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class CountiesByCountryIdInput extends SimpleQueryInput {
  @Of(() => [String], { optional: true })
  taraID?: string[] = [];

  @Of(() => String, { optional: true })
  liceuOrgType?: string;

  static of = Entity.of<CountiesByCountryIdInput>();
}
