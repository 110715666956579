import { Person } from '@api/core/entities';
import { IsNotEmpty, MaxLength, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class Certificate {
  @Of(() => String)
  cerType = '';

  @Of(() => String)
  cerDate: string = '';

  @Of(() => String)
  cerNumber = '';

  @Of(() => String)
  cerReason = '';

  @Of(() => CertificateFields)
  cerData: CertificateFields = CertificateFields.of({});

  @Of(() => String, { optional: true })
  cerAscID: string = '';

  @Of(() => String, { optional: true })
  cerClID: string = '';

  @Of(() => String, { optional: true })
  cerCreatedAt: string = '';

  @Of(() => String, { optional: true })
  cerFormDataID: string = '';

  @Of(() => String, { optional: true })
  cerID: string = '';

  @Of(() => Person, { optional: true })
  cerPerson: Person = Person.of({});

  @Of(() => String, { optional: true })
  cerReasonID: string = '';

  @Of(() => String, { optional: true })
  cerUaID: string = '';

  @Of(() => String, { optional: true })
  cerUpdatedAt: string = '';

  @Of(() => Boolean, { optional: true })
  canDelete?: boolean;
  static of = Entity.of<Certificate>();
}

@Entity
export class CertificateFields {
  @Of(() => String, { optional: true })
  cerType: string = '';

  @Of(() => String, { optional: true })
  cerDate: string = '';

  @Of(() => String, { optional: true })
  id: string = '';

  @Of(() => String, { optional: true })
  last_name: string = '';

  @Of(() => String, { optional: true })
  first_name: string = '';

  @Of(() => String, { optional: true })
  school: string = '';

  @Of(() => String, { optional: true })
  class: string = '';

  @Of(() => String, { optional: true })
  class_year: string = '';

  @ValidateIf((o, val) => {
    if (['4'].includes(o?.cerType)) {
      return true;
    }
    return false;
  })
  @IsNotEmpty({ message: 'errors.award.required' })
  @Of(() => String, { optional: true })
  award: string = '';

  @ValidateIf((o, val) => {
    if (['2', '3', '8'].includes(o?.cerType)) {
      return true;
    }
    return false;
  })
  @MaxLength(97, { message: 'errors.reason.max_97' })
  @IsNotEmpty({ message: 'errors.reason.required' })
  @Of(() => String, { optional: true })
  reason: string = '';

  @Of(() => String, { optional: true })
  general_average: string = '';

  @Of(() => String, { optional: true })
  diploma_background: string = '';

  @Of(() => String, { optional: true })
  show_logo: string = 'false';

  @Of(() => String, { optional: true })
  county: string = '';

  @Of(() => String, { optional: true })
  director: string = '';

  @Of(() => String, { optional: true })
  headmaster: string = '';

  @Of(() => String, { optional: true })
  issued_date: string = '';

  @Of(() => String, { optional: true })
  blank: string = '';

  @Of(() => String, { optional: true })
  cif: string = '';

  @Of(() => String, { optional: true })
  city: string = '';

  @Of(() => String, { optional: true })
  father_init: string = '';

  @Of(() => String, { optional: true })
  formal_title: string = '';

  @Of(() => String, { optional: true })
  graduate: string = '';

  @Of(() => String, { optional: true })
  issued_by: string = '';

  @Of(() => String, { optional: true })
  number: string = '';

  @Of(() => String, { optional: true })
  of: string = '';

  @Of(() => String, { optional: true })
  secretary: string = '';

  @Of(() => String, { optional: true })
  teacher: string = '';

  @Of(() => String, { optional: true })
  social_number: string = '';

  @Of(() => String, { optional: true })
  year: string = '';

  @Of(() => String, { optional: true })
  branch: string = '';

  @Of(() => String, { optional: true })
  specialization: string = '';

  @Of(() => String, { optional: true })
  profile: string = '';

  @Of(() => String, { optional: true })
  session_year: string = '';

  @Of(() => String, { optional: true })
  session_county: string = '';

  @Of(() => String, { optional: true })
  audio_evaluation_result: string = '';

  @Of(() => String, { optional: true })
  chairman: string = '';

  @Of(() => String, { optional: true })
  digital_competences: string = '';

  @Of(() => String, { optional: true })
  equivalence_exam_name: string = '';

  @Of(() => String, { optional: true })
  evaluation_language: string = '';

  @Of(() => String, { optional: true })
  exam_name: string = '';

  @Of(() => String, { optional: true })
  issued_by_school: string = '';

  @Of(() => String, { optional: true })
  level: string = '';

  @Of(() => String, { optional: true })
  native_language: string = '';

  @Of(() => String, { optional: true })
  native_language_note: string = '';

  @Of(() => String, { optional: true })
  native_language_result: string = '';

  @Of(() => String, { optional: true })
  oral_interaction: string = '';

  @Of(() => String, { optional: true })
  reading_evaluation_result: string = '';

  @Of(() => String, { optional: true })
  romanian_language_note: string = '';

  @Of(() => String, { optional: true })
  romanian_language_result: string = '';

  @Of(() => String, { optional: true })
  session: string = '';

  @Of(() => String, { optional: true })
  session_city: string = '';

  @Of(() => String, { optional: true })
  session_school: string = '';

  @Of(() => String, { optional: true })
  speaking_evaluation_result: string = '';

  @Of(() => String, { optional: true })
  subject_bac_03: string = '';

  @Of(() => String, { optional: true })
  subject_bac_03_note: string = '';

  @Of(() => String, { optional: true })
  subject_bac_04: string = '';

  @Of(() => String, { optional: true })
  subject_bac_04_note: string = '';

  @Of(() => String, { optional: true })
  writing_evaluation_result: string = '';

  @Of(() => String, { optional: true })
  wrong_id_title: string = '';

  @Of(() => String, { optional: true })
  wrong_id_subtitle: string = '';

  static of = Entity.of<CertificateFields>();
}
