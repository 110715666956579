import { Entity, Of } from 'entity-of';

@Entity
export class ObservationInput {
  @Of(() => String)
  elevID: string = '';

  @Of(() => String)
  clID: string = '';

  @Of(() => String)
  sanID: string = '';

  @Of(() => String)
  ascID: string = '';

  static of = Entity.of<ObservationInput>();
}
