import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useStorageSession } from '@lib/utils';

import { useBreakpointValue } from '../../../hooks';
import { AdservioIcons, Box, Button, ButtonProps, Flex, Stack } from '../../atoms';
import { CKModal, CKModalContent, Dialog, Stepper2 } from '../../molecules';

import {
  FooterContainer,
  MainContainer,
  MobileStepper,
  RenderContentWrapper,
  Subtitle,
  Title,
  TitleContainer,
} from './FullpageWizard.theme';

interface AlertDialogProps {
  step: number;
  title?: string;
  description?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
}

interface SubmitProps {
  label?: string;
  isValid?: boolean;
  isSubmitDisabled?: boolean;
  submitLabel?: string;
  children?: React.ReactNode;
}

interface FullpageWizardProps {
  title: string[];
  subtitle?: string[];
  stepperTitles?: string[];
  stepsCount: number;
  startingStep?: number;
  isLoading?: boolean;
  cancelLabel?: string;
  alertDialogConfig?: AlertDialogProps;
  onCancel?(): void;
  onBack?(): void;
  renderContent?: (
    Footer: React.FC<SubmitProps>,
    step: number,
    setStep: (step: number) => void
  ) => React.ReactElement;
  renderFooter?: (
    Submit: React.FC<ButtonProps & SubmitProps>,
    Back: React.FC<ButtonProps>,
    step: number,
    setStep: (step: number) => void,
    isSubmitDisabled?: boolean
  ) => React.ReactElement;
}

export const FullpageWizard = ({
  title,
  subtitle = [],
  stepperTitles,
  stepsCount,
  startingStep,
  isLoading,
  cancelLabel,
  alertDialogConfig,
  onCancel,
  onBack,
  renderContent,
  renderFooter,
}: FullpageWizardProps) => {
  const { t } = useTranslation('shared');

  const { isNative } = useStorageSession();
  const showMobileStepper = isNative ? false : useBreakpointValue({ base: true, lg: false });

  const ref = useRef<HTMLDivElement>(null);

  const [step, setStep] = useState(startingStep || 0);
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);

  useEffect(() => {
    setStep(startingStep || 0);
  }, [startingStep]);

  const handleBack = () => {
    if (alertDialogConfig?.step === step) {
      setShowAlertDialog(true);
    } else {
      setStep(step - 1);
      onBack?.();
    }
  };

  const handleSetStep = (s: number) => {
    setStep(s);
  };

  const confirmBack = () => {
    alertDialogConfig?.onConfirm?.();

    setStep(step - 1);
    onBack?.();
  };

  const cancelBack = () => {
    setShowAlertDialog(false);
  };

  const Back = ({ children, ...props }: ButtonProps) => {
    return (
      <Button
        data-testid="fullpage-wizard-back-btn"
        variant="outline"
        width={{ base: '100%', md: 'initial' }}
        colorScheme="gray"
        onClick={() => {
          (ref as any).current.scrollTo(0, 0);
          handleBack();
        }}
        isDisabled={isLoading}
        {...props}
      >
        {children ? (
          children
        ) : (
          <>
            <AdservioIcons.FiChevronLeft mr="1" />
            {t('general.back')}
          </>
        )}
      </Button>
    );
  };

  const Submit = ({ label, isValid, children, ...props }: ButtonProps & SubmitProps) => {
    return (
      <Button
        data-testid="submit-button"
        type="submit"
        onClick={() => {
          if (isValid) {
            ref?.current?.scrollTo(0, 0);
          }
        }}
        width={{ base: '100%', md: 'initial' }}
        isLoading={isLoading}
        {...props}
      >
        {children ? (
          children
        ) : (
          <>
            {stepsCount === 1 || step === stepsCount - 1 ? (
              <>
                <AdservioIcons.FiCheck mr="1" /> {label || t('general.finish')}
              </>
            ) : (
              <>
                {t('general.next')}
                <AdservioIcons.FiChevronRight ml="1" />
              </>
            )}
          </>
        )}
      </Button>
    );
  };

  const Footer = ({ isValid, isSubmitDisabled, submitLabel }: SubmitProps & ButtonProps) => {
    return (
      <FooterContainer>
        {onCancel ? (
          <Button
            display={{ base: 'none', md: 'block' }}
            colorScheme="red"
            variant="outline"
            isDisabled={isLoading}
            onClick={onCancel}
            data-testid="fullpage-wizard-cancel-button-footer"
          >
            {cancelLabel || t('general.cancel')}
          </Button>
        ) : (
          <Box display={{ base: 'none', md: 'block' }} />
        )}

        <Stack
          spacing="4"
          direction={{ base: 'column', sm: 'row' }}
          width={{ base: '100%', md: 'initial' }}
        >
          {renderFooter ? (
            renderFooter(Submit, Back, step, handleSetStep, isSubmitDisabled)
          ) : (
            <>
              {step > 0 && <Back />}
              <Submit isValid={isValid} isDisabled={isSubmitDisabled} label={submitLabel} />
            </>
          )}
        </Stack>
      </FooterContainer>
    );
  };

  return (
    <>
      <CKModal isOpen autoFocus={false} onClose={() => onCancel?.()} size="full">
        <CKModalContent>
          <MainContainer ref={ref} steps={stepperTitles}>
            {showMobileStepper && <MobileStepper step={step} steps={stepperTitles} />}

            {!isNative && (
              <TitleContainer>
                <Box>
                  <Title>{title[step] || title[0]}</Title>
                  {subtitle && <Subtitle>{subtitle[step] || subtitle[0]}</Subtitle>}
                </Box>

                {stepperTitles?.length && (
                  <Box display={{ base: 'none', lg: 'block' }}>
                    <Stepper2
                      steps={stepperTitles?.map((title, index) => {
                        return { id: index, label: title };
                      })}
                      currentStep={step}
                    />
                  </Box>
                )}

                {onCancel && (
                  <Box display={{ base: 'block', md: 'none' }}>
                    <Button
                      colorScheme="red"
                      variant="ghost"
                      isDisabled={isLoading}
                      onClick={onCancel}
                      data-testid="fullpage-wizard-mobile-cancel-btn"
                    >
                      {cancelLabel || t('general.cancel')}
                    </Button>
                  </Box>
                )}
              </TitleContainer>
            )}

            <Flex flex={1}>
              <RenderContentWrapper steps={stepperTitles}>
                {renderContent?.(Footer, step, handleSetStep)}
              </RenderContentWrapper>
            </Flex>
          </MainContainer>
        </CKModalContent>
      </CKModal>

      {showAlertDialog && (
        <Dialog
          variant="warn"
          isOpen={!!showAlertDialog}
          onClose={cancelBack}
          title={alertDialogConfig?.title || t('general.confirm_go_back_title')}
          description={alertDialogConfig?.description || t('general.confirm_go_back_description')}
          confirmText={alertDialogConfig?.confirmLabel || t('general.back')}
          cancelText={alertDialogConfig?.cancelLabel || t('general.cancel')}
          onConfirm={confirmBack}
        />
      )}
    </>
  );
};
