import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableToggleAllRowsSelectedProps } from 'react-table';

import { Badge, Button, ButtonProps, HStack, Stack, Text } from '../../../atoms';
import { SelectRowCheckbox } from '../TableMisc';

export const ActionsContainer = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(
  ({ children }, _) => {
    return (
      <Stack
        p="4"
        w="100%"
        flexDirection={{ base: 'column', sm: 'row' }}
        spacing="4"
        borderWidth="1px"
        borderRadius="xl"
        borderColor="gray.200"
        boxShadow="xs"
        background="white"
        justifyContent="space-between"
        alignItems={{ base: 'flex-start', sm: 'center' }}
      >
        {children}
      </Stack>
    );
  }
);

interface SelectionProps {
  count: number;
  toggleAllProps?: TableToggleAllRowsSelectedProps;
}

export function Selection({ toggleAllProps, count }: SelectionProps) {
  const { t } = useTranslation('shared');
  return (
    <HStack spacing="2" align="center">
      <SelectRowCheckbox data-testid="select-all-cards" {...toggleAllProps} />
      <Badge>{count}</Badge>
      <Text>{t('general.selected')}</Text>
    </HStack>
  );
}

export const Actions = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      flexDirection={{ base: 'column', sm: 'row' }}
      spacing="3"
      w="full"
      justifyContent="flex-end"
      flexWrap="wrap"
    >
      {children}
    </Stack>
  );
};

export const Action = (props: ButtonProps) => {
  return <Button {...props} size="sm" />;
};
