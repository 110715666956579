import { SimpleQueryInput } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class ClassesSearchInput extends SimpleQueryInput {
  @Of(() => String, { optional: true })
  clAstID?: string;

  @Of(() => String, { optional: true })
  clID?: string;

  @Of(() => String, { optional: true })
  clAscID?: string;

  @Of(() => String, { optional: true })
  withClassbookInfo?: string;

  @Of(() => String, { optional: true })
  withAveragesLocks?: string;

  @Of(() => String, { optional: true })
  withCoordinators?: string;

  @Of(() => String, { optional: true })
  withLocksHistory?: string;

  @Of(() => String, { optional: true })
  withLockInfo?: string;

  static of = Entity.of<ClassesSearchInput>();
}
