import { Person, Viewer } from '@api/core/entities';
import { Type } from 'class-transformer';
import { Entity, Of } from 'entity-of';

import { Recipient } from '../Recipient';
import { RecurrenceWidthResult } from '../RecurrenceWidthResult';

export enum RecurrenceType {
  DATES = 'dates',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

@Entity
export class Todo {
  @Of(() => String)
  id: string = '';

  @Of(() => Number)
  type: number = 0;

  @Of(() => String)
  title: string = '';

  @Of(() => Number)
  totalQuestions: number = 0;

  @Of(() => RecurrenceWidthResult)
  @Type(() => RecurrenceWidthResult)
  recurrence = RecurrenceWidthResult.of({});

  @Of(() => Recipient)
  recipients = Recipient.of({});

  @Of(() => String)
  createdAt: string = '';

  @Of(() => String)
  description: string = '';

  @Of(() => Boolean)
  isAnonymousAnswer: boolean = false;

  @Of(() => Boolean)
  isPublished: boolean = false;

  @Of(() => String)
  dueDate: string = '';

  @Of(() => String)
  timeLimit: string = '';

  @Of(() => Person)
  owner: Person = Person.of({});

  @Of(() => String)
  progress: string = '';

  @Of(() => String)
  publishResultsType: string = '';

  @Of(() => String)
  startDate?: string = '';

  @Of(() => Viewer)
  viewers: Viewer = Viewer.of({});

  static of = Entity.of<Todo>();
}
