import { Entity, Of } from 'entity-of';

export type SubscriptionStatus =
  | 'expired'
  | 'active'
  | 'scheduled'
  | 'disabled'
  | 'unassigned'
  | string;

@Entity
export class SchoolSubscriptionStats {
  @Of(() => OrganizationDetails)
  organization: OrganizationDetails = OrganizationDetails.of({});

  @Of(() => StudentsStats)
  studentsStats: StudentsStats = StudentsStats.of({});

  @Of(() => String)
  status: SubscriptionStatus = '';

  @Of(() => String)
  updatedAt: string = '';

  @Of(() => SubscriptionInfo, { optional: true })
  subscription?: SubscriptionInfo = SubscriptionInfo.of({});

  static of = Entity.of<SchoolSubscriptionStats>();
}

@Entity
class OrganizationDetails {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  address: string = '';

  static of = Entity.of<OrganizationDetails>();
}

@Entity
class StudentsStats {
  @Of(() => Number)
  total: number = 0;

  @Of(() => Number)
  withTrial: string = '';

  @Of(() => Number)
  withPaidSubscription: string = '';

  static of = Entity.of<StudentsStats>();
}

@Entity
class SubscriptionInfo {
  @Of(() => String)
  packageId: string = '';

  @Of(() => String)
  endDate: string = '';

  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  managedBy: 'adservio' | 'self' = 'adservio';

  @Of(() => String)
  packageName: string = '';

  static of = Entity.of<SubscriptionInfo>();
}
