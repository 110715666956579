import { Entity, Of } from 'entity-of';

@Entity
export class Callout {
  @Of(() => String)
  calloutID: string = '';

  @Of(() => String)
  createdAt: string = '';

  static of = Entity.of<Callout>();
}
