import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class SignTimeTableFormData {
  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.date.required',
  })
  date: string = '';

  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.attendance.activityId.required',
  })
  activityId: string = '';

  @Of(() => String)
  description: string = '';

  static of = Entity.of<SignTimeTableFormData>();
}
