import { Person } from '@api/core/entities';
import { Type } from 'class-transformer';
import { Entity, Of } from 'entity-of';

import { RecurrenceWidthResult } from '../RecurrenceWidthResult';

export enum PublishResultsType {
  MANUAL = 'manual',
  DEADLINE = 'deadline',
  INSTANT = 'instant',
}

@Entity
export class Results {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  title: string = '';

  @Of(() => Number)
  type: number = 0;

  @Of(() => String)
  description: string = '';

  @Of(() => Boolean)
  isAnonymousAnswer: boolean = false;

  @Of(() => Number)
  totalQuestions: number = 0;

  @Of(() => RecurrenceWidthResult)
  @Type(() => RecurrenceWidthResult)
  recurrence = RecurrenceWidthResult.of({});

  @Of(() => Person)
  owner: Person = Person.of({});

  @Of(() => String)
  createdAt: string = '';

  @Of(() => Number)
  timeLimit: number = 0;

  @Of(() => String)
  publishResultsType: string = '';

  @Of(() => Boolean)
  isPublished: boolean = false;

  @Of(() => String)
  progress: string = '';

  static of = Entity.of<Results>();
}
