import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

import { BalanceSummary } from '../BalanceSummary';

@Entity
export class Balance_Person extends BalanceSummary {
  @Of(() => Person)
  person: Person = Person.of({});

  static of = Entity.of<Balance_Person>();
}
