import { Entity, Of } from 'entity-of';

@Entity
export class ProgramsGroup {
  @Of(() => Number)
  academicYearId: number | null = null;

  @Of(() => String)
  acronym: string | null = null;

  @Of(() => Number)
  id: number | null = null;

  @Of(() => Number)
  idPromotie: number | null = null;

  @Of(() => String)
  name: string | null = null;

  @Of(() => Number)
  parentId: number | null = null;

  @Of(() => String)
  parentName: string | null = null;

  @Of(() => String)
  type: string | null = null;

  @Of(() => String)
  uniqueAcronym: string | null = null;

  static of = Entity.of<ProgramsGroup>();
}
