import { ArrayNotEmpty, IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class StatementColumnsInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.value.required' })
  date: string = '';

  @Of(() => String, { optional: true, nullable: true })
  type?: string;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.value.required' })
  amount: string = '';

  @Of(() => String, { optional: true, nullable: true })
  currency?: string;

  @Of(() => [String])
  @ArrayNotEmpty({ message: 'errors.value.required' })
  details: string[] = [];

  static of = Entity.of<StatementColumnsInput>();
}
