import { Entity, Of } from 'entity-of';

@Entity
export class ChargedAmount {
  @Of(() => String)
  currency: string = '';

  @Of(() => Number)
  value: number = 0;

  static of = Entity.of<ChargedAmount>();
}
