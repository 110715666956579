import { CertificateFields, Person } from '@api/core/entities';
import { Type } from 'class-transformer';
import { ValidateNested } from 'class-validator';
import { Entity, Of } from 'entity-of';

import 'reflect-metadata';

import { Errors } from '../../CertificateTemplate';

@Entity
export class CreateCertificateInput {
  @Of(() => String)
  cerType: string = '';

  @Of(() => CertificateUser)
  cerUsers: CertificateUser[] = [];

  static of = Entity.of<CreateCertificateInput>();
}

@Entity
export class CertificateUser {
  @Of(() => String)
  cerUaID: string = '';

  @Of(() => String)
  cerClID: string = '';

  @ValidateNested()
  @Type(() => CertificateFields)
  @Of(() => CertificateFields)
  cerData: CertificateFields = CertificateFields.of({});

  @Of(() => Boolean, { optional: true })
  isDuplicate?: boolean = false;

  @Of(() => Object, { optional: true })
  errors?: Record<string, Errors[]> = {};

  @Of(() => Person)
  student?: Person = Person.of({});

  static of = Entity.of<CertificateUser>();
}
