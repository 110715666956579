import { Entity, Of } from 'entity-of';

@Entity
export class PackageSales {
  @Of(() => String)
  packageId: string = '';

  @Of(() => Number)
  sold: number = 0;

  @Of(() => Number)
  trial: number = 0;

  @Of(() => Number)
  billedAmount: number = 0;

  static of = Entity.of<PackageSales>();
}
