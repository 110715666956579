import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class UnsubscribeForm {
  @IsNotEmpty({ message: 'errors.reason.required' })
  @Of(() => String)
  reason: string = '';

  @Of(() => String, { optional: true })
  otherReason?: string;

  static of = Entity.of<UnsubscribeForm>();
}
