import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import {
  AccountBillingProfile,
  CompanyBillingProfileInput,
  PrivateBillingProfileInput,
} from '@api/billing/entities';
import { httpClient } from '@lib/http';
import { handleRequestErrors } from '@lib/utils';
import { useToast } from '@ui2/components';
import { AxiosError } from 'axios';

interface MutationInput {
  accountId: string;
  profileId: number;
  payload: PrivateBillingProfileInput | CompanyBillingProfileInput;
  rootOrgId?: string;
  xAccountId?: string;
}

export const useAccountProfileUpdateMutation = () => {
  const { t } = useTranslation('billing');

  const queryClient = useQueryClient();
  const showToast = useToast();

  return useMutation<AccountBillingProfile, AxiosError, MutationInput>(
    ({ accountId, profileId, payload, rootOrgId, xAccountId }) => {
      let headers = {};

      if (rootOrgId) {
        headers = { ...headers, 'X-Tenant-ID': rootOrgId };
      }

      if (xAccountId) {
        headers = { ...headers, 'X-Account-ID': xAccountId };
      }

      return httpClient<
        AccountBillingProfile,
        PrivateBillingProfileInput | CompanyBillingProfileInput
      >(`/billing/profiles/accounts/${accountId}/${profileId}`, {
        payload,
        headers,
      }).put();
    },
    {
      onSuccess: () => {
        showToast({
          description: t('profile.update_success'),
          status: 'success',
        });

        queryClient.invalidateQueries('myProfiles');
      },
      onError: (error) => handleRequestErrors(error, showToast),
    }
  );
};
