import React from 'react';

import { Box, Grid, GridProps, HStack, StackProps, VStack } from '../../../components';

import { Subtitle, Title } from './ContentCard.theme';

type BreakpointSize = 'sm' | 'md' | 'lg' | 'xl';

interface ContentCardProps extends Omit<StackProps, 'title'> {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  rightElement?: React.ReactElement;
  headerAlign?: 'center' | 'flex-start';
  tooltip?: React.ReactElement;
  isOneRightElement?: boolean;
  breakpointSize?: BreakpointSize;
  headerSx?: GridProps;
}

export const ContentCard = ({
  title,
  subtitle,
  rightElement,
  headerAlign = 'center',
  isOneRightElement = false,
  breakpointSize = 'sm',
  children,
  tooltip,
  headerSx,
  ...rest
}: React.PropsWithChildren<ContentCardProps>) => {
  const shouldDisplayHeader = [title, subtitle, rightElement].filter(Boolean).length > 0;

  const _title = typeof title === 'string' ? <Title>{title}</Title> : title;
  const _subtitle = typeof subtitle === 'string' ? <Subtitle>{subtitle}</Subtitle> : subtitle;

  return (
    <VStack
      width="100%"
      spacing="4"
      p="4"
      backgroundColor="white"
      borderRadius="xl"
      boxShadow="xs"
      alignItems="flex-start"
      {...rest}
    >
      {shouldDisplayHeader && (
        <Grid
          w="full"
          templateColumns={{
            base: isOneRightElement ? '1fr auto' : '1fr',
            [breakpointSize]: '1fr auto',
          }}
          alignItems="center"
          gap={{ base: 0, [breakpointSize]: 4 }}
          {...headerSx}
        >
          <VStack
            w="full"
            spacing="1"
            alignItems="flex-start"
            mb={{ base: headerAlign === 'center' ? 0 : 4 }}
          >
            <HStack w="full">
              {_title}
              {tooltip}
            </HStack>
            {subtitle && _subtitle}
          </VStack>

          {rightElement}
        </Grid>
      )}

      {shouldDisplayHeader && children && (
        <Box width="100%" borderBottom="1px solid" borderColor="gray.200" borderRadius="lg" />
      )}

      {children && (
        <Box width="100%" h="full">
          {children}
        </Box>
      )}
    </VStack>
  );
};
