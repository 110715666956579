import { Entity, Of } from 'entity-of';

@Entity
export class SearchSchoolYearsInput {
  @Of(() => String)
  ascID?: string;

  @Of(() => Number)
  cuSemestre?: number;

  @Of(() => Number)
  liceuID?: number;

  @Of(() => String, { optional: true, nullable: true })
  _liceuID?: string | null = '';

  @Of(() => String)
  _order?: string;

  static of = Entity.of<SearchSchoolYearsInput>();
}
