import { ComponentStyleConfig } from '@chakra-ui/react';

import { InputTheme } from '../Input';

export const TextareaTheme: ComponentStyleConfig = {
  baseStyle: {
    field: {
      _placeholder: {
        opacity: 1,
        color: 'gray.300',
      },
    },
  },
  sizes: {
    xs: {
      borderRadius: 'base',
    },
    sm: {
      borderRadius: 'md',
    },
    md: {
      borderRadius: 'lg',
    },
    lg: {
      borderRadius: 'xl',
    },
  },
  variants: {
    outline: (InputTheme?.variants?.outline as any).field ?? {},
  },
};
