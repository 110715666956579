import { IsNotEmpty, MinLength } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class Vacation {
  @Of(() => String, { optional: true })
  v2pID? = '';

  @Of(() => String)
  @MinLength(3, { message: 'errors.name.min_3' })
  v2pName = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.startDate.required' })
  v2pStartDate = '';

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.endDate.required' })
  v2pEndDate = '';

  static of = Entity.of<Vacation>();
}
