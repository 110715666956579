import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class OtherActivityFormData {
  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.date.required',
  })
  date: string = '';

  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.attendance.activityName.required',
  })
  activityName: string = '';

  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.attendance.startHour.required',
  })
  startHour: string = '';

  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.attendance.endHour.required',
  })
  endHour: string = '';

  static of = Entity.of<OtherActivityFormData>();
}
