import React, { PropsWithChildren } from 'react';

import { getOrgTypeTheme } from '@ui2/utils';

import { hoverState } from '../../../theme/variables';
import { Box, Grid } from '../../atoms';

interface WrapperProps {
  isChecked?: boolean;
  isInvalid?: boolean;
  onClick: () => void;
}

export const Wrapper = ({
  isChecked,
  isInvalid,
  onClick,
  children,
}: PropsWithChildren<WrapperProps>) => {
  const baseColor = getOrgTypeTheme('blue');

  return (
    <Box
      p="4"
      border="1px solid"
      borderColor={isChecked ? `${baseColor}.500` : isInvalid ? 'red.500' : 'gray.200'}
      backgroundColor={isChecked ? `${baseColor}.50` : 'transparent'}
      borderRadius="xl"
      boxShadow="xs"
      _hover={hoverState}
      onClick={onClick}
    >
      <Grid height="100%" templateColumns="minmax(0, 1fr) auto" columnGap="4" alignItems="center">
        {children}
      </Grid>
    </Box>
  );
};
