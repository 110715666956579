import { Entity, Of } from 'entity-of';

@Entity
export class SummaryHistoryInput {
  @Of(() => Number)
  tenantBillingProfileId: number = 0;

  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  static of = Entity.of<SummaryHistoryInput>();
}
