import { Entity, Of } from 'entity-of';

@Entity
export class UserAuthInput {
  @Of(() => String)
  intent: string = 'auth';

  @Of(() => String)
  uaUserName: string = '';

  @Of(() => String)
  uaPassword: string = '';

  @Of(() => Number)
  setCookie: number = 1;

  @Of(() => Boolean)
  tfaSave: boolean = false;

  @Of(() => String)
  tfaOptID: string = '';

  @Of(() => String)
  tfaResponse: string = '';

  @Of(() => String)
  uaNewPassword: string = '';

  @Of(() => String)
  uaNewPassword2: string = '';

  @Of(() => String)
  tokenLifetime: string = '0';

  @Of(() => String)
  recaptcha: string = '';

  static of = Entity.of<UserAuthInput>();
}
