import { Entity, Of } from 'entity-of';

@Entity
export class BalanceAdjustmentsSearchInput {
  @Of(() => String, { optional: true })
  accountId?: string;

  @Of(() => Number, { optional: true })
  offset?: number;

  @Of(() => Number, { optional: true })
  limit?: number;

  @Of(() => String, { optional: true })
  order?: 'asc' | 'desc';

  @Of(() => String, { optional: true })
  orderBy?: string;

  static of = Entity.of<BalanceAdjustmentsSearchInput>();
}
