import { Entity, Of } from 'entity-of';

@Entity
export class InfoRecipient {
  @Of(() => String, { optional: true })
  text?: string;

  @Of(() => String, { optional: true })
  color?: string;

  @Of(() => String, { optional: true })
  info?: string;

  static of = Entity.of<InfoRecipient>();
}
