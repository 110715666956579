import { UploadedFile } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class PortfolioDocument {
  @Of(() => String)
  id!: string;

  @Of(() => String)
  categoryID!: string;

  @Of(() => String)
  createdAt!: string;

  @Of(() => String)
  updatedAt!: string;

  @Of(() => String)
  createdBy!: string;

  @Of(() => String)
  date!: string;

  @Of(() => UploadedFile)
  fs!: UploadedFile;

  static of = Entity.of<PortfolioDocument>();
}
