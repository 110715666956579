import { Entity, Of } from 'entity-of';

@Entity
export class UploadedFile {
  @Of(() => String)
  fsID: string = '';

  @Of(() => String)
  fsTip: string = '';

  @Of(() => String)
  fsNume: string = '';

  @Of(() => String)
  fsExt: string = '';

  @Of(() => String)
  fsMimeType: string = '';

  @Of(() => String)
  fsSize: string = '';

  @Of(() => String)
  fsInsertDate: string = '';

  @Of(() => String)
  fsUrl: string = '';

  @Of(() => String)
  fsOwnerUaID?: string;

  @Of(() => MetaInfo, { nullable: true })
  fsMeta?: MetaInfo | null = null;

  static of = Entity.of<UploadedFile>();
}

@Entity
export class MetaInfo {
  @Of(() => FileMeta)
  fsThumb!: FileMeta;

  static of = Entity.of<MetaInfo>();
}

@Entity
export class FileMeta {
  @Of(() => String)
  fsHeight!: string;

  @Of(() => String)
  fsWidth!: string;

  @Of(() => String)
  fsUrl!: string;

  static of = Entity.of<FileMeta>();
}
