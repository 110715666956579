import React, { PropsWithChildren } from 'react';

import { useRouter } from '@lib/router';
import { usePageContext } from '@lib/utils/page-context/usePageContext';
import {
  AdservioIcons,
  Grid,
  GridItem,
  GridProps,
  HStack,
  IconButton,
  Text,
  TextProps,
} from '@ui2/components';
import Cookies from 'js-cookie';

interface PageHeaderProps extends GridProps {
  title?: string;
  subTitle?: string;
  subTitleIcon?: React.ReactElement;
  onBack?: () => void;
  body?: React.ReactElement;
  rowGap?: string;
  titleSx?: TextProps;
  onBackMatches?: RegExp[];
}

export const PageHeader = ({
  title,
  children,
  subTitle,
  subTitleIcon,
  rowGap,
  onBack,
  body,
  titleSx,
  onBackMatches = [],
  ...props
}: PropsWithChildren<PageHeaderProps>) => {
  const isNative = !!Cookies.get('ads_native');
  const { push } = useRouter();
  const { getLastMatchingRoute } = usePageContext();

  const handleBack = () => {
    const lastMatchingRoute = getLastMatchingRoute(onBackMatches);

    if (lastMatchingRoute) {
      push(lastMatchingRoute);
    } else {
      onBack?.();
    }
  };

  return (
    <Grid
      mb="6"
      templateColumns={{ base: '1fr', md: '1fr auto' }}
      columnGap="4"
      rowGap={rowGap ? rowGap : '6'}
      alignItems={{ base: 'flex-start', sm: 'center' }}
      justifyContent={{ base: 'flex-start', md: 'space-between' }}
      sx={{ '@media print': { display: 'none' } }}
      {...props}
    >
      <Grid
        order={1}
        templateColumns="auto 1fr"
        alignItems={subTitle ? 'flex-start' : 'center'}
        columnGap={{ base: 0, md: 4 }}
      >
        {!isNative && onBack && (
          <GridItem>
            <IconButton
              size="md"
              variant="ghost"
              colorScheme="gray"
              aria-label="back-to-home"
              icon={<AdservioIcons.FiArrowLeft />}
              data-testid="page-header-back-button"
              onClick={handleBack}
            />
          </GridItem>
        )}

        {(title || subTitle) && (
          <Grid templateColumns="1fr" rowGap="1">
            {title && (
              <Text
                data-testid="page-heading"
                fontSize={{ base: '2xl', md: '3xl' }}
                fontWeight="bold"
                lineHeight="2.5rem"
                {...titleSx}
              >
                {title}
              </Text>
            )}

            {subTitle && (
              <HStack spacing="2">
                {subTitleIcon}

                <Text data-testid="page-heading" fontSize="sm" color="gray.400">
                  {subTitle}
                </Text>
              </HStack>
            )}
          </Grid>
        )}
      </Grid>

      {children && (
        <Grid order={{ base: 3, md: 2 }} templateColumns="auto">
          {children}
        </Grid>
      )}

      {body && (
        <GridItem order={{ base: 2, md: 3 }} colSpan={{ base: 'auto', md: 2 }}>
          {body}
        </GridItem>
      )}
    </Grid>
  );
};
