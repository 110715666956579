import { Entity, Of } from 'entity-of';

@Entity
export class SummaryHistoryTax {
  @Of(() => String)
  name: string = '';

  @Of(() => Number)
  value: number = 0;

  static of = Entity.of<SummaryHistoryTax>();
}
