import { useMemo, useState } from 'react';

import { useAtom } from 'jotai';
import { atomWithHash, atomWithStorage } from 'jotai/utils';
import isEqual from 'lodash/isEqual';

import { useRouter } from '../router';

export type Sort = {
  by: string;
  direction: 'asc' | 'desc';
};

interface UrlStateAtom<T> {
  filters: Partial<T>;
  pageIndex: number;
  pageSize: number;
  sort: Sort;
}

const defaultPageIndex = 0;
const defaultPageSize = 25;

export const useUrlState = <T extends object>(params?: Partial<UrlStateAtom<T>>) => {
  const { pathWithoutLang } = useRouter();
  const [_, setRerender] = useState(0);

  const { filters, pageIndex, pageSize, sort } = params || {};

  if (!window.URL_STATE_DEFAULTS) {
    window.URL_STATE_DEFAULTS = {};
  }

  if (!window.URL_STATE_DEFAULTS[pathWithoutLang]) {
    window.URL_STATE_DEFAULTS[pathWithoutLang] = {
      filters,
      pageIndex,
      pageSize,
      sort,
    };
  }

  const globalState = window.URL_STATE_DEFAULTS[pathWithoutLang] as UrlStateAtom<T>;

  const atoms = useMemo(() => {
    return {
      urlStateAtom: atomWithHash<UrlStateAtom<T>>('q', {
        filters: filters || {},
        pageIndex: globalState.pageIndex || pageIndex || defaultPageIndex,
        pageSize: globalState.pageSize || pageSize || defaultPageSize,
        sort: sort || {},
      } as UrlStateAtom<T>),
      rerenderAtom: atomWithStorage('rerender', 0),
    };
  }, []);

  const [stateAtom, setStateAtom] = useAtom(atoms.urlStateAtom);

  const clearGlobalUrlState = () => {
    if (window.URL_STATE_DEFAULTS[pathWithoutLang]) {
      delete window.URL_STATE_DEFAULTS[pathWithoutLang];
    }
  };

  const setDefaultFilters = (data: Partial<T>) => {
    if (!Object.keys(stateAtom.filters)?.length) {
      globalState.filters = data;
      setRerender((prev) => prev + 1);
    }
  };

  const setDefaultSort = (data?: Sort) => {
    if (!Object.keys(stateAtom.sort || {})?.length) {
      globalState.sort = data as Sort;
      setRerender((prev) => prev + 1);
    }
  };

  const setSort = (sort: Sort) => {
    setStateAtom((prev) => ({ ...prev, sort }));
  };

  const getSort = () => {
    return (
      Object.keys(stateAtom.sort || {})?.length ? stateAtom.sort : globalState.sort || {}
    ) as Sort;
  };

  const setPage = (pageIndex: number) => {
    setStateAtom((prev) => ({ ...prev, pageIndex }));
  };

  const getPage = () => {
    return stateAtom.pageIndex || globalState.pageIndex || defaultPageIndex;
  };

  const setSize = (pageSize: number) => {
    setStateAtom((prev) => ({ ...prev, pageSize }));
  };

  const getSize = () => {
    return stateAtom.pageSize || globalState.pageSize || defaultPageSize;
  };

  const setFilters = (filters: T) => {
    setStateAtom((prev) => ({ ...prev, filters }));
  };

  const setFilterField = (field: keyof T, value) => {
    setStateAtom((prev) => ({ ...prev, filters: { ...prev.filters, [field]: value } }));
  };

  const getFilters = () => {
    return (
      Object.keys(stateAtom.filters || {})?.length
        ? stateAtom.filters
        : (globalState.filters as T) || {}
    ) as T;
  };

  const setUrlState = (data: Partial<UrlStateAtom<T>>) => {
    setStateAtom((prev) => ({ ...prev, ...data }));
  };

  const resetUrlState = () => {
    if (
      (Object.keys(stateAtom.sort || {}).length !== 0 && stateAtom.sort.by !== '') ||
      stateAtom.pageIndex !== defaultPageIndex ||
      stateAtom.pageSize !== defaultPageSize ||
      Object.keys(stateAtom.filters || {}).length !== 0
    ) {
      setStateAtom({
        ...stateAtom,
        sort: { by: '', direction: 'asc' },
        pageIndex: defaultPageIndex,
        pageSize: defaultPageSize,
        filters: {},
      });
    }
  };

  return {
    sort: getSort(),
    setSort,
    pageSize: getSize(),
    setPage,
    pageIndex: getPage(),
    setSize,
    filters: getFilters(),
    setFilters,
    defaultFilters: globalState.filters,
    setDefaultFilters,
    setFilterField,
    isDefaultFilters: isEqual(getFilters(), globalState.filters || {}),
    defaultSort: globalState.sort,
    setDefaultSort,
    isDefaultSort: isEqual(getSort(), globalState.sort),
    setUrlState,
    clearGlobalUrlState,
    resetUrlState,
  };
};
