import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class ClientCodeInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.clientCode.required' })
  code: string = '';

  static of = Entity.of<ClientCodeInput>();
}
