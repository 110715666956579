// @ts-nocheck
/* action types */

import Api2 from '../../../Libs/api';

export const LOAD_BOOKS_PENDING = 'LOAD_BOOKS_PENDING';
export const LOAD_BOOKS_SUCCESS = 'LOAD_BOOKS_SUCCESS';
export const LOAD_BOOKS_ERROR = 'LOAD_BOOKS_ERROR';

export const LOAD_LNG = 'LOAD_LNG';
export const PARAMS_LOCATION_BOOK = 'PARAMS_LOCATION_BOOK';

export const loadLng = (lng) => {
  return (dispatch) => {
    dispatch({
      type: LOAD_LNG,
      lng,
    });
  };
};

export function getBooks() {
  return (dispatch) => {
    dispatch({
      type: LOAD_BOOKS_PENDING,
    });

    Api2.get('/biblioteca')
      .then(() => {
        dispatch({
          type: LOAD_BOOKS_SUCCESS,
          success: true,
        });
      })
      .catch(() => {
        dispatch({
          type: LOAD_BOOKS_ERROR,
          success: false,
        });
      });
  };
}

export const testRedux = (countDataRedux, countOffsetRedux, thePositionRedux, booksRedux) => {
  return (dispatch) => {
    dispatch({
      type: PARAMS_LOCATION_BOOK,
      countDataRedux,
      countOffsetRedux,
      thePositionRedux,
      booksRedux,
    });
  };
};
