import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class SignActivityFormData {
  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.date.required',
  })
  date: string = '';

  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.attendance.activityId.required',
  })
  activityId: string = '';

  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.attendance.startHour.required',
  })
  startHour: string = '';

  @Of(() => String)
  @IsNotEmpty({
    message: 'errors.attendance.endHour.required',
  })
  endHour: string = '';

  @Of(() => String)
  roomId: string = '';
  static of = Entity.of<SignActivityFormData>();
}
