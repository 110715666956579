import { Entity, Of } from 'entity-of';

@Entity
export class StudentGradesResponse {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  academicPlanId: number = 0;

  @Of(() => Number)
  academicYearId: number = 0;

  @Of(() => Number)
  programId: number = 0;

  @Of(() => Number)
  personId: number = 0;

  @Of(() => Number)
  disciplineId: number = 0;

  @Of(() => String)
  currentNumber: string = '';

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  code: string = '';

  @Of(() => Number)
  numberOfCredits: number = 0;

  @Of(() => Number)
  semester: number = 0;

  @Of(() => Number)
  year: number = 0;

  @Of(() => Number)
  grade: number = 0;

  @Of(() => Number)
  gradeExamSession1: number = 0;

  @Of(() => Number)
  gradeSeminarSession1: number = 0;

  @Of(() => Number)
  gradeLaboratorySession1: number = 0;

  @Of(() => Number)
  gradeProjectSession1: number = 0;

  @Of(() => Number)
  gradeExamSession2: number = 0;

  @Of(() => Number)
  gradeSeminarSession2: number = 0;

  @Of(() => Number)
  gradeLaboratorySession2: number = 0;

  @Of(() => Number)
  gradeProjectSession2: number = 0;

  @Of(() => Number)
  gradeExamSession3: number = 0;

  @Of(() => Number)
  gradeSeminarSession3: number = 0;

  @Of(() => Number)
  gradeLaboratorySession3: number = 0;

  @Of(() => Number)
  gradeProjectSession3: number = 0;

  @Of(() => Number)
  gradeExamSession4: number = 0;

  @Of(() => Number)
  gradeSeminarSession4: number = 0;

  @Of(() => Number)
  gradeLaboratorySession4: number = 0;

  @Of(() => Number)
  gradeProjectSession4: number = 0;

  @Of(() => Boolean)
  useSession1: boolean = true;

  @Of(() => Boolean)
  useSession2: boolean = true;

  @Of(() => Boolean)
  useSession3: boolean = true;

  @Of(() => Boolean)
  useSession4: boolean = true;

  @Of(() => Boolean)
  inscrisSesiune3: boolean = true;

  @Of(() => Boolean)
  inscrisSesiune4: boolean = true;

  @Of(() => String)
  session1Date: string = '';

  @Of(() => String)
  session2Date: string = '';

  @Of(() => String)
  session3Date: string = '';

  @Of(() => String)
  session4Date: string = '';

  @Of(() => Number)
  percentExam: number = 0;

  @Of(() => Number)
  percentSeminar: number = 0;

  @Of(() => Number)
  percentLaboratory: number = 0;

  @Of(() => Number)
  percentProject: number = 0;

  @Of(() => Boolean)
  necessaryMinimumExamGrade: boolean = true;

  @Of(() => Boolean)
  necessaryMinimumSeminarGrade: boolean = true;

  @Of(() => Boolean)
  necessaryMinimumLaboratoryGrade: boolean = true;

  @Of(() => Boolean)
  necessaryMinimumProjectGrade: boolean = true;

  @Of(() => Boolean)
  absent: boolean = true;

  @Of(() => String)
  gradeDate: string = '';

  @Of(() => Number)
  numberOfCourseHours: number = 0;

  @Of(() => Number)
  numberOfSeminarHours: number = 0;

  @Of(() => Number)
  numberOfLaboratoryHours: number = 0;

  @Of(() => Number)
  numberOfPracticeHours: number = 0;

  @Of(() => Number)
  numberOfIndividualLearningHours: number = 0;

  @Of(() => Number)
  numberOfConditions: number = 0;

  @Of(() => String)
  evaluationMethod: string = '';

  @Of(() => Boolean)
  optional: boolean = true;

  @Of(() => Boolean)
  free: boolean = true;

  @Of(() => Boolean)
  selectedInPlan: boolean = true;

  @Of(() => Boolean)
  activeDiscipline: boolean = true;

  @Of(() => Number)
  pack: number = 0;

  @Of(() => Boolean)
  accepted: boolean = true;

  @Of(() => Number)
  status: number = 0;

  @Of(() => String)
  programName: string = '';

  @Of(() => String)
  facultyName: string = '';

  @Of(() => Boolean)
  editable: boolean = true;

  @Of(() => [String])
  grades: any[] = [];

  static of = Entity.of<StudentGradesResponse>();
}
