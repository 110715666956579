import React, { ReactNode } from 'react';

import {
  Flex,
  Tag as CKTag,
  TagCloseButton,
  TagCloseButtonProps,
  TagLabel,
  TagLabelProps,
  TagProps as CKTagProps,
} from '@chakra-ui/react';

export interface TagProps extends CKTagProps {
  tagLabel: React.ReactElement | string | number;
  tagLabelProps?: TagLabelProps;
  onClose?: () => void;
  leftElement?: ReactNode;
  rightElement?: ReactNode;
  hasCloseButton?: boolean;
}

export const Tag = ({
  onClose,
  tagLabel,
  leftElement,
  rightElement,
  hasCloseButton,
  tagLabelProps,
  ...props
}: React.PropsWithChildren<TagProps>) => {
  return (
    <CKTag data-testid="tag" {...props}>
      {leftElement && <Flex mr="1">{leftElement}</Flex>}
      {tagLabel && (
        <TagLabel p="1" {...tagLabelProps}>
          {tagLabel}
        </TagLabel>
      )}
      {rightElement && <Flex ml="1">{rightElement}</Flex>}
      {hasCloseButton && <CustomTagCloseButton onClick={onClose} />}
    </CKTag>
  );
};

const CustomTagCloseButton = ({ ...props }: React.PropsWithChildren<TagCloseButtonProps>) => {
  return <TagCloseButton _focus={{ boxShadow: 'none', background: 'none' }} {...props} />;
};

export { CKTag, TagCloseButton, TagLabel };
