import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast } from '@ui2/components';
import { Button } from '@ui2/components/atoms/Button';
import { FileUpload } from '@ui2/components/atoms/FileUpload';
import { AdservioIcons } from '@ui2/components/atoms/Icons';
import { Image } from '@ui2/components/atoms/Image';
import { ContentEmptyState } from '@ui2/components/molecules/ContentEmptyState';
import { FeatureIcon } from '@ui2/components/molecules/FeatureIcon/FeatureIcon';
import { FormElement, FormElementProps } from '@ui2/components/molecules/FormElements/FormElement';
import { ImageCropModal } from '@ui2/components/organisms/ImageCropModal';

import Api2 from '../../../../Libs/api';

const apiBaseUrl = window.env?.V2_PUBLIC_API_BASE_URL || '';

interface ResponseUploadTmp {
  url: string;
}

interface ImageUploaderProps {
  name: string;
  onChange?: (value: string | null) => void;
  imgSrc?: string;
  height?: string;
  title?: string;
}

export const ImageUploader = ({
  name,
  onChange,
  imgSrc,
  height = '16rem',
  title = '',
  ...props
}: ImageUploaderProps) => {
  const { t } = useTranslation(['billing', 'common']);

  const showToast = useToast();

  const [file, setFile] = useState<File | null>(null);
  const [imgUrl, setImgUrl] = useState<string | undefined>();

  const handleFileChange = (file: File) => {
    setFile(file);
  };

  const handleCropChange = async (croppedUrl: string) => {
    setImgUrl(croppedUrl);

    try {
      const blob = await fetch(croppedUrl).then((r) => r.blob());
      const croppedFile = new File([blob], file?.name ?? 'file', { type: file?.type });

      await Api2.post('./fisiere/upload_tmp', {
        fsFile: croppedFile,
      }).then((response) => {
        onChange?.((response as ResponseUploadTmp).url);
      });
    } catch (error) {
      showToast({
        description: t('common:general_err'),
        status: 'error',
      });
    }
  };

  const removeImage = () => {
    setImgUrl(undefined);
    setFile(null);

    onChange?.(null);
  };

  useEffect(() => {
    if (imgSrc) {
      setImgUrl(imgSrc);
    }
  }, [imgSrc]);

  return (
    <>
      <ContentEmptyState
        p="6"
        h={height}
        title={imgUrl ? '' : title}
        renderHeader={() =>
          imgUrl ? (
            <Image h="9.5rem" alt="cropped" src={imgUrl} />
          ) : (
            <FeatureIcon colorScheme="gray" size="xl" name="FiImage" mb="2" />
          )
        }
        renderFooter={() =>
          imgUrl ? (
            <Button
              leftIcon={<AdservioIcons.FiTrash2 boxSize="4" />}
              variant="outline"
              size="sm"
              mt="2"
              colorScheme="red"
              onClick={removeImage}
            >
              {t('billing:general.remove')}
            </Button>
          ) : (
            <FileUpload
              size="sm"
              boxSize="4"
              apiBaseUrl={apiBaseUrl}
              name={name}
              accept="image/png,image/jpg,image/jpeg"
              invalidFileDescription={t('billing:profile.logo.invalid_file_description')}
              variant="outline"
              colorScheme="gray"
              label={t('billing:profile.logo.choose_file')}
              onChange={handleFileChange}
            />
          )
        }
        {...props}
      />

      <ImageCropModal
        title={t('billing:profile.logo.crop_image')}
        isOpen={!!file}
        aspectRatio="4:3"
        mimeType={file?.type}
        onChange={handleCropChange}
        onClose={() => setFile(null)}
        image={file ? URL.createObjectURL(file) : '#'}
      />
    </>
  );
};

export const FormImageUploader = ({
  name,
  label,
  formControlProps,
  ...props
}: FormElementProps<ImageUploaderProps>) => {
  return (
    <FormElement {...{ name, label, formControlProps }}>
      <ImageUploader name={name} {...props} />
    </FormElement>
  );
};
