import { Person, TimetableActivity } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

import { AttendanceActivity, AttendanceSummary, TimeOff } from '..';

@Entity
export class AttendanceReport {
  @Of(() => Person)
  teacher: Person = Person.of({});

  @Of(() => [AttendanceReportDay])
  days: AttendanceReportDay[] = [];

  @Of(() => [TimeOff])
  timeOffs: TimeOff[] = [];

  @Of(() => [AttendanceActivity])
  todayActivities: AttendanceActivity[] = [];

  @Of(() => [TimetableActivity])
  timetableActivities: TimetableActivity[] = [];

  @Of(() => AttendanceSummary)
  summary: AttendanceSummary = AttendanceSummary.of({});

  static of = Entity.of<AttendanceReport>();
}

@Entity
export class AttendanceReportDay {
  @Of(() => String)
  activitiesMissed: number = 0;

  @Of(() => AttendanceReportActivityPresent)
  activitiesPresent: AttendanceReportActivityPresent = AttendanceReportActivityPresent.of({});

  @Of(() => String)
  date: string = '';

  static of = Entity.of<AttendanceReportDay>();
}

@Entity
export class AttendanceReportActivityPresent {
  @Of(() => Number)
  scheduled: number = 0;

  @Of(() => Number)
  extra: number = 0;

  static of = Entity.of<AttendanceReportActivityPresent>();
}
