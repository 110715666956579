import { Entity, Of } from 'entity-of';

@Entity
export class AbsenceDetailsInput {
  @Of(() => Number)
  ascID: number = 0;

  @Of(() => Number)
  elevID: number = 0;

  @Of(() => Number)
  cmcID: number = 0;

  @Of(() => Number, { optional: true })
  cuMaterie?: number;

  static of = Entity.of<AbsenceDetailsInput>();
}
