import { Person } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class Scholarship {
  @Of(() => String)
  id: string = '';

  @Of(() => Number)
  gradesNo: number = 0;

  @Of(() => Number)
  gradesAvg: number = 0;

  @Of(() => Number)
  absencesNo: number = 0;

  @Of(() => Person)
  student: Person = Person.of({});

  static of = Entity.of<Scholarship>();
}
