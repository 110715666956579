const baseUrl = window.env?.V2_PUBLIC_API_BASE_URL || '';

import { ChangeEvent, ChangeEventHandler, ReactNode, useEffect, useRef, useState } from 'react';
import { CustomScroll } from 'react-custom-scroll';
import { useTranslation } from 'react-i18next';

import { TempFileUpload } from '@api/core/entities';
import Api from '@lib/api';
import { useSession } from '@lib/utils';
import { Box, EndpointOptions, VStack } from '@ui2/components';
import { StyledFileInput } from '@ui2/components/atoms/FileUpload/FileUpload.theme';

import Preview from '../../../../Components/Overlays/Preview/Preview';

import { EmptyState, FileCard, FileCardContainer } from './FileUploader.theme';

interface PresignData {
  token: string;
  url: string;
}

interface FileUploadProps {
  renderEmptyState?: () => ReactNode;
  endpointOptions?: EndpointOptions;
  onChange?(file: File): void;
  onSuccess?(data: any): void;
  hideProgressBar?: boolean;
  hideRemoveButton?: boolean;
  onError?: (any) => void;
  showDownload?: boolean;
  defaultValue?: string;
  description?: string;
  isDisabled?: boolean;
  hasErrors?: boolean;
  apiBaseUrl?: string;
  onRemove?(): void;
  accept?: string;
  name: string;
  onUploadStart?: () => void;
  setIsLoading?: (isLoading: boolean) => void;
  multiple?: boolean;
  maxSize?: number;
  abortAllFilesUpload?: boolean;
}

const endpointDefaultOptions: EndpointOptions = {
  uploadUrl: '/fisiere/upload',
  fileKeyName: 'fsFile',
  downloadUrl: '',
  payload: {
    fsTip: '8',
  },
};

const acceptDefault =
  'image/*, video/*, audio/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/zip, application/x-rar-compressed, application/x-7z-compressed, text/plain';

type Status = 'loading' | 'error' | 'success' | 'idle';

export const FileUpload2 = ({
  endpointOptions = endpointDefaultOptions,
  accept = acceptDefault,
  apiBaseUrl = baseUrl,
  renderEmptyState,
  hideRemoveButton,
  hideProgressBar,
  setIsLoading,
  defaultValue,
  showDownload,
  description,
  isDisabled,
  hasErrors,
  onSuccess,
  onChange,
  onRemove,
  onError,
  onUploadStart,
  name,
  multiple,
  maxSize = 1024 * 1024 * 1024,
  abortAllFilesUpload,
}: FileUploadProps) => {
  useTranslation('Preview');
  const { t } = useTranslation('shared');

  const { rootOrgId } = useSession();

  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLLabelElement>(null);
  const xhrRequests = useRef<Map<number, XMLHttpRequest>>(new Map());

  const [statuses, setStatuses] = useState<
    {
      index: number;
      status: Status;
    }[]
  >();
  const [files, setFiles] = useState<
    {
      index: number;
      file: File | null;
    }[]
  >();

  const [filesError, setFilesError] = useState<
    {
      index: number;
      message: string;
    }[]
  >();

  const [progress, setProgress] = useState<
    {
      index: number;
      progress: number;
    }[]
  >();

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [tempFiles, setTempFiles] = useState<{ file: TempFileUpload | null; index: number }[]>([]);

  useEffect(() => {
    const isLoading = (statuses ?? []).some((status) => status.status === 'loading');
    setIsLoading?.(isLoading);
  }, [statuses]);

  useEffect(() => {
    if (defaultValue) {
      const fetchFileDetails = async (fileId: string) => {
        try {
          const response = await fetch(`${apiBaseUrl}/fisiere/detalii?fsID=${fileId}`, {
            headers: {
              'X-Tenant-ID': rootOrgId,
            },
          });
          const data = await response.json();

          const tempFileResp = TempFileUpload.of(data);

          setTempFiles([
            {
              index: 0,
              file: tempFileResp,
            },
          ]);

          setFiles([
            {
              index: 0,
              file: null,
            },
          ]);

          setStatuses([
            {
              index: 0,
              status: 'success',
            },
          ]);
        } catch (error) {
          setStatuses([
            {
              index: 0,
              status: 'error',
            },
          ]);
        }
      };

      fetchFileDetails(defaultValue);
    }
  }, [defaultValue, apiBaseUrl]);

  const updateStatus = (index: number, status: Status) => {
    setStatuses((prevStatuses) => {
      const statusIndex = (prevStatuses ?? []).findIndex((status) => status.index === index);
      if (statusIndex !== -1) {
        const newStatuses = [...(prevStatuses ?? [])];
        newStatuses[statusIndex].status = status;
        return newStatuses;
      } else {
        return [
          ...(prevStatuses ?? []),
          {
            index: index,
            status: status,
          },
        ];
      }
    });
  };

  const updateProgress = (index: number, newProgress: number) => {
    setProgress((prevProgress) => {
      const progressIndex = (prevProgress ?? []).findIndex((progress) => progress.index === index);
      if (progressIndex !== -1) {
        const newProgresses = [...(prevProgress ?? [])];
        newProgresses[progressIndex].progress = newProgress;
        return newProgresses;
      } else {
        return [
          ...(prevProgress ?? []),
          {
            index: index,
            progress: newProgress,
          },
        ];
      }
    });
  };

  const updateFileError = (index: number, message: string) => {
    setFilesError((prevErrors) => {
      const fileErrorIndex = (prevErrors ?? []).findIndex((fileError) => fileError.index === index);
      if (fileErrorIndex !== -1) {
        const newErrors = [...(prevErrors ?? [])];
        newErrors[fileErrorIndex].message = message;
        return newErrors;
      } else {
        return [
          ...(prevErrors ?? []),
          {
            index: index,
            message: message,
          },
        ];
      }
    });
  };

  const updateFiles = (index: number, file: File | null) => {
    setFiles((prevFiles) => {
      const fileIndex = (prevFiles ?? []).findIndex((file) => file.index === index);
      if (fileIndex !== -1) {
        const newFiles = [...(prevFiles ?? [])];
        newFiles[fileIndex].file = file;
        return newFiles;
      } else {
        return [
          ...(prevFiles ?? []),
          {
            index: index,
            file: file,
          },
        ];
      }
    });
  };

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = async () => {
    if (!inputRef) return;
    setFilesError(undefined);

    const currentFiles = inputRef.current?.files;

    if (!currentFiles) return;

    for (const file of currentFiles) {
      const xhr = new XMLHttpRequest();
      xhrRequests.current.set(Array.from(currentFiles).indexOf(file), xhr);

      if (!file) {
        xhr.abort();
        return;
      }

      if (file.size > maxSize) {
        updateStatus(Array.from(currentFiles).indexOf(file), 'error');

        updateFileError(Array.from(currentFiles).indexOf(file), t('errors.file_size.exceeded'));
        updateFiles(Array.from(currentFiles).indexOf(file), file);
        onChange?.(file);

        continue;
      }

      const fileTypeIsAllowed = accept.split(',').some((allowedType) => {
        if (allowedType.includes('/')) {
          return file.type === allowedType;
        } else {
          return file.name.includes(allowedType.trim());
        }
      });

      if (!fileTypeIsAllowed) {
        updateStatus(Array.from(currentFiles).indexOf(file), 'error');
        updateFileError(Array.from(currentFiles).indexOf(file), t('errors.file_type.not_accepted'));
        updateFiles(Array.from(currentFiles).indexOf(file), file);
        onChange?.(file);

        continue;
      }

      onChange?.(file);

      let presignToken: string | null = null;

      xhr.upload.onloadstart = () => {
        updateStatus(Array.from(currentFiles).indexOf(file), 'loading');
        updateFiles(Array.from(currentFiles).indexOf(file), file);

        onUploadStart?.();
      };

      xhr.upload.onprogress = (e) => {
        const percentage = Math.floor((e.loaded * 100) / e.total);
        updateProgress(Array.from(currentFiles).indexOf(file), percentage);
      };

      xhr.upload.onerror = () => {
        updateStatus(Array.from(currentFiles).indexOf(file), 'error');
        updateFiles(Array.from(currentFiles).indexOf(file), null);
      };

      xhr.upload.onabort = () => {
        updateStatus(Array.from(currentFiles).indexOf(file), 'error');
        updateFiles(Array.from(currentFiles).indexOf(file), null);
      };

      xhr.upload.ontimeout = () => {
        updateStatus(Array.from(currentFiles).indexOf(file), 'error');
        updateFiles(Array.from(currentFiles).indexOf(file), null);
      };

      xhr.onloadend = (e) => {
        if (xhr.status >= 200 && xhr.status < 300) {
          if (!endpointOptions.isPresigned) {
            updateStatus(Array.from(currentFiles).indexOf(file), 'success');
          }

          updateFiles(Array.from(currentFiles).indexOf(file), file);
        } else {
          updateStatus(Array.from(currentFiles).indexOf(file), 'error');
          updateFiles(Array.from(currentFiles).indexOf(file), null);
        }
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          if (endpointOptions.isPresigned) {
            const headers = {
              'Content-Type': 'multipart/form-data',
            };

            if (Object.keys(endpointOptions.headers || {})?.length) {
              Object.keys(endpointOptions.headers || {}).forEach((key) => {
                headers[key] = String(endpointOptions.headers?.[key]);
              });
            }

            Api.post<TempFileUpload>(
              'fisiere/upload_s3',
              {
                token: presignToken,
                fsNume: file.name.split('.')[0],
                ...endpointOptions.payload,
                _liceuID: null,
              },
              headers
            ).then((response) => {
              let data = TempFileUpload.of(response);

              onSuccess?.(response.fsID);
              setTempFiles((prevFiles) => [
                ...(prevFiles ?? []),
                {
                  index: Array.from(currentFiles).indexOf(file),
                  file: data,
                },
              ]);

              updateStatus(Array.from(currentFiles).indexOf(file), 'success');
            });
          } else {
            let data = TempFileUpload.of({ ...JSON.parse(xhr.responseText) });

            onSuccess?.(data.fsID);
            setTempFiles((prevFiles) => [
              ...(prevFiles ?? []),
              {
                index: Array.from(currentFiles).indexOf(file),
                file: data,
              },
            ]);
          }
        } else if (xhr.readyState === 4) {
          let data = JSON.parse(xhr.responseText);

          const error = {
            response: {
              data: data,
            },
          };

          onError?.(error);
        }
      };

      if (!endpointOptions) {
        inputRef.current.value = '';
        return;
      }

      if (endpointOptions) {
        if (endpointOptions.isPresigned) {
          const presigned = await Api.post<PresignData>('fisiere/upload_token', {
            size: file.size,
            name: file.name,
            type: file.type,
            extension: file.name.split('.').pop(),
            _liceuID: null,
          });

          presignToken = presigned.token;

          xhr.open('PUT', presigned.url);
          xhr.setRequestHeader('Content-Type', file.type);

          xhr.send(file);
        } else {
          const formData = new FormData();

          xhr.open('POST', `${apiBaseUrl}${endpointOptions.uploadUrl}`);

          if (Object.keys(endpointOptions.headers || {})?.length) {
            Object.keys(endpointOptions.headers || {}).forEach((key) => {
              xhr.setRequestHeader(key, String(endpointOptions.headers?.[key]));
            });
          }

          formData.append(endpointOptions.fileKeyName ?? 'content', file);

          formData.append('fsNume', file.name.split('.')[0]);

          if (endpointOptions.payload) {
            Object.keys(endpointOptions.payload).forEach((key) => {
              formData.append(key, String(endpointOptions.payload?.[key]));
            });
          }

          xhr.send(formData);
        }
      }
    }
  };

  const handleFileRemove = (index: number) => {
    const xhr = xhrRequests.current.get(index);

    if (xhr && xhr.readyState !== 4) {
      xhr.abort();
    }

    xhrRequests.current.delete(index);

    setFiles((prevFiles) => prevFiles?.filter((file) => file.index !== index));
    setProgress((prevProgress) => prevProgress?.filter((progress) => progress.index !== index));
    setStatuses((prevStatuses) => prevStatuses?.filter((status) => status.index !== index));
    setTempFiles((prevFiles) => prevFiles?.filter((file) => file.index !== index));
    setFilesError((prevErrors) => prevErrors?.filter((error) => error.index !== index));

    if (files && files?.length <= 1 && inputRef.current) {
      inputRef.current.value = '';
    }

    onRemove?.();
  };

  const handleDropFile = (e: any) => {
    e.preventDefault();
    setFilesError(undefined);
    const files = e.dataTransfer.files;

    if (files.length > 0) {
      const dataTransferEvent = {
        target: {
          files: files,
        },
      } as ChangeEvent<HTMLInputElement>;
      if (inputRef?.current) inputRef.current.files = files;

      handleOnChange(dataTransferEvent);
    }
  };

  const handleRetry = () => {
    if (containerRef) {
      setTimeout(() => {
        containerRef.current?.click();
      }, 100);
    }
  };

  const handleCardClick = (e, status: string) => {
    e.preventDefault();

    if (status === 'success') {
      setShowPreview(true);
    }
  };

  const handleAbortAllFilesUpload = () => {
    xhrRequests.current.forEach((xhr) => {
      xhr.abort();
    });
    setFiles([]);
    setProgress([]);
    setStatuses([]);
    setTempFiles([]);
    setFilesError([]);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (abortAllFilesUpload) {
      handleAbortAllFilesUpload();
    }
  }, [abortAllFilesUpload]);

  const getStatusAtIndex = (index: number) => {
    return statuses?.find((status) => status.index === index)?.status;
  };

  const getProgressAtIndex = (index: number) => {
    return progress?.find((progress) => progress.index === index)?.progress;
  };

  const getTempFileAtIndex = (index: number) => {
    return tempFiles?.find((tempFile) => tempFile.index === index)?.file;
  };

  const getFileErrorAtIndex = (index: number) => {
    return filesError?.find((fileError) => fileError.index === index)?.message;
  };

  return (
    <FileCardContainer
      ref={containerRef as any}
      isDisabled={isDisabled}
      onDrop={handleDropFile}
      hasErrors={hasErrors}
      status={
        statuses?.every((status) => status.status === 'success')
          ? 'success'
          : statuses?.some((status) => status.status === 'error')
            ? 'error'
            : 'idle'
      }
      name={name}
      withStyling={!files || files.length === 0}
    >
      {files && files.length ? (
        <Box
          w="full"
          h={multiple ? '400px' : 'auto'}
          overflow="hidden"
          sx={{
            ' .rcs-inner-handle': {
              backgroundColor: 'gray.200',
            },
          }}
          pb="2"
        >
          <CustomScroll heightRelativeToParent={multiple ? 'calc(100% - 0.25rem)' : '100%'}>
            <VStack w="full" spacing="3">
              {files.map(({ file, index }) => (
                <FileCard
                  key={index}
                  handleRemoveFile={() => handleFileRemove(index)}
                  hideProgressBar={hideProgressBar}
                  handleCardClick={(e) => handleCardClick(e, getStatusAtIndex(index) || 'idle')}
                  showDownload={showDownload}
                  fsId={getTempFileAtIndex(index)?.fsID || ''}
                  handleRetry={handleRetry}
                  progress={getProgressAtIndex(index) || 0}
                  status={getStatusAtIndex(index) || 'idle'}
                  file={
                    file ?? {
                      name: getTempFileAtIndex(index)?.fsNume,
                      size: getTempFileAtIndex(index)?.fsSize,
                      type: getTempFileAtIndex(index)?.fsExt,
                    }
                  }
                  fileError={getFileErrorAtIndex(index) || undefined}
                  hideRemoveButton={hideRemoveButton}
                  hideRetryButton={true}
                />
              ))}
            </VStack>
          </CustomScroll>
        </Box>
      ) : renderEmptyState ? (
        renderEmptyState()
      ) : (
        <EmptyState description={description} />
      )}

      {(!files || files.length === 0) && (
        <StyledFileInput
          onChange={handleOnChange}
          ref={inputRef}
          accept={accept}
          type="file"
          id={name}
          multiple={!!multiple}
        />
      )}

      {!multiple && showPreview && !!tempFiles && (
        <Preview
          onRequestClose={() => setShowPreview(false)}
          onAfterClose={() => {}}
          file={tempFiles[0].file}
          height="100%"
          width="100%"
          apiPath={`/fisiere/download?fsID=${tempFiles?.[0]?.file?.fsID}&download=1&_liceuID=${rootOrgId}`}
        />
      )}
    </FileCardContainer>
  );
};
