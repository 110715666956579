import { Entity, Of } from 'entity-of';

@Entity
export class PreferencesPayload {
  @Of(() => String)
  prefCadruShareIstoric = '';

  @Of(() => String)
  uaID = '';

  static of = Entity.of<PreferencesPayload>();
}
