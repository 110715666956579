import React from 'react';

import { TYPE_CADRU, TYPE_ELEV, TYPE_PARINTE, UserTypes } from '@constants';

interface RoleBadgeProps {
  userType: UserTypes;
  badgeText: string;
}

import { Badge } from '@ui2/components';
import { getOrgTypeTheme } from '@ui2/utils';

export const RoleBadge: React.FC<RoleBadgeProps> = ({ userType, badgeText }) => {
  const baseColor = getOrgTypeTheme('blue');

  const badgeColorScheme = {
    [TYPE_ELEV]: baseColor,
    [TYPE_PARINTE]: 'orange',
    [TYPE_CADRU]: 'green',
  };

  return (
    <Badge size="xs" variant="outline" colorScheme={badgeColorScheme[userType]}>
      {badgeText}
    </Badge>
  );
};
