import { Entity, Of } from 'entity-of';

import { AccountBillingProfile } from '../AccountBillingProfile';
import { TenantBillingProfile } from '../BillingProfile';
import { FullDetailedCharge } from '../Charge';
import { Service } from '../Service';

export type InvoiceStatus = 'closed' | 'issued' | 'tosend' | 'canceled';
export type InvoiceRetrieve = 'proforma' | 'invoice' | 'full' | 'user_invoices';
export type InvoiceOverdueNotificationType = 'user' | 'all';

export enum InvoiceType {
  INVOICE = 'invoice',
  PROFORMA = 'proforma',
}

export enum InvoiceSpvStatus {
  TOSEND = 'tosend',
  WAITING = 'waiting',
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

@Entity
export class Invoice {
  @Of(() => Number)
  id = 0;

  @Of(() => Number)
  totalAmount = 0;

  @Of(() => Number)
  netAmount = 0;

  @Of(() => Number)
  vatAmount = 0;

  @Of(() => Number)
  remainingAmount = 0;

  @Of(() => InvoiceType)
  type: InvoiceType = InvoiceType.INVOICE;

  @Of(() => String)
  createdDate = '';

  @Of(() => String)
  dueDate = '';

  @Of(() => String)
  updatedAt = '';

  @Of(() => String)
  status: InvoiceStatus = 'issued';

  @Of(() => Number)
  number = 0;

  @Of(() => String)
  serial = '';

  @Of(() => String)
  accountId = '';

  @Of(() => String)
  tenantId = '';

  @Of(() => Number)
  accountBillingProfileId = 0;

  @Of(() => Number)
  tenantBillingProfileId = 0;

  @Of(() => String)
  billableAccountId = '';

  @Of(() => String)
  currency: string = 'RON';

  @Of(() => [ExchangeRates])
  exchangeRates: ExchangeRates[] = [];

  @Of(() => SerializedInvoiceDetails)
  invoiceDetails = SerializedInvoiceDetails.of({});

  @Of(() => Number)
  reversesInvoiceId: number = 0;

  @Of(() => Number)
  reversedByInvoiceId: number = 0;

  @Of(() => Number, { optional: true })
  relatedInvoiceId?: number;

  @Of(() => Boolean, { optional: true })
  duplicate?: boolean;

  @Of(() => String, { optional: true })
  spvIndex?: string;

  @Of(() => InvoiceSpvStatus, { optional: true })
  spvStatus?: InvoiceSpvStatus;

  @Of(() => String, { optional: true })
  spvError?: string;

  @Of(() => String, { optional: true })
  spvIndexDate?: string;

  static of = Entity.of<Invoice>();
}

@Entity
export class ExchangeRates {
  @Of(() => Number)
  rate = 0;

  @Of(() => String)
  from = '';

  @Of(() => String)
  to = '';

  @Of(() => String)
  createdAt = '';

  static of = Entity.of<ExchangeRates>();
}

@Entity
export class SerializedExchangeRates {
  @Of(() => String)
  baseCurrency = '';

  @Of(() => [ExchangeRates])
  rates: ExchangeRates[] = [];

  static of = Entity.of<SerializedExchangeRates>();
}

@Entity
export class PaymentFragment {
  @Of(() => Number)
  id? = 0;

  @Of(() => Number)
  amount = 0;

  @Of(() => Number)
  invoiceId = 0;

  @Of(() => Number)
  paymentId = 0;

  static of = Entity.of<PaymentFragment>();
}

@Entity
export class SerializedInvoiceDetails {
  invoice?: Invoice;

  @Of(() => [FullDetailedCharge])
  charges: FullDetailedCharge[] = [];

  @Of(() => TenantBillingProfile)
  tenantProfile = TenantBillingProfile.of({});

  @Of(() => AccountBillingProfile)
  accountProfile = AccountBillingProfile.of({});

  @Of(() => Service)
  penaltyService = Service.of({});

  @Of(() => SerializedExchangeRates)
  exchangeRates? = SerializedExchangeRates.of({});

  static of = Entity.of<SerializedInvoiceDetails>();
}
