import { Entity, Of } from 'entity-of';

@Entity
export class TimetableSignInput {
  @Of(() => String)
  description: string = '';

  @Of(() => String)
  activityID: String = '';

  @Of(() => String)
  date: string = '';

  @Of(() => Number)
  isVisibleStudents: 0 | 1 = 0;

  static of = Entity.of<TimetableSignInput>();
}
