import { useTranslation } from 'react-i18next';

import { MODULE_BASE_ROUTES, REPORTS_ROUTES } from '@constants';
import { useRouter } from '@lib/router';
import { LinkButtonItem, LinkButtonsGroup } from '@modules/shared';

export const useInstitutionLinkGroups = () => {
  const { lang } = useRouter();
  const { t } = useTranslation(['Site', 'common']);

  const groups: LinkButtonsGroup[] = [
    {
      items: [
        LinkButtonItem.of({
          icon: 'FiHome',
          label: t('buttons_mm.scoli'),
          url: 'institutions',
          redirectRule: 'window',
        }),
        LinkButtonItem.of({
          icon: 'FiMail',
          label: t('buttons_mm.mesaje'),
          url: `messages`,
          matchingUrls: ['messages/sent', 'messages/drafts', 'messages/archive'],
        }),
        LinkButtonItem.of({
          icon: 'FiBarChart2',
          label: t('buttons_mm.statistici'),
          matchingUrls: [
            'secretary/rankings_attendance',
            'secretary/rankings_attendance/time_slot',
            'secretary/rankings_attendance/classes',
            'secretary/rankings_classes/final_average',
            'secretary/rankings_grades/date_interval',
            'secretary/rankings_grades/time_slot',
            'secretary/rankings_grades/classes',
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.STUDENTS].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.GRADUATE].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.GRADES].join('/'),
            [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.ABSENCES].join('/'),
          ],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.statistici_elevi'),
              url: [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.STUDENTS].join('/'),
              matchingUrls: [
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.STUDENTS].join('/'),
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.GRADUATE].join('/'),
              ],
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.statistici_note_si_medii'),
              url: [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.GRADES].join('/'),
              matchingUrls: [
                'reports/grades',
                'secretary/rankings_grades',
                'secretary/rankings_grades/time_slot',
                'secretary/rankings_grades/classes',
                'secretary/rankings_grades/average',
                'secretary/rankings_classes/final_average',
                'secretary/rankings_grades/date_interval',
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.GRADES].join('/'),
              ],
            }),

            LinkButtonItem.of({
              label: t('buttons_mm.statistici_absente'),
              url: [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.ABSENCES].join('/'),

              matchingUrls: [
                [MODULE_BASE_ROUTES.REPORTS, REPORTS_ROUTES.ABSENCES].join('/'),
                'secretary/rankings_attendance',
                'secretary/rankings_attendance/time_slot',
                'secretary/rankings_attendance/classes',
              ],
            }),
          ],
        }),
        LinkButtonItem.of({
          icon: 'FiBell',
          label: t('buttons_mm.noutati'),
          url: 'news',
        }),
        LinkButtonItem.of({
          icon: 'FiHelpCircle',
          label: t('buttons_mm.support'),
          url:
            lang === 'ro'
              ? 'https://manual.adservio.ro/web/'
              : 'https://manual.adservio.ro/web/en/',
          redirectRule: 'blank',
          hasTopDivider: true,
        }),
      ],
    },
  ];

  return {
    groups,
  };
};
