import { phonePrefixes } from '.';

export const computePhoneInputValue = (phone: string | null, defaultPrefix?: string) => {
  if (!phone) {
    const prefix = String(phonePrefixes.find((f) => f.code === defaultPrefix)?.dial_code) || '';

    return {
      phone: '',
      prefix: prefix,
    };
  }

  let response = {
    phone: phone,
    prefix: '',
  };

  phonePrefixes.forEach((f) => {
    if (phone.startsWith(f.dial_code) && f.dial_code.length > response.prefix.length) {
      response = {
        phone: phone.replace(f.dial_code, ''),
        prefix: f.dial_code,
      };
    }
  });

  return response;
};
