import { Entity, Of } from 'entity-of';

@Entity
export class TestQueryInput {
  @Of(() => String)
  testID: string = '';

  @Of(() => String)
  testStatus: string = '';

  @Of(() => String)
  withResults: string = '';

  @Of(() => String)
  elevID: string = '';

  static of = Entity.of<TestQueryInput>();
}
