import React from 'react';

import { Switch, SwitchProps } from '../../../atoms/Switch';
import { FormElement, FormElementProps } from '../FormElement';

export const FormSwitch = ({
  testId,
  name,
  label,
  isRequired,
  helperText,
  showHelperText,
  formControlProps,
  swapLabel,
  errorBehaviour,
  onChangeCallback,
  ...props
}: React.PropsWithChildren<FormElementProps<SwitchProps>>) => {
  return (
    <FormElement
      inlineLayout
      {...{
        name,
        label,
        isRequired,
        helperText,
        showHelperText,
        testId,
        formControlProps,
        swapLabel,
        errorBehaviour,
        onChangeCallback,
      }}
    >
      <Switch {...props} />
    </FormElement>
  );
};
