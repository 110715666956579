import { TranslatedLanguageItem } from '@api/core/entities';
import { Entity, Of } from 'entity-of';

import { PricingModel, SubscriberType } from '../Package';
import { PackageFeature } from '../PackageFeature';

@Entity
export class PackageUpdateInput {
  @Of(() => String)
  name = '';

  @Of(() => String)
  countryId = '';

  @Of(() => String)
  organizationTypeId = '';

  @Of(() => SubscriberType, { nullable: true })
  subscriberType = 'student';

  @Of(() => String)
  description = '';

  @Of(() => String)
  internalReference = '';

  @Of(() => Boolean)
  isPublic = false;

  @Of(() => Number, { optional: true })
  trialDays?: number;

  @Of(() => Boolean)
  isPopular = false;

  @Of(() => Number)
  order = 1;

  @Of(() => Number, { nullable: true })
  groupId: number | null = null;

  @Of(() => String)
  currencyCode: string = '';

  @Of(() => Number)
  monthlyPrice: number | null = null;

  @Of(() => Number)
  yearlyPrice: number | null = null;

  @Of(() => String)
  pricingModel = PricingModel.FIXED;

  @Of(() => Number, { nullable: true })
  licensesLimit: number | null = null;

  @Of(() => Number, { nullable: true })
  billingProfileId: number | null = null;

  @Of(() => String)
  studentDefaultPackageId?: string = '';

  @Of(() => [PackageFeature])
  featuresData: PackageFeature[] = [];

  @Of(() => [TranslatedLanguageItem])
  packageName: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  packageDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  priceLabel: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  priceDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  featuresDescription: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature1: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature2: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature3: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature4: TranslatedLanguageItem[] = [];

  @Of(() => [TranslatedLanguageItem])
  feature5: TranslatedLanguageItem[] = [];

  @Of(() => String)
  colorTheme: string = '';

  @Of(() => [TranslatedLanguageItem])
  promoBannerDescription: TranslatedLanguageItem[] = [];

  static of = Entity.of<PackageUpdateInput>();
}
