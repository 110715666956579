import { Faculty, StudentType } from '@modules/shared';
import { Entity, Of } from 'entity-of';

@Entity
export class Study {
  @Of(() => Number)
  id: number = 0;

  @Of(() => Number)
  studentId: number = 0;

  @Of(() => Faculty)
  faculty: Faculty = Faculty.of({});

  @Of(() => Number)
  faculty_id: number = 0;

  @Of(() => Number)
  studentTypeId: number = 0;

  @Of(() => String)
  registrationNumber: string = '';

  @Of(() => String)
  uniqueRegistrationNumber: string = '';

  @Of(() => StudentType, { nullable: true })
  studentType: StudentType | null = null;

  @Of(() => String)
  educationForm: string = '';

  @Of(() => Number, { optional: true })
  year?: number;

  @Of(() => String, { optional: true })
  profile?: string;

  @Of(() => String, { optional: true })
  specialisation?: string;

  @Of(() => String, { optional: true })
  type?: string;

  @Of(() => String, { optional: true })
  series?: string;

  @Of(() => String, { optional: true })
  group?: string;

  @Of(() => Number, { optional: true })
  isBudget?: number;

  @Of(() => String, { optional: true })
  startYear?: string;

  @Of(() => String, { optional: true })
  endYear?: string;

  static of = Entity.of<Study>();
}
