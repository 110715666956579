import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class RepartitionInput {
  @Of(() => String, { optional: true })
  @IsNotEmpty({ message: 'errors.studentId.required' })
  studentId: number | undefined;

  @Of(() => Number, { optional: true })
  facultyId?: number;

  @Of(() => Number, { optional: true })
  studentStudyId?: number;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.checkInDate.required' })
  checkInDate?: string;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.checkOutDate.required' })
  checkOutDate?: string;

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.noOfBeds.required' })
  noOfBeds?: number;

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.dormId.required' })
  dormId?: number;

  @Of(() => Number)
  @IsNotEmpty({ message: 'errors.roomId.required' })
  roomId?: number;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.didacticPeriodEndDate.required' })
  didacticPeriodEndDate?: string;

  @Of(() => Number, { optional: true })
  @IsNotEmpty({ message: 'errors.pricePlanId.required' })
  pricePlanId?: number;

  @Of(() => String, { nullable: true })
  carNumber: string | null = null;

  @Of(() => String)
  observations: string = '';

  @Of(() => Boolean, { optional: true })
  isReserved: boolean = false;

  static of = Entity.of<RepartitionInput>();
}
