import { Entity, Of } from 'entity-of';

export type Status = 'start' | 'cancel' | 'finish' | 'regenerate';
@Entity
export class TimetableWizardStatusInput {
  @Of(() => String)
  type: Status = 'start';

  static of = Entity.of<TimetableWizardStatusInput>();
}
