import { Entity, Of } from 'entity-of';

@Entity
export class ClassAllocationInput {
  @Of(() => String)
  clID: string = '';

  @Of(() => Number, { optional: true })
  cuElevi?: number = 0;

  static of = Entity.of<ClassAllocationInput>();
}
