import { Entity, Of } from 'entity-of';

@Entity
export class PaginatedPaymentsInput {
  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => [String], { optional: true })
  groupIds?: string[];

  @Of(() => [String], { optional: true })
  billableAccountIds?: string[];

  @Of(() => String, { optional: true })
  rootOrgId?: string;

  @Of(() => String, { optional: true })
  xAccountId?: string;

  @Of(() => String, { optional: true })
  type?: string;

  @Of(() => Number, { optional: true })
  amount?: number;

  @Of(() => String, { optional: true })
  createdAt?: string;

  @Of(() => Number, { optional: true })
  offset?: number;

  @Of(() => Number, { optional: true })
  limit?: number;

  @Of(() => String, { optional: true })
  order?: 'asc' | 'desc';

  @Of(() => String, { optional: true })
  orderBy?: string;

  static of = Entity.of<PaginatedPaymentsInput>();
}
