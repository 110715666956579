import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { createValidator, FormInput, FormModal } from '@ui2/components';
import Delta from 'quill-delta';

import { EditorSelection, LinkInput } from '../../utils';

const validator = createValidator(LinkInput);

interface LinkModalProps {
  editorRef: any;
  isOpen: boolean;
  selection: EditorSelection;
  onClose: () => void;
}

export const LinkModal = ({ editorRef, isOpen, selection, onClose }: LinkModalProps) => {
  const { t } = useTranslation('shared');

  const handleSubmit = (values) => {
    let quill = editorRef.current.getEditor();

    const link =
      values.url.startsWith('http://') || values.url.startsWith('https://')
        ? values.url
        : `http://${values.url}`;

    const attributes = {
      link,
      target: values.target,
    };

    quill.updateContents(
      new Delta().retain(selection.index).delete(selection.length).insert(values.text, attributes)
    );

    quill.setSelection(selection.index + values?.text?.length || 0, 0);
    onClose();
  };

  const getEditorSelection = () => {
    let quill = editorRef.current.getEditor();
    const range = quill.getSelection();

    return quill.getContents(range.index, range.length);
  };

  const defaultValues = useMemo(() => {
    if (!isOpen) {
      return {};
    }

    const sel = getEditorSelection();

    return {
      text: sel.ops?.[0]?.insert,
      url: sel.ops?.[0]?.attributes?.link || '',
      target: '_blank',
    };
  }, [isOpen]);

  // const linkTargetOptions = [
  //   {
  //     value: '_blank',
  //     label: t('general.new_window'),
  //   },
  //   {
  //     value: '_self',
  //     label: t('general.current_window'),
  //   },
  // ];

  return (
    <FormModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      title={t('general.add_insert_link')}
      defaultValues={defaultValues}
      validator={validator}
    >
      <FormInput name="url" label="URL" />
      <FormInput name="text" label={t('general.text_to_display')} />
      {/* <FormSyncSelect name="target" label={t('general.open_link_in')} options={linkTargetOptions} /> */}
    </FormModal>
  );
};
