import { Progress, ProgressProps } from '@chakra-ui/react';
import { HStack, Text } from '@ui2/components';
import { getOrgTypeTheme } from '@ui2/utils';
interface ProgressBarProps extends ProgressProps {
  withProgressText?: boolean;
}

export const ProgressBar = ({ value, withProgressText, ...props }: ProgressBarProps) => {
  const baseColor = getOrgTypeTheme('blue');

  return (
    <HStack spacing="4" w="full">
      <Progress
        colorScheme={baseColor}
        borderRadius="md"
        value={value}
        w="full"
        size="sm"
        {...props}
      />
      {withProgressText && (
        <Text fontSize="sm" fontWeight="semibold" color="gray.600">
          {value}%
        </Text>
      )}
    </HStack>
  );
};
