import React from 'react';
import { useTranslation } from 'react-i18next';

import { PERM_CAD_DIRECTOR, PERM_CAD_SECRETAR } from '@constants';
import { useHasAccess } from '@lib/utils/session/hasAccess';
import { EmptyFallback } from '@modules/shared';
import { Vectors } from '@ui2/assets';

export const NoSubscriptionEmptyState = () => {
  const { t } = useTranslation('shared');

  const isHeadmaster = useHasAccess({ permissions: [PERM_CAD_DIRECTOR] });
  const isSecretary = useHasAccess({ permissions: [PERM_CAD_SECRETAR] });

  return (
    <EmptyFallback
      imgSrc={Vectors.NoAccessAdservio}
      title={t('general.no_access_to_adservio_title')}
      description={
        isHeadmaster || isSecretary
          ? t('general.no_access_to_adservio_rep_description')
          : t('general.no_access_to_adservio_description')
      }
    />
  );
};
