// @ts-nocheck
import axios from 'axios';

import { API_URL } from '../constants';

// const jwtDecode = () => undefined;

const { CancelToken } = axios;
const Api = {
  host: API_URL,
  _liceuID: null,

  async getTokenId() {
    try {
      const res = await fetch(`${this.host}/auth`, { method: 'get' });
      const data = await res.json();

      return data;
    } catch (err) {
      return undefined;
    }
  },

  getLiceuID() {
    // eslint-disable-next-line no-underscore-dangle
    return Api._liceuID;
  },

  setLiceuID(liceuID) {
    // eslint-disable-next-line no-underscore-dangle
    Api._liceuID = liceuID;
  },

  get<T>(url, params?) {
    const source = CancelToken.source();

    const options = {
      method: 'get',
      baseURL: this.host,
      url,
      params: this.addExtraParams(params),
      cancelToken: source.token,
    };

    return this.call<T>(options, source);
  },

  post<T>(url, data, headers: Record<string, string> = { 'Content-Type': 'multipart/form-data' }) {
    const options = {
      method: 'post',
      baseURL: this.host,
      headers,
      url,
      data,
    };

    if (headers['Content-Type'] === 'multipart/form-data') {
      options.transformRequest = [this.addExtraParams.bind(this), this.getFormData];
    }

    return this.call<T>(options);
  },

  put<T>(url, data) {
    const options = {
      method: 'put',
      baseURL: this.host,
      transformRequest: [this.addExtraParams.bind(this), this.getFormData],
      url,
      data,
    };

    return this.call<T>(options);
  },

  del<T>(url, data?) {
    const options = {
      method: 'delete',
      baseURL: this.host,
      url,
      params: this.addExtraParams(data),
    };

    return this.call<T>(options);
  },

  call<T>(options, source) {
    const newPromise = new Promise<T>((res, rej) => {
      const enhancedOptions = options;

      axios(enhancedOptions)
        .then((data) => {
          res(data.data);
        })
        .catch((error) => {
          rej(error);
          // if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          //   axios({
          //     method: 'delete',
          //     baseURL: this.host,
          //     url: '/auth',
          //     params: this.addExtraParams({}),
          //   }).then(
          //     () => {
          //       window.location.href = '/';
          //     },
          //     () => {
          //       window.location.href = '/';
          //     }
          //   );
          // }
        });
    });

    newPromise.cancelCall = (reason) => {
      let enhancedReason = reason;

      if (!enhancedReason) {
        enhancedReason = 'Operation canceled by the user.';
      }

      return source ? source.cancel(enhancedReason) : false;
    };
    return newPromise;
  },

  getFormData(data) {
    const form = new FormData();

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        if (!data[key].length) {
          form.append(`${key}[]`, '');
        }

        data[key].forEach((value, index) => {
          if (typeof value === 'object') {
            for (const prop in value) {
              form.append(`${key}[${index}][${prop}]`, value[prop]);
            }
          } else {
            form.append(`${key}[${index}]`, value);
          }
        });

        return;
      }

      form.append(key, data[key]);
    });

    return form;
  },

  addExtraParams(data) {
    let enhancedData = data;

    if (Api.getLiceuID() && !data?._liceuID) {
      enhancedData = { _liceuID: this.getLiceuID(), ...data };
    }

    return enhancedData;
  },
};

export default Api;
