import { WeekType } from '@api/core/entities';
import { ISO_DAYS_TYPE } from '@constants';
import { IsAfterStartHour } from '@lib/validators';
import { IsNotEmpty, Matches, ValidateIf } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { ActivityType } from '../TimetableActivity';

@Entity
export class CreateTimetableActivityInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.value.required' })
  slotID?: string;

  @Of(() => String)
  @ValidateIf((o) => o.slotID === '0')
  @IsNotEmpty({ message: 'errors.value.required' })
  startHour?: string;

  @Of(() => String)
  @ValidateIf((o) => o.slotID === '0')
  @IsNotEmpty({ message: 'errors.value.required' })
  @IsAfterStartHour({ message: 'errors.endHour.beforeStartHour' })
  endHour?: string;

  @Of(() => String, { nullable: true })
  @IsNotEmpty({ message: 'errors.value.required' })
  dayID: ISO_DAYS_TYPE | null = null;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.value.required' })
  clID?: string;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.value.required' })
  cadID?: string;

  @Of(() => Number)
  roomID?: number;

  @Of(() => ActivityType)
  type: ActivityType = ActivityType.OFFICIAL;

  @Of(() => String)
  cmcID?: string;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.value.required' })
  matID?: string;

  @Of(() => String)
  @ValidateIf((o) => o.matID === ActivityType.EXTRA)
  @Matches(/^[^-\s]/, {
    message: 'errors.admission.no_white_space',
  })
  @IsNotEmpty({ message: 'errors.value.required' })
  name?: string;

  @Of(() => String)
  @IsNotEmpty({ message: 'errors.weekType.required' })
  weekType?: WeekType;

  static of = Entity.of<CreateTimetableActivityInput>();
}
