import { Entity, Of } from 'entity-of';

@Entity
export class GroupEvent {
  @Of(() => String)
  evsID = '';

  @Of(() => String)
  evsStartDate = '';

  @Of(() => String)
  evsEndDate = '';

  @Of(() => GroupEventInfo)
  eventInfo = GroupEventInfo.of({});

  static of = Entity.of<GroupEvent>();
}

@Entity
class GroupEventInfo {
  @Of(() => String)
  evDescription = '';

  @Of(() => String)
  evName = '';

  @Of(() => String)
  evGrpID = '';

  @Of(() => String)
  evID = '';

  static of = Entity.of<GroupEventInfo>();
}
