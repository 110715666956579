import { useRouter } from '@lib/router';

export const usePageContext = () => {
  const { lang, location } = useRouter();
  let routes: string[] = JSON.parse(localStorage.getItem('routeHistory') || '{}') as string[];

  const getLastMatchingRoute = (onBackMatches: RegExp[], fallbackRoute?: string) => {
    for (let i = routes.length - 1; i >= 0; i--) {
      if (
        onBackMatches.some((match) => {
          const parsedLocation = getParsedRoute(location.pathname, location.hash);

          return (
            new RegExp(match).test(routes[i]) &&
            routes[i] !== parsedLocation &&
            routes[i].split('#')[0].split('?')[0] !== parsedLocation.split('#')[0].split('?')[0]
          );
        })
      ) {
        return routes[i];
      }
    }

    return fallbackRoute || '';
  };

  const getParsedRoute = (pathname: string, hash?: string) => {
    return `${pathname.split(`${lang}/`)[1]}${hash || ''}`;
  };

  return {
    getParsedRoute,
    getLastMatchingRoute,
  };
};
