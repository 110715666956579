import { Entity, Of } from 'entity-of';

import { CertificateFields } from '../../Certificate';

@Entity
export class EditCertificateInput {
  @Of(() => CertificateFields)
  cerData: CertificateFields = CertificateFields.of({});

  static of = Entity.of<EditCertificateInput>();
}
