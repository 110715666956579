import { Entity, Of } from 'entity-of';

import { SimpleQueryInput } from '../../../shared';

@Entity
export class CertificatesSearchInput extends SimpleQueryInput {
  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  clID: string = '';

  @Of(() => String)
  uaID?: string = '';

  @Of(() => String)
  cerTypeCategory: string = '';

  @Of(() => String)
  cerType: string = '';

  @Of(() => String)
  cerDate: string = '';

  @Of(() => String)
  createdAt: string = '';

  static of = Entity.of<CertificatesSearchInput>();
}

@Entity
export class CertificateSearchInput {
  @Of(() => String)
  id: string = '';

  static of = Entity.of<CertificateSearchInput>();
}
