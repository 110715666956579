import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdservioIcons,
  Center,
  Checkbox,
  Flex,
  IconButton,
  Spinner,
  Text,
  VStack,
} from '../../../atoms';
import { FeatureIcon, Menu, MenuButton, MenuList } from '../../../molecules';
import { CKTableContainer, Td, Tr } from '../';

interface ExpandRowButtonProps {
  onClick: () => void;
  isExpanded: boolean;
  children?: React.ReactNode;
}

export const ExpandRowButton = ({ onClick, isExpanded, ...props }: ExpandRowButtonProps) => {
  return (
    <Flex p="1">
      <IconButton
        size="sm"
        variant="ghost"
        onClick={onClick}
        colorScheme="gray"
        aria-label="expand"
        icon={isExpanded ? <AdservioIcons.FiChevronUp /> : <AdservioIcons.FiChevronDown />}
        {...props}
      />
    </Flex>
  );
};

export const SelectRowCheckbox = React.forwardRef<HTMLInputElement, any>(
  ({ indeterminate, checked, ...props }, _) => {
    return (
      <Flex p="1">
        <Checkbox isIndeterminate={indeterminate} isChecked={checked} {...props} />
      </Flex>
    );
  }
);

interface RowMenuProps {
  isLoading?: boolean;
}

export const RowMenu = ({ isLoading, children }: PropsWithChildren<RowMenuProps>) => {
  return (
    <Flex>
      {isLoading ? (
        <Center width="2rem">
          <Spinner size="sm" />
        </Center>
      ) : (
        <Menu placement="bottom-end" isLazy>
          <MenuButton
            size="sm"
            variant="ghost"
            as={IconButton}
            colorScheme="gray"
            aria-label="row menu"
            data-testid="row-menu"
            icon={<AdservioIcons.FiMoreVertical />}
          />
          <MenuList w="auto" minW="auto" maxW="auto">
            {children}
          </MenuList>
        </Menu>
      )}
    </Flex>
  );
};

export const NoResults_TABLE_ONLY = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation('shared');

  return (
    <Tr>
      <Td colSpan={100} textAlign="center" p="16" h="33vh" borderBottom="0">
        {children && <>{children}</>}
        {!children && (
          <VStack>
            <FeatureIcon name="FiSearch" size="xl" variant="default" />
            <Text fontSize="semibold">{t('general.noResults')}</Text>
          </VStack>
        )}
      </Td>
    </Tr>
  );
};

export const Error = ({ children }: { children: React.ReactNode }) => {
  return (
    <Tr>
      <Td colSpan={100} textAlign="center" p="16" h="33vh">
        <FeatureIcon size="xl" margin="auto" variant="error" name="FiAlertCircle" />
        <Text color="red.500" fontWeight="medium">
          {children}
        </Text>
      </Td>
    </Tr>
  );
};

export const Loading = () => {
  const { t } = useTranslation('shared');

  return (
    <Tr>
      <Td colSpan={100} textAlign="center" p="16" h="33vh">
        <Spinner />
        <Text color="gray.400" fontWeight="medium">
          {t('general.loading')}
        </Text>
      </Td>
    </Tr>
  );
};

export const TableContainer = ({ children, ...props }: { children: React.ReactNode }) => {
  return (
    <CKTableContainer
      width="full"
      height="auto"
      borderRadius="xl"
      border="1px solid"
      borderColor="gray.200"
      boxShadow="xs"
      {...props}
    >
      {children}
    </CKTableContainer>
  );
};

export const MobileTableContainer = ({ children, ...props }: { children: React.ReactNode }) => {
  return (
    <VStack w="100%" spacing={4} {...props}>
      {children}
    </VStack>
  );
};
