import { Entity, Of } from 'entity-of';

import { PeriodBlockedInfo } from './AddWarningForm';

@Entity
export class AddWarningInput {
  @Of(() => String)
  elevID: string = '';

  @Of(() => String)
  ascID: string = '';

  @Of(() => String)
  avtNumar: string = '';

  @Of(() => String)
  avtData: string = '';

  @Of(() => String)
  avtMotiv: string = '';

  @Of(() => String)
  avtShowInReports: '0' | '1' = '0';

  @Of(() => [PeriodBlockedInfo], { optional: true })
  blockedPeriod?: PeriodBlockedInfo[];

  static of = Entity.of<AddWarningInput>();
}
