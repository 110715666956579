import { Entity, Of } from 'entity-of';

@Entity
export class SchoolConfig {
  @Of(() => SchoolConfigValues)
  rapoarte_elevi: SchoolConfigValues = SchoolConfigValues.of({});

  @Of(() => SchoolConfigValues)
  stiri: SchoolConfigValues = SchoolConfigValues.of({});

  @Of(() => SchoolConfigValues)
  subscriptions: SchoolConfigValues = SchoolConfigValues.of({});

  @Of(() => SchoolConfigValues)
  timetable: SchoolConfigValues = SchoolConfigValues.of({});

  @Of(() => Catalog)
  catalog: Catalog = Catalog.of({});

  @Of(() => BillingConfigValues)
  billing: BillingConfigValues = BillingConfigValues.of({});

  @Of(() => String)
  defaultLanguage: string = '';

  @Of(() => SchoolConfigAttendanceRegister)
  attendance_register: SchoolConfigAttendanceRegister = SchoolConfigAttendanceRegister.of({});

  @Of(() => Conduita)
  conduita: Conduita = Conduita.of({});

  @Of(() => SchoolConfigValues)
  progress_grades: SchoolConfigValues = SchoolConfigValues.of({});

  @Of(() => Chestionare)
  chestionare: Chestionare = Chestionare.of({});

  @Of(() => SchoolConfigValues)
  studentPortfolio: SchoolConfigValues = SchoolConfigValues.of({});

  @Of(() => SchoolConfigOrganizationType)
  organization: SchoolConfigOrganizationType = SchoolConfigOrganizationType.of({});

  @Of(() => String)
  liceuObservatii: string = '';

  static of = Entity.of<SchoolConfig>();
}

@Entity
export class SchoolConfigOrganizationType {
  @Of(() => String)
  liceuTehnic: string = '';

  @Of(() => String)
  liceuVocational: string = '';

  static of = Entity.of<SchoolConfigOrganizationType>();
}

@Entity
export class SchoolConfigValues {
  @Of(() => String)
  isActive: string = '0';

  @Of(() => String)
  isSelfManaged: string = '0';

  static of = Entity.of<SchoolConfigValues>();
}

@Entity
export class SchoolConfigAttendanceRegister {
  @Of(() => String)
  isActive: string = '0';

  @Of(() => String)
  allowedActionsDays: string = '0';

  static of = Entity.of<SchoolConfigAttendanceRegister>();
}

@Entity
export class BillingConfigValues {
  @Of(() => String)
  isActive: string = '0';

  @Of(() => String)
  allowClientLegalEntity: string = '0';

  static of = Entity.of<BillingConfigValues>();
}

@Entity
export class Catalog {
  @Of(() => String)
  absZileMotivare: string = '0';

  @Of(() => String)
  absZileStergere: string = '0';

  @Of(() => String)
  avtZileStergere: string = '0';

  @Of(() => String)
  avtZileModificare: string = '0';

  @Of(() => String)
  mediiModProf: string = '0';

  @Of(() => String)
  noteZileStergereFaraAprobare: string = '0';

  @Of(() => String)
  tcAbsenteAcordare: string = '0';

  @Of(() => String)
  tcAbsenteVizualizare: string = '0';

  @Of(() => String)
  tcMediiVizualizare: string = '0';

  @Of(() => String)
  tcNoteAcordare: string = '0';

  @Of(() => String)
  tcNoteVizualizare: string = '0';

  @Of(() => String)
  tcVizibil: string = '0';

  @Of(() => String)
  gradebookVersion: string = 'false';

  static of = Entity.of<Catalog>();
}

@Entity
export class Chestionare {
  @Of(() => String)
  feedbackIsActive: string = '0';

  @Of(() => String)
  filterSubjectsActive: string = '0';

  @Of(() => String)
  formsRaportZilnicMasa: string = '0';

  @Of(() => String)
  isActive: string = '0';

  @Of(() => String)
  surveyIsActive: string = '0';

  @Of(() => String)
  testIsActive: string = '0';

  static of = Entity.of<Chestionare>();
}

@Entity
export class Conduita {
  @Of(() => String)
  cadreMotivNou: string = '0';

  @Of(() => String)
  creareMotiveCadru: string = '0';

  @Of(() => String)
  isActive: string = '0';

  @Of(() => String)
  scorAbateri: string = '0';

  @Of(() => String)
  scorRecompense: string = '0';

  @Of(() => String)
  scorRemarci: string = '0';

  static of = Entity.of<Conduita>();
}
