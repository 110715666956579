import { Entity, Of } from 'entity-of';

import { InvoiceType } from '../../Invoice';
import { CreateTenantNumberSeriesInput } from '../../TenantSeriesNumber/inputs';
import { EfacturaIssueType, OrganisationType } from '../TenantBillingProfile';

@Entity
export class BillingProfileInput {
  @Of(() => String)
  name: string = '';

  @Of(() => String)
  alias: string = '';

  @Of(() => Boolean)
  vatPayer: boolean = false;

  @Of(() => String)
  fiscalCode: string = '';

  @Of(() => String)
  street: string = '';

  @Of(() => String)
  zipCode: string = '';

  @Of(() => String)
  country: string = '';

  @Of(() => String)
  county: string = '';

  @Of(() => String)
  city: string = '';

  @Of(() => String)
  countryId: string = '';

  @Of(() => String)
  countyId: string = '';

  @Of(() => String, { optional: true })
  cityId?: string;

  @Of(() => String)
  email: string = '';

  @Of(() => String)
  phone: string = '';

  @Of(() => String)
  bankName: string = '';

  @Of(() => String)
  iban: string = '';

  @Of(() => [CreateTenantNumberSeriesInput])
  invoiceSeries: CreateTenantNumberSeriesInput[] = [];

  @Of(() => [CreateTenantNumberSeriesInput])
  proformaSeries: CreateTenantNumberSeriesInput[] = [];

  @Of(() => InvoiceType)
  defaultInvoiceType: InvoiceType | null = null;

  @Of(() => String)
  currency: string = '';

  @Of(() => Number)
  invoiceDay: number | null = 1;

  @Of(() => Number, { optional: true })
  invoiceDueAfter?: number;

  @Of(() => String, { optional: true })
  otherInformation?: string;

  @Of(() => Number, { optional: true })
  penaltyPercentage?: number;

  @Of(() => Boolean)
  applyProformaPenalties: boolean = false;

  @Of(() => Boolean, { optional: true })
  displayBankOnInvoice?: boolean;

  @Of(() => Boolean)
  acceptsOnlinePayments: boolean = false;

  @Of(() => String)
  defaultInvoiceStatus: string = '';

  @Of(() => String, { optional: true })
  nrJ?: string;

  @Of(() => String, { optional: true })
  capital?: string;

  @Of(() => String, { optional: true })
  organisationType?: OrganisationType;

  @Of(() => String, { optional: true })
  logoURL?: string;

  @Of(() => Boolean)
  eFacturaActive: boolean = false;

  @Of(() => EfacturaIssueType, { optional: true })
  eFacturaIssueType?: EfacturaIssueType;

  static of = Entity.of<BillingProfileInput>();
}
