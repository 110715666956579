import { Entity, Of } from 'entity-of';

@Entity
export class AccountRole {
  @Of(() => String)
  roleName = '';

  static of = Entity.of<AccountRole>();
}

@Entity
export class Account {
  @Of(() => String)
  id = '';

  @Of(() => String)
  name = '';

  @Of(() => [AccountRole])
  role: AccountRole[] = [];

  @Of(() => String)
  avatar = '';

  @Of(() => String)
  billableAccountId = '';

  @Of(() => String)
  parentId = '';

  @Of(() => String, { optional: true })
  className?: string;

  static of = Entity.of<Account>();
}
