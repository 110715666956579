import { Entity, Of } from 'entity-of';

@Entity
export class SimpleQueryInput {
  @Of(() => Number, { optional: true })
  _limit?: number;

  @Of(() => Number, { optional: true })
  _offset?: number;

  @Of(() => String, { optional: true })
  _order?: string;

  static of = Entity.of<SimpleQueryInput>();
}
