import { IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

@Entity
export class RecoverPasswordInput {
  @IsNotEmpty({ message: 'errors.uaUserName.required' })
  @Of(() => String)
  uaUserName: string = '';

  @IsNotEmpty({ message: 'errors.recaptcha.required' })
  @Of(() => String)
  recaptcha: string = '';

  static of = Entity.of<RecoverPasswordInput>();
}
