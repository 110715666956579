import { ContentEmptyState, FeatureIcon, SimpleGrid, VStack } from '@ui2/components';

export const SearchResultsSection = ({ children }) => {
  return (
    <SimpleGrid
      pb="4"
      w="full"
      minH="10vh"
      spacing="4"
      overflow="visible"
      alignItems="flex-start"
      alignContent="flex-start"
      columns={{ base: 1, md: 2 }}
    >
      {children}
    </SimpleGrid>
  );
};

export const SearchPersonsContainer = ({ children }) => {
  return (
    <VStack spacing="8" mt="8">
      {children}
    </VStack>
  );
};

export const SearchResultsPlaceholder = ({ placeholder }: { placeholder: string }) => {
  return (
    <ContentEmptyState
      w="full"
      h="16rem"
      description={placeholder}
      data-testid="search-accounts-results-placeholder"
      renderHeader={() => <FeatureIcon variant="default" name="FiUsers" size="xl" />}
    />
  );
};
