import { Entity, Of } from 'entity-of';

import { Subject } from '../../materii';

@Entity
export class AbsenceDetails {
  @Of(() => String)
  absID: string = '';

  @Of(() => String)
  absE2mID: string = '';

  @Of(() => String)
  absCadruID: string = '';

  @Of(() => String)
  absSanID: string = '';

  @Of(() => String)
  absData: string = '';

  @Of(() => String)
  absDataInsert: string = '';

  @Of(() => String)
  absStatus: string = '';

  @Of(() => String, { nullable: true })
  absMotivareCadruID: string | null = null;

  @Of(() => String)
  absMotivareData = null;

  @Of(() => String, { nullable: true })
  absMotivareExtraInfo: string | null = null;

  @Of(() => String, { nullable: true })
  absObs: string | null = null;

  @Of(() => Subject)
  cmcMaterie: Subject = Subject.of({});

  static of = Entity.of<AbsenceDetails>();
}
