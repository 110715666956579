import { Entity, Of } from 'entity-of';

@Entity
export class PackagesTableFilters {
  @Of(() => String, { optional: true })
  searchTerm?: string;

  @Of(() => String, { optional: true })
  orgType?: string;

  @Of(() => String, { optional: true })
  status?: 'Enabled' | 'Disabled' | 'Review';

  @Of(() => String, { optional: true })
  country?: string;

  static of = Entity.of<PackagesTableFilters>();
}
