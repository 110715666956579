import { ComponentMultiStyleConfig } from '@chakra-ui/react';
import { getOrgTypeTheme } from '@ui2/utils';

const baseColor = getOrgTypeTheme('blue');

export const CheckboxTheme: ComponentMultiStyleConfig = {
  parts: ['icon', 'label', 'container', 'control'],
  baseStyle: {
    control: {
      borderRadius: '4px',
      _focus: {
        boxShadow: 'inputFocus',
        borderColor: `${baseColor}.500`,
        backgroundColor: `${baseColor}.100`,
        _invalid: {
          boxShadow: 'inputInvalid',
          backgroundColor: 'red.100',
        },
      },
      _hover: {
        borderColor: `${baseColor}.300`,
        boxShadow: 'inputFocus',
        backgroundColor: `${baseColor}.100`,
        _invalid: {
          borderColor: 'red.300',
          boxShadow: 'inputInvalid',
          backgroundColor: 'red.100',
        },
      },
      _invalid: {
        boxShadow: 'inputInvalid',
      },
      _checked: {
        backgroundColor: `${baseColor}.100`,
        borderColor: `${baseColor}.500`,
        boxShadow: 'inputFocus',
        _invalid: {
          backgroundColor: 'red.100',
          boxShadow: 'inputInvalid',
          _before: {
            backgroundColor: 'red.500',
          },
          _disabled: {
            boxShadow: 'unset',
            borderColor: 'gray.200',
            '& > div': {
              '& > *': {
                color: 'red.500',
              },
            },
          },
          '& > div': {
            '& > *': {
              color: 'red.500',
            },
          },
        },
        _hover: {
          backgroundColor: `${baseColor}.100`,
          borderColor: `${baseColor}.500`,
          _invalid: {
            backgroundColor: 'red.100',
            borderColor: 'red.500',
          },
        },
        _before: {
          backgroundColor: `${baseColor}.500`,
        },
      },
      _indeterminate: {
        backgroundColor: `${baseColor}.100`,
        boxShadow: 'inputFocus',
        _invalid: {
          backgroundColor: 'red.100',
          boxShadow: 'inputInvalid',
          _before: {
            backgroundColor: 'red.500',
          },
          _disabled: {
            borderColor: 'gray.200',
            boxShadow: 'unset',
            '& > div': {
              '& > *': {
                color: 'red.500',
              },
            },
          },
          '& > div': {
            '& > *': {
              color: 'red.500',
            },
          },
        },
        _hover: {
          backgroundColor: `${baseColor}.100`,
          borderColor: `${baseColor}.500`,
          _invalid: {
            backgroundColor: 'red.100',
            borderColor: 'red.500',
          },
        },
        _disabled: {
          _hover: {
            borderColor: 'gray.200',
          },
        },
        _before: {
          backgroundColor: `${baseColor}.500`,
        },
      },
      _disabled: {
        boxShadow: 'unset',
        borderColor: 'gray.200',
        _focus: {
          boxShadow: 'unset',
        },
        _invalid: {
          boxShadow: 'unset',
          borderColor: 'red.200',
          _hover: {
            boxShadow: 'unset',
            borderColor: 'red.200',
            backgroundColor: 'gray.100',
          },
        },
        _checked: {
          _before: {
            backgroundColor: 'gray.400',
          },
          '& > div': {
            '& > *': {
              color: 'gray.400',
            },
          },
        },
        _indeterminate: {
          _before: {
            backgroundColor: 'gray.400',
          },
          '& > div': {
            '& > *': {
              color: 'gray.400',
            },
          },
        },
        _hover: {
          borderColor: 'gray.200',
          boxShadow: 'unset',
        },
      },
    },
    icon: {
      color: `${baseColor}.500`,
    },
  },
};
