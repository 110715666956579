import React, { useEffect, useState } from 'react';
import {
  Form as FinalForm,
  FormProps as FinalFormProps,
  FormSpy,
  FormSpyProps,
  FormSpyRenderProps,
} from 'react-final-form';

import { chakra } from '@chakra-ui/react';
import { ValidationErrors } from 'final-form';
import { get, unset } from 'lodash';

const CustomForm = chakra('form');

interface FormProps<T> extends FinalFormProps<T> {
  defaultValues?: T;
  errors?: ValidationErrors;
  relatedErrors?: Record<string, Record<string, string[]>>;
}

/**
 * Proxy component for form libraries
 * The purpose of this component is to abstract and isolate the third party
 * library code and logic away from the consumers.
 *
 * -> It's enforcing an agnostic props interface which is not library specific.
 * -> It's easy to replace with any other form management library if needed.
 * -> It prevents code repetition across custom forms implemented in /domains
 */
export function Form<T = Record<string, unknown>>({
  children,
  defaultValues,
  initialValues: _, // proxied by 'defaultValues'
  errors,
  relatedErrors = {},
  validate,
  ...props
}: FormProps<T>): React.ReactElement {
  const [localErrors, setLocalErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    setLocalErrors(errors as any);
  }, [errors]);

  return (
    <FinalForm
      initialValues={defaultValues}
      validate={(data) => ({ ...validate?.(data), ...localErrors })}
      {...props}
    >
      {({ handleSubmit, active, values, form }) => {
        useEffect(() => {
          if (active && get(localErrors, String(active))) {
            setLocalErrors((prev) => {
              const temp = { ...prev };
              unset(temp, String(active));

              const related: Record<string, string[]> = {};

              if (Object.keys(relatedErrors)?.length) {
                Object.keys(relatedErrors).forEach((key) => {
                  related[key] = [
                    ...new Set([...Object.values(relatedErrors[key]).flat(1)]),
                  ] as string[];
                });
              }

              if (related[String(active)]) {
                related[String(active)].forEach((key) => {
                  unset(temp, key);
                });
              }

              Object.keys(related).forEach((key) => {
                if (related[key].includes(String(active))) {
                  unset(temp, key);

                  related[key].forEach((k) => {
                    unset(temp, k);
                  });
                }
              });

              return temp;
            });
          }
        }, [values]);

        useEffect(() => {
          // This useEffect is used to set all form elements that receise an error from BE as touched
          // This is needed because the form elements are not touched by default when defaultValues are present and errors are not displayed

          const formFields: string[] = form.getRegisteredFields();

          form.batch(() => {
            formFields.forEach((fieldName) => {
              if (get(errors, fieldName)) {
                form.blur(fieldName as keyof T);
              }
            });
          });
        }, [localErrors]);

        return (
          <CustomForm onSubmit={handleSubmit} noValidate width="full">
            {children as React.ReactNode}
          </CustomForm>
        );
      }}
    </FinalForm>
  );
}

interface FormHookProps<T = Record<string, unknown>> extends FormSpyProps<T> {
  children: (formStateProps: FormSpyRenderProps<T>) => React.ReactElement;
}

/**
 * Utility component used for hooking into the form state using render props pattern
 */
export function FormHook<T = Record<string, unknown>>({ children, ...rest }: FormHookProps<T>) {
  return <FormSpy {...rest}>{(formStateProps) => <>{children(formStateProps)}</>}</FormSpy>;
}

export { useField, useForm, useFormState } from 'react-final-form';
