// @ts-nocheck
/* eslint-disable no-console */
/* eslint-disable valid-typeof */
/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';

import { PostMessageInput } from './types';

export const BridgeEventType = {
  session_init: 'session_init',
  load_modal: 'load_modal',
  tooltip_grade: 'tooltip_grade',
  tooltip_exempt: 'tooltip_exempt',
  tooltip_attendance: 'tooltip_attendance',
  tooltip_file_options: 'tooltip_file_options',
  tooltip_message_options: 'tooltip_message_options',
  tooltip_group_options: 'tooltip_group_options',
  tooltip_photo_gallery_options: 'tooltip_photo_gallery_options',
  tooltip_photo_album_options: 'tooltip_photo_album_options',
  tooltip_member_control_options: 'tooltip_member_control_options',
  tooltip_session_member_control_options: 'tooltip_session_member_control_options',
  share_modal: 'share_modal',
  add_warning_success: 'add_warning_success',
  edit_warning_success: 'edit_warning_success',
  close_warning_page: 'close_warning_page',
  buy_premium: 'buy_premium',
  display_toast: 'display_toast',
  download_file: 'download_file',
  wb_state_modal: 'wb_state_modal',
  library_add: 'library_add',
  library_finish: 'library_finish',
  add_grade_success: 'add_grade_success',
  edit_grade_success: 'edit_grade_success',
  close_grade_page: 'close_grade_page',
  delete_students_classes: 'delete_students_classes',
  close_students_classes_page: 'close_students_classes_page',
};

export const usePlatformEvent = (cb?: any) => {
  const sendEvent = (message: PostMessageInput) => {
    if (!window.NATIVE) return;

    window.NATIVE.postMessage(JSON.stringify(message));
  };

  useEffect(() => {
    if (cb) {
      window.fromFlutter = function (input: any) {
        cb(input);
      };
    }
  }, []);

  return {
    sendEvent,
    types: BridgeEventType,
  };
};

export function platformEventWrapper(Component) {
  return function WrappedComponent(props) {
    const { sendEvent } = usePlatformEvent();

    return <Component {...props} sendEvent={sendEvent} />;
  };
}
