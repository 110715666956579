import { Entity, Of } from 'entity-of';

@Entity
export class ProgramsStduyYearsResponse {
  @Of(() => Number)
  programId: number = 0;

  @Of(() => Number)
  studyYear: number = 0;

  @Of(() => Number)
  semester: number = 0;

  @Of(() => Number)
  studyYearId: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  code: string = '';

  @Of(() => Number)
  disciplineId: number = 0;

  @Of(() => Number)
  academicPlanId: number = 0;

  static of = Entity.of<ProgramsStduyYearsResponse>();
}
