import { Entity, Of } from 'entity-of';

import { Slot } from './Slot';

@Entity
export class Slots {
  @Of(() => [String])
  days: string[] = [];

  @Of(() => [Slot])
  slots: Slot[] = [];

  static of = Entity.of<Slots>();
}
