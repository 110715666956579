import { VisibilityItem } from '@modules/shared/components/SelectOrganizationTree/entities';
import { Entity, Of } from 'entity-of';

@Entity
export class PublishPackageInput {
  @Of(() => [VisibilityItem])
  visibility: VisibilityItem[] = [];

  @Of(() => [String])
  excludedOrganizationIds?: string[] = [];

  static of = Entity.of<PublishPackageInput>();
}
