import { Entity, Of } from 'entity-of';

import { PricePlan } from '..';

@Entity
export class StudentType {
  @Of(() => Number)
  id: number = 0;

  @Of(() => String)
  name: string = '';

  @Of(() => String)
  category: string = '';

  @Of(() => String)
  code: string = '';

  @Of(() => PricePlan)
  pricePlan: PricePlan = PricePlan.of({});

  static of = Entity.of<StudentType>();
}
