import { Entity, Of } from 'entity-of';

import { TaxType } from '..';

@Entity
export class TaxPrice {
  @Of(() => String)
  pricePlanId: string = '';

  @Of(() => String)
  price: string = '';

  @Of(() => String)
  pricePlan: 'free' | 'unsubsidized' | 'subsidized' = 'unsubsidized';

  static of = Entity.of<TaxPrice>();
}

@Entity
export class DormTax {
  @Of(() => String)
  currency: string = '';

  @Of(() => String)
  taxId: string = '';

  @Of(() => String)
  tax: string = '';

  @Of(() => String)
  taxType: TaxType = 'didactic';

  @Of(() => String)
  startDate: string = '';

  @Of(() => String)
  endDate: string = '';

  @Of(() => String)
  dormId: string = '';

  @Of(() => String)
  dorm: string = '';

  @Of(() => [TaxPrice])
  prices: TaxPrice[] = [];

  @Of(() => String)
  price: string = '';

  static of = Entity.of<DormTax>();
}

@Entity
export class PaginatedDormTaxInput {
  @Of(() => Number)
  size?: number = 10;

  @Of(() => Number)
  page?: number = 1;

  @Of(() => String)
  sortField?: string = '';

  @Of(() => String, { nullable: true })
  direction?: 'asc' | 'desc' | null = null;

  @Of(() => [Number], { nullable: true })
  dorms: number[] | null = null;

  @Of(() => String, { nullable: true })
  priceType?: 'defined' | 'undefined' | 'free' | null = null;

  @Of(() => [Number], { nullable: true })
  taxes?: number[] | null = null;

  static of = Entity.of<PaginatedDormTaxInput>();
}
