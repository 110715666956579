import { Entity, Of } from 'entity-of';

@Entity
export class EdumarketOffer {
  @Of(() => String)
  emoID: string = '';

  @Of(() => String)
  emoOfertant: string = '';

  @Of(() => String)
  emoOfertantID: string = '';

  @Of(() => String)
  emoNume: string = '';

  @Of(() => String)
  emoDescriere: string = '';

  @Of(() => String)
  emoLogo: string = '';

  @Of(() => String)
  emoCover: string = '';

  @Of(() => String)
  emoLink: string = '';

  @Of(() => String)
  isFavorit: string = '';

  @Of(() => String)
  emoDataInsert: string = '';

  @Of(() => String)
  emoIsDeleted: string = '';

  @Of(() => String)
  emoIsPublished: string = '';

  static of = Entity.of<EdumarketOffer>();
}
