import React, { useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { useTranslation } from 'react-i18next';

import { useVirtualizer } from '@tanstack/react-virtual';
import { getOrgTypeTheme } from '@ui2/utils';
import debounce from 'lodash/debounce';

import {
  AdservioIcons,
  Box,
  Button,
  HStack,
  IconButton,
  InputGroup,
  Text,
  VStack,
} from '../../atoms';
import { Menu, MenuButton, MenuItem, MenuList } from '../Menu';

import { CountryPhoneData, phonePrefixes } from '.';
interface PhoneInputLeftAddonProps {
  selectedPhonePrefix: CountryPhoneData | undefined;
  handlePrefixChange: (prefix: CountryPhoneData | undefined) => void;
}

export const PhoneInputPrefixAddon = ({
  selectedPhonePrefix,
  handlePrefixChange,
}: PhoneInputLeftAddonProps) => {
  const { t } = useTranslation('shared');
  const baseColor = getOrgTypeTheme('blue');

  const initialFocusRef = React.useRef(null);
  const parentRef = React.useRef(null);

  const [filteredPhonePrefixes, setFilteredPhonePrefixes] =
    useState<CountryPhoneData[]>(phonePrefixes);

  const [searchValue, setSearchValue] = useState('');

  const rowVirtualizer = useVirtualizer({
    count: filteredPhonePrefixes.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    overscan: 5,
  });

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target.value);
    debouncedOnSearchChange(e.target.value);
  };

  const handleResetSearch = () => {
    setSearchValue('');
    setFilteredPhonePrefixes(phonePrefixes);
  };

  const debouncedOnSearchChange = debounce((value: string) => {
    setFilteredPhonePrefixes(
      phonePrefixes.filter(
        (prefix) =>
          prefix.name.toLowerCase().includes(value.toLowerCase()) ||
          prefix.dial_code.includes(value)
      )
    );
  }, 500);

  return (
    <Menu initialFocusRef={initialFocusRef}>
      {({ isOpen }) => (
        <>
          <HStack spacing="0" h="100%">
            <MenuButton
              as={Button}
              w="full"
              size="md"
              variant="ghost"
              colorScheme={baseColor}
              aria-label="toggle country selector"
              borderRightRadius="0"
              pr={selectedPhonePrefix ? '1.5' : undefined}
            >
              <HStack w="full" spacing="3" justifyContent="center">
                {selectedPhonePrefix ? (
                  <HStack spacing="3">
                    <CircleFlag
                      height="24"
                      width="24"
                      countryCode={selectedPhonePrefix.code.toLowerCase()}
                    />
                    <Text>{selectedPhonePrefix.dial_code}</Text>
                  </HStack>
                ) : (
                  <AdservioIcons.FiGlobe color="gray.400" boxSize="6" />
                )}

                {!selectedPhonePrefix &&
                  (isOpen ? (
                    <AdservioIcons.FiChevronUp color={`${baseColor}.500`} />
                  ) : (
                    <AdservioIcons.FiChevronDown color={`${baseColor}.500`} />
                  ))}
              </HStack>
            </MenuButton>
            {selectedPhonePrefix && (
              <IconButton
                aria-label="clear selected country"
                icon={<AdservioIcons.FiX />}
                onClick={() => handlePrefixChange(undefined)}
                variant="ghost"
                colorScheme={baseColor}
                borderRadius="0"
                size="sm"
                h="100%"
              />
            )}
          </HStack>
          <MenuList py="0" w={{ base: '20rem', md: '25rem' }} overflow="hidden">
            <InputGroup
              ref={initialFocusRef}
              autoComplete="off"
              leftElement={<AdservioIcons.FiSearch color="gray.400" />}
              placeholder={t('general.search_ellipsis')}
              variant="flushed"
              value={searchValue}
              onChange={handleSearchChange}
              rightElement={
                searchValue.length > 0 && (
                  <AdservioIcons.FiX
                    color="gray.400"
                    cursor="pointer"
                    onClick={handleResetSearch}
                  />
                )
              }
            />
            {filteredPhonePrefixes.length === 0 ? (
              <VStack w="full" py="3">
                <Text color="gray.400">{t('general.no_results')}</Text>
              </VStack>
            ) : (
              <Box
                ref={parentRef}
                maxH={{ base: '8.5rem', md: '10.5rem', '3xl': '17.5rem' }}
                overflow="hidden"
                overflowY="auto"
                w="full"
                py="2"
              >
                <Box w="full" h={`${rowVirtualizer.getTotalSize()}px`} position="relative">
                  {rowVirtualizer.getVirtualItems().map((virtualRow) => (
                    <Box
                      key={virtualRow.index}
                      transform={`translateY(${virtualRow.start}px)`}
                      h={`${virtualRow.size}`}
                      position="absolute"
                      left="0"
                      top="0"
                      w="full"
                    >
                      <MenuItem
                        onClick={() => handlePrefixChange(filteredPhonePrefixes[virtualRow.index])}
                        w="full"
                        px="2"
                        bg={
                          filteredPhonePrefixes[virtualRow.index].code === selectedPhonePrefix?.code
                            ? `${baseColor}.50`
                            : undefined
                        }
                      >
                        <HStack w="full" justifyContent="space-between">
                          <HStack w="full" spacing="4">
                            <CircleFlag
                              height="24"
                              width="24"
                              countryCode={filteredPhonePrefixes[
                                virtualRow.index
                              ].code.toLowerCase()}
                            />
                            <Text color="gray.500">
                              {filteredPhonePrefixes[virtualRow.index].name}
                            </Text>
                          </HStack>
                          {filteredPhonePrefixes[virtualRow.index].code ===
                            selectedPhonePrefix?.code && (
                            <AdservioIcons.FiCheck color={`${baseColor}.500`} />
                          )}
                        </HStack>
                      </MenuItem>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
