import { Entity, Of } from 'entity-of';

@Entity
export class NationalHoliday {
  @Of(() => String)
  holID: string = '';

  @Of(() => String)
  holName: string = '';

  @Of(() => String)
  holDate: string = '';

  @Of(() => String)
  holIsGlobal: '0' | '1' = '0';

  @Of(() => String, { optional: true })
  holCountryCod?: string;

  @Of(() => String)
  holCountryID: string = '';

  static of = Entity.of<NationalHoliday>();
}
