import { Entity, Of } from 'entity-of';

@Entity
export class Article {
  @Of(() => Number)
  nsID: number = 0;

  @Of(() => Number)
  nsUaID: number = 0;

  @Of(() => String)
  nsType: string = '';

  @Of(() => String)
  nsTitlu: string = '';

  @Of(() => String)
  nsContinutScurt: string = '';

  @Of(() => String)
  nsPoza: string = '';

  @Of(() => String)
  nsDataInsert: string = '';

  @Of(() => String)
  nsIsPinned: string = '';

  @Of(() => String)
  countFavorite: string = '';

  @Of(() => String)
  isFavorite: string = '';

  @Of(() => ArticleOwner)
  nsOwner: ArticleOwner = ArticleOwner.of({});

  static of = Entity.of<Article>();
}

@Entity
export class ArticleOwner {
  @Of(() => String)
  firstName: string = '';

  @Of(() => String)
  lastName: string = '';

  @Of(() => String)
  uaID: string = '';

  @Of(() => String)
  uaTip: string = '';

  @Of(() => String)
  avatar: string = '';

  static of = Entity.of<ArticleOwner>();
}
