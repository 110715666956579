import { IsEmail, IsNotEmpty } from 'class-validator';
import { Entity, Of } from 'entity-of';

import { EfacturaIssueType } from '../TenantBillingProfile';

@Entity
export class BillingProfileEfacturaInput {
  @Of(() => String)
  @IsNotEmpty({ message: 'errors.naming.required' })
  alias: string = '';

  @Of(() => String)
  @IsEmail(undefined, { message: 'errors.email.isValid' })
  @IsNotEmpty({ message: 'errors.email.required' })
  email: string = '';

  @Of(() => EfacturaIssueType)
  @IsNotEmpty({ message: 'errors.value.required' })
  eFacturaIssueType: EfacturaIssueType = EfacturaIssueType.MANUAL;

  static of = Entity.of<BillingProfileEfacturaInput>();
}
