import { useMediaQuery as _useMediaQuery } from '@chakra-ui/react';

export const mediaQueryBreakpoints = [
  '(min-width: 1536px)',
  '(min-width: 1280px)',
  '(min-width: 768px)',
  '(min-width: 560px)',
  '(min-width: 425px)',
];

export const useMediaQuery = () => {
  const [isLaptop, isNotebook, isTablet, isLargeMobile, isMobile] = _useMediaQuery(
    mediaQueryBreakpoints,
    {
      ssr: false,
    }
  );

  return {
    isLaptop,
    isNotebook,
    isTablet,
    isLargeMobile,
    isMobile,
  };
};
