import React, { PropsWithChildren } from 'react';
import { Row, TableCellProps, TableRowProps } from 'react-table';

import { getOrgTypeTheme } from '@ui2/utils';

import { ColumnProps, ColumnPropsItem } from '../../../organisms/Table';
import { Td, Tr } from '..';

import { ExpandedRowProps } from './TableRow';

interface TableCellItemProps {
  isLast?: boolean;
  isExpanded?: boolean;
  columnProps?: ColumnPropsItem;
}

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  TableCellProps & PropsWithChildren<TableCellItemProps>
>(({ children, isLast, isExpanded, columnProps, ...props }, ref) => {
  const extraStyles = {};

  const { backgroundColor, bgColor, ...rest } = columnProps || {};

  if (backgroundColor || bgColor) {
    extraStyles['backgroundColor'] = `${backgroundColor || bgColor} !important`;
  }

  return (
    <Td
      ref={ref}
      {...props}
      borderBottom={isLast && !isExpanded ? 0 : '1px solid'}
      {...columnProps}
      sx={{ ...extraStyles, ...rest }}
    >
      {children}
    </Td>
  );
});

export interface BaseRowProps<T extends object> {
  row: Row<T>;
  isDisabled: boolean;
  isLast?: boolean;
  columnProps?: ColumnProps;
}
export function BaseRow<T extends object>({
  row,
  isDisabled,
  isLast,
  columnProps,
}: BaseRowProps<T>): React.ReactElement {
  return (
    <TableRowContainer isDisabled={isDisabled} isSelected={row.isSelected} {...row.getRowProps()}>
      {row.cells.map((cell, i) => {
        return (
          <TableCell
            {...cell.getCellProps()}
            key={i}
            isLast={isLast}
            isExpanded={row.isExpanded}
            columnProps={columnProps?.[i]}
          >
            {cell.render('Cell')}
          </TableCell>
        );
      })}
    </TableRowContainer>
  );
}

interface TableContainerProps extends TableRowProps {
  isDisabled: boolean;
  isSelected: boolean;
  children: React.ReactElement[] | React.ReactNode;
}

export const TableRowContainer = ({
  children,
  isDisabled,
  isSelected,
  ...props
}: TableContainerProps) => {
  const baseColor = getOrgTypeTheme('blue');

  return (
    <Tr
      data-testid="table-row"
      sx={{
        '&&&': {
          _hover: {
            '& > td': {
              bg: isSelected ? `${baseColor}.100 !important` : 'gray.50 !important',
            },
          },
          '& > td': {
            bg: isSelected
              ? `${baseColor}.50 !important`
              : isDisabled
                ? 'gray.50 !important'
                : 'white',
            color: isDisabled ? 'gray.300' : 'gray.700',
            filter: isDisabled ? 'greyscale(100%)' : 'none',
          },
        },
      }}
      tabIndex={0}
      {...props}
    >
      {children}
    </Tr>
  );
};

export interface ControlledItem {
  colSpan: number;
  content: React.ReactNode;
}

export const ExpandedRow = ({ items, controlledItems }: ExpandedRowProps) => (
  <>
    <Tr
      w="full"
      minW="max-content"
      data-testid="expanded-row"
      bg="white"
      boxShadow={controlledItems?.length ? 'insetMd' : 'none'}
      _hover={{
        backgroundColor: controlledItems?.length ? 'transparent' : 'gray.50',
      }}
    >
      <Td
        style={{
          background: 'transparent',
          borderBottom: '1px solid',
          borderColor: controlledItems?.length
            ? 'var(--chakra-colors-gray-200)'
            : 'var(--chakra-colors-gray-100)',
        }}
      />

      {items?.map((item, index) => (
        <Td
          key={index}
          style={{ borderColor: 'var(--chakra-colors-gray-100)', background: 'transparent' }}
        >
          {item}
        </Td>
      ))}

      {controlledItems?.map((item, index) => (
        <Td
          key={index}
          colSpan={item.colSpan}
          style={{
            background: 'transparent',
            borderBottom: '1px solid',
            borderColor: 'var(--chakra-colors-gray-200)',
          }}
        >
          {item.content}
        </Td>
      ))}
    </Tr>
  </>
);
