import { useTranslation } from 'react-i18next';

import {
  CONFIG_FLAGS,
  PERM_ACCOMODATION_ALL_DORMS_CHECKIN_MANAGER,
  PERM_ACCOMODATION_ASSIGNMENT_MANAGER,
  PERM_ACCOMODATION_BUILDING_MANAGER,
  PERM_ACCOMODATION_CHECKIN_MANAGER,
  PERM_ACCOMODATION_FINANCIAL_MANAGER,
  PERM_ACCOMODATION_REPORTS,
  PERM_BILLING_CLIENT,
  PERM_BILLING_MANAGER,
  PERM_CAD_AUX,
  PERM_CAD_DIRECTOR,
  PERM_CAD_PROFESOR,
  PERM_CAD_REP_ADSERVIO,
  PERM_CAD_SECRETAR,
  PERM_LIBRARY_MANAGER,
  PERM_LIBRARY_VIEW,
  PERM_MANAGER_ERASMUS,
  PERM_MANAGER_STRUCTURA_UNI,
  PERM_SCHOLARSHIP_ASSIGN_MANAGER,
  PERM_SCHOLARSHIP_BUDGET_MANAGER,
  PERM_SCHOLARSHIP_CLIENT,
  PERM_SCHOLARSHIP_FACULTIES_MANAGER,
  PERM_SCHOLARSHIP_REPORTS,
  PERM_TENANT_MANAGER,
} from '@constants';
import { LinkButtonItem, LinkButtonsGroup } from '@modules/shared';

export const useUniversitiesLinkGroups = () => {
  const { t } = useTranslation(['Site', 'common']);

  const DEFAULT_PERMS = [PERM_CAD_DIRECTOR, PERM_CAD_SECRETAR, PERM_CAD_REP_ADSERVIO];

  const groups: LinkButtonsGroup[] = [
    {
      items: [
        LinkButtonItem.of({
          icon: 'FiHome',
          label: t('buttons_mm.dashboard'),
          url: 'dashboard',
          matchingUrls: [
            'secretary/configuration',
            'secretary/configuration/information',
            'secretary/configuration/notification',
            'secretary/configuration/study_years',
            'secretary/configuration/catalog',
            'configuration/roles-and-permissions',
            'secretary/configuration/messages',
            'secretary/configuration/behavior',
            'secretary/configuration/groups',
            'secretary/configuration/homework',
            'secretary/configuration/activities_staff',
            'secretary/config/forms',
            'secretary/configuration/observations',
            'secretary/configuration/schedule',
            'secretary/configuration/files',
            'secretary/configuration/iq_tests',
            'secretary/configuration/library',
            'secretary/configuration/photos',
            'secretary/configuration/admittance',
            'secretary/configuration/edumarket',
            'secretary/configuration/neuron_english',
            'secretary/configuration/news',
            'secretary/configuration/teacher_history',
            'universities/configuration',
          ],
          accessRules: {
            permissions: [...DEFAULT_PERMS, PERM_CAD_PROFESOR, PERM_CAD_AUX],
          },
        }),

        LinkButtonItem.of({
          icon: 'FiLayers',
          label: t('buttons_mm.university_structure'),
          url: 'universities/university-structure',
          matchingUrls: ['university-structure'],
          accessRules: {
            permissions: [...DEFAULT_PERMS, PERM_MANAGER_STRUCTURA_UNI, PERM_TENANT_MANAGER],
          },
        }),

        LinkButtonItem.of({
          icon: 'Teachers',
          label: t('buttons_mm.university_personal'),
          url: 'universities/university-personal',
          matchingUrls: ['university-personal'],
          accessRules: {
            permissions: [...DEFAULT_PERMS, PERM_TENANT_MANAGER],
          },
        }),

        LinkButtonItem.of({
          icon: 'FiUsers',
          label: t('buttons_mm.university_student'),
          url: 'universities/university-student',
          matchingUrls: ['university-student'],
          accessRules: {
            permissions: [...DEFAULT_PERMS, PERM_TENANT_MANAGER],
          },
        }),

        LinkButtonItem.of({
          icon: 'Groups',
          label: t('buttons_mm.commissions'),
          url: 'universities/commissions',
          accessRules: {
            permissions: [PERM_CAD_REP_ADSERVIO, PERM_CAD_DIRECTOR],
          },
        }),

        LinkButtonItem.of({
          icon: 'Admin',
          label: t('buttons_mm.job_statuses'),
          url: 'universities/state-of-functions',
          accessRules: {
            permissions: [PERM_CAD_DIRECTOR, PERM_CAD_REP_ADSERVIO],
          },
        }),

        LinkButtonItem.of({
          icon: 'History',
          label: t('buttons_mm.history'),
          url: 'universities/super-admin-history',
          accessRules: {
            permissions: [PERM_CAD_REP_ADSERVIO],
          },
        }),

        LinkButtonItem.of({
          icon: 'FiBarChart2',
          label: t('buttons_mm.reports_statistics'),
          url: 'universities/reports',
          accessRules: {
            permissions: [PERM_CAD_SECRETAR],
          },
        }),
      ],
    },
    {
      emptyTitle: true,
      items: [
        LinkButtonItem.of({
          icon: 'ReportCard',
          label: t('buttons_mm.educational_plan'),
          url: 'universities/educational-plan',
          matchingUrls: ['educational-plan'],
          accessRules: {
            permissions: [...DEFAULT_PERMS, PERM_CAD_PROFESOR],
          },
        }),

        LinkButtonItem.of({
          icon: 'Gradebook',
          label: t('buttons_mm.catalog'),
          url: 'universities/catalog',
          matchingUrls: [
            'universities/catalog',
            'universities/catalog/student',
            'universities/catalog/grades',
            'universities/catalog/reexamination',
            'universities/catalog/academic',
            'universities/catalog/absences',
            'universities/catalog/promotion',
          ],
          accessRules: {
            permissions: [...DEFAULT_PERMS, PERM_CAD_PROFESOR],
          },
        }),

        LinkButtonItem.of({
          icon: 'DataFlow',
          label: t('buttons_mm.options'),
          url: 'universities/options',
          matchingUrls: ['options'],
          accessRules: {
            permissions: [...DEFAULT_PERMS],
          },
        }),

        LinkButtonItem.of({
          icon: 'FiGlobe',
          label: t('buttons_mm.erasmus'),
          url: 'universities/erasmus-program',
          matchingUrls: ['universities/erasmus-program-approvals'],
          accessRules: {
            permissions: [PERM_MANAGER_ERASMUS],
          },
        }),
      ],
    },
    {
      emptyTitle: true,
      items: [
        LinkButtonItem.of({
          icon: 'Portofolio',
          label: t('buttons_mm.academic_profile'),
          url: 'universities/profile-academic',
          accessRules: {
            permissions: [PERM_CAD_PROFESOR],
          },
        }),

        LinkButtonItem.of({
          icon: 'FiFile',
          label: t('buttons_mm.fisiere'),
          url: `files`,
          accessRules: {
            configFlags: [CONFIG_FLAGS.FILES_ACTIVE],
          },
        }),

        LinkButtonItem.of({
          icon: 'Library',
          matchingUrls: ['universities/library/manage', 'universities/library/book'],
          label: t('buttons_mm.biblioteca'),
          url: 'universities/library',
          accessRules: {
            permissions: [PERM_LIBRARY_MANAGER, PERM_LIBRARY_VIEW],
            configFlags: [CONFIG_FLAGS.LIBRARY_ACTIVE],
          },
        }),
      ],
    },
    {
      emptyTitle: true,
      items: [
        LinkButtonItem.of({
          icon: 'Scholarship',
          label: t('buttons_mm.scholarships.label'),
          accessRules: {
            permissions: [
              PERM_SCHOLARSHIP_ASSIGN_MANAGER,
              PERM_SCHOLARSHIP_BUDGET_MANAGER,
              PERM_SCHOLARSHIP_REPORTS,
              PERM_SCHOLARSHIP_FACULTIES_MANAGER,
            ],
          },
          matchingUrls: [
            'universities/scholarships',
            'universities/scholarships/allocated',
            'universities/scholarships/reports',
          ],
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.scholarships.configuration'),
              url: 'universities/scholarships',
              accessRules: {
                permissions: [PERM_SCHOLARSHIP_BUDGET_MANAGER],
              },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.scholarships.allocated'),
              url: 'universities/scholarships/allocated',
              accessRules: {
                permissions: [PERM_SCHOLARSHIP_ASSIGN_MANAGER, PERM_SCHOLARSHIP_FACULTIES_MANAGER],
              },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.scholarships.reports'),
              url: 'universities/scholarships/reports',
              accessRules: {
                permissions: [PERM_SCHOLARSHIP_REPORTS],
              },
            }),
          ],
        }),

        LinkButtonItem.of({
          icon: 'Scholarship',
          label: t('buttons_mm.scholarships.label'),
          url: 'universities/scholarships/student',
          accessRules: {
            permissions: [PERM_SCHOLARSHIP_CLIENT],
          },
          matchingUrls: ['universities/scholarships/student'],
        }),

        LinkButtonItem.of({
          icon: 'Building',
          label: t('buttons_mm.accommodations'),
          url: 'universities/accommodations',
          accessRules: {
            permissions: [
              PERM_CAD_REP_ADSERVIO,
              PERM_ACCOMODATION_REPORTS,
              PERM_ACCOMODATION_ALL_DORMS_CHECKIN_MANAGER,
              PERM_ACCOMODATION_ASSIGNMENT_MANAGER,
              PERM_ACCOMODATION_BUILDING_MANAGER,
              PERM_ACCOMODATION_CHECKIN_MANAGER,
              PERM_ACCOMODATION_FINANCIAL_MANAGER,
            ],
            configFlags: [CONFIG_FLAGS.ACCOMMODATION_ACTIVE],
          },
          subitems: [
            LinkButtonItem.of({
              label: t('buttons_mm.structure_configuration'),
              url: 'universities/accommodations/dorms',
              matchingUrls: [
                'universities/accommodations/rooms',
                'universities/accommodations/room-types',
                'universities/accommodations/student-types',
              ],
              accessRules: {
                permissions: [PERM_CAD_REP_ADSERVIO, PERM_ACCOMODATION_BUILDING_MANAGER],
              },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.administration'),
              url: 'universities/accommodations/administration',
              matchingUrls: ['universities/accommodations/students'],
              accessRules: {
                permissions: [
                  PERM_CAD_REP_ADSERVIO,
                  PERM_ACCOMODATION_ASSIGNMENT_MANAGER,
                  PERM_ACCOMODATION_CHECKIN_MANAGER,
                  PERM_ACCOMODATION_ALL_DORMS_CHECKIN_MANAGER,
                ],
              },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.taxes'),
              url: 'universities/accommodations/taxes',
              matchingUrls: ['universities/accommodations/prices'],
              accessRules: {
                permissions: [PERM_CAD_REP_ADSERVIO, PERM_ACCOMODATION_FINANCIAL_MANAGER],
              },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.financial'),
              url: 'universities/accommodations/payment-obligations',
              matchingUrls: [
                'universities/accommodations/payments',
                'universities/accommodations/sold-students',
              ],
              accessRules: {
                permissions: [PERM_CAD_REP_ADSERVIO, PERM_ACCOMODATION_FINANCIAL_MANAGER],
              },
            }),
            LinkButtonItem.of({
              label: t('buttons_mm.reports'),
              url: 'universities/accommodations/reports',
              accessRules: {
                permissions: [PERM_CAD_REP_ADSERVIO, PERM_ACCOMODATION_REPORTS],
              },
            }),
          ],
        }),

        LinkButtonItem.of({
          icon: 'Coins',
          label: t('buttons_mm.financials'),
          matchingUrls: [
            'billing',
            `billing/invoices`,
            `billing/services`,
            `billing/billing-profiles`,
          ],
          subitems: [
            LinkButtonItem.of({
              label: t('legacy-url.path.financial_panel'),
              url: `billing`,
            }),
            LinkButtonItem.of({
              label: t('legacy-url.path.invoices_payments'),
              url: `billing/invoices`,
            }),
            LinkButtonItem.of({
              label: t('legacy-url.path.services'),
              url: `billing/services`,
            }),
            LinkButtonItem.of({
              label: t('legacy-url.path.profiles'),
              url: `billing/billing-profiles`,
            }),
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.BILLING_ACTIVE],
            permissions: [PERM_BILLING_MANAGER],
          },
        }),

        LinkButtonItem.of({
          icon: 'Coins',
          label: t('buttons_mm.financials'),
          url: 'billing/account/invoices',
          matchingUrls: [
            'billing/account/invoices',
            'billing/account/payments',
            'billing/account/available-services',
            'billing/account/pending-services',
            'billing/account/profiles',
          ],
          accessRules: {
            configFlags: [CONFIG_FLAGS.BILLING_ACTIVE],
            permissions: [PERM_BILLING_CLIENT],
          },
        }),
      ],
    },
  ];

  return {
    groups,
  };
};
