import { Entity, Of } from 'entity-of';

@Entity
export class DormsQueryInput {
  @Of(() => Number)
  size?: number = 10;

  @Of(() => Number)
  page?: number = 1;

  @Of(() => String)
  sortField?: string = '';

  @Of(() => String, { nullable: true })
  direction?: 'asc' | 'desc' | null = null;

  @Of(() => String, { optional: true })
  searchTerm?: string | null = null;

  @Of(() => Boolean, { nullable: true })
  allData?: boolean | number | null = null;

  @Of(() => Boolean, { optional: true })
  withRooms: boolean = false;

  @Of(() => Boolean, { optional: true })
  withStats: boolean = false;

  static of = Entity.of<DormsQueryInput>();
}
