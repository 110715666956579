import { Entity, Of } from 'entity-of';

@Entity
export class Slot {
  @Of(() => String)
  id: string = '';

  @Of(() => String)
  startHour: string = '';

  @Of(() => String)
  endHour: string = '';

  static of = Entity.of<Slot>();
}
