import { Entity, Of } from 'entity-of';

@Entity
export class ExchangeRatesInput {
  @Of(() => String)
  toCurrency: string = '';

  @Of(() => [String])
  fromCurrencies: string[] = [];

  @Of(() => String, { nullable: true })
  date?: string;

  @Of(() => String, { optional: true })
  rootOrgId?: string = '';

  static of = Entity.of<ExchangeRatesInput>();
}
