import { Entity, Of } from 'entity-of';

import { AssignableBy, Permission } from '../Service';

type ServiceInputStatus = 'enabled' | 'disabled' | 'deleted';

@Entity
export class PaginatedServiceSearchInput {
  @Of(() => [String], { optional: true })
  accountIds?: string[] = [];

  @Of(() => [String], { optional: true })
  serviceIds?: string[] = [];

  @Of(() => [String], { optional: true })
  statuses?: ServiceInputStatus[];

  @Of(() => [String], { optional: true })
  userCanAdd?: Permission[];

  @Of(() => Number, { optional: true })
  offset?: number = 0;

  @Of(() => Number, { optional: true })
  limit?: number = 1;

  @Of(() => String, { optional: true })
  name?: string;

  @Of(() => Number, { optional: true })
  billingProfileId?: number;

  @Of(() => String, { optional: true })
  order?: 'asc' | 'desc';

  @Of(() => String, { optional: true })
  orderBy?: string;

  @Of(() => Boolean, { optional: true })
  assignableBy?: AssignableBy;

  static of = Entity.of<PaginatedServiceSearchInput>();
}
