import Cookie from 'js-cookie';

import {
  ADS_NATIVE,
  defaultState,
  SESSION_SET,
  SESSION_UNSET,
  TOGGLE_FOOTER,
  TOGGLE_HEADER,
  TOGGLE_MENU,
  UNSET_ADS_NATIVE,
} from './SessionActions';

const SessionReducer = (state = defaultState, action) => {
  const newSession = {
    ...state,
    lastUpdated: new Date(),
    ...action.data,
    isVisibleHeader: !Cookie.get('ads_native'),
    isVisibleFooter: !Cookie.get('ads_native'),
  };

  switch (action.type) {
    case SESSION_SET:
      if (window.localStorage) {
        localStorage.setItem('session', JSON.stringify(newSession));
      }

      return newSession;
    case SESSION_UNSET:
      if (window.localStorage) {
        localStorage.removeItem('orgId');
        localStorage.removeItem('session');
        localStorage.removeItem('routeHistory');
      }

      return {
        ...defaultState,
      };

    case ADS_NATIVE:
      return {
        ...state,
        hasCookiesAdsNative: !!Cookie.get('ads_native'),
      };

    case UNSET_ADS_NATIVE:
      return {
        ...state,
        hasCookiesAdsNative: false,
      };

    case TOGGLE_HEADER:
      return {
        ...state,
        isVisibleHeader: !state.isVisibleHeader,
      };

    case TOGGLE_FOOTER:
      return {
        ...state,
        isVisibleFooter: !state.isVisibleFooter,
      };

    case TOGGLE_MENU:
      return {
        ...state,
        isVisibleMenu: !state.isVisibleMenu,
      };

    default:
      return state;
  }
};

export default SessionReducer;
